import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment-timezone';

import { MUTATION_APPROVE_CASE_SS } from '@graphql/mutation';
import { modal } from '@components/ModalManagement';
import { loadPhotoProfileGender } from '../../utils';
import ButtonPrime from '@components/shared/ButtonPrime';
import ModalSuccess from '@components/Modal/ModalSuccess';

const CardComponent = styled(Link)`
  text-decoration: none;
  border: none !important;
`;

const Container = styled.div`
  min-width: 262px;
  min-height: 106px;
  max-width: 262px;
  padding: 17px;
  background: #fff;
  border-radius: 27px;
  box-shadow:
    0px 2px 0px rgba(162, 162, 162, 0.1),
    0px 1px 3px rgba(162, 162, 162, 0.1);
  transition: all 0.3s ease-out;
  border: 2px solid transparent !important;
  &:hover {
    border: 2px solid #d97617 !important;
  }
  &:active {
    border: 2px solid #ab5d12 !important;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 1.5px;
`;

const TreatmentCardDoctor = (props) => {
  const history = useHistory();
  const location = useLocation();
  const userId = localStorage.getItem('b360-id');

  const isRefinement = props.patient.treatment_related.is_refinement;
  const hasRefinement = props.patient.treatment_related.hasRefinement;
  let patient = props.patient;
  let current_timezone = moment.tz.guess();
  let start_date =
    patient.treatment_related.smartsChecks.length > 0
      ? moment(
          patient.treatment_related.smartsChecks[
            patient.treatment_related.smartsChecks.length - 1
          ].start_date,
        ).tz(current_timezone)
      : null;
  let finish_date =
    patient.treatment_related.smartsChecks.length > 0
      ? moment(
          patient.treatment_related.smartsChecks[
            patient.treatment_related.smartsChecks.length - 1
          ].finish_date,
        ).tz(current_timezone)
      : null;
  let current_date = moment();
  let hasMessageAdmin =
    patient.treatment_related?.messages?.filter(
      (message) => message.from?.id !== userId && !message.readed,
    ).length > 0;
  let message_doctor = patient.treatment_related.message_doctor;
  const isArchived = patient.treatment_related.archived;
  const isTypeError = patient.treatment_related.treatmentStatus.color_type;
  const statusIndex = patient.treatment_related.treatmentStatus.index;
  const statusType = patient.treatment_related.treatmentStatus.type;
  const statusId = patient.treatment_related.treatmentStatus.id;

  const [approveCaseSS] = useMutation(MUTATION_APPROVE_CASE_SS, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Iniciaremos la manufactura de tu FitJig. Te notificaremos cuando
            hayan sido enviados. Este proceso dura aproximadamente 8 días
            hábiles.
          </LabelNormal>
        </ModalSuccess>,
      );
    },
  });

  const getMessagePathname = () => {
    if (message_doctor) {
      if (props.role === 'student') {
        return `/estudiante/caso/planificacion/${patient.id}`;
      } else {
        return `/doctor/caso/mensajes-borgatta/${patient.id}`;
      }
    } else {
      if (props.role === 'student') {
        return `/estudiante/caso/mensajes-borgatta/${patient.id}`;
      } else {
        return `/doctor/caso/mensajes-borgatta/${patient.id}`;
      }
    }
  };

  return (
    <CardComponent {...props}>
      <Container style={props.style}>
        <InlineContainer
          style={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 14,
          }}
        >
          <img
            src={
              patient.photo_filename
                ? `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
                : loadPhotoProfileGender(patient.gender)
            }
            alt="patient-avatar"
            style={{
              height: 70,
              width: 70,
              borderRadius: 74.38,
              objectFit: 'cover',
            }}
          />
          {(hasRefinement || isRefinement) && (
            <LabelNormal
              fontSize="12"
              style={{
                color: '#757575',
                marginRight: 3,
                borderRadius: 4,
                backgroundColor: '#F4F4F4',
                padding: '3px 5px',
              }}
            >
              {isRefinement ? 'Refinamiento' : 'Tratamiento original'}
            </LabelNormal>
          )}
        </InlineContainer>
        <InlineContainer>
          <LabelNormal
            fontSize="11"
            style={{ color: '#436FF1', marginRight: 3 }}
          >
            PX
          </LabelNormal>
          <LabelNormal
            style={{ color: '#000000', fontSize: 15 }}
          >{`${patient.first_name} ${patient.last_name}`}</LabelNormal>
        </InlineContainer>
        <InlineContainer
          style={{
            paddingLeft: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {isTypeError === 'error' &&
          isArchived === false &&
          hasMessageAdmin === false ? (
            <FeatherIcon
              icon="alert-triangle"
              size="16"
              color="#E41D11"
              style={{ marginRight: 1 }}
            />
          ) : isArchived === false ? (
            <div
              style={{
                background:
                  statusId === '1'
                    ? '#828282'
                    : isTypeError === 'error'
                      ? '#E41D11'
                      : '#169D00',
                height: 10,
                width: 10,
                borderRadius: 5,
                marginLeft: 2,
                marginRight: 5,
              }}
            />
          ) : (
            <div
              style={{
                background: '#BDBDBD',
                height: 10,
                width: 10,
                borderRadius: 5,
              }}
            />
          )}

          {isArchived === false ? (
            <LabelNormal
              style={{
                color:
                  statusId === '1'
                    ? '#828282'
                    : isTypeError === 'error'
                      ? '#E41D11'
                      : '#169D00',
                fontSize: 15.5,
              }}
            >
              {patient.treatment_related.treatmentStatus.name}
            </LabelNormal>
          ) : (
            <LabelNormal style={{ color: '#BDBDBD', fontSize: 15.5 }}>
              Caso Inactivo
            </LabelNormal>
          )}
        </InlineContainer>
        {props.role !== 'teacher' && props.role !== 'university' ? (
          <>
            {statusId === '5' &&
              isArchived === false &&
              hasMessageAdmin === false && (
                <Link
                  to={{
                    pathname: `/${
                      props.role === 'student' ? 'estudiante' : 'doctor'
                    }/caso/correccion/${patient.id}`,
                    state: {
                      treatmentId: patient.treatment_related.id,
                      firstName: patient.first_name,
                      lastName: patient.last_name,
                    },
                  }}
                >
                  <ButtonPrime size="sm" label="Corregir errores" />
                </Link>
              )}
            {statusIndex === 1 &&
              statusType === 'planning' &&
              isArchived === false &&
              hasMessageAdmin === false && (
                <Link
                  to={{
                    pathname: `/${
                      props.role === 'student' ? 'estudiante' : 'doctor'
                    }/caso/planificacion/${patient.id}`,
                    state: { student: props.role === 'student' ? true : false },
                  }}
                >
                  <ButtonPrime size="sm" label="Ver planificación" />
                </Link>
              )}
            {hasMessageAdmin && (
              <>
                <InlineContainer
                  style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
                >
                  <FeatherIcon
                    icon="message-circle"
                    color="#EB9542"
                    size={15}
                  />
                  <LabelNormal color="#3C4549" fontSize="15">
                    Mensaje nuevo de Borgatta
                  </LabelNormal>
                </InlineContainer>
                <Link to={{ pathname: getMessagePathname() }}>
                  <ButtonPrime size="sm" label="Contestar mensajes" />
                </Link>
              </>
            )}
            {((patient.treatment === 'cryst_aligner' &&
              statusIndex === 3 &&
              statusType === 'planning' &&
              patient.treatment_related.make_smartcheck === true &&
              patient.treatment_related.smartsChecks.length === 0 &&
              isArchived === false) ||
              (patient.treatment === 'smile_system' &&
                statusIndex === 3 &&
                statusType === 'planning' &&
                isArchived === false)) && (
              <Link
                to={{
                  pathname: `/${
                    props.role === 'student' ? 'estudiante' : 'doctor'
                  }/caso/agendar-smartcheck/${patient.id}`,
                  state: { treatmentId: patient.treatment_related.id },
                }}
              >
                <ButtonPrime size="sm" label="Agendar Smart check" />
              </Link>
            )}
            {statusType === 'planning' &&
              (statusIndex === 5 || statusIndex === 7 || statusIndex === 10) &&
              patient.treatment_related.smartsChecks.length > 0 &&
              isArchived === false && (
                <>
                  {current_date.isBefore(start_date) ? (
                    <Link
                      to={''}
                      style={{
                        border: '1px solid #F2B559',
                        padding: '9px 11px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 40,
                        textDecoration: 'none',
                      }}
                    >
                      <FeatherIcon icon="calendar" color="#F2B559" size="19" />
                      <LabelNormal color="#F2B559" fontSize="15">
                        {start_date.format('DD [de] MMMM [-] hh:mm a')}
                      </LabelNormal>
                    </Link>
                  ) : current_date.isBetween(start_date, finish_date) ? (
                    <ButtonPrime
                      size="sm"
                      label="Ir a Smart check"
                      onClick={() =>
                        window.open(
                          patient.treatment_related.smartsChecks[
                            patient.treatment_related.smartsChecks.length - 1
                          ].zoom_link,
                          '_blank',
                        )
                      }
                    />
                  ) : (
                    <LabelNormal color="#757575" fontSize="15">
                      Smart check perdido
                    </LabelNormal>
                  )}
                </>
              )}
            {((statusType === 'planning' &&
              statusIndex === 4 &&
              patient.treatment_related.make_smartcheck === false &&
              patient.treatment_related.smartsChecks.length === 0 &&
              isArchived === false &&
              patient.treatment === 'cryst_aligner' &&
              localStorage.getItem('b360-role') === 'doctor') ||
              (statusType === 'planning' &&
                statusIndex === 4 &&
                patient.treatment_related.make_smartcheck === true &&
                patient.treatment_related.smartsChecks.length > 0 &&
                isArchived === false &&
                patient.treatment === 'cryst_aligner' &&
                localStorage.getItem('b360-role') === 'doctor') ||
              (statusType === 'planning' &&
                localStorage.getItem('b360-role') === 'student' &&
                statusIndex === 14)) &&
              hasMessageAdmin === false && (
                <Link
                  to={{
                    pathname: `/${
                      location.state && location.state.student
                        ? 'estudiante'
                        : 'doctor'
                    }/aprobar-caso/${patient.id}`,
                    state: { treatmentId: patient.treatment_related.id },
                  }}
                >
                  <ButtonPrime size="sm" label="Aprobar caso" />
                </Link>
              )}
            {((statusType === 'planning' &&
              statusIndex === 4 &&
              patient.treatment_related.smartsChecks.length > 0 &&
              isArchived === false) ||
              (statusType === 'planning' &&
                localStorage.getItem('b360-role') === 'student' &&
                statusIndex === 14)) &&
              patient.treatment !== 'cryst_aligner' && (
                <ButtonPrime
                  size="sm"
                  label="Aprobar caso"
                  onClick={() =>
                    approveCaseSS({ variables: { patient_id: patient.id } })
                  }
                />
              )}
          </>
        ) : (
          props.role === 'teacher' && (
            <>
              {statusType === 'planning' && statusIndex === 13 && (
                <ButtonPrime
                  size="sm"
                  label="Aprobar caso"
                  onClick={props.onSubmitTeacher}
                />
              )}
            </>
          )
        )}
      </Container>
    </CardComponent>
  );
};

export default TreatmentCardDoctor;
