import React, { FC } from 'react';
import { ButtonSecondary } from '@acueductostudio/borgatta-components';
import Treatment from '@models/treatment';
import { TreatmentStatusEnum } from '@models/treatment-status';
import UserRole, { UserRoleEnum } from '@models/user-role';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface ContinueTreatmentProps {
  treatment: Treatment;
  viewer: UserRole;
}

const ContinueTreatmentCard: FC<ContinueTreatmentProps> = ({
  treatment,
  viewer,
}) => {
  const history = useHistory();
  const extensibleStatus = [
    TreatmentStatusEnum.Delivered,
    TreatmentStatusEnum.WaitingEvidence,
    TreatmentStatusEnum.EndedCase,
  ];
  const isDoctor = () =>
    viewer === UserRoleEnum.DOCTOR || viewer === UserRoleEnum.STUDENT;
  const ableToRefinement = () =>
    extensibleStatus.includes(treatment.treatmentStatus.id);
  const expired = () =>
    treatment.expiration_date &&
    moment().isAfter(moment(treatment.expiration_date));
  if (isDoctor() && ableToRefinement() && !expired()) {
    return (
      <div className="bg-white rounded-2xl shadow-md p-4 flex flex-col gap-4">
        <p className="text-prominence-default text-base font-normal">
          ¿Necesitas más alineadores?
        </p>
        <div
          onClick={() =>
            history.push(
              `/doctor/nuevo-caso?register_type=extension&parent_treatment_id=${treatment.id}&doctor_id=${treatment.doctor.id}`
            )
          }
        >
          <ButtonSecondary>Continuar tratamiento</ButtonSecondary>
        </div>
      </div>
    );
  }
  return null;
};

export default ContinueTreatmentCard;
