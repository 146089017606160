import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';

interface UploadFileProps {
  query: string;
  variables: object;
  file: File;
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
  onResponse: (response: AxiosResponse<any, any>) => void;
  onError?: (e: any) => void;
}

export const downloadFile = (
  url: string,
  filename: string,
  onDownload: () => void
) => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token-b360')}`,
    },
  };
  fetch(url, options)
    .then((res) => res.json())
    .then((data) => {
      let a = document.createElement('a');
      a.href = data.url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      onDownload();
    });
};

export const UploadFileThroughGraphQL = ({
  query,
  variables,
  file,
  onUploadProgress,
  onResponse,
  onError,
}: UploadFileProps): Promise<void> => {
  const token = localStorage.getItem('token-b360');
  const instance = axios.create({
    baseURL: process.env.REACT_APP_URL_GRAPHQL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  const formData = new FormData();
  formData.append(
    'operations',
    JSON.stringify({
      query,
      variables,
    })
  );
  formData.append('0', file);
  formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
  return instance
    .post('', formData, {
      onUploadProgress: (progressEvent) => onUploadProgress(progressEvent),
    })
    .then((response) => onResponse(response))
    .catch((e) => onError && onError(e));
};
