import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENT = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      patient {
        id
        first_name
        last_name
        treatment
      }
      treatmentStatus {
        id
        name
        index
        type
        admin_name
        color_type
      }
      treatmentType {
        id
        name
      }
      bracket_ss
      has_changes_ca
      has_changes_ss
      aligner_send_type
      visor_url
      visor_desk_link
      visor_patient_link
      planning_software
      planning_case_id
      message_doctor
      make_smartcheck
      aligners_number
      bottom_aligners_number
      last_smart_check {
        id
        zoom_link
        status
      }
      files {
        id
        name
        file_name
      }
      partialities {
        id
        bottom
        upper
      }
      smartsChecks {
        id
        start_date
        finish_date
        zoom_link
        status
      }
    }
  }
`;
