import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { MUTATION_APPROVED_BY_TEACHER } from '@graphql/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import TreatmentCardDoctor from '../../components/shared/TreatmentCardDoctor';
import { onboarding } from '@components/OnBoarding';
import { modal } from '@components/ModalManagement';
import ModalWarning from '@components/Modal/ModalWarning';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';
import Skeleton from 'react-loading-skeleton';
import { TreatmentCard } from '@acueductostudio/borgatta-components';
import { UserRoleEnum } from '@models/user-role';
import TreatmentCardContainer from '@components/kanban/treatment-card-container';
import { QUERY_GET_TREATMENT_TEACHERS } from '@graphql/queries/teacher/cases';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 30px;
`;

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
  margin-bottom: 39px;
`;

const options = [
  { id: 'Requieren de tu atención', name: 'Requieren de tu atención' },
  { id: 'En proceso', name: 'En proceso' },
  { id: 'Terminados / inactivos', name: 'Terminados / inactivos' },
];

const YourCases = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('onboarding'))
      onboarding.open({
        columns: [
          {
            title: 'Este es el tablero de Casos',
            body: 'Aquí aparecerán los casos de tus estudiantes por status y en orden de los que requieran de tu atención. Puedes filtrar y buscar por nombre.',
            arrows: true,
            indicator: true,
            position: 'center',
          },
          {
            title: 'Este es el tablero de Estudiantes',
            body: 'En el perfil de cada estudiante puedes ver sus casos asignados.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 0,
          },
          {
            title: 'Estamos para ayudarte',
            body: 'En soporte puedes comunicarte con nuestro equipo para resolver errores de la plataforma.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 10,
          },
        ],
      });
  }, []);

  const [selectedOption, setSelectedOption] = useState([
    'Requieren de tu atención',
    'En proceso',
    'Terminados / inactivos',
  ]);
  const [cases, setCases] = useState([]);
  const [search, setSearch] = useState('');

  const [getTreatmentsByTeachers] = useLazyQuery(QUERY_GET_TREATMENT_TEACHERS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getTreatmentsByTeachers) {
        setCases(data.getTreatmentsByTeachers);
      }
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    if (search !== '') {
      getTreatmentsByTeachers({
        variables: {
          teacher_id: +localStorage.getItem('b360-role_id'),
          filters: selectedOption,
          search,
        },
      });
    } else {
      getTreatmentsByTeachers({
        variables: {
          teacher_id: +localStorage.getItem('b360-role_id'),
          filters: selectedOption,
        },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    setLoading(true);
    if (search !== '') {
      getTreatmentsByTeachers({
        variables: {
          teacher_id: +localStorage.getItem('b360-role_id'),
          filters: selectedOption,
          search,
        },
      });
    } else {
      getTreatmentsByTeachers({
        variables: {
          teacher_id: +localStorage.getItem('b360-role_id'),
          filters: selectedOption,
        },
      });
    }
  }, [search]);

  const [approvedTeacher] = useMutation(MUTATION_APPROVED_BY_TEACHER, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setLoading(true);
              getTreatmentsByTeachers({
                variables: {
                  teacher_id: +localStorage.getItem('b360-role_id'),
                  filters: selectedOption,
                },
              });
            })
          }
        >
          <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
            Caso aprobado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            El estudiante podrá mandar a manufactura cuando apruebe el caso.
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onSubmitTeacher = async (id) => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal color="#060809" fontSize="22" style={{ marginBottom: 14 }}>
          Este caso se mandará a producción
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Confirmar este caso aprobará la planificación y el alumno procederá al
          pago para su manufactura.
        </LabelNormal>
        <ButtonPrime
          label="Aprobar caso"
          onClick={() => approvedTeacher({ variables: { patient_id: id } })}
        />
      </ModalWarning>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="casos" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <Title>Casos</Title>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                marginRight="33"
              />
              <DropdownCustom
                label="Filtros"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </InlineContainer>
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            {loading ? (
              <>
                <div style={{ display: 'flex', marginTop: 30 }}>
                  {Array.from(Array(4).keys()).map((_, index) => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={`skeleton-${index}`}
                    >
                      <Skeleton
                        height={159}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map((_, index) => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={`skeleton-1-${index}`}
                    >
                      <Skeleton
                        height={159}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map((_, index) => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={`skeleton-2-${index}`}
                    >
                      <Skeleton
                        height={159}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div
                className="row"
                style={
                  cases.length <= 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh',
                        paddingBottom: '24px',
                      }
                    : {
                        display: 'flex',
                        gap: '20px',
                        flex: 1,
                        maxWidth: '85%',
                        paddingBottom: '24px',
                      }
                }
              >
                {cases.length > 0 ? (
                  <>
                    {cases.map((item) => (
                      <div key={Math.random()}>
                        <TreatmentCardContainer
                          treatment={item}
                          viewer={UserRoleEnum.TEACHER}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div style={{}}>
                    <TitleWithoutContent>
                      Aún no hay <br /> casos registrados
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default YourCases;
