import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import { QUERY_GET_PATIENT_FILES } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import Input from '@components/shared/Input';
import History from '@components/shared/History';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import LabelCase from '../../../components/shared/LabelCase.js';
import {
  MUTATION_UPDATE_PATIENT,
  MUTATION_ARCHIVIED_TREATMENT,
  MUTATION_ADD_PHOTO_PATIENT,
  MUTATION_ADD_COMMENT,
  MUTATION_DAY_SMARTCHECK,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import { buildOdontogramObject, loadPhotoProfileGender } from '../../../utils';
import Skeleton from 'react-loading-skeleton';
import Card from '../../../components/shared/Card';
import InternalNotesCard from '@components/shared/InternalNotesCard';
import ResumeSS from '../../../components/DoctorFormSS/resume';
import ResumeCA from '../../../components/DoctorFormCA/resume';
import ExtraoralCard from '../../../components/History/ExtraoralCard';
import FileCard from '../../../components/History/FileCard';
import IntraoralCard from '../../../components/History/IntraoralCard';
import { HeaderCard } from '../../../components/shared/Styled';
import { UserRoleEnum } from '@models/user-role';
import {
  QUERY_GET_FORM_ARCADE,
  QUERY_GET_FORM_BRACKET,
  QUERY_GET_FORM_IPR,
  QUERY_GET_FORM_ODONTOGRAM,
  QUERY_GET_FORM_SHAPE,
  QUERY_GET_TREATMENT,
} from '@graphql/queries/admin/case-details';
import { SmartcheckEnum } from '@models/smartcheck';
import CaseDetailsContainer from '../tablero/caso/case-details.container';
import ContinueTreatmentCard from '@components/case-details/cards/continue-treatment';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const Case = (props) => {
  const history = useHistory();
  const { id } = useParams();
  // Full treatment info
  const [treatment, setTreatment] = useState();
  // Patient info
  const [patient, setPatient] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [photo, setPhoto] = useState('');
  // Form info
  const [formCAData, setFormCAData] = useState();
  const [formSSData, setFormSSData] = useState();

  const [edit, setEdit] = useState(false);

  const [historyClinic, setHistoryClinic] = useState(false);

  const [photoEvidences, setPhotoEvidences] = useState('');
  const [file, setFile] = useState({});

  const [selectItem, setSelectItem] = useState('Formulario');
  const [filesTreatment, setFilesTreatment] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [defaultAddress, setDefaultAddress] = useState({});

  const [getFormBracket] = useLazyQuery(QUERY_GET_FORM_BRACKET, {
    onCompleted: (data) => {
      if (data.formBracket) {
        setFormSSData({
          ...formSSData,
          brackets: data.formBracket,
        });
      }
    },
  });

  const [getFormOdotogram] = useLazyQuery(QUERY_GET_FORM_ODONTOGRAM, {
    onCompleted: (data) => {
      if (data.formOdontogram) {
        const { up_odontograma, lo_odontograma } = buildOdontogramObject(
          data.formOdontogram
        );
        if (treatment?.treatmentType.id == 1) {
          setFormCAData({
            ...formCAData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
        } else {
          setFormSSData({
            ...formSSData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
          getFormBracket({ variables: { treatment_id: treatment?.id } });
        }
      }
    },
  });

  const [getFormIpr] = useLazyQuery(QUERY_GET_FORM_IPR, {
    onCompleted: (data) => {
      if (data.formIpr) {
        setFormCAData({
          ...formCAData,
          ipr: data.formIpr,
        });
      }
      getFormOdotogram({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormShape] = useLazyQuery(QUERY_GET_FORM_SHAPE, {
    onCompleted: (data) => {
      if (data.formShape) {
        setFormCAData({
          ...formCAData,
          shape: data.formShape,
        });
      }
      getFormIpr({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormArcade] = useLazyQuery(QUERY_GET_FORM_ARCADE, {
    onCompleted: (data) => {
      if (data.formArcade) {
        setFormCAData({
          ...formCAData,
          arcad: data.formArcade,
        });
      }
      getFormShape({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;
        let decode = {};
        patientfiles.forEach((item) => {
          decode = {
            ...decode,
            [item.name]: {
              type: item.file_type,
              name: item.file_name,
              url: item.fileUrl,
            },
          };
        });
        setFilesTreatment(decode);
        if (decode.evidence)
          setPhotoEvidences(decode.evidence.url ? decode.evidence.url : '');
      }
    },
    onError: (err) => console.log(err),
  });

  const { loading: loadingSkeleton, refetch } = useQuery(QUERY_GET_TREATMENT, {
    variables: { id },
    pollInterval: 3000,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.treatment) {
        // Set treatment object
        setTreatment({
          ...data.treatment,
          treatmentStatus: {
            ...data.treatment.treatmentStatus,
            id: parseInt(data.treatment.treatmentStatus.id),
          },
          treatmentType: {
            ...data.treatment.treatmentType,
            id: parseInt(data.treatment.treatmentType.id),
          },
        });
        // Set patient object only if wasn't setted
        // if (!patient) {
        setPatient(data.treatment.patient);
        // }
        // Set form CA data if wasn't setted and treatment type is Cryst Aligner
        if (!formCAData && data.treatment.treatmentType.id == 1) {
          setFormCAData({
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormArcade({ variables: { treatment_id: data.treatment.id } });
        }
        // Set form SS data if wasn't setted and treatment type is Smile System
        if (!formSSData && data.treatment.treatmentType.id == 2) {
          setFormSSData({
            bracket_ss: data.treatment.bracket_ss,
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormOdotogram({ variables: { treatment_id: data.treatment.id } });
        }
        if (Object.keys(filesTreatment).length === 0) {
          // Call to patient files query
          getPatientFiles({
            variables: {
              patient_id: data.treatment.patient.id,
              onlyErrors: false,
            },
          });
        }
        const lastSmartcheck =
          data.treatment.smartChecks
            ?.filter(
              (smartChecks) => smartChecks.status !== SmartcheckEnum.NotAssist
            )
            .sort((a, b) => a.id - b.id)[0] ?? null;
        if (
          data.treatment.treatmentStatus.type === 'planning' &&
          data.treatment.treatmentStatus.index !== 7 &&
          lastSmartcheck.status === 'Pending'
        ) {
          changeDaySmartcheck({
            variables: { patient_id: data.treatment.patient.id },
          });
        }
      }
    },
  });

  useEffect(() => {
    if (patient) {
      setFirstName(patient.first_name);
      setLastName(patient.last_name);
      setPhone(patient.phone);
      setGender(patient.gender);
      setPhoto(
        patient.photo_filename
          ? `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
          : ''
      );
      setEmail(patient.email);

      if (patient.birthdate) {
        setDay(patient.birthdate.split('-')[2]);
        setMonth(patient.birthdate.split('-')[1]);
        setYear(patient.birthdate.split('-')[0]);
      }
    }
  }, [patient]);

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: () => {
      if (file.name) addProfilePhoto({ variables: file, patient_id: +id });
      else {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setEdit(false);
                refetch();
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_PHOTO_PATIENT, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setEdit(false);
              refetch();
            })
          }
        >
          <LabelNormal color="#060809" fontSize="22" style={{ marginTop: 30 }}>
            Cambios a perfil guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [addComment] = useMutation(MUTATION_ADD_COMMENT, {
    onCompleted: () => {
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => refetch())}>
          <LabelNormal
            fontSize="22"
            color="#060809"
            style={{ paddingTop: 26, fontWeight: 500, margin: 0 }}
          >
            Comentario añadido
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#4F4F4F"
            style={{ paddingTop: 14, marginBottom: 15 }}
          >
            Comentario agregado correctamente al tratamiento
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const [changeDaySmartcheck] = useMutation(MUTATION_DAY_SMARTCHECK, {
    onCompleted: (data) => {
      if (data.daySmartcheck) {
        refetch();
      }
    },
  });

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const maskPhone = (event) => {
    let val = event.target.value;
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
      6,
      val.length
    )}`;
    num = num.trim();
    setPhone(num);
  };

  const handleOpenModal = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Mover a casos inactivos pausarí¡ el tratamiento
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          El doctor ya no podra realizar acciones en el caso. Puedes consultar
          tus casos inactivos en el tablero de tus casos y filtrar por status a
          casos inactivos.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            archivedTreatment({ variables: { patient_id: id, archived: true } })
          }
          label="Mover a casos inactivos"
        />
      </ModalWarning>
    );
  };

  const [archivedTreatment] = useMutation(MUTATION_ARCHIVIED_TREATMENT, {
    onCompleted: (data) => {
      if (data.changeArchivedTreatment) {
        modal.close();
        refetch();
      }
    },
    onError: (err) => console.log(err),
  });

  const backHistoryClinic = () => {
    setHistoryClinic(false);
  };

  const parentScroll = useRef(null);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() =>
                historyClinic ? backHistoryClinic() : history.goBack()
              }
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {!edit && !historyClinic && (
                <div className="col-md-12">
                  <Title>Caso</Title>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.goBack()}
                      mainLabel={'Tableros'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                </div>
              )}
              {edit && (
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <Title>Editar perfil</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Edita los datos y guarda los cambios.
                  </LabelNormal>
                </div>
              )}
              {historyClinic && (
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                  <Title>Historial clí­nico</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Consulta los archivos de tu paciente.
                  </LabelNormal>
                </div>
              )}
              <div className={historyClinic ? 'col-md-12' : 'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    {photoEvidences !== '' ? (
                      <>
                        <InlineContainer
                          style={{ alignItems: 'center', marginBottom: 10 }}
                        >
                          {!loadingSkeleton ? (
                            <>
                              <Avatar
                                src={
                                  photo ? photo : loadPhotoProfileGender(gender)
                                }
                                floatButton={edit}
                                edit={!!photo}
                                onChange={onChange}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                              <FeatherIcon
                                icon="arrow-right"
                                size={45}
                                style={{ marginLeft: 40, marginRight: 40 }}
                              />
                              <Avatar
                                src={
                                  photoEvidences
                                    ? photoEvidences
                                    : loadPhotoProfileGender(gender)
                                }
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                            </>
                          ) : (
                            <div>
                              <Skeleton
                                height={161}
                                borderRadius={27}
                                width={161}
                                baseColor={'#EEEEF2'}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          )}
                        </InlineContainer>
                        <InlineContainer>
                          {!loadingSkeleton ? (
                            <>
                              {!edit && !historyClinic && (
                                <div style={{ marginLeft: 35 }}>
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <LabelNormal
                                      color="#436FF1"
                                      fontSize="21"
                                      style={{ marginRight: 7 }}
                                    >
                                      PX.
                                    </LabelNormal>
                                    <LabelNormal
                                      fontSize="30"
                                      color="#3C4549"
                                    >{`${firstName} ${lastName}`}</LabelNormal>
                                  </InlineContainer>
                                  <InlineContainer>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <InlineContainer>
                                          <LabelNormal
                                            color="#4F4F4F"
                                            fontSize="20"
                                          >
                                            ID
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#BDBDBD"
                                            fontSize="20"
                                          >
                                            {id}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      <div className="col-md-8">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="phone" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {phone}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      {email && (
                                        <div className="col-md-7">
                                          <InlineContainer
                                            style={{ alignItems: 'center' }}
                                          >
                                            <FeatherIcon
                                              icon="mail"
                                              size={19}
                                            />
                                            <LabelNormal
                                              fontSize="14"
                                              color="#3C4549"
                                            >
                                              {email}
                                            </LabelNormal>
                                          </InlineContainer>
                                        </div>
                                      )}
                                    </div>
                                  </InlineContainer>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: 35,
                                marginTop: 10,
                                width: '100%',
                              }}
                            >
                              <div>
                                <Skeleton
                                  baseColor={'#F6F6F8'}
                                  width={'70%'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-7">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {historyClinic && (
                            <div style={{ marginLeft: 35 }}>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  fontSize="30"
                                  color="#3C4549"
                                >{`${firstName} ${lastName}`}</LabelNormal>
                              </InlineContainer>
                              <InlineContainer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginBottom: 10,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#828282"
                                        fontSize="13"
                                      >
                                        Tratamiento
                                      </LabelNormal>
                                      <LabelNormal
                                        color={
                                          treatment?.treatmentType.id == 1
                                            ? '#18A1DC'
                                            : '#F0BB00'
                                        }
                                        fontSize="20.45"
                                      >
                                        {treatment?.treatmentType.id == 1
                                          ? 'Cryst Aligner'
                                          : 'Smile System'}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  className="col-md-8"
                                  style={{ paddingLeft: 0 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="user" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {gender}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-8">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="phone" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {phone}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  className="col-md-8"
                                  style={{ marginTop: 8, paddingLeft: 0 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="gift" />
                                    <LabelNormal
                                      fontSize="14"
                                      color="#3C4549"
                                    >{`${year}-${month}-${day}`}</LabelNormal>
                                  </InlineContainer>
                                </div>
                                {email && (
                                  <div
                                    className="col-md-8"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <img
                                        src={require('@images/icons/email.png')}
                                        style={{ height: 16, width: 15.5 }}
                                        alt="email-icon"
                                      />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {email}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                )}
                              </InlineContainer>
                            </div>
                          )}
                        </InlineContainer>
                      </>
                    ) : (
                      <InlineContainer>
                        {!loadingSkeleton ? (
                          <div>
                            <Avatar
                              src={
                                photo ? photo : loadPhotoProfileGender(gender)
                              }
                              floatButton={edit}
                              edit={!!photo}
                              onChange={onChange}
                              height="161"
                              width="161"
                              borderRadius="30"
                            />
                          </div>
                        ) : (
                          <div>
                            <Skeleton
                              height={161}
                              borderRadius={27}
                              width={161}
                              baseColor={'#EEEEF2'}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        )}
                        {!loadingSkeleton ? (
                          <>
                            {!edit && !historyClinic && (
                              <div style={{ marginLeft: 35 }}>
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 8,
                                  }}
                                >
                                  <LabelNormal
                                    color="#436FF1"
                                    fontSize="21"
                                    style={{
                                      marginRight: 7,
                                      fontFamily: 'neue-montreal-medium',
                                      margin: '0px 12px 0px 0px',
                                    }}
                                  >
                                    PX
                                  </LabelNormal>
                                  <LabelNormal
                                    fontSize="22"
                                    color="#3C4549"
                                    style={{
                                      fontFamily: 'neue-montreal-medium',
                                      margin: 0,
                                    }}
                                  >{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                                <InlineContainer>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ marginBottom: 8 }}
                                      >
                                        <LabelNormal
                                          color="#4F4F4F"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: '0px 12px 0px 0px',
                                          }}
                                        >
                                          ID
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#BDBDBD"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: 0,
                                            letterSpacing: '0.11em',
                                          }}
                                        >
                                          {id}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    <div className="col-md-8">
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <FeatherIcon
                                          icon="phone"
                                          color="#757575"
                                          size={19}
                                        />
                                        <LabelNormal
                                          fontSize="17"
                                          color="#3C4549"
                                          style={{ margin: '0px 8px' }}
                                        >
                                          {phone}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    {email && (
                                      <div className="col-md-7">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="mail" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {email}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                    )}
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 35,
                              marginTop: 10,
                              width: '100%',
                            }}
                          >
                            <div>
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                width={'70%'}
                                height={27}
                                borderRadius={5}
                                highlightColor={'#FFF'}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <div className="row">
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-7">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {historyClinic && (
                          <div style={{ marginLeft: 35 }}>
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <LabelNormal
                                fontSize="30"
                                color="#3C4549"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <div className="row">
                                <div className="col-md-12">
                                  <InlineContainer
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <LabelNormal color="#828282" fontSize="13">
                                      Tratamiento
                                    </LabelNormal>
                                    <LabelNormal
                                      color={
                                        treatment?.treatmentType.id == 1
                                          ? '#18A1DC'
                                          : '#F0BB00'
                                      }
                                      fontSize="20.45"
                                    >
                                      {treatment?.treatmentType.id == 1
                                        ? 'Cryst Aligner'
                                        : 'Smile System'}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              </div>
                            </InlineContainer>
                            <InlineContainer>
                              <div
                                className="col-md-8"
                                style={{ paddingLeft: 0 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="user" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {gender}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-8">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="phone" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            </InlineContainer>
                            <InlineContainer>
                              <div
                                className="col-md-8"
                                style={{ marginTop: 8, paddingLeft: 0 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="gift" />
                                  <LabelNormal
                                    fontSize="14"
                                    color="#3C4549"
                                  >{`${year}-${month}-${day}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              {email && (
                                <div
                                  className="col-md-8"
                                  style={{ marginTop: 8 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <img
                                      src={require('@images/icons/email.png')}
                                      style={{ height: 16, width: 15.5 }}
                                      alt="email-icon"
                                    />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {email}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              )}
                            </InlineContainer>
                          </div>
                        )}
                      </InlineContainer>
                    )}
                  </div>
                  {!edit && !historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: 47 }}
                      ref={parentScroll}
                    >
                      <Card
                        padding="0px"
                        border="none"
                        minWidth="572px"
                        borderRadius="30px"
                      >
                        <HeaderCard>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="17"
                            style={{
                              fontFamily: 'neue-montreal-medium',
                              margin: 0,
                            }}
                          >
                            Historial
                          </LabelNormal>
                        </HeaderCard>
                        {!loadingSkeleton ? (
                          <CardContainer>
                            <div style={{ textAlign: 'center' }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="12"
                                style={{ textTransform: 'uppercase' }}
                              >
                                Paso actual
                              </LabelNormal>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <History
                                  histories={treatment?.histories ?? []}
                                  type="admin"
                                ></History>
                              </div>
                            </div>
                          </CardContainer>
                        ) : (
                          <>
                            <CardContainer style={{ minHeight: 500 }}>
                              <center>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={16}
                                  width={'20%'}
                                  highlightColor={'#FFF'}
                                />
                              </center>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 33,
                                  border: '3px solid #EEEEF2',
                                  borderRadius: 10,
                                  padding: 10,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </CardContainer>
                          </>
                        )}
                      </Card>
                    </div>
                  )}
                  {edit && (
                    <div className="col-md-12" style={{ marginTop: 17 }}>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            style={{ marginTop: 38 }}
                            className="grid-layout"
                          >
                            <InlineContainer>
                              <div>
                                <Input
                                  placeholder="Nombre"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  label="Nombre del paciente"
                                />
                              </div>
                              <div>
                                <span
                                  style={{ fontSize: 18, visibility: 'hidden' }}
                                >
                                  none
                                </span>
                                <Input
                                  placeholder="Apellidos"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 20 }}
                          >
                            <LabelNormal color="#828282" fontSize="13">
                              Tratamiento
                            </LabelNormal>
                            <LabelNormal
                              color={
                                treatment?.treatmentType.id == 1
                                  ? '#18A1DC'
                                  : '#F0BB00'
                              }
                              fontSize="20.45"
                            >
                              {treatment?.treatmentType.id == 1
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        <div className="col-md-7">
                          <div>
                            <LabelInput>Sexo</LabelInput>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="gender-1"
                                  onClick={() => setGender('Masculino')}
                                  checked={
                                    gender === 'Masculino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-1"
                                  className="radio-label"
                                >
                                  Hombre
                                </label>
                              </div>
                              <div className="radio">
                                <input
                                  id="gender-2"
                                  onClick={() => setGender('Femenino')}
                                  checked={
                                    gender === 'Femenino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-2"
                                  className="radio-label"
                                >
                                  Mujer
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 28 }}>
                          <LabelInput>Fecha de nacimiento</LabelInput>
                          <InlineContainer>
                            <Input
                              placeholder="DD"
                              maxWidth="60px"
                              value={day}
                              onChange={(e) => setDay(e.target.value)}
                            />
                            <Input
                              placeholder="MM"
                              maxWidth="60px"
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                            />
                            <Input
                              placeholder="AAAA"
                              maxWidth="90px"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </InlineContainer>
                        </div>
                        <div className="col-md-7" style={{ marginTop: 28 }}>
                          <div>
                            <LabelInput>Telí©fono</LabelInput>
                            <InlineContainer>
                              <Input
                                readOnly
                                disabled
                                value={'+52'}
                                backgroundColor="#F6F6F8"
                                maxWidth="70px"
                              />
                              <Input
                                placeholder="Telí©fono"
                                value={phone}
                                type="int"
                                oonChange={maskPhone}
                              />
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-5" style={{ marginTop: 28 }}>
                          <div className="grid-layout">
                            <Input
                              placeholder="Tu correo"
                              label="Email (Opcional)"
                              value={email}
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              maxWidth="300px"
                            />
                          </div>
                        </div>
                      </div>
                      <ButtonPrime
                        label="Guardar cambios"
                        disabled={false}
                        onClick={() =>
                          updatePatient({
                            variables: {
                              patient_id: id,
                              first_name: firstName,
                              last_name: lastName,
                              email: email === null ? '' : email,
                              phone,
                              gender,
                              birthdate: `${year}-${month}-${day}`,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: historyClinic ? 60 : 0 }}
                    >
                      <InlineContainer
                        style={{
                          background: '#F6F6F8',
                          borderRadius: 40,
                          paddingTop: 30,
                          paddingLeft: 20,
                          paddingBottom: 30,
                          alignItems: 'self-start',
                          boxShadow: '0px 0px 8px rgba(162, 162, 162, 0.1)',
                        }}
                      >
                        <div
                          style={{
                            marginRight: 40,
                            width: 168,
                            position: 'sticky',
                            top: '50px',
                          }}
                        >
                          <div>
                            {[
                              'Formulario',
                              'Intraorales',
                              'Extraorales',
                              "STL's",
                              'Radiografias',
                            ].map((row) => (
                              <div
                                style={{
                                  maxWidth: 165,
                                  maxHeight: 55,
                                  background:
                                    selectItem === row ? '#FFF' : 'transparent',
                                  borderBottomColor:
                                    selectItem === row
                                      ? 'transparent'
                                      : '#D4D4D4',
                                  borderBottomStyle: 'solid',
                                  borderBottomWidth: 1,
                                  padding: 15,
                                  borderRadius: selectItem !== row ? 0 : 10,
                                }}
                                key={Math.random()}
                              >
                                <LabelNormal
                                  color="#000000"
                                  fontSize="18.5"
                                  onClick={() => setSelectItem(row)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {row}
                                </LabelNormal>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div ref={parentScroll} style={{ width: '100%' }}>
                          {selectItem === 'Formulario' && (
                            <div className="row">
                              <div className="col-md-12">
                                {formSSData?.brackets &&
                                  treatment?.treatmentType.id == 2 && (
                                    <ResumeSS
                                      formSSData={formSSData ?? {}}
                                      chatMessages={[]}
                                    />
                                  )}
                                {formCAData?.arcad &&
                                  treatment?.treatmentType.id == 1 && (
                                    <ResumeCA
                                      formCAData={formCAData ?? {}}
                                      chatMessages={[]}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Radiografias' && (
                            <div className="row">
                              <div className="col-md-7">
                                <InlineContainer style={{ gap: '24px' }}>
                                  <IntraoralCard
                                    image={filesTreatment['lateral']}
                                    alt="lateral"
                                    name="Lateral"
                                  />
                                  <IntraoralCard
                                    image={filesTreatment['panoramica']}
                                    alt="panoramica"
                                    name="Panorámica"
                                  />
                                  {filesTreatment['trazado_cefalometrico'] && (
                                    <IntraoralCard
                                      image={
                                        filesTreatment['trazado_cefalometrico']
                                      }
                                      alt="trazado_cefalometrico"
                                      name="Trazado cefalométrico"
                                    />
                                  )}
                                  {filesTreatment['tomografia'] && (
                                    <IntraoralCard
                                      image={filesTreatment['tomografia']}
                                      alt="tomografia"
                                      name="Tomografía"
                                    />
                                  )}
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === "STL's" && (
                            <div className="row">
                              <div className="col-8">
                                {treatment?.stl_type === 'shipping' && (
                                  <div>
                                    <LabelNormal color="#3C4549" fontSize="17">
                                      Se seleccionó envío de modelos por
                                      paquetería a la siguiente dirección:
                                    </LabelNormal>
                                    <InlineContainer>
                                      <CardModel style={{ maxWidth: 356 }}>
                                        <LabelNormal
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            marginRight: 7,
                                            marginBottom: 10,
                                          }}
                                          color="#757575"
                                          fontSize="18"
                                        >
                                          Dirección de envío | Borgatta
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#828282"
                                          style={{ margin: 0, padding: 0 }}
                                          fontSize="17"
                                        >
                                          {defaultAddress.id
                                            ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code}, ${defaultAddress.state}`
                                            : ''}
                                        </LabelNormal>
                                      </CardModel>
                                    </InlineContainer>
                                  </div>
                                )}
                                {treatment?.stl_type === 'url' && (
                                  <InlineContainer>
                                    <LabelNormal color="#3C4549" fontSize="20">
                                      {treatment?.visor_url}
                                    </LabelNormal>
                                  </InlineContainer>
                                )}
                                {treatment?.stl_type !== 'url' &&
                                  treatment?.stl_type !== 'shipping' && (
                                    <InlineContainer
                                      style={{ flexWrap: 'wrap' }}
                                    >
                                      <FileCard
                                        stl={filesTreatment.arcada_superior}
                                        name="Arcada superior"
                                      />
                                      <FileCard
                                        stl={filesTreatment.oclusion_derecha}
                                        name="Oclusión"
                                      />
                                      {filesTreatment.oclusion_izquierda && (
                                        <FileCard
                                          stl={
                                            filesTreatment.oclusion_izquierda
                                          }
                                          name="Oclusión izquierda"
                                        />
                                      )}
                                      <FileCard
                                        stl={filesTreatment.arcada_inferior}
                                        name="Arcada inferior"
                                      />
                                    </InlineContainer>
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Extraorales' && (
                            <div className="row">
                              <div
                                className="col-8"
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '32px',
                                  justifyContent: 'center',
                                }}
                              >
                                <ExtraoralCard
                                  image={filesTreatment['frontal_serio']}
                                  alt="frontal_serio"
                                  name="Frontal serio"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['frontal_sonriendo']}
                                  alt="frontal_sonriendo"
                                  name="Frontal sonriendo"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['frontal_retractores']}
                                  alt="frontal_retractores"
                                  name="Frontal con retractores"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['lateral_izq_serio']}
                                  alt="lateral_izq_serio"
                                  name="Lateral izquierda"
                                />
                                <ExtraoralCard
                                  image={filesTreatment['lateral_der_serio']}
                                  alt="lateral_der_serio"
                                  name="Lateral derecha"
                                />
                              </div>
                            </div>
                          )}
                          {selectItem === 'Intraorales' && (
                            <div
                              className="col-9"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                              }}
                            >
                              <InlineContainer
                                style={{ justifyContent: 'space-between' }}
                              >
                                <IntraoralCard
                                  image={filesTreatment['oclusal_superior']}
                                  alt="oclusal_superior"
                                  name="Oclusal superior"
                                />
                                <IntraoralCard
                                  image={filesTreatment['oclusal_inferior']}
                                  alt="oclusal_inferior"
                                  name="Oclusal inferior"
                                />
                              </InlineContainer>
                              <InlineContainer
                                style={{ justifyContent: 'space-between' }}
                              >
                                <IntraoralCard
                                  image={filesTreatment['lat_der_oclusion']}
                                  alt="lat_der_oclusion"
                                  name="Lateral derecha en oclusión"
                                />
                                <IntraoralCard
                                  image={filesTreatment['frontal_oclusion']}
                                  alt="frontal_oclusion"
                                  name="Frontal en oclusión"
                                />
                                <IntraoralCard
                                  image={filesTreatment['lat_izq_oclusion']}
                                  alt="lat_izq_oclusion"
                                  name="Lateral izquierda en oclusión"
                                />
                              </InlineContainer>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!historyClinic && (
                  <div className="row w-[340px] flex flex-col gap-6">
                    {treatment && (
                      <div className="flex flex-col gap-4 w-full">
                        <CaseDetailsContainer
                          viewer={UserRoleEnum.ADMIN}
                          treatment={treatment}
                          refresh={refetch}
                        />
                        <ContinueTreatmentCard
                          treatment={treatment}
                          viewer={UserRoleEnum.ADMIN}
                        />
                      </div>
                    )}
                    <div className="flex flex-col w-full shadow-md rounded-2xl overflow-hidden bg-white">
                      {!loadingSkeleton ? (
                        <Card
                          padding="0px"
                          border="none"
                          minWidth="340px"
                          borderRadius="30px"
                        >
                          <CardContainer style={{ borderRadius: 25 }}>
                            <LabelCase
                              onClick={() =>
                                history.push({
                                  pathname: `/app/doctores/perfil-doctor/${treatment?.doctor.id}`,
                                })
                              }
                              label={'Datos del doctor'}
                              icon={'user'}
                              description={true}
                              descriptionText={'Contacto, Facturación, Enví­o'}
                            />
                            <LabelCase
                              onClick={() => setHistoryClinic(!historyClinic)}
                              label={'Archivos del paciente'}
                              icon={'folder'}
                              description={true}
                              descriptionText={
                                'Fotografí­as, STLs, Radiografí­as'
                              }
                              marginTop={'30'}
                            />
                            <LabelCase
                              onClick={() =>
                                history.push({
                                  pathname: `/app/tablero/soporte/${patient?.id}/${treatment?.id}`,
                                })
                              }
                              label={'Escribir al doctor'}
                              icon={'message-circle'}
                              marginTop={'30'}
                            />
                            <LabelCase
                              onClick={() =>
                                history.push({
                                  pathname: `/app/tablero/caso/revision-planificacion/${id}`,
                                  state: {
                                    treatmentId: treatment?.id,
                                    firstName,
                                    lastName,
                                    treatment: treatment?.patient.treatment,
                                    smart:
                                      props.location.state &&
                                      props.location.state.smart
                                        ? props.location.state.smart
                                        : false,
                                    visorReview: true,
                                  },
                                })
                              }
                              label={
                                treatment?.treatmentType.id == 1
                                  ? 'Revisión de visor'
                                  : 'Datos de planificación'
                              }
                              icon={
                                treatment?.treatmentType.id == 1
                                  ? 'message-square'
                                  : 'clipboard-notes'
                              }
                              marginTop={'30'}
                            />
                            {treatment?.manufact_stl_filename !== '' && (
                              <LabelCase
                                onClick={() =>
                                  history.push({
                                    pathname: `/app/tablero/caso/stl-manufactura/${id}`,
                                  })
                                }
                                label={'STLs para manufactura'}
                                icon={'folder'}
                                marginTop={'30'}
                              />
                            )}
                            {treatment?.treatmentStatus.type !== 'new_case' &&
                              treatment?.archived === false && (
                                <LabelCase
                                  onClick={() => handleOpenModal()}
                                  label={'Mover a casos inactivos'}
                                  icon={'archive'}
                                  marginTop={'30'}
                                />
                              )}
                            {(treatment?.treatmentStatus.type === 'new_case' ||
                              treatment?.archived === true) && (
                              <LabelCase disabled />
                            )}
                          </CardContainer>
                        </Card>
                      ) : (
                        <CardContainer style={{ borderRadius: 25 }}>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="row"
                                style={{ alignItems: 'flex-start' }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardContainer>
                      )}
                    </div>
                    {!loadingSkeleton && (
                      <div className="flex flex-col w-full shadow-md rounded-2xl overflow-hidden bg-white">
                        <InternalNotesCard
                          comments={treatment?.comments ?? []}
                          treatmentId={treatment?.id}
                          addComment={addComment}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
