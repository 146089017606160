import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelNormal = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  margin-top: 10px;
`;

const CalendlyWidget = styled.div`
  margin-top: -2em;
  display: flex;
  justifycontent: center;
  width: 900px;
  height: 700px;

  iframe {
    border: none;
  }
`;

const Benefits = (props) => {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="asesorias" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Agenda tu asesoría Cryst Aligner</Title>
                <LabelNormal>
                  Agenda tus asesorías con nuestro equipo de capacitación, elige
                  el horario que mejor se adapte a tu calendario.
                </LabelNormal>
              </div>
              {/* <CalendlyWidget className='col-md-12'>
                <iframe 
                  title='Asesorias' 
                  src="https://calendly.com/crystalignerasesorias/30min?embed_domain=xxx.es&amp;embed_type=Inline&amp;primary_color=3db0bf"  
                  width='100%'
                  height='100%'
                >
                </iframe>
              </CalendlyWidget> */}
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Benefits;
