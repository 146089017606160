import React from 'react';
import styled from 'styled-components';
import { modal } from '@components/ModalManagement';
import X from '@images/icons/fi_x.svg';

const Image = styled.img`
  height: 60px;
  width: 60px;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const ButtonClose = styled.button`
  position: absolute;
  right: 18px;
  top: 17px;
  border-radius: 50px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 4px 0px 0px 2px;
  cursor: pointer;
  border: none;
  background: #f6f6f8;
`;

const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;

const ModalWaiting = (props) => {
  return (
    <div className="modal">
      <Inline>
        <BorderLoader className="text-center">
          <div class="loader-inner line-spin-fade-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </BorderLoader>
      </Inline>
      <div>{props.children}</div>
    </div>
  );
};

export default ModalWaiting;
