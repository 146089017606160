import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Support = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  useEffect(() => {
    setLoadingSkeleton(true);
    const timeout = setTimeout(() => {
      setLoadingSkeleton(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="soporte" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Soporte</Title>
              </div>
              <div className="col-md-10" style={{ marginBottom: 10 }}>
                <LabelNormal color="#757575" fontSize="17">
                  Contáctanos y te atenderemos en nuestros horarios de atención
                  al cliente.
                </LabelNormal>
              </div>
              <div className="col-md-3" style={{ marginTop: 40 }}>
                {!loadingSkeleton ? (
                  <InlineContainer
                    style={{
                      background: '#FFF',
                      borderRadius: 40,
                      padding: '14px 17px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      window.open('mailto:soporte@b360.mx', '_blank')
                    }
                  >
                    <FeatherIcon icon="mail" size="22" color="#757575" />
                    <LabelNormal
                      style={{ marginLeft: 12 }}
                      fontSize="17"
                      color="#3C4549"
                    >
                      soporte@b360.mx
                    </LabelNormal>
                  </InlineContainer>
                ) : (
                  <Skeleton
                    height={44}
                    borderRadius={48}
                    width={218}
                    baseColor={'#F6F6F8'}
                    highlightColor={'#FFF'}
                  />
                )}
              </div>
              <div className="col-md-10" style={{ marginTop: 30 }}>
                {!loadingSkeleton ? (
                  <>
                    <LabelNormal fontSize="17" color="#757575">
                      Horarios de atención
                    </LabelNormal>
                    <InlineContainer
                      style={{ marginTop: 10, alignItems: 'center' }}
                    >
                      <FeatherIcon icon="clock" size="22" color="#757575" />
                      <LabelNormal fontSize="17" color="#3C4549">
                        09:00am a 6:00pm
                      </LabelNormal>
                    </InlineContainer>
                  </>
                ) : (
                  <Skeleton
                    height={57}
                    borderRadius={11}
                    width={179}
                    baseColor={'#F6F6F8'}
                    highlightColor={'#FFF'}
                  />
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Support;
