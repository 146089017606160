import React from 'react';
import { ButtonSecondary } from '@acueductostudio/borgatta-components';

import { InlineContainer, LabelInput } from '../../../shared/Styled';
import Input from '@components/shared/Input';

/**
 * @typedef {{
 * countryName,
 * day,
 * email,
 * firstName,
 * gender,
 * lastName,
 * month,
 * phone,
 * onClickNewCaseNextStep: () => void,
 * setDay,
 * setEmail,
 * setFirstName,
 * setGender,
 * setLastName,
 * setMonth,
 * setPhone,
 * setYear,
 * validDate,
 * validGender,
 * validLastName,
 * validName,
 * year
 * }} RegisterNewCaseProps
 * @param {RegisterNewCaseProps} props
 * @returns
 */
export const RegisterNewCase = ({
  countryName,
  day,
  email,
  firstName,
  gender,
  lastName,
  month,
  onClickNewCaseNextStep,
  phone,
  setDay,
  setEmail,
  setFirstName,
  setGender,
  setLastName,
  setMonth,
  setPhone,
  setYear,
  validDate,
  validGender,
  validLastName,
  validName,
  year,
}) => {
  return (
    <>
      <div className="flex flex-col">
        <InlineContainer style={{ alignItems: 'flex-end' }}>
          <div>
            <Input
              placeholder="Nombre(s)"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="Nombre del paciente"
              type="custom"
              validation={validName}
            />
            {!validName && (
              <InlineContainer>
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce el nombre(s)
                </p>
              </InlineContainer>
            )}
          </div>
          <div>
            <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
            <Input
              placeholder="Apellidos"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="custom"
              validation={validLastName}
            />
            {!validLastName && (
              <InlineContainer>
                <p
                  style={{
                    color: '#C60E02',
                    fontSize: 18,
                    margin: 0,
                    padding: 0,
                    marginTop: 5,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Introduce los apellidos
                </p>
              </InlineContainer>
            )}
          </div>
        </InlineContainer>
      </div>
      <div className="flex flex-col">
        <LabelInput>Sexo</LabelInput>
        <InlineContainer>
          <div className="radio" style={{ marginRight: 20, width: 150 }}>
            <input
              id="gender-1"
              onClick={(e) => setGender('Masculino')}
              checked={gender === 'Masculino' ? 'checked' : false}
              name="gender"
              type="radio"
              onChange={() => {}}
            />
            <label htmlFor="gender-1" className="radio-label">
              Hombre
            </label>
          </div>
          <div className="radio" style={{ marginRight: 20, width: 150 }}>
            <input
              id="gender-2"
              onClick={(e) => setGender('Femenino')}
              checked={gender === 'Femenino' ? 'checked' : false}
              name="gender"
              type="radio"
              onChange={() => {}}
            />
            <label htmlFor="gender-2" className="radio-label">
              Mujer
            </label>
          </div>
        </InlineContainer>
        {!validGender && (
          <InlineContainer>
            <p
              style={{
                color: '#C60E02',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Indica el sexo
            </p>
          </InlineContainer>
        )}
      </div>
      <div className="flex flex-col">
        <LabelInput>Fecha de nacimiento</LabelInput>
        <InlineContainer>
          <Input
            placeholder="DD"
            maxWidth="60px"
            value={day}
            onChange={setDay}
            mask="date2"
            type="day"
            validDate={validDate}
          />
          <Input
            placeholder="MM"
            maxWidth="60px"
            value={month}
            onChange={setMonth}
            mask="date2"
            type="monthInput"
            validDate={validDate}
          />
          <Input
            placeholder="AAAA"
            maxWidth="90px"
            value={year}
            onChange={setYear}
            mask="date4"
            validDate={validDate}
          />
        </InlineContainer>
        {!validDate && (
          <InlineContainer>
            <p
              style={{
                color: '#C60E02',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              {(!validDate && day === '') || month === '' || year === ''
                ? '*Introduce tu fecha de nacimiento'
                : '*Fecha incorrecta'}
            </p>
          </InlineContainer>
        )}
      </div>
      <div className="flex flex-col">
        <LabelInput>Teléfono (Opcional)</LabelInput>
        <InlineContainer>
          <Input
            readOnly
            disabled
            value={
              countryName !== null
                ? countryName === 'México'
                  ? '+52 '
                  : countryName === 'Panamá'
                  ? '+507 '
                  : '+502 '
                : ''
            }
            backgroundColor="#F6F6F8"
            maxWidth={countryName === 'México' ? '68px' : '75px'}
          />
          <Input
            placeholder="Teléfono"
            value={phone}
            onChange={setPhone}
            mask="phone"
            validation="phone"
          />
        </InlineContainer>
      </div>
      <div className="flex flex-col">
        <Input
          placeholder="Tu correo"
          label="Email (Opcional)"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          maxWidth="300px"
        />
      </div>
      <div className="flex w-6-column">
        <ButtonSecondary onClick={onClickNewCaseNextStep}>
          Siguiente
        </ButtonSecondary>
      </div>
    </>
  );
};
