import React, { useState } from 'react';
import styled from 'styled-components';
import RealoadIcon from '@images/reaload-icon.svg';

///toda la celda background de cada punto

const Map = (props) => {
  const [positionFocus, setPositionFocus] = useState([null, null]);
  const [erros, setErrors] = useState(
    props.containerNumber2
      ? [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0,
        ]
      : [
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
          '0,0',
        ],
  );

  const isInteger = (value) => {
    return value.match(/^[0-9]*\.?[0-9]*$/);
  };

  const topNumeration = (num, reverse = false, numeration = []) => {
    if (props.containerNumber2) {
      return Array.from(Array(8).keys()).map((_, index) => (
        <div style={{ width: props.width ? props.width : 50 }}>
          <div style={{ width: '100%' }}>
            {props.topData[index + num].split(',')[0] > 0 &&
              !props.disabled && (
                <div
                  onClick={() => removeData(0, index + num, 1)}
                  style={{
                    marginLeft: 3,
                    marginTop: -32,
                    marginBottom: 10,
                    cursor: 'pointer',
                    background: '#F6F6F8',
                    textAlign: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 23,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={RealoadIcon} />
                </div>
              )}
            {props.topData[index + num].split(',')[1] > 0 &&
              !props.disabled && (
                <div
                  onClick={() => removeData(0, index + num, 2)}
                  style={{
                    marginLeft: 30,
                    marginTop: -33,
                    marginBottom: 10,
                    cursor: 'pointer',
                    background: '#F6F6F8',
                    textAlign: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 23,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={RealoadIcon} />
                </div>
              )}
          </div>
          <LabelNormal
            style={{ textAlign: 'center' }}
            color="#C2C2C2"
            fontSize="17"
          >
            {numeration[index]}
          </LabelNormal>
          {num === 8 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <LabelNormal color="#9E9E9E" fontSize="13">
                M
              </LabelNormal>
              <LabelNormal fontSize="13" color="#9E9E9E">
                D
              </LabelNormal>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <LabelNormal fontSize="13" color="#9E9E9E">
                D
              </LabelNormal>
              <LabelNormal color="#9E9E9E" fontSize="13">
                M
              </LabelNormal>
            </div>
          )}
        </div>
      ));
    } else {
      return Array.from(Array(8).keys()).map((_, index) => (
        <div style={{ width: props.width ? props.width : 50 }}>
          {props.topData[index + num] > 0 && !props.disabled && (
            <div
              onClick={() => removeData(0, index + num)}
              style={{
                marginLeft: 12,
                marginTop: -32,
                marginBottom: 10,
                cursor: 'pointer',
                background: '#F6F6F8',
                textAlign: 'center',
                width: 24,
                height: 24,
                borderRadius: 23,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={RealoadIcon} />
            </div>
          )}
          <LabelNormal
            key={Math.random()}
            style={{ textAlign: 'center' }}
            color="#C2C2C2"
            fontSize="17"
          >
            {numeration[index]}
          </LabelNormal>
        </div>
      ));
    }
  };

  const bottomNumeration = (num, reverse = false, numeration = []) => {
    if (props.containerNumber2) {
      return Array.from(Array(8).keys()).map((_, index) => (
        <div style={{ width: props.width ? props.width : 50 }}>
          {num === 8 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <LabelNormal color="#9E9E9E" fontSize="13">
                M
              </LabelNormal>
              <LabelNormal fontSize="13" color="#9E9E9E">
                D
              </LabelNormal>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <LabelNormal fontSize="13" color="#9E9E9E">
                D
              </LabelNormal>
              <LabelNormal color="#9E9E9E" fontSize="13">
                M
              </LabelNormal>
            </div>
          )}
          <LabelNormal
            key={Math.random()}
            style={{ textAlign: 'center' }}
            color="#C2C2C2"
            fontSize="17"
          >
            {numeration[index]}
          </LabelNormal>
          {props.bottomData[index + num].split(',')[0] > 0 &&
            !props.disabled && (
              <div
                onClick={() => removeData(1, index + num, 1)}
                style={{
                  marginLeft: 3,
                  marginBottom: -32,
                  marginTop: 10,
                  cursor: 'pointer',
                  background: '#F6F6F8',
                  textAlign: 'center',
                  width: 24,
                  height: 24,
                  borderRadius: 23,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={RealoadIcon} />
              </div>
            )}
          {props.bottomData[index + num].split(',')[1] > 0 &&
            !props.disabled && (
              <div
                onClick={() => removeData(1, index + num, 2)}
                style={{
                  marginLeft: 30,
                  marginBottom: -32,
                  marginTop: 8,
                  cursor: 'pointer',
                  background: '#F6F6F8',
                  textAlign: 'center',
                  width: 24,
                  height: 24,
                  borderRadius: 23,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={RealoadIcon} />
              </div>
            )}
        </div>
      ));
    } else {
      return Array.from(Array(8).keys()).map((_, index) => (
        <div style={{ width: props.width ? props.width : 50 }}>
          <LabelNormal
            key={Math.random()}
            style={{ textAlign: 'center' }}
            color="#C2C2C2"
            fontSize="17"
          >
            {numeration[index]}
          </LabelNormal>
          {props.bottomData[index + num] > 0 && !props.disabled && (
            <div
              onClick={() => removeData(1, index + num)}
              style={{
                marginLeft: props.containerNumber2 ? 3 : 12,
                marginBottom: -32,
                marginTop: 10,
                cursor: 'pointer',
                background: '#F6F6F8',
                textAlign: 'center',
                width: 24,
                height: 24,
                borderRadius: 23,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={RealoadIcon} />
            </div>
          )}
        </div>
      ));
    }
  };

  const removeData = (i, index, position = 1) => {
    if (props.containerNumber2) {
      if (i === 0) {
        const map = props.topData.map((v, key) =>
          key === index
            ? position === 1
              ? `${0},${v.split(',')[1]}`
              : `${v.split(',')[0]},${0}`
            : v,
        );
        props.setTopData(map);
      } else {
        const map = props.bottomData.map((v, key) =>
          key === index
            ? position === 1
              ? `${0},${v.split(',')[1]}`
              : `${v.split(',')[0]},${0}`
            : v,
        );
        props.setBottomData(map);
      }
    } else {
      if (i === 0) {
        const map = props.topData.map((v, key) => (key === index ? 0 : v));
        props.setTopData(map);
      } else {
        const map = props.bottomData.map((v, key) => (key === index ? 0 : v));
        props.setBottomData(map);
      }
    }
  };

  const changeValuePosition = (i, index, value, position = 1) => {
    if ((isInteger(value + '') || value === '') && !props.disabled) {
      if (props.containerNumber2) {
        if (i === 0) {
          const map = props.topData.map((v, key) =>
            key === index
              ? position === 1
                ? `${value},${v.split(',')[1]}`
                : `${v.split(',')[0]},${value}`
              : v,
          );
          props.setTopData(map);
        } else {
          const map = props.bottomData.map((v, key) =>
            key === index
              ? position === 1
                ? `${value},${v.split(',')[1]}`
                : `${v.split(',')[0]},${value}`
              : v,
          );
          props.setBottomData(map);
        }
      } else {
        if (i === 0) {
          const map = props.topData.map((v, key) =>
            key === index ? parseFloat(value ? value : 0) : v,
          );
          props.setTopData(map);
        } else {
          const map = props.bottomData.map((v, key) =>
            key === index ? parseFloat(value ? value : 0) : v,
          );
          props.setBottomData(map);
        }
      }
    }
  };

  const onBlurValidation = () => {
    if (props.containerNumber2) {
      const i = positionFocus[0];
      const index = positionFocus[1];
      const position = positionFocus[2];
      let value = '';
      if (position === 0)
        value =
          props.topData[index].split(',')[0] > 0 && i === 0
            ? props.topData[index].split(',')[0]
            : props.bottomData[index].split(',')[0] > 0 && i === 1
              ? props.bottomData[index].split(',')[0]
              : '';
      else
        value =
          props.topData[index].split(',')[1] > 0 && i === 0
            ? props.topData[index].split(',')[1]
            : props.bottomData[index].split(',')[1] > 0 && i === 1
              ? props.bottomData[index].split(',')[1]
              : '';

      if (value === '' || value === 0 || value === '0') {
        setErrors(
          erros.map((v, k) =>
            k === (i === 0 ? index : index + 16)
              ? `${position === 0 ? `error,0` : `0,error`}`
              : v,
          ),
        );
      } else {
        setErrors(
          erros.map((v, k) =>
            k === (i === 0 ? index : index + 16)
              ? `${position === 0 ? `0,0` : `0,0`}`
              : v,
          ),
        );
      }
      setPositionFocus([null, null, null]);
    } else {
      // const i = positionFocus[0];
      // const index = positionFocus[1];
      // const value = props.topData[index] > 0 && i === 0 ? props.topData[index] : props.bottomData[index] > 0 && i === 1 ? props.bottomData[index] : '';

      // if(value === '' || value === 0 || value === '0'){
      //     setErrors(erros.map((v, k) => k === (i === 0 ? index : (index + 16)) ? 'error' : v))
      // }else{
      //     setErrors(erros.map((v, k) => k === (i === 0 ? index : (index + 16)) ? 0 : v))
      // }
      setPositionFocus([null, null]);
    }
  };

  return (
    <div
      style={{
        marginTop: 80,
        marginLeft: 17,
        position: 'relative',
        maxWidth: props.width * 16,
      }}
    >
      <table style={{ borderSpacing: 0 }}>
        <tbody>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{
                position: 'absolute',
                top: props.height - 10,
                left: -17,
              }}
            >
              R
            </LabelNormal>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: 2,
                background: '#000',
                top: props.height ? props.height : 101,
                zIndex: 1,
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{
                position: 'absolute',
                top: props.height - 10,
                right: -17,
              }}
            >
              L
            </LabelNormal>
          </div>
          <div
            style={{
              position: 'absolute',
              width: 2,
              height: props.height ? props.height * 2 + 50 : 202,
              top: -25,
              background: '#000',
              left: '50%',
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: -35,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            {topNumeration(
              0,
              false,
              props.numerationTopLeft ? props.numerationTopLeft : [],
            )}
            {topNumeration(
              8,
              false,
              props.numerationTopRight ? props.numerationTopRight : [],
            )}
          </div>
          {Array.from(Array(2).keys()).map((_, i) => (
            <tr data-test-id={`map-tr-${i}`}>
              {Array.from(Array(16).keys()).map((_, index) => (
                <Contains
                  width={props.width}
                  height={props.height}
                  borderStyle={
                    (positionFocus[0] === i && positionFocus[1] === index) ||
                    (props.topData[index] > 0 && i === 0) ||
                    (props.bottomData[index] > 0 && i === 1)
                      ? 'solid'
                      : !props.containerNumber2 &&
                          erros.find(
                            (v, k) => k === (i === 0 ? index : index + 16),
                          ) === 'error'
                        ? 'solid'
                        : props.borderStyle
                  }
                  borderColor={
                    props.topData[index] > 0 && i === 0
                      ? 'transparent'
                      : props.bottomData[index] > 0 && i === 1
                        ? 'transparent'
                        : positionFocus[0] === i && positionFocus[1] === index
                          ? 'transparent'
                          : !props.containerNumber2 &&
                              erros.find(
                                (v, k) => k === (i === 0 ? index : index + 16),
                              ) === 'error'
                            ? '#E41D11'
                            : '#C8C8C8'
                  }
                >
                  {props.containerNumber2 ? (
                    <>
                      {props.personalize === 'Indicar milímetros por diente' ? (
                        <div style={{ display: 'flex', height: '100%' }}>
                          <input
                            type="text"
                            onChange={(e) =>
                              changeValuePosition(i, index, e.target.value, 1)
                            }
                            value={
                              props.topData[index].split(',')[0] > 0 && i === 0
                                ? props.topData[index].split(',')[0]
                                : props.bottomData[index].split(',')[0] > 0 &&
                                    i === 1
                                  ? props.bottomData[index].split(',')[0]
                                  : ''
                            }
                            onFocus={() =>
                              !props.disabled && setPositionFocus([i, index, 0])
                            }
                            readOnly={!props.disabled ? false : true}
                            style={{
                              background:
                                props.containerNumber2 &&
                                erros.find(
                                  (v, k) =>
                                    k === (i === 0 ? index : index + 16),
                                ) === 'error,0'
                                  ? '#FFE5E3'
                                  : '#FFF',
                              border: 'none',
                              height: '100%',
                              width: '48%',
                              marginRight: 1,
                              textAlign: 'center',
                              color: '#EB9542',
                              fontSize: 17,
                              fontFamily: 'neue-montreal-regular',
                              outlineColor:
                                positionFocus[0] === i &&
                                positionFocus[1] === index &&
                                positionFocus[2] === 0
                                  ? '#EB9542'
                                  : 'transparent',
                            }}
                          />
                          <input
                            type="text"
                            onChange={(e) =>
                              changeValuePosition(i, index, e.target.value, 2)
                            }
                            value={
                              props.topData[index].split(',')[1] > 0 && i === 0
                                ? props.topData[index].split(',')[1]
                                : props.bottomData[index].split(',')[1] > 0 &&
                                    i === 1
                                  ? props.bottomData[index].split(',')[1]
                                  : ''
                            }
                            onFocus={() =>
                              !props.disabled && setPositionFocus([i, index, 1])
                            }
                            readOnly={!props.disabled ? false : true}
                            style={{
                              background:
                                props.containerNumber2 &&
                                erros.find(
                                  (v, k) =>
                                    k === (i === 0 ? index : index + 16),
                                ) === '0,error'
                                  ? '#FFE5E3'
                                  : '#FFF',
                              border: 'none',
                              height: '100%',
                              width: '48%',
                              marginLeft: 1,
                              textAlign: 'center',
                              color: '#EB9542',
                              fontSize: 17,
                              fontFamily: 'neue-montreal-regular',
                              outlineColor:
                                positionFocus[0] === i &&
                                positionFocus[1] === index &&
                                positionFocus[2] === 1
                                  ? '#EB9542'
                                  : 'transparent',
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            height: '100%',
                            zIndex: 999,
                          }}
                        >
                          <div
                            onClick={() =>
                              !props.disabled &&
                              changeValuePosition(i, index, 1, 1)
                            }
                            style={{
                              border: 'none',
                              height: '100%',
                              width: '48.5%',
                              marginRight: 1.5,
                              textAlign: 'center',
                              color: '#EB9542',
                              fontSize: 17,
                              fontFamily: 'neue-montreal-regular',
                              backgroundColor:
                                props.topData[index].split(',')[0] > 0 &&
                                i === 0
                                  ? '#F6CEA7'
                                  : props.bottomData[index].split(',')[0] > 0 &&
                                      i === 1
                                    ? '#F6CEA7'
                                    : 'transparent',
                              cursor: 'pointer',
                            }}
                          />
                          <div
                            onClick={() =>
                              !props.disabled &&
                              changeValuePosition(i, index, 1, 2)
                            }
                            style={{
                              border: 'none',
                              height: '100%',
                              width: '48.5%',
                              marginLeft: 1.5,
                              textAlign: 'center',
                              color: '#EB9542',
                              fontSize: 17,
                              fontFamily: 'neue-montreal-regular',
                              backgroundColor:
                                props.topData[index].split(',')[1] > 0 &&
                                i === 0
                                  ? '#F6CEA7'
                                  : props.bottomData[index].split(',')[1] > 0 &&
                                      i === 1
                                    ? '#F6CEA7'
                                    : 'transparent',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <input
                      type="text"
                      onChange={(e) =>
                        changeValuePosition(i, index, e.target.value)
                      }
                      value={
                        props.topData[index] > 0 && i === 0
                          ? props.topData[index]
                          : props.bottomData[index] > 0 && i === 1
                            ? props.bottomData[index]
                            : ''
                      }
                      onFocus={() =>
                        !props.disabled && setPositionFocus([i, index])
                      }
                      readOnly={!props.disabled ? false : true}
                      onBlur={() => onBlurValidation()}
                      style={{
                        background:
                          !props.containerNumber2 &&
                          erros.find(
                            (v, k) => k === (i === 0 ? index : index + 16),
                          ) === 'error'
                            ? '#FFE5E3'
                            : '#FFF',
                        border: 'none',
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        color: '#EB9542',
                        fontSize: 17,
                        fontFamily: 'neue-montreal-regular',
                        outline: 'none',
                      }}
                    />
                  )}
                  {props.containerNumber2 && (
                    <div
                      style={{
                        position: 'absolute',
                        height: '100%',
                        width: 1,
                        background: 'transparent',
                        top: 0,
                        left: '50%',
                        borderStyle:
                          ((props.topData[index].split(',')[0] > 0 ||
                            props.topData[index].split(',')[1] > 0) &&
                            i === 0) ||
                          ((props.bottomData[index].split(',')[0] > 0 ||
                            props.bottomData[index].split(',')[1] > 0) &&
                            i === 1)
                            ? 'solid'
                            : 'dashed',
                        borderWidth: 0.5,
                        borderColor:
                          ((props.topData[index].split(',')[0] > 0 ||
                            props.topData[index].split(',')[1] > 0) &&
                            i === 0) ||
                          ((props.bottomData[index].split(',')[0] > 0 ||
                            props.bottomData[index].split(',')[1] > 0) &&
                            i === 1)
                            ? '#EB9542'
                            : '#C8C8C8',
                      }}
                    />
                  )}
                </Contains>
              ))}
            </tr>
          ))}
          <div style={{ position: 'absolute', bottom: -35, display: 'flex' }}>
            {bottomNumeration(
              0,
              false,
              props.numerationBottomLeft ? props.numerationBottomLeft : [],
            )}
            {bottomNumeration(
              8,
              props.bottomRightReverse ? true : false,
              props.numerationBottomRight ? props.numerationBottomRight : [],
            )}
          </div>
        </tbody>
      </table>
      {erros.filter((v) => v === 'error' || v === 'error,0' || v === '0,error')
        .length > 0 && (
        <LabelNormal
          style={{
            position: 'absolute',
            bottom: -80,
            color: '#C60E02',
            fontSize: 17,
            marginTop: 70,
          }}
        >
          {props.containerNumber2
            ? '*Modifica el IPR de al menos un diente o seleccina No requiere IPR'
            : '*Indica el IPR en al menos un diente o selecciona Cerrar todos los espacios'}
        </LabelNormal>
      )}
    </div>
  );
};

const Contains = styled.td`
  position: relative;
  height: ${({ height }) => (height ? height : '101.75')}px;
  width: ${({ width }) => (width ? width : '50px')};
  border-style: ${({ borderStyle }) => (borderStyle ? borderStyle : 'dashed')};
  border-color: ${({ borderColor }) => (borderColor ? borderColor : '#C8C8C8')};
  border-width: 1px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

export default Map;
