import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { QUERY_GET_EXTENSIBLE_PATIENTS } from '@graphql/query';
import { NewCaseContext } from './Context';
import { NewCases } from '../NewCase';

/** Helper method to obtain the query params for the router.
 */
function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const NewCaseContainer = (props) => {
  let query = useQueryParams();

  /**
   * @typedef {'Initial' | 'Default' | 'Success' | 'Error' | 'Loading'} AsyncState
   * @type {[AsyncState, React.Dispatch<AsyncState>]} state
   */
  const [extendiblePatientsState, setExtendiblePatientsState] =
    useState('Initial');
  /**
   * @typedef {'Cryst Aligner' | 'Smile System' | null} TreatmentType
   * @type {[TreatmentType, React.Dispatch<TreatmentType>]} state
   */
  const [treatmentType, setTreatmentType] = useState(null);
  /**
   * @typedef {'NewCase' | 'Extension'} RegisterType
   * @type {[RegisterType, React.Dispatch<RegisterType>]} state
   */
  const [registerType, setRegisterType] = useState('NewCase');
  /**
   * @typedef {'NewCase' | 'Extension'} RegisterType
   * @typedef {{
   *   registerType: RegisterType;
   *   parentTreatmentId: number | null;
   *   doctorId: number;
   * }} PageParams
   * @type {[PageParams, React.Dispatch<PageParams>]} state
   */
  const [pageParams, setPageParams] = useState(null);
  /**
   * @typedef {{
   *  context: {
   *    bundleType: string;
   *    expirationDate: Date;
   *    expired: boolean;
   *    purchaseOptions: Array<{
   *      id: number;
   *      title: string;
   *      description: string;
   *      descriptionMedium: string;
   *      priceLabel: string;
   *      type: string;
   *      bundleType: string;
   *    }>;
   *    freePlanningsAvailable: number | null;
   *    freePlanningsTotal: number | null;
   *    remainingAligners: number | null;
   *  };
   *  doctorId: number;
   *  firstName: string;
   *  gender: string;
   *  lastName: string;
   *  patientId: number;
   *  photoUrl: string;
   *  parentTreatmentId: number;
   * }} ExtendiblePatient
   * @type {[Array<ExtendiblePatient>, React.Dispatch<Array<ExtendiblePatient>>]} state
   */
  const [extendiblePatients, setExtendiblePatients] = useState([]);
  const [extendiblePatientSelected, setExtendiblePatientSelected] =
    useState(null);

  const [getExtendiblePatients] = useLazyQuery(QUERY_GET_EXTENSIBLE_PATIENTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setExtendiblePatients(data.getExtendiblePatients);
      setExtendiblePatientsState('Success');
    },
    onError: () => {
      setExtendiblePatientsState('Error');
    },
  });

  /**
   * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
   * @param {AttachPatientItem} attachPatientItem
   */
  const selectExtendiblePatient = (attachPatientItem) => {
    if (attachPatientItem === null) return setExtendiblePatientSelected(null);

    const extendiblePatient = extendiblePatients.find(
      (ep) => ep.patientId === attachPatientItem.id
    );

    if (extendiblePatient) setExtendiblePatientSelected(extendiblePatient);
  };

  const selectRegisterType = (type) => {
    setRegisterType(type);

    if (type === 'Extension' && treatmentType === 'Smile System') {
      setTreatmentType('Cryst Aligner');
    }
  };

  const selectTreatmentType = (type) => {
    setTreatmentType(type);
  };

  const validatePageParams = useCallback(() => {
    let validParams = true;
    let params = {
      registerType: query.get('register_type'),
      parentTreatmentId: query.get('parent_treatment_id'),
      doctorId: query.get('doctor_id'),
    };

    if (
      params.registerType === null &&
      params.registerType === undefined &&
      params.registerType === '' &&
      ['new_case', 'extension'].includes(params.registerType) === false
    ) {
      validParams = false;
    }

    if (
      params.parentTreatmentId === null &&
      params.parentTreatmentId === undefined &&
      params.parentTreatmentId === '' &&
      Number.isNaN(Number(params.parentTreatmentId))
    ) {
      validParams = false;
    }

    if (
      params.doctorId === null &&
      params.doctorId === undefined &&
      params.doctorId === '' &&
      Number.isNaN(Number(params.doctorId))
    ) {
      validParams = false;
    }

    if (validParams) {
      setPageParams({
        doctorId: Number(params.doctorId),
        parentTreatmentId: Number(params.parentTreatmentId),
        registerType: params.registerType,
      });
      setRegisterType('Extension');
    } else {
      setPageParams({
        doctorId: localStorage.getItem('b360-role_id'),
        parentTreatmentId: null,
        registerType: 'NewCase',
      });
      setRegisterType('NewCase');
    }
    setTreatmentType('Cryst Aligner');
    setExtendiblePatientsState('Loading');
  }, [query]);

  useEffect(() => {
    // Inits general state to "Loading". firing all async requests in cascade.
    if (window !== undefined) validatePageParams();
  }, [validatePageParams]);

  useEffect(() => {
    if (pageParams !== null) {
      getExtendiblePatients({
        variables: { doctor_id: Number(pageParams.doctorId) },
      });
    }
  }, [pageParams, getExtendiblePatients]);

  useEffect(() => {
    if (pageParams != null && extendiblePatients.length > 0) {
      const extendiblePatient = extendiblePatients.find(
        (ep) => ep.doctorId === pageParams.doctorId
      );

      if (extendiblePatient) setExtendiblePatientSelected(extendiblePatient);
    }
  }, [pageParams, extendiblePatients]);

  return (
    <NewCaseContext.Provider
      value={{
        extendiblePatientSelected,
        extendiblePatients,
        extendiblePatientsState,
        registerType,
        selectExtendiblePatient,
        selectRegisterType,
        selectTreatmentType,
        setExtendiblePatientsState,
        treatmentType,
      }}
    >
      <NewCases />
    </NewCaseContext.Provider>
  );
};
