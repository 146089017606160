import React from 'react';
import { useHistory } from 'react-router-dom';
import { UilClipboardNotes, UilEye } from '@iconscout/react-unicons';
import Card from '../shared/Card';
import {
  CardContainer,
  HoverContainer,
  InlineContainer,
  LabelNormal,
} from '../shared/Styled';
import SkeletonActions from './actionsComponents/SkeletonActions';
import LabelCase from '../shared/LabelCase';

const Actions = ({
  patientId,
  treatmentRelated,
  archived,
  loadingSkeleton,
  setEdit,
  setHistoryClinic,
  navigateToInvoices,
  handleOpenModal,
}) => {
  const history = useHistory();
  const treatmentTypeId = parseInt(treatmentRelated?.treatmentType?.id) ?? null;
  const { type: statusType, index: statusIndex } =
    treatmentRelated?.treatmentStatus ?? {};
  const role = localStorage.getItem('b360-role');
  return (
    <div className="shadow-md w-full rounded-2xl overflow-hidden bg-white">
      {!loadingSkeleton ? (
        <>
          <CardContainer
            style={{
              padding: 24,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {(statusType === 'planning' ||
              (statusType === 'new_case' &&
                (statusIndex === 7 || statusIndex === 9) &&
                treatmentRelated?.planning_case_id) ||
              statusType === 'printing' ||
              statusType === 'thermoforming' ||
              statusType === 'warehouse' ||
              statusType === 'packing' ||
              statusType === 'shipping' ||
              statusType === 'finished') && (
              <HoverContainer>
                <InlineContainer
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '2px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/${
                        role === 'teacher' ? 'profesor' : 'doctor'
                      }/caso/planificacion/${patientId}`,
                      state: {},
                    })
                  }
                >
                  {treatmentTypeId === 2 ? (
                    <UilClipboardNotes size="24" color="#444E53" />
                  ) : (
                    <UilEye size="24" color="#444E53" />
                  )}
                  <LabelNormal color="#444E53" fontSize="20">
                    {treatmentTypeId === 2
                      ? 'Datos de planificación'
                      : 'Visor de tratamiento'}
                  </LabelNormal>
                </InlineContainer>
              </HoverContainer>
            )}
            <LabelCase
              onClick={() => {
                setEdit(true);
                setHistoryClinic(false);
              }}
              label={'Editar'}
              icon={'edit'}
            />
            <LabelCase
              onClick={() => {
                setEdit(false);
                setHistoryClinic(true);
              }}
              label={'Ver historial clinico'}
              icon={'file'}
            />
            <LabelCase
              onClick={() => {
                setEdit(false);
                navigateToInvoices();
              }}
              label={'Facturas'}
              icon={'file-minus'}
            />
            {statusType !== 'new_case' &&
              archived === false &&
              !(statusType === 'planning' && statusIndex === 7) && (
                <LabelCase
                  onClick={() => handleOpenModal()}
                  label={'Mover a casos inactivos'}
                  icon={'archive'}
                  marginTop={'10'}
                />
              )}
            {(statusType === 'new_case' ||
              archived === true ||
              (statusType === 'planning' && statusIndex === 7)) && (
              <LabelCase disabled />
            )}
          </CardContainer>
        </>
      ) : (
        <SkeletonActions />
      )}
    </div>
  );
};

export default Actions;
