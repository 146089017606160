/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_APPROVED_FILE_TREATMENT,
  MUTATION_REJECT_FILE_TREATMENT,
  MUTATION_UNDO_CORRECTION_TREATMENT,
  MUTATION_REVIEW_TREATMENT,
  MUTATION_ACCEPT_DECLINE_FORM,
  MUTATION_DELETE_CHAT_CORRECTION_FORM,
  MUTATION_CHANGE_STL_ON_REVIEW,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import ModalQuestion from '@components/Modal/ModalQuestion';
import ModalWarning from '@components/Modal/ModalWarning';
import Breadcrumb from '@components/Navigation/Breadcrumb/Breadcrumb';
import { Row } from '../../../components/shared/Styled';
import FilesSidebar from '../../../components/Corrections/FilesSidebar';
import FileDisplay from '../../../components/Corrections/FileDisplay';
import Comments from '../../../components/Corrections/Comments';
import {
  QUERY_GET_TREATMENT,
  QUERY_GET_TREATMENT_FILES,
} from '@graphql/queries/admin/review-files';
import {
  QUERY_GET_FORM_ARCADE,
  QUERY_GET_FORM_BRACKET,
  QUERY_GET_FORM_IPR,
  QUERY_GET_FORM_ODONTOGRAM,
  QUERY_GET_FORM_SHAPE,
} from '@graphql/queries/admin/case-details';
import { buildOdontogramObject } from 'utils';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Review = () => {
  const history = useHistory();
  const { id } = useParams();

  const refStatusCorrections = useRef(null);

  const [treatment, setTreatment] = useState();
  const [formCAData, setFormCAData] = useState();
  const [formSSData, setFormSSData] = useState();

  const [filesTreatment, setFilesTreatment] = useState([]);
  const [selectTreatmentFile, setSelectTreatmentFile] = useState({});
  const [commentsCorrections, setCommentsCorrections] = useState([]);
  const [reject, setReject] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [editCorrection, setEditCorrection] = useState(false);
  const [alertCommentReject, setAlertCommentReject] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getFormBracket] = useLazyQuery(QUERY_GET_FORM_BRACKET, {
    onCompleted: (data) => {
      if (data.formBracket) {
        setFormSSData({
          ...formSSData,
          brackets: data.formBracket,
        });
      }
    },
  });

  const [getFormOdotogram] = useLazyQuery(QUERY_GET_FORM_ODONTOGRAM, {
    onCompleted: (data) => {
      if (data.formOdontogram) {
        const { up_odontograma, lo_odontograma } = buildOdontogramObject(
          data.formOdontogram
        );
        if (treatment?.treatmentType.id == 1) {
          setFormCAData({
            ...formCAData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
        } else {
          setFormSSData({
            ...formSSData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
          getFormBracket({ variables: { treatment_id: treatment?.id } });
        }
      }
    },
  });

  const [getFormIpr] = useLazyQuery(QUERY_GET_FORM_IPR, {
    onCompleted: (data) => {
      if (data.formIpr) {
        setFormCAData({
          ...formCAData,
          ipr: data.formIpr,
        });
      }
      getFormOdotogram({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormShape] = useLazyQuery(QUERY_GET_FORM_SHAPE, {
    onCompleted: (data) => {
      if (data.formShape) {
        setFormCAData({
          ...formCAData,
          shape: data.formShape,
        });
      }
      getFormIpr({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormArcade] = useLazyQuery(QUERY_GET_FORM_ARCADE, {
    onCompleted: (data) => {
      if (data.formArcade) {
        setFormCAData({
          ...formCAData,
          arcad: data.formArcade,
        });
      }
      getFormShape({ variables: { treatment_id: treatment?.id } });
    },
  });

  const { loadingTreatment, refetch } = useQuery(QUERY_GET_TREATMENT, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.treatment) {
        setTreatment(data.treatment);
        if (!formCAData && data.treatment.treatmentType.id == 1) {
          setFormCAData({
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormArcade({ variables: { treatment_id: data.treatment.id } });
        }
        // Set form SS data if wasn't setted and treatment type is Smile System
        if (!formSSData && data.treatment.treatmentType.id == 2) {
          setFormSSData({
            bracket_ss: data.treatment.bracket_ss,
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormOdotogram({ variables: { treatment_id: data.treatment.id } });
        }
      }
    },
  });

  const [getTreatmentFiles, { loadingFiles, refetch: refetchFiles }] =
    useLazyQuery(QUERY_GET_TREATMENT_FILES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getTreatmentFiles) {
          const treatmentFiles = data.getTreatmentFiles;

          let decode = treatmentFiles.map((item) => ({
            id: item.id,
            review: item.name,
            type: item.name !== 'modelo' ? item.file_type : 'image',
            name: item.file_name,
            url: item.fileUrl,
            preview:
              item.file_type === 'stl' && item.name !== 'modelo'
                ? require('@images/files/stl.jpg')
                : item.file_type === 'zip'
                ? require('@images/files/zip.jpg')
                : item.fileUrl,
            section: item.section,
            status: item.FileStatus.name,
            statusId: item.FileStatus.id,
          }));

          decode = [
            decode.find((v) => v.type === 'pdf')
              ? decode.find((v) => v.type === 'pdf')
              : {
                  id: 'id-review',
                  review: 'pdf',
                  type: 'pdf',
                  name: 'form',
                  url: '',
                  section: '',
                  status: treatment?.form_status,
                },
            ...decode.filter((v) => v.type !== 'pdf'),
          ];
          const formFile = decode.find((v) => v.type === 'pdf')
            ? decode.find((v) => v.type === 'pdf')
            : null;

          if (
            selectTreatmentFile &&
            selectTreatmentFile.id &&
            selectTreatmentFile.name !== ''
          ) {
            let index = decode.findIndex(
              (f) =>
                f.section === selectTreatmentFile.section &&
                f.review === selectTreatmentFile.review
            );
            if (index < 0) {
              index = 0;
            }
            setSelectTreatmentFile(decode[index]);
          } else {
            if (selectTreatmentFile && selectTreatmentFile.name === '') {
              setSelectTreatmentFile(
                decode.find((d) => d.review === selectTreatmentFile.review)
              );
            } else {
              setSelectTreatmentFile(decode[0]);
            }
          }

          if (treatment?.stl_type === 'url') {
            const arcada_superior = decode.filter(
              (d) => d.review === 'arcada_superior'
            );
            if (
              arcada_superior.length <= 0 &&
              treatment?.treatmentStatus?.index !== 6
            ) {
              decode.push({
                id: Math.random(),
                review: 'arcada_superior',
                type: 'stl',
                name: '',
                url: require('@images/stl/arcada-superior.jpg'),
                section: 'stl',
                status: 'upload',
              });
            }

            const arcada_inferior = decode.filter(
              (d) => d.review === 'arcada_inferior'
            );
            if (
              arcada_inferior.length <= 0 &&
              treatment?.treatmentStatus?.index !== 6
            ) {
              decode.push({
                id: Math.random(),
                review: 'arcada_inferior',
                type: 'stl',
                name: '',
                url: require('@images/stl/arcada-inferior.jpg'),
                section: 'stl',
                status: 'upload',
              });
            }

            const oclusion = decode.filter(
              (d) => d.review === 'oclusion_derecha'
            );
            if (
              oclusion.length <= 0 &&
              treatment?.treatmentStatus?.index !== 6
            ) {
              decode.push({
                id: Math.random(),
                review: 'oclusion_derecha',
                type: 'stl',
                name: '',
                url: require('@images/stl/oclusion.jpg'),
                section: 'stl',
                status: 'upload',
              });
            }
          } else if (treatment?.stl_type === 'shipping') {
            const modelo = decode.filter((d) => d.review === 'modelo');
            if (
              modelo.length <= 0 &&
              !(
                treatment?.treatmentStatus?.type === 'new_case' &&
                treatment?.treatmentStatus?.index === 6
              )
            ) {
              decode.push({
                id: Math.random(),
                review: 'modelo',
                type: 'image',
                name: '',
                url: require('@images/files/modelo-placeholder.png'),
                preview: require('@images/files/modelo-placeholder.png'),
                section: 'stl',
                status: 'upload',
              });
            }
          }

          if (
            formFile.status === 'Aprobado' &&
            treatment?.treatmentStatus?.type === 'new_case' &&
            treatment?.treatmentStatus?.index === 6
          ) {
            decode = decode.filter((v) => v.type !== 'pdf');
            setSelectTreatmentFile(
              decode.find((d) => d.id === selectTreatmentFile.id)
                ? decode.find((d) => d.id === selectTreatmentFile.id)
                : decode[0]
            );
          }
          setFilesTreatment(decode);
        }
      },
      onError: (err) => console.log(err),
    });

  const [getCommentsCorrection, { refetch: refetchComments }] = useLazyQuery(
    QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getCorrectionCommentsByTreatment.correctionsComments) {
          setCommentsCorrections(
            data.getCorrectionCommentsByTreatment.correctionsComments
          );
        }
      },
    }
  );

  const [approvedFile] = useMutation(MUTATION_APPROVED_FILE_TREATMENT, {
    onCompleted: () => {
      refetchFiles();
      refetchComments();
    },
  });

  const [rejectFile] = useMutation(MUTATION_REJECT_FILE_TREATMENT, {
    onCompleted: () => {
      setReject('');
      setAlertCommentReject(false);
      setEditCorrection(false);
      refetchFiles();
      refetchComments();
    },
  });

  const [changeStlOnReview] = useMutation(MUTATION_CHANGE_STL_ON_REVIEW, {
    onCompleted: () => {
      getTreatmentFiles({
        variables: {
          treatment_id: treatment.id,
          onlyErrors:
            treatment.treatmentStatus.type === 'new_case' &&
            treatment.treatmentStatus.index === 6
              ? true
              : false,
        },
      });
      getCommentsCorrection({
        variables: {
          onlyErrors:
            treatment.treatmentStatus.type === 'new_case' &&
            treatment.treatmentStatus.index === 6
              ? true
              : false,
          treatment_id: treatment.id,
        },
      });
    },
  });

  const [undoFile] = useMutation(MUTATION_UNDO_CORRECTION_TREATMENT, {
    onCompleted: () => {
      refetchFiles();
      refetchComments();
    },
  });

  const [chatCorrection] = useMutation(MUTATION_DELETE_CHAT_CORRECTION_FORM, {
    onCompleted: () => {
      modal.close();
      setReject('');
      setEditCorrection(false);
      refetchFiles();
      refetch();
      refetchComments();
    },
  });

  const [reviewTreatment] = useMutation(MUTATION_REVIEW_TREATMENT, {
    onCompleted: () => {
      if (
        (treatment?.form_correction === '' ||
          treatment.form_correction === null) &&
        (treatment?.contact_question === '' ||
          treatment.contact_question === null)
      ) {
        if (
          commentsCorrections.length > 0 &&
          commentsCorrections.filter((c) => c.type !== 'success').length > 0
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Errores enviados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Espera a que el doctor corrija sus archivos para revisarlos
                nuevamente.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Archivos aprobados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Archivos aprobados y caso enviado a planificación.
              </LabelNormal>
            </ModalSuccess>
          );
        }
      } else {
        if (
          commentsCorrections.length > 0 &&
          commentsCorrections.filter((c) => c.type !== 'success').length > 0
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => history.replace(`/app/tablero/caso/${id}`))
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Errores enviados
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Espera a que el doctor corrija sus archivos para revisarlos
                nuevamente.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          if (
            treatment?.contact_question !== '' &&
            treatment?.contact_question !== null
          ) {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.replace(`/app/tablero/caso/${id}`))
                }
              >
                <LabelNormal
                  fontSize="22"
                  color="#060809"
                  style={{ paddingTop: 30 }}
                >
                  Contacto establecido
                </LabelNormal>
                <LabelNormal
                  fontSize="17"
                  color="#4F4F4F"
                  style={{ paddingTop: 14, marginBottom: 15 }}
                >
                  Espera a que el doctor conteste en la conversación para llegar
                  a acuerdos
                </LabelNormal>
              </ModalSuccess>
            );
          } else {
            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() => history.replace(`/app/tablero/caso/${id}`))
                }
              >
                <LabelNormal
                  fontSize="22"
                  color="#060809"
                  style={{ paddingTop: 30 }}
                >
                  Archivos aprobados
                </LabelNormal>
                <LabelNormal
                  fontSize="17"
                  color="#4F4F4F"
                  style={{ paddingTop: 14, marginBottom: 15 }}
                >
                  Archivos aprobados y caso enviado a planificación.
                </LabelNormal>
              </ModalSuccess>
            );
          }
        }
      }
    },
  });

  const [acceptDeclineForm] = useMutation(MUTATION_ACCEPT_DECLINE_FORM, {
    onCompleted: () => {
      setReject('');
      setEditCorrection(false);
      refetchFiles();
      refetchComments();
      refetch();
    },
  });

  const questionDoctor = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatment?.id}
        doctorId={treatment?.doctor?.id}
        onClose={() => {
          refetchFiles();
          refetch();
        }}
      />
    );
  };

  const questionDoctorForm = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatment?.id}
        question={
          treatment?.contact_question !== ''
            ? treatment?.contact_question
            : treatment?.form_correction
        }
        doctorId={treatment?.doctor?.id}
        form={true}
        onClose={() => {
          setReject('');
          setEditCorrection(false);
          refetchFiles({ variables: { treatment_id: id, onlyErrors: false } });
          refetchComments({
            variables: {
              onlyErrors: false,
              treatment_id: treatment?.id,
            },
          });
          refetch();
        }}
      />
    );
  };

  const questionDoctorEdit = () => {
    modal.open(
      <ModalQuestion
        treatmentId={treatment?.id}
        question={treatment?.contact_question}
        doctorId={treatment?.doctor?.id}
        form={false}
        doctorEdit={true}
        onClose={() => {
          refetchFiles();
          refetch();
        }}
      />
    );
  };

  const deleteQuestion = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          El borrado de la pregunta es permanente
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          La pregunta antes realizada será borrada completamente del chat.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            chatCorrection({
              variables: {
                treatment_id: treatment?.id,
                form: treatment?.form_correction !== '' ? true : false,
              },
            })
          }
          label="Borrar pregunta"
        />
      </ModalWarning>
    );
  };

  const deleteQuestionForm = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se aceptará el formulario
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Al borrar el contenido de la pregunta se aceptará el formulario.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            chatCorrection({
              variables: {
                treatment_id: treatment?.id,
                form: treatment?.form_correction !== '' ? true : false,
              },
            })
          }
          label="Borrar pregunta"
        />
      </ModalWarning>
    );
  };

  const openWarning = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se pausará el caso
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Espera a que el doctor envíe sus correcciones y conteste a tus dudas
          para aprobar los archivos.
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            reviewTreatment({
              variables: { treatment_id: treatment?.id },
            })
          }
          label="Enviar correcciones"
        />
      </ModalWarning>
    );
  };

  const getNextImageReview = (id) => {
    if (reject) {
      setAlertCommentReject(true);
    } else {
      const reply = filesTreatment;
      const index = reply.findIndex((r) => r.id === id);
      if (index < reply.length - 1) {
        setSelectTreatmentFile({ ...reply[index + 1], index: index + 1 });
      }
    }
  };

  useEffect(() => {
    if (treatment) {
      if (
        treatment.treatmentStatus.type === 'new_case' &&
        treatment.treatmentStatus.index === 6 &&
        treatment.stl_type === 'shipping'
      ) {
        changeStlOnReview({ variables: { treatment_id: treatment.id } });
      } else if (filesTreatment.length === 0) {
        getTreatmentFiles({
          variables: {
            treatment_id: treatment.id,
            onlyErrors:
              treatment.treatmentStatus.type === 'new_case' &&
              treatment.treatmentStatus.index === 6
                ? true
                : false,
          },
        });
        getCommentsCorrection({
          variables: {
            onlyErrors:
              treatment.treatmentStatus.type === 'new_case' &&
              treatment.treatmentStatus.index === 6
                ? true
                : false,
            treatment_id: treatment.id,
          },
        });
      }
    }
  }, [treatment]);

  useEffect(() => {
    if (document.querySelector('#card-container-scroll')) {
      document.querySelector('#card-container-scroll').scrollTop =
        document.querySelector('#card-container-scroll')?.scrollHeight;
    }
  }, [reject, commentsCorrections]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <div
          style={{
            position: 'fixed',
            height: '20px',
            width: '90%',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
            top: 0,
            left: 0,
          }}
        ></div>
        <Header
          style={{
            position: 'sticky',
            top: '20px',
            backgroundColor: '#f6f6f8',
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent style={{ marginTop: 0, marginRight: 0 }}>
          <div className="bootstrap-wrapper">
            <div
              className="row"
              style={{
                position: 'sticky',
                top: '90px',
                backgroundColor: '#f6f6f8',
                zIndex: 10,
                marginRight: 0,
              }}
            >
              <div className="col-md-7">
                <Title>Revisión de archivos</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.push('/app/tablero')}
                    mainLabel={'Tableros'}
                    midLabel={`${treatment?.patient?.first_name ?? ''} ${
                      treatment?.patient?.last_name ?? ''
                    }`}
                    onSecondClick={() => history.goBack()}
                    endLabel={'Revisión de archivos'}
                  />
                  <LabelNormal color="#757575" fontSize="17">
                    Revisa cada archivo y acéptalos o recházalos para registrar
                    el caso.
                  </LabelNormal>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginRight: 0 }}>
              <div className="col-md-12" style={{ maxWidth: 1145 }}>
                <Row style={{ flexWrap: 'nowrap' }}>
                  <FilesSidebar
                    loadingSkeleton={loadingTreatment && loadingFiles}
                    loading={loading}
                    files={filesTreatment}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    type="admin"
                  />
                  <FileDisplay
                    type="admin"
                    loadingSkeleton={loadingTreatment && loadingFiles}
                    loading={loading}
                    setLoading={setLoading}
                    file={selectTreatmentFile}
                    setSelectTreatmentFile={setSelectTreatmentFile}
                    caseId={id}
                    patient={treatment?.patient}
                    treatment={treatment}
                    formCAData={formCAData}
                    formSSData={formSSData}
                    filesTreatment={filesTreatment}
                    setFilesTreatment={setFilesTreatment}
                    typeSTL={treatment?.stl_type}
                    chatMessages={[]}
                    activeChat={false}
                    getNextImageReview={getNextImageReview}
                    doctorData={treatment?.doctor}
                    defaultAddress={{}}
                    errorForm={false}
                    formStatus={treatment?.form_status}
                    questionDoctorForm={questionDoctorForm}
                    acceptDeclineForm={acceptDeclineForm}
                    approvedFile={approvedFile}
                    undoFile={undoFile}
                    reject={reject}
                    setReject={setReject}
                  />
                  <Comments
                    role="admin"
                    loading={loadingTreatment && loadingFiles}
                    loadingImage={loading}
                    patientId={treatment?.patient?.id}
                    treatmentId={treatment?.id}
                    statusTreatment={treatment?.treatmentStatus}
                    comments={commentsCorrections}
                    typeSTL={treatment?.stl_type}
                    files={filesTreatment}
                    refStatusCorrections={refStatusCorrections}
                    file={selectTreatmentFile}
                    setSelectFile={setSelectTreatmentFile}
                    reject={reject}
                    alertCommentReject={alertCommentReject}
                    formCorrection={treatment?.form_correction}
                    contactQuestion={treatment?.contact_question}
                    reviewTreatment={reviewTreatment}
                    openWarning={openWarning}
                    acceptDeclineForm={acceptDeclineForm}
                    rejectFile={rejectFile}
                    questionDoctor={questionDoctor}
                    questionDoctorForm={questionDoctorForm}
                    questionDoctorEdit={questionDoctorEdit}
                    deleteQuestionForm={deleteQuestionForm}
                    deleteQuestion={deleteQuestion}
                  />
                </Row>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Review;
