import styled from 'styled-components';

/*Estilos generales y genericos para la vista de login*/
export const ImgLogin = styled.img`
  width: 5%;
`;

export const MainLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const InputContainer = styled.div`
  margin-bottom: 10px;
`;

export const H2Login = styled.h2`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3c4549;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const SpanLogin = styled.span`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #757575;
  margin-bottom: 40px;
`;

export const SpanLoginBt = styled.span`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #757575;
  margin-top: 50px;
`;

export const SpanInput = styled.span`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: #3c4549;
  text-align: left;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
`;
