import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENTS_BY_FILTER = gql`
  query getTreatmentsByFilters(
    $doctor_id: ID!
    $filters: [String!]!
    $search: String
  ) {
    getTreatmentsByFilters(
      input: { doctor_id: $doctor_id, filters: $filters, search: $search }
    ) {
      id
      patient {
        id
        treatment
        first_name
        last_name
        gender
        birthdate
        phone
        email
        photo_filename
      }
      smartsChecks {
        id
        start_date
        finish_date
        zoom_link
        status
      }
      treatmentStatus {
        id
        name
        admin_name
        color_type
        index
        type
      }
      treatmentType {
        id
        name
      }
      make_smartcheck
      message_doctor
      archived
      doctor {
        id
        user {
          id
        }
      }
      parentTreatment {
        id
      }
      childTreatment {
        id
      }
      payments {
        id
        type
        status
      }
      messages {
        id
        from {
          id
        }
        readed
      }
    }
  }
`;
