import {
  Container,
  LabelInput,
  LabelMuted,
  InputComponent,
  InlineContainer,
  ErrorMessage,
  LabelAnotation,
} from '../Styled';
import { Controller } from 'react-hook-form';

const TextInput = ({
  name,
  control,
  errors,
  clearErrors,
  rules = {},
  label,
  muted,
  anotation,
  parentProps = {},
  optional = false,
  anotationPosition = 'bottom',
  ...props
}) => {
  return (
    <Container {...parentProps}>
      {label && <LabelInput>{label}</LabelInput>}
      {muted && <LabelMuted>{muted}</LabelMuted>}
      {anotation && anotationPosition === 'top' && (
        <LabelAnotation>{anotation}</LabelAnotation>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <InputComponent
            {...field}
            {...props}
            onFocus={() => clearErrors && clearErrors(name)}
            borderColor={
              errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)'
            }
          />
        )}
      />
      {anotation && anotationPosition === 'bottom' && (
        <LabelAnotation>{anotation}</LabelAnotation>
      )}
      {!optional && (
        <InlineContainer>
          <ErrorMessage>
            {errors && errors[name] && errors[name]?.message}
          </ErrorMessage>
        </InlineContainer>
      )}
    </Container>
  );
};

export default TextInput;
