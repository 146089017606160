import axios from 'axios';
import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';

export const Object = [
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
  { name: 'Patricia smith', image: require('./images/avatar.png') },
];

export const validateEmail = (email) => {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/.test(
    email
  );
};

export const validateNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const validateRFC = (rfc) => {
  return /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
    rfc
  );
};

export const validatePassword = (password) => {
  if (password.length >= 8) {
    return true;
  } else {
    return false;
  }
};

export const validatePhone = (phone) => {
  if (phone.length < 12) {
    return false;
  } else {
    return true;
  }
};

export const validateDate = (date) => {
  return /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(date);
};

export const validateExpDate = (date) => {
  if (
    date.length < 5 ||
    (date.length === 5 &&
      parseInt(`20${date.split('/')[1]}`) < new Date().getFullYear()) ||
    (date.length === 5 &&
      parseInt(`20${date.split('/')[1]}`) === new Date().getFullYear() &&
      parseInt(`${date.split('/')[0]}`) < new Date().getMonth() + 1)
  ) {
    return false;
  }
  return true;
};

export const validateCVV = (brand, cvv) => {
  if (cvv.length < 3 || (brand === 'amex' && cvv.length < 4)) {
    return false;
  }
  return true;
};

export const validateURL = (url) => {
  return /^((ftp|http|https):\/\/)?(www\.)?([A-z0-9]+)\.([A-z]{2,})/.test(url);
};

export const getTypeCard = (number) => {
  if (number.length === 0) return '';
  const types = [
    { name: 'visa', regex: /^4/ },
    {
      name: 'mastercard',
      regex:
        /^5[1-5][0-9]{2}?\s?([0-9]{0,4})?\s?([0-9]{0,4})?\s?([0-9]{0,4})?$/,
    },
    { name: 'amex', regex: /^3[47]/ },
    {
      name: 'discover',
      regex:
        /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    },
    { name: 'diners', regex: /^36/ },
    { name: 'diners', regex: /^3(?:0[0-59]{1}|[689])[0-9]{0,}/ },
    {
      name: 'maestro',
      regex: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)/,
    },
    { name: 'maestro', regex: /^(5[06789]|6)[0-9]{0,}/ },
  ];

  const result = types.filter((cc) => number.match(cc.regex) !== null);
  if (result.length > 0) {
    return result[0].name;
  } else {
    return '';
  }
};

export const normalizeInputPhone = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const validationRFC = (rfc) => {
  const crfc = rfc;
  let regex = '';
  if (rfc.length === 12) {
    regex = '^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
  } else {
    regex =
      '^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))';
  }
  const validRfc = new RegExp(regex);

  const match = crfc.match(validRfc);
  if (match === null) {
    return false;
  } else {
    return true;
  }
};

export const validateZipCode = (zip_code, country_id) => {
  const countries = [1, 2, 3];
  if (countries.includes(parseInt(country_id))) {
    if (zip_code.length < 5) return false;
    return true;
  }
  return true;
};

export const loadPhotoProfileGender = (gender, type = '') => {
  if (type === 'doctor') {
    return gender === 'Masculino' || gender === 'Femenino'
      ? require(`@images/people/${
          gender.toLowerCase() === 'masculino' ? 'doctor' : 'doctora'
        }.jpg`)
      : require('@images/people/doctor.jpg');
  } else {
    return gender === 'Masculino' || gender === 'Femenino'
      ? require(`@images/people/usuario_${gender.toLowerCase()}.jpg`)
      : require('@images/people/usuario_masculino.jpg');
  }
};

export const getPlaceholdersByCountry = (country) => {
  if (!country || parseInt(country) === 1) {
    return {
      external: 'N° exterior',
      zipCode: 'Código postal',
      locality: 'Municipio/Ciudad',
      state: 'Estado/Provincia',
      city: 'Localidad/Colonia',
    };
  } else if (parseInt(country) === 2) {
    return {
      external: 'Edificio',
      zipCode: 'Código postal',
      locality: 'Ciudad',
      state: 'Provincia',
      city: 'Localización',
    };
  } else if (parseInt(country) === 3) {
    return {
      external: 'Número',
      zipCode: 'Comuna/Distrito/Región',
      locality: 'Ciudad',
      state: 'Estado',
      city: 'Ciudad',
    };
  }
};

export const downloadImage = async (fileName, url) => {
  const token = localStorage.getItem('token-b360');
  const instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers: {
      'Content-type': 'image/jpeg',
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
  console.log('URL:', url);
  const path = url
    .split('?')[0]
    .replace('https://portal-b360.s3.amazonaws.com', '');

  try {
    const response = await instance.get(
      `/api/files/download?filePath=${path}&mimeType=image/jpeg`
    );
    const href = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'image/jpeg',
      })
    );

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (e) {
    console.log(e);
  }
};

export const odontogramaArray = [
  { name: 'Diente ausente', icon: DienteAusente, id: 'diente_ausente' },
  { name: 'Diente a extraer', icon: DienteExtraer, id: 'diente_a_extraer' },
  {
    name: 'Diente a excluir de los movimientos',
    icon: PiesaExcluir,
    id: 'diente_a_excluir_de_los_movimientos',
  },
  { name: 'Implante', icon: Implante, id: 'implante' },
  { name: 'Pieza a excluir', icon: PiesaExcluir, id: 'pieza_a_excluir' },
  { name: 'Diente en erupción', icon: PiezaErupcion, id: 'diente_en_erupcion' },
  {
    name: 'Diente con prótesis fija',
    icon: ProtesisFija,
    id: 'diente_con_protesis_fija',
  },
  {
    name: 'Diente incluido retenido',
    icon: DienteIncluidoRetenido,
    id: 'diente_incluido_retenido',
  },
  {
    name: 'Diente con endodoncia',
    icon: DienteConEndodoncia,
    id: 'diente_con_endodoncia',
  },
  {
    name: 'Diente con erosión dental',
    icon: ErosionDental,
    id: 'diente_con_erosion_dental',
  },
  {
    name: 'Fractura en corona',
    icon: FracturaCorona,
    id: 'fractura_en_corona',
  },
  {
    name: 'Diente con incrustación',
    icon: Inscrustacion,
    id: 'diente_con_incrustacion',
  },
  {
    name: 'Diente con restauración',
    icon: Restauracion,
    id: 'diente_con_restauracion',
  },
];

export const upOdontogramaTemplate = [
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
];

export const lowOdontogramaTemplate = [
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
  [''],
];

export const setUpOdontogram = (odontograma) => {
  return [
    JSON.parse(odontograma.up_left_third_molar_data),
    JSON.parse(odontograma.up_left_second_molar_data),
    JSON.parse(odontograma.up_left_first_molar_data),
    JSON.parse(odontograma.up_left_second_premolar_data),
    JSON.parse(odontograma.up_left_first_premolar_data),
    JSON.parse(odontograma.up_left_canine_data),
    JSON.parse(odontograma.up_left_lateral_incisor_data),
    JSON.parse(odontograma.up_left_central_incisor_data),
    JSON.parse(odontograma.up_right_third_molar_data),
    JSON.parse(odontograma.up_right_second_molar_data),
    JSON.parse(odontograma.up_right_first_molar_data),
    JSON.parse(odontograma.up_right_second_premolar_data),
    JSON.parse(odontograma.up_right_first_premolar_data),
    JSON.parse(odontograma.up_right_canine_data),
    JSON.parse(odontograma.up_right_lateral_incisor_data),
    JSON.parse(odontograma.up_right_central_incisor_data),
  ];
};

export const setLowOdontogram = (odontograma) => {
  return [
    JSON.parse(odontograma.lo_left_third_molar_data),
    JSON.parse(odontograma.lo_left_second_molar_data),
    JSON.parse(odontograma.lo_left_first_molar_data),
    JSON.parse(odontograma.lo_left_second_premolar_data),
    JSON.parse(odontograma.lo_left_first_premolar_data),
    JSON.parse(odontograma.lo_left_canine_data),
    JSON.parse(odontograma.lo_left_lateral_incisor_data),
    JSON.parse(odontograma.lo_left_central_incisor_data),
    JSON.parse(odontograma.lo_right_third_molar_data),
    JSON.parse(odontograma.lo_right_second_molar_data),
    JSON.parse(odontograma.lo_right_first_molar_data),
    JSON.parse(odontograma.lo_right_second_premolar_data),
    JSON.parse(odontograma.lo_right_first_premolar_data),
    JSON.parse(odontograma.lo_right_canine_data),
    JSON.parse(odontograma.lo_right_lateral_incisor_data),
    JSON.parse(odontograma.lo_right_central_incisor_data),
  ];
};

export const buildOdontogramObject = (odontogram) => {
  if (
    odontogram &&
    odontogram.up_left_second_molar_data &&
    odontogram.type !== 'Sin cambios'
  ) {
    return {
      up_odontograma: setUpOdontogram(odontogram),
      lo_odontograma: setLowOdontogram(odontogram),
    };
  }
  return {
    up_odontograma: upOdontogramaTemplate,
    lo_odontograma: lowOdontogramaTemplate,
  };
};

export const buildSTLFilesObject = (treatment) => {
  let objectStls = {};
  let arcada_superior = treatment.files.find(
    (f) => f.name === 'arcada_superior'
  );
  if (arcada_superior) {
    objectStls = {
      ...objectStls,
      arcada_superior: {
        file: {
          name: arcada_superior.file_name,
          file_url: arcada_superior.fileUrl,
        },
        preview: require('@images/patient/stl-upload.png'),
        error: false,
      },
    };
  }
  let oclusion_derecha = treatment.files.find(
    (f) => f.name === 'oclusion_derecha'
  );
  if (oclusion_derecha) {
    objectStls = {
      ...objectStls,
      oclusion_derecha: {
        file: {
          name: oclusion_derecha.file_name,
          file_url: oclusion_derecha.fileUrl,
        },
        preview: require('@images/patient/stl-upload.png'),
        error: false,
      },
    };
  }
  let oclusion_izquierda = treatment.files.find(
    (f) => f.name === 'oclusion_izquierda'
  );
  if (oclusion_izquierda) {
    objectStls = {
      ...objectStls,
      oclusion_izquierda: {
        file: {
          name: oclusion_izquierda.file_name,
          file_url: oclusion_izquierda.fileUrl,
        },
        preview: require('@images/patient/stl-upload.png'),
        error: false,
      },
    };
  }
  let arcada_inferior = treatment.files.find(
    (f) => f.name === 'arcada_inferior'
  );
  if (arcada_inferior) {
    objectStls = {
      ...objectStls,
      arcada_inferior: {
        file: {
          name: arcada_inferior.file_name,
          file_url: arcada_inferior.fileUrl,
        },
        preview: require('@images/patient/stl-upload.png'),
        error: false,
      },
    };
  }
  return objectStls;
};
