import React from 'react';
import styled from 'styled-components';

const ImageBrand = styled.img`
  height: ${({ height }) => (height ? height : '40')}px;
  width: ${({ width }) => (width ? width : '40')}px;
`;

const Brand = (props) => {
  return <ImageBrand {...props} />;
};

export default Brand;
