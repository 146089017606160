import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { LabelNormal } from './Styled';

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const History = (props) =>
  props.histories.map((item, index) => {
    if (props.type === 'admin') {
      if (index === 0 && item.admin_title !== null) {
        if (item.message.includes('directamente a este link')) {
          const message = item.message.substring(0, 63);
          const link = item.message.substring(64, item.message.length);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: item.type === 'error' ? 2 : 1,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontWeight={item.type === 'error' ? '600' : '500'}
                  fontSize="17"
                >
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      className="text-[#436FF1]"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link}
                    </a>
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (item.message.includes('Tu paquete ha sido enviado por')) {
          const message = item.message.substring(0, 53);
          const guide = item.message.substring(54, item.message.length);
          const cleanGuide = guide.substring(0, guide.length - 1);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: item.type === 'error' ? 2 : 1,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontWeight={item.type === 'error' ? '600' : '500'}
                  fontSize="17"
                >
                  {item.admin_title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.admin_message != null && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      href={`https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=${cleanGuide}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cleanGuide}
                    </a>
                    .
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else {
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: 2,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontWeight={item.type === 'error' ? '600' : '500'}
                  fontSize="17"
                >
                  {item.admin_title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.admin_message != null && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {item.admin_message}
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        }
      } else if (item.admin_title !== null) {
        if (item.message.includes('directamente a este link')) {
          const message = item.message.substring(0, 63);
          const link = item.message.substring(64, item.message.length);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      className="text-[#436FF1]"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link}
                    </a>
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (item.message.includes('Tu paquete ha sido enviado por')) {
          const message = item.message.substring(0, 53);
          const guide = item.message.substring(54, item.message.length);
          const cleanGuide = guide.substring(0, guide.length - 1);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.admin_title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.admin_message != null && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      href={`https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=${cleanGuide}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cleanGuide}
                    </a>
                    .
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (item.admin_title.includes('Registro de nuevo caso')) {
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                // borderColor: item.type === 'error' ? '#C60E02' : '#F2B559',
                // borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.admin_title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.admin_message != null && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {item.admin_message.includes('MXN')
                      ? item.admin_message.replace('MXN', 'USD')
                      : item.admin_message}
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else {
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderWidth: item.type === 'error' ? 1 : 0,
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.admin_title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.admin_message != null && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {item.admin_message}
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        }
      } else return null;
    } else {
      if (
        (index === 0 && item.title !== '') ||
        (index === 1 && item.title !== '' && item.type === 'error') ||
        (index === 2 && item.title !== '' && item.type === 'error')
      ) {
        if (item.message.includes('directamente a este link')) {
          const message = item.message.substring(0, 63);
          const link = item.message.substring(64, item.message.length);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: item.type === 'error' ? 2 : 1,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontSize="17"
                  fontWeight={item.type === 'error' ? '600' : '500'}
                >
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      className="text-[#436FF1]"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link}
                    </a>
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (item.message.includes('Tu paquete ha sido enviado por')) {
          const message = item.message.substring(0, 53);
          const guide = item.message.substring(54, item.message.length);
          const cleanGuide = guide.substring(0, guide.length - 1);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: item.type === 'error' ? 2 : 1,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontSize="17"
                  fontWeight={item.type === 'error' ? '600' : '500'}
                >
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      href={`https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=${cleanGuide}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cleanGuide}
                    </a>
                    .
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (
          (item.tittle !== '' && item.message !== '') ||
          (item.tittle !== '' && item.message === '')
        ) {
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#E41D11' : '#F2B559',
                borderWidth: 2,
                borderStyle: 'solid',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal
                  color={item.type === 'error' ? '#C60E02' : '#3C4549'}
                  fontSize="17"
                  fontWeight={item.type === 'error' ? '600' : '500'}
                >
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {item.message}
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else return null;
      } else if (item.title !== '') {
        if (item.message.includes('directamente a este link')) {
          const message = item.message.substring(0, 63);
          const link = item.message.substring(64, item.message.length);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      className="text-[#436FF1]"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link}
                    </a>
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else if (item.message.includes('Tu paquete ha sido enviado por')) {
          const message = item.message.substring(0, 53);
          const guide = item.message.substring(54, item.message.length);
          const cleanGuide = guide.substring(0, guide.length - 1);
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {message}{' '}
                    <a
                      href={`https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=${cleanGuide}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cleanGuide}
                    </a>
                    .
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        } else {
          return (
            <CardContainer
              key={`history-item-${index}`}
              style={{
                borderColor: item.type === 'error' ? '#828282' : '',
                borderRadius: 14,
                padding: 15,
                marginTop: 10,
              }}
            >
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <LabelNormal color="#3C4549" fontSize="17">
                  {item.title}
                </LabelNormal>
                <LabelNormal color="#3C4549" fontSize="17">
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </LabelNormal>
              </InlineContainer>
              {item.message !== '' && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">
                    {item.message}
                  </LabelNormal>
                </InlineContainer>
              )}
              {item.user && (
                <InlineContainer>
                  <LabelNormal color="#828282" fontSize="17">{`${
                    item.user.first_name
                  } ${item.user.last_name} | ${moment(item.created_at).format(
                    'hh:mm A'
                  )}`}</LabelNormal>
                </InlineContainer>
              )}
            </CardContainer>
          );
        }
      } else return null;
    }
  });

export default History;
