import React, { useState, useEffect } from 'react';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  QUERY_GET_DETAILS_DOCTOR,
  QUERY_GET_PATIENT_BY_FILTER,
} from '@graphql/query';
import { useLazyQuery } from '@apollo/client';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import TreatmentCard from '@components/shared/TreatmentCard';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainHome = styled.div`
  width: 100%;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const options = [
  { id: 'Activos', name: 'Activos' },
  { id: 'Inactivos', name: 'Inactivos' },
  { id: 'Finalizados', name: 'Finalizados' },
];

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;

const CasesDoctor = () => {
  const { id } = useParams();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [search, setSearch] = useState('');

  const [getDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;

        setFirstName(doctor.user.first_name);
        setLastName(doctor.user.last_name);
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getDetails({ variables: { id } });
  }, []);

  const [selectedOption, setSelectedOption] = useState([
    'Activos',
    'Inactivos',
    'Finalizados',
  ]);
  const [patients, setPatients] = useState([]);

  const [getPatientsByFilters] = useLazyQuery(QUERY_GET_PATIENT_BY_FILTER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientsByFilters) {
        setPatients(data.getPatientsByFilters);
      }
    },
  });

  useEffect(() => {
    if (search !== '') {
      getPatientsByFilters({
        variables: { doctor_id: id, filters: selectedOption, search },
      });
    } else {
      getPatientsByFilters({
        variables: { doctor_id: id, filters: selectedOption },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (search !== '') {
      getPatientsByFilters({
        variables: { doctor_id: id, filters: selectedOption, search },
      });
    } else {
      getPatientsByFilters({
        variables: { doctor_id: id, filters: selectedOption },
      });
    }
  }, [search]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="doctores" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent style={{ marginTop: 0, maxWidth: '1150px' }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              {firstName !== '' && (
                <div className="col-md-12">
                  <Title>Casos</Title>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Breadcrumb
                      elements={2}
                      onClick={() => history.push('/app/doctores')}
                      mainLabel={'Doctores'}
                      midLabel={`${firstName} ${lastName}`}
                      onSecondClick={() => history.goBack()}
                      endLabel={'Casos'}
                    />
                  </div>
                </div>
              )}
              {/* <div className='col-md-12'>
                                <InlineContainer style={{justifyContent: 'space-between', marginRight: '25%', marginLeft: '25%', marginBottom: 20}}>
                                    <LabelNormal fontSize="25" onClick={() => setSelectItem('cryst_aligner')} color={selectItem === "cryst_aligner" ? "#3C4549" : "#C8C8C8"} style={{borderBottomColor: selectItem === "cryst_aligner" ? '#3C4549' : 'transparent', borderBottomStyle: 'solid', borderBottomWidth: 2, cursor: 'pointer'}}>Cryst Aligner</LabelNormal>
                                    <LabelNormal fontSize="25" onClick={() => setSelectItem('smile_system')} color={selectItem === "smile_system" ? "#3C4549" : "#C8C8C8"} style={{borderBottomColor: selectItem === "smile_system" ? '#3C4549' : 'transparent', borderBottomStyle: 'solid', borderBottomWidth: 2, cursor: 'pointer'}}>Smile System</LabelNormal>
                                </InlineContainer>
                            </div> */}
              <InlineContainer style={{ justifyContent: 'space-between' }}>
                <InlineContainer>
                  <InputSearch
                    placeholder="Busca por nombre o ID"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    marginRight="33"
                  />
                  <DropdownCustom
                    label="Filtros"
                    data={options}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                </InlineContainer>
              </InlineContainer>
              <div className="col-md-12" style={{ marginTop: 0 }}>
                {patients.length > 0 ? (
                  <div className="row">
                    {patients.map((item) => (
                      <div
                        className="col-md-3"
                        style={{ marginTop: 15 }}
                        key={Math.random()}
                      >
                        <TreatmentCard
                          key={item.id}
                          to={`/app/tablero/caso/${item.id}`}
                          id={item.treatment_related.id}
                          patient_id={item.id}
                          patient_first_name={item.first_name}
                          patient_gender={item.gender}
                          patient_last_name={item.last_name}
                          photo_filename={item.photo_filename}
                          color_type={
                            item.treatment_related.treatmentStatus.color_type
                          }
                          status_name={
                            item.treatment_related.treatmentStatus.admin_name
                          }
                          treatment={item.treatment}
                          smarts_checks={item.treatment_related.smartsChecks}
                          messages={item.treatment_related.messages}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="col-md-12">
                    <TitleWithoutContent>
                      Aún no tiene casos
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default CasesDoctor;
