import { ChangeEvent, MutableRefObject, useRef } from 'react';
import { History } from 'history';
import InfoModal from '@components/case-details/modals/info-modal';
import React, { useMutation } from '@apollo/client';
import Treatment from '@models/treatment';
import { modal } from '@components/ModalManagement';
import { TreatmentStatusEnum } from '@models/treatment-status';
import ModalWarning from '@components/Modal/ModalWarning';
import { useHistory } from 'react-router-dom';
import { CaseDetails } from '@acueductostudio/borgatta-components';
import UserRole from '@models/user-role';
import { MUTATION_APPROVED_BY_TEACHER } from '@graphql/mutations/teacher/case';
import { LabelNormal } from '@components/shared/Styled';
import ButtonPrime from '@components/shared/ButtonPrime';

interface TeacherFunctionsProps {
  treatment: Treatment;
  viewer: UserRole;
  refresh: () => void;
}

const TeacherFunctions = ({
  treatment,
  viewer,
  refresh,
}: TeacherFunctionsProps) => {
  const statusId: number = treatment.treatmentStatus.id;
  const history = useHistory();

  const [approvedTeacher] = useMutation(MUTATION_APPROVED_BY_TEACHER, {
    onCompleted: () => {
      modal.open(
        <InfoModal
          modal={modal}
          onClose={refresh}
          title="Caso aprobado"
          body="El estudiante podrá mandar a manufactura cuando apruebe el caso."
        />
      );
    },
  });

  const onSubmitTeacher = async () => {
    modal.open(
      <ModalWarning onClick={() => modal.close(() => {})}>
        <LabelNormal
          color="#060809"
          fontSize="22"
          style={{ marginBottom: 14, marginTop: 14 }}
        >
          Este caso se mandará a producción
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Confirmar este caso aprobará la planificación y el alumno procederá al
          pago para su manufactura.
        </LabelNormal>
        <ButtonPrime
          label="Aprobar caso"
          onClick={() =>
            approvedTeacher({ variables: { treatment_id: treatment?.id } })
          }
        />
      </ModalWarning>
    );
  };

  const resolveClickFunction = () => {
    if (TreatmentStatusEnum.WaitingApprove === statusId) {
      return onSubmitTeacher();
    }
    if (TreatmentStatusEnum.ReadyVisor === statusId) {
      return history.push(`/profesor/caso/planificacion/${treatment?.id}`);
    }
    return null;
  };

  return (
    <>
      <CaseDetails
        viewer={viewer}
        treatment={treatment}
        pendingMessages={0}
        pendingPayment={false}
        onClick={resolveClickFunction}
      />
    </>
  );
};

export default TeacherFunctions;
