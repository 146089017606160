import React from 'react';
import TreatmentCardProps from '@interfaces/treatment-card-props.interface';
import { TreatmentCard } from '@acueductostudio/borgatta-components';
import { UserRoleEnum } from '@models/user-role';
import { useHistory } from 'react-router-dom';
import { TreatmentStatusEnum } from '@models/treatment-status';
import Smartcheck, { SmartcheckEnum } from '@models/smartcheck';

const TreatmentCardContainer = ({ treatment, viewer }: TreatmentCardProps) => {
  const statusId: number = treatment.treatmentStatus.id;
  const history = useHistory();
  const pendingMessages =
    treatment.messages?.filter(
      (message) =>
        ((viewer === UserRoleEnum.DOCTOR || viewer === UserRoleEnum.STUDENT) &&
          (message.from === null ||
            message.from?.id !== treatment.doctor.user?.id)) ||
        (viewer === UserRoleEnum.ADMIN &&
          message.from?.id === treatment.doctor.user?.id)
    ).length ?? 0;
  const lastSmartcheck: Smartcheck | null =
    treatment.smartChecks
      ?.filter((smartChecks) => smartChecks.status !== SmartcheckEnum.NotAssist)
      .sort((a, b) => a.id - b.id)[0] ?? null;

  const adminClickFunction = () => {
    if (pendingMessages > 0) {
      return history.push(
        `/app/tablero/soporte/${treatment.patient.id}/${treatment.id}`
      );
    }
    if (
      TreatmentStatusEnum.ReviewingFiles === statusId ||
      TreatmentStatusEnum.SolvedError === statusId
    ) {
      return history.push(`/app/tablero/caso/revision/${treatment.id}`);
    }
    if (TreatmentStatusEnum.ReadyToPlanning === statusId) {
      return history.push(`/app/tablero/caso/planificacion/${treatment.id}`);
    }
    if (
      TreatmentStatusEnum.ReadyVisor === statusId ||
      TreatmentStatusEnum.WaitingPlanning === statusId ||
      TreatmentStatusEnum.DoubtsResolved === statusId
    ) {
      return history.push(
        `/app/tablero/revision-planificacion/${treatment.id}`
      );
    }
    if (TreatmentStatusEnum.DaySmartcheck === statusId && lastSmartcheck) {
      return window.open(lastSmartcheck?.zoom_link, '_blank');
    }
    if (TreatmentStatusEnum.EndedSmartcheck === statusId) {
      return history.push(
        `/app/tablero/revision-planificacion/${treatment.id}`
      );
    }
    if (TreatmentStatusEnum.ReadyToShipping === statusId) {
      return history.push(`/app/tablero/envio-tratamiento/${treatment.id}`);
    }
    return null;
  };

  const doctorClickFunction = () => {
    if (pendingMessages > 0) {
      return history.push(`/doctor/caso/mensajes-borgatta/${treatment.id}`);
    }
    if (TreatmentStatusEnum.ReportedErrors === statusId) {
      return history.push(`/doctor/caso/correccion/${treatment.id}`);
    }
    if (
      TreatmentStatusEnum.PausedCase === statusId ||
      TreatmentStatusEnum.DoctorMessage === statusId ||
      TreatmentStatusEnum.PendingPayment === statusId ||
      /**TODO: Validate only status EndedSecondSmartcheck when makeSmartchecks flag is enabled */
      TreatmentStatusEnum.EndedSecondSmartcheck === statusId ||
      TreatmentStatusEnum.ReadyToApprove === statusId ||
      TreatmentStatusEnum.ApprovedByProfesor === statusId
    ) {
      return history.push(`/doctor/aprobar-caso/${treatment.patient.id}`);
    }
    if (TreatmentStatusEnum.ReadyVisor === statusId) {
      return history.push(`/doctor/caso/planificacion/${treatment.patient.id}`);
    }
    if (
      TreatmentStatusEnum.ReadyPlanning === statusId ||
      TreatmentStatusEnum.ScheduledSmartcheck === statusId ||
      TreatmentStatusEnum.MissedSmartcheck === statusId
    ) {
      return history.push(
        `/doctor/caso/agendar-smartcheck/${treatment.patient.id}`
      );
    }
    if (TreatmentStatusEnum.DaySmartcheck === statusId) {
      return window.open(lastSmartcheck?.zoom_link, '_blank');
    }
    // if (TreatmentStatusEnum.WaitingEvidence === statusId) {
    // }
    return null;
  };

  const onClick = () => {
    switch (viewer) {
      case UserRoleEnum.ADMIN:
        return adminClickFunction();
      case UserRoleEnum.DOCTOR:
      case UserRoleEnum.STUDENT:
        return doctorClickFunction();
      default:
        return;
    }
  };

  const goToDetails = () => {
    switch (viewer) {
      case UserRoleEnum.ADMIN:
        return history.push(`/app/tablero/caso/${treatment.id}`);
      case UserRoleEnum.DOCTOR:
      case UserRoleEnum.STUDENT:
        if (
          [
            TreatmentStatusEnum.IncompleteRegistry,
            TreatmentStatusEnum.PendingPayment,
          ].includes(treatment.treatmentStatus.id)
        ) {
          return history.push(`/doctor/continuar-caso/${treatment.id}`);
        }
        return history.push(`/doctor/caso/${treatment.id}`);
      case UserRoleEnum.TEACHER:
        return history.push(`/profesor/caso/${treatment.id}`);
    }
  };

  return (
    <div className="w-[250px]">
      <TreatmentCard
        treatment={treatment}
        viewer={viewer}
        onClick={onClick}
        goToDetails={goToDetails}
        pendingMessages={pendingMessages}
        pendingPayment={false}
      />
    </div>
  );
};

export default TreatmentCardContainer;
