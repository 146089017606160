import React from 'react';
import FeatherIcons from 'feather-icons-react';

import styled from 'styled-components';
import ButtonPrime from '@components/shared/ButtonPrime';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export const PlanningReviewSmartCheck = ({
  treatment,
  modalDoctorSmartcheck,
  planningSendReady,
}) => {
  return (
    <>
      <div className="flex flex-col w-full">
        <h2 className="text-2xl font-medium text-[#3C4549] border-t border-[#9E9E9E] pt-8 mt-8 max-w-[1145px]">
          Aprobación de caso
        </h2>
        <InlineContainer style={{ alignItems: 'center' }}>
          {treatment?.treatmentStatus.type === 'planning' &&
            [2, 9, 15].includes(treatment?.treatmentStatus.index) && (
              <ButtonPrime
                label="Caso listo para aprobarse"
                onClick={() =>
                  planningSendReady({
                    variables: { patient_id: treatment?.patient.id },
                  })
                }
              />
            )}
          {treatment?.make_smartcheck && (
            <InlineContainer style={{ marginLeft: 14, alignItems: 'center' }}>
              <FeatherIcons icon="calendar" color="#EB9542" />
              <LabelNormal color="#EB9542" fontSize="17">
                Smart check habilitado
              </LabelNormal>
            </InlineContainer>
          )}
          {treatment?.make_smartcheck &&
            treatment?.last_smart_check?.status === 'Done' && (
              <InlineContainer style={{ marginLeft: 14, alignItems: 'center' }}>
                <FeatherIcons icon="calendar" color="#757575" />
                <LabelNormal color="#757575" fontSize="17">
                  Smart check finalizado
                </LabelNormal>
              </InlineContainer>
            )}
          <LabelNormal
            color="#757575"
            fontSize="17"
            onClick={modalDoctorSmartcheck}
            style={{
              textDecorationLine: 'underline',
            }}
          >
            ¿Tu doctor necesita Smart check?
          </LabelNormal>
        </InlineContainer>
      </div>
    </>
  );
};
