import React from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { CardContainer, InlineContainer, LabelNormal } from '../shared/Styled';
import classNames from 'classnames';

const CardWithLink = ({ linkTo, content, icon = null, active = false }) => (
  <div
    className={classNames(
      'shadow-md',
      'w-full',
      'bg-white',
      'rounded-2xl',
      'border-2',
      'cursor-pointer',
      'overflow-hidden',
      {
        'border-button-primary-default': active,
        'border-transparent': !active,
      }
    )}
  >
    <Link to={linkTo}>
      <CardContainer
        style={{
          padding: 20,
        }}
      >
        <InlineContainer style={{ alignItems: 'center' }}>
          {icon && (
            <FeatherIcon
              icon="message-circle"
              color={active ? '#EB9542' : '#3C4549'}
              size={20}
            />
          )}
          <LabelNormal style={{ marginLeft: 10 }} color="#3C4549" fontSize="18">
            {content}
          </LabelNormal>
        </InlineContainer>
      </CardContainer>
    </Link>
  </div>
);

export default CardWithLink;
