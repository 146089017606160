import odontograma from './odontograma';
import { tops, bottoms } from '@components/DoctorFormCA/icons';

const Consideration = ({ odontogramaArray, index, element, horizontal }) => {
  const tooth = element ? odontograma.find((v) => v.name === element[0]) : null;
  const excluir = odontograma.find((v) => v.name === 'Pieza a excluir')?.icon;
  if (
    tooth &&
    odontogramaArray?.find(
      (v, i) => i === index + (horizontal === 'left' ? 0 : 8) && v[0] !== '',
    )
  ) {
    return (
      <div style={{ position: 'absolute', zIndex: 8, top: 30 }}>
        <img
          src={tooth.icon}
          style={{
            cursor: 'pointer',
            marginRight: tooth.name === 'Implante' ? -7 : 0,
          }}
          alt="pointer"
        />
        {tooth.name === 'Implante' && (
          <img
            src={excluir}
            style={{ cursor: 'pointer', paddingTop: 7 }}
            alt="implante"
          />
        )}
      </div>
    );
  }
  return null;
};

const Tooth = ({
  odontogramaArray,
  index,
  vertical = 'top',
  horizontal = 'left',
}) => {
  const element = odontogramaArray?.find(
    (_, i) => i === index + (horizontal === 'left' ? 0 : 8),
  );
  return (
    <>
      <Consideration
        odontogramaArray={odontogramaArray}
        index={index}
        element={element}
        horizontal={horizontal}
      />
      {vertical === 'top' && horizontal === 'left' && <p>{18 - index}</p>}
      {vertical === 'top' && horizontal === 'right' && <p>{21 + index}</p>}
      {vertical === 'top' ? (
        <img
          src={tops[horizontal === 'left' ? index : Math.abs(index - 7)]}
          style={{
            height: '100%',
            transform: horizontal === 'right' ? 'rotateY(180deg)' : '',
            opacity: element ? 0.5 : 1,
          }}
          alt="tooth"
        />
      ) : (
        <img
          src={bottoms[horizontal === 'left' ? index : Math.abs(index - 7)]}
          style={{
            height: '100%',
            transform: horizontal === 'right' ? 'rotateY(180deg)' : '',
            opacity: element ? 0.5 : 1,
          }}
          alt="tooth"
        />
      )}
      {vertical === 'bottom' && horizontal === 'left' && <p>{48 - index}</p>}
      {vertical === 'bottom' && horizontal === 'right' && <p>{31 + index}</p>}
    </>
  );
};

export default Tooth;
