import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';

const ButtonPrimary = ({ children, size, icon = undefined, ...props }) => {
  return (
    <button
      className={classNames(
        'rounded-full',
        'border-2',
        'border-solid',
        'w-full',
        'border-button-primary-default',
        'bg-button-primary-default',
        'hover:bg-button-primary-hover',
        'hover:border-button-primary-hover',
        'disabled:bg-button-primary-disabled',
        'disabled:border-button-primary-disabled',
        'disabled:cursor-not-allowed',
        'text-white',
        {
          'text-base': size === 'sm',
          'px-6': size === 'sm',
          'py-2': size === 'sm',
        },
        {
          'text-lg': size === 'md',
          'px-6': size === 'md',
          'py-2': size === 'md',
        },
        {
          'text-2xl': size === 'lg',
          'px-8': size === 'lg',
          'py-4': size === 'lg',
        },
      )}
      {...props}
    >
      <div className="flex gap-2 justify-center">
        {icon && (
          <FeatherIcon
            icon={icon}
            size={size === 'sm' ? '16' : size === 'md' ? '24' : '32'}
            color="white"
          />
        )}
        {children}
      </div>
    </button>
  );
};

export default ButtonPrimary;
