import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_TREATMENT_BY_UNIVERSITY } from '@graphql/query';
import { useLazyQuery } from '@apollo/client';
import TreatmentCardDoctor from '../../components/shared/TreatmentCardDoctor';
import { onboarding } from '@components/OnBoarding';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
  margin-bottom: 43px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 30px;
`;

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;

const ListCases = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('onboarding'))
      onboarding.open({
        columns: [
          {
            title: 'Este es el tablero de Casos',
            body: 'Aquí aparecerán los casos de los estudiantes por status y en orden de los que requieran atención. Puedes filtrar y buscar por nombre.',
            arrows: true,
            indicator: true,
            position: 'center',
          },
          {
            title: 'Este es el tablero de Profesores',
            body: 'En el perfil de cada profesor puedes ver sus estudiantes asignados.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 1,
          },
          {
            title: 'Este es el tablero de Estudiantes',
            body: 'En el perfil de cada estudiante puedes ver sus casos asignados.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 2,
          },
          {
            title: 'Consulta casos, estudiantes y profesores',
            body: 'En tu perfil puedes visualizar el contador de casos, detalle y tablas de usuarios activos.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 11,
          },
        ],
      });
  }, []);

  const [selectedOption, setSelectedOption] = useState([
    'Requieren atención',
    'En proceso',
    'Terminados / inactivos',
  ]);
  const [treatments, setTreatments] = useState([]);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([
    { name: 'Requieren atención', id: 'Requieren atención' },
    { name: 'En proceso', id: 'En proceso' },
    { name: 'Terminados / inactivos', id: 'Terminados / inactivos' },
  ]);

  useEffect(() => {
    if (search !== '') {
      getTreatments({
        variables: {
          filters: selectedOption.map((o) => o),
          university_id: +localStorage.getItem('b360-role_id'),
          search,
        },
      });
    } else {
      getTreatments({
        variables: {
          filters: selectedOption.map((o) => o),
          university_id: +localStorage.getItem('b360-role_id'),
        },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (search !== '') {
      getTreatments({
        variables: {
          filters: selectedOption.map((o) => o),
          university_id: +localStorage.getItem('b360-role_id'),
          search,
        },
      });
    } else {
      getTreatments({
        variables: {
          filters: selectedOption.map((o) => o),
          university_id: +localStorage.getItem('b360-role_id'),
        },
      });
    }
  }, [search]);

  const [getTreatments] = useLazyQuery(QUERY_GET_TREATMENT_BY_UNIVERSITY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getTreatmentsByUniversities) {
        setTreatments(data.getTreatmentsByUniversities);
      }
      setLoading(false);
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    getTreatments({
      variables: {
        university_id: +localStorage.getItem('b360-role_id'),
        filters: [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     if(search !== ''){
  //         getPatientsByFilters({ variables: { doctor_id: localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
  //     }else{
  //         getPatientsByFilters({ variables: { doctor_id: localStorage.getItem('b360-role_id'), filters: selectedOption } });
  //     }
  // },[selectedOption])

  // useEffect(() => {
  //     if(search !== ''){
  //         getPatientsByFilters({ variables: { doctor_id: localStorage.getItem('b360-role_id'), filters: selectedOption, search } });
  //     }else{
  //         getPatientsByFilters({ variables: { doctor_id: localStorage.getItem('b360-role_id'), filters: selectedOption } });
  //     }
  // },[search])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="casos" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <Title>Casos</Title>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                marginRight="33"
              />
              <DropdownCustom
                label="Filtros"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </InlineContainer>
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            {loading ? (
              <>
                <div style={{ display: 'flex' }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 17px' }}>
                      <Skeleton
                        height={75}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', marginTop: 30 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 17px' }}>
                      <Skeleton
                        height={215}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 17px' }}>
                      <Skeleton
                        height={215}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div
                className="row"
                style={
                  treatments.length <= 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh',
                      }
                    : { display: 'flex', gap: '20px', flex: 1 }
                }
              >
                {treatments.length > 0 ? (
                  <>
                    {treatments.map((item) => (
                      <div key={Math.random()}>
                        <TreatmentCardDoctor
                          style={{
                            borderColor: 'transparent',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            minHeight: 73,
                          }}
                          role={'university'}
                          to={`/universidad/caso/${item.patient.id}`}
                          patient={item.patient}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div style={{}}>
                    <TitleWithoutContent>
                      Aún no hay ningún
                      <br /> caso asignado
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ListCases;
