import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Input from '@components/shared/Input';
import Select from '@components/shared/Select';
import ButtonPrime from '@components/shared/ButtonPrime';
import { MUTATION_SEND_TREATMENT_DHL } from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWaiting from '@components/Modal/ModalWaiting';
import { modal } from '@components/ModalManagement';
import { validateNumber } from '../../../utils';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ReviewPlanning = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [pack, setPackage] = useState('');
  const [caracter, setCaracter] = useState('');
  const [error, setError] = useState('');

  const [sendTreatment] = useMutation(MUTATION_SEND_TREATMENT_DHL, {
    onCompleted: (data) => {
      modal.close();
      modal.open(
        <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
          <LabelNormal fontSize="22" color="#060809">
            Guía de rastreo y carta porte registradas
          </LabelNormal>
          <LabelNormal color="#4F4F4F" fontSize="17">
            El status de envío se actualizará con los status de la paquetería
            seleccionada en el perfil del caso.
          </LabelNormal>
        </ModalSuccess>,
      );
    },
    onError: (err) => {
      modal.close();
      const errors = err.graphQLErrors.map((error) => error.message);
      console.log('Error:', errors);
      if (errors.length) {
        setError(
          errors[0] ===
            'This tracking number is invalid or has no information related.'
            ? '*Número de guía inválido'
            : errors[0],
        );
      }
    },
  });

  const sendTreatmentWainting = () => {
    modal.open(
      <ModalWaiting>
        <LabelNormal fontSize="22" color="#060809">
          Registrando guía de rastreo
        </LabelNormal>
      </ModalWaiting>,
    );
    sendTreatment({
      variables: { treatment_id: id, parcel: pack, waybill_number: caracter },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Envío de tratamiento</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <LabelNormal color="#757575" fontSize="17">
                    Ingresa el número de guía proporcionado por la empresa de
                    paquetería y notifica
                    <br /> al doctor de que su tratamiento ha sido enviado.{' '}
                  </LabelNormal>
                </div>
              </div>
              <div
                className="col-md-12"
                style={{ maxWidth: 1145, marginTop: 39 }}
              >
                <LabelNormal
                  style={{ fontWeight: 400, marginBottom: 15 }}
                  color="#3C4549"
                  fontSize="22"
                >
                  Carta porte
                </LabelNormal>
                <LabelNormal
                  style={{ marginBottom: 25 }}
                  fontSize="17"
                  color="#757575"
                >
                  Descarga la carta y agrégala al paquete para su envío.
                </LabelNormal>
                <ButtonPrime
                  background="transparent"
                  border="2px solid #3C4549"
                  icon="download"
                  label="Descargar"
                  color="#3C4549"
                  onClick={() => {}}
                />
                <div style={{ marginTop: 55 }}>
                  <LabelNormal fontSize="22" color="#3C4549">
                    Seguimiento y actualización de envío
                  </LabelNormal>
                  <div className="row col-md-5">
                    <Select
                      options={[{ name: 'DHL', id: 'DHL' }]}
                      placeholder={'Selecciona una paquetería'}
                      onSelect={setPackage}
                      minWidth="110%"
                      value={pack}
                    />
                  </div>
                  <LabelNormal
                    style={{ marginTop: 25 }}
                    fontSize="22"
                    color="#3C4549"
                  >
                    Número de guía
                  </LabelNormal>
                  <div className="row col-md-5" style={{ marginBottom: 55 }}>
                    <Input
                      onChange={(e) =>
                        e.target.value === ''
                          ? setCaracter(e.target.value)
                          : validateNumber(e.target.value) &&
                              e.target.value.length < 11
                            ? setCaracter(e.target.value)
                            : {}
                      }
                      value={caracter}
                      placeholder="Caracteres"
                    />
                    {error && (
                      <LabelNormal color="#C60E02" fontSize="18">
                        {error}
                      </LabelNormal>
                    )}
                  </div>
                  <ButtonPrime
                    label="Registrar guía de rastreo"
                    disabled={pack && caracter ? false : true}
                    onClick={() => sendTreatmentWainting()}
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ReviewPlanning;
