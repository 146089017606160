import { InlineContainer, LabelNormal, ToothContainer } from '../shared/Styled';
import Glossary from '../DoctorFormCA/glossary';
import Tooth from './tooth';

const Odontogram = ({ up_odontograma, lo_odontograma }) => {
  return (
    <div>
      <LabelNormal color="#3C4549" fontSize="12" style={{ marginBottom: 20 }}>
        Arcada superior
      </LabelNormal>
      <InlineContainer
        style={{
          height: 50,
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          marginLeft: 10,
        }}
      >
        {Array.from(Array(8).keys()).map((_, index) => (
          <ToothContainer>
            <Tooth
              odontogramaArray={up_odontograma}
              index={index}
              vertical="top"
              horizontal="left"
            />
          </ToothContainer>
        ))}
        {Array.from(Array(8).keys()).map((_, index) => (
          <ToothContainer>
            <Tooth
              odontogramaArray={up_odontograma}
              index={index}
              vertical="top"
              horizontal="right"
            />
          </ToothContainer>
        ))}
      </InlineContainer>

      <LabelNormal
        color="#3C4549"
        fontSize="12"
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        Arcada inferior
      </LabelNormal>
      <div className="row">
        <div className="col-md-12">
          <InlineContainer
            style={{
              height: 60,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              marginLeft: 10,
            }}
          >
            {Array.from(Array(8).keys()).map((_, index) => (
              <ToothContainer>
                <Tooth
                  odontogramaArray={lo_odontograma}
                  index={index}
                  vertical="bottom"
                  horizontal="left"
                />
              </ToothContainer>
            ))}
            {Array.from(Array(8).keys()).map((_, index) => (
              <ToothContainer>
                <Tooth
                  odontogramaArray={lo_odontograma}
                  index={index}
                  vertical="bottom"
                  horizontal="right"
                />
              </ToothContainer>
            ))}
          </InlineContainer>
        </div>
      </div>
      <LabelNormal color="#757575" fontSize="17" style={{ marginTop: 40 }}>
        Glosario
      </LabelNormal>
      <Glossary />
    </div>
  );
};

export default Odontogram;
