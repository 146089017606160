import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TREATMENT_INVOICES } from '../../graphql/query';
import axios from 'axios';
import InvoiceCard from '../../components/Doctor/InvoiceCard';
import moment from 'moment';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Invoice = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const { firstName, lastName } = history.location.state;
  const { loading, error, data } = useQuery(QUERY_GET_TREATMENT_INVOICES, {
    variables: { id },
  });
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (data) {
      const treatmentType = parseInt(data.treatment?.treatmentType?.id);
      setInvoices(
        data.treatment?.payments
          ?.filter((payment) => payment.invoiced)
          ?.map((payment) => {
            return payment.invoices.map((invoice) => {
              return {
                id: invoice.id,
                type:
                  payment.type === 'planning'
                    ? 'planificación'
                    : treatmentType === 1
                      ? 'manufactura'
                      : 'tratamiento',
                created_at: invoice.created_at,
                tag:
                  payment.invoices.length > 1
                    ? invoice.partialPayment?.remaining_amount > 0
                      ? 'Pago parcial'
                      : 'Pago complementario completo'
                    : payment.invoices.length === 1 &&
                        invoice.partialPayment?.remaining_amount > 0
                      ? 'Pago parcial'
                      : null,
              };
            });
          })
          .flat()
          .sort((a, b) =>
            moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1,
          ),
      );
    }
  }, [data]);

  const downloadZip = async (invoiceId) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    try {
      const response = await instance.get(`/api/invoice/${invoiceId}/zip`);
      const href = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/zip',
        }),
      );

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'invoice.zip');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <Sidebar name="soporte" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Facturas</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={1}
                    onClick={() => history.push('doctor/tus-casos')}
                    mainLabel={'Tus casos'}
                    endLabel={`${firstName} ${lastName}`}
                  />
                </div>
              </div>
              <div className="col-md-10" style={{ marginBottom: 10 }}>
                <LabelNormal color="#757575" fontSize="17">
                  Consulta y descarga el .zip con el PDF y XML de las facturas
                  que necesites.
                </LabelNormal>
              </div>
            </div>
            {invoices.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {invoices.map((invoice, index) => (
                  <InvoiceCard
                    invoice={invoice}
                    downloadZip={downloadZip}
                    current={index === 0}
                  />
                ))}
              </div>
            ) : (
              <div className="row">
                <div
                  className="col-md-4 offset-md-4"
                  style={{
                    marginBottom: 10,
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LabelNormal color="#757575" fontSize="18">
                    No has facturado ningún pago
                  </LabelNormal>
                </div>
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Invoice;
