import Skeleton from 'react-loading-skeleton';
import FeatherIcon from 'feather-icons-react';
import {
  CardContainer,
  HeaderCardWithDivision,
  InlineContainer,
  LabelNormal,
} from '../shared/Styled';
import FileCard from './FileCard';

const FilesSidebar = ({
  loadingSkeleton,
  loading,
  files,
  setSelectTreatmentFile,
  corrections = false,
  type = 'doctor',
}) => {
  return (
    <div
      style={{
        flexGrow: 0,
        position: 'sticky',
        top: corrections ? '270px' : '225px',
      }}
    >
      <HeaderCardWithDivision>
        <InlineContainer style={{ alignItems: 'center' }}>
          <FeatherIcon icon="folder" size="19" />
          <LabelNormal color="#3C4549" fontSize="17">
            Archivos
          </LabelNormal>
        </InlineContainer>
      </HeaderCardWithDivision>
      <CardContainer
        style={{
          maxHeight: '67vh',
          minHeight: '67vh',
          maxWidth: 180,
          minWidth: 180,
          overflowY: 'scroll',
        }}
      >
        {!loadingSkeleton ? (
          <>
            {files.map((item, index) => (
              <FileCard
                index={index}
                item={item}
                borderTop={
                  index !== 0 && item.section !== files[index - 1].section
                }
                selectFile={() =>
                  !loading
                    ? setSelectTreatmentFile({
                        ...item,
                        index,
                        next:
                          files.length - 1 !== index
                            ? files[index + 1].id
                            : null,
                      })
                    : null
                }
                type={type}
              />
            ))}
          </>
        ) : (
          <>
            {Array.from(Array(10).keys()).map((_) => (
              <Skeleton
                height={91}
                borderRadius={11}
                style={{ marginBottom: 10 }}
                width={120}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
              />
            ))}
          </>
        )}
      </CardContainer>
    </div>
  );
};

export default FilesSidebar;
