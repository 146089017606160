import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_UNIVERSITY,
  MUTATION_ADD_MEMBER_PHOTO,
} from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import { QUERY_GET_UNIVERSITY_ID } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { validateEmail, validatePhone } from '../../../utils';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const UpdateUniversity = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [countryId, setCountryId] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState({});
  const [photo, setPhoto] = useState('');
  const [idUser, setIdUser] = useState('');

  const [getUniversity] = useLazyQuery(QUERY_GET_UNIVERSITY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.university) {
        const university = data.university;

        setIdUser(university.user.id);

        setName(university.name);
        setCountry(university.user.country.lada_code);
        setCountryId(university.user.country.id);
        setFirstName(university.user.first_name);
        setLastName(university.user.last_name);
        setPhone(university.user.phone);
        setEmail(university.user.email);
        setPhoto(university.user.photo_filename);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getUniversity({ variables: { id } });
  }, []);

  const [updateUniversity] = useMutation(MUTATION_UPDATE_UNIVERSITY, {
    onCompleted: async (data) => {
      if (data.updateUniversity) {
        if (file.name)
          addProfilePhoto({
            variables: { file: file, user_id: +data.updateUniversity.user.id },
          });
        else
          modal.open(
            <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 20 }}
              >
                Cambios en perfil guardados
              </LabelNormal>
            </ModalSuccess>,
          );
      }
    },
    onError: (err) => console.log(err),
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Cambios en perfil guardados
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, phone, email]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (phone === '' || !validatePhone(phone)) {
      setDisabled(true);
    } else if (email === '' || !validateEmail(email)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    updateUniversity({
      variables: {
        university_id: +id,
        country_id: +countryId,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        name,
      },
    });
  };

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Editar perfil</Title>
          <div>
            <Avatar
              src={
                photo && !file.name
                  ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                  : file.name
                    ? photo
                    : require('@images/university.png')
              }
              floatButton={true}
              edit={!!photo}
              onChange={onChangeFile}
              height="161"
              width="161"
              borderRadius="30"
            />
          </div>
          <div style={{ marginTop: 38 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Nombre de la universidad"
                onChange={(e) => setName(e.target.value)}
                value={name}
                label="Nombre de la institución"
              />
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="Datos de contacto"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <LabelInput>Teléfono</LabelInput>
              <InlineContainer>
                <Input
                  readOnly
                  disabled
                  value={country !== '' ? country : '+52'}
                  backgroundColor="#F6F6F8"
                  maxWidth="70px"
                />
                <Input
                  placeholder="Teléfono"
                  onChange={setPhone}
                  value={phone}
                  style={{ width: 256 }}
                  mask="phone"
                  validation="phone"
                />
              </InlineContainer>
            </div>
            <div className="col-md-6">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Guardar cambios"
              disabled={disabled}
              onClick={() => handlerSubmit()}
              style={{ marginRight: 10 }}
            />
            <ButtonPrime
              background="transparent"
              label="Cancelar"
              border="2px solid #3C4549"
              color="#3C4549"
              onClick={() => history.goBack()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default UpdateUniversity;
