import Top1 from '@images/arcada-superior/top1.svg';
import Top2 from '@images/arcada-superior/top2.svg';
import Top3 from '@images/arcada-superior/top3.svg';
import Top4 from '@images/arcada-superior/top4.svg';
import Top5 from '@images/arcada-superior/top5.svg';
import Top6 from '@images/arcada-superior/top6.svg';
import Top7 from '@images/arcada-superior/top7.svg';
import Top8 from '@images/arcada-superior/top8.svg';

import Bottom1 from '@images/arcada-inferior/bottom1.svg';
import Bottom2 from '@images/arcada-inferior/bottom2.svg';
import Bottom3 from '@images/arcada-inferior/bottom3.svg';
import Bottom4 from '@images/arcada-inferior/bottom4.svg';
import Bottom5 from '@images/arcada-inferior/bottom5.svg';
import Bottom6 from '@images/arcada-inferior/bottom6.svg';
import Bottom7 from '@images/arcada-inferior/bottom7.svg';
import Bottom8 from '@images/arcada-inferior/bottom8.svg';

const tops = [Top1, Top2, Top3, Top4, Top5, Top6, Top7, Top8];
const bottoms = [
  Bottom1,
  Bottom2,
  Bottom3,
  Bottom4,
  Bottom5,
  Bottom6,
  Bottom7,
  Bottom8,
];

export { bottoms, tops };
