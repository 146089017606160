export const loginSuccess = (token, role, id, role_id) => {
  return {
    type: 'LOGIN_SUCCESS',
    token,
    role,
    id,
    role_id,
  };
};

export const _updateData = (data) => {
  return {
    type: 'UPDATE_DATA',
    userData: data,
  };
};

export const loginFailed = (error) => {
  return {
    type: 'LOGIN_FAILED',
    error,
  };
};

export const logout = () => {
  return {
    type: 'LOGOUT',
  };
};
