import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  MUTATION_CHANGE_STATUS_SMARTCHECK,
  MUTATION_CHANGE_NUMBER_ALIGNER,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import Card from '@components/shared/Card';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ConfirmAligners = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [quantityAligners, setQuantityAligners] = useState('');
  const [details, setDetails] = useState({});

  useEffect(() => {
    if (props.location) {
      setDetails(props.location.state);
    }
  }, []);

  const [changeSmartCheck] = useMutation(MUTATION_CHANGE_STATUS_SMARTCHECK, {
    onCompleted: async (data) => {
      if (data.updateStatusSmartCheck) {
        await changeNumberAligner({
          variables: { smart_check_id: id, aligners_number: +quantityAligners },
        });
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal fontSize="22" color="#060809">
              Smart check finalizado con éxito
            </LabelNormal>
            <LabelNormal fontSize="17" color="#4F4F4F">
              Si necesitas un segundo Smart check, da{' '}
              <a href={`/app/tablero/caso/${data.updateStatusSmartCheck.id}`}>
                click aqui
              </a>
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
  });

  const [changeNumberAligner] = useMutation(MUTATION_CHANGE_NUMBER_ALIGNER, {
    onCompleted: (data) => {},
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="smartchecks" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Confirma alineadores finales</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <LabelNormal color="#757575" fontSize="17">
                    Para que el doctor pueda realizar el pago del número de
                    alineadores correcto, <br /> por favor confirma cuántos son.
                  </LabelNormal>
                </div>
              </div>
              <div className="col-md-12" style={{ maxWidth: 1145 }}>
                <div className="col-md-3">
                  {details.id && (
                    <Card style={{ marginBottom: 30 }}>
                      <InlineContainer>
                        <LabelNormal
                          style={{ marginTop: 0 }}
                          color="#436FF1"
                          fontSize="11"
                        >
                          PX
                        </LabelNormal>
                        <LabelNormal
                          style={{ marginTop: 0 }}
                          color="#4F4F4F"
                          fontSize="15"
                        >{`${details.treatment.patient.first_name} ${details.treatment.patient.last_name}`}</LabelNormal>
                      </InlineContainer>
                      <InlineContainer style={{ marginTop: 0 }}>
                        <LabelNormal
                          style={{ marginTop: 0 }}
                          color="#828282"
                          fontSize="18"
                        >
                          CON
                        </LabelNormal>
                        <LabelNormal
                          style={{ marginTop: 0 }}
                          fontSize="17"
                          color="#3C4549"
                        >
                          Dr.{' '}
                          {`${details.doctor.user.first_name} ${details.doctor.user.last_name}`}
                        </LabelNormal>
                      </InlineContainer>
                      <InlineContainer style={{ marginBottom: 13 }}>
                        <LabelNormal color="#4F4F4F" fontSize="14">
                          ID
                        </LabelNormal>
                        <LabelNormal fontSize="14" color="#4F4F4F">
                          {details.id}
                        </LabelNormal>
                      </InlineContainer>
                      <LabelNormal
                        style={{ marginTop: 13 }}
                        fontSize="15"
                        color="#757575"
                      >
                        Smart Check finalizado
                      </LabelNormal>
                    </Card>
                  )}
                </div>
                <div className="col-md-5" style={{ marginBottom: 30 }}>
                  <Input
                    label="Número final de alineadores"
                    placeholder="Cantidad"
                    onChange={(e) => setQuantityAligners(e.target.value)}
                  />
                </div>
                <div className="col-md-5">
                  <ButtonPrime
                    label="Confirmar alineadores"
                    disabled={quantityAligners ? false : true}
                    onClick={() =>
                      changeSmartCheck({
                        variables: { smart_check_id: id, status: 'Done' },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ConfirmAligners;
