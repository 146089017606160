/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_PATIENT_BY_FILTER } from '@graphql/query';
import { QUERY_GET_TREATMENTS_BY_FILTER } from '@graphql/queries/doctor/cases';
import { useLazyQuery, useQuery } from '@apollo/client';
import TreatmentCardDoctor from '../../components/shared/TreatmentCardDoctor';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { useAlertDeploy } from '../../components/hooks/useAlertDeploy';
import { ModalUnichatUpdateAlert } from '../../components/Modal/ModalUnichatUpdateAlert';
import { onboarding } from '@components/OnBoarding';
import TreatmentCardContainer from '@components/kanban/treatment-card-container';
import { UserRoleEnum } from '@models/user-role';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
  transition: all 0.3s ease-out;
  &:hover {
    font-size: 25px;
    border-bottom: 2px solid transparent !important;
    color: #3c4549;
  }
  &:active {
    color: #3c4549;
    border-bottom: 2px solid #3c4549 !important;
  }
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 30px;
`;

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;

const options = [
  { id: 'Activos', name: 'Activos' },
  { id: 'Inactivos', name: 'Inactivos' },
  { id: 'Finalizados', name: 'Finalizados' },
  { id: 'Registro incompleto', name: 'Registro incompleto' },
];

const YourCases = (props) => {
  useAlertDeploy({
    component: ModalUnichatUpdateAlert,
  });
  const history = useHistory();

  const [selectItem, setSelectItem] = useState('cryst_aligner');
  const [selectedOption, setSelectedOption] = useState([
    'Activos',
    'Inactivos',
    'Finalizados',
    'Registro incompleto',
  ]);
  const [treatments, setTreatments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [search, setSearch] = useState('');

  const { loading, refetch } = useQuery(QUERY_GET_TREATMENTS_BY_FILTER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        doctor_id: localStorage.getItem('b360-role_id'),
        filters: [],
      },
    },
    onCompleted: (data) => {
      if (data.getTreatmentsByFilters) {
        setTreatments(
          data.getTreatmentsByFilters.map((treatment) => ({
            ...treatment,
            treatmentType: {
              ...treatment.treatmentType,
              id: parseInt(treatment.treatmentType.id),
            },
            treatmentStatus: {
              ...treatment.treatmentStatus,
              id: parseInt(treatment.treatmentStatus.id),
            },
          }))
        );
      }
    },
  });

  useEffect(() => {
    if (localStorage.getItem('memori-treatment')) {
      setSelectItem(localStorage.getItem('memori-treatment'));
    }
  }, []);

  useEffect(() => {
    if (selectItem) {
      localStorage.setItem('memori-treatment', selectItem);
    }
  }, [selectItem]);

  useEffect(() => {
    if (
      localStorage.getItem('b360-role') === 'student' &&
      localStorage.getItem('onboarding')
    )
      onboarding.open({
        columns: [
          {
            title: 'Este es el tablero de Tus casos',
            body: 'Aquí cada caso aparecerá por status y en orden de los que requieran de tu atención. Puedes filtrar y buscar por nombre y elegir entre las pestañas de tratamientos.',
            arrows: true,
            indicator: true,
            position: 'center',
          },
          {
            title: 'Agrega nuevos casos',
            body: 'Puedes agregar nuevos casos en unos cuantos clicks y rastrear el seguimiento de ellos.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 0,
          },
          {
            title: 'Estamos para ayudarte',
            body: 'En soporte puedes comunicarte con nuestro equipo para resolver errores de la plataforma.',
            arrows: true,
            indicator: true,
            position: 'left',
            section: 10,
          },
        ],
      });
  }, []);

  useEffect(() => {
    if (search !== '') {
      refetch({
        doctor_id: localStorage.getItem('b360-role_id'),
        filters: selectedOption,
        search,
      });
    } else {
      refetch({
        doctor_id: localStorage.getItem('b360-role_id'),
        filters: selectedOption,
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (search !== '') {
      refetch({
        doctor_id: localStorage.getItem('b360-role_id'),
        filters: selectedOption,
        search,
      });
    } else {
      refetch({
        doctor_id: localStorage.getItem('b360-role_id'),
        filters: selectedOption,
      });
    }
  }, [search]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <InlineContainer
            style={{
              justifyContent: 'space-between',
              marginRight: '19%',
              marginLeft: '19%',
              marginBottom: 43,
            }}
          >
            <LabelNormal
              fontSize="25"
              onClick={() => setSelectItem('cryst_aligner')}
              color={selectItem === 'cryst_aligner' ? '#3C4549' : '#C8C8C8'}
              style={{
                borderBottomColor:
                  selectItem === 'cryst_aligner' ? '#3C4549' : 'transparent',
                borderBottomStyle: 'solid',
                borderBottomWidth: 2,
                cursor: 'pointer',
              }}
            >
              Cryst Aligner
            </LabelNormal>
            <LabelNormal
              fontSize="25"
              onClick={() => setSelectItem('smile_system')}
              color={selectItem === 'smile_system' ? '#3C4549' : '#C8C8C8'}
              style={{
                borderBottomColor:
                  selectItem === 'smile_system' ? '#3C4549' : 'transparent',
                borderBottomStyle: 'solid',
                borderBottomWidth: 2,
                cursor: 'pointer',
              }}
            >
              Smile System
            </LabelNormal>
          </InlineContainer>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                marginRight="33"
              />
              <DropdownCustom
                label="Filtros"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </InlineContainer>
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            {loading ? (
              <>
                <div style={{ display: 'flex' }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={Math.random()}
                    >
                      <Skeleton
                        height={75}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', marginTop: 30 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={Math.random()}
                    >
                      <Skeleton
                        height={215}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      style={{ padding: '0px 17px 0px 17px' }}
                      key={Math.random()}
                    >
                      <Skeleton
                        height={215}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div
                className="row"
                style={
                  treatments.length <= 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh',
                      }
                    : {
                        display: 'flex',
                        gap: '20px',
                        flex: 1,
                        marginBottom: 24,
                      }
                }
              >
                {treatments.length > 0 ? (
                  <>
                    {treatments
                      .filter(
                        (treatment) =>
                          treatment.treatmentType?.name
                            ?.replace(' ', '_')
                            .toLocaleLowerCase() === selectItem
                      )
                      .map((treatment) => (
                        <div key={Math.random()}>
                          <TreatmentCardContainer
                            treatment={treatment}
                            viewer={UserRoleEnum.DOCTOR}
                          />
                        </div>
                      ))}
                  </>
                ) : (
                  <div style={{}}>
                    <TitleWithoutContent>
                      Aún no hay <br /> casos registrados
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default YourCases;
