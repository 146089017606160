import Input from '@components/shared/Input';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import TextInput from '../shared/inputs/TextInput';
import { Column, Row } from '../shared/Styled';
import PhoneInput from '../shared/inputs/PhoneInput';
import AddressForm from './AddressForm';
import { useEffect } from 'react';

const B360NetworkDoctorOfficeForm = ({
  countries,
  country,
  setValid,
  setValues,
  defaultValues = null,
  defaultValuesAddress = null,
  doctor = false,
  submit = 0,
  clear = 0,
}) => {
  const [addressValid, setAddressValid] = useState(false);
  const [addressValues, setAddressValues] = useState({});
  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          clinic_name: '',
          website: '',
          clinic_phone: '',
          alternative_phone: '',
        },
    mode: 'onBlur',
  });

  const watchAllFields = useWatch({ control });

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key]),
      );
      trigger();
    }
  }, [defaultValues]);

  useEffect(() => {
    setValid(isValid && addressValid);
    if (isValid && addressValid) {
      const values = getValues();
      setValues({
        ...values,
        ...addressValues,
      });
    } else {
      setValues({});
    }
  }, [isValid, addressValid, watchAllFields, addressValues]);

  useEffect(() => {
    if (clear > 0) {
      console.log('Clearing all errors');
      clearErrors();
    }
  }, [clear]);

  useEffect(() => {
    if (submit > 0) trigger();
  }, [submit]);

  return (
    <Column gap="3px" style={{ width: '100%' }}>
      <TextInput
        name="clinic_name"
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        rules={{ required: 'Introduce el nombre de tu consultorio o clínica' }}
        placeholder="Consultorio"
        label="Nombre del consultorio o clínica"
        muted="Asigna un nombre a tu dirección para ubicarla fácilmente en el futuro."
        maxWidth="70%"
      />
      <TextInput
        name="website"
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        rules={{
          pattern: {
            value: /^((ftp|http|https):\/\/)?(www\.)?([A-z0-9]+)\.([A-z]{2,})/,
            message: 'URL inválida',
          },
        }}
        placeholder="URL"
        label="Sitio web del consultorio (Opcional)"
        maxWidth="40%"
      />
      <Row gap="12px">
        <PhoneInput
          name="clinic_phone"
          control={control}
          errors={errors}
          rules={{
            required: 'Introduce el teléfono de la clínica',
            minLength: { value: 8, message: 'Número inválido' },
            maxLenght: { value: 12, message: 'Número inválido' },
          }}
          clearErrors={clearErrors}
          setValue={setValue}
          countries={countries}
          country={country}
          placeholder="Teléfono"
          label="Teléfono"
          style={{ width: 256 }}
        />
        <PhoneInput
          name="alternative_phone"
          control={control}
          errors={errors}
          countries={countries}
          country={country}
          rules={{
            minLength: { value: 8, message: 'Número inválido' },
            maxLenght: { value: 12, message: 'Número inválido' },
          }}
          clearErrors={clearErrors}
          setValue={setValue}
          placeholder="Otro teléfono (Opcional)"
          label="Otro teléfono (Opcional)"
          style={{ width: 256 }}
        />
      </Row>
      <AddressForm
        country={country}
        setValid={setAddressValid}
        setValues={setAddressValues}
        defaultValues={defaultValuesAddress}
        doctor={doctor}
        submit={submit}
        clear={clear}
      />
    </Column>
  );
};

export default B360NetworkDoctorOfficeForm;
