import { gql } from '@apollo/client';

export const QUERY_GET_USER_BY_UUID = gql`
  query getUserByUuid($uuid: String!) {
    getUserByUuid(input: { uuid: $uuid }) {
      user {
        id
        first_name
        last_name
        email
        phone
        type
      }
      role_id
    }
  }
`;

export const QUERY_GET_COUNTRY_DATA = gql`
  query getCountryData {
    getCountryData {
      id
      name
      lada_code
    }
  }
`;

export const QUERY_GET_COUNTRIES = gql`
  query countries {
    countries {
      data {
        id
        name
        lada_code
      }
    }
  }
`;

export const QUERY_GET_DEPARTAMENT = gql`
  query departments {
    departments {
      data {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_ROLES = gql`
  query roles {
    roles {
      data {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_ADMINS = gql`
  query getAdminsByFilters(
    $departments: [Int!]!
    $search: String
    $countries: [Int!]
  ) {
    getAdminsByFilters(
      input: {
        departments: $departments
        search: $search
        countries: $countries
      }
    ) {
      id
      status
      user {
        id
        first_name
        last_name
        photo_filename
        gender
      }
      department {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_CHAT_MESSAGES = gql`
  query getChatMessages($channel_url: String!) {
    getChatMessages(input: { channel_url: $channel_url }) {
      chatMessages {
        id
        from {
          id
          first_name
          last_name
        }
        message
        readed
      }
    }
  }
`;

export const QUERY_GET_DOCTORS = gql`
  query getDoctorsByFilters(
    $filters: [String!]!
    $search: String
    $countries: [Int!]
  ) {
    getDoctorsByFilters(
      input: { filters: $filters, search: $search, countries: $countries }
    ) {
      id
      status
      user {
        id
        first_name
        last_name
        photo_filename
        gender
      }
      active_treatments {
        id
      }
      total_treatments {
        id
      }
      active_treatments_cryst_aligner {
        id
      }
      active_treatments_smile_system {
        id
      }
      count_treatments
      count_total_treatments
      count_treatments_smile_system
      count_treatments_cryst_aligner
    }
  }
`;

export const QUERY_GET_ADMIN = gql`
  query admin($id: ID) {
    admin(id: $id) {
      status
      id
      user {
        id
        first_name
        last_name
        email
        phone
        gender
        photo_filename
        country {
          id
          name
        }
      }
      super_admin
      department {
        id
        name
      }
      administratorRoles {
        id
        role {
          id
          name
        }
        country {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_GET_DATA_POSTAL_CODE = gql`
  query getDomDataByCode($postal_code: String!, $country_id: Int!) {
    getDomDataByCode(
      input: { postal_code: $postal_code, country_id: $country_id }
    ) {
      state {
        id
        name
      }
      locality
      suburbs {
        name
      }
      timezone_name
      timezone
    }
  }
`;

export const QUERY_GET_DETAILS_DOCTOR = gql`
  query doctor($id: ID) {
    doctor(id: $id) {
      id
      user {
        id
        first_name
        last_name
        photo_filename
        email
        phone
        gender
        type
        country {
          id
          name
        }
        created_at
      }
      comments {
        id
        text
        administrator {
          user {
            first_name
            last_name
          }
        }
        created_at
      }
      university {
        id
        name
        graduate_periods
        type
      }
      teacher {
        id
      }
      birthdate
      id_doctor
      id_adviser
      category
      professional_license
      is_doctor
      enabled_at
      cryst_aligner
      smile_system
      status
      semester
      timezone
      timezone_name
      clinic {
        id
        name
        website
        phone
        alternative_phone
        street
        number
        internal_number
        postal_code
        suburb
        state
        locality
      }
      treatments {
        id
        patient {
          id
          first_name
          last_name
        }
        treatmentStatus {
          id
          name
          color_type
        }
        treatmentType {
          id
          name
        }
        channels {
          name
          channel_url
          status
        }
      }
      addresses {
        id
        name
        street
        number
        internal_number
        postal_code
        suburb
        state
        default
        locality
      }
      taxdatas {
        id
        business_name
        type
        rfc
        cfdiUsage {
          id
          name
        }
        street
        number
        internal_number
        postal_code
        suburb
        state
        default
        locality
      }
      cards {
        id
        service_id
        service_id_ca
        service_id_ss
        last_digits
        brand
        default
      }
      active_treatments {
        id
      }
      active_treatments_cryst_aligner {
        id
      }
      active_treatments_smile_system {
        id
      }
      count_treatments_cryst_aligner
      count_treatments_smile_system
      count_treatments
    }
  }
`;

export const QUERY_GET_DOCTOR_TOKEN = gql`
  query getDoctorAuth {
    getDoctorAuth {
      id
      user {
        id
        first_name
        last_name
        photo_filename
        email
        phone
        gender
        type
        country {
          id
          name
        }
        created_at
      }
      university {
        id
        name
      }
      teacher {
        id
      }
      id_doctor
      birthdate
      id_adviser
      category
      professional_license
      is_doctor
      cryst_aligner
      smile_system
      status
      timezone
      timezone_name
      clinic {
        id
        name
        website
        phone
        alternative_phone
        street
        number
        internal_number
        postal_code
        suburb
        state
        locality
      }
      treatments {
        id
        treatmentType {
          id
          name
        }
      }
      addresses {
        id
        name
        street
        number
        internal_number
        postal_code
        suburb
        state
        default
        references
        locality
      }
      taxdatas {
        id
        business_name
        type
        rfc
        taxSystem {
          id
          name
        }
        cfdiUsage {
          id
          name
        }
        street
        number
        internal_number
        postal_code
        suburb
        state
        default
        locality
      }
      cards {
        id
        service_id
        service_id_ca
        service_id_ss
        last_digits
        brand
        default
      }
      active_treatments {
        id
      }
      active_treatments_cryst_aligner {
        id
      }
      active_treatments_smile_system {
        id
      }
      count_treatments_cryst_aligner
      count_treatments_smile_system
      count_treatments
      surgeries {
        id
      }
    }
  }
`;

export const QUERY_GET_DEFAULT_ADDRESS_DOCTOR = gql`
  query getDefaultAddress($user_id: ID!) {
    getDefaultAddress(input: { user_id: $user_id }) {
      id
      name
      street
      number
      internal_number
      postal_code
      suburb
      state
      locality
    }
  }
`;

export const QUERY_GET_DEFAULT_TAX_DATA = gql`
  query getDefaultTaxData($user_id: ID!) {
    getDefaultTaxData(input: { user_id: $user_id }) {
      id
      business_name
      type
      rfc
      cfdiUsage {
        id
        name
      }
      taxSystem {
        id
        name
      }
      street
      number
      internal_number
      postal_code
      suburb
      state
      default
      locality
    }
  }
`;

export const QUERY_GET_DEFAULT_PAYMENT = gql`
  query getDefaultPaymentMethod($user_id: ID!) {
    getDefaultPaymentMethod(input: { user_id: $user_id }) {
      id
      service_id
      service_id_ca
      service_id_ss
      last_digits
      brand
      funding
    }
  }
`;

export const QUERY_GET_PATIENT_BY_FILTER = gql`
  query getPatientsByFilters(
    $doctor_id: ID!
    $filters: [String!]!
    $search: String
  ) {
    getPatientsByFilters(
      input: { doctor_id: $doctor_id, filters: $filters, search: $search }
    ) {
      id
      treatment
      first_name
      last_name
      gender
      birthdate
      phone
      email
      photo_filename

      treatment_related {
        smartsChecks {
          id
          start_date
          finish_date
          zoom_link
          status
        }
        id
        treatmentStatus {
          id
          name
          admin_name
          color_type
          index
          type
        }
        channelNotifications {
          channel {
            channel_url
          }
          new_message_admin
          new_message_doctor
        }
        channels {
          name
          channel_url
          status
        }
        make_smartcheck
        message_doctor
        archived
        is_refinement
        treatment_id
        payments {
          id
          type
          status
        }
        messages {
          id
          from {
            id
          }
          readed
        }
      }
    }
  }
`;

export const QUERY_GET_ME_DATA = gql`
  query me {
    me {
      id
      first_name
      last_name
      country {
        id
        name
        lada_code
      }
      email
      gender
      phone
      photo_filename
      created_at
      administrator {
        department {
          id
          name
        }
        super_admin
        administratorRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const QUERY_GET_SIDEBAR_DATA = gql`
  query me {
    me {
      id
      photo_filename
    }
  }
`;

export const QUERY_GET_MY_COUNTRIES = gql`
  query getCountries {
    getCountries {
      id
      name
    }
  }
`;

export const QUERY_GET_PRICE_BY_TYPE = gql`
  query getPriceByType($type: String!, $pack_id: ID) {
    getPriceByType(input: { type: $type, pack_id: $pack_id }) {
      name
      type
      currency
      amount
      pack {
        id
        name
        principal_aligners
        refinement_aligners
      }
    }
  }
`;

export const QUERY_GET_PACK = gql`
  query getPacksByType($type: String!) {
    getPacksByType(input: { type: $type }) {
      id
      name
      principal_aligners
      refinement_aligners
      price {
        currency
        amount
      }
    }
  }
`;

export const QUERY_GET_PAYMENT_INSTALLMENTS = gql`
  query getPaymentInstallments(
    $user_id: ID!
    $treatment_id: ID!
    $payment_method_ca: String
    $payment_method_ss: String
    $amount: Float!
    $currency: String!
    $treatment_type: String!
    $process: String
  ) {
    getPaymentInstallments(
      input: {
        user_id: $user_id
        treatment_id: $treatment_id
        payment_method_ca: $payment_method_ca
        payment_method_ss: $payment_method_ss
        amount: $amount
        currency: $currency
        treatment_type: $treatment_type
        process: $process
      }
    ) {
      intent_id
      installments {
        count
        interval
        type
        description
        price
      }
      currency_before
      amount_before
    }
  }
`;

export const QUERY_GET_PATIENT = gql`
  query pacient($id: ID) {
    pacient(id: $id) {
      id
      first_name
      last_name
      treatment
      gender
      birthdate
      phone
      email
      photo_filename
      treatment_related {
        treatment_id
        is_refinement
        last_smart_check {
          id
          status
        }
        smartsChecks {
          id
          start_date
          finish_date
          zoom_link
          status
        }
        id
        stl_type
        link_data
        treatmentStatus {
          id
          name
          index
          type
          admin_name
          color_type
        }
        treatmentType {
          id
        }
        visor_url
        visor_desk_link
        visor_patient_link
        form_status
        form_correction
        contact_question
        comments {
          id
          text
          administrator {
            user {
              first_name
              last_name
            }
          }
          created_at
        }
        files {
          id
          name
          fileUrl
          file_name
          file_type
        }
        histories {
          id
          type
          title
          message
          tag
          user {
            id
            first_name
            last_name
          }
          admin_title
          admin_message
          created_at
        }
        channels {
          name
          channel_url
          status
        }
        channelNotifications {
          channel {
            channel_url
          }
          new_message_admin
          new_message_doctor
        }
        bracket_ss
        aligners_number
        bottom_aligners_number
        planning_software
        planning_case_id
        archived
        message_doctor
        more_smartchecks
        make_smartcheck
        has_changes_ss
        has_changes_ca
        had_planning_comments
        manufact_stl_filename
        aligner_send_type
        partialities {
          upper
          bottom
          id
        }
        doctor {
          id
          category
          university {
            id
            name
          }
          user {
            id
            first_name
            last_name
            photo_filename
            type
            country {
              id
              name
            }
          }
        }
        teacher {
          id
          user {
            id
            first_name
            last_name
            photo_filename
            country {
              id
              name
            }
          }
        }
        formArcade {
          treatment {
            id
          }
          type
          middle_line
          has_middle_line_left
          middle_line_left
          has_middle_line_right
          middle_line_right
          overbite
          overbite_data
          overjet
          overjet_data
          spaces
          up_spaces_data
          lo_spaces_data
          arch_width
          arch_exp
          arch_exp_data
          arch_shr
          arch_shr_data
          arch_teeth_data
          cross_bite
          cross_bite_subselect
          crossbite_exp
          crossbite_exp_data
          crossbite_shr
          crossbite_shr_data
          crossbite_teeth_data
          procline_adjust_maxilla
          procline_adjust_maxilla_data
          procline_adjust_jaw
          procline_adjust_jaw_data
          expand_adjust_maxilla
          expand_adjust_maxilla_data
          expand_adjust_jaw
          expand_adjust_jaw_data
          treatment_type
          aligner_pack
          spee
        }
        formShape {
          treatment {
            id
          }
          arc_shape
          desired_class
          molar_class
          molar_left_type
          molar_right_type
          canine_relationship
          canine_left
          canine_right
          anterior_area
        }
        formIpr {
          treatment {
            id
          }
          instructions
          up_ipr_data
          lo_ipr_data
          torque
          back_teeth
          back_teeth_to
          front_teeth
          front_teeth_to
          teeth_torque_data
          additional_indications
          kind
        }
        formBracket {
          treatment {
            id
          }
          type
          up_positions_data
          lo_positions_data
        }
        formOdontogram {
          treatment {
            id
          }
          type
          up_left_third_molar_data
          up_left_second_molar_data
          up_left_first_molar_data
          up_left_second_premolar_data
          up_left_first_premolar_data
          up_left_canine_data
          up_left_lateral_incisor_data
          up_left_central_incisor_data
          up_right_third_molar_data
          up_right_second_molar_data
          up_right_first_molar_data
          up_right_second_premolar_data
          up_right_first_premolar_data
          up_right_canine_data
          up_right_lateral_incisor_data
          up_right_central_incisor_data
          lo_left_third_molar_data
          lo_left_second_molar_data
          lo_left_first_molar_data
          lo_left_second_premolar_data
          lo_left_first_premolar_data
          lo_left_canine_data
          lo_left_lateral_incisor_data
          lo_left_central_incisor_data
          lo_right_third_molar_data
          lo_right_second_molar_data
          lo_right_first_molar_data
          lo_right_second_premolar_data
          lo_right_first_premolar_data
          lo_right_canine_data
          lo_right_lateral_incisor_data
          lo_right_central_incisor_data
          comments
        }
        additional_instructions
        payments {
          id
          amount
          history_amount
          history_currency
          amount_usd
          currency
          invoiced
          status
          type
          coupon {
            id
            coupon_code
          }
          card {
            id
          }
          invoiced
          invoices {
            id
          }
          type
          status
          bank_code
          bank_name
          clabe
          reference
          taxdata {
            id
            business_name
            type
            rfc
            street
            number
            suburb
            postal_code
            state
            cfdiUsage {
              id
              name
            }
            taxSystem {
              id
              name
            }
          }
        }
        manufact {
          id
          pack {
            id
            name
          }
        }
        messages {
          id
          from {
            id
          }
          readed
        }
      }
      doctor {
        id
        category
        university {
          id
          name
        }
        user {
          id
          first_name
          last_name
          photo_filename
          email
          country {
            id
            name
          }
        }
      }
    }
  }
`;

export const QUERY_GET_PATIENT_FILES = gql`
  query getPatientFiles($patient_id: ID!, $onlyErrors: Boolean!) {
    getPatientFiles(
      input: { patient_id: $patient_id, onlyErrors: $onlyErrors }
    ) {
      id
      fileUrl
      file_type
      file_name
      name
      section
      treatment {
        id
        stl_type
        link_data
        form_status
        form_correction
        contact_question
      }
      FileStatus {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_PATIENT_MANUFACT_STL = gql`
  query getPatientStlManufact($patient_id: ID!) {
    getPatientStlManufact(input: { patient_id: $patient_id }) {
      fileName
      fileUrl
    }
  }
`;

export const QUERY_GET_TREATMENT_CA_BY_DEPARTAMENT = gql`
  query getTreatmentCAByDepartments {
    getTreatmentCAByDepartments {
      data {
        id
        name
        treatmentsCA {
          id
          treatmentStatus {
            id
            name
            admin_name
            color_type
            index
          }
          smartsChecks {
            id
            start_date
            finish_date
            zoom_link
            status
          }
          patient {
            id
            first_name
            last_name
            photo_filename
            treatment
          }
        }
      }
    }
  }
`;

export const QUERY_GET_TREATMENT_SS_BY_DEPARTAMENT = gql`
  query getTreatmentSSByDepartments {
    getTreatmentSSByDepartments {
      data {
        id
        name
        treatmentsSS {
          id
          treatmentStatus {
            id
            name
            admin_name
            color_type
            index
          }
          smartsChecks {
            id
            start_date
            finish_date
            zoom_link
            status
          }
          patient {
            id
            first_name
            last_name
            photo_filename
            treatment
          }
        }
      }
    }
  }
`;

export const QUERY_GET_TREATMENT_BY_DEPARTAMENT = gql`
  query getTreatmentsByDepartments(
    $treatment: TreatmentTypeEnum!
    $categories: [TreatmentStatusAdminEnum!]!
    $countries: [ID!]!
    $search: String
  ) {
    getTreatmentsByDepartments(
      input: {
        treatment: $treatment
        categories: $categories
        countries: $countries
        search: $search
      }
    ) {
      id
      name
      treatments {
        id
        visor_url
        aligners_number
        bottom_aligners_number
        message_doctor
        is_refinement
        treatment_id
        treatmentStatus {
          id
          name
          admin_name
          color_type
          index
          type
        }
        treatmentType {
          id
          name
        }
        messages {
          id
          from {
            id
          }
          readed
        }
        smartsChecks {
          id
          start_date
          finish_date
          zoom_link
          status
          opened
        }
        patient {
          id
          first_name
          last_name
          photo_filename
          gender
          treatment
        }
        doctor {
          id
          user {
            id
          }
        }
        parentTreatment {
          id
        }
        childTreatment {
          id
        }
      }
    }
  }
`;

export const QUERY_GET_CORRECTIONS_COMMENT_BY_TREATMENT = gql`
  query getCorrectionCommentsByTreatment(
    $treatment_id: ID!
    $onlyErrors: Boolean!
  ) {
    getCorrectionCommentsByTreatment(
      input: { treatment_id: $treatment_id, onlyErrors: $onlyErrors }
    ) {
      correctionsComments {
        title
        comments
        type
        file_id
        file {
          id
          name
          file_name
          section
        }
      }
      history {
        id
        file {
          id
          name
          file_name
          section
        }
      }
    }
  }
`;

export const QUERY_DEFAULT_ADDRESS_DATA = gql`
  query getDefaultAddressData($user_id: ID!, $patient_id: ID!) {
    getDefaultAddressData(
      input: { user_id: $user_id, patient_id: $patient_id }
    ) {
      patient_name
      doctor_name
      defaultAddress {
        id
        name
        street
        number
        internal_number
        suburb
        postal_code
        city
        state
      }
    }
  }
`;

export const QUERY_GET_PLANNING_DAY = gql`
  query getPlanningDay($date: Date!) {
    getPlanningDay(input: { date: $date }) {
      available
      hours {
        available
        hour
        hour_string
      }
    }
  }
`;

export const QUERY_GET_PLANNING_CALENDAR = gql`
  query getPlanningCalendar($month: String!, $year: String!) {
    getPlanningCalendar(input: { month: $month, year: $year }) {
      year
      month
      day
      date
      available
    }
  }
`;

export const QUERY_GET_ALL_SMARTCHECKS = gql`
  query getAllSmartChecksByDate($date: Date!) {
    getAllSmartChecksByDate(input: { date: $date }) {
      value
      hours {
        value
        smartChecks {
          id
          start_date
          finish_date
          zoom_link
          status
          aligners_number
          opened
          treatment {
            id
            patient {
              id
              first_name
              last_name
            }
            treatmentType {
              id
              name
            }
          }
          doctor {
            id
            user {
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SENDBIRS_ACCESS_TOKEN = gql`
  query getSendbirdAccessToken {
    getSendbirdAccessToken {
      sendbird_access_token
      userId
    }
  }
`;

export const QUERY_GET_UNIVERSITIES = gql`
  query getUniversitiesByFilters($filters: [String!]!, $search: String) {
    getUniversitiesByFilters(input: { filters: $filters, search: $search }) {
      id
      name
      type
      teachers {
        id
      }
      doctors {
        id
      }
      user {
        id
        country {
          id
          name
        }
      }
      count_doctors
      count_teachers
      count_treatments
    }
  }
`;

export const QUERY_GET_UNIVERSITY_ID = gql`
  query university($id: ID) {
    university(id: $id) {
      id
      name
      type
      disabled
      count_teachers
      graduate_periods
      teachers {
        id
        user {
          id
          first_name
          last_name
        }
        count_doctors
        count_students
      }
      students {
        id
        user {
          id
          first_name
          last_name
        }
        active_treatments {
          id
        }
        count_total_treatments
        count_treatments
        count_inactives_treatments
      }
      treatments {
        id
        patient {
          id
          first_name
          last_name
        }
        treatmentStatus {
          id
          name
        }
        treatmentType {
          id
          name
        }
      }
      user {
        id
        first_name
        last_name
        photo_filename
        country {
          id
          name
          lada_code
        }
        phone
        email
        created_at
      }
      count_treatments
      count_inactives_treatments
      count_finished_treatments
      count_heredity_treatments
      count_doctors
      count_doctors_active
      count_doctors_inactive
    }
  }
`;

export const QUERY_GET_TEACHER = gql`
  query teacher($id: ID!) {
    teacher(id: $id) {
      id
      status
      doctors {
        id
        user {
          id
          first_name
          last_name
          gender
        }
      }
      students {
        id
        user {
          id
          first_name
          last_name
          gender
        }
      }
      treatments {
        id
        doctor {
          id
          user {
            first_name
            last_name
          }
        }
        patient {
          id
          first_name
          last_name
        }
      }
      treatments_ca {
        id
      }
      treatments_ss {
        id
      }
      count_treatments
      count_students
      user {
        id
        first_name
        last_name
        email
        gender
        country {
          id
          name
        }
        photo_filename
      }
      university {
        id
        name
      }
      count_doctors
    }
  }
`;

export const QUERY_GET_STUDENTS_BY_UNIVERSITIES = gql`
  query getStudentsByUniversities(
    $filters: [String!]!
    $university_id: Int!
    $search: String
  ) {
    getStudentsByUniversities(
      input: {
        filters: $filters
        university_id: $university_id
        search: $search
      }
    ) {
      id
      user {
        id
        first_name
        last_name
        photo_filename
        gender
      }
      count_total_treatments
      count_inactives_treatments
    }
  }
`;

export const QUERY_GET_TEACHERS_BY_UNIVERSITIES = gql`
  query getTeachersByUniversities(
    $filters: [String!]!
    $university_id: Int!
    $search: String
  ) {
    getTeachersByUniversities(
      input: {
        filters: $filters
        university_id: $university_id
        search: $search
      }
    ) {
      id
      user {
        id
        first_name
        last_name
        photo_filename
        gender
      }
      count_doctors
    }
  }
`;

export const QUERY_GET_TREATMENT_BY_UNIVERSITY = gql`
  query getTreatmentsByUniversities(
    $filters: [String!]!
    $university_id: Int!
    $search: String
  ) {
    getTreatmentsByUniversities(
      input: {
        filters: $filters
        university_id: $university_id
        search: $search
      }
    ) {
      id
      patient {
        id
        first_name
        last_name
        photo_filename
        treatment
        treatment_related {
          smartsChecks {
            id
            start_date
            finish_date
            zoom_link
            status
          }
          id
          stl_type
          link_data
          treatmentStatus {
            id
            name
            index
            type
            admin_name
            color_type
          }
          visor_url
          files {
            id
            name
            fileUrl
            file_name
            file_type
          }
          histories {
            id
            type
            title
            message
            tag
            user {
              id
              first_name
              last_name
            }
            admin_title
            admin_message
            created_at
          }
          bracket_ss
          aligners_number
          bottom_aligners_number
          planning_software
          planning_case_id
          archived
          message_doctor
          more_smartchecks
          make_smartcheck
          form_status
          form_correction
          contact_question
        }
      }
      doctor {
        id
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const QUERY_GET_HISTORY_CASE = gql`
  query getAssingHistories($patient_id: ID!) {
    getAssingHistories(input: { patient_id: $patient_id }) {
      doctors_histories {
        doctor {
          id
          user {
            id
            first_name
            last_name
          }
        }
        histories {
          id
          message
          created_at
        }
      }
      teachers_histories {
        teacher {
          id
          user {
            id
            first_name
            last_name
          }
        }
        histories {
          id
          message
          created_at
        }
      }
    }
  }
`;

export const QUERY_GET_TREATMENT_TEACHERS = gql`
  query getTreatmentsByTeachers(
    $filters: [String!]!
    $teacher_id: Int!
    $search: String
  ) {
    getTreatmentsByTeachers(
      input: { filters: $filters, teacher_id: $teacher_id, search: $search }
    ) {
      id
      doctor {
        id
        user {
          id
          first_name
        }
      }
      patient {
        id
        first_name
        last_name
        photo_filename
        treatment
        treatment_related {
          smartsChecks {
            id
            start_date
            finish_date
            zoom_link
            status
          }
          id
          stl_type
          link_data
          treatmentStatus {
            id
            name
            index
            type
            admin_name
            color_type
          }
          visor_url
          files {
            id
            name
            fileUrl
            file_name
            file_type
          }
          histories {
            id
            type
            title
            message
            tag
            user {
              id
              first_name
              last_name
            }
            admin_title
            admin_message
            created_at
          }
          bracket_ss
          aligners_number
          bottom_aligners_number
          planning_software
          planning_case_id
          archived
          message_doctor
          more_smartchecks
          make_smartcheck
          form_status
          form_correction
          contact_question
        }
      }
    }
  }
`;

export const QUERY_GET_STUDENTS_BY_TEACHER = gql`
  query getStudentsByTeachers(
    $filters: [String!]!
    $teacher_id: Int!
    $search: String
  ) {
    getStudentsByTeachers(
      input: { filters: $filters, teacher_id: $teacher_id, search: $search }
    ) {
      id
      user {
        id
        first_name
        last_name
      }
      university {
        id
        name
      }
      active_treatments {
        id
      }
      count_inactives_treatments
    }
  }
`;

export const QUERY_GET_UNIVERSITY_DATA = gql`
  query getUniversityData($university_id: Int!, $search: String) {
    getUniversityData(
      input: { university_id: $university_id, search: $search }
    ) {
      teachers {
        id
        user {
          id
          first_name
          last_name
        }
        count_doctors
        count_students
      }
      treatments {
        id
        patient {
          id
          first_name
          last_name
        }
        treatmentStatus {
          id
          name
        }
        treatmentType {
          id
          name
        }
      }
      doctors {
        id
        user {
          id
          first_name
          last_name
        }
        active_treatments {
          id
        }
        count_total_treatments
        count_treatments
        count_inactives_treatments
      }
    }
  }
`;

export const QUERY_GET_MESSAGES_CHAT = gql`
  query getChatMessages($channel_url: String!) {
    getChatMessages(input: { channel_url: $channel_url }) {
      chatMessages {
        id
        from {
          id
          first_name
          last_name
        }
        message
        readed
      }
    }
  }
`;

export const QUERY_GET_PHYSICAL_TAXSYSTEMS = gql`
  query getTaxSystems {
    getTaxSystemsByTaxpayerType(
      where: { column: PHYSICAL, operator: EQ, value: true }
    ) {
      id
      name
      code
      cfdiUsages {
        id
        sat_code
        name
      }
    }
  }
`;

export const QUERY_GET_MORAL_TAXSYSTEMS = gql`
  query getTaxSystems {
    getTaxSystemsByTaxpayerType(
      where: { column: MORAL, operator: EQ, value: true }
    ) {
      id
      name
      code
      cfdiUsages {
        id
        sat_code
        name
      }
    }
  }
`;

export const QUERY_GET_TREATMENT_INVOICES = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      treatmentType {
        id
        name
      }
      payments {
        id
        amount
        status
        invoiced
        type
        invoices {
          id
          created_at
          partialPayment {
            id
            amount
            remaining_amount
          }
        }
      }
    }
  }
`;

export const QUERY_GET_TREATMENT_DOCTOR = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      had_planning_comments
      treatmentType {
        id
        name
      }
      treatmentStatus {
        id
        name
        type
        index
      }
      patient {
        id
        first_name
        last_name
      }
      doctor {
        id
        user {
          id
          first_name
          last_name
        }
      }
      areaStatus {
        id
        area
        status
        priority
      }
    }
  }
`;

export const QUERY_GET_PENDING_MESSAGES = gql`
  query pendingMessages($treatment: ID!) {
    pendingChatMessages(treatment: $treatment) {
      id
      from {
        id
        first_name
        last_name
        type
      }
      message
      readed
    }
  }
`;

export const QUERY_GET_EXTENSIBLE_PATIENTS = gql`
  query getExtendiblePatients($doctor_id: Int!) {
    getExtendiblePatients(input: { doctor_id: $doctor_id }) {
      context {
        bundleType: bundle_type
        expirationDate: expiration_date
        expired
        purchaseOptions: purchase_options {
          id
          title
          description
          descriptionMedium: description_medium
          priceLabel: price_label
          type
          bundleType: bundle_type
        }
        freePlanningsAvailable: free_plannings_available
        freePlanningsTotal: free_plannings_total
        remainingAligners: remaining_aligners
      }
      doctorId: doctor_id
      firstName: first_name
      gender
      lastName: last_name
      patient_id
      photoUrl: photo_url
      parentTreatmentId: parent_treatment_id
    }
  }
`;
