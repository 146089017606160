/*/ eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_TREATMENT_DOCTOR } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';
import Chat from 'borgatta-chat';
import Actions from '../../../components/Chat/Actions';
import { Row } from '../../../components/shared/Styled';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 24px;
  max-width: 1072px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Support = (props) => {
  const history = useHistory();
  const { patient_id, treatment_id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const [status, setStatus] = useState(null);
  const [hadPlanningComments, setHadPlanningComments] = useState(null);
  const [areaStatus, setAreaStatus] = useState([]);
  const [treatmentType, setTreatmentType] = useState(null);

  const [getDetails] = useLazyQuery(QUERY_GET_TREATMENT_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.treatment) {
        const treatment = data.treatment;
        setDoctor(treatment.doctor);
        setPatient(treatment.patient);
        setStatus(treatment.treatmentStatus);
        setAreaStatus(treatment.areaStatus);
        setHadPlanningComments(treatment.had_planning_comments);
        setTreatmentType(data.treatment.treatmentType.name);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getDetails({ variables: { id: treatment_id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment_id]);

  const isDataReady = () => status && areaStatus && treatmentType;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tableros" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Escribir a doctor</Title>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Breadcrumb
                    elements={3}
                    onClick={() => history.goBack()}
                    mainLabel={'Caso'}
                    midLabel={
                      doctor
                        ? `${doctor?.user?.first_name} ${doctor?.user?.last_name}`
                        : ''
                    }
                    onSecondClick={() =>
                      history.push({
                        pathname: `/app/doctores/perfil-doctor/${doctor?.id}`,
                      })
                    }
                    midSecondLabel={
                      patient
                        ? `${patient?.first_name} ${patient.last_name}`
                        : ''
                    }
                    onTirdClick={() =>
                      history.push({
                        pathname: `/app/tablero/caso/${patient?.id}`,
                      })
                    }
                    endLabel={'Escribir al Doctor'}
                  />
                </div>
                <div style={{ marginTop: 10, marginBottom: 46 }}>
                  <LabelNormal fontSize="17" color="#757575">
                    Resuelve tus dudas y finaliza la conversación para que el
                    doctor pueda enviar sus correcciones.
                  </LabelNormal>
                </div>
              </div>
              <Row
                className="w-100"
                gap="25px"
                paddingLeft="15px"
                paddingRight="15px"
              >
                <div
                  className=""
                  style={{ maxWidth: 680, minWidth: 680, height: 584 }}
                >
                  <Chat
                    token={localStorage.getItem('token-b360')}
                    treatment_id={parseInt(treatment_id)}
                    user_id={parseInt(localStorage.getItem('b360-id'))}
                    api_uri={process.env.REACT_APP_CHAT_API_URL}
                  />
                </div>
                {isDataReady() && (
                  <Actions
                    treatmentType={treatmentType}
                    treatmentId={treatment_id}
                    status={status}
                    patientId={patient_id}
                    hadPlanningComments={hadPlanningComments}
                    getDetails={getDetails}
                    areaStatus={areaStatus}
                  />
                )}
              </Row>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Support;
