import { ChangeEvent, MutableRefObject, useRef } from 'react';
import InfoModal from '@components/case-details/modals/info-modal';
import React, { useMutation } from '@apollo/client';
import Smartcheck, { SmartcheckEnum } from '@models/smartcheck';
import Treatment from '@models/treatment';
import { modal } from '@components/ModalManagement';
import { TreatmentStatusEnum } from '@models/treatment-status';
import { MUTATION_UPLOAD_EVIDENCE } from '@graphql/mutation';
import { useHistory } from 'react-router-dom';
import { CaseDetails } from '@acueductostudio/borgatta-components';
import UserRole from '@models/user-role';

interface DoctorFunctionsProps {
  pendingMessages: number;
  treatment: Treatment;
  viewer: UserRole;
  refresh: () => void;
}

interface UploadEvidenceEventProps {
  event: ChangeEvent<HTMLInputElement>;
  treatment: Treatment;
  refresh: () => void;
}

export const uploadEvidenceEvent = ({
  event,
  treatment,
  refresh,
}: UploadEvidenceEventProps) => {
  const status = treatment.treatmentStatus;
  const [uploadEvidence] = useMutation(MUTATION_UPLOAD_EVIDENCE, {
    onCompleted: (data) => {
      if (data.addPatientEvidencePhoto) {
        if (!(status.type === 'finished' && status.index === 3)) {
          modal.open(
            <InfoModal
              modal={modal}
              onClose={refresh}
              title="¡Felicidades! Un caso más a la bolsa"
              body="Evidencias de fin de caso subidas, puedes consultarlas en tu historial clínico. Te notificaremos de la acumulación de puntos cuando definamos la dificultad de tu caso."
            />
          );
        } else {
          modal.open(
            <InfoModal
              modal={modal}
              onClose={refresh}
              title="Evidencia actualizada"
              body="Fotografía de evidencia correctamente actualizada"
            />
          );
        }
      }
    },
  });

  if (event.target.files?.length && event.target.files?.length > 0) {
    return uploadEvidence({
      variables: {
        file: event?.target?.files[0],
        patient_id: treatment.patient.id,
      },
    });
  }
};

const DoctorFunctions = ({
  pendingMessages,
  treatment,
  viewer,
  refresh,
}: DoctorFunctionsProps) => {
  const statusId: number = treatment.treatmentStatus.id;
  const lastSmartcheck: Smartcheck | null =
    treatment.smartChecks
      ?.filter((smartChecks) => smartChecks.status !== SmartcheckEnum.NotAssist)
      .sort((a, b) => a.id - b.id)[0] ?? null;
  const history = useHistory();
  const refInput = useRef<HTMLInputElement | null>(null);

  const resolveClickFunction = () => {
    if (pendingMessages > 0) {
      return history.push(`/doctor/caso/mensajes-borgatta/${treatment.id}`);
    }
    if (TreatmentStatusEnum.ReportedErrors === statusId) {
      return history.push(`/doctor/caso/correccion/${treatment.id}`);
    }
    if (
      TreatmentStatusEnum.PausedCase === statusId ||
      TreatmentStatusEnum.DoctorMessage === statusId ||
      TreatmentStatusEnum.PendingPayment === statusId ||
      /**TODO: Validate only status EndedSecondSmartcheck when makeSmartchecks flag is enabled */
      TreatmentStatusEnum.EndedSecondSmartcheck === statusId ||
      TreatmentStatusEnum.ReadyToApprove === statusId ||
      TreatmentStatusEnum.ApprovedByProfesor === statusId
    ) {
      return history.push(`/doctor/aprobar-caso/${treatment.id}`);
    }
    if (TreatmentStatusEnum.ReadyVisor === statusId) {
      return history.push(`/doctor/caso/planificacion/${treatment.id}`);
    }
    if (
      TreatmentStatusEnum.ReadyPlanning === statusId ||
      TreatmentStatusEnum.ScheduledSmartcheck === statusId ||
      TreatmentStatusEnum.MissedSmartcheck === statusId
    ) {
      return history.push(`/doctor/caso/agendar-smartcheck/${treatment.id}`);
    }
    if (TreatmentStatusEnum.DaySmartcheck === statusId) {
      return window.open(lastSmartcheck?.zoom_link, '_blank');
    }
    // if (TreatmentStatusEnum.WaitingEvidence === statusId) {
    // }
    return null;
  };

  return (
    <>
      <CaseDetails
        viewer={viewer}
        treatment={treatment}
        pendingMessages={pendingMessages}
        pendingPayment={false}
        onClick={resolveClickFunction}
      />
      <input
        type="file"
        accept=".png, .jpe, .jpeg"
        style={{ display: 'none' }}
        ref={refInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          uploadEvidenceEvent({
            event,
            treatment,
            refresh,
          })
        }
      />
    </>
  );
};

export default DoctorFunctions;
