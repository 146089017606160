import React, { FC } from 'react';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalProps from '@interfaces/modal-props.interface';

const InfoModal: FC<ModalProps> = ({ modal, onClose, title, body }) => {
  return (
    <ModalSuccess onClick={() => modal.close(onClose)}>
      <div className="flex flex-col gap-3.5">
        <p className="text-2xl text-modal-title">{title}</p>
        <p className="text-lg text-modal-body">{body}</p>
      </div>
    </ModalSuccess>
  );
};

export default InfoModal;
