export const CasesIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 13H14.0002C14.2654 13 14.5197 12.8946 14.7073 12.7071C14.8948 12.5196 15.0002 12.2652 15.0002 12C15.0002 11.7348 14.8948 11.4804 14.7073 11.2929C14.5197 11.1054 14.2654 11 14.0002 11H10.0002C9.73495 11 9.4806 11.1054 9.29306 11.2929C9.10553 11.4804 9.00017 11.7348 9.00017 12C9.00017 12.2652 9.10553 12.5196 9.29306 12.7071C9.4806 12.8946 9.73495 13 10.0002 13ZM19.0002 3H5.00017C4.29663 3.00546 3.61738 3.25801 3.08118 3.71352C2.54497 4.16902 2.18591 4.79849 2.06677 5.49189C1.94763 6.18529 2.07598 6.89851 2.4294 7.50687C2.78281 8.11523 3.3388 8.58002 4.00017 8.82V18C4.00017 18.7956 4.31624 19.5587 4.87885 20.1213C5.44146 20.6839 6.20452 21 7.00017 21H17.0002C17.7958 21 18.5589 20.6839 19.1215 20.1213C19.6841 19.5587 20.0002 18.7956 20.0002 18V8.82C20.6615 8.58002 21.2175 8.11523 21.5709 7.50687C21.9244 6.89851 22.0527 6.18529 21.9336 5.49189C21.8144 4.79849 21.4554 4.16902 20.9192 3.71352C20.383 3.25801 19.7037 3.00546 19.0002 3ZM18.0002 18C18.0002 18.2652 17.8948 18.5196 17.7073 18.7071C17.5197 18.8946 17.2654 19 17.0002 19H7.00017C6.73495 19 6.4806 18.8946 6.29306 18.7071C6.10553 18.5196 6.00017 18.2652 6.00017 18V9H18.0002V18ZM19.0002 7H5.00017C4.73495 7 4.4806 6.89464 4.29306 6.70711C4.10553 6.51957 4.00017 6.26522 4.00017 6C4.00017 5.73478 4.10553 5.48043 4.29306 5.29289C4.4806 5.10536 4.73495 5 5.00017 5H19.0002C19.2654 5 19.5197 5.10536 19.7073 5.29289C19.8948 5.48043 20.0002 5.73478 20.0002 6C20.0002 6.26522 19.8948 6.51957 19.7073 6.70711C19.5197 6.89464 19.2654 7 19.0002 7Z"
        fill={color ? color : 'rgba(78, 90, 95, 0.6)'}
      />
    </svg>
  );
};
