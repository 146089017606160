import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENT_TEACHERS = gql`
  query getTreatmentsByTeachers(
    $filters: [String!]!
    $teacher_id: Int!
    $search: String
  ) {
    getTreatmentsByTeachers(
      input: { filters: $filters, teacher_id: $teacher_id, search: $search }
    ) {
      id
      patient {
        id
        treatment
        first_name
        last_name
        gender
        birthdate
        phone
        email
        photo_filename
      }
      smartsChecks {
        id
        start_date
        finish_date
        zoom_link
        status
      }
      treatmentStatus {
        id
        name
        admin_name
        color_type
        index
        type
      }
      treatmentType {
        id
        name
      }
      make_smartcheck
      message_doctor
      archived
      doctor {
        id
        user {
          id
        }
      }
      parentTreatment {
        id
      }
      childTreatment {
        id
      }
      payments {
        id
        type
        status
      }
      messages {
        id
        from {
          id
        }
        readed
      }
    }
  }
`;
