import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardComponent = styled(Link)`
  text-decoration: none;
  border: none !important;
`;

const CardComponent2 = styled.div`
  text-decoration: none;
  border: none !important;
`;

const Container = styled.div`
  min-width: 262px;
  min-height: 106px;
  padding: 17px;
  border-radius: 27px;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
  transition: all 0.3s ease-out;
`;

const CardBasic = (props) =>
  props.to ? (
    <CardComponent {...props}>
      <Container style={props.style}>{props.children}</Container>
    </CardComponent>
  ) : (
    <CardComponent2 {...props}>
      <Container style={props.style}>{props.children}</Container>
    </CardComponent2>
  );

export default CardBasic;
