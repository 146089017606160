export const BoardIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4.76099H15.82C15.6137 4.17744 15.2319 3.67198 14.7271 3.31392C14.2222 2.95586 13.6189 2.76273 13 2.76099H11C10.3811 2.76273 9.7778 2.95586 9.27293 3.31392C8.76807 3.67198 8.38631 4.17744 8.18 4.76099H7C6.20435 4.76099 5.44129 5.07706 4.87868 5.63967C4.31607 6.20228 4 6.96534 4 7.76099V19.761C4 20.5566 4.31607 21.3197 4.87868 21.8823C5.44129 22.4449 6.20435 22.761 7 22.761H17C17.7956 22.761 18.5587 22.4449 19.1213 21.8823C19.6839 21.3197 20 20.5566 20 19.761V7.76099C20 6.96534 19.6839 6.20228 19.1213 5.63967C18.5587 5.07706 17.7956 4.76099 17 4.76099ZM10 5.76099C10 5.49577 10.1054 5.24142 10.2929 5.05388C10.4804 4.86634 10.7348 4.76099 11 4.76099H13C13.2652 4.76099 13.5196 4.86634 13.7071 5.05388C13.8946 5.24142 14 5.49577 14 5.76099V6.76099H10V5.76099ZM18 19.761C18 20.0262 17.8946 20.2806 17.7071 20.4681C17.5196 20.6556 17.2652 20.761 17 20.761H7C6.73478 20.761 6.48043 20.6556 6.29289 20.4681C6.10536 20.2806 6 20.0262 6 19.761V7.76099C6 7.49577 6.10536 7.24142 6.29289 7.05388C6.48043 6.86634 6.73478 6.76099 7 6.76099H8V7.76099C8 8.0262 8.10536 8.28056 8.29289 8.46809C8.48043 8.65563 8.73478 8.76099 9 8.76099H15C15.2652 8.76099 15.5196 8.65563 15.7071 8.46809C15.8946 8.28056 16 8.0262 16 7.76099V6.76099H17C17.2652 6.76099 17.5196 6.86634 17.7071 7.05388C17.8946 7.24142 18 7.49577 18 7.76099V19.761Z"
        fill={color ? color : 'rgba(78, 90, 95, 0.6)'}
      />
    </svg>
  );
};
