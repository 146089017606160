import { Controller } from 'react-hook-form';
import {
  Container,
  LabelInput,
  LabelMuted,
  InputComponent,
  InlineContainer,
  ErrorMessage,
} from '../Styled';

const NumberInput = ({
  name,
  control,
  errors,
  clearErrors,
  setValue,
  rules,
  label,
  muted,
  limit = 0,
  ...props
}) => {
  const maskNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/[^0-9]/g, '');
    if (limit > 0 && val.length <= limit) {
      setValue(name, val);
    }
  };
  return (
    <Container>
      {label && <LabelInput>{label}</LabelInput>}
      {muted && <LabelMuted>{muted}</LabelMuted>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <InputComponent
            {...field}
            {...props}
            onFocus={() => clearErrors && clearErrors(name)}
            onChange={maskNumber}
            borderColor={
              errors && errors[name] ? '#D11105' : 'rgba(189, 189, 189, 0.7)'
            }
          />
        )}
      />
      <InlineContainer>
        <ErrorMessage>
          {errors && errors[name] && errors[name]?.message}
        </ErrorMessage>
      </InlineContainer>
    </Container>
  );
};

export default NumberInput;
