import React from 'react';
import ButtonClose from '../shared/ButtonClose';

const UnSubscribe = (props) => {
  return (
    <div className="modal flex flex-col gap-8">
      <div className="flex flex-row justify-center items-center">
        <div className="text-center">
          <img
            className="w-16 h-16"
            src={require('@images/icons/success.png')}
          />
        </div>
        <ButtonClose onClick={props.onClick} />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default UnSubscribe;
