import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_GET_ALL_SMARTCHECKS } from '@graphql/query';
import {
  MUTATION_CHANGE_STATUS_SMARTCHECK,
  MUTATION_CHANGE_OPENED_SMARTCHECK,
} from '@graphql/mutation';
import FeatherIcons from 'feather-icons-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ButtonPrime from '@components/shared/ButtonPrime';
import Card from '@components/shared/Card';
import { modal } from '@components/ModalManagement';
import ModalWarning from '@components/Modal/ModalWarning';
import ModalSuccess from '@components/Modal/ModalSuccess';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;
const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const SmartChecks = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [smartChecks, setSmartChecks] = useState([]);
  const [statusSmartCheck, setStatusSmartCheck] = useState('');
  const [modalProps, setModalProps] = useState(null);

  const [getSmartChecks] = useLazyQuery(QUERY_GET_ALL_SMARTCHECKS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getAllSmartChecksByDate) {
        setSmartChecks(data.getAllSmartChecksByDate.hours);
      }
      setLoadingSkeleton(false);
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getSmartChecks({
      variables: { date: moment(currentDate).format('YYYY-MM-DD') },
    });
  }, [currentDate]);

  const dateformat = (current) => {
    const date = moment(current);
    return moment().diff(date, 'days') >= 2
      ? date.fromNow()
      : date.calendar().split(' ')[0];
  };

  const [changeSmartCheck] = useMutation(MUTATION_CHANGE_STATUS_SMARTCHECK, {
    onCompleted: (data) => {
      if (data.updateStatusSmartCheck) {
        if (statusSmartCheck === 'NotAssist') {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() =>
                  getSmartChecks({
                    variables: {
                      date: moment(currentDate).format('YYYY-MM-DD'),
                    },
                  }),
                )
              }
            >
              <LabelNormal fontSize="22" color="#060809">
                Segundo smart check solicitado{' '}
              </LabelNormal>
              <LabelNormal fontSize="17" color="#4F4F4F">
                Espera a que el doctor agende un segundo Smart check.
              </LabelNormal>
            </ModalSuccess>,
          );
        } else if (statusSmartCheck === 'Assist') {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() =>
                  getSmartChecks({
                    variables: {
                      date: moment(currentDate).format('YYYY-MM-DD'),
                    },
                  }),
                )
              }
            >
              <LabelNormal fontSize="22" color="#060809">
                Smart check finalizado con éxito
              </LabelNormal>
              <LabelNormal fontSize="17" color="#4F4F4F">
                Para continuar haz clic en Continuar, para enviar el caso como
                listo para aprobarse.
              </LabelNormal>
              <ButtonPrime
                label="Continuar e introducir visor de paciente"
                size="19"
                color="#FFF"
                background="#EB9542"
                border="none"
                style={{
                  backgroundColor: '#EB9542',
                  padding: '11px',
                  marginTop: 15,
                  marginBottom: 15,
                }}
                onClick={() => {
                  modal.close();
                  history.push({
                    pathname: `/app/tablero/caso/revision-planificacion/${data.updateStatusSmartCheck.id}`,
                    state: {
                      treatmentId: modalProps.treatment.id,
                      firstName: modalProps.treatment.patient.first_name,
                      lastName: modalProps.treatment.patient.last_name,
                      treatment: modalProps.treatment,
                      smart:
                        props.location.state && props.location.state.smart
                          ? props.location.state.smart
                          : false,
                      visorReview: true,
                      isEdit: true,
                    },
                  });
                }}
              />
              <a href={`/app/tablero/caso/${data.updateStatusSmartCheck.id}`}>
                <ButtonPrime
                  label="Solicitar segundo SmartCheck"
                  size="19"
                  color="#3C4549"
                  background="transparent"
                  border="1px solid #3C4549"
                  style={{ cursor: 'pointer' }}
                  // onClick={() => onClickNoAssist(row.id)}
                />
              </a>
              {/* <LabelNormal fontSize="17" color="#4F4F4F">Si necesitas un segundo Smart Check, dafg <a href={`/app/tablero/caso/${data.updateStatusSmartCheck.id}`}>click aqui</a></LabelNormal> */}
            </ModalSuccess>,
          );
        }
      }
      console.log(data);
    },
  });

  const [changeOpenedSmartcheck] = useMutation(
    MUTATION_CHANGE_OPENED_SMARTCHECK,
    {
      onCompleted: (data) => {
        if (data.changeOpenedSmartcheck) {
          getSmartChecks({
            variables: { date: moment(currentDate).format('YYYY-MM-DD') },
          });
        }
      },
    },
  );

  const compareTimeAndDate = (date) => {
    const startTime = moment(date, 'YYYY-MM-DD hh:mma');
    const end = moment(new Date(), 'YYYY-MM-DD hh:mma');
    const duration = moment.duration(end.diff(startTime));
    const hours = duration.asHours();
    return hours >= 0 && hours < 1;
  };

  const compareTimeAndDateCalendar = (date) => {
    const startTime = moment(date, 'YYYY-MM-DD hh:mma');
    const end = moment(new Date(), 'YYYY-MM-DD hh:mma');
    const duration = moment.duration(end.diff(startTime));
    const hours = duration.asHours();
    return hours < 0;
  };

  const maxOneHours = (date) => {
    const startTime = moment(date, 'YYYY-MM-DD hh:mma');
    const end = moment(new Date(), 'YYYY-MM-DD hh:mma');
    const duration = moment.duration(end.diff(startTime));
    const hours = duration.asHours();
    console.log(hours > 1, 'max');
    return hours > 1;
  };

  const addDate = () => setCurrentDate(moment(currentDate).add(1, 'day'));
  const subDate = () => setCurrentDate(moment(currentDate).subtract(1, 'day'));

  const onClickNoAssist = (id) => {
    setStatusSmartCheck('NotAssist');
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809">
          Confirma que el doctor no asistió{' '}
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Al confirmar, el doctor deberá agendar otro Smart Check.
        </LabelNormal>
        <ButtonPrime
          label="Doctor no asistió"
          onClick={() =>
            changeSmartCheck({
              variables: { status: 'NotAssist', smart_check_id: id },
            })
          }
        />
      </ModalWarning>,
    );
  };

  const onClickAssits = (id) => {
    setStatusSmartCheck('Assist');
    changeSmartCheck({ variables: { status: 'Done', smart_check_id: id } });
    getSmartChecks({
      variables: { date: moment(currentDate).format('YYYY-MM-DD') },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="smartchecks" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            <div className="col-md-12">
              <Title>Citas Smart check</Title>
            </div>
            {!loadingSkeleton ? (
              <div className="col-md-12" style={{ marginTop: 75 }}>
                <InlineContainer>
                  <FeatherIcons
                    icon="chevron-left"
                    size="22"
                    color="#4F4F4F"
                    style={{ cursor: 'pointer' }}
                    onClick={() => subDate()}
                  />
                  <LabelNormal
                    style={{ marginLeft: 22, marginRight: 22 }}
                    fontSize="22"
                    color="#3C4549"
                  >{`${
                    dateformat(currentDate).charAt(0).toUpperCase() +
                    dateformat(currentDate).slice(1)
                  }, ${moment(currentDate).format(
                    'DD [de] MMMM',
                  )}`}</LabelNormal>
                  <FeatherIcons
                    icon="chevron-right"
                    size="22"
                    color="#4F4F4F"
                    style={{ cursor: 'pointer' }}
                    onClick={() => addDate()}
                  />
                </InlineContainer>
              </div>
            ) : (
              <div className="col-md-12" style={{ marginTop: 75 }}>
                <Skeleton
                  height={39}
                  borderRadius={3}
                  width={'20%'}
                  baseColor={'#EEEEF2'}
                  highlightColor={'#FFF'}
                />
              </div>
            )}
            {!loadingSkeleton ? (
              <div
                className="row"
                style={
                  smartChecks.length <= 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                      }
                    : {}
                }
              >
                {smartChecks.length > 0 ? (
                  <div className="col-md-8" style={{ marginTop: 70 }}>
                    {smartChecks.map((item) => (
                      <div
                        className="row"
                        style={{
                          alignItems: 'flex-start',
                          marginBottom: 20,
                          marginLeft: 10,
                        }}
                      >
                        <div className="col-md-6">
                          <LabelNormal color="#3C4549" fontSize="22">
                            {moment(item.value, 'hh:mm a').format('hh:mm a')}
                          </LabelNormal>
                        </div>
                        {item.smartChecks.map((row) => (
                          <Card minWidth={'249px'}>
                            <InlineContainer
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                history.push({
                                  pathname: `/app/tablero/caso/${row.treatment.patient.id}`,
                                  state: { smart: true },
                                })
                              }
                            >
                              <LabelNormal
                                style={{ marginTop: 0, marginBottom: 0 }}
                                color="#436FF1"
                                fontSize="11"
                              >
                                PX
                              </LabelNormal>
                              <LabelNormal
                                style={{ marginTop: 0, marginBottom: 0 }}
                                color="#4F4F4F"
                                fontSize="15"
                              >{`${row.treatment.patient.first_name} ${row.treatment.patient.last_name}`}</LabelNormal>
                            </InlineContainer>
                            <InlineContainer
                              style={{ marginTop: 0, alignItems: 'flex-start' }}
                            >
                              <LabelNormal
                                style={{ marginTop: 0, marginBottom: 0 }}
                                color="#828282"
                                fontSize="18.5"
                              >
                                con
                              </LabelNormal>
                              <LabelNormal
                                style={{ marginTop: 0, marginBottom: 0 }}
                                fontSize="17"
                                color="#3C4549"
                              >
                                Dr.{' '}
                                {`${row.doctor.user.first_name} ${row.doctor.user.last_name}`}
                              </LabelNormal>
                            </InlineContainer>
                            <InlineContainer style={{ marginBottom: 13 }}>
                              <LabelNormal
                                color="#4F4F4F"
                                fontSize="14.5"
                                style={{ fontWeight: '600' }}
                              >
                                ID
                              </LabelNormal>
                              <LabelNormal fontSize="14.5" color="#4F4F4F">
                                {row.id}
                              </LabelNormal>
                            </InlineContainer>
                            {compareTimeAndDate(row.start_date) &&
                              !maxOneHours(row.start_date) &&
                              !row.opened && (
                                <ButtonPrime
                                  label="Unirse a zoom"
                                  onClick={() => {
                                    changeOpenedSmartcheck({
                                      variables: { smartcheck_id: row.id },
                                    });
                                    window.open(row.zoom_link, '_blank');
                                  }}
                                />
                              )}
                            {compareTimeAndDateCalendar(row.start_date) &&
                              !maxOneHours(row.start_date) &&
                              row.opened &&
                              row.status === 'Pending' && (
                                <div
                                  style={{
                                    border: '1px solid #F2B559',
                                    padding: '7px 11px 9px 15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 40,
                                    textDecoration: 'none',
                                  }}
                                >
                                  <FeatherIcons
                                    icon="calendar"
                                    color="#F2B559"
                                    size="19"
                                  />
                                  <LabelNormal color="#F2B559" fontSize="15">
                                    {moment(row.start_date).format(
                                      'DD [de] MMMM [-] hh:mm a',
                                    )}
                                  </LabelNormal>
                                </div>
                              )}
                            {((maxOneHours(row.start_date) &&
                              row.status !== 'Done' &&
                              row.status !== 'NotAssist') ||
                              (row.opened &&
                                row.status !== 'Done' &&
                                row.status !== 'NotAssist')) && (
                              <InlineContainer>
                                {/* <Link
                                //   to={
                                //     row.treatment.treatmentType.name ===
                                //     'Cryst Aligner'
                                //       ? {
                                //           pathname: `/app/smartchecks/numero-alineadores/${row.id}`,
                                //           state: { ...row },
                                //         }
                                //       : ''
                                //   }
                                //   onClick={(e) => {
                                //     if (
                                //       row.treatment.treatmentType.name !==
                                //       'Cryst Aligner'
                                //     ) {
                                //       e.preventDefault();
                                //     } else {
                                //     }
                                //   }}
                                > */}
                                <ButtonPrime
                                  label="Asistió"
                                  onClick={() => {
                                    setModalProps(row);
                                    return onClickAssits(row.id);
                                    // return row.treatment.treatmentType.name !==
                                    //   'Cryst Aligner'
                                    //   ? onClickAssits(row.id)
                                    //   : onClickAssits(row.id);
                                  }}
                                />
                                {/* </Link> */}
                                <ButtonPrime
                                  style={{ marginLeft: 5 }}
                                  background="transparent"
                                  color="#3C4549"
                                  label="No asistió"
                                  onClick={() => onClickNoAssist(row.id)}
                                  border="1px solid #3C4549"
                                />
                              </InlineContainer>
                            )}
                            {row.status === 'Done' && (
                              <LabelNormal
                                style={{ margin: '13px 0 0 0' }}
                                fontSize="15"
                                color="#757575"
                              >
                                Smart Check finalizado
                              </LabelNormal>
                            )}
                            {row.status === 'NotAssist' && (
                              <LabelNormal
                                style={{ marginTop: 13 }}
                                fontSize="15"
                                color="#757575"
                              >
                                Smart Check perdido
                              </LabelNormal>
                            )}
                          </Card>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <TitleWithoutContent>
                      Aún no tienes <br /> ningún Smart Check
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            ) : (
              <div className="row">
                <div className="col-md-8" style={{ marginTop: 70 }}>
                  <div
                    className="row"
                    style={{
                      alignItems: 'flex-start',
                      marginBottom: 20,
                      marginLeft: 10,
                    }}
                  >
                    {Array.from(Array(3).keys()).map((_, i) => (
                      <>
                        <div
                          className="col-md-6"
                          style={{ marginTop: i === 0 ? 0 : 60 }}
                        >
                          <Skeleton
                            height={39}
                            borderRadius={3}
                            width={'20%'}
                            baseColor={'#EEEEF2'}
                            highlightColor={'#FFF'}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 80 }}>
                          <Skeleton
                            height={151}
                            borderRadius={27}
                            width={249}
                            baseColor={'#EEEEF2'}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default SmartChecks;
