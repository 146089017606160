import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 39px;
  height: 39px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin: 0 5px;
  cursor: pointer;
`;

const IconNavigation = styled.img`
  height: 17px;
  width: 11px;
`;

const ButtonNavigation = (props) => {
  return (
    <Container {...props}>
      <IconNavigation src={props.icon} />
    </Container>
  );
};

export default ButtonNavigation;
