import { useEffect } from 'react';

import { modal } from '@components/ModalManagement';
import { ModalUnichatUpdateAlert } from '../Modal/ModalUnichatUpdateAlert';

/**
 * The component prop should be a reference to some ReactNode component,
 * do not pass it as props with JSX, only the component reference.
 *
 * Use https://www.epochconverter.com/ to test timestamps.
 *
 * @param {{
 * component: ReactNode
 * }} props
 * @returns
 */
export const useAlertDeploy = ({ component }) => {
  useEffect(() => {
    if (window) {
      const LOCAL_STORAGE_KEY = 'isAlertDeployViewed';
      const viewedCountLimit = process.env.REACT_APP_ALERT_DEPLOY_COUNT_LIMIT;
      const begginingDate = process.env.REACT_APP_ALERT_DEPLOY_BEGGINING_DATE;
      const endingDate = process.env.REACT_APP_ALERT_DEPLOY_ENDING_DATE;

      /* If some env var fails, do not execute. */
      if (
        viewedCountLimit === undefined ||
        begginingDate === undefined ||
        endingDate === undefined
      ) {
        return console.error(
          "Alert Deploy hook couldn't be executed due to a bad environment variable configuration.",
        );
      }

      const result = localStorage.getItem(LOCAL_STORAGE_KEY);
      const viewedCount = localStorage != null ? Number(result) : 0;
      const currentDate = new Date().getTime();

      /* If count was exceeded or equal, do not execute. */
      if (viewedCount >= viewedCountLimit) return;

      /* If date window of execution was not satisfied, abort. */
      if (currentDate <= begginingDate || currentDate >= endingDate) return;

      const onClickUnderstood = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, viewedCount + 1);
        modal.close();
      };

      modal.open(
        <ModalUnichatUpdateAlert onClickUnderstood={onClickUnderstood} />,
      );
    }
  }, [component]);

  return null;
};
