import { gql } from '@apollo/client';

// import {
//   DOCTOR_INFO_FRAGMENT,
//   MANUFACT_FRAGMENT,
//   MESSAGES_INFO_FRAGMENT,
//   PATIENT_BASIC_INFO_FRAGMENT,
//   TEACHER_INFO_FRAGMENT,
//   TREATMENT_STATUS_FRAGMENT,
//   TREATMENT_TYPE_FRAGMENT,
// } from '../../fragments';

export const QUERY_GET_TREATMENT = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      patient {
        id
        first_name
        last_name
        treatment
        gender
        birthdate
        phone
        email
        photo_filename
      }
      treatmentStatus {
        id
        name
        index
        type
        admin_name
        color_type
      }
      treatmentType {
        id
        name
      }
      doctor {
        id
        category
        university {
          id
          name
        }
        user {
          id
          first_name
          last_name
          photo_filename
          type
          country {
            id
            name
          }
        }
      }
      teacher {
        id
        user {
          id
          first_name
          last_name
          photo_filename
          type
          country {
            id
            name
          }
        }
      }
      manufact {
        id
        pack {
          id
          name
        }
      }
      messages {
        id
        from {
          id
        }
        readed
      }
      smartsChecks {
        id
        start_date
        finish_date
        zoom_link
        status
      }
      stl_type
      archived
      link_data
      visor_url
      visor_desk_link
      visor_patient_link
      form_status
      form_correction
      contact_question
      additional_instructions
      comments {
        id
        text
        administrator {
          user {
            first_name
            last_name
          }
        }
        created_at
      }
      files {
        id
        name
        fileUrl
        file_name
        file_type
      }
      histories {
        id
        type
        title
        message
        tag
        user {
          id
          first_name
          last_name
        }
        admin_title
        admin_message
        created_at
      }
      parentTreatment {
        id
        manufact {
          id
          pack {
            id
            name
          }
        }
      }
      childTreatment {
        id
      }
      finished_date
      preexpiration_date
      expiration_date
    }
  }
`;

export const QUERY_GET_FORM_ARCADE = gql`
  query getFormArcade($treatment_id: ID) {
    formArcade(treatment_id: $treatment_id) {
      treatment {
        id
      }
      type
      middle_line
      has_middle_line_left
      middle_line_left
      has_middle_line_right
      middle_line_right
      overbite
      overbite_data
      overjet
      overjet_data
      spaces
      up_spaces_data
      lo_spaces_data
      arch_width
      arch_exp
      arch_exp_data
      arch_shr
      arch_shr_data
      arch_teeth_data
      cross_bite
      cross_bite_subselect
      crossbite_exp
      crossbite_exp_data
      crossbite_shr
      crossbite_shr_data
      crossbite_teeth_data
      procline_adjust_maxilla
      procline_adjust_maxilla_data
      procline_adjust_jaw
      procline_adjust_jaw_data
      expand_adjust_maxilla
      expand_adjust_maxilla_data
      expand_adjust_jaw
      expand_adjust_jaw_data
      treatment_type
      aligner_pack
      spee
    }
  }
`;

export const QUERY_GET_FORM_SHAPE = gql`
  query getFormShape($treatment_id: ID) {
    formShape(treatment_id: $treatment_id) {
      treatment {
        id
      }
      arc_shape
      desired_class
      molar_class
      molar_left_type
      molar_right_type
      canine_relationship
      canine_left
      canine_right
      anterior_area
    }
  }
`;

export const QUERY_GET_FORM_IPR = gql`
  query getFormIpr($treatment_id: ID) {
    formIpr(treatment_id: $treatment_id) {
      treatment {
        id
      }
      instructions
      up_ipr_data
      lo_ipr_data
      torque
      back_teeth
      back_teeth_to
      front_teeth
      front_teeth_to
      teeth_torque_data
      additional_indications
      kind
    }
  }
`;

export const QUERY_GET_FORM_BRACKET = gql`
  query getFormBracket($treatment_id: ID) {
    formBracket(treatment_id: $treatment_id) {
      treatment {
        id
      }
      type
      up_positions_data
      lo_positions_data
    }
  }
`;

export const QUERY_GET_FORM_ODONTOGRAM = gql`
  query getFormOdontogram($treatment_id: ID) {
    formOdontogram(treatment_id: $treatment_id) {
      treatment {
        id
      }
      type
      up_left_third_molar_data
      up_left_second_molar_data
      up_left_first_molar_data
      up_left_second_premolar_data
      up_left_first_premolar_data
      up_left_canine_data
      up_left_lateral_incisor_data
      up_left_central_incisor_data
      up_right_third_molar_data
      up_right_second_molar_data
      up_right_first_molar_data
      up_right_second_premolar_data
      up_right_first_premolar_data
      up_right_canine_data
      up_right_lateral_incisor_data
      up_right_central_incisor_data
      lo_left_third_molar_data
      lo_left_second_molar_data
      lo_left_first_molar_data
      lo_left_second_premolar_data
      lo_left_first_premolar_data
      lo_left_canine_data
      lo_left_lateral_incisor_data
      lo_left_central_incisor_data
      lo_right_third_molar_data
      lo_right_second_molar_data
      lo_right_first_molar_data
      lo_right_second_premolar_data
      lo_right_first_premolar_data
      lo_right_canine_data
      lo_right_lateral_incisor_data
      lo_right_central_incisor_data
      comments
    }
  }
`;
