import React from 'react';
import styled from 'styled-components';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const HistoryChat = (props) => {
  return (
    <div>
      <LabelNormal
        color="#3C4549"
        fontSize="30"
        style={{ fontWeight: 500, marginBottom: 15 }}
      >
        Acuerdos adicionales
      </LabelNormal>

      {props.chat.map((item, i) => (
        <div>
          {(i === 0 ||
            item.date_section !==
              props.chat.find((v, ind) => ind === i - 1).date_section) && (
            <LabelNormal
              color="#9E9E9E"
              fontSize="12"
              style={{ marginBottom: 9, textTransform: 'uppercase' }}
            >
              {item.date_section}
            </LabelNormal>
          )}

          <InlineContainer
            style={{ alignItems: 'flex-start', marginBottom: 5 }}
          >
            {
              <img
                src={require('@images/people/usuario_masculino.jpg')}
                style={{ height: 25, width: 25, borderRadius: 38 }}
              />
            }
            <LabelNormal style={{ width: 95 }} color="#3C4549" fontSize="17">
              [{item.date}]
            </LabelNormal>
            <LabelNormal style={{ width: '75%' }} fontSize="17" color="#757575">
              {item.message}
            </LabelNormal>
          </InlineContainer>
        </div>
      ))}
    </div>
  );
};

export default HistoryChat;
