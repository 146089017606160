const Check = ({ color }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7099 8.05581C18.617 7.96208 18.5064 7.88769 18.3845 7.83692C18.2627 7.78615 18.132 7.76001 17.9999 7.76001C17.8679 7.76001 17.7372 7.78615 17.6154 7.83692C17.4935 7.88769 17.3829 7.96208 17.29 8.05581L9.83995 15.5158L6.70995 12.3758C6.61343 12.2826 6.49949 12.2093 6.37463 12.1601C6.24978 12.1108 6.11645 12.0867 5.98227 12.089C5.84809 12.0914 5.71568 12.1201 5.5926 12.1736C5.46953 12.2271 5.35819 12.3043 5.26495 12.4008C5.17171 12.4973 5.0984 12.6113 5.04919 12.7361C4.99999 12.861 4.97586 12.9943 4.97818 13.1285C4.9805 13.2627 5.00923 13.3951 5.06272 13.5182C5.11622 13.6412 5.19343 13.7526 5.28995 13.8458L9.12995 17.6858C9.22291 17.7795 9.33351 17.8539 9.45537 17.9047C9.57723 17.9555 9.70794 17.9816 9.83995 17.9816C9.97196 17.9816 10.1027 17.9555 10.2245 17.9047C10.3464 17.8539 10.457 17.7795 10.55 17.6858L18.7099 9.52581C18.8115 9.43217 18.8925 9.31851 18.9479 9.19201C19.0033 9.06552 19.0319 8.92891 19.0319 8.79081C19.0319 8.65271 19.0033 8.5161 18.9479 8.3896C18.8925 8.2631 18.8115 8.14945 18.7099 8.05581Z"
      fill={color ?? '#EB9542'}
    />
  </svg>
);

export default Check;
