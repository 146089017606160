import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import InputSearch from '@components/shared/InputSearch';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonAction from '@components/shared/ButtonAction';
import Card from '@components/shared/Card';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_UNIVERSITIES } from '@graphql/query';
import FeatherIcons from 'feather-icons-react';
import ChipIcons from '@components/shared/ChipIcons';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
  background-color: transparent;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-medium;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const Universities = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [options, setDepartaments] = useState([
    { name: 'Activas', id: 'Activos' },
    { name: 'Desactivadas', id: 'Inactivos' },
  ]);
  const [universities, setUniversities] = useState([]);
  const [selectedOption, setSelectedOption] = useState([
    'Activos',
    'Inactivos',
  ]);
  const [search, setSearch] = useState('');

  const [getUniversities] = useLazyQuery(QUERY_GET_UNIVERSITIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getUniversitiesByFilters) {
        setUniversities(data.getUniversitiesByFilters);
      }
      setLoadingSkeleton(false);
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    if (search !== '') {
      getUniversities({
        variables: { filters: selectedOption.map((o) => o), search },
      });
    } else {
      getUniversities({ variables: { filters: selectedOption.map((o) => o) } });
    }
  }, [selectedOption]);

  useEffect(() => {
    setLoadingSkeleton(true);
    if (search !== '') {
      getUniversities({
        variables: { filters: selectedOption.map((o) => o), search },
      });
    } else {
      getUniversities({ variables: { filters: selectedOption.map((o) => o) } });
    }
  }, [search]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <Title>Universidades</Title>
          <InlineContainer
            style={{ justifyContent: 'space-between', marginTop: 40 }}
          >
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <DropdownCustom
                label="Filtros"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </InlineContainer>
            <ButtonAction
              label="Añadir universidad"
              icon
              to="/app/universidades/registrar"
            />
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            {!loadingSkeleton ? (
              <div className="row">
                {universities.map((item) => (
                  <div
                    className="col-md-4"
                    style={{ marginTop: 15 }}
                    key={Math.random()}
                  >
                    <Card
                      to={`/app/universidades/${item.id}`}
                      noMaxWidth={true}
                      minWidth={'296px'}
                    >
                      <InlineContainer
                        style={{ justifyContent: 'space-between' }}
                      >
                        <LabelNormal color="#3C4549" fontSize="18">
                          {item.name}
                        </LabelNormal>
                        <InlineContainer>
                          <FeatherIcons icon="map-pin" color="#C8C8C8" />
                          <LabelNormal
                            fontSize="17"
                            color="#757575"
                            style={{ marginLeft: 13 }}
                          >
                            {item.user.country.name}
                          </LabelNormal>
                        </InlineContainer>
                      </InlineContainer>
                      <InlineContainer
                        style={{ alignContent: 'center', marginTop: 35 }}
                      >
                        <ChipIcons
                          name={item.count_doctors}
                          icon="cap"
                          color="#C8C8C8"
                        />
                        <ChipIcons
                          name={item.count_teachers}
                          icon="libraries"
                          color="#C8C8C8"
                        />
                        <ChipIcons
                          name={item.count_treatments}
                          icon="clipboard"
                          color="#C8C8C8"
                        />
                      </InlineContainer>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div style={{ display: 'flex' }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      className="col-md-4"
                      style={{ padding: '0px 17px 0px 0px' }}
                    >
                      <Skeleton
                        height={137}
                        borderRadius={27}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      className="col-md-4"
                      style={{ padding: '0px 17px 0px 0px' }}
                    >
                      <Skeleton
                        height={137}
                        borderRadius={27}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(4).keys()).map(() => (
                    <div
                      className="col-md-4"
                      style={{ padding: '0px 17px 0px 0px' }}
                    >
                      <Skeleton
                        height={137}
                        borderRadius={27}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Universities;
