import LinkUnderline from '../shared/LinkUnderline';
import { Column, InlineContainer, LabelNormal, Row } from '../shared/Styled';

const PaymentCardCard = ({
  card,
  onModalDeleteCreditCard,
  canDelete = false,
}) => (
  <Column
    style={{
      borderRadius: 10,
      minHeight: 71,
      maxWidth: 376,
      padding: 16,
      boxShadow:
        '0px 2px 0px rgba(162, 162, 162, 0.1), 0px 1px 3px rgba(161, 161, 161, 0.1)',
    }}
    className={card.default ? 'gradiant-border' : ''}
    key={Math.random()}
  >
    <InlineContainer
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: 38,
          width: 50,
          background: '#FAFAFB',
          marginRight: 20,
        }}
      >
        <img
          src={require(`@images/icon-cc/${card.brand}-transparent.png`)}
          style={{ width: 30 }}
          alt="card"
        />
      </div>
      <LabelNormal color="#3C4549" fontSize="18">
        *{card.last_digits}
      </LabelNormal>
    </InlineContainer>
    <Row gap="8px" alignItems="flex-end">
      <LinkUnderline
        label={'Ver tarjeta'}
        link={`/doctor/editar-tarjeta-credito`}
        card={{ ...card }}
        margin={'10px 0 0 0'}
      />
      {canDelete && (
        <LabelNormal
          onClick={() => onModalDeleteCreditCard(parseInt(card.id))}
          color="#757575"
          fontSize="16"
          style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
          margin="0"
        >
          Eliminar
        </LabelNormal>
      )}
    </Row>
  </Column>
);

export default PaymentCardCard;
