import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import { QUERY_GET_PATIENT_FILES } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import Input from '@components/shared/Input';
import History from '@components/shared/History';
import ButtonPrime from '@components/shared/ButtonPrime';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import {
  MUTATION_UPDATE_PATIENT,
  MUTATION_ADD_PHOTO_PATIENT,
  MUTATION_ARCHIVIED_TREATMENT,
  MUTATION_DAY_SMARTCHECK,
  MUTATION_UPLOAD_EVIDENCE,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import {
  validateEmail,
  loadPhotoProfileGender,
  buildOdontogramObject,
  buildSTLFilesObject,
} from '../../utils';
import Skeleton from 'react-loading-skeleton';
import Card from '../../components/shared/Card';
import ResumeSS from '../../components/DoctorFormSS/resume';
import ResumeCA from '../../components/DoctorFormCA/resume';
import Actions from '../../components/Case/Actions';
import CardWithLink from '../../components/Case/CardWithLink';
import {
  QUERY_GET_FORM_ARCADE,
  QUERY_GET_FORM_BRACKET,
  QUERY_GET_FORM_IPR,
  QUERY_GET_FORM_ODONTOGRAM,
  QUERY_GET_FORM_SHAPE,
  QUERY_GET_TREATMENT,
} from '@graphql/queries/admin/case-details';
import CaseDetailsContainer from '@pages/App/tablero/caso/case-details.container';
import { UserRoleEnum } from '@models/user-role';
import ContinueTreatmentCard from '@components/case-details/cards/continue-treatment';
import { SmartcheckEnum } from '@models/smartcheck';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const Case = () => {
  const history = useHistory();
  const { id } = useParams();
  // Full treatment info
  const [treatment, setTreatment] = useState();
  // Patient info
  const [patient, setPatient] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [photo, setPhoto] = useState('');
  // Form info
  const [formCAData, setFormCAData] = useState();
  const [formSSData, setFormSSData] = useState();

  const [edit, setEdit] = useState(false);

  const [historyClinic, setHistoryClinic] = useState(false);

  const [photoEvidences, setPhotoEvidences] = useState('');

  const [validDate, setValidDate] = useState(true);
  const [selectItem, setSelectItem] = useState('Formulario');
  const [file, setFile] = useState({});

  const [filesTreatment, setFilesTreatment] = useState({});
  const [filesTreatmentStl, setFilesTreatmentSlt] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});

  const [pendingMessages, setPendingMessages] = useState(0);

  const role = localStorage.getItem('b360-role');

  const [getFormBracket] = useLazyQuery(QUERY_GET_FORM_BRACKET, {
    onCompleted: (data) => {
      if (data.formBracket) {
        setFormSSData({
          ...formSSData,
          brackets: data.formBracket,
        });
      }
    },
  });

  const [getFormOdotogram] = useLazyQuery(QUERY_GET_FORM_ODONTOGRAM, {
    onCompleted: (data) => {
      if (data.formOdontogram) {
        const { up_odontograma, lo_odontograma } = buildOdontogramObject(
          data.formOdontogram
        );
        if (treatment?.treatmentType.id == 1) {
          setFormCAData({
            ...formCAData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
        } else {
          setFormSSData({
            ...formSSData,
            odontograma: data.formOdontogram,
            up_odontograma,
            lo_odontograma,
          });
          getFormBracket({ variables: { treatment_id: treatment?.id } });
        }
      }
    },
  });

  const [getFormIpr] = useLazyQuery(QUERY_GET_FORM_IPR, {
    onCompleted: (data) => {
      if (data.formIpr) {
        setFormCAData({
          ...formCAData,
          ipr: data.formIpr,
        });
      }
      getFormOdotogram({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormShape] = useLazyQuery(QUERY_GET_FORM_SHAPE, {
    onCompleted: (data) => {
      if (data.formShape) {
        setFormCAData({
          ...formCAData,
          shape: data.formShape,
        });
      }
      getFormIpr({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getFormArcade] = useLazyQuery(QUERY_GET_FORM_ARCADE, {
    onCompleted: (data) => {
      if (data.formArcade) {
        setFormCAData({
          ...formCAData,
          arcad: data.formArcade,
        });
      }
      getFormShape({ variables: { treatment_id: treatment?.id } });
    },
  });

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;
        let decode = {};
        patientfiles.forEach((item) => {
          decode = {
            ...decode,
            [item.name]: {
              type: item.file_type,
              name: item.file_name,
              url: item.fileUrl,
            },
          };
        });
        setFilesTreatment(decode);
        if (decode.evidence)
          setPhotoEvidences(decode.evidence.url ? decode.evidence.url : '');
      }
    },
    onError: (err) => console.log(err),
  });

  const { loading: loadingSkeleton, refetch } = useQuery(QUERY_GET_TREATMENT, {
    variables: { id },
    pollInterval: 3000,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.treatment) {
        // Set treatment object
        setTreatment({
          ...data.treatment,
          treatmentStatus: {
            ...data.treatment.treatmentStatus,
            id: parseInt(data.treatment.treatmentStatus.id),
          },
          treatmentType: {
            ...data.treatment.treatmentType,
            id: parseInt(data.treatment.treatmentType.id),
          },
        });
        // Set patient object only if wasn't setted
        // if (!patient) {
        setPatient(data.treatment.patient);
        // }
        // Set pending messages
        setPendingMessages(
          data.treatment?.messages.filter(
            (c) => c.from.type === 'admin' && !c.readed
          ).length
        );
        // Set form CA data if wasn't setted and treatment type is Cryst Aligner
        if (!formCAData && data.treatment.treatmentType.id == 1) {
          setFormCAData({
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormArcade({ variables: { treatment_id: data.treatment.id } });
        }
        // Set form SS data if wasn't setted and treatment type is Smile System
        if (!formSSData && data.treatment.treatmentType.id == 2) {
          setFormSSData({
            bracket_ss: data.treatment.bracket_ss,
            additional: data.treatment.additional_instructions,
            status: data.treatment.form_status,
            histories: data.treatment.histories,
          });
          // Call to first form query
          getFormOdotogram({ variables: { treatment_id: data.treatment.id } });
        }
        if (Object.keys(filesTreatment).length === 0) {
          // Call to patient files query
          getPatientFiles({
            variables: {
              patient_id: data.treatment.patient.id,
              onlyErrors: false,
            },
          });
        }
        if (Object.keys(filesTreatmentStl).length === 0) {
          setFilesTreatmentSlt(buildSTLFilesObject(data.treatment));
        }
        const lastSmartcheck =
          data.treatment.smartChecks
            ?.filter(
              (smartChecks) => smartChecks.status !== SmartcheckEnum.NotAssist
            )
            .sort((a, b) => a.id - b.id)[0] ?? null;
        if (
          data.treatment.treatmentStatus.type === 'planning' &&
          data.treatment.treatmentStatus.index !== 7 &&
          lastSmartcheck?.status === 'Pending'
        ) {
          changeDaySmartcheck({
            variables: { patient_id: data.treatment.patient.id },
          });
        }
      }
    },
  });

  const [uploadEvidence] = useMutation(MUTATION_UPLOAD_EVIDENCE, {
    onCompleted: (data) => {
      if (data.addPatientEvidencePhoto) {
        modal.close();
        if (
          !(
            treatment?.treatmentStatus.type === 'finished' &&
            treatment?.treatmentStatus.index === 3
          )
        ) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  setPhotoEvidences(
                    data.addPatientEvidencePhoto.fileUrl
                      ? data.addPatientEvidencePhoto.fileUrl
                      : ''
                  );
                  refetch();
                })
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                ¡Felicidades! Un caso más a la bolsa
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Evidencias de fin de caso subidas, puedes consultarlas en tu
                historial clínico. <br />
                Te notificaremos de la acumulación de puntos cuando definamos la
                dificultad de tu caso.
              </LabelNormal>
            </ModalSuccess>
          );
        } else {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  refetch();
                })
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Evidencia actualizada
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14, marginBottom: 15 }}
              >
                Fotografía de evidencia correctamente actualizada
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    },
  });

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: () => {
      if (file.name) addProfilePhoto({ variables: { file, patient_id: +id } });
      else {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setEdit(false);
                refetch();
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_PHOTO_PATIENT, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setEdit(false);
              refetch();
            })
          }
        >
          <LabelNormal color="#060809" fontSize="22" style={{ marginTop: 30 }}>
            Cambios a perfil guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const onChangeEvidences = (evt) => {
    const prefile = evt.target.files[0];
    if (prefile) {
      setPhotoEvidences(URL.createObjectURL(prefile));
      uploadEvidence({ variables: { file: prefile, patient_id: id } });
    }
  };

  const [archivedTreatment] = useMutation(MUTATION_ARCHIVIED_TREATMENT, {
    onCompleted: (data) => {
      if (data.changeArchivedTreatment) {
        modal.close();
        refetch();
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  const [changeDaySmartcheck] = useMutation(MUTATION_DAY_SMARTCHECK, {
    onCompleted: (data) => {
      if (data.daySmartcheck) {
        refetch();
      }
    },
  });

  const handleOpenModal = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          Se pausará el tratamiento
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17" style={{ marginTop: 14 }}>
          Puedes encontrarlo en el tablero de tus casos buscando por nombre o
          filtrando por casos inactivos
        </LabelNormal>
        <ButtonPrime
          style={{ marginTop: 20 }}
          onClick={() =>
            archivedTreatment({ variables: { patient_id: id, archived: true } })
          }
          label="Mover a casos inactivos"
        />
      </ModalWarning>
    );
  };

  useEffect(() => {
    if (patient) {
      setFirstName(patient.first_name);
      setLastName(patient.last_name);
      setPhone(patient.phone);
      setGender(patient.gender);
      setPhoto(
        patient.photo_filename
          ? `${process.env.REACT_APP_API_URL}/patients/${patient.id}/profile/${patient.photo_filename}`
          : ''
      );
      setEmail(patient.email);

      if (patient.birthdate) {
        setDay(patient.birthdate.split('-')[2]);
        setMonth(patient.birthdate.split('-')[1]);
        setYear(patient.birthdate.split('-')[0]);
      }
    }
  }, [patient]);

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {
      let date = moment(`${year}-${month}-${day}`);
      setValidDate(date.isValid());
    }
  }, [day, month, year]);

  const historyBack = () => {
    if (historyClinic) {
      setHistoryClinic(false);
    } else if (edit) {
      setEdit(false);
    } else {
      history.push('/doctor/tus-casos');
    }
  };

  const navigateToInvoices = () => {
    history.push({
      pathname: `/doctor/invoices/${treatment?.id}`,
      state: {
        firstName,
        lastName,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => historyBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {!edit && !historyClinic && (
                <div className="col-md-12">
                  <Title>Caso</Title>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.push('doctor/tus-casos')}
                      mainLabel={'Tus casos'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                </div>
              )}
              {edit && (
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <Title>Editar perfil</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Edita los datos y guarda los cambios.
                  </LabelNormal>
                </div>
              )}
              {historyClinic && (
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                  <Title>Historial clínico</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Consulta los archivos de tu paciente.
                  </LabelNormal>
                </div>
              )}
              <div className={historyClinic ? 'col-md-12' : 'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    {(treatment?.treatmentStatus.type === 'finished' &&
                      treatment?.treatmentStatus.index === 2) ||
                    photoEvidences !== '' ? (
                      <>
                        <InlineContainer
                          style={{ alignItems: 'center', marginBottom: 10 }}
                        >
                          {!loadingSkeleton ? (
                            <>
                              <Avatar
                                src={
                                  photo ? photo : loadPhotoProfileGender(gender)
                                }
                                floatButton={edit}
                                edit={!!photo}
                                onChange={onChange}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                              <FeatherIcon
                                icon="arrow-right"
                                size={45}
                                style={{ marginLeft: 40, marginRight: 40 }}
                              />
                              <Avatar
                                src={
                                  photoEvidences
                                    ? photoEvidences
                                    : loadPhotoProfileGender(gender)
                                }
                                onChange={onChangeEvidences}
                                floatButton
                                edit={photoEvidences !== '' ? true : false}
                                height="161"
                                width="161"
                                borderRadius="30"
                              />
                            </>
                          ) : (
                            <div>
                              <Skeleton
                                height={161}
                                borderRadius={27}
                                width={161}
                                baseColor={'#EEEEF2'}
                                highlightColor={'#FFF'}
                              />
                            </div>
                          )}
                        </InlineContainer>
                        <InlineContainer>
                          {!loadingSkeleton ? (
                            <>
                              {!edit && !historyClinic && (
                                <div style={{ marginLeft: 35 }}>
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <LabelNormal
                                      color="#436FF1"
                                      fontSize="21"
                                      style={{ marginRight: 7 }}
                                    >
                                      PX.
                                    </LabelNormal>
                                    <LabelNormal
                                      fontSize="30"
                                      color="#3C4549"
                                    >{`${firstName} ${lastName}`}</LabelNormal>
                                  </InlineContainer>
                                  <InlineContainer>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <InlineContainer>
                                          <LabelNormal
                                            color="#4F4F4F"
                                            fontSize="20"
                                          >
                                            ID
                                          </LabelNormal>
                                          <LabelNormal
                                            color="#BDBDBD"
                                            fontSize="20"
                                          >
                                            {id}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      <div className="col-md-12">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="phone" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {phone}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                      {email && (
                                        <div className="col-md-7">
                                          <InlineContainer
                                            style={{ alignItems: 'center' }}
                                          >
                                            <FeatherIcon
                                              icon="email"
                                              size={19}
                                            />
                                            <LabelNormal
                                              fontSize="14"
                                              color="#3C4549"
                                            >
                                              {email}
                                            </LabelNormal>
                                          </InlineContainer>
                                        </div>
                                      )}
                                    </div>
                                  </InlineContainer>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft: 35,
                                marginTop: 10,
                                width: '100%',
                              }}
                            >
                              <div>
                                <Skeleton
                                  baseColor={'#F6F6F8'}
                                  width={'70%'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }}>
                                  <div className="col-md-7">
                                    <Skeleton
                                      count={1}
                                      baseColor={'#F6F6F8'}
                                      height={27}
                                      borderRadius={5}
                                      highlightColor={'#FFF'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {historyClinic && (
                            <div style={{ marginLeft: 35 }}>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  fontSize="30"
                                  color="#3C4549"
                                >{`${firstName} ${lastName}`}</LabelNormal>
                              </InlineContainer>
                              <InlineContainer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginBottom: 10,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#828282"
                                        fontSize="13"
                                      >
                                        Tratamiento
                                      </LabelNormal>
                                      <LabelNormal
                                        color={
                                          treatment?.treatmentType.id == 1
                                            ? '#18A1DC'
                                            : '#F0BB00'
                                        }
                                        fontSize="20.45"
                                      >
                                        {treatment?.treatmentType.id == 1
                                          ? 'Cryst Aligner'
                                          : 'Smile System'}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div className="col-md-6">
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="user" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {gender}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div className="col-md-6">
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="phone" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {phone}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div
                                    className="col-md-6"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon size="19" icon="gift" />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >{`${year}-${month}-${day}`}</LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  {email && (
                                    <div
                                      className="col-md-6"
                                      style={{ marginTop: 8 }}
                                    >
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <img
                                          src={require('@images/icons/email.png')}
                                          style={{ height: 16, width: 15.5 }}
                                        />
                                        <LabelNormal
                                          fontSize="14"
                                          color="#3C4549"
                                        >
                                          {email}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                  )}
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </InlineContainer>
                      </>
                    ) : (
                      <InlineContainer>
                        {!loadingSkeleton ? (
                          <div>
                            <Avatar
                              src={
                                photo ? photo : loadPhotoProfileGender(gender)
                              }
                              floatButton={edit}
                              edit={!!photo}
                              onChange={onChange}
                              height="161"
                              width="161"
                              borderRadius="30"
                            />
                          </div>
                        ) : (
                          <div>
                            <Skeleton
                              height={161}
                              borderRadius={27}
                              width={161}
                              baseColor={'#EEEEF2'}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        )}
                        {!loadingSkeleton ? (
                          <>
                            {!edit && !historyClinic && (
                              <div style={{ marginLeft: 35 }}>
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 8,
                                  }}
                                >
                                  <LabelNormal
                                    color="#436FF1"
                                    fontSize="21"
                                    style={{
                                      marginRight: 7,
                                      fontFamily: 'neue-montreal-medium',
                                      margin: '0px 12px 0px 0px',
                                    }}
                                  >
                                    PX
                                  </LabelNormal>
                                  <LabelNormal
                                    fontSize="22"
                                    color="#3C4549"
                                    style={{
                                      fontFamily: 'neue-montreal-medium',
                                      margin: 0,
                                    }}
                                  >{`${firstName} ${lastName}`}</LabelNormal>
                                </InlineContainer>
                                <InlineContainer>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ marginBottom: 8 }}
                                      >
                                        <LabelNormal
                                          color="#4F4F4F"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: '0px 12px 0px 0px',
                                          }}
                                        >
                                          ID
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#BDBDBD"
                                          fontSize="20"
                                          style={{
                                            fontFamily: 'neue-montreal-medium',
                                            margin: 0,
                                            letterSpacing: '0.11em',
                                          }}
                                        >
                                          {id}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    <div className="col-md-12">
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <FeatherIcon
                                          icon="phone"
                                          color="#757575"
                                          size={19}
                                        />
                                        <LabelNormal
                                          fontSize="17"
                                          color="#3C4549"
                                          style={{ margin: '0px 8px' }}
                                        >
                                          {treatment?.doctor?.user?.country
                                            ? treatment?.doctor?.user?.country
                                                ?.name === 'México'
                                              ? '+52 '
                                              : treatment?.doctor?.user?.country
                                                  ?.name === 'Panamá'
                                              ? '+507 '
                                              : '+502 '
                                            : ''}
                                          {phone}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                    {email && (
                                      <div className="col-md-7">
                                        <InlineContainer
                                          style={{ alignItems: 'center' }}
                                        >
                                          <FeatherIcon icon="email" size={19} />
                                          <LabelNormal
                                            fontSize="14"
                                            color="#3C4549"
                                          >
                                            {email}
                                          </LabelNormal>
                                        </InlineContainer>
                                      </div>
                                    )}
                                  </div>
                                </InlineContainer>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 35,
                              marginTop: 10,
                              width: '100%',
                            }}
                          >
                            <div>
                              <Skeleton
                                baseColor={'#F6F6F8'}
                                width={'70%'}
                                height={27}
                                borderRadius={5}
                                highlightColor={'#FFF'}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <div className="row">
                                <div className="col-md-5">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 10 }}>
                                <div className="col-md-7">
                                  <Skeleton
                                    count={1}
                                    baseColor={'#F6F6F8'}
                                    height={27}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {historyClinic && (
                          <div style={{ marginLeft: 35 }}>
                            <InlineContainer style={{ alignItems: 'center' }}>
                              <LabelNormal
                                fontSize="30"
                                color="#3C4549"
                              >{`${firstName} ${lastName}`}</LabelNormal>
                            </InlineContainer>
                            <InlineContainer>
                              <div className="row">
                                <div className="col-md-12">
                                  <InlineContainer
                                    style={{
                                      alignItems: 'center',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <LabelNormal color="#828282" fontSize="13">
                                      Tratamiento
                                    </LabelNormal>
                                    <LabelNormal
                                      color={
                                        treatment?.treatmentType.id == 1
                                          ? '#18A1DC'
                                          : '#F0BB00'
                                      }
                                      fontSize="20.45"
                                    >
                                      {treatment?.treatmentType.id == 1
                                        ? 'Cryst Aligner'
                                        : 'Smile System'}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-6">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="user" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {gender}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div className="col-md-6">
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="phone" />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {phone}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 8 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <FeatherIcon size="19" icon="gift" />
                                    <LabelNormal
                                      fontSize="14"
                                      color="#3C4549"
                                    >{`${year}-${month}-${day}`}</LabelNormal>
                                  </InlineContainer>
                                </div>
                                {email && (
                                  <div
                                    className="col-md-6"
                                    style={{ marginTop: 8 }}
                                  >
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <img
                                        src={require('@images/icons/email.png')}
                                        style={{ height: 16, width: 15.5 }}
                                      />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {email}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                )}
                              </div>
                            </InlineContainer>
                          </div>
                        )}
                      </InlineContainer>
                    )}
                  </div>
                  {!edit && !historyClinic && (
                    <div
                      className="col-md-12"
                      style={{
                        marginTop: 47,
                        filter:
                          'drop-shadow(0px 0px 4px rgba(162, 162, 162, 0.1))',
                      }}
                    >
                      <Card
                        padding="0px"
                        border="none"
                        minWidth="572px"
                        borderRadius="30px"
                      >
                        <HeaderCard>
                          <LabelNormal
                            color="#3C4549"
                            fontSize="17"
                            style={{
                              fontFamily: 'neue-montreal-medium',
                              margin: 0,
                            }}
                          >
                            Historial
                          </LabelNormal>
                        </HeaderCard>
                        {!loadingSkeleton ? (
                          <CardContainer>
                            <div style={{ textAlign: 'center' }}>
                              <LabelNormal
                                color="#3C4549"
                                fontSize="12"
                                style={{ textTransform: 'uppercase' }}
                              >
                                Paso actual
                              </LabelNormal>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <History
                                  histories={treatment?.histories ?? []}
                                  type="doctor"
                                ></History>
                              </div>
                            </div>
                          </CardContainer>
                        ) : (
                          <>
                            <CardContainer style={{ minHeight: 500 }}>
                              <center>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={16}
                                  width={'20%'}
                                  highlightColor={'#FFF'}
                                />
                              </center>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 33,
                                  border: '3px solid #EEEEF2',
                                  borderRadius: 10,
                                  padding: 10,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  justifyContent: 'space-between',
                                  marginTop: 23,
                                }}
                              >
                                <div className="col-md-6">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Skeleton
                                    borderRadius={5}
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={69}
                                    width={'100%'}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </CardContainer>
                          </>
                        )}
                      </Card>
                    </div>
                  )}
                  {edit && (
                    <div className="col-md-12">
                      <div className="flex flex-col gap-4 my-6">
                        <div className="flex items-end">
                          <Input
                            placeholder="Nombre"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            label="Nombre del paciente"
                          />
                          <Input
                            placeholder="Apellidos"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-row items-baseline">
                          <LabelNormal
                            color="#828282"
                            fontSize="13"
                            style={{ margin: 0 }}
                          >
                            Tratamiento
                          </LabelNormal>
                          <LabelNormal
                            color={
                              treatment?.treatmentType.id == 1
                                ? '#18A1DC'
                                : '#F0BB00'
                            }
                            fontSize="20.45"
                          >
                            {treatment?.treatmentType.id == 1
                              ? 'Cryst Aligner'
                              : 'Smile System'}
                          </LabelNormal>
                        </div>
                        <div className="flex flex-col">
                          <LabelInput>Sexo</LabelInput>
                          <InlineContainer>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="gender-1"
                                onClick={(e) => setGender('Masculino')}
                                checked={
                                  gender === 'Masculino' ? 'checked' : false
                                }
                                name="gender"
                                type="radio"
                              />
                              <label htmlFor="gender-1" className="radio-label">
                                Hombre
                              </label>
                            </div>
                            <div className="radio">
                              <input
                                id="gender-2"
                                onClick={(e) => setGender('Femenino')}
                                checked={
                                  gender === 'Femenino' ? 'checked' : false
                                }
                                name="gender"
                                type="radio"
                              />
                              <label htmlFor="gender-2" className="radio-label">
                                Mujer
                              </label>
                            </div>
                          </InlineContainer>
                        </div>
                        <div className="flex flex-col">
                          <LabelInput>Fecha de nacimiento</LabelInput>
                          <InlineContainer>
                            <Input
                              placeholder="DD"
                              maxWidth="60px"
                              value={day}
                              onChange={setDay}
                              mask="date2"
                              type="day"
                            />
                            <Input
                              placeholder="MM"
                              maxWidth="60px"
                              value={month}
                              onChange={setMonth}
                              mask="date2"
                              type="monthInput"
                            />
                            <Input
                              placeholder="AAAA"
                              maxWidth="90px"
                              value={year}
                              onChange={setYear}
                              mask="date4"
                            />
                          </InlineContainer>
                          {!validDate && (
                            <InlineContainer>
                              <p
                                style={{
                                  color: '#C60E02',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Fecha incorrecta
                              </p>
                            </InlineContainer>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <LabelInput>Teléfono</LabelInput>
                          <InlineContainer>
                            <Input
                              readOnly
                              disabled
                              value={
                                treatment?.doctor?.user?.country
                                  ? treatment?.doctor?.user?.country?.name ===
                                    'México'
                                    ? '+52 '
                                    : treatment?.doctor?.user?.country?.name ===
                                      'Panamá'
                                    ? '+507 '
                                    : '+502 '
                                  : ''
                              }
                              backgroundColor="#F6F6F8"
                              maxWidth={
                                treatment?.doctor?.user?.country?.name ===
                                'México'
                                  ? '68px'
                                  : '75px'
                              }
                            />
                            <Input
                              placeholder="Teléfono"
                              value={phone}
                              onChange={setPhone}
                              mask="phone"
                              validation="phone"
                            />
                          </InlineContainer>
                        </div>
                        <div className="flex flex-col">
                          <Input
                            placeholder="Tu correo"
                            label="Email (Opcional)"
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            maxWidth="300px"
                          />
                        </div>
                      </div>
                      <ButtonPrime
                        label="Guardar cambios"
                        disabled={
                          validDate &&
                          (email === null ||
                            email === '' ||
                            (email !== '' && validateEmail(email)))
                            ? false
                            : true
                        }
                        onClick={() =>
                          updatePatient({
                            variables: {
                              patient_id: treatment?.patient.id,
                              first_name: firstName,
                              last_name: lastName,
                              email: email === null ? '' : email,
                              phone,
                              gender,
                              birthdate: `${year}-${month}-${day}`,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: historyClinic ? 60 : 0 }}
                    >
                      <InlineContainer
                        style={{
                          backgroundColor: '#EEEFF1',
                          borderRadius: 40,
                          paddingTop: 30,
                          paddingLeft: 20,
                          paddingBottom: 30,
                          alignItems: 'self-start',
                        }}
                      >
                        <div
                          style={{
                            marginRight: 40,
                            position: 'sticky',
                            top: '50px',
                          }}
                        >
                          {[
                            'Formulario',
                            'Intraorales',
                            'Extraorales',
                            "STL's",
                            'Radiografias',
                          ].map((row, index) => (
                            <div
                              style={{
                                maxWidth: 165,
                                maxHeight: 55,
                                background:
                                  selectItem === row ? '#FFF' : 'transparent',
                                borderBottomColor:
                                  selectItem === row
                                    ? 'transparent'
                                    : '#D4D4D4',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                padding: 15,
                                borderRadius: selectItem !== row ? 0 : 10,
                              }}
                              key={Math.random()}
                            >
                              <LabelNormal
                                color="#000000"
                                fontSize="18.5"
                                onClick={() => setSelectItem(row)}
                                style={{ cursor: 'pointer' }}
                              >
                                {row}
                              </LabelNormal>
                            </div>
                          ))}
                        </div>
                        <div>
                          {selectItem === 'Formulario' && (
                            <div className="row">
                              <div className="col-md-12">
                                {formSSData.brackets &&
                                  treatment?.treatmentType.id == 2 && (
                                    <ResumeSS
                                      formSSData={formSSData}
                                      chatMessages={[]}
                                    />
                                  )}
                                {formCAData.arcad &&
                                  treatment?.treatmentType.id == 1 && (
                                    <ResumeCA
                                      formCAData={formCAData}
                                      chatMessages={[]}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Radiografias' && (
                            <div className="row">
                              <div className="col-md-7">
                                <InlineContainer>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral'] &&
                                          filesTreatment['lateral'].url
                                            ? filesTreatment['lateral'].url
                                            : require('@images/patient/lateral.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral'] &&
                                      filesTreatment['lateral'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['lateral'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Lateral
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['panoramica'] &&
                                          filesTreatment['panoramica'].url
                                            ? filesTreatment['panoramica'].url
                                            : require('@images/patient/panoramica.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['panoramica'] &&
                                      filesTreatment['panoramica'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['panoramica'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Panorámica
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ] &&
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ].url
                                            ? filesTreatment[
                                                'trazado_cefalometrico'
                                              ].url
                                            : require('@images/patient/trazado_cefalometrico.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['trazado_cefalometrico'] &&
                                      filesTreatment['trazado_cefalometrico']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment[
                                              'trazado_cefalometrico'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Trazado cefalométrico
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['tomografia'] &&
                                          filesTreatment['tomografia'].url
                                            ? require('@images/patient/zip-upload.png')
                                            : require('@images/patient/tomografia.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['tomografia'] &&
                                      filesTreatment['tomografia'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['tomografia'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Tomografía
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === "STL's" && (
                            <div className="row">
                              <div className="col-md-7">
                                {treatment?.stl_type === 'shipping' && (
                                  <div>
                                    <LabelNormal color="#3C4549" fontSize="17">
                                      Se seleccionó envío de modelos por
                                      paquetería a la siguiente dirección:
                                    </LabelNormal>
                                    <InlineContainer>
                                      <CardModel style={{ maxWidth: 356 }}>
                                        <LabelNormal
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            marginRight: 7,
                                            marginBottom: 10,
                                          }}
                                          color="#757575"
                                          fontSize="18"
                                        >
                                          Dirección de envío | Borgatta
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#828282"
                                          style={{ margin: 0, padding: 0 }}
                                          fontSize="17"
                                        >
                                          {defaultAddress.id
                                            ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code} ${defaultAddress.state}`
                                            : ''}
                                        </LabelNormal>
                                      </CardModel>
                                    </InlineContainer>
                                  </div>
                                )}
                                {treatment?.stl_type === 'url' && (
                                  <div>
                                    {treatment?.link_data.map((item, key) => (
                                      <LabelNormal
                                        key={`item-${key}`}
                                        color="#3C4549"
                                        fontSize="20"
                                      >
                                        {item}
                                      </LabelNormal>
                                    ))}
                                  </div>
                                )}
                                {treatment?.stl_type !== 'url' &&
                                  treatment?.stl_type !== 'shipping' && (
                                    <InlineContainer>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            src={
                                              filesTreatmentStl.arcada_superior
                                                ? require('@images/patient/stl-upload.png')
                                                : require('@images/patient/arcada_superior.png')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                            }}
                                          />
                                          <a
                                            href={
                                              filesTreatmentStl.arcada_superior
                                                ?.file.file_url
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                              background:
                                                'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                              height: 24,
                                              width: 24,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              borderRadius: 26,
                                              position: 'absolute',
                                              right: 32,
                                              bottom: 10,
                                            }}
                                            download={
                                              filesTreatmentStl.arcada_superior
                                                ?.file.file_url
                                            }
                                          >
                                            <FeatherIcon
                                              icon="download"
                                              color="#FFF"
                                              size="16"
                                            />
                                          </a>
                                        </div>
                                        {filesTreatmentStl.arcada_superior &&
                                          filesTreatmentStl.arcada_superior
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="14"
                                            >
                                              {
                                                filesTreatmentStl
                                                  .arcada_superior?.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="14"
                                        >
                                          Arcada superior
                                        </LabelNormal>
                                      </div>
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            src={
                                              filesTreatmentStl.oclusion_derecha
                                                ? require('@images/patient/stl-upload.png')
                                                : require('@images/patient/oclusion.png')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                            }}
                                          />
                                          <a
                                            href={
                                              filesTreatmentStl.oclusion_derecha
                                                ?.file.file_url
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                              background:
                                                'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                              height: 24,
                                              width: 24,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              borderRadius: 26,
                                              position: 'absolute',
                                              right: 32,
                                              bottom: 10,
                                            }}
                                            download={
                                              filesTreatmentStl.oclusion_derecha
                                                ?.file.file_url
                                            }
                                          >
                                            <FeatherIcon
                                              icon="download"
                                              color="#FFF"
                                              size="16"
                                            />
                                          </a>
                                        </div>
                                        {filesTreatmentStl.oclusion_derecha &&
                                          filesTreatmentStl.oclusion_derecha
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="14"
                                            >
                                              {
                                                filesTreatmentStl
                                                  .oclusion_derecha?.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="14"
                                        >
                                          Oclusión/Oclusión derecha
                                        </LabelNormal>
                                      </div>
                                      {filesTreatmentStl.oclusion_izquierda &&
                                        filesTreatmentStl.oclusion_izquierda
                                          .file && (
                                          <div
                                            style={{
                                              marginRight: 20,
                                              marginBottom: 20,
                                            }}
                                          >
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <img
                                                src={
                                                  filesTreatmentStl.oclusion_izquierda
                                                    ? require('@images/patient/stl-upload.png')
                                                    : require('@images/patient/oclusion.png')
                                                }
                                                style={{
                                                  height: 104,
                                                  width: 162,
                                                  borderRadius: 3,
                                                }}
                                              />
                                              <a
                                                href={
                                                  filesTreatmentStl
                                                    .oclusion_izquierda?.file
                                                    .file_url
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                  background:
                                                    'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                                  height: 24,
                                                  width: 24,
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  borderRadius: 26,
                                                  position: 'absolute',
                                                  right: 32,
                                                  bottom: 10,
                                                }}
                                                download={
                                                  filesTreatmentStl
                                                    .oclusion_izquierda?.file
                                                    .file_url
                                                }
                                              >
                                                <FeatherIcon
                                                  icon="download"
                                                  color="#FFF"
                                                  size="16"
                                                />
                                              </a>
                                            </div>
                                            {filesTreatmentStl.oclusion_izquierda &&
                                              filesTreatmentStl
                                                .oclusion_izquierda.file && (
                                                <LabelNormal
                                                  style={{
                                                    width: 162,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                  }}
                                                  color="#828282"
                                                  fontSize="14"
                                                >
                                                  {
                                                    filesTreatmentStl
                                                      .oclusion_izquierda?.file
                                                      .name
                                                  }
                                                </LabelNormal>
                                              )}
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="14"
                                            >
                                              Oclusión izquierda
                                            </LabelNormal>
                                          </div>
                                        )}
                                      <div
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20,
                                        }}
                                      >
                                        <div style={{ position: 'relative' }}>
                                          <img
                                            src={
                                              filesTreatmentStl.arcada_inferior
                                                ? require('@images/patient/stl-upload.png')
                                                : require('@images/patient/arcada_inferior.png')
                                            }
                                            style={{
                                              height: 104,
                                              width: 162,
                                              borderRadius: 3,
                                            }}
                                          />
                                          <a
                                            href={
                                              filesTreatmentStl.arcada_inferior
                                                ?.file.file_url
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                              background:
                                                'linear-gradient(101.94deg, #E3772D -18.13%, #F2B559 109.46%)',
                                              height: 24,
                                              width: 24,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              borderRadius: 26,
                                              position: 'absolute',
                                              right: 32,
                                              bottom: 10,
                                            }}
                                            download={
                                              filesTreatmentStl.arcada_inferior
                                                ?.file.file_url
                                            }
                                          >
                                            <FeatherIcon
                                              icon="download"
                                              color="#FFF"
                                              size="16"
                                            />
                                          </a>
                                        </div>
                                        {filesTreatmentStl.arcada_inferior &&
                                          filesTreatmentStl.arcada_inferior
                                            .file && (
                                            <LabelNormal
                                              style={{
                                                width: 162,
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                              }}
                                              color="#828282"
                                              fontSize="14"
                                            >
                                              {
                                                filesTreatmentStl
                                                  .arcada_inferior?.file.name
                                              }
                                            </LabelNormal>
                                          )}
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="14"
                                        >
                                          Arcada inferior
                                        </LabelNormal>
                                      </div>
                                    </InlineContainer>
                                  )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Extraorales' && (
                            <div>
                              <div
                                className="row"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_serio'] &&
                                          filesTreatment['frontal_serio'].url
                                            ? filesTreatment['frontal_serio']
                                                .url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_serio'] &&
                                      filesTreatment['frontal_serio'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {filesTreatment['frontal_serio'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_sonriendo'] &&
                                          filesTreatment['frontal_sonriendo']
                                            .url
                                            ? filesTreatment[
                                                'frontal_sonriendo'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_sonriendo'] &&
                                      filesTreatment['frontal_sonriendo']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['frontal_sonriendo']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal sonriendo
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'frontal_retractores'
                                          ] &&
                                          filesTreatment['frontal_retractores']
                                            .url
                                            ? filesTreatment[
                                                'frontal_retractores'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_retractores'] &&
                                      filesTreatment['frontal_retractores']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment[
                                              'frontal_retractores'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal con retractores
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_izq_serio'] &&
                                          filesTreatment['lateral_izq_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_izq_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_izq_serio'] &&
                                      filesTreatment['lateral_izq_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_izq_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral izquierda serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_der_serio'] &&
                                          filesTreatment['lateral_der_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_der_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_der_serio'] &&
                                      filesTreatment['lateral_der_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_der_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral derecha serio
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectItem === 'Intraorales' && (
                            <div>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_superior'] &&
                                        filesTreatment['oclusal_superior'].url
                                          ? filesTreatment['oclusal_superior']
                                              .url
                                          : require('@images/patient/superior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_superior'] &&
                                    filesTreatment['oclusal_superior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_superior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal superior
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{
                                    width: 162,
                                    marginRight: 20,
                                    marginBottom: 20,
                                  }}
                                ></div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_inferior'] &&
                                        filesTreatment['oclusal_inferior'].url
                                          ? filesTreatment['oclusal_inferior']
                                              .url
                                          : require('@images/patient/inferior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_inferior'] &&
                                    filesTreatment['oclusal_inferior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_inferior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal inferior
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="derecha"
                                      src={
                                        filesTreatment['lat_der_oclusion'] &&
                                        filesTreatment['lat_der_oclusion'].url
                                          ? filesTreatment['lat_der_oclusion']
                                              .url
                                          : require('@images/patient/lateral-derecha.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_der_oclusion'] &&
                                    filesTreatment['lat_der_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_der_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal
                                    color="#3C4549"
                                    fontSize="15"
                                    style={{ width: 162 }}
                                  >
                                    Lateral derecha en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="frontal"
                                      src={
                                        filesTreatment['frontal_oclusion'] &&
                                        filesTreatment['frontal_oclusion'].url
                                          ? filesTreatment['frontal_oclusion']
                                              .url
                                          : require('@images/patient/frontal.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['frontal_oclusion'] &&
                                    filesTreatment['frontal_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['frontal_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Frontal en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      alt="izquierda"
                                      src={
                                        filesTreatment['lat_izq_oclusion'] &&
                                        filesTreatment['lat_izq_oclusion'].url
                                          ? filesTreatment['lat_izq_oclusion']
                                              .url
                                          : require('@images/patient/lateral-izquierda.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_izq_oclusion'] &&
                                    filesTreatment['lat_izq_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_izq_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal
                                    color="#3C4549"
                                    fontSize="15"
                                    style={{ width: 162 }}
                                  >
                                    Lateral izquierda en oclusión
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!historyClinic && (
                  <div className="flex flex-col gap-4">
                    {treatment && (
                      <div className="flex flex-col gap-4 w-full">
                        <CaseDetailsContainer
                          viewer={role}
                          treatment={treatment}
                          refresh={refetch}
                        />
                        <ContinueTreatmentCard
                          treatment={treatment}
                          viewer={role}
                        />
                      </div>
                    )}
                    {role !== 'teacher' && (
                      <CardWithLink
                        linkTo={{
                          pathname: `/doctor/caso/mensajes-borgatta/${treatment?.id}`,
                        }}
                        content="Mensajes de Borgatta"
                        icon="message-circle"
                        active={pendingMessages > 0}
                      />
                    )}
                    <Actions
                      patientId={treatment?.patient.id}
                      treatmentRelated={treatment}
                      archived={treatment?.archived}
                      loadingSkeleton={loadingSkeleton}
                      setEdit={setEdit}
                      setHistoryClinic={setHistoryClinic}
                      navigateToInvoices={navigateToInvoices}
                      handleOpenModal={handleOpenModal}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
