import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { MUTATION_UPDATE_STUDENT } from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useHistory, useParams } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import Breadcrumb from '../../../components/Navigation/Breadcrumb/Breadcrumb';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const RegisterStudent = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [period, setPeriod] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [gender, setGender] = useState('');
  const [treatment, setTreatment] = useState('');

  const [nameUniversity, setNameUniversity] = useState('');
  const [universityId, setUniversityId] = useState('');

  const [getStudent] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;

        setFirstName(doctor.user.first_name);
        setLastName(doctor.user.last_name);
        setGender(doctor.user.gender);
        setEmail(doctor.user.email);
        setPeriod(doctor.semester);
        setNameUniversity(doctor.university.name);
        setUniversityId(doctor.university.id);

        if (doctor.smile_system && doctor.cryst_aligner) {
          setTreatment('ambos');
        } else if (doctor.cryst_aligner) {
          setTreatment('cryst_aligner');
        } else if (doctor.smile_system) {
          setTreatment('smile_system');
        }
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getStudent({ variables: { id } });
  }, []);

  const [updateStudent] = useMutation(MUTATION_UPDATE_STUDENT, {
    onCompleted: async (data) => {
      if (data.updateStudent) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.goBack())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Estudiante actualizado
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, period, email, gender, treatment]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (period === '') {
      setDisabled(true);
    } else if (email === '') {
      setDisabled(true);
    } else if (gender === '') {
      setDisabled(true);
    } else if (treatment === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    updateStudent({
      variables: {
        id: id,
        first_name: firstName,
        last_name: lastName,
        email,
        semester: +period,
        gender,
        cryst_aligner:
          treatment === 'cryst_aligner' || treatment === 'ambos' ? true : false,
        smile_system:
          treatment === 'smile_system' || treatment === 'ambos' ? true : false,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Editar estudiante</Title>
          <div style={{ marginTop: 10, marginBottom: 39 }}>
            <Breadcrumb
              elements={2}
              onClick={() => history.push(`/app/universidades/${universityId}`)}
              mainLabel={nameUniversity}
              onSecondClick={() => history.goBack()}
              midLabel={'Estudiante'}
              endLabel={`${firstName} ${lastName}`}
            />
          </div>

          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre(s)"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="Nombre y apellidos"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div>
            <LabelInput>Género</LabelInput>
            <InlineContainer>
              <div className="radio" style={{ marginRight: 20 }}>
                <input
                  id="radio-1"
                  onClick={(e) => setGender('Masculino')}
                  checked={gender === 'Masculino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-1" className="radio-label">
                  Masculino
                </label>
              </div>
              <div className="radio">
                <input
                  id="radio-2"
                  onClick={(e) => setGender('Femenino')}
                  checked={gender === 'Femenino' ? 'checked' : false}
                  name="gender"
                  type="radio"
                />
                <label for="radio-2" className="radio-label">
                  Femenino
                </label>
              </div>
            </InlineContainer>
          </div>

          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Período"
                label="Semestre"
                value={period}
                onChange={(e) =>
                  setPeriod(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
            </div>
          </div>

          <div style={{ marginTop: 28 }}>
            <LabelInput>Capacitaciones</LabelInput>
            <InlineContainer>
              <div className="radio" style={{ marginRight: 20 }}>
                <input
                  id="radio-treatment-1"
                  onClick={(e) => setTreatment('cryst_aligner')}
                  checked={treatment === 'cryst_aligner' ? 'checked' : false}
                  name="treatment"
                  type="radio"
                />
                <label for="radio-treatment-1" className="radio-label">
                  Cryst Aligner
                </label>
              </div>
              <div className="radio" style={{ marginRight: 20 }}>
                <input
                  id="radio-treatment-2"
                  onClick={(e) => setTreatment('smile_system')}
                  checked={treatment === 'smile_system' ? 'checked' : false}
                  name="treatment"
                  type="radio"
                />
                <label for="radio-treatment-2" className="radio-label">
                  Smile System
                </label>
              </div>
              <div className="radio">
                <input
                  id="radio-treatment-3"
                  onClick={(e) => setTreatment('ambos')}
                  checked={treatment === 'ambos' ? 'checked' : false}
                  name="treatment"
                  type="radio"
                />
                <label for="radio-treatment-3" className="radio-label">
                  Ambos
                </label>
              </div>
            </InlineContainer>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Guardar cambios"
              disabled={disabled}
              onClick={() => handlerSubmit()}
              style={{ marginRight: 10 }}
            />
            <ButtonPrime
              background="transparent"
              label="Cancelar"
              border="2px solid #3C4549"
              color="#3C4549"
              onClick={() => history.goBack()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default RegisterStudent;
