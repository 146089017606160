import React from 'react';
import styled from 'styled-components';
import ButtonPrime from '@components/shared/ButtonPrime';
import { Link } from 'react-router-dom';
import { loadPhotoProfileGender } from '../../utils';
import FeatherIcon from 'feather-icons-react';
import Card from './Card';
import { Column } from './Styled';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 1.5px;
`;

const TypeTreatment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 23px;
  border-radius: 28px;
  margin-left: 10px;
  background: ${({ background }) => (background ? background : '#F0BB00')};
`;

const CaseStatusIcon = styled.div`
  background: ${({ background }) => (background ? background : '#BDBDBD')};
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 5.5px;
`;

const TreatmentCard = (props) => {
  let treatmentId = props.id;

  const doctor = props.doctor;
  const message_doctor =
    props.messages.filter(
      (message) => message.from?.id === doctor?.user?.id && !message.readed,
    ).length > 0;

  return (
    <Card
      style={{
        ...props.style,
        opacity: props.hasRefinement ? '0.6' : '1',
      }}
      {...props}
      minWidth={'285px'}
    >
      <InlineContainer
        style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <img
          src={
            props.photo_filename
              ? `${process.env.REACT_APP_API_URL}/patients/${props.patient_id}/profile/${props.photo_filename}`
              : loadPhotoProfileGender(props.patient_gender)
          }
          style={{
            height: 36,
            width: 36,
            borderRadius: 50,
            objectFit: 'cover',
          }}
          alt="paciente"
        />
        <Column gap="10px" style={{ alignItems: 'flex-end' }}>
          <InlineContainer>
            <LabelNormal fontSize="11" color="#757575">
              ID
            </LabelNormal>
            <LabelNormal fontSize="12" color="#C8C8C8">
              {props.id}
            </LabelNormal>
            <TypeTreatment
              background={
                props.treatment === 'cryst_aligner' ? '#18A1DC' : '#F0BB00'
              }
            >
              <LabelNormal fontSize="7.5" color="#F6F6F8">
                {props.treatment === 'cryst_aligner' ? 'CA' : 'SS'}
              </LabelNormal>
            </TypeTreatment>
          </InlineContainer>
          {(props.hasRefinement || props.is_refinement) && (
            <LabelNormal
              fontSize="12"
              style={{
                color: '#757575',
                borderRadius: 4,
                backgroundColor: '#F4F4F4',
                padding: '3px 5px',
                margin: '0',
              }}
            >
              {props.is_refinement ? 'Refinamiento' : 'Tratamiento original'}
            </LabelNormal>
          )}
        </Column>
      </InlineContainer>
      <InlineContainer style={{ alignItems: 'center', marginTop: 14 }}>
        <LabelNormal fontSize="11" color="#436FF1">
          PX
        </LabelNormal>
        <LabelNormal
          fontSize="15"
          color="#000000"
        >{`${props.patient_first_name} ${props.patient_last_name}`}</LabelNormal>
      </InlineContainer>
      <InlineContainer style={{ paddingLeft: 1.5 }}>
        <CaseStatusIcon
          background={
            props.color_type === 'success'
              ? '#169D00'
              : props.color_type === 'error'
                ? 'red'
                : '#BDBDBD'
          }
        />
        <LabelNormal
          color={
            props.color_type === 'success'
              ? '#169D00'
              : props.color_type === 'error'
                ? 'red'
                : '#BDBDBD'
          }
          fontSize="15.5"
        >
          {props.status_name}
        </LabelNormal>
      </InlineContainer>

      {props.type === 'planning' &&
        props.index === 2 &&
        message_doctor === false && (
          <Link
            to={{
              pathname: `/app/tablero/revision-planificacion/${props.patient_id}`,
              state: { ...props, visorReview: true },
            }}
          >
            <ButtonPrime size="sm" label="Ir a Revisión de visor" />
          </Link>
        )}

      {message_doctor ? (
        <>
          <InlineContainer>
            <FeatherIcon
              icon="message-circle"
              color="#EB9542"
              size={15}
              style={{ marginTop: 3 }}
            />
            <LabelNormal
              style={{ marginLeft: 4 }}
              color="#3C4549"
              fontSize="15"
            >
              Mensaje nuevo de Doctor
            </LabelNormal>
          </InlineContainer>
          <Link
            to={{
              pathname: `/app/tablero/soporte/${props.patient_id}/${treatmentId}`,
            }}
          >
            <ButtonPrime size="sm" label="Contestar mensajes" />
          </Link>
        </>
      ) : (
        <>
          {props.type === 'new_case' && props.index === 3 && (
            <Link
              to={{
                pathname: `/app/tablero/caso/revision/${props.patient_id}`,
              }}
              style={{ width: 'fit-content', display: 'block' }}
            >
              <ButtonPrime size="sm" label="Revisar archivos" />
            </Link>
          )}
        </>
      )}
    </Card>
  );
};

export default TreatmentCard;
