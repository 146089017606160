import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from '../../shared/Avatar';

const Linke = styled(Link)`
  text-decoration: none;
`;

const ItemDefault = styled.div`
  cursor: pointer;
`;

const ItemContain = styled.div`
  border-radius: 11px;
  background-color: ${({ $background }) =>
    $background ? $background : 'transparent'};
  padding: 12px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 170px;
  transition: all 0.3s ease-out;
  &:hover {
    p,
    svg {
      filter: ${({ $background, color }) =>
        $background
          ? ''
          : color === '#3792E6'
          ? 'invert(62%) sepia(75%) saturate(4802%) hue-rotate(6deg) brightness(198%) contrast(82%)'
          : 'invert(62%) sepia(75%) saturate(4802%) hue-rotate(6deg) brightness(108%) contrast(82%)'};
    }
    img {
      outline: ${({ $background }) => ($background ? '' : '2px solid #DF9853')};
    }
  }
`;

const TextItem = styled.p`
  font-size: 16px;
  padding: 0;
  line-height: 21.6px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ color }) => (color ? color : '#4E5A5F')};
  font-family: ${({ bold }) =>
    bold ? 'neue-montreal-bold' : 'neue-montreal-medium'};
`;

const Item = (props) => {
  const itemContain = () => {
    return (
      <ItemContain {...props}>
        {props.icon}
        {props.img && (
          <Avatar
            src={props.img}
            alt="icon"
            height="40"
            width="40"
            borderRadius="19"
          />
        )}
        <TextItem style={{ marginLeft: 11 }} color={props.color}>
          {props.label}
        </TextItem>
      </ItemContain>
    );
  };
  return props.to ? (
    <Linke {...props}>{itemContain()}</Linke>
  ) : (
    <ItemDefault>{itemContain()}</ItemDefault>
  );
};

export default Item;
