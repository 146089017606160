import {
  AttachPatient,
  ButtonSecondary,
  InformationContextAlert,
  NewCasePurchaseOptions,
} from '@acueductostudio/borgatta-components';

/**
 * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
 * @typedef {{
 *  isErrorValidation?: boolean;
 *  isRemovable: boolean;
 *  onChangeSelectedPatient: (patient: AttachPatientItem | null) => void;
 *  options: Array<AttachPatientItem>;
 *  selected: AttachPatientItem | null;
 *  setIsErrorValidation: (isErrorValidation: boolean) => void;
 *  state: "Error" | "Initial" | "Loading" | "Success";
 * }} AttachPatientType
 * @typedef {{
 * aligners?: number;
 *  bundleType: BundleType;
 *  expirationDate: Date;
 *  refinements?: {
 *    available: number;
 *    total: number;
 *  };
 * }} InformationContextAlert
 * @typedef {{
 *  description: string;
 *  descriptionMedium: string;
 *  priceLabel: string;
 *  title: string;
 *  type: "Refinement" | "Unitary" | "UpgradeIntegral" | "UpgradeModerado";
 * }} PurchaseOption
 * @typedef {{
 *  informativeContextAlert: InformationContextAlert | null;
 *  onClickExtensionNextStep: () => void;
 *  attachPatient: AttachPatientType;
 *  purchaseOptions: Array<PurchaseOption>;
 * }} NewCaseRegisterExtensionProps
 * @param {NewCaseRegisterExtensionProps} props
 */
export const RegisterExtension = ({
  informativeContextAlert,
  onClickExtensionNextStep,
  attachPatient,
  purchaseOptions,
}) => {
  const isPatientSelected = attachPatient.selected !== null;

  return (
    <>
      <div>
        <AttachPatient {...attachPatient} />
      </div>
      {isPatientSelected && (
        <>
          <div className="flex w-6-column">
            {informativeContextAlert && (
              <InformationContextAlert {...informativeContextAlert} />
            )}
          </div>
          <div className="flex w-12-column">
            <NewCasePurchaseOptions options={purchaseOptions} />
          </div>
        </>
      )}
      <div className="flex w-6-column">
        <ButtonSecondary onClick={onClickExtensionNextStep}>
          Siguiente
        </ButtonSecondary>
      </div>
    </>
  );
};
