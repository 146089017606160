import { createContext } from 'react';

/**
 * @typedef {'Initial' | 'Default' | 'Success' | 'Error' | 'Loading'} AsyncState
 * @typedef {'NewCase' | 'Extension'} RegisterType
 * @typedef {'Cryst Aligner' | 'Smile System'} TreatmentType
 * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
 * @typedef {{
 *  context: {
 *    bundleType: string;
 *    expirationDate: Date;
 *    expired: boolean;
 *    purchaseOptions: Array<{
 *      id: number;
 *      title: string;
 *      description: string;
 *      descriptionMedium: string;
 *      priceLabel: string;
 *      type: string;
 *      bundleType: string;
 *    }>;
 *    freePlanningsAvailable: number | null;
 *    freePlanningsTotal: number | null;
 *    remainingAligners: number | null;
 *  };
 *  doctorId: number;
 *  firstName: string;
 *  gender: string;
 *  lastName: string;
 *  patientId: number;
 *  photoUrl: string;
 *  parentTreatmentId: number;
 * }} ExtendiblePatient
 * @typedef {{
 *  extendiblePatientSelected: ExtendiblePatient | null;
 *  extendiblePatients: Array<ExtendiblePatient>;
 *  extendiblePatientsState: AsyncState;
 *  registerType: RegisterType;
 *  selectExtendiblePatient: (attachPatient: AttachPatientItem) => void;
 *  selectRegisterType: (type: RegisterType) => void;
 *  selectTreatmentType: (type: TreatmentType) => void;
 *  setExtendiblePatientsState: (state: AsyncState) => void;
 *  treatmentType: TreatmentType;
 * }} NewCaseContextType
 * @type {NewCaseContextType}
 */
const defaultContext = {
  extendiblePatients: [],
  extendiblePatientSelected: null,
  extendiblePatientsState: 'Initial',
  registerType: 'NewCase',
  selectExtendiblePatient: () => undefined,
  selectRegisterType: () => undefined,
  selectTreatmentType: () => undefined,
  setExtendiblePatientsState: () => undefined,
  treatmentType: 'Cryst Aligner',
};

export const NewCaseContext = createContext(defaultContext);
