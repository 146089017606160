/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardBasic from '@components/shared/CardBasic';
import { QUERY_GET_TREATMENT_BY_DEPARTAMENT } from '@graphql/query';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import TreatmentCardContainer from '@components/kanban/treatment-card-container';

const Kanban = ({
  treatment,
  categories,
  countries,
  search,
  searching,
  setSearching,
}) => {
  const [departments, setDepartments] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [allTreatmentsWithRefinement, setAllTreatmentsWithRefinement] =
    useState([]);

  const { loading, refetch } = useQuery(QUERY_GET_TREATMENT_BY_DEPARTAMENT, {
    variables: {
      treatment: treatment,
      categories: categories,
      countries: countries,
      search: search,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getTreatmentsByDepartments) {
        data.getTreatmentsByDepartments.forEach((apt) => {
          apt.treatments.forEach((t) => {
            if (t.is_refinement) {
              //   allTreatmentsWithRefinement.push(t.treatment_id);
              setAllTreatmentsWithRefinement((allTreatmentsWithRefinement) => [
                ...allTreatmentsWithRefinement,
                t.treatment_id,
              ]);
            }
          });
        });
        setDepartments(
          data.getTreatmentsByDepartments.map((department) => ({
            ...department,
            treatments: department.treatments.map((treatment) => ({
              ...treatment,
              treatmentType: {
                ...treatment.treatmentType,
                id: parseInt(treatment.treatmentType.id),
              },
              treatmentStatus: {
                ...treatment.treatmentStatus,
                id: parseInt(treatment.treatmentStatus.id),
              },
            })),
          }))
        );
      }
      setLoadingSkeleton(false);
    },
    onError: () => {},
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    if (firstLoad) {
      refetch({
        treatment,
        categories: categories,
        countries: countries,
        search: search,
      });
      setSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, countries]);

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  return (
    <>
      {!loadingSkeleton ? (
        <>
          {(!loading || departments.length > 0) && (
            <InlineContainer
              style={{
                overflowX: 'scroll',
                alignItems: 'flex-start',
                width: '81vw',
                height: '70vh',
                gap: 15,
              }}
            >
              {departments.map((item) => {
                return (
                  <CardBasic
                    style={{
                      background: 'rgba(51, 60, 63, 0.04)',
                      borderRadius: 10,
                      boxShadow: 'none',
                      cursor: 'default',
                      paddingTop: 0,
                    }}
                    key={`${treatment}-${item.id}`}
                  >
                    <div
                      style={{
                        position: 'sticky',
                        top: '0px',
                        zIndex: 10,
                        background: 'rgb(235 235 236)',
                        padding: '17px',
                        marginRight: '-17px',
                        marginLeft: '-17px',
                        borderRadius: 10,
                      }}
                    >
                      <LabelNormal
                        fontSize="17.5"
                        color="#3C4549"
                        style={{ margin: 0, fontWeight: '500' }}
                      >
                        {item.name}
                      </LabelNormal>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                        marginTop: '5px',
                      }}
                    >
                      {item.treatments.map((row) => {
                        return (
                          <TreatmentCardContainer
                            treatment={row}
                            viewer="admin"
                            key={row.id}
                          />
                        );
                      })}
                    </div>
                  </CardBasic>
                );
              })}
            </InlineContainer>
          )}
        </>
      ) : (
        <>
          <div style={{ display: 'flex', marginTop: 27 }}>
            {Array.from(Array(4).keys()).map((_, index) => (
              <div
                style={{ padding: '0px 15px 0px 0px' }}
                key={`item-${index}`}
              >
                <Skeleton
                  height={69}
                  borderRadius={10}
                  width={319}
                  baseColor={'#F6F6F8'}
                  highlightColor={'#FFF'}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export default Kanban;
