import React from 'react';
import styled from 'styled-components';

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: ${({ margin }) => (margin ? margin : '5px')};
  transition: all 0.3s ease-out;
  &:hover {
    color: #436ff1;
  }
  &:active {
    color: #1249ed;
  }
`;

const LabelUnderline = (props) => (
  <LabelNormal
    color={props.color}
    fontSize={props.fontSize}
    margin={props.margin}
    onClick={() => props.onClick()}
    style={{
      textDecorationLine: 'underline',
      cursor: 'pointer',
      marginTop: props.marginTop ? props.marginTop : null,
      marginBottom: props.marginBottom ? props.marginBottom : null,
      marginRight: props.marginRight ? props.marginRight : null,
      marginLeft: props.marginLeft ? props.marginLeft : null,
      maxWidth: props.maxWidth ? props.maxWidth : null,
      textAlign: props.textAlign ? props.textAlign : null,
    }}
  >
    {props.label}
  </LabelNormal>
);

export default LabelUnderline;
