import styled from 'styled-components';

import { modal } from '@components/ModalManagement';
import ModalWarning from '@components/Modal/ModalWarning';
import ButtonPrime from '@components/shared/ButtonPrime';

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

/**
 *
 * @param {{
 * onClickUnderstood: () => void;
 * }} props Props required by the useAlertDeploy hook.
 * @returns
 */
export const ModalUnichatUpdateAlert = ({ onClickUnderstood }) => {
  return (
    <ModalWarning onClick={() => modal.close()} withoutIcon>
      <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
        ¡Nos actualizamos para ti!
      </LabelNormal>
      <LabelNormal
        fontSize="17"
        color="#4F4F4F"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        Lanzaremos una actualización que hará que nuestra comunicación sea más
        cercana. Podrás contactarnos desde el portal en cualquier momento y
        acceder a todos los mensajes de tu caso en un mismo lugar.
      </LabelNormal>
      <LabelNormal
        fontSize="17"
        color="#4F4F4F"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        Esta nueva versión se lanzará el
        <b> jueves 9 de noviembre a las 5pm CST</b>, por lo que los mensajes que
        tienes actualmente no estarán disponibles después de esta fecha.
      </LabelNormal>
      <LabelNormal
        fontSize="17"
        color="#4F4F4F"
        style={{ marginBottom: 15, paddingBottom: 12 }}
      >
        Pero no te preocupes,
        <b> guardaremos un respaldo de todas nuestras conversaciones</b>.
      </LabelNormal>
      <ButtonPrime label="Enterado" onClick={onClickUnderstood} />
    </ModalWarning>
  );
};
