import React, { useEffect, useState } from 'react';
import Input from '@components/shared/Input';
import Checkbox from 'react-custom-checkbox';
import ButtonPrime from '@components/shared/ButtonPrime';
import Select from '@components/shared/Select';
import {
  MUTATION_FORM_ARCADA,
  MUTATION_FORM_SHAPE,
  MUTATION_FORM_IPR,
  MUTATION_FORM_ODONTOGRAMA,
} from '@graphql/mutation';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import ReactTooltip from 'react-tooltip';
import Map from './map';
import {
  tops,
  bottoms,
  odontogramIcons,
  previousZoneImages,
  archShapeImages,
  icons,
} from './icons';

import { itemsTabs, odontograma } from './constants';
import {
  TextArea,
  InlineContainer,
  LabelNormal,
  Line,
  ScrollableBody,
  Title,
} from './DoctorFormCA.style';
import ObservationsCAForm from '../ObservationsCAForm/ObservationsCAForm';

const FormCA = (props) => {
  const [arcad, setArcad] = useState('Ambas arcadas');
  const [treatmentType, setTreatmentType] = useState('Inicial');
  const [alignerPack, setAlignerPack] = useState(
    'Ajustar en alineadores pares',
  );
  const [spee, setSpee] = useState('Mantener');
  const [errors, setErrors] = useState({});

  const [lineMiddle, setLineMiddle] = useState('Mantener');
  const [rightLineMiddle, setRightLineMiddle] = useState(false);
  const [rightLineMiddleMilimetros, setRightLineMiddleMilimetros] =
    useState('');
  const [leftLineMiddle, setLeftLineMiddle] = useState(false);
  const [leftLineMiddleMilimetros, setLeftLineMiddleMilimetros] = useState('');

  const [overbite, setOverbite] = useState('Mantener');
  const [dataOverbite, setDataOverbite] = useState('');

  const [overjet, setOverjet] = useState('Mantener');
  const [dataOverjet, setDataOverjet] = useState('');

  const [spaces, setSpaces] = useState('Dejar espacios');

  const [arcadWidth, setArcarWidth] = useState('Indicar por arcada');
  const [crossBitePosterior, setCrossBitePosterior] = useState('Ajustar');
  const [crossBiteSub, setCrossBiteSub] = useState('Diente');

  const [arcarWidthExpShr, setArcarWidthExpShr] = useState('Expandir');
  const [crossBiteExpShr, setCrossBiteExpShr] = useState('Expandir');

  const {
    DienteAusente,
    DienteExtraer,
    PiesaExcluir,
    Implante,
    PiezaErupcion,
    Inscrustacion,
    ProtesisFija,
    DienteIncluidoRetenido,
    DienteConEndodoncia,
    ErosionDental,
    FracturaCorona,
    Restauracion,
  } = odontogramIcons;

  useEffect(() => {
    const tab = localStorage.getItem('tab-form');
    if (tab) {
      props.setSelectTab(+tab);
    }
    return () => {
      localStorage.removeItem('tab-form');
    };
  }, []);

  useEffect(() => {
    if (arcarWidthExpShr === 'Expandir') {
      setArcadWidthContractMilimetro('');
      setArcadWidthExpandMilimetro('');
    } else {
      setArcadWidthContractMilimetro('');
      setArcadWidthExpandMilimetro('');
    }
  }, [arcarWidthExpShr]);

  useEffect(() => {
    if (crossBiteExpShr === 'Expandir') {
      setCrossBitePosteriorContractMilimetro('');
      setCrossBitePosteriorExpandMilimetro('');
    } else {
      setCrossBitePosteriorContractMilimetro('');
      setCrossBitePosteriorExpandMilimetro('');
    }
  }, [crossBiteExpShr]);

  const [arcadWidthExpandMilimetro, setArcadWidthExpandMilimetro] =
    useState('');
  const [arcadWidthContractMilimetro, setArcadWidthContractMilimetro] =
    useState('');
  const [
    crossBitePosteriorExpandMilimetro,
    setCrossBitePosteriorExpandMilimetro,
  ] = useState('');
  const [
    crossBitePosteriorContractMilimetro,
    setCrossBitePosteriorContractMilimetro,
  ] = useState('');

  const [proclinarCheck1, setProclinarCheck1] = useState(false);
  const [proclinarMilimetros1, setProclinarMilimetros1] = useState('');
  const [expandirCheck1, setExpandirCheck1] = useState(false);
  const [expandirMilimetros1, setExpandirMilimetros1] = useState('');

  const [proclinarCheck2, setProclinarCheck2] = useState(false);
  const [proclinarMilimetros2, setProclinarMilimetros2] = useState('');
  const [expandirCheck2, setExpandirCheck2] = useState(false);
  const [expandirMilimetros2, setExpandirMilimetros2] = useState('');

  const [arch_teeth_data, setArchTeethData] = useState(['Contraer,,']);
  const [crossbite_teeth_data, setCrossbiteTeethData] = useState([
    'Contraer,,',
  ]);
  const [crossbite_teeth_data_uncross, setCrossbiteTeethDataUncross] = useState(
    [','],
  );

  const [up_spaces_data, setUpSpacesData] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);
  const [lo_spaces_data, setLoSpacesData] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);

  const [odontogramComments, setOdontogramComments] = useState('');
  const [needComments, setNeedComments] = useState(false);
  const [validateComments, setValidateComments] = useState(true);

  const [formArcad] = useMutation(MUTATION_FORM_ARCADA, {
    onCompleted: (data) => {
      if (data.saveFormArcade) {
        props.setSelectTab(2);
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitArcad = () => {
    if (validationArcad())
      formArcad({
        variables: {
          treatment_id: props.treatment_id,
          type: arcad,
          middle_line: lineMiddle,
          has_middle_line_left: leftLineMiddle,
          middle_line_left: leftLineMiddleMilimetros
            ? parseFloat(leftLineMiddleMilimetros)
            : 0,
          has_middle_line_right: rightLineMiddle,
          middle_line_right: rightLineMiddleMilimetros
            ? parseFloat(rightLineMiddleMilimetros)
            : 0,
          overbite,
          overbite_data: parseFloat(dataOverbite),
          overjet,
          overjet_data: parseFloat(dataOverjet),
          spaces,
          up_spaces_data,
          lo_spaces_data,
          arch_width: arcadWidth,
          arch_exp: arcarWidthExpShr === 'Expandir' ? true : false,
          arch_exp_data: parseFloat(arcadWidthExpandMilimetro),
          arch_shr: arcarWidthExpShr === 'Contraer' ? true : false,
          arch_shr_data: parseFloat(arcadWidthContractMilimetro),
          arch_teeth_data,
          cross_bite: crossBitePosterior,
          cross_bite_subselect: crossBiteSub,
          crossbite_exp: crossBiteExpShr === 'Expandir' ? true : false,
          crossbite_exp_data: parseFloat(crossBitePosteriorExpandMilimetro),
          crossbite_shr: crossBiteExpShr === 'Contraer' ? true : false,
          crossbite_shr_data: parseFloat(crossBitePosteriorContractMilimetro),
          crossbite_teeth_data:
            crossBitePosterior === 'Ajustar'
              ? crossbite_teeth_data
              : crossbite_teeth_data_uncross,
          procline_adjust_maxilla: proclinarCheck1,
          procline_adjust_maxilla_data: proclinarMilimetros1
            ? parseFloat(proclinarMilimetros1)
            : 0,
          expand_adjust_maxilla: expandirCheck1,
          expand_adjust_maxilla_data: expandirMilimetros1
            ? parseFloat(expandirMilimetros1)
            : 0,
          procline_adjust_jaw: proclinarCheck2,
          procline_adjust_jaw_data: proclinarMilimetros2
            ? parseFloat(proclinarMilimetros2)
            : 0,
          expand_adjust_jaw: expandirCheck2,
          expand_adjust_jaw_data: expandirMilimetros2
            ? parseFloat(expandirMilimetros2)
            : 0,
          treatment_type: treatmentType,
          aligner_pack: alignerPack,
          spee: spee,
        },
      });
  };

  const validationArcad = () => {
    if (
      arcad !== '' &&
      (lineMiddle === 'Mantener' ||
        lineMiddle === 'Centrar' ||
        (lineMiddle === 'Ajustar' &&
          ((leftLineMiddle && leftLineMiddleMilimetros !== '') ||
            (rightLineMiddle && rightLineMiddleMilimetros !== '')))) &&
      (overbite === 'Mantener' ||
        (overbite === 'Ajustar' && dataOverbite !== '')) &&
      (overjet === 'Mantener' ||
        (overjet === 'Ajustar' && dataOverjet !== '')) &&
      (spaces === 'Cerrar todos los espacios' ||
        (spaces === 'Dejar espacios' &&
          (up_spaces_data.find((v) => v !== '0,0') ||
            lo_spaces_data.find((v) => v !== '0,0')))) &&
      (arcadWidth === 'Mantener ancho de arcada actual' ||
        (arcadWidth === 'Indicar por arcada' &&
          (arcadWidthExpandMilimetro !== '' ||
            arcadWidthContractMilimetro !== '')) ||
        (arcadWidth === 'Indicar por diente' &&
          arch_teeth_data.filter(
            (v) =>
              v !== '' &&
              v.split(',').length === 3 &&
              v.split(',')[0] !== '' &&
              v.split(',')[1] !== '' &&
              v.split(',')[2] !== '',
          ).length === arch_teeth_data.length)) &&
      (crossBitePosterior === 'Mantener' ||
        (crossBitePosterior !== 'Mantener' &&
          crossBiteSub === 'Arcada' &&
          (crossBitePosteriorExpandMilimetro !== '' ||
            crossBitePosteriorContractMilimetro !== '')) ||
        (crossBiteSub === 'Diente' &&
          crossBitePosterior === 'Ajustar' &&
          crossbite_teeth_data.filter(
            (v) =>
              v !== '' &&
              v.split(',').length === 3 &&
              v.split(',')[0] !== '' &&
              v.split(',')[1] !== '' &&
              v.split(',')[2] !== '',
          ).length === crossbite_teeth_data.length) ||
        (crossBitePosterior === 'Descruzar' &&
          crossbite_teeth_data_uncross.filter(
            (v) =>
              v !== '' &&
              v.split(',').length === 2 &&
              v.split(',')[0] !== '' &&
              v.split(',')[1] !== '',
          ).length === crossbite_teeth_data_uncross.length)) &&
      (!proclinarCheck1 || (proclinarCheck1 && proclinarMilimetros1)) &&
      (!expandirCheck1 || (expandirCheck1 && expandirMilimetros1)) &&
      (!proclinarCheck2 || (proclinarCheck2 && proclinarMilimetros2)) &&
      (!expandirCheck2 || (expandirCheck2 && expandirMilimetros2))
    ) {
      setErrors({});
      return true;
    } else {
      setErrors({ name: 'arcad' });
      return false;
    }
  };

  useEffect(() => {
    if (lineMiddle === 'Mantener') {
      setRightLineMiddle(false);
      setRightLineMiddleMilimetros('');
      setLeftLineMiddle(false);
      setLeftLineMiddleMilimetros('');
    }
    if (overbite === 'Mantener') {
      setDataOverbite('');
    }
    if (overjet === 'Mantener') {
      setDataOverjet('');
    }
  }, [overjet, overbite, lineMiddle]);

  const [arc_shape, setArcShape] = useState('Redonda');
  const [desired_class, setDesiredClass] = useState('Priorizar');
  const [molar_class, setMolarClass] = useState(false);
  const [molar_right, setMolarRight] = useState('');
  const [molar_left, setMolarLeft] = useState('');
  const [canine_relationship, setCanineRelationship] = useState(false);
  const [canine_left, setCanineLeft] = useState('');
  const [canine_right, setCanineRight] = useState('');
  const [anterior_area, setAnteriorArea] = useState('Planos incisales rectos');

  const [formShape] = useMutation(MUTATION_FORM_SHAPE, {
    onCompleted: (data) => {
      if (data.saveFormShape) {
        props.setSelectTab(3);
      }
    },
  });

  const onSubmitShape = () => {
    if (validationShape()) {
      formShape({
        variables: {
          treatment_id: props.treatment_id,
          arc_shape,
          desired_class,
          molar_class,
          molar_left_type: molar_left,
          molar_right_type: molar_right,
          canine_relationship,
          canine_left,
          canine_right,
          anterior_area,
        },
      });
    }
  };

  const validationShape = () => {
    if (
      arc_shape !== '' &&
      (desired_class === 'Sin cambios' ||
        desired_class === 'Según sea necesario' ||
        (desired_class === 'Priorizar' &&
          ((molar_class && molar_left !== '' && molar_right !== '') ||
            (canine_relationship &&
              canine_left !== '' &&
              canine_right !== '')))) &&
      anterior_area !== ''
    ) {
      setErrors({});
      return true;
    } else {
      console.log('error?');
      setErrors({ name: 'shape' });
      return false;
    }
  };

  const [up_ipr_data, setUpIprData] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);
  const [lo_ipr_data, setLoIprData] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);
  const [instructions, setInstructions] = useState('Personalizar IPR');
  const [prev_torque, setPrevTorque] = useState(false);
  const [prev_torque_data, setPrevTorqueData] = useState('');
  const [rear_torque, setRearTorque] = useState(false);
  const [rear_torque_data, setRearTorqueData] = useState('');
  const [torque, setTorque] = useState(
    'Indicar torque por anteriores y posteriores',
  );
  const [teeth_torque_data, setTeethTorqueData] = useState(['']);
  const [commentIpr, setCommentIpr] = useState('');
  const [personalizeIpr, setPersonalizeIpr] = useState('Marcar dientes');

  const [formIpr] = useMutation(MUTATION_FORM_IPR, {
    onCompleted: (data) => {
      if (data.saveFormIpr) {
        props.setSelectTab(4);
      }
    },
  });

  const onSubmitIpr = () => {
    if (validationIpr()) {
      formIpr({
        variables: {
          treatment_id: props.treatment_id,
          instructions,
          up_ipr_data,
          lo_ipr_data,
          torque,
          back_teeth: prev_torque,
          back_teeth_to: +prev_torque_data,
          front_teeth: rear_torque,
          front_teeth_to: +rear_torque_data,
          teeth_torque_data,
          additional_indications: commentIpr,
          kind: personalizeIpr,
        },
      });
    }
  };

  const validationIpr = () => {
    if (
      instructions !== '' &&
      ((instructions === 'Personalizar IPR' &&
        (up_ipr_data.find((v) => v !== '0,0') ||
          lo_ipr_data.find((v) => v !== '0,0'))) ||
        instructions === 'No requiere' ||
        instructions === 'Según sea necesario') &&
      (torque === 'No requiere' ||
        (torque === 'Indicar torque por anteriores y posteriores' &&
          ((prev_torque && prev_torque_data !== '') ||
            (rear_torque && rear_torque_data !== ''))) ||
        (torque === 'Indicar torque por dientes' &&
          teeth_torque_data.filter((v) => v === '').length === 0))
    ) {
      setErrors({});
      return true;
    } else {
      setErrors({ name: 'ipr' });
      return false;
    }
  };

  const [selectOdontograma, setSelectOdontograma] = useState('diente_ausente');
  const [arcadUp, setArcadUp] = useState([
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
  ]);
  const [arcadLo, setArcadLo] = useState([
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
    [''],
  ]);
  const [typeOdontograma, setTypeOdontograma] = useState(
    'Marcar consideraciones especiales',
  );

  const [formOdontograma] = useMutation(MUTATION_FORM_ODONTOGRAMA, {
    onCompleted: (data) => {
      if (data.saveFormOdontogram) {
        props.setSelectTab(5);
      }
    },
  });

  const onSubmitFormOdontograma = () => {
    if (validationOdontograma()) {
      formOdontograma({
        variables: {
          treatment_id: props.treatment_id,
          type: typeOdontograma,
          up_left_third_molar_data: arcadUp[0],
          up_left_second_molar_data: arcadUp[1],
          up_left_first_molar_data: arcadUp[2],
          up_left_second_premolar_data: arcadUp[3],
          up_left_first_premolar_data: arcadUp[4],
          up_left_canine_data: arcadUp[5],
          up_left_lateral_incisor_data: arcadUp[6],
          up_left_central_incisor_data: arcadUp[7],
          up_right_third_molar_data: arcadUp[8],
          up_right_second_molar_data: arcadUp[9],
          up_right_first_molar_data: arcadUp[10],
          up_right_second_premolar_data: arcadUp[11],
          up_right_first_premolar_data: arcadUp[12],
          up_right_canine_data: arcadUp[13],
          up_right_lateral_incisor_data: arcadUp[14],
          up_right_central_incisor_data: arcadUp[15],

          lo_left_third_molar_data: arcadLo[0],
          lo_left_second_molar_data: arcadLo[1],
          lo_left_first_molar_data: arcadLo[2],
          lo_left_second_premolar_data: arcadLo[3],
          lo_left_first_premolar_data: arcadLo[4],
          lo_left_canine_data: arcadLo[5],
          lo_left_lateral_incisor_data: arcadLo[6],
          lo_left_central_incisor_data: arcadLo[7],
          lo_right_third_molar_data: arcadLo[8],
          lo_right_second_molar_data: arcadLo[9],
          lo_right_first_molar_data: arcadLo[10],
          lo_right_second_premolar_data: arcadLo[11],
          lo_right_first_premolar_data: arcadLo[12],
          lo_right_canine_data: arcadLo[13],
          lo_right_lateral_incisor_data: arcadLo[14],
          lo_right_central_incisor_data: arcadLo[15],
          comments: odontogramComments,
        },
      });
    }
  };

  const validationOdontograma = () => {
    if (
      (typeOdontograma !== '' &&
        ((odontogramComments === '' && !needComments) ||
          (needComments && odontogramComments !== '')) &&
        (arcadUp.filter((v) => v[0] === '').length !== arcadUp.length ||
          arcadLo.filter((v) => v[0] === '').length !== arcadLo.length)) ||
      typeOdontograma === 'No tengo consideraciones'
    ) {
      setErrors({});
      return true;
    } else {
      setErrors({ name: 'odontograma' });
      return false;
    }
  };

  const validateOnSubmitSS = () => {
    if (typeOdontograma !== 'No tengo consideraciones') {
      if (odontogramComments === '' && needComments) {
        setValidateComments(false);
      } else {
        setValidateComments(true);
        onSubmitFormOdontograma();
      }
    } else {
      onSubmitFormOdontograma();
    }
  };

  useEffect(() => {
    if (!molar_class) {
      setMolarLeft('');
      setMolarRight('');
    }
    if (!canine_relationship) {
      setCanineLeft('');
      setCanineRight('');
    }
  }, [molar_class, canine_relationship]);

  useEffect(() => {
    setArcadWidthExpandMilimetro('');
    setArcadWidthContractMilimetro('');
  }, [arcadWidth]);

  useEffect(() => {
    setCrossBitePosteriorExpandMilimetro('');
    setCrossBitePosteriorContractMilimetro('');
  }, [crossBitePosterior]);

  useEffect(() => {
    if (props.formCAData.arcad) {
      const ca = props.formCAData;
      setArcad(ca.arcad?.type);
      setTreatmentType(ca.arcad?.treatment_type);
      setAlignerPack(ca.arcad?.aligner_pack);
      setSpee(ca.arcad?.spee);
      setLineMiddle(ca.arcad?.middle_line);
      setLeftLineMiddle(ca.arcad?.has_middle_line_left);
      setLeftLineMiddleMilimetros(ca.arcad?.middle_line_left);
      setRightLineMiddle(ca.arcad?.has_middle_line_right);
      setRightLineMiddleMilimetros(ca.arcad?.middle_line_right);
      setOverbite(ca.arcad?.overbite);
      setDataOverbite(ca.arcad?.overbite_data);
      setOverjet(ca.arcad?.overjet);
      setDataOverjet(ca.arcad?.overjet_data);
      setSpaces(ca.arcad?.spaces);
      setUpSpacesData(
        ca.arcad?.up_spaces_data
          ? JSON.parse(ca.arcad?.up_spaces_data)
          : [
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
            ],
      );
      setLoSpacesData(
        ca.arcad?.lo_spaces_data
          ? JSON.parse(ca.arcad?.lo_spaces_data)
          : [
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
              '0,0',
            ],
      );
      setArcarWidth(ca.arcad?.arch_width);
      setArcarWidthExpShr(
        ca.arcad?.arch_shr === true ? 'Contraer' : 'Expandir',
      );
      setArcadWidthContractMilimetro(ca.arcad?.arch_shr_data);
      setArcadWidthExpandMilimetro(ca.arcad?.arch_exp_data);
      setArchTeethData(
        ca.arcad?.arch_teeth_data
          ? JSON.parse(ca.arcad?.arch_teeth_data)
          : ['Contraer,,'],
      );
      setCrossBitePosterior(ca.arcad?.cross_bite);
      setCrossBiteSub(ca.arcad?.cross_bite_subselect);
      setCrossBiteExpShr(
        ca.arcad?.crossbite_exp === true ? 'Expandir' : 'Contraer',
      );
      setCrossBitePosteriorExpandMilimetro(ca.arcad?.crossbite_exp_data);
      setCrossBitePosteriorContractMilimetro(ca.arcad?.crossbite_shr_data);
      if (ca.arcad?.cross_bite === 'Descruzar') {
        setCrossbiteTeethData(['Contraer,,']);
        setCrossbiteTeethDataUncross(
          ca.arcad?.crossbite_teeth_data
            ? JSON.parse(ca.arcad?.crossbite_teeth_data)
            : [''],
        );
      } else if (ca.arcad?.cross_bite === 'Ajustar') {
        setCrossbiteTeethData(
          ca.arcad?.crossbite_teeth_data
            ? JSON.parse(ca.arcad?.crossbite_teeth_data)
            : [''],
        );
        setCrossbiteTeethDataUncross([',']);
      }
      setProclinarCheck1(ca.arcad?.procline_adjust_maxilla);
      setProclinarMilimetros1(ca.arcad?.procline_adjust_maxilla_data);
      setProclinarCheck2(ca.arcad?.procline_adjust_jaw);
      setProclinarMilimetros2(ca.arcad?.procline_adjust_jaw_data);
      setExpandirCheck1(ca.arcad?.expand_adjust_maxilla);
      setExpandirMilimetros1(ca.arcad?.expand_adjust_maxilla_data);
      setExpandirCheck2(ca.arcad?.expand_adjust_jaw);
      setExpandirMilimetros2(ca.arcad?.expand_adjust_jaw_data);

      if (ca.shape?.arc_shape) {
        setArcShape(ca.shape.arc_shape);
        setDesiredClass(ca.shape.desired_class);
        setMolarClass(ca.shape.molar_class);
        setMolarLeft(ca.shape.molar_left_type);
        setMolarRight(ca.shape.molar_right_type);
        setCanineRelationship(ca.shape.canine_relationship);
        setCanineLeft(ca.shape.canine_left);
        setCanineRight(ca.shape.canine_right);
        setAnteriorArea(ca.shape.anterior_area);
      }
      if (ca.ipr?.instructions) {
        setInstructions(ca.ipr.instructions);
        setUpIprData(
          ca.ipr.up_ipr_data
            ? JSON.parse(ca.ipr.up_ipr_data)
            : [
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
              ],
        );
        setLoIprData(
          ca.ipr.lo_ipr_data
            ? JSON.parse(ca.ipr.lo_ipr_data)
            : [
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
                '0,0',
              ],
        );
        setTorque(ca.ipr.torque);
        setTeethTorqueData(
          ca.ipr.teeth_torque_data
            ? JSON.parse(ca.ipr.teeth_torque_data)
            : [''],
        );
        setPrevTorque(ca.ipr.back_teeth);
        setPrevTorqueData(ca.ipr.back_teeth_to);
        setRearTorque(ca.ipr.front_teeth);
        setRearTorqueData(ca.ipr.front_teeth_to);
        setCommentIpr(ca.ipr.additional_indications);
        setPersonalizeIpr(
          ca.ipr.kind === 'Indicar milimetros por diente'
            ? 'Indicar milímetros por diente'
            : 'Marcar dientes',
        );
      }
      if (ca.odontograma?.type) {
        setTypeOdontograma(ca.odontograma.type);
        setNeedComments(ca.odontograma?.comments ? true : false);
        setOdontogramComments(ca.odontograma?.comments);
      }
      if (ca.odontograma?.up_left_third_molar_data) {
        setArcadUp([
          JSON.parse(ca.odontograma.up_left_third_molar_data),
          JSON.parse(ca.odontograma.up_left_second_molar_data),
          JSON.parse(ca.odontograma.up_left_first_molar_data),
          JSON.parse(ca.odontograma.up_left_second_premolar_data),
          JSON.parse(ca.odontograma.up_left_first_premolar_data),
          JSON.parse(ca.odontograma.up_left_canine_data),
          JSON.parse(ca.odontograma.up_left_lateral_incisor_data),
          JSON.parse(ca.odontograma.up_left_central_incisor_data),
          JSON.parse(ca.odontograma.up_right_third_molar_data),
          JSON.parse(ca.odontograma.up_right_second_molar_data),
          JSON.parse(ca.odontograma.up_right_first_molar_data),
          JSON.parse(ca.odontograma.up_right_second_premolar_data),
          JSON.parse(ca.odontograma.up_right_first_premolar_data),
          JSON.parse(ca.odontograma.up_right_canine_data),
          JSON.parse(ca.odontograma.up_right_lateral_incisor_data),
          JSON.parse(ca.odontograma.up_right_central_incisor_data),
        ]);
        setArcadLo([
          JSON.parse(ca.odontograma.lo_left_third_molar_data),
          JSON.parse(ca.odontograma.lo_left_second_molar_data),
          JSON.parse(ca.odontograma.lo_left_first_molar_data),
          JSON.parse(ca.odontograma.lo_left_second_premolar_data),
          JSON.parse(ca.odontograma.lo_left_first_premolar_data),
          JSON.parse(ca.odontograma.lo_left_canine_data),
          JSON.parse(ca.odontograma.lo_left_lateral_incisor_data),
          JSON.parse(ca.odontograma.lo_left_central_incisor_data),
          JSON.parse(ca.odontograma.lo_right_third_molar_data),
          JSON.parse(ca.odontograma.lo_right_second_molar_data),
          JSON.parse(ca.odontograma.lo_right_first_molar_data),
          JSON.parse(ca.odontograma.lo_right_second_premolar_data),
          JSON.parse(ca.odontograma.lo_right_first_premolar_data),
          JSON.parse(ca.odontograma.lo_right_canine_data),
          JSON.parse(ca.odontograma.lo_right_lateral_incisor_data),
          JSON.parse(ca.odontograma.lo_right_central_incisor_data),
        ]);
      } else {
        setArcadUp([
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ]);
        setArcadLo([
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
          [''],
        ]);
      }
    }
  }, [props.formCAData]);

  const validationNumbers = (e) => {
    const { value } = e.target;
    return value.match(/^[0-9]+$/) || value === '';
  };

  const validationNumbersDecimal = (e) => {
    const { value } = e.target;
    return value.match(/^[0-9]*\.?[0-9]*$/) || value === '';
  };

  const onMoveStepsForm = (index) => {
    if (index === 1) {
      props.setSelectTab(index);
    } else if (index === 2 && validationArcad()) {
      onSubmitArcad();
      props.setSelectTab(index);
    } else if (index === 3 && validationShape()) {
      onSubmitShape();
      props.setSelectTab(index);
    } else if (index === 4 && validationIpr()) {
      onSubmitIpr();
      props.setSelectTab(index);
    } else if (index === 5 && validationOdontograma()) {
      validateOnSubmitSS();
      props.setSelectTab(index);
    }
  };

  useEffect(() => {
    if (props.selectTab > 1) {
      localStorage.setItem('tab-form', props.selectTab);
    }
  }, [props.selectTab]);

  return (
    <ScrollableBody>
      <div className="col-md-12">
        <Title style={{ marginBottom: 15 }}>Formulario de tratamiento</Title>
        <LabelNormal color="#757575" fontSize="17" style={{ marginBottom: 30 }}>
          Selecciona las opciones según tu caso.
        </LabelNormal>
      </div>
      <div style={{ marginLeft: 30 }}>
        <div className="row">
          {itemsTabs.map((item, index) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => onMoveStepsForm(index + 1)}
            >
              <LabelNormal
                fontSize="13"
                color={props.selectTab >= index + 1 ? '#EB9542' : '#757575'}
              >
                {item}
              </LabelNormal>
              <Line
                background={
                  props.selectTab >= index + 1 ? '#EB9542' : '#E0E0E0'
                }
              />
            </div>
          ))}
        </div>
      </div>
      {props.selectTab === 1 && (
        <>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Tipo de tratamiento
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona una fase de tratamiento.
            </LabelNormal>
            <InlineContainer data-test-id="type-treatment">
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="treatment_type-1"
                  name="treatment_type"
                  onClick={() => setTreatmentType('Inicial')}
                  checked={treatmentType === 'Inicial' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="treatment_type-1" className="radio-label">
                  Inicial
                </label>
              </div>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="treatment_type-2"
                  name="treatment_type"
                  onClick={() => setTreatmentType('Refinamiento')}
                  checked={treatmentType === 'Refinamiento' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="treatment_type-2" className="radio-label">
                  Refinamiento
                </label>
              </div>
            </InlineContainer>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Arcada a tratar
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona una arcada.
            </LabelNormal>
            <InlineContainer data-test-id="arcads">
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="arcad-1"
                  name="arcad"
                  onClick={() => setArcad('Ambas arcadas')}
                  checked={arcad === 'Ambas arcadas' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="arcad-1" className="radio-label">
                  Ambas arcadas
                </label>
              </div>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="arcad-2"
                  name="arcad"
                  onClick={() => setArcad('Arcada inferior')}
                  checked={arcad === 'Arcada inferior' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="arcad-2" className="radio-label">
                  Arcada inferior
                </label>
              </div>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="arcad-3"
                  name="arcad"
                  onClick={() => setArcad('Arcada superior')}
                  checked={arcad === 'Arcada superior' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="arcad-3" className="radio-label">
                  Arcada superior
                </label>
              </div>
            </InlineContainer>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Paquete de alineadores
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona un tipo de paquete.
            </LabelNormal>
            <InlineContainer data-test-id="pack-aligner">
              <div className="radio" style={{ width: 300 }}>
                <input
                  id="aligner_pack-1"
                  name="aligner_pack"
                  onClick={() => setAlignerPack('Ajustar en alineadores pares')}
                  checked={
                    alignerPack === 'Ajustar en alineadores pares'
                      ? 'checked'
                      : false
                  }
                  type="radio"
                />
                <label htmlFor="aligner_pack-1" className="radio-label">
                  Ajustar en alineadores pares
                </label>
              </div>
              <div className="radio" style={{ width: 650 }}>
                <input
                  id="aligner_pack-2"
                  name="aligner_pack"
                  onClick={() =>
                    setAlignerPack(
                      'Mantener de acuerdo a la planificación (número de alineadores asimétrico)',
                    )
                  }
                  checked={
                    alignerPack ===
                    'Mantener de acuerdo a la planificación (número de alineadores asimétrico)'
                      ? 'checked'
                      : false
                  }
                  type="radio"
                />
                <label htmlFor="aligner_pack-2" className="radio-label">
                  Mantener de acuerdo a la planificación (número de alineadores
                  asimétrico)
                </label>
              </div>
            </InlineContainer>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Sección anterior
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Indica si hay ajustes en la línea media, overbite y overjet o si
              se mantiene.
            </LabelNormal>
            {/** Section Linea media */}
            <InlineContainer style={{ alignItems: 'flex-start' }}>
              <div
                style={{ marginRight: 77, position: 'relative' }}
                data-test-id="line-middle"
              >
                <LabelNormal
                  fontSize="17"
                  style={{ fontWeight: 500, marginBottom: 20 }}
                  color="#3C4549"
                >
                  Línea media
                </LabelNormal>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="line-middle-1"
                    name="line-middle"
                    onClick={() => setLineMiddle('Mantener')}
                    checked={lineMiddle === 'Mantener' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="line-middle-1" className="radio-label">
                    Mantener
                  </label>
                </div>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="line-middle-2"
                    name="line-middle"
                    onClick={() => setLineMiddle('Centrar')}
                    checked={lineMiddle === 'Centrar' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="line-middle-2" className="radio-label">
                    Centrar
                  </label>
                </div>
                <div className="radio" style={{ width: 180, marginTop: 15 }}>
                  <input
                    id="line-middle-3"
                    name="line-middle"
                    onClick={() => setLineMiddle('Ajustar')}
                    checked={lineMiddle === 'Ajustar' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="line-middle-3" className="radio-label">
                    Ajustar
                  </label>
                </div>

                <InlineContainer
                  style={{ alignItems: 'center', marginTop: 44 }}
                  data-test-id="line-middle-right"
                >
                  <Checkbox
                    className={
                      lineMiddle === 'Mantener' || lineMiddle === 'Centrar'
                        ? ''
                        : 'hover'
                    }
                    checked={rightLineMiddle}
                    icon={
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={!leftLineMiddle ? '#EB9542' : '#C8C8C8'}
                        size="sm"
                      />
                    }
                    borderColor="#C8C8C8"
                    style={{ overflow: 'hidden' }}
                    size={20}
                    onChange={(value) => setRightLineMiddle(value)}
                    labelStyle={{
                      marginLeft: 5,
                      userSelect: 'none',
                      fontFamily: 'neue-montreal-regular',
                      color: !leftLineMiddle ? '#3C4549' : '#C8C8C8',
                      marginRight: 16,
                    }}
                    label={'Derecha'}
                    disabled={
                      lineMiddle === 'Mantener' ||
                      lineMiddle === 'Centrar' ||
                      leftLineMiddle
                    }
                  />
                  <div style={{ position: 'relative' }}>
                    <LabelNormal
                      fontSize="17"
                      color="#C8C8C8"
                      style={{ position: 'absolute', top: -20 }}
                    >
                      Milímetros
                    </LabelNormal>
                    <Input
                      placeholder="Milímetros"
                      disabled={
                        lineMiddle === 'Mantener' ||
                        lineMiddle === 'Centrar' ||
                        !rightLineMiddle
                      }
                      onChange={(e) =>
                        validationNumbersDecimal(e) &&
                        setRightLineMiddleMilimetros(e.target.value)
                      }
                      value={rightLineMiddleMilimetros}
                      maxWidth="109px"
                      style={{
                        borderColor:
                          errors?.name === 'arcad' &&
                          lineMiddle === 'Ajustar' &&
                          rightLineMiddle &&
                          rightLineMiddleMilimetros === ''
                            ? '#C60E02'
                            : '#D1D1D1',
                      }}
                    />
                    {errors?.name === 'arcad' &&
                      lineMiddle === 'Ajustar' &&
                      rightLineMiddle &&
                      rightLineMiddleMilimetros === '' && (
                        <p
                          style={{
                            color: '#C60E02',
                            position: 'absolute',
                            bottom: -38,
                            width: 200,
                            fontSize: 18,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Indica los milímetros
                        </p>
                      )}
                  </div>
                </InlineContainer>

                <InlineContainer
                  style={{ alignItems: 'center', marginTop: 44 }}
                  data-test-id="line-middle-left"
                >
                  <Checkbox
                    className={
                      lineMiddle === 'Mantener' || lineMiddle === 'Centrar'
                        ? ''
                        : 'hover'
                    }
                    checked={leftLineMiddle}
                    icon={
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={!rightLineMiddle ? '#EB9542' : '#C8C8C8'}
                        size="sm"
                      />
                    }
                    borderColor="#C8C8C8"
                    style={{ overflow: 'hidden' }}
                    size={20}
                    onChange={(value) => setLeftLineMiddle(value)}
                    labelStyle={{
                      marginLeft: 5,
                      userSelect: 'none',
                      fontFamily: 'neue-montreal-regular',
                      color: !rightLineMiddle ? '#3C4549' : '#C8C8C8',
                      marginRight: 16,
                    }}
                    label={'Izquierda'}
                    disabled={
                      lineMiddle === 'Mantener' ||
                      lineMiddle === 'Centrar' ||
                      rightLineMiddle
                    }
                  />
                  <div style={{ position: 'relative' }}>
                    <LabelNormal
                      fontSize="17"
                      color="#C8C8C8"
                      style={{ position: 'absolute', top: -20 }}
                    >
                      Milímetros
                    </LabelNormal>
                    <Input
                      placeholder="Milímetros"
                      disabled={
                        lineMiddle === 'Mantener' ||
                        lineMiddle === 'Centrar' ||
                        !leftLineMiddle
                      }
                      onChange={(e) =>
                        validationNumbersDecimal(e) &&
                        setLeftLineMiddleMilimetros(e.target.value)
                      }
                      value={leftLineMiddleMilimetros}
                      maxWidth="109px"
                      style={{
                        borderColor:
                          errors?.name === 'arcad' &&
                          lineMiddle === 'Ajustar' &&
                          leftLineMiddle &&
                          leftLineMiddleMilimetros === ''
                            ? '#C60E02'
                            : '#D1D1D1',
                      }}
                    />
                    {errors?.name === 'arcad' &&
                      lineMiddle === 'Ajustar' &&
                      leftLineMiddle &&
                      leftLineMiddleMilimetros === '' && (
                        <p
                          style={{
                            color: '#C60E02',
                            position: 'absolute',
                            bottom: -38,
                            width: 200,
                            fontSize: 18,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Indica los milímetros
                        </p>
                      )}
                  </div>
                </InlineContainer>
                {errors?.name === 'arcad' &&
                  lineMiddle === 'Ajustar' &&
                  !leftLineMiddle &&
                  !rightLineMiddle && (
                    <p
                      style={{
                        color: '#C60E02',
                        width: 270,
                        fontSize: 18,
                        bottom: -65,
                        position: 'absolute',
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Selecciona si Ajustar a la Izquierda o a la Derecha{' '}
                    </p>
                  )}
              </div>

              {/** Section Overbite */}
              <div
                style={{ marginRight: 77, position: 'relative' }}
                data-test-id="overbite"
              >
                <LabelNormal
                  fontSize="17"
                  style={{ fontWeight: 500, marginBottom: 20 }}
                  color="#3C4549"
                >
                  Overbite
                </LabelNormal>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="overbite-1"
                    name="overbite"
                    onClick={() => setOverbite('Mantener')}
                    checked={overbite === 'Mantener' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="overbite-1" className="radio-label">
                    Mantener
                  </label>
                </div>
                <InlineContainer
                  style={{ alignItems: 'center', marginTop: 44 }}
                >
                  <div
                    className="radio"
                    style={{ width: 180, marginRight: -50 }}
                  >
                    <input
                      id="overbite-2"
                      name="overbite"
                      onClick={() => setOverbite('Ajustar')}
                      checked={overbite === 'Ajustar' ? 'checked' : false}
                      type="radio"
                    />
                    <label htmlFor="overbite-2" className="radio-label">
                      Ajustar
                    </label>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <LabelNormal
                      fontSize="17"
                      color="#C8C8C8"
                      style={{ position: 'absolute', top: -20 }}
                    >
                      Milímetros
                    </LabelNormal>
                    <Input
                      placeholder="Milímetros"
                      disabled={overbite === 'Mantener'}
                      onChange={(e) =>
                        validationNumbersDecimal(e) &&
                        setDataOverbite(e.target.value)
                      }
                      value={dataOverbite}
                      maxWidth="109px"
                      style={{
                        borderColor:
                          errors?.name === 'arcad' &&
                          overbite === 'Ajustar' &&
                          !dataOverbite
                            ? '#C60E02'
                            : '#D1D1D1',
                      }}
                    />
                    {errors?.name === 'arcad' &&
                      overbite === 'Ajustar' &&
                      !dataOverbite && (
                        <p
                          style={{
                            color: '#C60E02',
                            position: 'absolute',
                            bottom: -38,
                            width: 200,
                            fontSize: 18,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Indica los milímetros
                        </p>
                      )}
                  </div>
                </InlineContainer>
              </div>

              {/** Section Overjet */}
              <div style={{ position: 'relative' }} data-test-id="overjet">
                <LabelNormal
                  fontSize="17"
                  style={{ fontWeight: 500, marginBottom: 20 }}
                  color="#3C4549"
                >
                  Overjet
                </LabelNormal>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="overjet-1"
                    name="overjet"
                    onClick={() => setOverjet('Mantener')}
                    checked={overjet === 'Mantener' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="overjet-1" className="radio-label">
                    Mantener
                  </label>
                </div>
                <InlineContainer
                  style={{ alignItems: 'center', marginTop: 44 }}
                >
                  <div
                    className="radio"
                    style={{ width: 180, marginRight: -50 }}
                  >
                    <input
                      id="overjet-2"
                      name="overjet"
                      onClick={() => setOverjet('Ajustar')}
                      checked={overjet === 'Ajustar' ? 'checked' : false}
                      type="radio"
                    />
                    <label htmlFor="overjet-2" className="radio-label">
                      Ajustar
                    </label>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <LabelNormal
                      fontSize="17"
                      color="#C8C8C8"
                      style={{ position: 'absolute', top: -20 }}
                    >
                      Milimetros
                    </LabelNormal>
                    <Input
                      placeholder="Milimetros"
                      disabled={overjet === 'Mantener'}
                      onChange={(e) =>
                        validationNumbersDecimal(e) &&
                        setDataOverjet(e.target.value)
                      }
                      value={dataOverjet}
                      maxWidth="109px"
                      style={{
                        borderColor:
                          errors?.name === 'arcad' &&
                          overjet === 'Ajustar' &&
                          !dataOverjet
                            ? '#C60E02'
                            : '#D1D1D1',
                      }}
                    />
                    {errors?.name === 'arcad' &&
                      overjet === 'Ajustar' &&
                      !dataOverjet && (
                        <p
                          style={{
                            color: '#C60E02',
                            position: 'absolute',
                            bottom: -38,
                            width: 200,
                            fontSize: 18,
                            fontFamily: 'neue-montreal-regular',
                          }}
                        >
                          *Indica los milímetros
                        </p>
                      )}
                  </div>
                </InlineContainer>
              </div>
            </InlineContainer>
          </div>

          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Sección posterior
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Indica si hay espacios y tus necesidades para el ancho de arcada y
              mordida cruzada posterior.{' '}
            </LabelNormal>
            <div className="" data-test-id="spaces">
              <LabelNormal
                fontSize="22"
                color="#3C4549"
                style={{ fontWeight: 500, marginBottom: 20 }}
              >
                Espacios
              </LabelNormal>
              <InlineContainer>
                <div
                  className="radio"
                  data-test-id="spaces-1"
                  style={{ width: 180 }}
                >
                  <input
                    id="space-1"
                    name="space"
                    onClick={() => setSpaces('Dejar espacios')}
                    checked={spaces === 'Dejar espacios' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="space-1" className="radio-label">
                    Dejar espacios
                  </label>
                </div>
                <div
                  className="radio"
                  data-test-id="spaces-2"
                  style={{ width: 280 }}
                >
                  <input
                    id="space-2"
                    name="space"
                    onClick={() => setSpaces('Cerrar todos los espacios')}
                    checked={
                      spaces === 'Cerrar todos los espacios' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="space-2" className="radio-label">
                    Cerrar todos los espacios
                  </label>
                </div>
              </InlineContainer>
            </div>
            <div style={{ marginTop: 17 }}>
              {spaces !== 'Cerrar todos los espacios' && (
                <div data-test-id="map-container-spaces">
                  <LabelNormal color="#757575" fontSize="17">
                    Indica las piezas dentales donde quieres dejar espacios y
                    especifica los milímetros.
                  </LabelNormal>
                  <Map
                    personalize="Indicar milímetros por diente"
                    width={60}
                    height={78}
                    containerNumber2
                    numerationTopLeft={[
                      '18',
                      '17',
                      '16',
                      '15',
                      '14',
                      '13',
                      '12',
                      '11',
                    ]}
                    numerationTopRight={[
                      '21',
                      '22',
                      '23',
                      '24',
                      '25',
                      '26',
                      '27',
                      '28',
                    ]}
                    numerationBottomLeft={[
                      '48',
                      '47',
                      '46',
                      '45',
                      '44',
                      '43',
                      '42',
                      '41',
                    ]}
                    numerationBottomRight={[
                      '31',
                      '32',
                      '33',
                      '34',
                      '35',
                      '36',
                      '37',
                      '38',
                    ]}
                    borderStyle="dashed"
                    topData={up_spaces_data}
                    bottomData={lo_spaces_data}
                    setTopData={setUpSpacesData}
                    setBottomData={setLoSpacesData}
                  />
                  {errors?.name === 'arcad' &&
                    spaces === 'Dejar espacios' &&
                    up_spaces_data.filter((v) => v == '0,0').length ===
                      up_spaces_data.length &&
                    lo_spaces_data.filter((v) => v == '0,0').length ===
                      lo_spaces_data.length && (
                      <p
                        style={{
                          color: '#C60E02',
                          width: '100%',
                          paddingTop: 30,
                          fontSize: 18,
                          fontFamily: 'neue-montreal-regular',
                        }}
                      >
                        *Indica los milímetros en al menos un diente o
                        selecciona Cerrar todos los espacios{' '}
                      </p>
                    )}
                </div>
              )}
              {spaces === 'Cerrar todos los espacios' && (
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{ marginTop: 20, marginBottom: 16 }}
                >
                  Se cierran todos los espacios entre dientes.
                </LabelNormal>
              )}

              <div
                style={{
                  borderTop: '1px solid #BDBDBD',
                  maxWidth: '70%',
                  marginTop: 87,
                }}
              />
              <LabelNormal
                fontSize="22"
                color="#3C4549"
                style={{ fontWeight: 500, marginBottom: 20 }}
              >
                Ancho de arcada
              </LabelNormal>
              <LabelNormal color="#757575" fontSize="17">
                Selecciona si expandir o contraer por arcada, por diente
                individual o mantener el ancho actual
              </LabelNormal>

              <InlineContainer
                style={{ marginTop: 20 }}
                data-test-id="width-arcad"
              >
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="ancho-arcad-1"
                    name="ancho-arcad"
                    onClick={() => setArcarWidth('Indicar por arcada')}
                    checked={
                      arcadWidth === 'Indicar por arcada' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="ancho-arcad-1" className="radio-label">
                    Indicar por arcada
                  </label>
                </div>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="ancho-arcad-2"
                    name="ancho-arcad"
                    onClick={() => setArcarWidth('Indicar por diente')}
                    checked={
                      arcadWidth === 'Indicar por diente' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="ancho-arcad-2" className="radio-label">
                    Indicar por diente
                  </label>
                </div>
                <div className="radio" style={{ width: 280 }}>
                  <input
                    id="ancho-arcad-3"
                    name="ancho-arcad"
                    onClick={() =>
                      setArcarWidth('Mantener ancho de arcada actual')
                    }
                    checked={
                      arcadWidth === 'Mantener ancho de arcada actual'
                        ? 'checked'
                        : false
                    }
                    type="radio"
                  />
                  <label htmlFor="ancho-arcad-3" className="radio-label">
                    Mantener ancho de arcada actual
                  </label>
                </div>
              </InlineContainer>

              {arcadWidth === 'Indicar por arcada' && (
                <>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 10 }}
                  >
                    Indica si quieres expandir o contrar todo el ancho de arcada
                    y cuántos milimetros
                  </LabelNormal>
                  <InlineContainer
                    style={{ marginTop: 40 }}
                    data-test-id="indicate-for-arcad"
                  >
                    <InlineContainer
                      style={{ alignItems: 'center', marginRight: 20 }}
                    >
                      <div className="radio" style={{ width: 130 }}>
                        <input
                          id="ancho-arcad-indicate-1"
                          name="ancho-arcad-exp-srh"
                          onClick={() => setArcarWidthExpShr('Expandir')}
                          checked={
                            arcarWidthExpShr === 'Expandir' ? 'checked' : false
                          }
                          type="radio"
                        />
                        <label
                          htmlFor="ancho-arcad-indicate-1"
                          className="radio-label"
                        >
                          Expandir
                        </label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <LabelNormal
                          fontSize="17"
                          color="#C8C8C8"
                          style={{ position: 'absolute', top: -20 }}
                        >
                          Milimetros
                        </LabelNormal>
                        <Input
                          data-test-id="expand-input-mm"
                          placeholder="Milimetros"
                          disabled={arcarWidthExpShr !== 'Expandir'}
                          onChange={(e) =>
                            validationNumbers(e) &&
                            setArcadWidthExpandMilimetro(e.target.value)
                          }
                          value={arcadWidthExpandMilimetro}
                          maxWidth="109px"
                          style={{
                            borderColor:
                              errors?.name === 'arcad' &&
                              arcarWidthExpShr === 'Expandir' &&
                              arcadWidthExpandMilimetro === ''
                                ? '#C60E02'
                                : '#D1D1D1',
                          }}
                        />
                        {errors?.name === 'arcad' &&
                          arcarWidthExpShr === 'Expandir' &&
                          arcadWidthExpandMilimetro === '' && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Indica los milímetros
                            </p>
                          )}
                      </div>
                    </InlineContainer>

                    <InlineContainer style={{ alignItems: 'center' }}>
                      <div className="radio" style={{ width: 130 }}>
                        <input
                          id="ancho-arcad-indicate-2"
                          name="ancho-arcad-exp-srh"
                          onClick={() => setArcarWidthExpShr('Contraer')}
                          checked={
                            arcarWidthExpShr === 'Contraer' ? 'checked' : false
                          }
                          type="radio"
                        />
                        <label
                          htmlFor="ancho-arcad-indicate-2"
                          className="radio-label"
                        >
                          Contraer
                        </label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <LabelNormal
                          fontSize="17"
                          color="#C8C8C8"
                          style={{ position: 'absolute', top: -20 }}
                        >
                          Milímetros
                        </LabelNormal>
                        <Input
                          data-test-id="contract-input-mm"
                          placeholder="Milimetros"
                          disabled={arcarWidthExpShr !== 'Contraer'}
                          onChange={(e) =>
                            validationNumbers(e) &&
                            setArcadWidthContractMilimetro(e.target.value)
                          }
                          value={arcadWidthContractMilimetro}
                          maxWidth="109px"
                          style={{
                            borderColor:
                              errors?.name === 'arcad' &&
                              arcarWidthExpShr === 'Contraer' &&
                              arcadWidthContractMilimetro === ''
                                ? '#C60E02'
                                : '#D1D1D1',
                          }}
                        />
                        {errors?.name === 'arcad' &&
                          arcarWidthExpShr === 'Contraer' &&
                          arcadWidthContractMilimetro === '' && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Indica los milímetros
                            </p>
                          )}
                      </div>
                    </InlineContainer>
                  </InlineContainer>
                </>
              )}

              {arcadWidth === 'Indicar por diente' && (
                <>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 10 }}
                  >
                    Indica el/los diente(s) que deseas expandir o contraer y
                    cuántos milímetros
                  </LabelNormal>
                  {arch_teeth_data.map((item, key) => (
                    <InlineContainer
                      style={{ marginTop: 40, alignItems: 'center' }}
                    >
                      <Select
                        options={[
                          { name: 'Contraer', id: 'Contraer' },
                          { name: 'Expandir', id: 'Expandir' },
                        ]}
                        value={item !== '' ? item.split(',')[0] : ''}
                        onSelect={(e) =>
                          setArchTeethData([
                            ...arch_teeth_data.map((v, i) =>
                              i === key
                                ? `${e},${
                                    item !== '' ? item.split(',')[1] : ''
                                  },${item !== '' ? item.split(',')[2] : ''}`
                                : v,
                            ),
                          ])
                        }
                        placeholder="Selecciona contraer o..."
                      />

                      <div
                        style={{
                          position: 'relative',
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      >
                        <LabelNormal
                          fontSize="17"
                          color="#C8C8C8"
                          style={{ position: 'absolute', top: -20, width: 180 }}
                        >
                          Número de diente
                        </LabelNormal>
                        <Input
                          placeholder="Número"
                          onChange={(e) =>
                            validationNumbers(e) &&
                            setArchTeethData([
                              ...arch_teeth_data.map((v, i) =>
                                i === key
                                  ? `${item !== '' ? item.split(',')[0] : ''},${
                                      e.target.value
                                    },${item !== '' ? item.split(',')[2] : ''}`
                                  : v,
                              ),
                            ])
                          }
                          value={item !== '' ? item.split(',')[1] : ''}
                          maxWidth="109px"
                          style={{ borderColor: '#D1D1D1' }}
                        />
                        {errors?.name === 'arcad' &&
                          arcadWidth === 'Indicar por diente' &&
                          ((item !== '' && item.split(',')[1] === '') ||
                            item === '') && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Indica el n° diente
                            </p>
                          )}
                      </div>

                      <LabelNormal color="#3C4549" fontSize="17">
                        Hasta
                      </LabelNormal>

                      <div
                        style={{
                          position: 'relative',
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      >
                        <LabelNormal
                          fontSize="17"
                          color="#C8C8C8"
                          style={{ position: 'absolute', top: -20 }}
                        >
                          Milímetros
                        </LabelNormal>
                        <Input
                          placeholder="Número"
                          onChange={(e) =>
                            validationNumbers(e) &&
                            setArchTeethData([
                              ...arch_teeth_data.map((v, i) =>
                                i === key
                                  ? `${item !== '' ? item.split(',')[0] : ''},${
                                      item !== '' ? item.split(',')[1] : ''
                                    },${e.target.value}`
                                  : v,
                              ),
                            ])
                          }
                          value={item !== '' ? item.split(',')[2] : ''}
                          maxWidth="109px"
                          style={{ borderColor: '#D1D1D1' }}
                        />
                        {errors?.name === 'arcad' &&
                          arcadWidth === 'Indicar por diente' &&
                          ((item !== '' && item.split(',')[2] === '') ||
                            item === '') && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Indica los milímetros
                            </p>
                          )}
                      </div>
                      {key === arch_teeth_data.length - 1 && (
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            background: '#F6F6F8',
                            borderRadius: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setArchTeethData([
                              'Contraer,,',
                              ...arch_teeth_data.map((v) => v),
                            ])
                          }
                        >
                          <LabelNormal fontSize="15" color="#000000">
                            +
                          </LabelNormal>
                        </div>
                      )}
                      {key < arch_teeth_data.length - 1 && (
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            background: '#F6F6F8',
                            borderRadius: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setArchTeethData([
                              ...arch_teeth_data.filter((v, i) => i !== key),
                            ])
                          }
                        >
                          <LabelNormal fontSize="15" color="#000000">
                            -
                          </LabelNormal>
                        </div>
                      )}
                    </InlineContainer>
                  ))}
                </>
              )}
              {arcadWidth === 'Mantener ancho de arcada actual' && (
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{ marginTop: 10 }}
                >
                  Se mantiene el ancho de arcada actual del paciente.
                </LabelNormal>
              )}
              {errors?.name === 'arcad' &&
                arcadWidth === 'Indicar por diente' &&
                arch_teeth_data.length === 1 &&
                arch_teeth_data.filter((v) => v === '').length > 0 && (
                  <p
                    style={{
                      color: '#C60E02',
                      fontSize: 18,
                      fontFamily: 'neue-montreal-regular',
                    }}
                  >
                    *Indica el diente(s) y número de milímetros o selecciona
                    Indicar por arcada o Mantener ancho de arcada actual
                  </p>
                )}

              <LabelNormal
                color="#3C4549"
                fontSize="22"
                style={{ fontWeight: 500, marginBottom: 20, paddingTop: 50 }}
              >
                Mordida cruzada posterior
              </LabelNormal>
              <LabelNormal color="#757575" fontSize="17">
                Selecciona si ajustar, descruzar o mantener la mordida cruzada
                posterior actual.
              </LabelNormal>

              <InlineContainer style={{ marginTop: 20 }}>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="mordida-cruzada-1"
                    name="mordida-cruzada"
                    onClick={() => setCrossBitePosterior('Ajustar')}
                    checked={
                      crossBitePosterior === 'Ajustar' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="mordida-cruzada-1" className="radio-label">
                    Ajustar
                  </label>
                </div>
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="mordida-cruzada-2"
                    name="mordida-cruzada"
                    onClick={() => setCrossBitePosterior('Descruzar')}
                    checked={
                      crossBitePosterior === 'Descruzar' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="mordida-cruzada-2" className="radio-label">
                    Descruzar
                  </label>
                </div>
                <div className="radio" style={{ width: 310 }}>
                  <input
                    id="mordida-cruzada-3"
                    name="mordida-cruzada"
                    onClick={() => setCrossBitePosterior('Mantener')}
                    checked={
                      crossBitePosterior === 'Mantener' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor="mordida-cruzada-3" className="radio-label">
                    Mantener
                  </label>
                </div>
              </InlineContainer>
              {crossBitePosterior !== 'Mantener' && (
                <>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 20 }}
                  >
                    Indica si ajustar la arcada o dientes individuales.
                  </LabelNormal>
                  <InlineContainer style={{ marginTop: 10 }}>
                    <div className="radio" style={{ width: 180 }}>
                      <input
                        id="mordida-cruzada-sub-1"
                        name="mordida-cruzada-sub"
                        onClick={() => setCrossBiteSub('Diente')}
                        checked={crossBiteSub === 'Diente' ? 'checked' : false}
                        type="radio"
                      />
                      <label
                        htmlFor="mordida-cruzada-sub-1"
                        className="radio-label"
                      >
                        Diente
                      </label>
                    </div>
                    <div className="radio" style={{ width: 180 }}>
                      <input
                        id="mordida-cruzada-sub-2"
                        name="mordida-cruzada-sub"
                        onClick={() => setCrossBiteSub('Arcada')}
                        checked={crossBiteSub === 'Arcada' ? 'checked' : false}
                        type="radio"
                      />
                      <label
                        htmlFor="mordida-cruzada-sub-2"
                        className="radio-label"
                      >
                        Arcada
                      </label>
                    </div>
                  </InlineContainer>
                </>
              )}
              {crossBitePosterior === 'Mantener' && (
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{ marginTop: 10 }}
                >
                  Se mantiene la mordida cruzada posterior actual del paciente.
                </LabelNormal>
              )}

              {crossBiteSub === 'Arcada' &&
                crossBitePosterior !== 'Mantener' && (
                  <>
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginTop: 10 }}
                    >
                      Indica si deseas expandir o contraer la arcada y cuántos
                      milimetros.
                    </LabelNormal>
                    <InlineContainer style={{ marginTop: 40 }}>
                      <InlineContainer
                        style={{ alignItems: 'center', marginRight: 20 }}
                      >
                        <div className="radio" style={{ width: 130 }}>
                          <input
                            id="mordida-cruzada-expand-1"
                            name="mordida-cruzada-exp-srh"
                            onClick={() => setCrossBiteExpShr('Expandir')}
                            checked={
                              crossBiteExpShr === 'Expandir' ? 'checked' : false
                            }
                            type="radio"
                          />
                          <label
                            htmlFor="mordida-cruzada-expand-1"
                            className="radio-label"
                          >
                            Expandir
                          </label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{ position: 'absolute', top: -20 }}
                          >
                            Milimetros
                          </LabelNormal>
                          <Input
                            placeholder="Milimetros"
                            disabled={crossBiteExpShr !== 'Expandir'}
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossBitePosteriorExpandMilimetro(
                                e.target.value,
                              )
                            }
                            value={crossBitePosteriorExpandMilimetro}
                            maxWidth="109px"
                            style={{
                              borderColor:
                                errors?.name === 'arcad' &&
                                crossBiteExpShr === 'Expandir' &&
                                crossBitePosteriorExpandMilimetro === ''
                                  ? '#C60E02'
                                  : '#D1D1D1',
                            }}
                          />
                          {errors?.name === 'arcad' &&
                            crossBiteExpShr === 'Expandir' &&
                            crossBitePosteriorExpandMilimetro === '' && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica los milímetros
                              </p>
                            )}
                        </div>
                      </InlineContainer>

                      <InlineContainer style={{ alignItems: 'center' }}>
                        <div className="radio" style={{ width: 130 }}>
                          <input
                            id="mordida-cruzada-contract-1"
                            name="mordida-cruzada-exp-srh"
                            onClick={() => setCrossBiteExpShr('Contraer')}
                            checked={
                              crossBiteExpShr === 'Contraer' ? 'checked' : false
                            }
                            type="radio"
                          />
                          <label
                            htmlFor="mordida-cruzada-contract-1"
                            className="radio-label"
                          >
                            Contraer
                          </label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{ position: 'absolute', top: -20 }}
                          >
                            Milímetros
                          </LabelNormal>
                          <Input
                            placeholder="Milímetros"
                            disabled={crossBiteExpShr !== 'Contraer'}
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossBitePosteriorContractMilimetro(
                                e.target.value,
                              )
                            }
                            value={crossBitePosteriorContractMilimetro}
                            maxWidth="109px"
                            style={{
                              borderColor:
                                errors?.name === 'arcad' &&
                                crossBiteExpShr === 'Contraer' &&
                                crossBitePosteriorContractMilimetro === ''
                                  ? '#C60E02'
                                  : '#D1D1D1',
                            }}
                          />
                          {errors?.name === 'arcad' &&
                            crossBiteExpShr === 'Contraer' &&
                            crossBitePosteriorContractMilimetro === '' && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica los milímetros
                              </p>
                            )}
                        </div>
                      </InlineContainer>
                    </InlineContainer>
                  </>
                )}

              {crossBiteSub === 'Diente' &&
                crossBitePosterior === 'Ajustar' && (
                  <>
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginTop: 10 }}
                    >
                      Indica el/los diente(s) que deseas expandir o contraer y
                      cuántos milímetros
                    </LabelNormal>
                    {crossbite_teeth_data.map((item, key) => (
                      <InlineContainer
                        style={{ marginTop: 40, alignItems: 'center' }}
                      >
                        <Select
                          options={[
                            { name: 'Contraer', id: 'Contraer' },
                            { name: 'Expandir', id: 'Expandir' },
                          ]}
                          value={item !== '' ? item.split(',')[0] : ''}
                          onSelect={(e) =>
                            setCrossbiteTeethData([
                              ...crossbite_teeth_data.map((v, i) =>
                                i === key
                                  ? `${e},${
                                      item !== '' ? item.split(',')[1] : ''
                                    },${item !== '' ? item.split(',')[2] : ''}`
                                  : v,
                              ),
                            ])
                          }
                          placeholder="Selecciona contraer o..."
                        />

                        <div
                          style={{
                            position: 'relative',
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                        >
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{
                              position: 'absolute',
                              top: -20,
                              width: 180,
                            }}
                          >
                            Número de diente
                          </LabelNormal>
                          <Input
                            placeholder="Número"
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossbiteTeethData([
                                ...crossbite_teeth_data.map((v, i) =>
                                  i === key
                                    ? `${
                                        item !== '' ? item.split(',')[0] : ''
                                      },${e.target.value},${
                                        item !== '' ? item.split(',')[2] : ''
                                      }`
                                    : v,
                                ),
                              ])
                            }
                            value={item !== '' ? item.split(',')[1] : ''}
                            maxWidth="109px"
                            style={{ borderColor: '#D1D1D1' }}
                          />
                          {errors?.name === 'arcad' &&
                            crossBitePosterior === 'Ajustar' &&
                            ((item !== '' && item.split(',')[1] === '') ||
                              item === '') && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica el n° diente
                              </p>
                            )}
                        </div>

                        <LabelNormal color="#3C4549" fontSize="17">
                          Hasta
                        </LabelNormal>

                        <div
                          style={{
                            position: 'relative',
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                        >
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{ position: 'absolute', top: -20 }}
                          >
                            Milímetros
                          </LabelNormal>
                          <Input
                            placeholder="Número"
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossbiteTeethData([
                                ...crossbite_teeth_data.map((v, i) =>
                                  i === key
                                    ? `${
                                        item !== '' ? item.split(',')[0] : ''
                                      },${
                                        item !== '' ? item.split(',')[1] : ''
                                      },${e.target.value}`
                                    : v,
                                ),
                              ])
                            }
                            value={item !== '' ? item.split(',')[2] : ''}
                            maxWidth="109px"
                            style={{ borderColor: '#D1D1D1' }}
                          />
                          {errors?.name === 'arcad' &&
                            ((item !== '' && item.split(',')[2] === '') ||
                              item === '') && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica los milímetros
                              </p>
                            )}
                        </div>
                        {key === crossbite_teeth_data.length - 1 && (
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              background: '#F6F6F8',
                              borderRadius: 50,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setCrossbiteTeethData([
                                'Contraer,,',
                                ...crossbite_teeth_data.map((v) => v),
                              ])
                            }
                          >
                            <LabelNormal fontSize="15" color="#000000">
                              +
                            </LabelNormal>
                          </div>
                        )}
                        {key < crossbite_teeth_data.length - 1 && (
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              background: '#F6F6F8',
                              borderRadius: 50,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setCrossbiteTeethData([
                                ...crossbite_teeth_data.filter(
                                  (v, i) => i !== key,
                                ),
                              ])
                            }
                          >
                            <LabelNormal fontSize="15" color="#000000">
                              -
                            </LabelNormal>
                          </div>
                        )}
                      </InlineContainer>
                    ))}
                  </>
                )}
              {crossBiteSub === 'Diente' &&
                crossBitePosterior === 'Descruzar' && (
                  <>
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginTop: 10 }}
                    >
                      Indica el/los diente(s) que deseas expandir o contraer y
                      cuántos milímetros
                    </LabelNormal>
                    {crossbite_teeth_data_uncross.map((item, key) => (
                      <InlineContainer
                        style={{ marginTop: 40, alignItems: 'center' }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                        >
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{
                              position: 'absolute',
                              top: -20,
                              width: 180,
                            }}
                          >
                            Número de diente
                          </LabelNormal>
                          <Input
                            placeholder="Número"
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossbiteTeethDataUncross([
                                ...crossbite_teeth_data_uncross.map((v, i) =>
                                  i === key
                                    ? `${e.target.value},${
                                        item !== '' ? item.split(',')[1] : ''
                                      }`
                                    : v,
                                ),
                              ])
                            }
                            value={item !== '' ? item.split(',')[0] : ''}
                            maxWidth="109px"
                            style={{ borderColor: '#D1D1D1' }}
                          />
                          {errors?.name === 'arcad' &&
                            crossBitePosterior === 'Descruzar' &&
                            ((item !== '' && item.split(',')[0] === '') ||
                              item === '') && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica el n° diente
                              </p>
                            )}
                        </div>

                        <LabelNormal color="#3C4549" fontSize="17">
                          Hasta
                        </LabelNormal>

                        <div
                          style={{
                            position: 'relative',
                            marginLeft: 20,
                            marginRight: 20,
                          }}
                        >
                          <LabelNormal
                            fontSize="17"
                            color="#C8C8C8"
                            style={{ position: 'absolute', top: -20 }}
                          >
                            Milímetros
                          </LabelNormal>
                          <Input
                            placeholder="Número"
                            onChange={(e) =>
                              validationNumbers(e) &&
                              setCrossbiteTeethDataUncross([
                                ...crossbite_teeth_data_uncross.map((v, i) =>
                                  i === key
                                    ? `${
                                        item !== '' ? item.split(',')[0] : ''
                                      },${e.target.value}`
                                    : v,
                                ),
                              ])
                            }
                            value={item !== '' ? item.split(',')[1] : ''}
                            maxWidth="109px"
                            style={{ borderColor: '#D1D1D1' }}
                          />
                          {errors?.name === 'arcad' &&
                            ((item !== '' && item.split(',')[1] === '') ||
                              item === '') &&
                            crossBitePosterior === 'Descruzar' && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  position: 'absolute',
                                  bottom: -38,
                                  width: 200,
                                  fontSize: 18,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Indica los milímetros
                              </p>
                            )}
                        </div>
                        {key === crossbite_teeth_data_uncross.length - 1 && (
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              background: '#F6F6F8',
                              borderRadius: 50,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setCrossbiteTeethDataUncross([
                                ',',
                                ...crossbite_teeth_data_uncross.map((v) => v),
                              ])
                            }
                          >
                            <LabelNormal fontSize="15" color="#000000">
                              +
                            </LabelNormal>
                          </div>
                        )}
                        {key < crossbite_teeth_data_uncross.length - 1 && (
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              background: '#F6F6F8',
                              borderRadius: 50,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setCrossbiteTeethDataUncross([
                                ...crossbite_teeth_data_uncross.filter(
                                  (v, i) => i !== key,
                                ),
                              ])
                            }
                          >
                            <LabelNormal fontSize="15" color="#000000">
                              -
                            </LabelNormal>
                          </div>
                        )}
                      </InlineContainer>
                    ))}
                  </>
                )}
              {errors?.name === 'arcad' &&
                crossBitePosterior === 'Indicar por diente' &&
                ((crossBitePosterior === 'Ajustar' &&
                  crossbite_teeth_data.length === 1 &&
                  crossbite_teeth_data.filter((v) => v === '').length > 0) ||
                  (crossBitePosterior === 'Descruzar' &&
                    crossbite_teeth_data_uncross.length === 1 &&
                    crossbite_teeth_data_uncross.filter((v) => v === '')
                      .length > 0)) && (
                  <p
                    style={{
                      color: '#C60E02',
                      position: 'absolute',
                      bottom: -38,
                      width: 300,
                      fontSize: 18,
                      fontFamily: 'neue-montreal-regular',
                    }}
                  >
                    *Indica el diente(s) y número de milímetros o selecciona
                    Indicar por arcada o Mantener mordida cruzada posterior
                  </p>
                )}
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Curva de spee
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona una opción para la curva de spee.
            </LabelNormal>
            <InlineContainer>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="spee-1"
                  name="spee"
                  onClick={() => setSpee('Mantener')}
                  checked={spee === 'Mantener' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="spee-1" className="radio-label">
                  Mantener
                </label>
              </div>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="spee-2"
                  name="spee"
                  onClick={() => setSpee('Aplanar')}
                  checked={spee === 'Aplanar' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="spee-2" className="radio-label">
                  Aplanar
                </label>
              </div>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id="spee-3"
                  name="spee"
                  onClick={() => setSpee('Ajustar')}
                  checked={spee === 'Ajustar' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="spee-3" className="radio-label">
                  Ajustar
                </label>
              </div>
            </InlineContainer>
            {spee === 'Mantener' && (
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginBottom: 20 }}
              >
                Se mantiene la curva de spee actual del paciente.
              </LabelNormal>
            )}
            {spee === 'Aplanar' && (
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginBottom: 20 }}
              >
                El departamento de planificación de Borgatta hará el aplanado
                como mejor convenga para el tratamiento.
              </LabelNormal>
            )}
            {spee === 'Ajustar' && (
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginBottom: 20 }}
              >
                El departamento de planificación de Borgatta hará el ajuste como
                mejor convenga para el tratamiento.
              </LabelNormal>
            )}
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Apiñamiento
            </LabelNormal>
            <LabelNormal fontSize="17" color="#757575">
              Indica si necesitas proclinar o expandir.
            </LabelNormal>
            <InlineContainer>
              <div style={{ marginRight: 50, marginTop: 30 }}>
                <LabelNormal
                  fontSize="22"
                  style={{ fontWeight: 500 }}
                  color="#3C4549"
                >
                  Maxilar
                </LabelNormal>

                <InlineContainer style={{ marginTop: 25 }}>
                  <InlineContainer
                    style={{ alignItems: 'center', marginRight: 30 }}
                  >
                    <Checkbox
                      className={'hover'}
                      checked={proclinarCheck1}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setProclinarCheck1(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Proclinar'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Grados
                      </LabelNormal>
                      <Input
                        placeholder="Grados"
                        disabled={!proclinarCheck1}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setProclinarMilimetros1(e.target.value)
                        }
                        value={proclinarMilimetros1}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'arcad' &&
                            proclinarCheck1 &&
                            !proclinarMilimetros1
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'arcad' &&
                        proclinarCheck1 &&
                        !proclinarMilimetros1 && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los grados
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                  <InlineContainer style={{ alignItems: 'center' }}>
                    <Checkbox
                      className={'hover'}
                      checked={expandirCheck1}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setExpandirCheck1(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Expandir'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Milímetros
                      </LabelNormal>
                      <Input
                        placeholder="Milímetros"
                        disabled={!expandirCheck1}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setExpandirMilimetros1(e.target.value)
                        }
                        value={expandirMilimetros1}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'arcad' &&
                            expandirCheck1 &&
                            !expandirMilimetros1
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'arcad' &&
                        expandirCheck1 &&
                        !expandirMilimetros1 && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los milímetros
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                </InlineContainer>
              </div>
            </InlineContainer>

            <InlineContainer>
              <div style={{ marginRight: 50, marginTop: 30 }}>
                <LabelNormal
                  fontSize="22"
                  style={{ fontWeight: 500 }}
                  color="#3C4549"
                >
                  Mandibular
                </LabelNormal>

                <InlineContainer style={{ marginTop: 25 }}>
                  <InlineContainer
                    style={{ alignItems: 'center', marginRight: 30 }}
                  >
                    <Checkbox
                      className={'hover'}
                      checked={proclinarCheck2}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setProclinarCheck2(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Proclinar'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Grados
                      </LabelNormal>
                      <Input
                        placeholder="Grados"
                        disabled={!proclinarCheck2}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setProclinarMilimetros2(e.target.value)
                        }
                        value={proclinarMilimetros2}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'arcad' &&
                            proclinarCheck2 &&
                            !proclinarMilimetros2
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'arcad' &&
                        proclinarCheck2 &&
                        !proclinarMilimetros2 && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los grados
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                  <InlineContainer style={{ alignItems: 'center' }}>
                    <Checkbox
                      className={'hover'}
                      checked={expandirCheck2}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setExpandirCheck2(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Expandir'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Milímetros
                      </LabelNormal>
                      <Input
                        placeholder="Milímetros"
                        disabled={!expandirCheck2}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setExpandirMilimetros2(e.target.value)
                        }
                        value={expandirMilimetros2}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'arcad' &&
                            expandirCheck2 &&
                            !expandirMilimetros2
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'arcad' &&
                        expandirCheck2 &&
                        !expandirMilimetros2 && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los milímetros
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                </InlineContainer>
              </div>
            </InlineContainer>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            {errors?.name === 'arcad' && (
              <div
                style={{
                  marginBottom: 18,
                  background: '#FFE5E3',
                  border: '1px solid #E41D11',
                  padding: '5px 10px 7px 10px',
                  borderRadius: 7,
                  maxWidth: 226,
                }}
              >
                <LabelNormal fontSize="14" color="#C60E02">
                  *Completa los campos faltantes
                </LabelNormal>
              </div>
            )}
            <InlineContainer style={{ marginTop: 40, marginBottom: 40 }}>
              <ButtonPrime
                label="Anterior"
                background="transparent"
                color="#3C4549"
                border="1px solid #3C4549"
                style={{ marginRight: 20 }}
                onClick={() => props.setSteps(1)}
              />
              <ButtonPrime label="Siguiente" onClick={onSubmitArcad} />
            </InlineContainer>
          </div>
        </>
      )}
      {props.selectTab === 2 && (
        <>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Forma de arco
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona la forma de arco deseada para tu paciente.
            </LabelNormal>
            <div className="row">
              <div className="col-md-2">
                <img src={archShapeImages.ArcoRedondo} height={77} width={68} />
                <div className="radio" style={{ width: 180 }}>
                  <input
                    id="arca-forma-1"
                    name="arca-forma"
                    onClick={() => setArcShape('Redonda')}
                    checked={arc_shape === 'Redonda' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="arca-forma-1" className="radio-label">
                    Redonda
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <img src={archShapeImages.Arco} height={81} width={87} />
                <div className="radio" style={{ width: 280 }}>
                  <input
                    id="arca-forma-2"
                    name="arca-forma"
                    onClick={() => setArcShape('Arco')}
                    checked={arc_shape === 'Arco' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="arca-forma-2" className="radio-label">
                    Arco
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <img src={archShapeImages.ArcoOvoide} height={77} width={80} />
                <div className="radio" style={{ width: 280 }}>
                  <input
                    id="arca-forma-3"
                    name="arca-forma"
                    onClick={() => setArcShape('Ovoide')}
                    checked={arc_shape === 'Ovoide' ? 'checked' : false}
                    type="radio"
                  />
                  <label htmlFor="arca-forma-3" className="radio-label">
                    Ovoide
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Clase deseada
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 10 }}
            >
              Selecciona tus preferencias para la clase deseada.
            </LabelNormal>
            <InlineContainer>
              <div className="radio" style={{ width: 140 }}>
                <input
                  id="clase-deseada-1"
                  name="clase-deseada"
                  onClick={() => setDesiredClass('Priorizar')}
                  checked={desired_class === 'Priorizar' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="clase-deseada-1" className="radio-label">
                  Priorizar
                </label>
              </div>
              <div className="radio" style={{ width: 260 }}>
                <input
                  id="clase-deseada-2"
                  name="clase-deseada"
                  onClick={() => setDesiredClass('Según sea necesario')}
                  checked={
                    desired_class === 'Según sea necesario' ? 'checked' : false
                  }
                  type="radio"
                />
                <label htmlFor="clase-deseada-2" className="radio-label">
                  Según sea necesario
                </label>
              </div>
              <div className="radio" style={{ width: 140 }}>
                <input
                  id="clase-deseada-3"
                  name="clase-deseada"
                  onClick={() => setDesiredClass('Sin cambios')}
                  checked={desired_class === 'Sin cambios' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor="clase-deseada-3" className="radio-label">
                  Sin cambios
                </label>
              </div>
            </InlineContainer>
            {desired_class === 'Según sea necesario' && (
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginTop: 10 }}
              >
                El departamento de planificación de Borgatta seleccionará la
                clase que mejor considere para la Clase molar y Relación canina.
              </LabelNormal>
            )}
            {desired_class === 'Sin cambios' && (
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginTop: 10 }}
              >
                Se mantiene la clase actual del paciente como la deseada.
              </LabelNormal>
            )}
            {desired_class === 'Priorizar' && (
              <>
                <div
                  className="row"
                  style={{ marginTop: 30, alignItems: 'center' }}
                >
                  <div className="col-md-3" style={{ marginRight: 40 }}>
                    <Checkbox
                      className={'hover'}
                      checked={molar_class}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setMolarClass(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                      }}
                      label={'Clase molar'}
                    />
                  </div>
                  <div className="col-md-3">
                    <Checkbox
                      className={'hover'}
                      checked={canine_relationship}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setCanineRelationship(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                      }}
                      label={'Relación canina'}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-3" style={{ marginRight: 40 }}>
                    <div className="row">
                      <div className="col-md-5" style={{ marginRight: 50 }}>
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ marginBottom: 8 }}
                        >
                          Izquierdo
                        </LabelNormal>
                        {Array.from(Array(3).keys()).map((_, i) => (
                          <div className="radio" style={{ width: 100 }}>
                            <input
                              id={`izquierda-${i}`}
                              name="molar-izquierdo"
                              onClick={() =>
                                setMolarLeft(
                                  `Clase ${
                                    i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                  }`,
                                )
                              }
                              checked={
                                molar_left ===
                                `Clase ${
                                  i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                }`
                                  ? 'checked'
                                  : false
                              }
                              disabled={!molar_class}
                              type="radio"
                            />
                            <label
                              htmlFor={`izquierda-${i}`}
                              className="radio-label"
                            >
                              Clase {i === 0 ? 'I' : i === 1 ? 'II' : 'III'}
                            </label>
                          </div>
                        ))}
                        {errors?.name === 'shape' &&
                          (molar_left === '' || molar_left === null) &&
                          molar_class && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Selecciona la clase
                            </p>
                          )}
                      </div>
                      <div className="col-md-4">
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ marginBottom: 8 }}
                        >
                          Derecho
                        </LabelNormal>
                        {Array.from(Array(3).keys()).map((_, i) => (
                          <div className="radio" style={{ width: 100 }}>
                            <input
                              id={`derecho-${i}`}
                              name="molar-derecho"
                              onClick={() =>
                                setMolarRight(
                                  `Clase ${
                                    i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                  }`,
                                )
                              }
                              checked={
                                molar_right ==
                                `Clase ${
                                  i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                }`
                                  ? 'checked'
                                  : false
                              }
                              disabled={!molar_class}
                              type="radio"
                            />
                            <label
                              htmlFor={`derecho-${i}`}
                              className="radio-label"
                            >
                              Clase {i === 0 ? 'I' : i === 1 ? 'II' : 'III'}
                            </label>
                          </div>
                        ))}
                        {errors?.name === 'shape' &&
                          (molar_right === '' || molar_right === null) &&
                          molar_class && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Selecciona la clase
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-5" style={{ marginRight: 50 }}>
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ marginBottom: 8 }}
                        >
                          Izquierdo
                        </LabelNormal>
                        {Array.from(Array(3).keys()).map((_, i) => (
                          <div className="radio" style={{ width: 100 }}>
                            <input
                              id={`canine-izquierda-${i}`}
                              name="canine-izquierdo"
                              onClick={() =>
                                setCanineLeft(
                                  `Clase ${
                                    i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                  }`,
                                )
                              }
                              checked={
                                canine_left ===
                                `Clase ${
                                  i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                }`
                                  ? 'checked'
                                  : false
                              }
                              disabled={!canine_relationship}
                              type="radio"
                            />
                            <label
                              htmlFor={`canine-izquierda-${i}`}
                              className="radio-label"
                            >
                              Clase {i === 0 ? 'I' : i === 1 ? 'II' : 'III'}
                            </label>
                          </div>
                        ))}
                        {errors?.name === 'shape' &&
                          (canine_left === '' || canine_left === null) &&
                          canine_relationship && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Selecciona la clase
                            </p>
                          )}
                      </div>
                      <div className="col-md-4">
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ marginBottom: 8 }}
                        >
                          Derecho
                        </LabelNormal>
                        {Array.from(Array(3).keys()).map((_, i) => (
                          <div className="radio" style={{ width: 100 }}>
                            <input
                              id={`canine-derecho-${i}`}
                              name="canine-derecho"
                              onClick={() =>
                                setCanineRight(
                                  `Clase ${
                                    i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                  }`,
                                )
                              }
                              checked={
                                canine_right ==
                                `Clase ${
                                  i === 0 ? 'I' : i === 1 ? 'II' : 'III'
                                }`
                                  ? 'checked'
                                  : false
                              }
                              disabled={!canine_relationship}
                              type="radio"
                            />
                            <label
                              htmlFor={`canine-derecho-${i}`}
                              className="radio-label"
                            >
                              Clase {i === 0 ? 'I' : i === 1 ? 'II' : 'III'}
                            </label>
                          </div>
                        ))}
                        {errors?.name === 'shape' &&
                          (canine_right === '' || canine_right === null) &&
                          canine_relationship && (
                            <p
                              style={{
                                color: '#C60E02',
                                position: 'absolute',
                                bottom: -38,
                                width: 200,
                                fontSize: 18,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Selecciona la clase
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {errors?.name === 'shape' &&
              desired_class === 'Priorizar' &&
              (!canine_relationship ||
                !molar_class ||
                canine_relationship ||
                molar_class) &&
              ((canine_left === '' &&
                canine_right === '' &&
                molar_left === '' &&
                molar_right === '') ||
                (canine_left === null &&
                  canine_right === null &&
                  molar_left === null &&
                  molar_right === null)) && (
                <p
                  style={{
                    color: '#C60E02',
                    position: 'absolute',
                    bottom: -68,
                    fontSize: 18,
                    fontFamily: 'neue-montreal-regular',
                  }}
                >
                  *Para priorizar selecciona Clase molar/Relación canina y su
                  clase, o la opción de Mejor ajuste
                </p>
              )}
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500, marginBottom: 10 }}
            >
              Zona anterior
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginBottom: 20 }}
            >
              Selecciona la zona anterior deseada para tu paciente.
            </LabelNormal>
            <InlineContainer>
              <InlineContainer>
                <img
                  src={previousZoneImages.PlanosIncisalesRectos}
                  height={83}
                  width={214}
                />
                <div className="radio" style={{ width: 210, marginLeft: 21 }}>
                  <input
                    id={`planos-incisales-rectos`}
                    name="zona-anterior"
                    onClick={() => setAnteriorArea('Planos incisales rectos')}
                    checked={
                      anterior_area === 'Planos incisales rectos'
                        ? 'checked'
                        : false
                    }
                    type="radio"
                  />
                  <label
                    htmlFor={`planos-incisales-rectos`}
                    className="radio-label"
                  >
                    Planos incisales rectos
                  </label>
                </div>
              </InlineContainer>
              <InlineContainer>
                <img
                  src={previousZoneImages.CurvaSonrisa}
                  height={83}
                  width={214}
                />
                <div className="radio" style={{ width: 210, marginLeft: 21 }}>
                  <input
                    id={`curva-de-sonrisa`}
                    name="zona-anterior"
                    onClick={() => setAnteriorArea('Curva de sonrisa')}
                    checked={
                      anterior_area === 'Curva de sonrisa' ? 'checked' : false
                    }
                    type="radio"
                  />
                  <label htmlFor={`curva-de-sonrisa`} className="radio-label">
                    Curva de sonrisa
                  </label>
                </div>
              </InlineContainer>
            </InlineContainer>
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            {errors?.name === 'shape' && (
              <div
                style={{
                  marginBottom: 18,
                  background: '#FFE5E3',
                  border: '1px solid #E41D11',
                  padding: '5px 10px 7px 10px',
                  borderRadius: 7,
                  maxWidth: 226,
                }}
              >
                <LabelNormal fontSize="14" color="#C60E02">
                  *Completa los campos faltantes
                </LabelNormal>
              </div>
            )}
            <InlineContainer style={{ marginTop: 40, marginBottom: 40 }}>
              <ButtonPrime
                label="Anterior"
                background="transparent"
                color="#3C4549"
                border="1px solid #3C4549"
                style={{ marginRight: 20 }}
                onClick={() => props.setSelectTab(1)}
              />
              <ButtonPrime label="Siguiente" onClick={onSubmitShape} />
            </InlineContainer>
          </div>
        </>
      )}
      {props.selectTab === 3 && (
        <>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500 }}
            >
              Instrucciones IPR
            </LabelNormal>
            <LabelNormal
              color="#757575"
              fontSize="17"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              Indica en el diente la cara que requiere de IPR o si ninguno los
              requiere.
            </LabelNormal>
            <InlineContainer>
              <div className="radio" style={{ width: 180 }}>
                <input
                  id={`require-ipr`}
                  name="ipr"
                  onClick={() => setInstructions('Personalizar IPR')}
                  checked={
                    instructions === 'Personalizar IPR' ? 'checked' : false
                  }
                  type="radio"
                />
                <label htmlFor={`require-ipr`} className="radio-label">
                  Personalizar IPR
                </label>
              </div>
              <div className="radio" style={{ width: 210 }}>
                <input
                  id={`planning-calc-ipr`}
                  name="ipr"
                  onClick={() => setInstructions('Según sea necesario')}
                  checked={
                    instructions === 'Según sea necesario' ? 'checked' : false
                  }
                  type="radio"
                />
                <label htmlFor={`planning-calc-ipr`} className="radio-label">
                  Según sea necesario
                </label>
              </div>
              <div className="radio" style={{ width: 180, marginLeft: 21 }}>
                <input
                  id={`not-require-ipr`}
                  name="ipr"
                  onClick={() => setInstructions('No requiere')}
                  checked={instructions === 'No requiere' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor={`not-require-ipr`} className="radio-label">
                  No requiere
                </label>
              </div>
            </InlineContainer>

            {instructions === 'Personalizar IPR' && (
              <>
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{ marginTop: 25, marginBottom: 10 }}
                >
                  Selecciona una manera de personalizar el IPR
                </LabelNormal>
                <InlineContainer>
                  <div className="radio" style={{ width: 180 }}>
                    <input
                      id={`personalize-ipr`}
                      name="ipr-personalize"
                      onClick={() => setPersonalizeIpr('Marcar dientes')}
                      checked={
                        personalizeIpr === 'Marcar dientes' ? 'checked' : false
                      }
                      type="radio"
                    />
                    <label htmlFor={`personalize-ipr`} className="radio-label">
                      Marcar dientes
                    </label>
                  </div>
                  <div className="radio" style={{ width: 280 }}>
                    <input
                      id={`personalize-mm-ipr`}
                      name="ipr-personalize"
                      onClick={() =>
                        setPersonalizeIpr('Indicar milímetros por diente')
                      }
                      checked={
                        personalizeIpr === 'Indicar milímetros por diente'
                          ? 'checked'
                          : false
                      }
                      type="radio"
                    />
                    <label
                      htmlFor={`personalize-mm-ipr`}
                      className="radio-label"
                    >
                      Indicar milímetros por diente
                    </label>
                  </div>
                </InlineContainer>
              </>
            )}

            {instructions === 'Según sea necesario' && (
              <LabelNormal fontSize="17" color="#757575">
                El departamento de planificación de Borgatta calculará el IPR
                como mejor convenga para el tratamiento.
              </LabelNormal>
            )}
            {instructions === 'No requiere' && (
              <LabelNormal fontSize="17" color="#757575">
                No hay reducción interproximal en ningún diente.
              </LabelNormal>
            )}
            {instructions === 'Personalizar IPR' && (
              <>
                <Map
                  personalize={personalizeIpr}
                  numerationTopLeft={[
                    '18',
                    '17',
                    '16',
                    '15',
                    '14',
                    '13',
                    '12',
                    '11',
                  ]}
                  numerationTopRight={[
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                  ]}
                  numerationBottomLeft={[
                    '48',
                    '47',
                    '46',
                    '45',
                    '44',
                    '43',
                    '42',
                    '41',
                  ]}
                  numerationBottomRight={[
                    '31',
                    '32',
                    '33',
                    '34',
                    '35',
                    '36',
                    '37',
                    '38',
                  ]}
                  width={60}
                  height={78}
                  borderStyle="solid"
                  containerNumber2
                  topData={up_ipr_data}
                  bottomData={lo_ipr_data}
                  setTopData={setUpIprData}
                  setBottomData={setLoIprData}
                />
                {errors?.name === 'ipr' &&
                  instructions === 'Personalizar IPR' &&
                  up_ipr_data.filter((v) => v === '0,0').length ===
                    up_ipr_data.length &&
                  lo_ipr_data.filter((v) => v === '0,0').length ===
                    lo_ipr_data.length && (
                    <p
                      style={{
                        color: '#C60E02',
                        marginTop: 50,
                        fontSize: 18,
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Modifica el IPR de al menos un diente o seleccina No
                      requiere IPR
                    </p>
                  )}

                <div className="col-md-12" style={{ marginTop: 80 }}>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginBottom: 8, fontWeight: 500 }}
                  >
                    Indicaciones adicionales
                  </LabelNormal>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginBottom: 20 }}
                  >
                    Si tienes medidas específicas, enlístalas aquí.
                  </LabelNormal>
                  <div className="row col-md-8">
                    <TextArea
                      onChange={(e) => setCommentIpr(e.target.value)}
                      value={commentIpr}
                      placeholder="Enlista tus medidas aquí..."
                      rows={7}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500 }}
            >
              Torque
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#757575"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              Selecciona una opción para torque.
            </LabelNormal>

            <InlineContainer>
              <div className="radio" style={{ width: 360 }}>
                <input
                  id={`torque-1`}
                  name="torque"
                  onClick={() =>
                    setTorque('Indicar torque por anteriores y posteriores')
                  }
                  checked={
                    torque === 'Indicar torque por anteriores y posteriores'
                      ? 'checked'
                      : false
                  }
                  type="radio"
                />
                <label htmlFor={`torque-1`} className="radio-label">
                  Indicar torque por anteriores y posteriores
                </label>
              </div>
              <div className="radio" style={{ width: 250 }}>
                <input
                  id={`torque-2`}
                  name="torque"
                  onClick={() => setTorque('Indicar torque por dientes')}
                  checked={
                    torque === 'Indicar torque por dientes' ? 'checked' : false
                  }
                  type="radio"
                />
                <label htmlFor={`torque-2`} className="radio-label">
                  Indicar torque por dientes
                </label>
              </div>
              <div className="radio" style={{ width: 180, marginLeft: 21 }}>
                <input
                  id={`torque-3`}
                  name="torque"
                  onClick={() => setTorque('No requiere')}
                  checked={torque === 'No requiere' ? 'checked' : false}
                  type="radio"
                />
                <label htmlFor={`torque-3`} className="radio-label">
                  No requiere
                </label>
              </div>
            </InlineContainer>

            {torque === 'Indicar torque por anteriores y posteriores' && (
              <>
                <LabelNormal
                  fontSize="17"
                  color="#757575"
                  style={{ marginTop: 30, marginBottom: 30 }}
                >
                  La cantidad de grados indicados se aplicará a todos los
                  dientes.
                </LabelNormal>
                <InlineContainer>
                  <InlineContainer
                    style={{ alignItems: 'center', marginRight: 30 }}
                  >
                    <Checkbox
                      className={'hover'}
                      checked={prev_torque}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setPrevTorque(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Dientes anteriores hasta'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Grados
                      </LabelNormal>
                      <Input
                        placeholder="Número"
                        disabled={!prev_torque}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setPrevTorqueData(e.target.value)
                        }
                        value={prev_torque_data}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'ipr' &&
                            prev_torque &&
                            prev_torque_data === ''
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'ipr' &&
                        prev_torque &&
                        prev_torque === '' && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los grados
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                  <InlineContainer style={{ alignItems: 'center' }}>
                    <Checkbox
                      className={'hover'}
                      checked={rear_torque}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setRearTorque(value)}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'Dientes posteriores hasta'}
                    />
                    <div style={{ position: 'relative' }}>
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Grados
                      </LabelNormal>
                      <Input
                        placeholder="Número"
                        disabled={!rear_torque}
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setRearTorqueData(e.target.value)
                        }
                        value={rear_torque_data}
                        maxWidth="109px"
                        style={{
                          borderColor:
                            errors?.name === 'ipr' &&
                            rear_torque &&
                            rear_torque_data === ''
                              ? '#C60E02'
                              : '#D1D1D1',
                        }}
                      />
                      {errors?.name === 'ipr' &&
                        rear_torque &&
                        rear_torque_data === '' && (
                          <p
                            style={{
                              color: '#C60E02',
                              position: 'absolute',
                              bottom: -38,
                              width: 200,
                              fontSize: 18,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los grados
                          </p>
                        )}
                    </div>
                  </InlineContainer>
                </InlineContainer>
                {errors?.name === 'ipr' &&
                  !prev_torque &&
                  (prev_torque_data === '' || prev_torque_data === null) &&
                  !rear_torque &&
                  (rear_torque_data === '' || rear_torque_data === null) && (
                    <p
                      style={{
                        color: '#C60E02',
                        fontSize: 18,
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Indica el torque en los anteriores, posteriores, a
                      dientes en específico o selecciona No requiere torque
                    </p>
                  )}
              </>
            )}
            {torque === 'Indicar torque por dientes' && (
              <>
                <LabelNormal
                  fontSize="17"
                  color="#757575"
                  style={{ marginTop: 30, marginBottom: 20 }}
                >
                  Ingresa el número de diente e indica el número de grados
                </LabelNormal>
                {teeth_torque_data.map((item, key) => (
                  <InlineContainer
                    style={{
                      marginTop: 40,
                      alignItems: 'center',
                      marginBottom: 30,
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    >
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20, width: 180 }}
                      >
                        Número de diente
                      </LabelNormal>
                      <Input
                        placeholder="Número"
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setTeethTorqueData([
                            ...teeth_torque_data.map((v, i) =>
                              i === key
                                ? `${e.target.value},${
                                    item !== '' ? item.split(',')[1] : ''
                                  }`
                                : v,
                            ),
                          ])
                        }
                        value={item !== '' ? item.split(',')[0] : ''}
                        maxWidth="109px"
                        style={{ borderColor: '#D1D1D1' }}
                      />
                      {errors?.name === 'ipr' &&
                        (teeth_torque_data[key] === '' ||
                          teeth_torque_data[key].split(',')[0] === '') && (
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              position: 'absolute',
                              bottom: -40,
                              width: 210,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica el n° de diente
                          </p>
                        )}
                    </div>

                    <LabelNormal color="#3C4549" fontSize="17">
                      Hasta
                    </LabelNormal>

                    <div
                      style={{
                        position: 'relative',
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    >
                      <LabelNormal
                        fontSize="17"
                        color="#C8C8C8"
                        style={{ position: 'absolute', top: -20 }}
                      >
                        Grados
                      </LabelNormal>
                      <Input
                        placeholder="Número"
                        onChange={(e) =>
                          validationNumbers(e) &&
                          setTeethTorqueData([
                            ...teeth_torque_data.map((v, i) =>
                              i === key
                                ? `${item !== '' ? item.split(',')[0] : ''},${
                                    e.target.value
                                  }`
                                : v,
                            ),
                          ])
                        }
                        value={item !== '' ? item.split(',')[1] : ''}
                        maxWidth="109px"
                        style={{ borderColor: '#D1D1D1' }}
                      />
                      {errors?.name === 'ipr' &&
                        (teeth_torque_data[key] === '' ||
                          teeth_torque_data[key].split(',')[1] === '') && (
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              position: 'absolute',
                              bottom: -40,
                              width: 210,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Indica los grados
                          </p>
                        )}
                    </div>
                    {key === teeth_torque_data.length - 1 && (
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          background: '#F6F6F8',
                          borderRadius: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setTeethTorqueData([
                            '',
                            ...teeth_torque_data.map((v) => v),
                          ])
                        }
                      >
                        <LabelNormal fontSize="15" color="#000000">
                          +
                        </LabelNormal>
                      </div>
                    )}
                    {key < teeth_torque_data.length - 1 && (
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          background: '#F6F6F8',
                          borderRadius: 50,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setTeethTorqueData([
                            ...teeth_torque_data.filter((v, i) => i !== key),
                          ])
                        }
                      >
                        <LabelNormal fontSize="15" color="#000000">
                          -
                        </LabelNormal>
                      </div>
                    )}
                  </InlineContainer>
                ))}
                {errors?.name === 'ipr' &&
                  teeth_torque_data.length === 1 &&
                  teeth_torque_data.filter((v) => v === '').length === 1 && (
                    <p
                      style={{
                        color: '#C60E02',
                        fontSize: 18,
                        fontFamily: 'neue-montreal-regular',
                      }}
                    >
                      *Indica el diente(s) y número de grados o selecciona
                      Indicar torque por anteriores y posteriores o No requiere
                      torque
                    </p>
                  )}
              </>
            )}
            {torque === 'No requiere' && (
              <LabelNormal
                fontSize="17"
                color="#757575"
                style={{ marginTop: 30, marginBottom: 20 }}
              >
                No se considerará torque en planificación.
              </LabelNormal>
            )}
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            {errors?.name === 'ipr' && (
              <div
                style={{
                  marginBottom: 18,
                  background: '#FFE5E3',
                  border: '1px solid #E41D11',
                  padding: '5px 10px 7px 10px',
                  borderRadius: 7,
                  maxWidth: 226,
                }}
              >
                <LabelNormal fontSize="14" color="#C60E02">
                  *Completa los campos faltantes
                </LabelNormal>
              </div>
            )}
            <InlineContainer style={{ marginTop: 40, marginBottom: 40 }}>
              <ButtonPrime
                label="Anterior"
                background="transparent"
                color="#3C4549"
                border="1px solid #3C4549"
                style={{ marginRight: 20 }}
                onClick={() => props.setSelectTab(2)}
              />
              <ButtonPrime label="Siguiente" onClick={onSubmitIpr} />
            </InlineContainer>
          </div>
        </>
      )}
      {props.selectTab === 4 && (
        <>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <LabelNormal
              fontSize="26"
              color="#3C4549"
              style={{ fontWeight: 500 }}
            >
              Odontograma
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#757575"
              style={{ fontWeight: 400, marginBottom: 20 }}
            >
              Marcar los dientes que tengan consideraciones especiales o
              selecciona la opción No tengo consideraciones.
            </LabelNormal>
            <InlineContainer>
              <div className="radio" style={{ width: 310 }}>
                <input
                  id={`marcar-consideraciones-especiales`}
                  name="odontograma"
                  onClick={() =>
                    setTypeOdontograma('Marcar consideraciones especiales')
                  }
                  checked={
                    typeOdontograma === 'Marcar consideraciones especiales'
                      ? 'checked'
                      : false
                  }
                  type="radio"
                />
                <label
                  htmlFor={`marcar-consideraciones-especiales`}
                  className="radio-label"
                >
                  Marcar consideraciones especiales
                </label>
              </div>
              <div className="radio" style={{ width: 280, marginLeft: 21 }}>
                <input
                  id={`no-tengo-consideraciones`}
                  name="odontograma"
                  onClick={() => setTypeOdontograma('No tengo consideraciones')}
                  checked={
                    typeOdontograma === 'No tengo consideraciones'
                      ? 'checked'
                      : false
                  }
                  type="radio"
                />
                <label
                  htmlFor={`no-tengo-consideraciones`}
                  className="radio-label"
                >
                  No tengo consideraciones
                </label>
              </div>
            </InlineContainer>
            {typeOdontograma === 'No tengo consideraciones' && (
              <LabelNormal fontSize="17" color="#757575">
                No existen consideraciones especiales para el tratamiento.
              </LabelNormal>
            )}
            {typeOdontograma !== 'No tengo consideraciones' && (
              <>
                <div
                  className="row"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <div className="col-md-8">
                    <div
                      className="row"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div className="col-md-6">
                        <LabelNormal fontSize="17" color="#757575">
                          Selecciona una categoría y haz clic sobre el diente
                          para marcarlo.
                        </LabelNormal>
                      </div>
                      <div className="col-md-2" style={{ textAlign: 'right' }}>
                        <Select
                          options={odontograma}
                          onSelect={setSelectOdontograma}
                          value={selectOdontograma}
                          icon
                          maxHeight={'55px'}
                          iconDefault={DienteAusente}
                          style={{
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <LabelNormal
                    color="#3C4549"
                    fontSize="17"
                    style={{ marginBottom: 30 }}
                  >
                    Arcada superior
                  </LabelNormal>
                  <div className="row">
                    <div
                      className="col-md-10"
                      style={{ position: 'relative', maxWidth: 1080 }}
                    >
                      <InlineContainer
                        style={{
                          height: 60,
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                      >
                        {Array.from(Array(8).keys()).map((_, index) => (
                          <div>
                            <LabelNormal
                              style={{ textAlign: 'center', paddingRight: 16 }}
                              fontSize="17"
                              color="#9E9E9E"
                            >
                              {19 - (index + 1)}
                            </LabelNormal>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 60,
                              }}
                            >
                              {arcadUp.find(
                                (v, i) => i === index && v[0] !== '',
                              ) && (
                                <img
                                  src={icons.RealoadIcon}
                                  onClick={() =>
                                    setArcadUp(
                                      arcadUp.map((v, i) =>
                                        index === i ? [''] : v,
                                      ),
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: -40,
                                    paddingRight: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              {selectOdontograma &&
                              !arcadUp.find(
                                (v, i) => i === index && v[0] !== '',
                              ) ? (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-top-' + index}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.id === selectOdontograma,
                                      )?.icon
                                    }
                                    onClick={() =>
                                      setArcadUp(
                                        arcadUp.map((v, i) =>
                                          index === i
                                            ? odontograma.find(
                                                (v) =>
                                                  v.id === selectOdontograma,
                                              )?.name === 'Implante'
                                              ? ['Implante', 'Pieza a excluir']
                                              : [
                                                  odontograma.find(
                                                    (v) =>
                                                      v.id ===
                                                      selectOdontograma,
                                                  )?.name,
                                                ]
                                            : v,
                                        ),
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-top-' + index}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find(
                                            (v, i) => i === index,
                                          )[0],
                                      )?.icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? 0
                                          : 16,
                                      marginRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? -13
                                          : 0,
                                    }}
                                  />
                                  {odontograma.find(
                                    (v) =>
                                      v.name ===
                                      arcadUp.find((v, i) => i === index)[0],
                                  )?.name === 'Implante' && (
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) => v.name === 'Pieza a excluir',
                                        )?.icon
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        paddingRight: 16,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <img
                                src={tops[index]}
                                style={{
                                  paddingRight: 16,
                                  width: '100%',
                                  opacity: arcadUp.find(
                                    (v, i) => i === index && v[0] !== '',
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            {odontograma.find(
                              (v) =>
                                v.name ===
                                arcadUp.find((v, i) => i === index)[0],
                            ) && (
                              <ReactTooltip
                                id={'tooltip-top-' + index}
                                className="extraClass"
                                effect="solid"
                                place="bottom"
                                backgroundColor="#FFF"
                                type="light"
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <LabelNormal color="#000000" fontSize="17">
                                    {
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find(
                                            (v, i) => i === index,
                                          )[0],
                                      )?.name
                                    }
                                  </LabelNormal>
                                  <div
                                    style={{
                                      backgroundColor: '#F4F4F4',
                                      height: 37,
                                      width: 37,
                                      borderRadius: 29,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: 11,
                                    }}
                                  >
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.icon
                                      }
                                      style={{ padding: 4 }}
                                    />
                                  </div>
                                </InlineContainer>
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadUp.find((v, i) => i === index)[0],
                                )?.name === 'Implante' && (
                                  <>
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginTop: 11,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#000000"
                                        fontSize="17"
                                      >
                                        Pieza a excluir
                                      </LabelNormal>
                                      <div
                                        style={{
                                          backgroundColor: '#F4F4F4',
                                          height: 37,
                                          width: 37,
                                          borderRadius: 29,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: 11,
                                        }}
                                      >
                                        <img
                                          src={
                                            odontograma.find(
                                              (v) =>
                                                v.name === 'Pieza a excluir',
                                            )?.icon
                                          }
                                          style={{ padding: 4 }}
                                        />
                                      </div>
                                    </InlineContainer>

                                    <InlineContainer
                                      style={{
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="help-circle"
                                        color="#BDBDBD"
                                        size="22"
                                        style={{ marginTop: 0, marginRight: 8 }}
                                      />
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="17"
                                      >
                                        Una pieza con Implante se <br />
                                        excluye automáticamente.
                                      </LabelNormal>
                                    </InlineContainer>
                                  </>
                                )}
                              </ReactTooltip>
                            )}
                          </div>
                        ))}
                        {Array.from(Array(8).keys()).map((_, index) => (
                          <div>
                            <LabelNormal
                              style={{ textAlign: 'center', paddingRight: 16 }}
                              fontSize="17"
                              color="#9E9E9E"
                            >
                              {20 + (index + 1)}
                            </LabelNormal>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 60,
                              }}
                            >
                              {arcadUp.find(
                                (v, i) => i === index + 8 && v[0] !== '',
                              ) && (
                                <img
                                  src={icons.RealoadIcon}
                                  onClick={() =>
                                    setArcadUp(
                                      arcadUp.map((v, i) =>
                                        index + 8 === i ? [''] : v,
                                      ),
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: -40,
                                    paddingRight: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              {selectOdontograma &&
                              !arcadUp.find(
                                (v, i) => i === index + 8 && v[0] !== '',
                              ) ? (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-top-' + (index + 8)}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.id === selectOdontograma,
                                      )?.icon
                                    }
                                    onClick={() =>
                                      setArcadUp(
                                        arcadUp.map((v, i) =>
                                          index + 8 === i
                                            ? odontograma.find(
                                                (v) =>
                                                  v.id === selectOdontograma,
                                              )?.name === 'Implante'
                                              ? ['Implante', 'Pieza a excluir']
                                              : [
                                                  odontograma.find(
                                                    (v) =>
                                                      v.id ===
                                                      selectOdontograma,
                                                  )?.name,
                                                ]
                                            : v,
                                        ),
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-top-' + (index + 8)}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find(
                                            (v, i) => i === index + 8,
                                          )[0],
                                      )?.icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? 0
                                          : 16,
                                      marginRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? -13
                                          : 0,
                                    }}
                                  />
                                  {odontograma.find(
                                    (v) =>
                                      v.name ===
                                      arcadUp.find(
                                        (v, i) => i === index + 8,
                                      )[0],
                                  )?.name === 'Implante' && (
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) => v.name === 'Pieza a excluir',
                                        )?.icon
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        paddingRight: 16,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <img
                                src={tops[Math.abs(index - 7)]}
                                style={{
                                  paddingLeft: 16,
                                  width: '100%',
                                  transform: ' rotateY(180deg)',
                                  opacity: arcadUp.find(
                                    (v, i) => i === index + 8 && v[0] !== '',
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            {odontograma.find(
                              (v) =>
                                v.name ===
                                arcadUp.find((v, i) => i === index + 8)[0],
                            ) && (
                              <ReactTooltip
                                id={'tooltip-top-' + (index + 8)}
                                className="extraClass"
                                effect="solid"
                                place="bottom"
                                backgroundColor="#FFF"
                                type="light"
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <LabelNormal color="#000000" fontSize="17">
                                    {
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadUp.find(
                                            (v, i) => i === index + 8,
                                          )[0],
                                      )?.name
                                    }
                                  </LabelNormal>
                                  <div
                                    style={{
                                      backgroundColor: '#F4F4F4',
                                      height: 37,
                                      width: 37,
                                      borderRadius: 29,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: 11,
                                    }}
                                  >
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadUp.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.icon
                                      }
                                      style={{ padding: 4 }}
                                    />
                                  </div>
                                </InlineContainer>
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadUp.find((v, i) => i === index + 8)[0],
                                )?.name === 'Implante' && (
                                  <>
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginTop: 11,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#000000"
                                        fontSize="17"
                                      >
                                        Pieza a excluir
                                      </LabelNormal>
                                      <div
                                        style={{
                                          backgroundColor: '#F4F4F4',
                                          height: 37,
                                          width: 37,
                                          borderRadius: 29,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: 11,
                                        }}
                                      >
                                        <img
                                          src={
                                            odontograma.find(
                                              (v) =>
                                                v.name === 'Pieza a excluir',
                                            )?.icon
                                          }
                                          style={{ padding: 4 }}
                                        />
                                      </div>
                                    </InlineContainer>

                                    <InlineContainer
                                      style={{
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="help-circle"
                                        color="#BDBDBD"
                                        size="22"
                                        style={{ marginTop: 0, marginRight: 8 }}
                                      />
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="17"
                                      >
                                        Una pieza con Implante se <br />
                                        excluye automáticamente.
                                      </LabelNormal>
                                    </InlineContainer>
                                  </>
                                )}
                              </ReactTooltip>
                            )}
                          </div>
                        ))}
                      </InlineContainer>
                    </div>
                  </div>

                  <LabelNormal
                    color="#3C4549"
                    fontSize="17"
                    style={{ marginBottom: 30, marginTop: 30 }}
                  >
                    Arcada inferior
                  </LabelNormal>
                  <div className="row">
                    <div
                      className="col-md-10"
                      style={{ position: 'relative', maxWidth: 1080 }}
                    >
                      <InlineContainer
                        style={{
                          height: 60,
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                      >
                        {Array.from(Array(8).keys()).map((_, index) => (
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 60,
                              }}
                            >
                              {arcadLo.find(
                                (v, i) => i === index && v[0] !== '',
                              ) && (
                                <img
                                  src={icons.RealoadIcon}
                                  onClick={() =>
                                    setArcadLo(
                                      arcadLo.map((v, i) =>
                                        index === i ? [''] : v,
                                      ),
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    bottom: -40,
                                    paddingRight: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              {selectOdontograma &&
                              !arcadLo.find(
                                (v, i) => i === index && v[0] !== '',
                              ) ? (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-bottom-' + index}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.id === selectOdontograma,
                                      )?.icon
                                    }
                                    onClick={() =>
                                      setArcadLo(
                                        arcadLo.map((v, i) =>
                                          index === i
                                            ? odontograma.find(
                                                (v) =>
                                                  v.id === selectOdontograma,
                                              )?.name === 'Implante'
                                              ? ['Implante', 'Pieza a excluir']
                                              : [
                                                  odontograma.find(
                                                    (v) =>
                                                      v.id ===
                                                      selectOdontograma,
                                                  )?.name,
                                                ]
                                            : v,
                                        ),
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-bottom-' + index}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index,
                                          )[0],
                                      )?.icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? 0
                                          : 16,
                                      marginRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? -13
                                          : 0,
                                    }}
                                  />
                                  {odontograma.find(
                                    (v) =>
                                      v.name ===
                                      arcadLo.find((v, i) => i === index)[0],
                                  )?.name === 'Implante' && (
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) => v.name === 'Pieza a excluir',
                                        )?.icon
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        paddingRight: 16,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <img
                                src={bottoms[index]}
                                style={{
                                  paddingRight: 16,
                                  width: '100%',
                                  opacity: arcadLo.find(
                                    (v, i) => i === index && v[0] !== '',
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            <LabelNormal
                              style={{ textAlign: 'center', paddingRight: 16 }}
                              fontSize="17"
                              color="#9E9E9E"
                            >
                              {49 - (index + 1)}
                            </LabelNormal>
                            {odontograma.find(
                              (v) =>
                                v.name ===
                                arcadLo.find((v, i) => i === index)[0],
                            ) && (
                              <ReactTooltip
                                id={'tooltip-bottom-' + index}
                                className="extraClass"
                                effect="solid"
                                place="top"
                                backgroundColor="#FFF"
                                type="light"
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <LabelNormal color="#000000" fontSize="17">
                                    {
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index,
                                          )[0],
                                      )?.name
                                    }
                                  </LabelNormal>
                                  <div
                                    style={{
                                      backgroundColor: '#F4F4F4',
                                      height: 37,
                                      width: 37,
                                      borderRadius: 29,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: 11,
                                    }}
                                  >
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index,
                                            )[0],
                                        )?.icon
                                      }
                                      style={{ padding: 4 }}
                                    />
                                  </div>
                                </InlineContainer>
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadLo.find((v, i) => i === index)[0],
                                )?.name === 'Implante' && (
                                  <>
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginTop: 11,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#000000"
                                        fontSize="17"
                                      >
                                        Pieza a excluir
                                      </LabelNormal>
                                      <div
                                        style={{
                                          backgroundColor: '#F4F4F4',
                                          height: 37,
                                          width: 37,
                                          borderRadius: 29,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: 11,
                                        }}
                                      >
                                        <img
                                          src={
                                            odontograma.find(
                                              (v) =>
                                                v.name === 'Pieza a excluir',
                                            )?.icon
                                          }
                                          style={{ padding: 4 }}
                                        />
                                      </div>
                                    </InlineContainer>

                                    <InlineContainer
                                      style={{
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="help-circle"
                                        color="#BDBDBD"
                                        size="22"
                                        style={{ marginTop: 0, marginRight: 8 }}
                                      />
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="17"
                                      >
                                        Una pieza con Implante se <br />
                                        excluye automáticamente.
                                      </LabelNormal>
                                    </InlineContainer>
                                  </>
                                )}
                              </ReactTooltip>
                            )}
                          </div>
                        ))}
                        {Array.from(Array(8).keys()).map((_, index) => (
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 60,
                              }}
                            >
                              {arcadLo.find(
                                (v, i) => i === index + 8 && v[0] !== '',
                              ) && (
                                <img
                                  src={icons.RealoadIcon}
                                  onClick={() =>
                                    setArcadLo(
                                      arcadLo.map((v, i) =>
                                        index + 8 === i ? [''] : v,
                                      ),
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    bottom: -40,
                                    paddingRight: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              {selectOdontograma &&
                              !arcadLo.find(
                                (v, i) => i === index + 8 && v[0] !== '',
                              ) ? (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-bottom-' + (index + 8)}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) => v.id === selectOdontograma,
                                      )?.icon
                                    }
                                    onClick={() =>
                                      setArcadLo(
                                        arcadLo.map((v, i) =>
                                          index + 8 === i
                                            ? odontograma.find(
                                                (v) =>
                                                  v.id === selectOdontograma,
                                              )?.name === 'Implante'
                                              ? ['Implante', 'Pieza a excluir']
                                              : [
                                                  odontograma.find(
                                                    (v) =>
                                                      v.id ===
                                                      selectOdontograma,
                                                  )?.name,
                                                ]
                                            : v,
                                        ),
                                      )
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight: 16,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ position: 'absolute', zIndex: 9 }}
                                  data-for={'tooltip-bottom-' + (index + 8)}
                                  data-tip
                                >
                                  <img
                                    src={
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index + 8,
                                          )[0],
                                      )?.icon
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      paddingRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? 0
                                          : 16,
                                      marginRight:
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.name === 'Implante'
                                          ? -13
                                          : 0,
                                    }}
                                  />
                                  {odontograma.find(
                                    (v) =>
                                      v.name ===
                                      arcadLo.find(
                                        (v, i) => i === index + 8,
                                      )[0],
                                  )?.name === 'Implante' && (
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) => v.name === 'Pieza a excluir',
                                        )?.icon
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        paddingRight: 16,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <img
                                src={bottoms[Math.abs(index - 7)]}
                                style={{
                                  paddingLeft: 16,
                                  width: '100%',
                                  transform: ' rotateY(180deg)',
                                  opacity: arcadLo.find(
                                    (v, i) => i === index + 8 && v[0] !== '',
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              />
                            </div>
                            <LabelNormal
                              style={{ textAlign: 'center', paddingRight: 16 }}
                              fontSize="17"
                              color="#9E9E9E"
                            >
                              {30 + (index + 1)}
                            </LabelNormal>

                            {odontograma.find(
                              (v) =>
                                v.name ===
                                arcadLo.find((v, i) => i === index + 8)[0],
                            ) && (
                              <ReactTooltip
                                id={'tooltip-bottom-' + (index + 8)}
                                className="extraClass"
                                effect="solid"
                                place="top"
                                backgroundColor="#FFF"
                                type="light"
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <LabelNormal color="#000000" fontSize="17">
                                    {
                                      odontograma.find(
                                        (v) =>
                                          v.name ===
                                          arcadLo.find(
                                            (v, i) => i === index + 8,
                                          )[0],
                                      )?.name
                                    }
                                  </LabelNormal>
                                  <div
                                    style={{
                                      backgroundColor: '#F4F4F4',
                                      height: 37,
                                      width: 37,
                                      borderRadius: 29,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: 11,
                                    }}
                                  >
                                    <img
                                      src={
                                        odontograma.find(
                                          (v) =>
                                            v.name ===
                                            arcadLo.find(
                                              (v, i) => i === index + 8,
                                            )[0],
                                        )?.icon
                                      }
                                      style={{ padding: 4 }}
                                    />
                                  </div>
                                </InlineContainer>
                                {odontograma.find(
                                  (v) =>
                                    v.name ===
                                    arcadLo.find((v, i) => i === index + 8)[0],
                                )?.name === 'Implante' && (
                                  <>
                                    <InlineContainer
                                      style={{
                                        alignItems: 'center',
                                        marginTop: 11,
                                      }}
                                    >
                                      <LabelNormal
                                        color="#000000"
                                        fontSize="17"
                                      >
                                        Pieza a excluir
                                      </LabelNormal>
                                      <div
                                        style={{
                                          backgroundColor: '#F4F4F4',
                                          height: 37,
                                          width: 37,
                                          borderRadius: 29,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: 11,
                                        }}
                                      >
                                        <img
                                          src={
                                            odontograma.find(
                                              (v) =>
                                                v.name === 'Pieza a excluir',
                                            )?.icon
                                          }
                                          style={{ padding: 4 }}
                                        />
                                      </div>
                                    </InlineContainer>

                                    <InlineContainer
                                      style={{
                                        alignItems: 'flex-start',
                                        marginTop: 12,
                                      }}
                                    >
                                      <FeatherIcon
                                        icon="help-circle"
                                        color="#BDBDBD"
                                        size="22"
                                        style={{ marginTop: 0, marginRight: 8 }}
                                      />
                                      <LabelNormal
                                        color="#757575"
                                        fontSize="17"
                                      >
                                        Una pieza con Implante se <br />
                                        excluye automáticamente.
                                      </LabelNormal>
                                    </InlineContainer>
                                  </>
                                )}
                              </ReactTooltip>
                            )}
                          </div>
                        ))}
                      </InlineContainer>
                    </div>
                  </div>
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ marginTop: 60 }}
                  >
                    Glosario
                  </LabelNormal>
                  <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={DienteAusente}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Diente ausente
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={Implante}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Implante
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={ProtesisFija}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Prótesis fija
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={ErosionDental}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Erosión dental
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={DienteExtraer}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Diente a extraer
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={PiezaErupcion}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Pieza en erupción
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={DienteIncluidoRetenido}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Diente incluido retenido
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={FracturaCorona}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Fractura en corona
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={PiesaExcluir}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Pieza a excluir
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={Inscrustacion}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Incrustación
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={DienteConEndodoncia}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Diente con endodoncia
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                    <div className="col-md-2">
                      <InlineContainer>
                        <img
                          src={Restauracion}
                          style={{
                            width: '25px',
                            height: '25px',
                            borderRadius: '48.6765px',
                            background: '#FFFFFF',
                          }}
                        />
                        <LabelNormal
                          color="#3C4549"
                          fontSize="17"
                          style={{ fontWeight: 400 }}
                        >
                          Restauración
                        </LabelNormal>
                      </InlineContainer>
                    </div>
                  </div>
                  <InlineContainer style={{ marginTop: 44 }}>
                    <Checkbox
                      className={'hover'}
                      checked={needComments}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#EB9542"
                          size="sm"
                        />
                      }
                      borderColor="#C8C8C8"
                      style={{ overflow: 'hidden' }}
                      size={20}
                      onChange={(value) => setNeedComments(value)}
                      labelStyle={{
                        marginLeft: 10,
                        userSelect: 'none',
                        fontFamily: 'neue-montreal-regular',
                        color: '#3C4549',
                        marginRight: 16,
                      }}
                      label={'¿No encuentras la opción que buscas?'}
                    />
                  </InlineContainer>
                  {needComments && (
                    <>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ fontWeight: 400, marginTop: 10 }}
                      >
                        ¿Tienes algún diente temporal y será parte del
                        tratamiento? Especifícalo aquí
                      </LabelNormal>
                      <div className="col-md-12" style={{ marginTop: 15 }}>
                        <div className="row col-md-7">
                          <TextArea
                            borderColor={
                              validateComments
                                ? 'rgba(189, 189, 189, 0.7)'
                                : '#C60E02'
                            }
                            onChange={(e) =>
                              setOdontogramComments(e.target.value)
                            }
                            value={odontogramComments}
                            placeholder="Si tienes algún comentario que no fue cubierto en el formulario, escríbelo aquí..."
                            rows={9}
                          />
                        </div>
                      </div>
                      {!validateComments && (
                        <InlineContainer>
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              margin: 0,
                              padding: 0,
                              marginTop: 5,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Escríbenos tus comentarios adicionales a las
                            indicaciones especiales o elimina la selección.
                          </p>
                        </InlineContainer>
                      )}
                    </>
                  )}
                  {errors?.name === 'odontograma' &&
                    typeOdontograma === 'Marcar consideraciones especiales' &&
                    arcadUp.filter((v) => v[0] === '').length ===
                      arcadUp.length &&
                    arcadLo.filter((v) => v[0] === '').length ===
                      arcadLo.length && (
                      <p
                        style={{
                          color: '#C60E02',
                          fontSize: 18,
                          fontFamily: 'neue-montreal-regular',
                        }}
                      >
                        *Modifica una propiedad o seleciona No tengo
                        consideraciones
                      </p>
                    )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12" style={{ marginTop: 50 }}>
            {errors?.name === 'odontograma' && (
              <div
                style={{
                  marginBottom: 18,
                  background: '#FFE5E3',
                  border: '1px solid #E41D11',
                  padding: '5px 10px 7px 10px',
                  borderRadius: 7,
                  maxWidth: 226,
                }}
              >
                <LabelNormal fontSize="14" color="#C60E02">
                  *Completa los campos faltantes
                </LabelNormal>
              </div>
            )}
            <InlineContainer style={{ marginTop: 40, marginBottom: 40 }}>
              <ButtonPrime
                label="Anterior"
                background="transparent"
                color="#3C4549"
                border="1px solid #3C4549"
                style={{ marginRight: 20 }}
                onClick={() => props.setSelectTab(3)}
              />
              <ButtonPrime label="Siguiente" onClick={validateOnSubmitSS} />
            </InlineContainer>
          </div>
        </>
      )}
      {props.selectTab === 5 && (
        <ObservationsCAForm
          patientId={props.patient_id}
          setSteps={props.setSteps}
          formData={props.formCAData}
          setSelectTab={props.setSelectTab}
        />
      )}
    </ScrollableBody>
  );
};

export default FormCA;
