import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';

const odontograma = [
  { name: 'Diente ausente', icon: DienteAusente, id: 'diente_ausente' },
  { name: 'Diente a extraer', icon: DienteExtraer, id: 'diente_a_extraer' },
  {
    name: 'Diente a excluir de los movimientos',
    icon: PiesaExcluir,
    id: 'diente_a_excluir_de_los_movimientos',
  },
  { name: 'Implante', icon: Implante, id: 'implante' },
  {
    name: 'Diente en erupcií³n',
    icon: PiezaErupcion,
    id: 'diente_en_erupcion',
  },
  {
    name: 'Diente con prí³tesis fija',
    icon: ProtesisFija,
    id: 'diente_con_protesis_fija',
  },
  {
    name: 'Diente incluido retenido',
    icon: DienteIncluidoRetenido,
    id: 'diente_incluido_retenido',
  },
  {
    name: 'Diente con endodoncia',
    icon: DienteConEndodoncia,
    id: 'diente_con_endodoncia',
  },
  {
    name: 'Diente con erosií³n dental',
    icon: ErosionDental,
    id: 'diente_con_erosion_dental',
  },
  {
    name: 'Fractura en corona',
    icon: FracturaCorona,
    id: 'fractura_en_corona',
  },
  {
    name: 'Diente con incrustacií³n',
    icon: Inscrustacion,
    id: 'diente_con_incrustacion',
  },
  {
    name: 'Diente con restauracií³n',
    icon: Restauracion,
    id: 'diente_con_restauracion',
  },
];

export default odontograma;
