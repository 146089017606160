import React, { Component } from 'react';
import styled from 'styled-components';
import { EventEmitter } from 'events';
import { CSSTransition } from 'react-transition-group';

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) =>
    background ? background : 'rgba(226, 226, 226, 0.3);'}
  ${({ backdrop }) => (backdrop ? backdrop : 'backdrop-filter: blur(16px);')}
  margin-left: 210px;
  box-sizing: border-box;
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
`;

class ModalManagementMessages extends EventEmitter {
  constructor() {
    super();
    this.dom = null;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(dom, callback) {
    if (typeof callback === 'function') callback();
    this.dom = dom;
    this.emitChange();
  }

  close(callback) {
    if (typeof callback === 'function') callback();
    this.dom = null;
    this.emitChange();
  }

  emitChange() {
    this.emit('modal', this.dom);
  }
}

export const modalMessages = new ModalManagementMessages();

export default class ModalComponentMessages extends Component {
  state = {
    dom: null,
  };

  componentDidMount() {
    modalMessages.addListener('modal', this.handleModal);
    window.addEventListener('keydown', this.hendleKeyClose);
  }

  componentWillUnmount() {
    modalMessages.removeListener('modal', this.handleModal);
    window.removeEventListener('keydown', this.hendleKeyClose);
  }

  handleModal = (dom) => {
    this.setState({ dom });
  };

  hendleClickClose = (e) => {
    if (e.target !== e.currentTarget) return;
    modalMessages.close();
  };

  render() {
    const { dom } = this.state;
    return (
      <CSSTransition in={dom} timeout={250} classNames="me-modal" unmountOnExit>
        <BackdropContainer>
          <ScrollContainer>{dom}</ScrollContainer>
        </BackdropContainer>
      </CSSTransition>
    );
  }
}
