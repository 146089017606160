export enum SmartcheckEnum {
  Pending = 'Pending',
  NotAssist = 'NotAssist',
  Done = 'Done',
}

interface Smartcheck {
  id: number;
  start_date: string;
  finish_date: string;
  zoom_link: string;
  status: SmartcheckEnum;
}

export default Smartcheck;
