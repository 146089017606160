import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENT = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      patient {
        id
        first_name
        last_name
        treatment
      }
      treatmentType {
        id
        name
      }
      bracket_ss
    }
  }
`;
