import React from 'react';
import TreatmentTypeTag from '../Case/detailsComponents/TreatmentTypeTag';

const StudentDetailsCard = ({ student }) => {
  return (
    <div className="w-full rounded-3xl overflow-hidden shadow-md">
      <div className="bg-prominence-weak px-6 py-3 flex flex-row justify-between items-center">
        <p className="text-prominence-default font-medium">Estudiante</p>
        <p className="text-prominence-semiweak font-medium">
          {student.semester}º {student.university.type}
        </p>
      </div>
      <div className="bg-white p-6 flex flex-col gap-7">
        <div className="flex flex-col gap-2">
          <p className="text-base text-gray-200">Universidad</p>
          <p className="text-lg text-prominence-default font-medium">
            {student.university.name}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-base text-gray-200">Capacitaciones</p>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <TreatmentTypeTag treatmentTypeId={1} />
              <div className="flex flex-col gap-1.5">
                <div className="flex flex-row justify-between">
                  <p className="uppercase text-sm text-gray-100">Activos</p>
                  <p className="text-sm text-prominence-semiweak">
                    {student.active_treatments_cryst_aligner.length}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="uppercase text-sm text-gray-100">Totales</p>
                  <p className="text-sm text-prominence-semiweak">
                    {student.count_treatments_cryst_aligner}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <TreatmentTypeTag treatmentTypeId={2} />
              <div className="flex flex-col gap-1.5">
                <div className="flex flex-row justify-between">
                  <p className="uppercase text-sm text-gray-100">Activos</p>
                  <p className="text-sm text-prominence-semiweak">
                    {student.active_treatments_smile_system.length}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="uppercase text-sm text-gray-100">Totales</p>
                  <p className="text-sm text-prominence-semiweak">
                    {student.count_treatments_smile_system}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetailsCard;
