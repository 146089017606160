import React from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';

export const ButtonStyle = styled.button`
  display: flex;
  padding: ${({ icon }) => (icon === 'move' ? '10px' : '15px')};
  border-radius: 50%;
  width: 54px;
  height: 54px;
  color: ${({ color }) => (color ? color : 'white')};
  border: ${({ border }) => (border ? border : '0px')};
  margin-top: 5px;
  background: #eb9542;
  transition: all 0.3s ease-out;
  cursor: pointer;
  align-items: center;
  &:hover:enabled {
    background: #d97617;
  }
  &:active:enabled {
    background: #ab5d12;
  }
  &:disabled {
    background: #c8c8c8;
    cursor: default;
  }
`;

const ButtonFile = (props) => {
  return (
    <>
      <ButtonStyle {...props}>
        {' '}
        {props.icon && (
          <FeatherIcon
            icon={props.icon}
            size={props.size}
            style={props.icon === 'move' ? { transform: 'rotate(45deg)' } : {}}
          />
        )}{' '}
      </ButtonStyle>
    </>
  );
};
export default ButtonFile;
