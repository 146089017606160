import { useForm, useWatch } from 'react-hook-form';
import { Column, InlineContainer, LabelInput, Row } from '../shared/Styled';
import TextInput from '../shared/inputs/TextInput';
import AddressForm from './AddressForm';
import { useLazyQuery } from '@apollo/client';
import {
  QUERY_GET_PHYSICAL_TAXSYSTEMS,
  QUERY_GET_MORAL_TAXSYSTEMS,
} from '../../graphql/query';
import { useState } from 'react';
import { useEffect } from 'react';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Select from '../shared/Select';

const FiscalForm = ({
  country,
  setValid,
  setValues,
  defaultValues = null,
  defaultValuesAddress = null,
  submit = 0,
}) => {
  const [taxSystems, setTaxSystems] = useState([]);
  const [cfdiUsages, setCfdiUsages] = useState([]);
  const [addressValid, setAddressValid] = useState(false);
  const [addressValues, setAddressValues] = useState({});
  const [canResetTaxSystem, setCanResetTaxSystem] = useState(false);
  const [canResetCfdiUsages, setCanResetCfdiUsages] = useState(false);
  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    setValue,
    watch,
    setError,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      business_name: '',
      type: '',
      rfc: '',
      tax_system_id: null,
      cfdi_usage_id: null,
      default: false,
    },
    mode: 'onSubmit',
  });
  const watchType = watch('type');
  const watchTaxSystem = watch('tax_system_id');
  const watchCfdi = watch('cfdi_usage_id');
  const watchDefault = watch('default');
  const watchAllFields = useWatch({ control });

  const [getPhysicalTaxSystems] = useLazyQuery(QUERY_GET_PHYSICAL_TAXSYSTEMS, {
    onCompleted: (data) => {
      if (data.getTaxSystemsByTaxpayerType) {
        setTaxSystems(data.getTaxSystemsByTaxpayerType);
      } else {
        setTaxSystems([]);
      }
    },
    onError: (err) => {
      setTaxSystems([]);
    },
  });

  const [getMoralTaxSystems] = useLazyQuery(QUERY_GET_MORAL_TAXSYSTEMS, {
    onCompleted: (data) => {
      if (data.getTaxSystemsByTaxpayerType) {
        setTaxSystems(data.getTaxSystemsByTaxpayerType);
        clearErrors('cfdi_usage_id');
      } else {
        setTaxSystems([]);
        clearErrors('cfdi_usage_id');
      }
    },
    onError: (err) => {
      setTaxSystems([]);
      clearErrors('cfdi_usage_id');
    },
  });

  const setTaxSystem = (id) => setValue('tax_system_id', parseInt(id));
  const setCfdi = (id) => setValue('cfdi_usage_id', parseInt(id));
  const validateTaxSystem = () => {
    console.log('watchTaxSystem:', watchTaxSystem);
    if (watchTaxSystem === null || watchTaxSystem === '')
      setError('tax_system_id', {
        type: 'required',
        message: 'Selecciona un régimen fiscal',
      });
    else {
      clearErrors('tax_system_id');
      clearErrors('cfdi_usage_id');
    }
  };
  const validateCfdi = () => {
    if (watchCfdi === null || watchCfdi === '' || isNaN(watchCfdi))
      setError('cfdi_usage_id', {
        type: 'required',
        message: 'Selecciona una categoria',
      });
    else clearErrors('cfdi_usage_id');
  };

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key]),
      );
      trigger();
    }
  }, [defaultValues]);

  useEffect(() => {
    if (watchType) {
      if (canResetTaxSystem) {
        setValue('tax_system_id', null);
        setValue('cfdi_usage_id', null);
      } else setCanResetTaxSystem(true);
      if (watchType === 'physical') getPhysicalTaxSystems();
      else getMoralTaxSystems();
    }
  }, [watchType]);

  useEffect(() => {
    if (watchTaxSystem) clearErrors('tax_system_id');
  }, [watchTaxSystem]);

  useEffect(() => {
    if (watchCfdi) clearErrors('cfdi_usage_id');
  }, [watchCfdi]);

  useEffect(() => {
    if (watchTaxSystem && taxSystems.length > 0) {
      if (canResetCfdiUsages) setValue('cfdi_usage_id', null);
      else setCanResetCfdiUsages(true);
      const taxSystemAux = taxSystems.find(
        (ts) => parseInt(ts.id) === parseInt(watchTaxSystem),
      );
      if (taxSystemAux) {
        setCfdiUsages(taxSystemAux.cfdiUsages);
        clearErrors('cfdi_usage_id');
      }
    }
  }, [watchTaxSystem, taxSystems]);

  useEffect(() => {
    setValid(isValid && addressValid);
    if (isValid && addressValid) {
      const values = getValues();
      setValues({
        ...values,
        ...addressValues,
      });
    } else {
      setValues({});
    }
  }, [isValid, addressValid, watchAllFields, addressValues]);

  useEffect(() => {
    if (submit > 0) {
      validateTaxSystem();
      validateCfdi();
      trigger();
    }
  }, [submit]);

  return (
    <Column gap="20px">
      <Row>
        <TextInput
          name="business_name"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: 'Introduce la razón social' }}
          placeholder="Nombre"
          label="Razón social"
          parentProps={{
            style: {
              flex: '0 0 80%',
              maxWidth: '80%',
            },
          }}
        />
      </Row>
      <Row>
        <Column gap="5px">
          <LabelInput>Tipo de persona</LabelInput>
          <InlineContainer>
            <div className="radio" style={{ marginRight: 20 }}>
              <input
                id="radio-1"
                onClick={(e) => {
                  setValue('type', 'physical');
                  setValue('taxSystem', null);
                }}
                checked={watchType === 'physical' ? 'checked' : false}
                onChange={() => {}}
                name="type"
                type="radio"
              />
              <label htmlFor="radio-1" className="radio-label">
                Física
              </label>
            </div>
            <div className="radio">
              <input
                id="radio-2"
                onClick={(e) => {
                  setValue('type', 'moral');
                  setValue('taxSystem', null);
                }}
                checked={watchType === 'moral' ? 'checked' : false}
                onChange={() => {}}
                name="type"
                type="radio"
              />
              <label htmlFor="radio-2" className="radio-label">
                Moral
              </label>
            </div>
          </InlineContainer>
        </Column>
      </Row>
      <Row>
        <TextInput
          name="rfc"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: 'Introduce tu RFC con homoclave',
            pattern: {
              value:
                /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
              message: 'RFC inválido',
            },
          }}
          placeholder="RFC con homoclave"
          label="RFC"
          parentProps={{
            style: {
              flex: '0 0 50%',
              maxWidth: '50%',
            },
          }}
        />
        <div style={{ flex: '0 0 50%', maxWidth: '50%' }}>
          <Select
            label="Régimen fiscal"
            name="tax_system_id"
            options={taxSystems}
            onSelect={setTaxSystem}
            value={watchTaxSystem}
            errors={errors}
            placeholder="Régimen fiscal"
            style={{
              borderColor: errors['tax_system_id'] ? '#C60E02' : '#D1D1D1',
            }}
            // onBlur={validateTaxSystem}
            maxHeight="51px"
            disabled={watchType === ''}
          />
        </div>
      </Row>
      <Row>
        <div style={{ flex: '0 0 50%', maxWidth: '50%' }}>
          <Select
            label="Categoría (uso del CFDI)"
            name="cfdi_usage_id"
            options={cfdiUsages}
            onSelect={setCfdi}
            value={watchCfdi}
            errors={errors}
            placeholder="Uso CFDI"
            style={{
              borderColor: errors['cfdi_usage_id'] ? '#C60E02' : '#D1D1D1',
            }}
            // onBlur={validateCfdi}
            maxHeight="51px"
          />
        </div>
      </Row>
      <AddressForm
        country={country}
        setValid={setAddressValid}
        setValues={setAddressValues}
        defaultValues={defaultValuesAddress}
        profileFiscal={true}
        mode="all"
        submit={submit}
      />
      <Row>
        <Checkbox
          checked={watchDefault}
          icon={<FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />}
          borderColor="#C8C8C8"
          style={{ overflow: 'hidden' }}
          size={20}
          onChange={(value) => setValue('default', value)}
          labelStyle={{
            marginLeft: 5,
            userSelect: 'none',
            fontFamily: 'neue-montreal-regular',
            color: '#3C4549',
          }}
          label="Definir como perfil predeterminado"
        />
      </Row>
    </Column>
  );
};

export default FiscalForm;
