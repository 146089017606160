import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MUTATION_INSERT_ADDRESS } from '@graphql/mutation';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import Checkbox from 'react-custom-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ButtonPrime from '@components/shared/ButtonPrime';
import ButtonClose from '../shared/ButtonClose';
import { useForm } from 'react-hook-form';
import TextInput from '../shared/inputs/TextInput';
import AddressForm from '../Doctor/AddressForm';

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ModalBase = (props) => {
  const {
    control,
    clearErrors,
    formState: { errors, isValid },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      default: false,
    },
  });
  const [disabled, setDisabled] = useState(true);
  const [country, setCountry] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [values, setValues] = useState(null);

  const isDefault = watch('default');

  const [addAddress] = useMutation(MUTATION_INSERT_ADDRESS, {
    onCompleted: (data) => {
      if (data.registerAddress) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => props.onFinish())}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Dirección de envío agregada
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14, marginBottom: 40 }}
            >
              Ahora podrás seleccionarla para tus envíos.{' '}
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
  });

  const onSubmitAddress = () => {
    const formValues = getValues();
    addAddress({
      variables: {
        ...formValues,
        ...values,
      },
    });
  };

  useEffect(() => {
    setDisabled(!formValid && !isValid);
  }, [formValid, isValid]);

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    onCompleted: (data) => {
      if (data.doctor) setCountry(data.doctor.user.country);
    },
  });

  useEffect(() => {
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    });
  }, []);

  return (
    <div
      className="modal"
      style={{ maxWidth: 855, height: '100%', margin: 74 }}
    >
      <Inline>
        <ButtonClose onClick={() => modal.close()} />
      </Inline>
      <div>
        <div className="bootstrap-wrapper" style={{ zIndex: 999 }}>
          <div className="row">
            <div className="col-md-11">
              <Title style={{ marginBottom: 10 }}>Dirección de Envío</Title>
              <LabelNormal
                color="#757575"
                fontSize="17"
                style={{ marginBottom: 24, marginLeft: 0 }}
              >
                A esta dirección enviaremos tu tratamiento.
              </LabelNormal>
              <LabelNormal
                color="#454D58"
                fontSize="22"
                style={{ marginBottom: 24, marginLeft: 0 }}
              >
                {'Agregar dirección de Envío'}
              </LabelNormal>
            </div>
            <div class="col-md-12">
              <TextInput
                name="name"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: 'Introduce tu calle' }}
                placeholder="Nombre"
                label="Nombre"
                muted="Asigna un nombre a tu dirección para ubicarla fácilmente en el futuro"
                parentProps={{
                  style: {
                    flex: '0 0 75%',
                    maxWidth: '75%',
                  },
                }}
              />
              <AddressForm
                country={country.id}
                setValid={setFormValid}
                setValues={setValues}
                doctor={true}
                profileFiscal={false}
                mode="onBlur"
              />
              <div style={{ margin: 0, paddingTop: 28 }} className="row">
                <Checkbox
                  checked={isDefault}
                  icon={
                    <FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />
                  }
                  borderColor="#C8C8C8"
                  style={{ overflow: 'hidden' }}
                  size={20}
                  onChange={(value) => setValue('default', value)}
                  labelStyle={{
                    marginLeft: 5,
                    userSelect: 'none',
                    fontFamily: 'neue-montreal-regular',
                    color: '#3C4549',
                  }}
                  label="Definir como dirección de envío predeterminada"
                />
              </div>
              <div style={{ marginTop: 31, marginBottom: 41 }}>
                <ButtonPrime
                  disabled={disabled}
                  label={'Registrar dirección'}
                  onClick={() => onSubmitAddress()}
                ></ButtonPrime>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBase;
