import React from 'react';
import Map from '@components/DoctorFormCA/map';
import HistoryChat from '@components/shared/HistoryChat';
import ButtonFile from '@components/shared/ButtonFile';
import { InlineContainer, LabelNormal } from '../shared/Styled';
import Odontogram from '../DoctorFormSS/odontogram';

const ResumeCA = ({
  formCAData,
  chatMessages,
  review = false,
  formStatus,
  posteriorData,
  iprData,
  odontogramData,
}) => {
  return (
    <>
      <LabelNormal fontSize="26" color="#3C4549" bold>
        Arcada a tratar
      </LabelNormal>
      <LabelNormal style={{ marginTop: 10 }} fontSize="17" color="#757575">
        {formCAData.arcad.type}
      </LabelNormal>
      {review === true && formStatus === 'Rechazado' && (
        <div
          style={{
            background: '#C60E02',
            width: 188,
            height: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 19,
            position: 'absolute',
            top: '10%',
            right: '30%',
            left: '35%',
            zIndex: 9,
            filter: 'opacity(0.8)',
          }}
        >
          <LabelNormal fontSize="30" color="#F4F4F4">
            Rechazado
          </LabelNormal>
        </div>
      )}
      {review === true && formStatus === 'Aprobado' && (
        <div
          style={{
            background: '#1DBB24',
            width: 188,
            height: 80,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 19,
            position: 'absolute',
            top: '10%',
            right: '30%',
            left: '35%',
            zIndex: 9,
            filter: 'opacity(0.8)',
          }}
        >
          <LabelNormal fontSize="30" color="#F4F4F4">
            Aceptado
          </LabelNormal>
        </div>
      )}
      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Sección anterior
      </LabelNormal>
      <InlineContainer style={{ alignItems: 'flex-start' }}>
        <InlineContainer style={{ alignItems: 'center', marginTop: 10 }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Línea media
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.middle_line}
          </LabelNormal>
        </InlineContainer>
        <div style={{ marginTop: 10, marginLeft: 10 }}>
          {formCAData.arcad?.middle_line_right && (
            <InlineContainer>
              <LabelNormal color="#757575" fontSize="17">
                Derecha
              </LabelNormal>
              <LabelNormal color="#757575" fontSize="17">
                {formCAData.arcad?.middle_line_right} mm
              </LabelNormal>
            </InlineContainer>
          )}
          {formCAData.arcad?.middle_line_left && (
            <InlineContainer>
              <LabelNormal color="#757575" fontSize="17">
                Izquierda
              </LabelNormal>
              <LabelNormal color="#757575" fontSize="17">
                {formCAData.arcad?.middle_line_left} mm
              </LabelNormal>
            </InlineContainer>
          )}
        </div>
      </InlineContainer>

      <InlineContainer>
        <InlineContainer style={{ alignItems: 'center', marginTop: 10 }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Overbite
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.overbite}
          </LabelNormal>
        </InlineContainer>
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          {formCAData.arcad?.overbite === 'Ajustar' && (
            <LabelNormal color="#757575" fontSize="17">
              {formCAData.arcad?.overbite_data} mm
            </LabelNormal>
          )}
        </div>
      </InlineContainer>

      <InlineContainer>
        <InlineContainer style={{ alignItems: 'center', marginTop: 10 }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Overjet
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad?.overjet}
          </LabelNormal>
        </InlineContainer>
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          {formCAData.arcad?.overjet === 'Ajustar' && (
            <LabelNormal color="#757575" fontSize="17">
              {formCAData.arcad?.overjet_data} mm
            </LabelNormal>
          )}
        </div>
      </InlineContainer>

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Sección posterior
      </LabelNormal>
      <div className="row">
        <div className="col-md-6">
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Espacios
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.spaces}
          </LabelNormal>
        </div>
        {review === true && (
          <div
            className="col-md-6"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {formCAData.arcad.spaces === 'Dejar espacios' && (
              <ButtonFile
                onClick={() => posteriorData()}
                icon={'move'}
                size={35}
              />
            )}
          </div>
        )}
      </div>
      {formCAData.arcad.spaces !== 'Cerrar todos los espacios' && (
        <Map
          personalize="Indicar milímetros por diente"
          width={24}
          height={45}
          containerNumber2
          numerationTopLeft={['18', '17', '16', '15', '14', '13', '12', '11']}
          numerationTopRight={['21', '22', '23', '24', '25', '26', '27', '28']}
          numerationBottomLeft={[
            '48',
            '47',
            '46',
            '45',
            '44',
            '43',
            '42',
            '41',
          ]}
          numerationBottomRight={[
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
          ]}
          borderStyle="dashed"
          disabled
          topData={JSON.parse(formCAData.arcad.up_spaces_data)}
          bottomData={JSON.parse(formCAData.arcad.lo_spaces_data)}
          setTopData={() => {}}
          setBottomData={() => {}}
        />
      )}
      <LabelNormal fontSize="17" color="#3C4549" style={{ marginTop: 10 }} bold>
        Ancho de arcada
      </LabelNormal>

      {formCAData.arcad.arch_width === 'Indicar por arcada' && (
        <InlineContainer style={{ alignItems: 'center' }}>
          <InlineContainer style={{ alignItems: 'center' }}>
            <LabelNormal fontSize="17" color="#3C4549" bold>
              {formCAData.arcad?.arch_exp ? 'Expandir' : 'Contraer'}
            </LabelNormal>
            <LabelNormal fontSize="17" color="#757575">
              {formCAData.arcad.arch_exp_data && formCAData.arcad?.arch_exp
                ? formCAData.arcad.arch_exp_data + ' mm'
                : formCAData.arcad.arch_shr_data && formCAData.arcad?.arch_shr
                ? formCAData.arcad.arch_shr_data + ' mm'
                : ''}
            </LabelNormal>
          </InlineContainer>
        </InlineContainer>
      )}

      {formCAData.arcad.arch_width === 'Indicar por diente' && (
        <>
          {JSON.parse(formCAData.arcad?.arch_teeth_data).map((teeth) => (
            <InlineContainer style={{ alignItems: 'center' }}>
              <InlineContainer style={{ alignItems: 'center' }}>
                <LabelNormal
                  style={{ width: 174, marginRight: 10 }}
                  fontSize="17"
                  color="#3C4549"
                  bold
                >
                  {teeth.split(',')[0]}
                </LabelNormal>
                <LabelNormal color="#757575" fontSize="17">
                  Diente{' '}
                  <span style={{ color: '#3C4549' }}>
                    {teeth.split(',')[1]}
                  </span>{' '}
                  hasta
                </LabelNormal>
                <LabelNormal color="#757575" fontSize="17">
                  {' '}
                  <span style={{ color: '#3C4549' }}>
                    {teeth.split(',')[2]}
                  </span>{' '}
                  milimetros
                </LabelNormal>
              </InlineContainer>
            </InlineContainer>
          ))}
        </>
      )}

      {formCAData.arcad.arch_width === 'Mantener ancho de arcada actual' && (
        <LabelNormal color="#757575" fontSize="17">
          Mantener ancho de arcada actual
        </LabelNormal>
      )}

      <LabelNormal fontSize="17" color="#3C4549" style={{ marginTop: 20 }} bold>
        Mordida cruzada posterior
      </LabelNormal>
      {(formCAData.arcad.cross_bite === 'Ajustar' ||
        formCAData.arcad.cross_bite === 'Descruzar') &&
        formCAData.arcad.cross_bite_subselect === 'Arcada' && (
          <InlineContainer style={{ alignItems: 'center' }}>
            <InlineContainer style={{ alignItems: 'center' }}>
              <LabelNormal fontSize="17" color="#3C4549" bold>
                {formCAData.arcad.cross_bite}
              </LabelNormal>
              {formCAData.arcad.crossbite_exp && (
                <>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.arcad.cross_bite_subselect + ' Expandir hasta'}
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    {formCAData.arcad.crossbite_exp_data + ' milímetros'}
                  </LabelNormal>
                </>
              )}
              {formCAData.arcad.crossbite_shr && (
                <>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.arcad.cross_bite + ' Contraer hasta'}
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    {formCAData.arcad.crossbite_shr_data + ' milímetros'}
                  </LabelNormal>
                </>
              )}
            </InlineContainer>
          </InlineContainer>
        )}
      {formCAData.arcad.cross_bite === 'Ajustar' &&
        formCAData.arcad.cross_bite_subselect === 'Diente' && (
          <>
            {JSON.parse(formCAData.arcad?.crossbite_teeth_data).map((teeth) => (
              <InlineContainer style={{ alignItems: 'center' }}>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    {formCAData.arcad.cross_bite}
                  </LabelNormal>
                  <LabelNormal color="#757575" fontSize="17">
                    Diente{' '}
                    <span style={{ color: '#3C4549' }}>
                      {teeth.split(',')[1]}
                    </span>{' '}
                    hasta
                  </LabelNormal>
                  <LabelNormal
                    style={{ width: 174, marginRight: 10 }}
                    fontSize="17"
                    color="#3C4549"
                    bold
                  >
                    {teeth.split(',')[0]}
                  </LabelNormal>
                  <LabelNormal color="#757575" fontSize="17">
                    {' '}
                    <span style={{ color: '#3C4549' }}>
                      {teeth.split(',')[2]}
                    </span>{' '}
                    milimetros
                  </LabelNormal>
                </InlineContainer>
              </InlineContainer>
            ))}
          </>
        )}
      {formCAData.arcad.cross_bite === 'Descruzar' &&
        formCAData.arcad.cross_bite_subselect === 'Diente' && (
          <>
            {JSON.parse(formCAData.arcad?.crossbite_teeth_data).map((teeth) => (
              <InlineContainer style={{ alignItems: 'center' }}>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    {formCAData.arcad.cross_bite}
                  </LabelNormal>
                  <LabelNormal color="#757575" fontSize="17">
                    Diente{' '}
                    <span style={{ color: '#3C4549' }}>
                      {teeth.split(',')[0]}
                    </span>{' '}
                    hasta
                  </LabelNormal>
                  <LabelNormal color="#757575" fontSize="17">
                    {' '}
                    <span style={{ color: '#3C4549' }}>
                      {teeth.split(',')[1]}
                    </span>{' '}
                    milímetros
                  </LabelNormal>
                </InlineContainer>
              </InlineContainer>
            ))}
          </>
        )}
      {formCAData.arcad.cross_bite === 'Mantener' && (
        <LabelNormal color="#757575" fontSize="17">
          Mantener. Se mantiene la mordida cruzada posterior actual del
          paciente.
        </LabelNormal>
      )}

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Apiñamiento
      </LabelNormal>

      <LabelNormal color="#3C4549" fontSize="17" style={{ marginTop: 20 }} bold>
        Maxilar
      </LabelNormal>

      {formCAData.arcad.procline_adjust_maxilla && (
        <InlineContainer style={{ alignItems: 'center' }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Proclinar
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.procline_adjust_maxilla_data}°
          </LabelNormal>
        </InlineContainer>
      )}
      {formCAData.arcad.expand_adjust_maxilla && (
        <InlineContainer style={{ alignItems: 'center' }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Expandir
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.expand_adjust_maxilla_data} mm
          </LabelNormal>
        </InlineContainer>
      )}
      {!formCAData.arcad.procline_adjust_maxilla_data &&
        !formCAData.arcad.expand_adjust_maxilla_data && (
          <LabelNormal color="#757575" fontSize="17">
            No requiere
          </LabelNormal>
        )}
      <LabelNormal color="#3C4549" fontSize="17" style={{ marginTop: 20 }} bold>
        Mandibular
      </LabelNormal>
      {formCAData.arcad.procline_adjust_jaw && (
        <InlineContainer style={{ alignItems: 'center' }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Proclinar
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.procline_adjust_jaw_data}°
          </LabelNormal>
        </InlineContainer>
      )}
      {formCAData.arcad.expand_adjust_jaw && (
        <InlineContainer style={{ alignItems: 'center' }}>
          <LabelNormal fontSize="17" color="#3C4549" bold>
            Expandir
          </LabelNormal>
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.arcad.expand_adjust_jaw_data} mm
          </LabelNormal>
        </InlineContainer>
      )}
      {!formCAData.arcad.procline_adjust_jaw_data &&
        !formCAData.arcad.expand_adjust_jaw && (
          <LabelNormal color="#757575" fontSize="17">
            No requiere
          </LabelNormal>
        )}
      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Forma de arco
      </LabelNormal>
      <LabelNormal fontSize="17" color="#757575">
        {formCAData.shape.arc_shape}
      </LabelNormal>

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Clase deseada
      </LabelNormal>
      {formCAData.shape.desired_class === 'Priorizar' ? (
        <div>
          {formCAData.shape.molar_class && (
            <div style={{ marginTop: 20 }}>
              <LabelNormal fontSize="22" color="#3C4549" bold>
                Clase molar
              </LabelNormal>
              <>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    Lado derecho
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.shape.molar_right_type}
                  </LabelNormal>
                </InlineContainer>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    Lado izquierdo
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.shape.molar_left_type}
                  </LabelNormal>
                </InlineContainer>
              </>
            </div>
          )}

          {formCAData.shape.canine_relationship && (
            <div style={{ marginTop: 15 }}>
              <LabelNormal fontSize="22" color="#3C4549" bold>
                Relación canina
              </LabelNormal>
              <>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    Lado derecho
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.shape.canine_right}
                  </LabelNormal>
                </InlineContainer>
                <InlineContainer style={{ alignItems: 'center' }}>
                  <LabelNormal fontSize="17" color="#3C4549" bold>
                    Lado izquierdo
                  </LabelNormal>
                  <LabelNormal fontSize="17" color="#757575">
                    {formCAData.shape.canine_left}
                  </LabelNormal>
                </InlineContainer>
              </>
            </div>
          )}
        </div>
      ) : (
        <LabelNormal fontSize="17" color="#757575">
          {formCAData.shape.desired_class}
        </LabelNormal>
      )}

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Zona anterior
      </LabelNormal>
      <LabelNormal fontSize="17" color="#757575">
        {formCAData.shape.anterior_area}
      </LabelNormal>

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Instrucciones IPR
      </LabelNormal>
      <div className="row">
        <div
          className="col-md-6"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LabelNormal fontSize="17" color="#757575">
            IPR personalizado
          </LabelNormal>
          <LabelNormal
            fontSize="17"
            color="#757575"
            style={{ fontWeight: 600 }}
          >
            Marcar dientes
          </LabelNormal>
        </div>
        {review === true && (
          <div
            className="col-md-6"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {formCAData.ipr.instructions === 'Personalizar IPR' && (
              <ButtonFile onClick={() => iprData()} icon={'move'} size={35} />
            )}
          </div>
        )}
      </div>
      {formCAData.ipr.instructions === 'Personalizar IPR' && (
        <Map
          personalize={
            formCAData.ipr.kind === 'Indicar milimetros por diente'
              ? 'Indicar milímetros por diente'
              : 'Marcar dientes'
          }
          numerationTopLeft={['18', '17', '16', '15', '14', '13', '12', '11']}
          numerationTopRight={['21', '22', '23', '24', '25', '26', '27', '28']}
          numerationBottomLeft={[
            '48',
            '47',
            '46',
            '45',
            '44',
            '43',
            '42',
            '41',
          ]}
          numerationBottomRight={[
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
          ]}
          width={28}
          height={50}
          disabled
          borderStyle="solid"
          containerNumber2
          topData={JSON.parse(formCAData.ipr.up_ipr_data)}
          bottomData={JSON.parse(formCAData.ipr.lo_ipr_data)}
          setTopData={() => {}}
          setBottomData={() => {}}
        />
      )}
      <LabelNormal fontSize="17" color="#3C4549" style={{ marginTop: 80 }} bold>
        Indicaciones adicionales
      </LabelNormal>
      <div className="row" style={{ marginTop: 12, marginRight: 24 }}>
        <div className="col-md-12">
          <InlineContainer>
            <LabelNormal fontSize="17" color="#757575">
              {formCAData.ipr.additional_indications !== ''
                ? formCAData.ipr.additional_indications
                : 'No hay comentarios'}
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Torque
      </LabelNormal>
      {formCAData.ipr.torque ===
        'Indicar torque por anteriores y posteriores' && (
        <InlineContainer style={{ alignItems: 'center' }}>
          {formCAData.ipr.back_teeth && (
            <InlineContainer>
              <LabelNormal fontSize="17" color="#3C4549" bold>
                Dientes anteriores hasta{' '}
              </LabelNormal>
              <LabelNormal fontSize="17" color="#757575">
                {formCAData.ipr.back_teeth_to}°
              </LabelNormal>
            </InlineContainer>
          )}
          {formCAData.ipr.front_teeth && (
            <InlineContainer>
              <LabelNormal fontSize="17" color="#3C4549" bold>
                Dientes posteriores hasta{' '}
              </LabelNormal>
              <LabelNormal fontSize="17" color="#757575">
                {formCAData.ipr.front_teeth_to}°
              </LabelNormal>
            </InlineContainer>
          )}
        </InlineContainer>
      )}

      {formCAData.ipr.torque === 'Indicar torque por dientes' && (
        <>
          {JSON.parse(formCAData.ipr?.teeth_torque_data).map((teeth) => (
            <InlineContainer style={{ alignItems: 'center' }}>
              <InlineContainer style={{ alignItems: 'center' }}>
                <LabelNormal color="#757575" fontSize="17">
                  Diente{' '}
                  <span style={{ color: '#3C4549' }}>
                    {teeth.split(',')[0]}
                  </span>{' '}
                  hasta
                </LabelNormal>
                <LabelNormal color="#757575" fontSize="17">
                  {' '}
                  <span style={{ color: '#3C4549' }}>
                    {teeth.split(',')[1]}
                  </span>
                  °
                </LabelNormal>
              </InlineContainer>
            </InlineContainer>
          ))}
        </>
      )}

      {formCAData.ipr.torque === 'No requiere' && (
        <LabelNormal fontSize="17" color="#757575">
          No requiere
        </LabelNormal>
      )}

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Odontograma
      </LabelNormal>
      {/* <LabelNormal fontSize="17" color="#757575">Pon el cursor sobre la pieza para ver el detalle de las modificaciones realizadas.</LabelNormal> */}
      <div className="row">
        <div
          className="col-md-6"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LabelNormal fontSize="17" color="#757575">
            {formCAData.odontograma.type}
          </LabelNormal>
        </div>
        {review === true && (
          <div
            className="col-md-6"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {formCAData.odontograma.type ===
              'Marcar consideraciones especiales' && (
              <ButtonFile
                onClick={() => odontogramData()}
                icon={'move'}
                size={35}
              />
            )}
          </div>
        )}
      </div>
      {formCAData.odontograma.type !== 'No tengo consideraciones' && (
        <div>
          <Odontogram
            up_odontograma={formCAData.up_odontograma}
            lo_odontograma={formCAData.lo_odontograma}
          />
          {formCAData.odontograma.comments !== '' &&
            formCAData.odontograma.comments && (
              <div style={{ marginTop: 20 }}>
                <LabelNormal fontSize="17" color="#3C4549" bold>
                  Comentarios adicionales
                </LabelNormal>
                <LabelNormal fontSize="17" color="#757575">
                  {formCAData.odontograma.comments}
                </LabelNormal>
              </div>
            )}
        </div>
      )}

      <LabelNormal fontSize="26" color="#3C4549" style={{ marginTop: 40 }} bold>
        Comentarios adicionales (con respecto al tratamiento)
      </LabelNormal>
      <LabelNormal fontSize="17" color="#757575">
        {formCAData.additional !== ''
          ? formCAData.additional
          : 'No hay instrucciones adicionales'}
      </LabelNormal>

      {chatMessages.length > 0 && (
        <>
          <hr />
          <HistoryChat chat={chatMessages} />
        </>
      )}
    </>
  );
};

export default ResumeCA;
