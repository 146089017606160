import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Point from '../../components/shared/Point';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import levelsCA from '@images/levels/LevelsCA.png';
import levelsSS from '@images/levels/LevelsSS.png';
import axios from 'axios';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const TypesTreatments = styled.h2`
  font-size: 26px;
  font-weight: 600;
  font-family: neue-montreal-regular;
  margin: 0;
  padding: 0;
`;

const ContainCardPoints = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 547px;
  height: 280px;
  margin-bottom: 45px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
`;

const ContainNotice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 684px;
  height: 122px;
  padding: 15px 20px;
  gap: 10px;
  margin-bottom: 30px;
  background-color: #e0e0e0;
  border-radius: 15px;
`;

const IconNotice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 27px;
  height: 27px;
  padding: 6px;
  gap: 10px;
  margin-bottom: 30px;
  background-color: #bdbdbd;
  border-radius: 15px;
`;

const LevelCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1047px;
  height: 1374px;
  margin-top: 25px;
  margin-bottom: 45px;
  border-radius: 15px;
`;

const Benefits = (props) => {
  const history = useHistory();

  useEffect(() => {
    getPoints();
  });

  const [currentPoints, setCurrentPoints] = useState('0');

  const getPoints = async () => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await instance.get('/api/loyalty/points');
    const data = response.data;

    setCurrentPoints(Number(data.points).toLocaleString('es-MX'));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="beneficios" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent style={{ marginBottom: 100 }}>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Beneficios</Title>
              </div>
              <div className="col-md-12" style={{ marginTop: 45 }}>
                <div className="col-md-6" style={{ marginLeft: -12 }}>
                  <ContainCardPoints>
                    <Point />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginLeft: 52,
                      }}
                    >
                      <Title>Tus puntos</Title>
                      <p
                        style={{
                          fontSize: 52.21,
                          color: '#E88322',
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        {currentPoints}
                      </p>
                    </div>
                  </ContainCardPoints>
                </div>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-md-12'>
                <Title>Nuestros Niveles</Title>
              </div>
              <div className="col-md-12" style={{ marginTop: 10 }}>
                <div className="col-md-8" style={{ marginLeft: -14 }}>
                  <ContainNotice>
                    <IconNotice>
                      <FeatherIcon icon="tool" color="#FFFFFF" size="15" />
                    </IconNotice>
                    <p
                      style={{
                        fontSize: 17,
                        color: '#3C4549',
                        lineHeight: '135%',
                      }}
                    >
                      Estos niveles aún no están activos, pero guardaremos tus
                      puntos y tus casos activos para colocarte en el que te
                      corresponda cuando terminemos su construcción. Mientras
                      tanto puedes consultarlos y familiarizarte con sus
                      beneficios.
                    </p>
                  </ContainNotice>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TypesTreatments style={{ color: '#18A1DC' }}>
                  Cryst Aligner
                </TypesTreatments>
              </div>
              <div className="col-md-12" style={{ marginTop: 10 }}>
                <div className="col-md-12" style={{ marginLeft: -14 }}>
                  <LevelCardsContainer>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src={levelsCA}
                    />
                  </LevelCardsContainer>
                </div>
              </div>
              <div className="col-md-12">
                <TypesTreatments style={{ color: '#F0BB00' }}>
                  Smile System
                </TypesTreatments>
              </div>
              <div className="col-md-12" style={{ marginTop: 10 }}>
                <div className="col-md-12" style={{ marginLeft: -14 }}>
                  <LevelCardsContainer>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src={levelsSS}
                    />
                  </LevelCardsContainer>
                </div>
              </div>
            </div> */}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Benefits;
