import { useMutation, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  MainLogin,
  ImgLogin,
  SpanLogin,
  H2Login,
  InputContainer,
  SpanCorreo,
} from '@components/Login/LoginLink.style';
import ButtonPrime from '@components/shared/ButtonPrime';
import InputSet from '@components/shared/InputSet';
import Input from '@components/shared/Input';
import { MUTATION_RESET_PASSWORD, MUTATION_LOGIN } from '@graphql/mutation';
import { QUERY_GET_USER_BY_UUID } from '@graphql/query';
import LoginImg from '@images/Union.svg';
import { useHistory, useParams } from 'react-router-dom';
import ModalSuccess from '@components/Modal/ModalSuccess';
import styled from 'styled-components';
import { modal } from '@components/ModalManagement';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ForgotPasswordToken = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [resetPassword] = useMutation(MUTATION_RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.resetPassword) {
        modal.open(
          <ModalSuccess onClick={() => modal.close(() => history.push('/'))}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Contraseña cambiada con éxito
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
  });

  useEffect(() => {
    validation();
  }, [password, confirmPass]);

  const validation = () => {
    if (password === '') {
      setDisabled(true);
    } else if (confirmPass === '') {
      setDisabled(true);
    } else if (password.length < 8) {
      setDisabled(true);
    } else if (password !== confirmPass) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <MainLogin>
      <ImgLogin src={LoginImg} />
      <H2Login>Hola</H2Login>
      <SpanLogin>
        Crea una nueva contraseña <br /> para iniciar sesión.
      </SpanLogin>
      <InputContainer>
        <Input
          label="Contraseña"
          placeholder="8 caracteres"
          type="password"
          validation="passwordlenght"
          value={password}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          style={{
            borderColor:
              password != '' && confirmPass != '' && disabled ? '#C60E02' : '',
            borderWeight: 1,
            borderstyle: 'solid',
            minWidth: 305,
          }}
        />
      </InputContainer>
      <InputContainer>
        <Input
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          type="password"
          name="repassword"
          onChange={(e) => setConfirmPass(e.target.value)}
          style={{
            borderColor:
              password != '' && confirmPass != '' && disabled
                ? '#C60E02'
                : password.length >= 8 && confirmPass != ''
                  ? '#169D00'
                  : '',
            borderWeight: 1,
            borderstyle: 'solid',
            minWidth: 305,
          }}
        />
        {password != '' &&
          password.length >= 8 &&
          confirmPass != '' &&
          disabled && (
            <p
              style={{
                color: '#C60E02',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Contraseñas no coinciden
            </p>
          )}
        {password != '' &&
          password.length >= 8 &&
          confirmPass != '' &&
          !disabled && (
            <p
              style={{
                color: '#169D00',
                fontSize: 18,
                margin: 0,
                padding: 0,
                marginTop: 5,
                fontFamily: 'neue-montreal-regular',
              }}
            >
              *Contraseñas coinciden
            </p>
          )}
      </InputContainer>
      <ButtonPrime
        label="Ingresar"
        disabled={disabled}
        onClick={() => resetPassword({ variables: { token: id, password } })}
      />
    </MainLogin>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (token, type, id, role_id) =>
    dispatch(action.loginSuccess(token, type, id, role_id)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordToken);
