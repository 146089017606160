export enum UserRoleEnum {
  ADMIN = 'admin',
  DOCTOR = 'doctor',
  STUDENT = 'student',
  TEACHER = 'teacher'
}

type UserRole = `${UserRoleEnum}`;

// const UserRoles = Object.values(UserRole);

export default UserRole;
