import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Chip from '@components/shared/Chip';
import Input from '@components/shared/Input';
import InputPassword from '@components/shared/InputPassword';
import { SpanInput } from '@components/Login/Login.style';
import ButtonPrime from '@components/shared/ButtonPrime';
import EditProfile from '../../components/shared/EditProfle';
import CloseSession from '../../components/shared/CloseSession';
import { QUERY_GET_ME_DATA, QUERY_GET_ROLES } from '@graphql/query';
import LabelUnderline from '../../components/shared/LabelUnderline';
import {
  MUTATION_UPDATE_PASSWORD,
  MUTATION_UPDATE_ME_PROFILE_MEMBER,
  MUTATION_ADD_MEMBER_PHOTO,
} from '@graphql/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import * as action from '@redux/actions/auth-action';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { loadPhotoProfileGender } from '../../utils';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Card from '../../components/shared/Card';
import UserIcon from '@images/icons/user.svg';
import PhoneIcon from '@images/icons/phone.svg';
import MailIcon from '@images/icons/mail.svg';
import LocationIcon from '@images/icons/location.svg';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 20px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily === 'medium' ? 'neue-montreal-medium' : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

const CardContainer = styled.div`
  padding: 22px;
  background: #fff;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;

const InlineContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ChipCircle = styled.div`
  width: 34px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5690ff;
  margin-left: 11px;
  margin-top: 5px;
  cursor: pointer;
`;
const HeaderCard = styled.div`
  background: #eeeff1;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;
const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;
const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const Register = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [validatePassword, setValidatePassword] = useState(true);
  const [validateFirstName, setValidateFirstName] = useState(true);
  const [validateLastName, setValidateLastName] = useState(true);
  const [validatePhone, setValidatePhone] = useState(true);
  const [validateEmail, setValidateEmail] = useState(true);

  const [editInfo, setEditInfo] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [editPass, setEditPass] = useState(false);
  const [photo, setPhoto] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState({});
  const [departament, setDepartament] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [validation, setValidation] = useState('');
  const [file, setFile] = useState({});
  const [Id, setId] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [enabledPermissions, setEnabledPermissions] = useState(false);
  const [creatAt, setCreatAt] = useState('');
  const [loader, setLoader] = useState(false);

  const [getRoles] = useLazyQuery(QUERY_GET_ROLES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setPermissions(data.roles.data);
    },
  });

  const [getDetailsMe] = useLazyQuery(QUERY_GET_ME_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.me) {
        const user = data.me;

        setId(user.id);
        setPhone(user.phone);
        setEmail(user.email);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setPhoto(user.photo_filename);
        setGender(user.gender);
        setCountry(user.country);
        setCreatAt(user.created_at);
        setDepartament(user.administrator.department);
        if (user.administrator && user.administrator.super_admin) {
          getRoles({});
        } else {
          if (user.administrator.administratorRoles.length > 0) {
            setPermissions(
              user.administrator.administratorRoles.map((a) => ({ ...a.role })),
            );
          }
        }
      }
      setLoadingSkeleton(false);
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getDetailsMe({});
  }, []);

  const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD, {
    onCompleted: (data) => {
      if (data.updatePassword) {
        setEditPass(false);
        setPassword('');
        setValidation('');
        setConfirmPass('');
        modal.open(
          <ModalSuccess onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Contraseña cambiada
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Ingresa con la nueva contraseña cuando inicies sesión de nuevo en
              la plataforma.
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
  });

  const [updateProfileInfo] = useMutation(MUTATION_UPDATE_ME_PROFILE_MEMBER, {
    onCompleted: (data) => {
      if (data.updateMemberProfile) {
        if (file.name) {
          setLoader(true);
          addProfilePhoto({
            variables: { user_id: +data.updateMemberProfile.id, file },
          });
        } else {
          modal.open(
            <ModalSuccess onClick={() => modal.close()}>
              <LabelNormal
                color="#060809"
                fontSize="22"
                style={{ marginBottom: 14 }}
              >
                Cambios a perfil guardados
              </LabelNormal>
            </ModalSuccess>,
          );
          setEditInfo(false);
        }
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        setLoader(false);
        modal.open(
          <ModalSuccess onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>,
        );
        setEditInfo(false);
      }
    },
    onError: (err) => {
      setLoader(false);
      console.log(err);
    },
  });

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  useEffect(() => {
    validationPass();
  }, [password, confirmPass]);

  const validationPass = () => {
    if (password !== '') {
      setValidatePassword(true);
    }

    if (password === '') {
      setDisabled(false);
    } else if (confirmPass === '') {
      setDisabled(true);
    } else if (password.length < 8) {
      setDisabled(true);
    } else if (password !== confirmPass) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onSubmitPass = () => {
    if (password === '') {
      setValidatePassword(false);
    } else {
      updatePassword({
        variables: { password, password_confirmation: confirmPass },
      });
    }
  };

  const onSubmitEdit = () => {
    firstName === '' ? setValidateFirstName(false) : setValidateFirstName(true);
    lastName === '' ? setValidateLastName(false) : setValidateLastName(true);
    email === '' ? setValidateEmail(false) : setValidateEmail(true);
    phone === '' ? setValidatePhone(false) : setValidatePhone(true);
    if (firstName !== '' && lastName !== '' && email !== '' && phone !== '') {
      updateProfileInfo({
        variables: {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          gender,
        },
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="perfil" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 33,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {editInfo && (
                <div className="col-md-12">
                  <Title>Editar Datos de perfil</Title>
                </div>
              )}
              <div className="col-md-8">
                <InlineContainer>
                  {!loadingSkeleton ? (
                    <div>
                      <Avatar
                        src={
                          photo && !file.name
                            ? `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${photo}`
                            : photo && file.name
                              ? photo
                              : loadPhotoProfileGender(gender)
                        }
                        edit={photo ? true : false}
                        onChange={onChange}
                        state={loader}
                        floatButton={editInfo}
                        height="161"
                        width="161"
                        borderRadius="14"
                      />
                      {!editInfo && (
                        <>
                          <LabelNormal
                            color="#757575F2"
                            fontSize="14"
                            style={{ textAlign: 'center', marginTop: 20 }}
                          >
                            Miembro desde
                          </LabelNormal>
                          <LabelNormal
                            color="#757575F2"
                            fontSize="17"
                            fontFamily="medium"
                            style={{ textAlign: 'center' }}
                          >
                            {moment(creatAt).format('DD/MM/YYYY')}
                          </LabelNormal>
                        </>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Skeleton
                        height={161}
                        borderRadius={27}
                        width={161}
                        baseColor={'#EEEEF2'}
                        highlightColor={'#FFF'}
                      />
                      <center>
                        <Skeleton
                          count={1}
                          baseColor={'#F6F6F8'}
                          width={97}
                          height={27}
                          borderRadius={5}
                          style={{ marginTop: 10 }}
                          highlightColor={'#FFF'}
                        />
                      </center>
                    </div>
                  )}

                  {!loadingSkeleton ? (
                    <>
                      {!editInfo && (
                        <div style={{ marginLeft: 30 }}>
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 10 }}
                          >
                            <LabelNormal
                              fontSize="26"
                              color="#3C4549"
                              fontFamily="medium"
                            >
                              {`${firstName} ${lastName}`}
                            </LabelNormal>
                            <EditProfile
                              color="#757575"
                              width="24"
                              onClick={setEditInfo}
                            />
                          </InlineContainer>
                          <InlineContainer>
                            <div className="row">
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={UserIcon}
                                    alt="user"
                                    style={{ marginRight: 10 }}
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${gender}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={PhoneIcon}
                                    style={{ marginRight: 10 }}
                                    alt="phone"
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${phone}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={MailIcon}
                                    style={{ marginRight: 10 }}
                                    alt="email"
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${email}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={LocationIcon}
                                    style={{ marginRight: 10 }}
                                    alt="location"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {country.name ? country.name : ''}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-12">
                                <LabelUnderline
                                  color="#757575"
                                  fontSize="17"
                                  onClick={() => setEditPass(!editPass)}
                                  style={{
                                    marginBottom: 30,
                                    letterSpacing: '2%',
                                  }}
                                  label={'Cambiar contraseña'}
                                />
                              </div>
                            </div>
                          </InlineContainer>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{ marginLeft: 35, marginTop: 10, width: '100%' }}
                    >
                      <div>
                        <Skeleton
                          baseColor={'#F6F6F8'}
                          height={27}
                          borderRadius={5}
                          highlightColor={'#FFF'}
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <div className="row">
                          <div className="col-md-3">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div className="col-md-7">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-md-5">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div className="col-md-7">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-md-4">
                            <Skeleton
                              count={1}
                              baseColor={'#F6F6F8'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </InlineContainer>
                {editInfo && (
                  <div>
                    <div className="grid" style={{ marginTop: 38 }}>
                      <InlineContainer>
                        <div>
                          <Input
                            placeholder="Nombre"
                            defaultValue={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            label="Nombres y apellidos"
                            type="custom"
                            validation={validateFirstName}
                          />
                          {!validateFirstName && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 18,
                                margin: 0,
                                padding: 0,
                                marginTop: 5,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Introduce el nombre
                            </p>
                          )}
                        </div>
                        <div>
                          <span style={{ fontSize: 18, visibility: 'hidden' }}>
                            none
                          </span>
                          <Input
                            placeholder="Apellidos"
                            defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            type="custom"
                            validation={validateLastName}
                          />
                          {!validateLastName && (
                            <p
                              style={{
                                color: '#C60E02',
                                fontSize: 18,
                                margin: 0,
                                padding: 0,
                                marginTop: 5,
                                fontFamily: 'neue-montreal-regular',
                              }}
                            >
                              *Introduce el apellido
                            </p>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                    <div>
                      <LabelInput>Género</LabelInput>
                      <InlineContainer>
                        <div className="radio" style={{ marginRight: 20 }}>
                          <input
                            id="radio-1"
                            onClick={(e) => setGender('Masculino')}
                            checked={gender === 'Masculino' ? 'checked' : false}
                            name="gender"
                            type="radio"
                          />
                          <label htmlFor="radio-1" className="radio-label">
                            Masculino
                          </label>
                        </div>
                        <div className="radio">
                          <input
                            id="radio-2"
                            onClick={(e) => setGender('Femenino')}
                            checked={gender === 'Femenino' ? 'checked' : false}
                            name="gender"
                            type="radio"
                          />
                          <label htmlFor="radio-2" className="radio-label">
                            Femenino
                          </label>
                        </div>
                      </InlineContainer>
                    </div>
                    <div
                      style={{ marginTop: 38, marginLeft: 0 }}
                      className="row"
                    >
                      <div
                        className="col-md-7"
                        style={{ padding: 0, marginRight: -18 }}
                      >
                        <LabelInput>Teléfono</LabelInput>
                        <div className="row" style={{ margin: 0, padding: 0 }}>
                          <div
                            className="col-md-3"
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Input
                              readOnly
                              disabled
                              value={country.lada_code ? country.lada_code : ''}
                              backgroundColor="#F6F6F8"
                              maxWidth="70px"
                            />
                          </div>
                          <div
                            className="col-md-8"
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Input
                              placeholder="Teléfono"
                              defaultValue={phone}
                              onChange={setPhone}
                              value={phone}
                              mask="phone"
                              validation="phone"
                              emptyVal={!validatePhone}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5" style={{ padding: 0 }}>
                        <Input
                          placeholder="Tu correo"
                          label="Correo"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          emptyVal={!validateEmail}
                        />
                        {!validateEmail && (
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              margin: 0,
                              padding: 0,
                              marginTop: 5,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Introduce un correo
                          </p>
                        )}
                      </div>
                    </div>
                    <InlineContainer
                      style={{
                        marginTop: 40,
                        marginBottom: 40,
                        alignItems: 'center',
                      }}
                    >
                      <ButtonPrime
                        label="Guardar cambios"
                        onClick={() => onSubmitEdit()}
                      />
                      <ButtonPrime
                        label="Cancelar"
                        background="transparent"
                        color="#3C4549"
                        border="1px solid #3C4549"
                        minWidth="127"
                        style={{ marginLeft: 20 }}
                        onClick={() => setEditInfo(false)}
                      />
                    </InlineContainer>
                  </div>
                )}
                <div>
                  {editPass && (
                    <div className="col-md-12" style={{ marginTop: 40 }}>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="22"
                        style={{ letterSpacing: '2%', margin: 0 }}
                      >
                        Cambio de contraseña
                      </LabelNormal>
                      <div style={{ marginTop: 15 }}>
                        <SpanInput>Nueva contraseña</SpanInput>
                        <InputPassword
                          placeholder="Contraseña"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          validation="passwordlenght"
                          style={{
                            borderColor:
                              password !== '' && confirmPass !== '' && disabled
                                ? '#C60E02'
                                : '',
                            borderWeight: 1,
                            borderstyle: 'solid',
                            maxWidth: 305,
                          }}
                        />
                        {!validatePassword && (
                          <p
                            style={{
                              color: '#C60E02',
                              fontSize: 18,
                              margin: 0,
                              padding: 0,
                              marginTop: 5,
                              fontFamily: 'neue-montreal-regular',
                            }}
                          >
                            *Introduce la contraseña
                          </p>
                        )}
                        <div style={{ marginTop: 20 }}>
                          <SpanInput>Confirmar contraseña</SpanInput>
                          <InputPassword
                            placeholder="Contraseña"
                            onChange={(e) => setConfirmPass(e.target.value)}
                            style={{
                              borderColor:
                                password !== '' &&
                                confirmPass !== '' &&
                                disabled
                                  ? '#C60E02'
                                  : password.length >= 8 && confirmPass !== ''
                                    ? '#169D00'
                                    : '',
                              borderWeight: 1,
                              borderstyle: 'solid',
                              maxWidth: 305,
                            }}
                          />
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            disabled && (
                              <p
                                style={{
                                  color: '#C60E02',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas no coinciden
                              </p>
                            )}
                          {password !== '' &&
                            password.length >= 8 &&
                            confirmPass !== '' &&
                            !disabled && (
                              <p
                                style={{
                                  color: '#169D00',
                                  fontSize: 18,
                                  margin: 0,
                                  padding: 0,
                                  marginTop: 5,
                                  fontFamily: 'neue-montreal-regular',
                                }}
                              >
                                *Contraseñas coinciden
                              </p>
                            )}
                        </div>
                      </div>
                      <InlineContainer
                        style={{ marginTop: 25, alignItems: 'center' }}
                      >
                        <ButtonPrime
                          label="Guardar cambios"
                          disabled={disabled}
                          maxWidth="198px"
                          onClick={() => onSubmitPass()}
                        />
                        <ButtonPrime
                          label="Cancelar"
                          background="transparent"
                          color="#3C4549"
                          border="1px solid #3C4549"
                          minWidth="127"
                          style={{ marginLeft: 20 }}
                          onClick={() => setEditPass(false)}
                        />
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              {!editInfo && (
                <div className="col-md-4">
                  <Card padding="0px" border="none" minWidth="340px">
                    <HeaderCard>
                      <LabelNormal
                        color="#3C4549"
                        fontSize="17"
                        fontFamily="medium"
                      >
                        Resumen
                      </LabelNormal>
                    </HeaderCard>
                    {!loadingSkeleton ? (
                      <CardContainer>
                        <LabelNormal
                          fontSize="17"
                          color="#828282"
                          style={{ margin: 5 }}
                        >
                          Departamento
                        </LabelNormal>
                        <InlineContainerWrap>
                          <Chip background="#757575">
                            <LabelNormal
                              fontSize="17"
                              color="#FFF"
                              style={{ margin: 5 }}
                            >
                              {departament.name ? departament.name : ''}
                            </LabelNormal>
                          </Chip>
                        </InlineContainerWrap>
                        <div style={{ marginTop: 10 }}>
                          <LabelNormal
                            fontSize="17"
                            color="#828282"
                            style={{ margin: 5 }}
                          >
                            Permisos
                          </LabelNormal>
                          <InlineContainerWrap>
                            {permissions.map((perm, index) => {
                              if (!enabledPermissions) {
                                if (index < 3) {
                                  return (
                                    <Chip
                                      background="#F6F6F8"
                                      key={Math.random()}
                                    >
                                      <LabelNormal
                                        fontSize="17"
                                        color="#828282"
                                        style={{ margin: 5 }}
                                      >
                                        {perm.name}
                                      </LabelNormal>
                                    </Chip>
                                  );
                                }
                              } else {
                                return (
                                  <Chip
                                    background="#FBFBFC"
                                    key={Math.random()}
                                  >
                                    <LabelNormal
                                      fontSize="17"
                                      color="#828282"
                                      style={{ margin: 5 }}
                                    >
                                      {perm.name}
                                    </LabelNormal>
                                  </Chip>
                                );
                              }
                            })}
                            {!enabledPermissions && (
                              <ChipCircle
                                onClick={() => setEnabledPermissions(true)}
                              >
                                <LabelNormal fontSize="17" color="#FFF">
                                  +{permissions.length - 3}
                                </LabelNormal>
                              </ChipCircle>
                            )}
                            {enabledPermissions && (
                              <ChipCircle
                                onClick={() => setEnabledPermissions(false)}
                              >
                                <LabelNormal fontSize="17" color="#FFF">
                                  -
                                </LabelNormal>
                              </ChipCircle>
                            )}
                          </InlineContainerWrap>
                        </div>
                      </CardContainer>
                    ) : (
                      <div className="row" style={{ height: 345 }}>
                        <div className="col-md-12">
                          <Skeleton
                            baseColor={'#F6F6F8'}
                            height={'100%'}
                            style={{
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                            }}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      </div>
                    )}
                  </Card>
                  {!loadingSkeleton && (
                    <div
                      className="col-md-12"
                      style={{
                        marginTop: 59,
                        marginBottom: 59,
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <CloseSession onClick={() => props.logout()} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(action.logout()),
});

export default connect(null, mapDispatchToProps)(Register);
