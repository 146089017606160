import { ChangeEvent, MutableRefObject, useRef, useState } from 'react';
import InfoModal from '@components/case-details/modals/info-modal';
import { modal } from '@components/ModalManagement';
import React, { useMutation } from '@apollo/client';
import {
  MUTATION_MARK_AS_BRACES_PLACED,
  MUTATION_MARK_AS_PRINTING_READY,
  MUTATION_MARK_AS_REFINED_READY,
  MUTATION_MARK_AS_THERMOFORMING_READY,
  MUTATION_REQUEST_MORE_SMARTCHECKS,
  MUTATION_SEND_APPROVEMENT_REQUEST,
} from '@graphql/mutations/admin/case-details.mutations';
import Smartcheck, { SmartcheckEnum } from '@models/smartcheck';
import Treatment from '@models/treatment';
import { TreatmentStatusEnum } from '@models/treatment-status';
import { UploadFileThroughGraphQL, downloadFile } from '@utils/functions';
import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { CaseDetails } from '@acueductostudio/borgatta-components';
import { UserRoleEnum } from '@models/user-role';
import { useHistory } from 'react-router-dom';

interface AdminFunctionsProps {
  pendingMessages: number;
  treatment: Treatment;
  refresh: () => void;
}

interface UploadManufacturingFilesEventProps {
  event: ChangeEvent<HTMLInputElement>;
  treatment: Treatment;
  // eslint-disable-next-line no-unused-vars
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
  // eslint-disable-next-line no-unused-vars
  onResponse: (response: AxiosResponse<any, any>) => void;
  refresh: () => void;
}

export const UploadManufacturingFilesEvent = async ({
  event,
  treatment,
  onUploadProgress,
  onResponse,
  refresh,
}: UploadManufacturingFilesEventProps) => {
  if (event.target.files?.length && event.target.files?.length > 0) {
    await UploadFileThroughGraphQL({
      query: `
        mutation addTreatmentStl($treatment_id: ID!, $file: Upload!) {
          addTreatmentStl(input: { 
            treatment_id: $treatment_id, 
            file: $file 
          }) {
            id
          }
        }
      `,
      variables: {
        file: 'file',
        treatment_id: treatment.id,
      },
      file: event.target.files[0],
      onUploadProgress,
      onResponse: (response) => {
        if (response.data.data.addTreatmentStl) {
          onResponse(response);
          modal.open(
            <InfoModal
              modal={modal}
              onClose={refresh}
              title="Archivos subidos a portal"
              body="Caso enviado a impresión. Puedes cambiar el archivo desde el perfil del caso."
            />
          );
        }
      },
    });
  }
};

const AdminFunctions = ({
  pendingMessages,
  treatment,
  refresh,
}: AdminFunctionsProps) => {
  const statusId: number = treatment.treatmentStatus.id;
  const lastSmartcheck: Smartcheck | null =
    treatment.smartChecks
      ?.filter((smartChecks) => smartChecks.status !== SmartcheckEnum.NotAssist)
      .sort((a, b) => a.id - b.id)[0] ?? null;
  const refInput = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [progressUpload, setProgressUpload] = useState<number>(0);
  /**
   * Admin mutations
   */
  // Request n smartcheck
  // eslint-disable-next-line no-unused-vars
  const [changeMoreSmartchecks] = useMutation(
    MUTATION_REQUEST_MORE_SMARTCHECKS,
    {
      onCompleted: () => {
        modal.open(
          <InfoModal
            modal={modal}
            onClose={refresh}
            title="Segundo Smart check solicitado con éxito"
            body="Espera a que el doctor agende su segundo Smart Check"
          />
        );
      },
    }
  );
  // Send planning for approvement
  // eslint-disable-next-line no-unused-vars
  const [planningSendReady] = useMutation(MUTATION_SEND_APPROVEMENT_REQUEST, {
    onCompleted: () => {
      modal.open(
        <InfoModal
          modal={modal}
          onClose={() => history.goBack()}
          title="El caso está listo para aprobarse"
          body="Espera a que el doctor apruebe el caso para iniciar la manufactura."
        />
      );
    },
  });
  // Mark as printing ready
  const [printingReady] = useMutation(MUTATION_MARK_AS_PRINTING_READY, {
    onCompleted: () => {
      modal.open(
        <InfoModal
          modal={modal}
          onClose={() => history.go(0)}
          title="Impresiones terminadas"
          body="Impresiones terminadas."
        />
      );
    },
  });

  // Mark as thermoforming ready
  const [thermoformingReady] = useMutation(
    MUTATION_MARK_AS_THERMOFORMING_READY,
    {
      onCompleted: () => {
        modal.open(
          <InfoModal
            modal={modal}
            onClose={refresh}
            title="Termoformado listo"
            body="Termoformado listo."
          />
        );
      },
    }
  );
  // Mark as detailed ready
  const [refinedReady] = useMutation(MUTATION_MARK_AS_REFINED_READY, {
    onCompleted: () => {
      modal.open(
        <InfoModal
          modal={modal}
          onClose={refresh}
          title="Detallado listo"
          body="Detallado listo."
        />
      );
    },
  });
  // Mark as brackeds placed
  const [bracesPlaced] = useMutation(MUTATION_MARK_AS_BRACES_PLACED, {
    onCompleted: () => {
      modal.open(
        <InfoModal
          modal={modal}
          onClose={refresh}
          title="Brackets colocados"
          body="Brackets colocados"
        />
      );
    },
  });

  const resolveClickFunction = () => {
    if (pendingMessages > 0) {
      return history.push(
        `/app/tablero/soporte/${treatment.patient.id}/${treatment.id}`
      );
    }
    if (
      TreatmentStatusEnum.ReviewingFiles === statusId ||
      TreatmentStatusEnum.SolvedError === statusId
    ) {
      return history.push(`/app/tablero/caso/revision/${treatment.id}`);
    }
    if (TreatmentStatusEnum.ReadyToPlanning === statusId) {
      return history.push(`/app/tablero/caso/planificacion/${treatment.id}`);
    }
    if (
      TreatmentStatusEnum.ReadyVisor === statusId ||
      TreatmentStatusEnum.WaitingPlanning === statusId ||
      TreatmentStatusEnum.DoubtsResolved === statusId
    ) {
      return history.push(
        `/app/tablero/revision-planificacion/${treatment.id}`
      );
    }
    if (TreatmentStatusEnum.DaySmartcheck === statusId && lastSmartcheck) {
      return window.open(lastSmartcheck?.zoom_link, '_blank');
    }
    if (TreatmentStatusEnum.EndedSmartcheck === statusId) {
      return history.push(
        `/app/tablero/revision-planificacion/${treatment.id}`
      );
    }
    if (TreatmentStatusEnum.ApprovedManufacture === statusId) {
      console.log('Uploading files');
      return refInput?.current?.click();
    }
    if (TreatmentStatusEnum.ReadyToPrinting === statusId) {
      return downloadFile(
        `${process.env.REACT_APP_STL_URL}${treatment.id}`,
        'stl-zip.zip',
        refresh
      );
    }
    if (TreatmentStatusEnum.Printing === statusId) {
      return printingReady({
        variables: { treatment_id: treatment.id },
      });
    }
    if (TreatmentStatusEnum.Thermoforming === statusId) {
      return thermoformingReady({
        variables: { treatment_id: treatment.id },
      });
    }
    if (TreatmentStatusEnum.Warehouse === statusId) {
      return refinedReady({
        variables: { treatment_id: treatment.id },
      });
    }
    if (TreatmentStatusEnum.Packing === statusId) {
      return bracesPlaced({
        variables: { treatment_id: treatment.id },
      });
    }
    if (TreatmentStatusEnum.ReadyToShipping === statusId) {
      return history.push(`/app/tablero/envio-tratamiento/${treatment.id}`);
    }
    return null;
  };
  return (
    <>
      <CaseDetails
        rounded="2xl"
        viewer={UserRoleEnum.ADMIN}
        treatment={treatment}
        pendingMessages={pendingMessages}
        pendingPayment={false}
        onClick={resolveClickFunction}
      />
      <input
        type="file"
        accept=".zip"
        style={{ display: 'none' }}
        ref={refInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          UploadManufacturingFilesEvent({
            event,
            treatment,
            onUploadProgress: (progressEvent) => {
              let progress = Math.round(
                (100 * progressEvent.loaded) / (progressEvent?.total ?? 1)
              );
              setProgressUpload(progress);
            },
            onResponse: (response) => {
              if (response.data.data.addTreatmentStl) {
                setProgressUpload(0);
              }
            },
            refresh,
          })
        }
      />
    </>
  );
};

export default AdminFunctions;
