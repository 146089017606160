import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Checkbox from 'react-custom-checkbox';
import ButtonPrime from '@components/shared/ButtonPrime';
import LabelUnderline from '../../components/shared/LabelUnderline';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  MUTATION_CHANGE_DEFAULT_TAX_DATA,
  MUTATION_CHANGE_DEFAULT_ADDRESS,
  MUTATION_CHANGE_DEFAULT_CARD,
  MUTATION_PAY_MANUFACTURE_TREATMENT,
} from '@graphql/mutation';
import {
  QUERY_GET_DETAILS_DOCTOR,
  QUERY_GET_DEFAULT_TAX_DATA,
  QUERY_GET_DEFAULT_PAYMENT,
  QUERY_GET_PRICE_BY_TYPE,
  QUERY_GET_PACK,
  QUERY_GET_PAYMENT_INSTALLMENTS,
  QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
} from '@graphql/query';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ModalFormTaxData from '@components/Modal/ModalFormTaxData';
import ModalFormAddress from '@components/Modal/ModalFormAddress';
import ModalFormCard from '@components/Modal/ModalFormCard';
import { modal } from '@components/ModalManagement';
import ModalWaiting from '@components/Modal/ModalWaiting';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import ModalError from '../../components/Modal/ModalError';
import TransferInfo from '../../components/CaseForm/TransferInfo';
import Warning from '../../components/CaseForm/Warning';
import {
  Column,
  LabelDescription,
  LabelOrange,
  Row,
} from '../../components/shared/Styled';
import Check from '../../components/Icons/Check';
import TaxDataCard from '../../components/Doctor/TaxDataCard';
import AddressCard from '../../components/Doctor/AddressCard';
import PackCard from '../../components/CaseForm/PackCard';
import CouponForm from '@components/CouponForm/CouponForm';
import { PAYMENT_METHOD, TREATMENT } from '../../constants';
import { QUERY_GET_TREATMENT } from '@graphql/queries/doctor/approve-treatment';

const ApproveCase = () => {
  const history = useHistory();
  let { treatment_id } = useParams();
  const role = localStorage.getItem('b360-role');

  const [treatment, setTreatment] = useState();

  const [changePayment, setChangePayment] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeCard, setChangeCard] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [transferSend, setTransferSend] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [transferError, setTransferError] = useState(false);
  const [errorOnApprove, setErrorOnApprove] = useState(false);
  const [hasChangesCA, setHasChangesCA] = useState(false);
  const [bankName, setBankName] = useState('');
  const [clabe, setClabe] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [alignersNumber, setAlignersNumber] = useState(0);
  const [bottomAlignersNumber, setBottomAlignersNumber] = useState(0);
  const [totalAligners, setTotalAligners] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(
    PAYMENT_METHOD.PAYMENT_LINE
  );

  const [typePrice, setTypePrice] = useState({});
  const [originalTypePrice, setOriginalTypePrice] = useState({});
  const [total, setTotal] = useState(0);
  const [packs, setPacks] = useState([]);
  const [selectInstallment, setSelectInstallment] = useState({});
  const [selectPack, setSelectPack] = useState({});
  const [paymentInstallments, setPaymentInstallments] = useState({});
  const [doctorData, setDoctorData] = useState({});
  const [defaultAddressDoctor, setDefaultAddressDoctor] = useState({});
  const [defaultTaxData, setDefaultTaxData] = useState({});
  const [paymentTaxData, setPaymentTaxData] = useState(null);
  const [defaultPayment, setDefaultPayment] = useState({});
  const [couponId, setCouponId] = useState(null);
  const [treatmentType, setTreatmentType] = useState('');
  const [partialPaid, setPartialPaid] = useState(false);
  const [showAddressError, setShowAddressError] = useState(false);
  const [showCardError, setShowCardError] = useState(false);
  const [cards, setCards] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [activePayment, setActivePayment] = useState(null);
  const [resetCoupon, setResetCoupon] = useState(false);
  const [firstPaymentInstallment, setFirstPaymentInstallment] = useState(0);
  const [loadingPaymentInstallment, setLoadingPaymentInstallment] =
    useState(false);

  const totalPayment = total - discount;

  const { loadingSkeleton } = useQuery(QUERY_GET_TREATMENT, {
    variables: {
      id: treatment_id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.treatment) {
        setTreatment(data.treatment);
        setAlignersNumber(parseInt(data.treatment.aligners_number));
        setBottomAlignersNumber(
          parseInt(data.treatment.bottom_aligners_number)
        );
        setTreatmentType(data.treatment.patient.treatment);
        setHasChangesCA(data.treatment.has_changes_ca);
        const manufactPayment = data.treatment.payments?.find(
          (payment) =>
            payment.type === 'manufact' && payment.status !== 'cancel'
        );

        if (manufactPayment) {
          setActivePayment(manufactPayment);
          const discount =
            Number(manufactPayment.history_amount) - manufactPayment.amount;
          setDiscount(discount);
          setTotal(Number(manufactPayment.history_amount));
          setFirstPaymentInstallment(
            Number(manufactPayment.history_amount) - discount
          );
          setPaymentMethod(PAYMENT_METHOD.PAYMENT_TRANSFER);
          setInvoice(manufactPayment.invoiced);
          setTransferSend(true);
          setBankName(manufactPayment.bank_name);
          setClabe(manufactPayment.clabe);
          setReferenceCode(manufactPayment.reference);
          setPaymentTaxData(manufactPayment.taxdata);
          setPartialPaid(manufactPayment.invoices?.length > 0 ?? false);
          setSelectPack(data.treatment?.manufact?.pack ?? {});
        }
      }
    },
  });

  const getDoctorCards = async () => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('b360-token')}`,
      },
    });
    const response = await instance.get(
      `/api/cards/get_all/${treatment?.doctor.id}/${treatment?.treatmentType.id}`
    );
    const data = response.data;
    setCards(data.cards);
    const defaultCard = data?.cards?.find((card) => card.default === 1) ?? null;
    if (defaultCard) {
      setDefaultPayment(defaultCard);
    }
  };

  const [getPaymentInstallments] = useLazyQuery(
    QUERY_GET_PAYMENT_INSTALLMENTS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setLoadingPaymentInstallment(false);
        if (data.getPaymentInstallments) {
          if (
            discount > 0 &&
            data.getPaymentInstallments.installments.length > 0
          ) {
            setFirstPaymentInstallment(originalTypePrice.amount);
          } else {
            setPaymentInstallments(data.getPaymentInstallments);
            if (data.getPaymentInstallments.installments.length === 0) {
              setSelectInstallment({});
            }
          }
        }
      },
    }
  );

  const [getDoctorDetails] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        setDoctorData(data.doctor);
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
        getDefaultAddressDoctor({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
      }
    },
  });

  useEffect(() => {
    if (defaultAddressDoctor.name) {
      setShowAddressError(false);
    }
  }, [defaultAddressDoctor]);

  const [getDefaultTaxData] = useLazyQuery(QUERY_GET_DEFAULT_TAX_DATA, {
    onCompleted: (data) => {
      if (data.getDefaultTaxData) {
        setDefaultTaxData(data.getDefaultTaxData);
      }
    },
    onError: (err) => console.log(err, 'error taxdata'),
  });

  const [getDefaultAddressDoctor] = useLazyQuery(
    QUERY_GET_DEFAULT_ADDRESS_DOCTOR,
    {
      onCompleted: (data) => {
        if (data.getDefaultAddress) {
          setDefaultAddressDoctor(data.getDefaultAddress);
        }
      },
      onError: (err) => console.log(err, 'error default address doctor'),
    }
  );

  const [getDefaultMethodPayment] = useLazyQuery(QUERY_GET_DEFAULT_PAYMENT, {
    onCompleted: (data) => {
      if (data.getDefaultPaymentMethod) {
        setDefaultPayment(data.getDefaultPaymentMethod);
        const defaultPaymentMethod = data.getDefaultPaymentMethod;
        if (defaultPaymentMethod && typePrice) {
          getPaymentInstallments({
            variables: {
              user_id: localStorage.getItem('b360-id'),
              treatment_id: treatment_id,
              payment_method_ca: defaultPaymentMethod.service_id_ca,
              payment_method_ss: defaultPaymentMethod.service_id_ss,
              amount: Math.round(total - discount, 2),
              currency: typePrice.currency,
              treatment_type: treatmentType,
              process: 'manufacture',
            },
          });
        }
      }
    },
    onError: (err) => console.log(err, 'error method payment'),
  });

  const [getTypePrice] = useLazyQuery(QUERY_GET_PRICE_BY_TYPE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setTypePrice(data.getPriceByType);
      setOriginalTypePrice(data.getPriceByType);
      setTotal(data.getPriceByType.amount);
      setFirstPaymentInstallment(data.getPriceByType.amount);
    },
    onError: (err) => console.log('error on price', err),
  });

  const [getPack] = useLazyQuery(QUERY_GET_PACK, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setPacks(data.getPacksByType);
    },
  });

  const [changeDefaultTaxdata] = useMutation(MUTATION_CHANGE_DEFAULT_TAX_DATA, {
    onCompleted: (data) => {
      if (data.updateTaxDataDefault) {
        getDefaultTaxData({
          variables: { user_id: localStorage.getItem('b360-id') },
        });
      }
    },
  });

  const [changeDefaultAddressDoctor] = useMutation(
    MUTATION_CHANGE_DEFAULT_ADDRESS,
    {
      onCompleted: (data) => {
        if (data.updateAddressDefault) {
          getDefaultAddressDoctor({
            variables: { user_id: localStorage.getItem('b360-id') },
          });
        }
      },
    }
  );

  const [changeDefaultCard] = useMutation(MUTATION_CHANGE_DEFAULT_CARD, {
    onCompleted: (data) => {
      if (data.updatePaymentDefault) {
        // getDefaultMethodPayment({ variables: { user_id: localStorage.getItem('b360-id') } })
        setDefaultPayment(data.updatePaymentDefault);

        let cardsAux = [];
        cards.forEach((card) => {
          if (card.service_id === data.updatePaymentDefault.service_id) {
            cardsAux.push({
              ...card,
              default: true,
            });
          } else {
            cardsAux.push({
              ...card,
              default: false,
            });
          }
        });
        setDoctorData({
          ...doctorData,
          cards: cardsAux,
        });
      }
    },
  });

  const submitPayTreatment = (totalPayment) => {
    if (!defaultAddressDoctor.name) {
      setShowAddressError(true);
      return;
    }
    if (
      totalPayment > 0 &&
      paymentMethod === PAYMENT_METHOD.PAYMENT_LINE &&
      !defaultPayment.brand
    ) {
      setShowCardError(true);
      return;
    }
    setShowAddressError(false);
    setShowCardError(false);
    setErrorOnApprove(false);
    setTransferError(false);
    if (
      Object.keys(selectInstallment).length !== 0 &&
      paymentMethod === PAYMENT_METHOD.PAYMENT_LINE
    ) {
      modal.open(
        <ModalWaiting>
          <LabelNormal
            color="#060809"
            fontSize="22"
            style={{ marginBottom: 14 }}
          >
            Procesando...
          </LabelNormal>
          <LabelNormal
            color="#4F4F4F"
            fontSize="17"
            style={{ marginBottom: 14 }}
          >
            Un momento por favor...
          </LabelNormal>
        </ModalWaiting>
      );
      payManufactureTreatment({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          invoice,
          treatment_id: treatment_id,
          taxdata_id: defaultTaxData.id,
          address_id: defaultAddressDoctor.id,
          intent_id: paymentInstallments.intent_id + '',
          amount: parseFloat(originalTypePrice.amount),
          currency: originalTypePrice.currency,
          count: selectInstallment.count,
          interval: selectInstallment.interval,
          type: selectInstallment.type,
          currency_before: paymentInstallments.currency_before,
          amount_before: paymentInstallments.amount_before,
          installment_price: selectInstallment.installment_price,
          method: paymentMethod,
          coupon_id: couponId,
          pack_id: +selectPack.id,
        },
      });
    } else {
      if (paymentMethod === PAYMENT_METHOD.PAYMENT_LINE) {
        modal.open(
          <ModalWaiting>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14 }}
            >
              Procesando...
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Un momento por favor...
            </LabelNormal>
          </ModalWaiting>
        );
        payManufactureTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatment_id,
            taxdata_id: defaultTaxData.id,
            address_id: defaultAddressDoctor.id,
            intent_id: paymentInstallments.intent_id + '',
            amount: parseFloat(originalTypePrice.amount),
            currency: originalTypePrice.currency,
            currency_before: paymentInstallments.currency_before,
            amount_before: paymentInstallments.amount_before,
            method: paymentMethod,
            coupon_id: couponId,
            pack_id: selectPack.id,
          },
        });
      } else {
        setLoadingTransfer(true);
        payManufactureTreatment({
          variables: {
            user_id: localStorage.getItem('b360-id'),
            invoice,
            treatment_id: treatment_id,
            taxdata_id: defaultTaxData.id,
            address_id: defaultAddressDoctor.id,
            amount: parseFloat(originalTypePrice.amount),
            currency: originalTypePrice.currency,
            method: paymentMethod,
            coupon_id: couponId,
            pack_id: selectPack.id,
          },
        });
      }
    }
  };

  const [payManufactureTreatment] = useMutation(
    MUTATION_PAY_MANUFACTURE_TREATMENT,
    {
      onCompleted: (data) => {
        if (data.payManufactureTreatment) {
          if (
            paymentMethod === PAYMENT_METHOD.PAYMENT_LINE ||
            (totalPayment === 0 &&
              paymentMethod === PAYMENT_METHOD.PAYMENT_TRANSFER)
          ) {
            modal.close();
            let points = data.payManufactureTreatment.points;

            let tratamiento = selectPack?.name.split('+')[1];
            if (treatmentType === TREATMENT.CRYST_ALIGNER) {
              if (
                treatment?.aligners_number >= 0 &&
                treatment?.aligners_number <= 28
              ) {
                tratamiento = 'Terso';
              } else if (treatment?.aligners_number <= 50) {
                tratamiento = 'Moderado';
              } else {
                tratamiento = 'Integral';
              }
            }

            modal.open(
              <ModalSuccess
                onClick={() =>
                  modal.close(() =>
                    history.push(`/doctor/caso/${treatment_id}`)
                  )
                }
              >
                <LabelNormal
                  color="#060809"
                  fontSize="22"
                  style={{ marginBottom: 14 }}
                >
                  {discount > 0
                    ? 'Pago con código realizado y planificación aprobada'
                    : 'Planificación aprobada y pago de manufactura realizado'}
                </LabelNormal>
                <LabelNormal
                  color="#4F4F4F"
                  fontSize="17"
                  style={{ marginBottom: 14 }}
                >
                  Iniciaremos la manufactura de tus alineadores. Te
                  notificaremos cuando hayan sido enviados. Este proceso dura
                  aproximadamente 15 días hábiles.
                </LabelNormal>
                {role === 'doctor' && (
                  <div
                    style={{
                      backgroundColor: '#F6F6F8',
                      padding: '9px 15px',
                      borderRadius: 15,
                    }}
                  >
                    <LabelNormal
                      color="#757575"
                      fontSize="17"
                      style={{ marginBottom: 5 }}
                    >
                      Acumulaste puntos por el pago de manufactura de{' '}
                      <bold>{tratamiento}</bold>
                    </LabelNormal>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: 22,
                      }}
                    >
                      <LabelNormal color="#EB9542" fontSize="13">
                        PUNTOS ACUMULADOS
                      </LabelNormal>
                      <LabelNormal
                        color="#757575"
                        fontSize="17"
                        style={{ fontWeight: 500 }}
                      >
                        {points}
                      </LabelNormal>
                    </div>
                    <a a href={'/doctor/beneficios'}>
                      <ButtonPrime
                        style={{ padding: '12px 25px' }}
                        fontSize={19}
                        label="Ver puntos"
                      />
                    </a>
                  </div>
                )}
              </ModalSuccess>
            );
          } else {
            setLoadingTransfer(false);
            setTransferSend(true);
            if (
              data.payManufactureTreatment.bank_code !== '' &&
              data.payManufactureTreatment.bank_name !== '' &&
              data.payManufactureTreatment.clabe !== ''
            ) {
              setBankName(data.payManufactureTreatment.bank_name);
              setClabe(data.payManufactureTreatment.clabe);
              setReferenceCode(data.payManufactureTreatment.referenceCode);
            } else {
              setTransferError(true);
            }
          }
        }
      },
      onError: ({ graphQLErrors, networkError }) => {
        let errorMessage = '';
        if (graphQLErrors?.length > 0) {
          errorMessage = graphQLErrors[0].message;
        } else if (networkError)
          errorMessage =
            'Lo sentimos, no se puede procesar la transacción debido a una falla en tu conexión. Por favor, verifícala e inténtalo de nuevo.';
        modal.close();
        modal.open(
          <ModalError onClick={() => modal.close()}>
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginBottom: 14, marginTop: 30, fontWeight: '600' }}
            >
              Pago no procesado
            </LabelNormal>
            <LabelNormal
              color="#4F4F4F"
              fontSize="17"
              style={{ marginBottom: 14 }}
            >
              Hubo un error al procesar tu tarjeta. Verifica tus datos e
              inténtalo de nuevo.
            </LabelNormal>
            <div
              style={{
                backgroundColor: '#FFE5E3',
                padding: '15px',
                borderRadius: 13,
                color: '#C60E02',
              }}
            >
              <p style={{ fontWeight: '600' }}>Error de sistema:</p>
              <p style={{ fontWeight: '600' }}>{errorMessage}</p>
            </div>
          </ModalError>
        );
      },
    }
  );

  const addTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const editTaxDataModal = () => {
    modal.open(
      <ModalFormTaxData
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
        taxdata={defaultTaxData}
      />
    );
  };

  const addDefaultAddressDoctorModal = () => {
    modal.open(
      <ModalFormAddress
        onFinish={() =>
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          })
        }
      />
    );
  };

  const addCardModal = () => {
    modal.open(
      <ModalFormCard
        treatmentType={treatment?.treatmentType.id}
        onFinish={() => {
          getDoctorDetails({
            variables: { id: localStorage.getItem('b360-role_id') },
          });
          getDefaultMethodPayment({
            variables: { user_id: localStorage.getItem('b360-id') },
          });
          getDoctorCards();
        }}
      />
    );
  };

  useEffect(() => {
    getDoctorDetails({
      variables: { id: localStorage.getItem('b360-role_id') },
    });
    getDefaultTaxData({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getDefaultAddressDoctor({
      variables: { user_id: localStorage.getItem('b360-id') },
    });
    getPack({ variables: { type: 'manufact_ca' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (treatment) {
      getDoctorCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment]);

  useEffect(() => {
    let selected = false;
    if (packs.length > 0 && totalAligners !== 0) {
      packs.forEach((pack) => {
        if (pack.principal_aligners) {
          if (totalAligners <= parseInt(pack.principal_aligners) && !selected) {
            getTypePrice({
              variables: { type: 'manufact_ca', pack_id: pack.id },
            });
            if (!selected) selected = true;
            setSelectPack(pack);
          }
        } else {
          if (!selected) {
            getTypePrice({
              variables: { type: 'manufact_ca', pack_id: pack.id },
            });
            if (!selected) selected = true;
            setSelectPack(pack);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packs, totalAligners]);

  useEffect(() => {
    if (
      Object.keys(defaultPayment).length !== 0 &&
      Object.keys(typePrice).length !== 0 &&
      !changeCard &&
      paymentMethod === PAYMENT_METHOD.PAYMENT_LINE
    ) {
      setLoadingPaymentInstallment(true);
      getPaymentInstallments({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          treatment_id: treatment_id,
          payment_method_ca: defaultPayment.service_id_ca,
          payment_method_ss: defaultPayment.service_id_ss,
          amount: parseFloat(typePrice.amount),
          currency: typePrice.currency,
          treatment_type: treatmentType,
          process: 'manufacture',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPayment, typePrice, changeCard]);

  useEffect(() => {
    if (Object.keys(typePrice).length !== 0) {
      getDefaultMethodPayment({
        variables: { user_id: localStorage.getItem('b360-id') },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typePrice]);

  useEffect(() => {
    if (Object.keys(selectPack).length > 0) {
      getTypePrice({
        variables: { type: 'manufact_ca', pack_id: selectPack.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPack]);

  useEffect(() => {
    if (
      !changeCard &&
      defaultPayment &&
      Object.keys(defaultPayment).length > 0 &&
      typePrice &&
      Object.keys(typePrice).length > 0
    ) {
      setLoadingPaymentInstallment(true);
      getPaymentInstallments({
        variables: {
          user_id: localStorage.getItem('b360-id'),
          payment_method_ca: defaultPayment.service_id_ca,
          payment_method_ss: defaultPayment.service_id_ss,
          amount: Math.round(total - discount, 2),
          currency: typePrice.currency,
          treatment_type: treatmentType,
          process: 'manufacture',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCard]);

  useEffect(() => {
    setTotalAligners(alignersNumber + bottomAlignersNumber);
  }, [alignersNumber, bottomAlignersNumber]);

  const resetVoucherCode = () => {
    if (selectPack !== null) {
      getTypePrice({
        variables: { type: 'manufact_ss', pack_id: +selectPack?.id },
      });
    }
    if (treatmentType === TREATMENT.CRYST_ALIGNER) {
      if (selectPack !== null) {
        getTypePrice({
          variables: { type: 'manufact_ca', pack_id: +selectPack?.id },
        });
      }
    }
  };

  const resetCouponForm = () => {
    setResetCoupon(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => {
                history.goBack();
              }}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          {!changeCard && !changePayment && !changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <ScrollableBody style={{ width: '100%', overflowX: 'hidden' }}>
                  <div className="row">
                    {!loadingSkeleton && (
                      <>
                        <div className="col-md-7">
                          <Title style={{ marginBottom: 5 }}>
                            Pago de Manufactura
                          </Title>
                          <div style={{ marginTop: 10, marginBottom: 20 }}>
                            <LabelNormal color="#757575" fontSize="17">
                              Confirma tus datos de pago y aprueba tu caso
                            </LabelNormal>
                          </div>
                          <InlineContainer style={{ marginBottom: 10 }}>
                            <LabelNormal color="#436FF1" fontSize="15">
                              PX
                            </LabelNormal>
                            <LabelNormal color="#3C4549" fontSize="22">{`${
                              treatment?.patient.first_name ?? ''
                            } ${
                              treatment?.patient.last_name ?? ''
                            }`}</LabelNormal>
                          </InlineContainer>
                          <InlineContainer style={{ marginBottom: 18 }}>
                            <ChipCategory>
                              <LabelNormal color="#060809" fontSize="13">
                                Cryst Aligner
                              </LabelNormal>
                            </ChipCategory>
                          </InlineContainer>
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            Selecciona tu paquete de alineadores. Si en el
                            <br /> futuro requieres más alineadores de
                            <br /> refinamiento, podrás pedir más paquetes.
                          </LabelNormal>
                          {totalPayment > 0 && (
                            <Checkbox
                              checked={invoice}
                              icon={
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#EB9542"
                                  size="sm"
                                />
                              }
                              disabled={totalPayment === 0}
                              borderColor="#C8C8C8"
                              style={{ overflow: 'hidden' }}
                              size={20}
                              onChange={(value) => setInvoice(value)}
                              labelStyle={{
                                marginLeft: 5,
                                userSelect: 'none',
                                fontFamily: 'neue-montreal-regular',
                                color: total === 0 ? '#3C454966' : '#3C4549',
                              }}
                              label="Facturar pago"
                            />
                          )}

                          {totalPayment === 0 && (
                            <LabelNormal
                              color="#9E9E9E"
                              fontSize="17"
                              style={{
                                marginLeft: 0,
                                marginTop: 10,
                              }}
                            >
                              En pagos con descuento del 100% no se emiten
                              facturas
                            </LabelNormal>
                          )}
                          {invoice && (
                            <div style={{ marginTop: 29 }}>
                              <LabelNormal color="#3C4549" fontSize="22">
                                Información de facturación
                              </LabelNormal>
                              {paymentTaxData === null &&
                                defaultTaxData.business_name && (
                                  <TaxDataCard
                                    taxData={defaultTaxData}
                                    onClick={() =>
                                      editTaxDataModal(defaultTaxData)
                                    }
                                  />
                                )}
                              {paymentTaxData !== null && (
                                <TaxDataCard
                                  taxData={paymentTaxData}
                                  onClick={() =>
                                    editTaxDataModal(paymentTaxData)
                                  }
                                />
                              )}
                              <LabelUnderline
                                color="#757575"
                                fontSize="17"
                                onClick={() =>
                                  defaultTaxData.business_name
                                    ? setChangePayment(true)
                                    : addTaxDataModal()
                                }
                                label={
                                  defaultTaxData.business_name
                                    ? 'Cambiar perfil fiscal'
                                    : 'Añadir perfil fiscal'
                                }
                              />
                            </div>
                          )}
                          <div style={{ marginTop: 20 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Información de envío
                            </LabelNormal>
                            {defaultAddressDoctor.name && (
                              <AddressCard address={defaultAddressDoctor} />
                            )}
                            <LabelUnderline
                              color="#757575"
                              fontSize="17"
                              onClick={() =>
                                defaultAddressDoctor.name
                                  ? setChangeAddress(true)
                                  : addDefaultAddressDoctorModal()
                              }
                              label={
                                defaultAddressDoctor.name
                                  ? 'Cambiar dirección de envío'
                                  : 'Añadir dirección de envío'
                              }
                            />
                            {!defaultAddressDoctor.name && showAddressError && (
                              <LabelNormal
                                color="#C60E02"
                                fontSize="17"
                                style={{ marginTop: 10 }}
                              >
                                *Agrega una dirección de envío
                              </LabelNormal>
                            )}
                          </div>
                          <div style={{ marginTop: 46 }}>
                            <LabelNormal color="#3C4549" fontSize="22">
                              Método de pago
                            </LabelNormal>
                            <LabelNormal color="#757575" fontSize="17">
                              Selecciona un método de pago
                            </LabelNormal>
                            <div style={{ marginTop: 15 }}>
                              <InlineContainer>
                                <div
                                  className="radio"
                                  style={{ marginRight: 20 }}
                                >
                                  <input
                                    id="payment-1"
                                    name="payment"
                                    onClick={() => {
                                      setPaymentMethod(
                                        PAYMENT_METHOD.PAYMENT_LINE
                                      );
                                      setSelectInstallment({});
                                      resetCouponForm();
                                    }}
                                    checked={
                                      paymentMethod ===
                                      PAYMENT_METHOD.PAYMENT_LINE
                                        ? 'checked'
                                        : false
                                    }
                                    type="radio"
                                    onChange={() => {}}
                                  />
                                  <label
                                    htmlFor="payment-1"
                                    className="radio-label"
                                  >
                                    Pago con tarjeta
                                  </label>
                                </div>
                                <div
                                  className="radio"
                                  style={{
                                    marginRight: 20,
                                  }}
                                >
                                  <input
                                    id="payment-2"
                                    name="payment"
                                    onClick={() => {
                                      setPaymentMethod(
                                        PAYMENT_METHOD.PAYMENT_TRANSFER
                                      );
                                      resetCouponForm();
                                      resetVoucherCode();
                                      setTotal(typePrice.amount);
                                    }}
                                    checked={
                                      paymentMethod ===
                                      PAYMENT_METHOD.PAYMENT_TRANSFER
                                        ? 'checked'
                                        : false
                                    }
                                    type="radio"
                                    onChange={() => {}}
                                  />
                                  <label
                                    htmlFor="payment-2"
                                    className="radio-label"
                                  >
                                    Pago por transferencia
                                  </label>
                                </div>
                              </InlineContainer>
                            </div>
                          </div>
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_LINE && (
                            <div
                              style={{
                                marginTop: 29,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                              }}
                            >
                              <LabelNormal color="#3C4549" fontSize="22">
                                Cobrado a
                              </LabelNormal>
                              {totalPayment === 0 ? (
                                <LabelNormal color="#9E9E9E" fontSize="17">
                                  En pagos sin importe no requieres de un método
                                  de pago.
                                </LabelNormal>
                              ) : (
                                <>
                                  {defaultPayment.brand && (
                                    <CardInlineContainer
                                      style={{
                                        borderRadius: 10,
                                        minHeight: 71,
                                        maxWidth: 376,
                                        marginTop: 10,
                                        marginBottom: 10,
                                        padding: 16,
                                        minWidth: 338,
                                      }}
                                      key={Math.random()}
                                    >
                                      <InlineContainer>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 38,
                                            width: 50,
                                            background: '#FAFAFB',
                                          }}
                                        >
                                          <img
                                            alt="card"
                                            src={
                                              defaultPayment.brand ===
                                              'mastercard'
                                                ? require('@images/icon-cc/mastercard-transparent.png')
                                                : defaultPayment.brand ===
                                                  'visa'
                                                ? require('@images/icon-cc/visa-transparent.png')
                                                : defaultPayment.brand ===
                                                  'amex'
                                                ? require('@images/icon-cc/amex.jpg')
                                                : null
                                            }
                                            style={{
                                              height: 'auto',
                                              width: 40,
                                            }}
                                          />
                                        </div>
                                        <LabelNormal
                                          color="#3C4549"
                                          fontSize="18"
                                        >
                                          *
                                          {defaultPayment.last_digits
                                            ? defaultPayment.last_digits
                                            : ''}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </CardInlineContainer>
                                  )}
                                  {!loadingPaymentInstallment &&
                                    paymentInstallments.installments && (
                                      <>
                                        <CardInlineContainer
                                          style={{
                                            minHeight: 71,
                                            maxWidth: 376,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            padding: 16,
                                            minWidth: 338,
                                            borderTopRightRadius: 10,
                                            borderTopLeftRadius: 10,
                                            borderBottomRightRadius: 0,
                                            borderBottomLeftRadius: 0,
                                          }}
                                          key={Math.random()}
                                        >
                                          <InlineContainer>
                                            <div
                                              className="radio"
                                              style={{ marginRight: 8 }}
                                            >
                                              <input
                                                id="installments-0"
                                                name="installments"
                                                onClick={() => {
                                                  setSelectInstallment({});
                                                  setTotal(typePrice.amount);
                                                }}
                                                checked={
                                                  Object.keys(selectInstallment)
                                                    .length !== 0
                                                    ? false
                                                    : 'checked'
                                                }
                                                type="radio"
                                                onChange={() => {}}
                                              />
                                              <label
                                                htmlFor="installments-0"
                                                className="radio-label"
                                              ></label>
                                            </div>
                                            <LabelNormal
                                              color="#3C4549"
                                              fontSize="17"
                                            >{`1x ${firstPaymentInstallment.toFixed(
                                              2
                                            )} ${
                                              originalTypePrice.currency
                                            }`}</LabelNormal>
                                          </InlineContainer>
                                        </CardInlineContainer>
                                        {paymentInstallments.installments.map(
                                          (item, index) => {
                                            if (item.count < 9) {
                                              return (
                                                <CardInlineContainer
                                                  style={{
                                                    minHeight: 71,
                                                    maxWidth: 376,
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    padding: 16,
                                                    minWidth: 338,
                                                    borderTopRightRadius:
                                                      index + 1 === 0 ? 10 : 0,
                                                    borderTopLeftRadius:
                                                      index + 1 === 0 ? 10 : 0,
                                                    borderBottomRightRadius:
                                                      index + 1 ===
                                                      paymentInstallments
                                                        .installments.length -
                                                        1
                                                        ? 10
                                                        : 0,
                                                    borderBottomLeftRadius:
                                                      index + 1 ===
                                                      paymentInstallments
                                                        .installments.length -
                                                        1
                                                        ? 10
                                                        : 0,
                                                  }}
                                                  key={Math.random()}
                                                >
                                                  <InlineContainer
                                                    key={Math.random()}
                                                  >
                                                    <div
                                                      className="radio"
                                                      style={{ marginRight: 8 }}
                                                    >
                                                      <input
                                                        id={`installments-${
                                                          index + 1
                                                        }`}
                                                        name="installments"
                                                        onClick={() => {
                                                          setSelectInstallment({
                                                            count: item.count,
                                                            interval:
                                                              item.interval,
                                                            type: item.type,
                                                            installment_price:
                                                              item.price,
                                                          });
                                                          setTotal(
                                                            parseFloat(
                                                              item.price
                                                                .replace(
                                                                  '$',
                                                                  ''
                                                                )
                                                                .replace(
                                                                  /[USD||MXN]/g,
                                                                  ''
                                                                )
                                                            )
                                                          );
                                                        }}
                                                        checked={
                                                          selectInstallment.count !==
                                                          item.count
                                                            ? false
                                                            : 'checked'
                                                        }
                                                        type="radio"
                                                        onChange={() => {}}
                                                      />
                                                      <label
                                                        htmlFor={`installments-${
                                                          index + 1
                                                        }`}
                                                        className="radio-label"
                                                      ></label>
                                                    </div>
                                                    <LabelNormal
                                                      color="#3C4549"
                                                      fontSize="17"
                                                    >{`${item.description}`}</LabelNormal>
                                                    <LabelNormal
                                                      color="#3C4549"
                                                      fontSize="17"
                                                      style={{
                                                        paddingLeft: 10,
                                                        color: '#757575',
                                                      }}
                                                    >
                                                      {`${item.price}`}
                                                    </LabelNormal>
                                                  </InlineContainer>
                                                </CardInlineContainer>
                                              );
                                            } else return null;
                                          }
                                        )}
                                      </>
                                    )}
                                  <LabelUnderline
                                    color="#757575"
                                    fontSize="17"
                                    onClick={() =>
                                      defaultPayment.brand
                                        ? setChangeCard(true)
                                        : addCardModal()
                                    }
                                    label={
                                      defaultPayment.brand
                                        ? 'Cambiar método de pago'
                                        : 'Añadir método de pago'
                                    }
                                  />
                                </>
                              )}
                              {paymentMethod === PAYMENT_METHOD.PAYMENT_LINE &&
                                !defaultPayment.brand &&
                                showCardError && (
                                  <LabelNormal
                                    color="#C60E02"
                                    fontSize="17"
                                    style={{ marginTop: 10 }}
                                  >
                                    *Agrega una tarjeta
                                  </LabelNormal>
                                )}
                            </div>
                          )}
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_TRANSFER &&
                            totalPayment === 0 && (
                              <div
                                style={{
                                  marginTop: 29,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 5,
                                }}
                              >
                                <LabelNormal color="#3C4549" fontSize="22">
                                  Cobrado a
                                </LabelNormal>
                                <LabelNormal color="#9E9E9E" fontSize="17">
                                  En pagos sin importe no requieres de un método
                                  de pago.
                                </LabelNormal>
                              </div>
                            )}
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_TRANSFER &&
                            totalPayment > 0 && (
                              <div className="row">
                                <div
                                  className="col-md-7"
                                  style={{ marginTop: 25, marginBottom: 100 }}
                                >
                                  <LabelNormal color="#3C4549" fontSize="22">
                                    Datos para transferencia
                                  </LabelNormal>
                                  {!loadingTransfer && (
                                    <>
                                      {!transferSend && (
                                        <LabelNormal
                                          color="#757575"
                                          fontSize="19"
                                        >
                                          Da clic en Pagar por transferencia
                                          para ver los datos bancarios
                                        </LabelNormal>
                                      )}
                                      {transferSend && !transferError && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingLeft: '5px',
                                            gap: '35px',
                                            marginTop: '15px',
                                          }}
                                        >
                                          <TransferInfo
                                            bankName={bankName}
                                            clabe={clabe}
                                            referenceCode={referenceCode}
                                            treatment="cryst_aligner"
                                          />
                                          <Warning
                                            title="En espera de pago"
                                            body={
                                              <ol
                                                style={{ paddingLeft: '25px' }}
                                              >
                                                <li>
                                                  <b>
                                                    Recuerda poner la referencia
                                                    que se indica
                                                  </b>
                                                  , de lo contrario tu pago
                                                  podría no reflejarse en el
                                                  caso.
                                                </li>
                                                <li>
                                                  <b>
                                                    Realiza tu pago lo antes
                                                    posible para activar tu
                                                    caso.
                                                  </b>{' '}
                                                  Puedes volver a esta pantalla
                                                  ingresando el perfil de caso
                                                  para corroborar los datos.
                                                </li>
                                              </ol>
                                            }
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {loadingTransfer && (
                                    <div style={{ marginTop: 25 }}>
                                      <Inline>
                                        <BorderLoader className="text-center">
                                          <div className="loader-inner line-spin-fade-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </BorderLoader>
                                      </Inline>
                                      <div
                                        className="text-center"
                                        style={{ marginTop: 20 }}
                                      >
                                        <LabelNormal
                                          color="#4F4F4F"
                                          fontSize="17"
                                          style={{ marginBottom: 14 }}
                                        >
                                          Cargando datos bancarios
                                        </LabelNormal>
                                      </div>
                                    </div>
                                  )}
                                  {transferError && (
                                    <div
                                      style={{
                                        marginTop: 45,
                                        borderRadius: 13,
                                        padding: '15px 18px',
                                      }}
                                    >
                                      <InlineContainer
                                        style={{ marginBottom: 11 }}
                                      >
                                        <img
                                          src={require('@images/icons/warning-dark.png')}
                                          style={{
                                            height: 29,
                                            width: 29,
                                            marginRight: 10,
                                            marginBottom: 50,
                                          }}
                                          alt="warning"
                                        />
                                        <LabelNormal
                                          color="#6B4D00"
                                          fontSize="17"
                                        >
                                          Lo sentimos, hubo un problema al a
                                          cargar los datos de pago por
                                          transferencia, intenta con Pago con
                                          tarjeta o inténtalo más tarde.
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                        <div
                          className="col-md-4"
                          // style={{ ...scrollY, overflowY: 'auto' }}
                        >
                          <LabelNormal
                            color="#757575"
                            fontSize="17"
                            style={{ marginBottom: 46 }}
                          >
                            Selecciona tu número de
                            <br />
                            alineadores. Tu Smart Check
                            <br /> indica que necesitas{' '}
                            <strong>{totalAligners}</strong>.
                          </LabelNormal>
                          <div
                            style={{
                              marginTop: 29,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 5,
                            }}
                          >
                            {packs.map((item, key) => {
                              return (
                                <PackCard
                                  key={`pack-${key}`}
                                  onClick={() => {
                                    setSelectPack(item);
                                    setSelectInstallment({});
                                    resetCouponForm();
                                  }}
                                  selected={selectPack}
                                  pack={item}
                                  totalAligners={totalAligners}
                                  disable={partialPaid || transferSend}
                                />
                              );
                            })}
                          </div>
                          <div style={{ marginTop: 40, marginBottom: 25 }}>
                            <LabelNormal color="#757575" fontSize="22">
                              Total
                            </LabelNormal>
                            <InlineContainer>
                              <LabelNormal color="#3C4549" fontSize="37">
                                $ {total - discount}
                              </LabelNormal>
                              <LabelNormal fontSize="24" color="#3C4549">
                                {typePrice.currency ? typePrice.currency : ''}
                              </LabelNormal>
                            </InlineContainer>
                            {discount !== 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <LabelNormal
                                  color="#BDBDBD"
                                  fontSize="13"
                                  style={{ marginBottom: 0 }}
                                >
                                  Manufactura
                                </LabelNormal>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <LabelNormal
                                    color="#828282"
                                    fontSize="23.75"
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    $ {originalTypePrice?.amount?.toFixed(2)}{' '}
                                  </LabelNormal>
                                  <LabelNormal fontSize="15.4" color="#828282">
                                    {typePrice.currency
                                      ? typePrice.currency
                                      : ''}
                                  </LabelNormal>
                                </div>
                              </div>
                            )}
                          </div>
                          {errorOnApprove && (
                            <div
                              style={{
                                marginBottom: 18,
                                background: '#FFE5E3',
                                border: '1px solid #E41D11',
                                padding: '5px 10px 7px 10px',
                                borderRadius: 7,
                                maxWidth: 290,
                              }}
                            >
                              <LabelNormal fontSize="14" color="#C60E02">
                                *Espera a que Borgatta finalice la conversación
                              </LabelNormal>
                            </div>
                          )}
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_LINE && (
                            <ButtonPrime
                              onClick={() =>
                                hasChangesCA
                                  ? setErrorOnApprove(true)
                                  : submitPayTreatment(totalPayment)
                              }
                              label="Enviar caso"
                            ></ButtonPrime>
                          )}
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_TRANSFER &&
                            totalPayment === 0 && (
                              <ButtonPrime
                                onClick={() =>
                                  hasChangesCA
                                    ? setErrorOnApprove(true)
                                    : submitPayTreatment(totalPayment)
                                }
                                label="Enviar caso"
                              ></ButtonPrime>
                            )}
                          {paymentMethod === PAYMENT_METHOD.PAYMENT_TRANSFER &&
                            totalPayment > 0 && (
                              <>
                                <ButtonPrime
                                  disabled={transferSend ? true : false}
                                  onClick={() =>
                                    hasChangesCA
                                      ? setErrorOnApprove(true)
                                      : submitPayTreatment(totalPayment)
                                  }
                                  label="Obtener datos bancarios"
                                ></ButtonPrime>
                                {transferSend ? (
                                  <Column style={{ maxWidth: '300px' }}>
                                    <Row alignItems="center" gap="10px">
                                      <Check />
                                      <LabelOrange>
                                        Pago por transferencia habilitado
                                      </LabelOrange>
                                    </Row>
                                    <LabelDescription>
                                      El caso quedará activo una vez veamos
                                      reflejado tu pago, el proceso puede tomar
                                      algunas horas.
                                    </LabelDescription>
                                  </Column>
                                ) : null}
                              </>
                            )}

                          {(treatmentType === TREATMENT.CRYST_ALIGNER ||
                            treatmentType === TREATMENT.SMILE_SYSTEM) && (
                            <CouponForm
                              reset={resetCoupon}
                              isDisabled={
                                !!activePayment?.coupon || transferSend
                              }
                              initialValues={{
                                code: activePayment?.coupon?.coupon_code,
                                hasDiscount: !!activePayment?.coupon,
                              }}
                              originalPrice={total}
                              onReset={() => {
                                setDiscount(0);
                                setCouponId(null);
                                setFirstPaymentInstallment(
                                  originalTypePrice.amount
                                );
                                setTotal(originalTypePrice.amount);
                                setSelectInstallment({});
                              }}
                              onChange={({ discount, couponId }) => {
                                const newPrice = total - discount;
                                setDiscount(discount);
                                setCouponId(couponId);

                                if (newPrice === 0) {
                                  setInvoice(false);
                                }

                                setResetCoupon(false);
                                setFirstPaymentInstallment(newPrice);
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </ScrollableBody>
              </div>
            </div>
          )}
          {changeCard && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <Title style={{ marginBottom: 5 }}>Datos de pago</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta tarjeta cargaremos el monto del tratamiento
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Tarjetas guardadas
                  </LabelNormal>
                  {cards && cards.length > 0 && (
                    <>
                      {cards.map((card, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`card-payment-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={card.default ? 'checked' : false}
                              type="radio"
                              onChange={() => {}}
                            />
                            <label
                              htmlFor={`card-payment-${index}`}
                              onClick={() =>
                                changeDefaultCard({
                                  variables: {
                                    user_id: localStorage.getItem('b360-id'),
                                    payment_method_ca: card.service_id_ca,
                                    payment_method_ss: card.service_id_ss,
                                  },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 71,
                              maxWidth: 376,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                            }}
                          >
                            <InlineContainer>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: 38,
                                  width: 50,
                                  background: '#FAFAFB',
                                }}
                              >
                                <img
                                  src={
                                    card.brand === 'mastercard'
                                      ? require('@images/icon-cc/mastercard-transparent.png')
                                      : card.brand === 'visa'
                                      ? require('@images/icon-cc/visa-transparent.png')
                                      : card.brand === 'amex'
                                      ? require('@images/icon-cc/amex-transparent.png')
                                      : null
                                  }
                                  style={{ height: 'auto', width: 35 }}
                                  alt="card"
                                />
                              </div>
                              <LabelNormal color="#757575" fontSize="17">
                                {card.brand
                                  ? card.brand.charAt(0).toUpperCase() +
                                    card.brand.slice(1)
                                  : ''}
                              </LabelNormal>
                              <LabelNormal color="#3C4549" fontSize="18">
                                ****{card.last_digits ? card.last_digits : ''}
                              </LabelNormal>
                            </InlineContainer>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addCardModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir tarjeta'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeCard(false)}
                    label="Volver al pago"
                    Total
                  />
                </div>
              </div>
            </div>
          )}
          {changePayment && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7" style={{ marginBottom: 100 }}>
                  <Title style={{ marginBottom: 5 }}>
                    Información de facturación
                  </Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      Con estos datos fiscales facturamos el pago de tus
                      tratamientos, si usas perfiles diferentes puedes
                      agregarlos todos aquí.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Datos fiscales guardados
                  </LabelNormal>
                  {doctorData.taxdatas && doctorData.taxdatas.length > 0 && (
                    <>
                      {doctorData.taxdatas.map((taxdata, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`taxdata-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                taxdata.id === defaultTaxData.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                              onChange={() => {}}
                            />
                            <label
                              htmlFor={`taxdata-${index}`}
                              onClick={() =>
                                changeDefaultTaxdata({
                                  variables: { tax_data_id: taxdata.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 204,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.business_name
                                ? taxdata.business_name
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.rfc ? taxdata.rfc : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {taxdata.street
                                ? `${taxdata.street} ${taxdata.number}, ${taxdata.suburb}, ${taxdata.postal_code} ${taxdata.state}`
                                : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#3C4549"
                              fontSize="16"
                              style={{ marginBottom: 10 }}
                            >
                              {taxdata.cfdiUsage ? taxdata.cfdiUsage.name : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addTaxDataModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir perfil fiscal'}
                  />
                  <ButtonPrime
                    onClick={() => setChangePayment(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
          {changeAddress && (
            <div className="bootstrap-wrapper">
              <div className="row">
                <div className="col-md-7" style={{ marginBottom: 100 }}>
                  <Title style={{ marginBottom: 5 }}>Dirección de Envío</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <LabelNormal color="#757575" fontSize="17">
                      A esta dirección enviaremos tu tratamiento.{' '}
                    </LabelNormal>
                  </div>
                  <LabelNormal
                    color="#454D58"
                    fontSize="22"
                    style={{ marginBottom: 30 }}
                  >
                    Direcciones guardados
                  </LabelNormal>
                  {doctorData.addresses && doctorData.addresses.length > 0 && (
                    <>
                      {doctorData.addresses.map((address, index) => (
                        <InlineContainer key={Math.random()}>
                          <div className="radio">
                            <input
                              name={`address-${index}`}
                              style={{
                                fontSize: 20,
                                padding: 5,
                                background: '#eee',
                              }}
                              checked={
                                address.id === defaultAddressDoctor.id
                                  ? 'checked'
                                  : false
                              }
                              type="radio"
                              onChange={() => {}}
                            />
                            <label
                              htmlFor={`address-${index}`}
                              onClick={() =>
                                changeDefaultAddressDoctor({
                                  variables: { address_id: address.id },
                                })
                              }
                              className="radio-label"
                            ></label>
                          </div>
                          <CardInlineContainer
                            style={{
                              borderRadius: 10,
                              minHeight: 105,
                              maxWidth: 334,
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 16,
                              marginLeft: 10,
                            }}
                          >
                            <LabelNormal
                              color="#3C4549"
                              fontSize="18"
                              style={{ marginBottom: 10 }}
                            >
                              {address.name ? address.name : ''}
                            </LabelNormal>
                            <LabelNormal
                              color="#757575"
                              fontSize="16"
                              style={{ marginBottom: 10, width: 302 }}
                            >
                              {address.street
                                ? `${address.street} ${address.number}, ${address.suburb}, ${address.postal_code} ${address.state}`
                                : ''}
                            </LabelNormal>
                          </CardInlineContainer>
                        </InlineContainer>
                      ))}
                    </>
                  )}
                  <LabelUnderline
                    color="#757575"
                    fontSize="17"
                    onClick={() => addDefaultAddressDoctorModal()}
                    style={{ marginBottom: 30 }}
                    label={'Añadir dirección de envío'}
                  />
                  <ButtonPrime
                    onClick={() => setChangeAddress(false)}
                    label="Volver al pago"
                  />
                </div>
              </div>
            </div>
          )}
        </ContainContent>
      </ContainHome>
    </div>
  );
};

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const ScrollableBody = styled.div`
  // overflow-y: auto;
  // max-height: 79vh;
  width: 100%;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const ChipCategory = styled.div`
  border: 1.15px solid #060809;
  border-radius: 51.37px;
  padding: 5px 13px 6px 13px;
  box-sizing: border-box;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const BorderLoader = styled.div`
  border-radius: 50px;
  height: 60px;
  width: 60px;
  background: #13b723;
`;

const mapStateToProps = (state) => ({
  role_id: state.auth.role_id,
});

export default connect(mapStateToProps, null)(ApproveCase);
