import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: ${({ margin }) => (margin ? margin : '5px')};
  transition: all 0.3s ease-out;
  &:hover {
    color: #436ff1;
  }
  &:active {
    color: #1249ed;
  }
`;

const LinkUnderline = (props) => {
  let name = props.name;
  let id = props.id;
  let card = props.card;
  let data = props.data;
  let margin = props.margin;
  let disabled = props.disabled;

  return (
    <>
      {data && (
        <Link
          to={{ pathname: props.link, state: { ...data } }}
          style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
        >
          <LabelNormal
            color="#757575"
            fontSize="17"
            style={{ textDecorationLine: 'underline' }}
            margin={margin}
          >
            {props.label}
          </LabelNormal>
        </Link>
      )}
      {card && (
        <Link
          to={{ pathname: props.link, state: { ...card } }}
          style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
        >
          <LabelNormal
            color="#757575"
            fontSize="17"
            style={{
              letterSpacing: props.letterSpacing,
              textDecorationLine: 'underline',
            }}
            margin={margin}
          >
            {props.label}
          </LabelNormal>
        </Link>
      )}
      {props.name && props.id && (
        <Link
          to={{ pathname: props.link, state: { name, id } }}
          style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
        >
          <LabelNormal
            color="#757575"
            fontSize="17"
            style={{ textDecorationLine: 'underline' }}
          >
            {props.label}
          </LabelNormal>
        </Link>
      )}
      {!props.name && !props.id && !props.card && !props.data && !disabled && (
        <Link
          to={{ pathname: props.link }}
          style={{ marginTop: props.marginTop ? props.marginTop : 0 }}
        >
          <LabelNormal
            color="#757575"
            fontSize="17"
            style={{
              letterSpacing: props.letterSpacing,
              textDecorationLine: 'underline',
            }}
            margin={margin}
          >
            {props.label}
          </LabelNormal>
        </Link>
      )}
      {disabled && (
        <Link style={{ marginTop: 0, pointerEvents: 'none' }}>
          <LabelNormal
            color="#BDBDBD"
            fontSize="17"
            style={{
              letterSpacing: props.letterSpacing,
              textDecorationLine: 'underline',
            }}
            margin={margin}
          >
            {props.label}
          </LabelNormal>
        </Link>
      )}
    </>
  );
};

export default LinkUnderline;
