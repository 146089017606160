import { LabelNormal } from '../shared/Styled';

const Step = ({
  index,
  title,
  currentStep,
  setSteps,
  lastStep,
  totalSteps,
}) => {
  const color =
    index === currentStep
      ? '#EB9542'
      : index < currentStep
      ? '#FFF'
      : '#828282';
  const activeClass = index < currentStep ? 'actives-circle-timeline' : '';
  const current = index === currentStep ? 'active-circle-timeline' : '';
  const lineClass =
    index !== totalSteps
      ? `clr-timeline-step-body ${index < currentStep ? 'active-step' : ''}`
      : '';

  const onClick = () => {
    if (index <= lastStep) setSteps(index);
  };

  return (
    <li className="clr-timeline-step" key={index} onClick={onClick}>
      <div className={`circle-timeline ${activeClass} ${current}`}>
        <LabelNormal color={color} fontSize="10">
          {index}
        </LabelNormal>
      </div>
      <div className={lineClass}>
        <LabelNormal
          style={{ textAlign: 'center', marginLeft: '-70%' }}
          color={index === currentStep ? '#EB9542' : '#757575'}
          fontSize="13"
        >
          {title}
        </LabelNormal>
      </div>
    </li>
  );
};

export default Step;
