import React, { Component } from 'react';
import styled from 'styled-components';

export const SpanInput = styled.span`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 135%;
  color: #3c4549;
  text-align: left;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#FFF'};
  border: 1px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : 'rgba(189, 189, 189, 0.7)'};
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 3px 0px;
  padding: 15px;
  font-size: 17px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '305px')};
  &:focus {
    outline: none;
    border: 1px solid #e3772d;
    ::placeholder {
    }
  }
`;

/*Componente input, incluye span para identificación, estilos basicos
    /-Se hace uso de componente InputPrime-/

    Descripcion de props
    --------------------
    inputLabel: Texto que se mostrara en el span del input, como descripcion,
    placeholder: Texto que se mostrara en el placeholder del Input,
    type: Configuracion del tipo de input (password, text, etc),
    name: Nombre del Input, importante para obtener sus valores en el componente padre,
    action: Acción correspondiente que se ejecutara en el evento onChange del input, es una funcion que se describe en el componente padre
*/

const InputSet = (props) => {
  return (
    <Container>
      <SpanInput>{props.inputLabel}</SpanInput>
      <Input
        placeholder={props.placeholder}
        maxWidth={props.maxWidth}
        type={props.type}
        name={props.name}
        onBlur={props.onBlur}
        {...props}
        borderColor={
          props.validation !== undefined && props.validation
            ? '#D11105'
            : 'rgba(189, 189, 189, 0.7)'
        }
      />
    </Container>
  );
};

export default InputSet;
