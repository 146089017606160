export const TREATMENT = {
  CRYST_ALIGNER: 'cryst_aligner',
  SMILE_SYSTEM: 'smile_system',
};

export const PAYMENT_METHOD = {
  PAYMENT_LINE: 'payment_line',
  PAYMENT_TRANSFER: 'payment_transfer',
};

export const ALIGNER_PACKAGE = {
  SMOOTH: 'Terso',
  MODERATE: 'Moderado',
  INTEGRAL: 'Integral',
};

export const PAYMENT_COVERAGE = {
  PLANNING: 'Planificación',
  PLANNING_MANUFACTURING: 'Planificación y manufactura',
};

export const CURRENCIES = {
  USD: 'USD',
};

export const USER_TYPE = {
  STUDENT: 'student',
};
