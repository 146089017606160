export const SmartcheckIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4.76099H17V3.76099C17 3.49577 16.8946 3.24142 16.7071 3.05388C16.5196 2.86634 16.2652 2.76099 16 2.76099C15.7348 2.76099 15.4804 2.86634 15.2929 3.05388C15.1054 3.24142 15 3.49577 15 3.76099V4.76099H9V3.76099C9 3.49577 8.89464 3.24142 8.70711 3.05388C8.51957 2.86634 8.26522 2.76099 8 2.76099C7.73478 2.76099 7.48043 2.86634 7.29289 3.05388C7.10536 3.24142 7 3.49577 7 3.76099V4.76099H5C4.20435 4.76099 3.44129 5.07706 2.87868 5.63967C2.31607 6.20228 2 6.96534 2 7.76099V19.761C2 20.5566 2.31607 21.3197 2.87868 21.8823C3.44129 22.4449 4.20435 22.761 5 22.761H19C19.7956 22.761 20.5587 22.4449 21.1213 21.8823C21.6839 21.3197 22 20.5566 22 19.761V7.76099C22 6.96534 21.6839 6.20228 21.1213 5.63967C20.5587 5.07706 19.7956 4.76099 19 4.76099ZM20 19.761C20 20.0262 19.8946 20.2806 19.7071 20.4681C19.5196 20.6556 19.2652 20.761 19 20.761H5C4.73478 20.761 4.48043 20.6556 4.29289 20.4681C4.10536 20.2806 4 20.0262 4 19.761V12.761H20V19.761ZM20 10.761H4V7.76099C4 7.49577 4.10536 7.24142 4.29289 7.05388C4.48043 6.86634 4.73478 6.76099 5 6.76099H7V7.76099C7 8.0262 7.10536 8.28056 7.29289 8.46809C7.48043 8.65563 7.73478 8.76099 8 8.76099C8.26522 8.76099 8.51957 8.65563 8.70711 8.46809C8.89464 8.28056 9 8.0262 9 7.76099V6.76099H15V7.76099C15 8.0262 15.1054 8.28056 15.2929 8.46809C15.4804 8.65563 15.7348 8.76099 16 8.76099C16.2652 8.76099 16.5196 8.65563 16.7071 8.46809C16.8946 8.28056 17 8.0262 17 7.76099V6.76099H19C19.2652 6.76099 19.5196 6.86634 19.7071 7.05388C19.8946 7.24142 20 7.49577 20 7.76099V10.761Z"
        fill={color ? color : 'rgba(78, 90, 95, 0.6)'}
      />
    </svg>
  );
};
