import React from 'react';
import FeatherIcon from 'feather-icons-react';
import Skeleton from 'react-loading-skeleton';
import ButtonPrime from '@components/shared/ButtonPrime';
import {
  CardContainer,
  HeaderCard,
  InlineContainer,
  LabelNormal,
  OverflowDiv,
} from '../shared/Styled';
import LabelUnderline from '../shared/LabelUnderline';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const Comments = ({
  role = 'doctor',
  loading,
  loadingImage = false,
  patientId,
  treatmentId,
  statusTreatment,
  comments,
  typeSTL,
  files,
  refStatusCorrections,
  sendCorrectionsTreatment = () => {},
  file,
  setSelectFile,
  reject,
  alertCommentReject,
  formCorrection,
  contactQuestion,
  reviewTreatment = () => {},
  openWarning = () => {},
  acceptDeclineForm = () => {},
  rejectFile = () => {},
  questionDoctor = () => {},
  questionDoctorForm = () => {},
  questionDoctorEdit = () => {},
  deleteQuestionForm = () => {},
  deleteQuestion = () => {},
}) => {
  const [comment, setComment] = useState('');
  const [editCorrection, setEditCorrection] = useState(false);
  const [fileEdit, setFileEdit] = useState('');
  const textAreaRef = useRef(null);
  const newCommentRef = useRef(null);

  const onClick = async () => {
    if (
      files.length === comments.length &&
      !editCorrection &&
      (contactQuestion === '' || contactQuestion === null) &&
      (formCorrection === '' ||
        formCorrection === null ||
        (statusTreatment.type === 'new_case' && statusTreatment.index === 6))
    ) {
      reviewTreatment({
        variables: { treatment_id: treatmentId },
      });
    } else if (
      ((formCorrection !== '' && formCorrection !== null) ||
        (contactQuestion !== '' && contactQuestion !== null)) &&
      files.length === comments.length &&
      !editCorrection
    ) {
      openWarning();
    } else if (file.type === 'pdf') {
      acceptDeclineForm({
        variables: {
          treatment_id: treatmentId,
          status: 'Rechazado',
          correction: comment,
        },
      });
    } else if (editCorrection) {
      const response = await rejectFile({
        variables: { file_id: fileEdit, comments: textAreaRef?.current.value },
      });
      if (response.data?.rejectFile) {
        setEditCorrection(false);
        setFileEdit('');
      }
    } else {
      rejectFile({
        variables: { file_id: reject, comments: comment },
      });
    }
  };

  useEffect(() => {
    if (reject !== '') {
      newCommentRef?.current?.scrollIntoView({ behavior: 'smooth' });
      newCommentRef?.current?.focus();
    }
  }, [reject]);

  return (
    <div
      style={{
        flexGrow: 0,
        position: 'sticky',
        top: role === 'doctor' ? '270px' : '225px',
        zIndex: 10,
      }}
    >
      <HeaderCard style={{ maxWidth: 300, minWidth: 300 }}>
        <InlineContainer style={{ alignItems: 'center' }}>
          <FeatherIcon icon="align-left" size="19" />
          <LabelNormal color="#3C4549" fontSize="17">
            Correcciones en archivos
          </LabelNormal>
        </InlineContainer>
      </HeaderCard>
      <CardContainer
        style={{
          maxHeight: '495px',
          minHeight: '495px',
          overflowY: 'scroll',
          position: 'relative',
          padding: 0,
          maxWidth: 300,
          minWidth: 300,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        {!loading ? (
          <>
            {comments.map((item) => (
              <div key={Math.random()}>
                <InlineContainer
                  style={{
                    marginLeft: 30,
                    marginRight: 30,
                    alignItems: 'flex-start',
                    marginTop: 20,
                  }}
                  key={Math.random()}
                >
                  <div style={{ width: '10%', marginTop: 5 }}>
                    <FeatherIcon
                      icon={
                        item.type === 'success'
                          ? 'check-circle'
                          : 'alert-circle'
                      }
                      size="19"
                      style={{ marginTop: 4 }}
                      color={item.type === 'success' ? '#1DBB24' : '#C60E02'}
                    />
                  </div>
                  <div style={{ width: '80%' }}>
                    <LabelNormal
                      fontSize="18"
                      color={item.type === 'success' ? '#4F4F4F' : '#C60E02'}
                    >
                      {role === 'doctor'
                        ? item.title.replace('Rechazaste ', '')
                        : item.title}
                    </LabelNormal>
                  </div>
                </InlineContainer>
                {item.type !== 'success' && role === 'doctor' && (
                  <LabelNormal
                    fontSize="17"
                    color="#757575"
                    style={{ marginLeft: 30, marginRight: 30, width: '80%' }}
                  >
                    {item.comments}
                  </LabelNormal>
                )}
                {role === 'admin' &&
                  item.type !== 'success' &&
                  !item.title.includes('Rechazaste Formulario') && (
                    <>
                      {editCorrection && item.file_id === fileEdit ? (
                        <div style={{ marginLeft: 30, marginRight: 30 }}>
                          <textarea
                            style={{
                              border: '1px solid #BDBDBDB2',
                              borderRadius: 14,
                              width: '100%',
                              padding: 12,
                              fontFamily: 'neue-montreal-regular',
                              fontSize: 17,
                              minHeight: 150,
                            }}
                            ref={textAreaRef}
                            rows={5}
                            className="form-control"
                            placeholder="Escribe tu correción A"
                            defaultValue={comment}
                          ></textarea>
                        </div>
                      ) : (
                        <>
                          <LabelNormal
                            fontSize="17"
                            color="#757575"
                            style={{
                              marginLeft: 30,
                              marginRight: 30,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              width: '80%',
                            }}
                          >
                            {item.comments}
                          </LabelNormal>
                          <LabelUnderline
                            color="#757575"
                            fontSize="14"
                            marginLeft={30}
                            marginRight={30}
                            onClick={() => {
                              setEditCorrection(true);
                              setComment(item.comments);
                              setFileEdit(item.file_id);
                              setSelectFile(
                                files.find((d) => d.id === item.file_id)
                              );
                            }}
                            label={'Editar comentario'}
                          />
                        </>
                      )}
                    </>
                  )}
              </div>
            ))}
            {role === 'admin' && reject !== '' && (
              <>
                <InlineContainer
                  style={{
                    marginLeft: 30,
                    marginRight: 30,
                    alignItems: 'flex-start',
                    marginTop: 20,
                  }}
                  key={Math.random()}
                >
                  <div style={{ width: '10%', marginTop: 5 }}>
                    <FeatherIcon
                      icon={'alert-circle'}
                      size="19"
                      style={{ marginTop: 4 }}
                      color={'#C60E02'}
                    />
                  </div>
                  <div style={{ width: '80%' }}>
                    <LabelNormal fontSize="18" color={'#C60E02'}>{`Rechazaste ${
                      file.type === 'image'
                        ? `Fotografía `
                        : file.type === 'stl'
                        ? `STL `
                        : file.type === 'pdf'
                        ? `Formulario `
                        : `Archivo `
                    } ${
                      file.type === 'pdf' ? 'de tratamiento' : file.review
                    }`}</LabelNormal>
                  </div>
                </InlineContainer>
                <div style={{ marginLeft: 30, marginRight: 30 }}>
                  <textarea
                    onChange={(e) => setComment(e.target.value)}
                    ref={newCommentRef}
                    style={{
                      border:
                        alertCommentReject && comments === ''
                          ? '1px solid #E41D11'
                          : '1px solid #BDBDBDB2',
                      borderRadius: 14,
                      width: '100%',
                      padding: 12,
                      fontFamily: 'neue-montreal-regular',
                      fontSize: 17,
                      minHeight: 150,
                    }}
                    rows={5}
                    className="form-control"
                    placeholder="Escribe tu correción"
                  ></textarea>
                  {alertCommentReject && comments === '' && (
                    <div
                      style={{
                        border: '1px solid #E41D11',
                        borderRadius: 7,
                        background: '#FFE5E3',
                        width: 157,
                        height: 31,
                        marginBottom: 10,
                      }}
                    >
                      <LabelNormal color="#C60E02" fontSize="14">
                        *Escribe la corrección
                      </LabelNormal>
                    </div>
                  )}
                </div>
              </>
            )}
            <div ref={refStatusCorrections} />
          </>
        ) : (
          <div style={{ marginTop: 10, marginLeft: 20 }}>
            <div>
              <Skeleton
                height={25}
                borderRadius={5}
                width={'40%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
              />
              <Skeleton
                height={92}
                borderRadius={5}
                width={'80%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
                style={{ marginTop: 10 }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Skeleton
                height={25}
                borderRadius={5}
                width={'40%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
              />
              <Skeleton
                height={92}
                borderRadius={5}
                width={'80%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
                style={{ marginTop: 10 }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Skeleton
                height={25}
                borderRadius={5}
                width={'40%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
              />
              <Skeleton
                height={92}
                borderRadius={5}
                width={'80%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
                style={{ marginTop: 10 }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Skeleton
                height={25}
                borderRadius={5}
                width={'40%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
              />
              <Skeleton
                height={92}
                borderRadius={5}
                width={'80%'}
                baseColor={'#EEEEF2'}
                highlightColor={'#FFF'}
                style={{ marginTop: 10 }}
              />
            </div>
          </div>
        )}
      </CardContainer>
      <div
        style={{
          position: 'relative',
          background: '#FFf',
          maxWidth: 321,
          bottom: 0,
          minHeight: 94,
          borderTopColor: '#000',
          borderTopStyle: 'solid',
          borderTopWidth: 0.7,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottomRightRadius: 30,
          borderBottomLeftRadius: 30,
        }}
      >
        {role === 'doctor' ? (
          <ButtonPrime
            disabled={
              files.filter((f) => f.statusId === '4').length === files.length ||
              (files.find((f) => f.review === 'modelo') &&
                files.filter((f) => f.statusId === '4').length ===
                  files.length - 1)
                ? false
                : true
            }
            onClick={() =>
              sendCorrectionsTreatment({
                variables: { treatment_id: treatmentId },
              })
            }
            label="Enviar correcciones"
          />
        ) : (
          <ButtonPrime
            disabled={
              (reject !== '' && comment !== '') ||
              (files.length === comments.length &&
                !editCorrection &&
                !loadingImage) ||
              (editCorrection && comment !== '')
                ? false
                : true
            }
            onClick={onClick}
            label={
              (reject !== '' && comment !== '') ||
              (editCorrection && comment !== '')
                ? 'Guardar comentario'
                : comments.length > 0 &&
                  comments.filter((c) => c.type !== 'success').length === 0 &&
                  (contactQuestion === '' || contactQuestion === null)
                ? 'Aprobar archivos'
                : 'Enviar correcciones'
            }
          />
        )}
      </div>
      {(formCorrection === '' || formCorrection === null) &&
        (contactQuestion === '' || contactQuestion === null) && (
          <LabelNormal
            style={{
              maxWidth: 321,
              textAlign: 'center',
              textDecorationLine: 'underline',
              marginTop: 16,
              cursor: 'pointer',
            }}
            onClick={() => questionDoctor()}
            color="#828282"
            fontSize="16.5"
          >
            ¿Necesitas contactar al doctor?
          </LabelNormal>
        )}
      {role === 'admin' &&
        (formCorrection !== '' || contactQuestion !== '') &&
        !(
          statusTreatment?.type === 'new_case' && statusTreatment?.index === 9
        ) && (
          <div style={{ marginTop: 20 }}>
            <HeaderCard style={{ maxWidth: 321 }}>
              <InlineContainer style={{ alignItems: 'center' }}>
                <FeatherIcon icon="help-circle" size="19" />
                <LabelNormal color="#3C4549" fontSize="17">
                  Preguntas para doctor
                </LabelNormal>
              </InlineContainer>
            </HeaderCard>
            <CardContainer
              id="card-container-scroll"
              style={{
                minHeight: 94,
                position: 'relative',
                paddingTop: 10,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: 321,
                borderBottomRightRadius: 30,
                borderBottomLeftRadius: 30,
              }}
            >
              <OverflowDiv>
                <LabelNormal
                  style={{ maxWidth: 321, marginTop: 16 }}
                  color="#4F4F4F"
                  fontSize="17"
                >
                  {formCorrection !== '' ? formCorrection : contactQuestion}
                </LabelNormal>
              </OverflowDiv>
              <LabelUnderline
                color="#757575"
                fontSize="14"
                label={'Haz click para hacer cambios'}
                onClick={() =>
                  formCorrection !== ''
                    ? questionDoctorForm()
                    : questionDoctorEdit()
                }
              />
            </CardContainer>
            <LabelUnderline
              color="#828282"
              fontSize="17"
              marginTop={16}
              marginBottom={32}
              maxWidth={321}
              textAlign={'center'}
              label={'Borrar pregunta'}
              onClick={() =>
                formCorrection !== '' ? deleteQuestionForm() : deleteQuestion()
              }
            />
          </div>
        )}
    </div>
  );
};

export default Comments;
