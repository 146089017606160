import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from './routes';

const Auth = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.screen === '/auth') {
        return (
          <Route
            exact
            path={prop.screen + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/auth/inicio-sesion" />
      </Switch>
    </>
  );
};

export default Auth;
