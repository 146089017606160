import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { loadPhotoProfileGender } from '../../utils';
import moment from 'moment';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ScrollHorizontal = styled.div`
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const Student = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [crystAligner, setCrystAligner] = useState('');
  const [smileSystem, setSmileSystem] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState({});
  const [idUser, setIdUser] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  const [nameUniversity, setNameUniversity] = useState('');
  const [idUniversity, setIdUniversity] = useState('');
  const [period, setPeriod] = useState('');
  const [type, setType] = useState('');

  const [taxDatas, setTaxDatas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [creditCards, setCreditCards] = useState([]);

  const [treatments, setTreatments] = useState([]);
  const [statusDoctor, setStatusDoctor] = useState(false);

  const [getStudent] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;

        setTreatments(doctor.treatments);
        setStatusDoctor(doctor.status);

        setTaxDatas(doctor.taxdatas);
        setAddresses(doctor.addresses);
        setCreditCards(doctor.cards);

        setNameUniversity(doctor.university.name);
        setType(doctor.university.type);
        setPeriod(doctor.university.graduate_periods);

        setIdUser(doctor.user.id);
        setFirstName(doctor.user.first_name);
        setLastName(doctor.user.last_name);
        setGender(doctor.user.gender);
        setCountry(doctor.user.country.name);
        setEmail(doctor.user.email);
        setCreatedAt(doctor.user.created_at);
        setPhoto(doctor.user.photo_filename);

        setCrystAligner(doctor.cryst_aligner);
        setSmileSystem(doctor.smile_system);

        setIdUniversity(doctor.university.id);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getStudent({ variables: { id } });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="estudiantes" />
      <ContainHome>
        {statusDoctor === 'disabled' && (
          <div
            style={{
              background: 'rgba(229, 229, 229, 0.4)',
              top: 0,
              bottom: 0,
              position: 'fixed',
              zIndex: 9,
              width: '100%',
            }}
          />
        )}
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Estudiante</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={1}
                    onClick={() => {}}
                    mainLabel={'Estudiantes'}
                    endLabel={`${firstName} ${lastName}`}
                  />
                </div>
              </div>
              <div className={'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    <InlineContainer>
                      <div>
                        <Avatar
                          src={
                            photo && !file.name
                              ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                              : file.name
                                ? photo
                                : loadPhotoProfileGender(gender)
                          }
                          height="161"
                          width="161"
                          borderRadius="30"
                        />
                        <LabelNormal
                          color="#828282"
                          fontSize="14"
                          style={{ textAlign: 'center' }}
                        >
                          Miembro desde
                        </LabelNormal>
                        <LabelNormal
                          color="#828282"
                          fontSize="14"
                          style={{ textAlign: 'center' }}
                        >
                          {moment(createdAt).format('DD/MM/YYYY')}
                        </LabelNormal>
                      </div>
                      <div style={{ marginLeft: 35 }}>
                        <InlineContainer>
                          <div className="row">
                            <div className="col-md-12">
                              <InlineContainer>
                                <FeatherIcon icon="user" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {gender}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div className="col-md-7">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <FeatherIcon icon="mail" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {email}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div className="col-md-4">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <FeatherIcon icon="map-pin" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {country}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                          </div>
                        </InlineContainer>
                      </div>
                    </InlineContainer>
                  </div>
                  <div className="col-md-12" style={{ marginTop: 47 }}>
                    <LabelNormal fontSize="22" color="#454D58">
                      Casos asignados
                    </LabelNormal>
                    <HeaderCard style={{ display: 'block' }}>
                      <div className="row">
                        <div className="col-md-4">
                          <LabelNormal color="#3C4549" fontSize="17">
                            Paciente
                          </LabelNormal>
                        </div>
                        <div className="col-md-4">
                          <LabelNormal color="#3C4549" fontSize="17">
                            ID
                          </LabelNormal>
                        </div>
                        <div className="col-md-4">
                          <LabelNormal color="#3C4549" fontSize="17">
                            Status
                          </LabelNormal>
                        </div>
                      </div>
                    </HeaderCard>
                    <CardContainer>
                      {treatments.length > 0 ? (
                        <>
                          {treatments.map((item) => (
                            <div className="row">
                              <div className="col-md-4">
                                <Link to={`/app/tablero/caso/${item.id}`}>
                                  <LabelNormal
                                    color="#757575"
                                    fontSize="15"
                                    style={{ textDecorationLine: 'underline' }}
                                  >{`${item.patient.first_name} ${item.patient.last_name}`}</LabelNormal>
                                </Link>
                              </div>
                              <div className="col-md-4">
                                <LabelNormal
                                  color="#757575"
                                  style={{ textAlign: 'center' }}
                                  fontSize="15"
                                >
                                  {item.id}
                                </LabelNormal>
                              </div>
                              <div className="col-md-4">
                                <LabelNormal
                                  color="#757575"
                                  fontSize="15"
                                  style={{ textAlign: 'center' }}
                                >
                                  {item.treatmentStatus.name}
                                </LabelNormal>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <LabelNormal
                          color="#BDBDBD"
                          fontSize="15"
                          style={{ textAlign: 'center' }}
                        >
                          Sin casos registrados
                        </LabelNormal>
                      )}
                    </CardContainer>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <HeaderCard style={{ justifyContent: 'space-between' }}>
                      <LabelNormal color="#3C4549" fontSize="17">
                        Estudiante
                      </LabelNormal>
                      <LabelNormal color="#757575" fontSize="17">
                        {period}º {type}
                      </LabelNormal>
                    </HeaderCard>
                    <CardContainer>
                      <div className="row">
                        <div className="col-md-8" style={{ marginBottom: 30 }}>
                          <LabelNormal
                            color="#828282"
                            fontSize="16"
                            style={{ marginBottom: 10 }}
                          >
                            Universidad
                          </LabelNormal>
                          <LabelNormal color="#3C4549" fontSize="17">
                            {nameUniversity}
                          </LabelNormal>
                        </div>
                        <div className="col-md-12">
                          <LabelNormal
                            color="#828282"
                            fontSize="16"
                            style={{ marginBottom: 10 }}
                          >
                            Capacitaciones
                          </LabelNormal>
                          {crystAligner && (
                            <>
                              <DecoratorBlue style={{ marginTop: 10 }}>
                                <LabelNormal color="#FFF" fontSize="17">
                                  Cryst Aligner
                                </LabelNormal>
                              </DecoratorBlue>
                              <InlineContainer
                                style={{
                                  marginTop: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <LabelNormal color="#BDBDBD" fontSize="13">
                                  ACTIVOS
                                </LabelNormal>
                                <LabelNormal color="#757575" fontSize="17">
                                  0
                                </LabelNormal>
                              </InlineContainer>
                              <InlineContainer
                                style={{
                                  marginBottom: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <LabelNormal color="#BDBDBD" fontSize="13">
                                  TOTALES
                                </LabelNormal>
                                <LabelNormal color="#757575" fontSize="17">
                                  0
                                </LabelNormal>
                              </InlineContainer>
                            </>
                          )}
                          {smileSystem && (
                            <>
                              <DecoratorYellow>
                                <LabelNormal color="#FFF" fontSize="17">
                                  Smile System
                                </LabelNormal>
                              </DecoratorYellow>
                              <InlineContainer
                                style={{
                                  marginTop: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <LabelNormal color="#BDBDBD" fontSize="13">
                                  ACTIVOS
                                </LabelNormal>
                                <LabelNormal color="#757575" fontSize="17">
                                  0
                                </LabelNormal>
                              </InlineContainer>
                              <InlineContainer
                                style={{
                                  marginTop: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <LabelNormal color="#BDBDBD" fontSize="13">
                                  TOTALES
                                </LabelNormal>
                                <LabelNormal color="#757575" fontSize="17">
                                  0
                                </LabelNormal>
                              </InlineContainer>
                            </>
                          )}
                        </div>
                      </div>
                    </CardContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Student;
