import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { modal } from '@components/ModalManagement';
import axios from 'axios';
import moment from 'moment';
import ToggleSwitch from '@components/shared/ToggleSwitch';
import Select from '@components/shared/Select';
import ModalWarning from '@components/Modal/ModalWarning';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalError from '@components/Modal/ModalError';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_DOCTORS } from '@graphql/query';

const ContainHome = styled.div`
  width: 100%;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-medium;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const CustomTable = styled.table`
  border-collapse: collapse;
  td,
  th {
    border: none;
    font-size: 12px;
    color: #455065;
    height: 2.5.rem;
    padding: 0.5rem 1rem;
    border-bottom: none;
  }
  thead tr {
    border-top: 1px solid #dee2e6;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
    :hover {
      background-color: #eb9542;
    }
  }
  thead > tr {
    background-color: #c2c2c2;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const options = [
  { id: 1, name: 'Reembolsar' },
  { id: 2, name: 'Anular factura' },
  { id: 3, name: 'Generar factura' },
];

const months = [
  { id: 1, name: 'Enero' },
  { id: 2, name: 'Febrero' },
  { id: 3, name: 'Marzo' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Mayo' },
  { id: 6, name: 'Junio' },
  { id: 7, name: 'Julio' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Septiembre' },
  { id: 10, name: 'Octubre' },
  { id: 11, name: 'Noviembre' },
  { id: 12, name: 'Diciembre' },
];

const Payments = (props) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [hideTestCases, setHideTestCases] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedTaxData, setSelectedTaxData] = useState(null);
  const [doctors, setDoctors] = useState([
    {
      id: null,
      name: 'Todos los doctores',
    },
  ]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  useEffect(() => {
    getPayments();
    getDoctors({
      variables: {
        filters: '',
        search: '',
        countries: [1, 2, 3],
      },
    });
  }, [hideTestCases, selectedMonth, selectedDoctor]);

  const getInstance = () => {
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/api`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token-b360')}`,
      },
    });
    return instance;
  };

  const getPayments = async () => {
    try {
      setLoading(true);
      const instance = getInstance();

      let options = {
        params: {
          doctorId: selectedDoctor ? selectedDoctor : null,
        },
      };
      const response = await instance.get(
        `/payments/get/${selectedMonth}`,
        options
      );

      const data = await response.data;
      setPayments(
        data.payments.filter((payment) =>
          hideTestCases ? payment.treatment.patient : true
        )
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formatCell = (cell, rule) => {
    switch (rule) {
      case 'patient':
        return cell ? `${cell.first_name} ${cell.last_name}` : 'Sin paciente';
      case 'doctor':
        return cell
          ? `${cell.user.first_name} ${cell.user.last_name}`
          : 'Sin doctor';
      case 'treatment_type':
        return cell === 1 ? 'Cryst Aligner' : 'Smile System';
      case 'type':
        return cell === 'planning'
          ? 'Pago de planificación'
          : 'Pago de tratamiento';
      case 'metodo':
        return cell ? 'Tarjeta' : 'Transferencia';
      case 'amount':
        return cell.toLocaleString('es-MX');
      case 'invoiced':
        return cell === 0 ? 'No' : 'Si';
      case 'status':
        return cell === 'done'
          ? 'Pagado'
          : cell === 'pending'
          ? 'Pendiente'
          : 'Reembolsado';
      case 'invoices':
        return cell ? cell.uuid : 'Sin factura';
      case 'invoices_date':
        return cell
          ? moment(cell.created_at).format('DD-MM-YYYY')
          : 'Sin factura';
      case 'invoices_issued':
        return cell.taxdata
          ? cell.taxdata.business_name
          : cell.invoice
          ? 'VENTAS MOSTRADOR NACIONAL'
          : 'Sin factura';
      case 'invoice_status':
        return cell
          ? cell.status === 'created'
            ? 'Exitosa'
            : 'Cancelada'
          : 'Sin factura';
      default:
        return cell;
    }
  };

  const onChangeOption = async (payment, option) => {
    setSelectedOption(option);
    setSelectedPayment(payment.id);
  };

  useEffect(() => {
    if (selectedOption) {
      confirmModal(selectedOption);
    }
  }, [selectedOption]);

  const deleteInvoice = async (showModal) => {
    const instance = getInstance();
    const payment = payments.find((p) => p.id === selectedPayment);

    try {
      const response = await instance.get(
        `/internal_invoice/cancel/${payment.invoice.id}`
      );
      const data = response.data;
      if (!data.error) {
        if (showModal) {
          modal.open(
            <ModalSuccess
              onClick={() =>
                modal.close(() => {
                  setPayments(
                    payments.map((pay) =>
                      pay.id === selectedPayment
                        ? { ...pay, invoice: null }
                        : pay
                    )
                  );
                })
              }
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                textAlign="center"
                style={{ paddingTop: 10, textAlign: 'center' }}
              >
                Factura anulada exitosamente.
              </LabelNormal>
            </ModalSuccess>
          );
        }
      }
    } catch (error) {
      if (showModal) {
        modal.open(
          <ModalError onClick={() => modal.close()}>
            <LabelNormal
              fontSize="22"
              color="#060809"
              textAlign="center"
              style={{ paddingTop: 10, textAlign: 'center' }}
            >
              La factura no pudo ser anulada. Es posible que la factura ya se
              encuentre anulada
            </LabelNormal>
          </ModalError>
        );
      }
    }
  };

  const regenerateInvoice = async (taxId) => {
    const instance = getInstance();
    const payment = payments.find((p) => p.id === selectedPayment);

    try {
      const response = await instance.get(
        `/invoice/regenerate/${payment.treatment.id}/${taxId}/${payment.id}`
      );
      const data = response.data;
      if (!data.error) {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setPayments(
                  payments.map((pay) =>
                    pay.id === selectedPayment
                      ? { ...pay, invoice: data.invoice }
                      : pay
                  )
                );
              })
            }
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              textAlign="center"
              style={{ paddingTop: 10, textAlign: 'center' }}
            >
              Factura generada exitosamente.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    } catch (error) {
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            fontSize="22"
            color="#060809"
            textAlign="center"
            style={{ paddingTop: 10, textAlign: 'center' }}
          >
            No se pudo generar la factura asociada al pago
          </LabelNormal>
        </ModalError>
      );
    }
  };

  const refundPayment = async () => {
    const instance = getInstance();
    const payment = payments.find((p) => p.id === selectedPayment);

    try {
      const response = await instance.get(`/stripe/refund/${payment.id}`);
      const data = response.data;
      if (!data.error) {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                getPayments();
              })
            }
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              textAlign="center"
              style={{ paddingTop: 10, textAlign: 'center' }}
            >
              Pago reembolsado y factura anulada exitosamente
            </LabelNormal>
          </ModalSuccess>
        );
      }
    } catch (error) {
      modal.open(
        <ModalError onClick={() => modal.close()}>
          <LabelNormal
            fontSize="22"
            color="#060809"
            textAlign="center"
            style={{ paddingTop: 10, textAlign: 'center' }}
          >
            El pago ya se encuentra reembolsado
          </LabelNormal>
        </ModalError>
      );
    }
  };

  const confirmModal = (option) => {
    const payment = payments.find((p) => p.id === selectedPayment);
    let selectedTax = null;
    modal.open(
      <ModalWarning
        onClick={() =>
          modal.close(() => {
            setSelectedOption(null);
          })
        }
      >
        <LabelNormal
          fontSize="22"
          color="#060809"
          textAlign="center"
          style={{ paddingTop: 10, textAlign: 'center' }}
        >
          Confirmación de acción &quot;
          {options.find((o) => o.id === option).name}&quot;?
        </LabelNormal>
        {option === 3 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <LabelInput style={{ marginTop: 20, marginBottom: 10 }}>
              Selecciona un perfil fiscal
            </LabelInput>
            <div className="radio">
              <input
                id="radio-id-0"
                onClick={(e) => {
                  setSelectedTaxData(7);
                  selectedTax = 7;
                }}
                name="taxdata"
                type="radio"
                value={7}
              />
              <label htmlFor="radio-id-0" className="radio-label">
                Público en General
              </label>
            </div>
            {payment.treatment.doctor.taxdatas.map((tax) => {
              return (
                <div className="radio" key={`radio-${tax.id}`}>
                  <input
                    id={`radio-id-${tax.id}`}
                    onClick={(e) => {
                      setSelectedTaxData(tax.id);
                      selectedTax = tax.id;
                    }}
                    name="taxdata"
                    type="radio"
                    value={tax.id}
                  />
                  <label htmlFor={`radio-id-${tax.id}`} className="radio-label">
                    {tax.business_name}
                  </label>
                </div>
              );
            })}
          </div>
        )}

        <LabelNormal
          fontSize="17"
          color="#4F4F4F"
          style={{ paddingTop: 14, display: 'flex', justifyContent: 'center' }}
        >
          <ButtonPrime
            label="Confirmar"
            onClick={() => confirmAction(option, selectedTax)}
          />
        </LabelNormal>
      </ModalWarning>
    );
  };

  const confirmAction = async (option, taxId) => {
    if (option === 1) {
      await deleteInvoice(false);
      await refundPayment();
    }
    if (option === 2) {
      await deleteInvoice(true);
    }
    if (option === 3) {
      await regenerateInvoice(taxId);
    }
  };

  const [getDoctors] = useLazyQuery(QUERY_GET_DOCTORS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDoctorsByFilters) {
        const listedDoctors = doctors.concat(
          data.getDoctorsByFilters.map((doctor) => {
            return {
              id: doctor.id,
              name: `${doctor.user.first_name} ${doctor.user.last_name}`,
            };
          })
        );
        setDoctors(listedDoctors);
      }
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="pagos" />
      <ContainHome>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Pagos mensuales</Title>

                <div style={{ marginTop: 10, marginBottom: 46 }}>
                  <LabelNormal fontSize="17" color="#757575">
                    Listado de pagos por mes para poder realizar reembolsos y
                    generar nuevas facturas asociadas a pagos que quedaron
                    incorrectos.
                  </LabelNormal>
                </div>
              </div>
              <div className="col-md-3" style={{ marginBottom: 20 }}>
                <InlineContainer>
                  <ToggleSwitch
                    small
                    id="toggle"
                    checked={hideTestCases}
                    onChange={() => setHideTestCases(!hideTestCases)}
                  />
                  <label className="radio-label" style={{ color: '#3C4549' }}>
                    Ocultar casos de prueba
                  </label>
                </InlineContainer>
              </div>
              <div className="col-md-4">
                <Select
                  options={months}
                  onSelect={setSelectedMonth}
                  value={selectedMonth}
                  placeholder="Seleciona un mes a consultar"
                />
              </div>
              <div className="col-md-4">
                <Select
                  options={doctors}
                  onSelect={setSelectedDoctor}
                  value={selectedDoctor}
                  placeholder="Seleciona un doctor a filtrar"
                />
              </div>
              <div className="col-md-10">
                <CustomTable>
                  <thead>
                    <tr>
                      <th>Paciente</th>
                      <th>Doctor</th>
                      <th>Tratamiento</th>
                      <th>Tipo de pago</th>
                      <th>Método</th>
                      <th>Cantidad</th>
                      <th>Divisa</th>
                      <th>Facturado</th>
                      <th>ID de pago</th>
                      <th>Status de pago</th>
                      <th>Banco</th>
                      <th>CLABE</th>
                      <th>Referencia</th>
                      <th>ID de facura</th>
                      <th>Creada en</th>
                      <th>Emitida a</th>
                      <th>Status</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((pay) => {
                      return (
                        <tr key={`tr-key-${pay.id}`}>
                          <td>
                            {formatCell(pay.treatment.patient, 'patient')}
                          </td>
                          <td>{formatCell(pay.treatment.doctor, 'doctor')}</td>
                          <td>
                            {formatCell(
                              pay.treatment.treatment_type_id,
                              'treatment_type'
                            )}
                          </td>
                          <td>{formatCell(pay.type, 'type')}</td>
                          <td>{formatCell(pay.card_id, 'metodo')}</td>
                          <td>{formatCell(pay.amount, 'amount')}</td>
                          <td>{formatCell(pay.currency, 'currency')}</td>
                          <td>{formatCell(pay.invoiced, 'invoiced')}</td>
                          <td>
                            {formatCell(pay.transaction_id, 'transaction_id')}
                          </td>
                          <td>{formatCell(pay.status, 'status')}</td>
                          <td>{formatCell(pay.bank_name, 'bank_name')}</td>
                          <td>{formatCell(pay.clabe, 'clabe')}</td>
                          <td>{formatCell(pay.reference, 'reference')}</td>
                          <td>{formatCell(pay.invoice, 'invoices')}</td>
                          <td>{formatCell(pay.invoice, 'invoices_date')}</td>
                          <td>{formatCell(pay, 'invoices_issued')}</td>
                          <td>{formatCell(pay.invoice, 'invoice_status')}</td>
                          <td>
                            <Select
                              options={options}
                              onSelect={(option) => onChangeOption(pay, option)}
                              value={
                                selectedPayment === pay.id
                                  ? selectedOption
                                  : null
                              }
                              placeholder="Seleciona una opción"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </CustomTable>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Payments;
