import { gql } from '@apollo/client';

export const MUTATION_REQUEST_MORE_SMARTCHECKS = gql`
  mutation changeMoreSmartchecks($patient_id: ID!) {
    changeMoreSmartchecks(input: { patient_id: $patient_id }) {
      id
      message_doctor
    }
  }
`;

export const MUTATION_SEND_APPROVEMENT_REQUEST = gql`
  mutation changeToPlanificationReady($patient_id: ID!) {
    changeToPlanificationReady(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_MARK_AS_PRINTING_READY = gql`
  mutation printingReady($treatment_id: ID!) {
    printingReady(input: { treatment_id: $treatment_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_MARK_AS_THERMOFORMING_READY = gql`
  mutation thermoformingReady($treatment_id: ID!) {
    thermoformingReady(input: { treatment_id: $treatment_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_MARK_AS_REFINED_READY = gql`
  mutation refinedReady($treatment_id: ID!) {
    refinedReady(input: { treatment_id: $treatment_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_MARK_AS_BRACES_PLACED = gql`
  mutation bracesPlaced($treatment_id: ID!) {
    bracesPlaced(input: { treatment_id: $treatment_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_DAY_SMARTCHECK = gql`
  mutation daySmartcheck($patient_id: ID!) {
    daySmartcheck(input: { patient_id: $patient_id }) {
      id
    }
  }
`;
