import React from 'react';
import styled from 'styled-components';

const Chip = styled.div`
  background-color: ${({ background }) => (background ? background : '#FFF')};
  border-radius: 7px;
  padding: 6px 14px;
  min-width: 115px;
  margin: 5px;
`;

export default (props) => <Chip {...props}>{props.children}</Chip>;
