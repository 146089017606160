import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Checkbox from './Checkbox';
import NativeClickListener from './NativeClickListener';

const DropDownContainer = styled('div')`
  width: 10.5em;
  position: relative;
`;

const DropDownHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 500;
  font-size: 17px;
  color: #828282;
  background: #ffffff;
  border: 1px solid #bdbdbdb2;
  border-radius: 16px;
  width: 288px;
  margin: 3px 0px;
  padding: 15px;
  font-family: neue-montreal-regular;
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 100;
  width: 18em;
`;

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  min-width: 270px;
  border-radius: 17px;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelItem = styled.label`
  font-family: neue-montreal-regular;
  font-size: 17px;
  font-weight: 400;
  color: #3c4549;
`;

export default function SelectCheckbox(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  return (
    <NativeClickListener onClick={() => setIsOpen(false)}>
      <>
        <DropDownContainer>
          <DropDownHeader
            style={{
              color: props.selectedOption.length > 0 ? '#3C4549' : '#828282',
            }}
            onClick={toggling}
          >
            {props.selectedOption.length > 0
              ? props.selectedOption
                  .map((op) =>
                    props.data.filter((dt) => dt.id === op).length > 0
                      ? props.data.filter((dt) => dt.id === op)[0].name
                      : '',
                  )
                  .join(', ')
              : props.placeholder}
            <FontAwesomeIcon
              icon={faChevronDown}
              size="xs"
              style={{ marginLeft: 15 }}
            />
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {props.data.map((option) => (
                  <ListItem key={Math.random()}>
                    <Checkbox
                      id={option.id + 'checkbox'}
                      checked={
                        props.selectedOption.indexOf(option.id) !== -1
                          ? 'checked'
                          : false
                      }
                      onChange={() =>
                        props.selectedOption.indexOf(option.id) === -1
                          ? props.setSelectedOption([
                              ...props.selectedOption,
                              option.id,
                            ])
                          : props.setSelectedOption(
                              props.selectedOption.filter(
                                (sl) => sl !== option.id,
                              ),
                            )
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                    <LabelItem htmlFor={option.id + 'checkbox'}>
                      {option.name}
                    </LabelItem>
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </>
    </NativeClickListener>
  );
}
