import React from 'react';
import { BoardIcon } from '../../../Icons/Board';
import { DoctorsIcon } from '../../../Icons/Doctors';
import { HelpIcon } from '../../../Icons/Help';
import { InsightsIcon } from '../../../Icons/Insights';
import { SmartcheckIcon } from '../../../Icons/Smartcheck';
import { TeamIcon } from '../../../Icons/Team';
import { UilUniversity } from '@iconscout/react-unicons';
import { UilMoneyWithdrawal } from '@iconscout/react-unicons';
import Item from '../../Item/Item';

const SectionsAdmin = (props) => {
  return (
    <>
      {props.name && (
        <>
          <Item
            label="Tableros"
            to="/app/tablero"
            icon={
              <BoardIcon
                color={
                  props.name === 'tableros'
                    ? '#FFFFFF'
                    : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'tableros' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'tableros' && '#333C3F'}
          />
          <Item
            label="Smart checks"
            to="/app/smartchecks"
            icon={
              <SmartcheckIcon
                color={
                  props.name === 'smartchecks'
                    ? '#FFFFFF'
                    : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'smartchecks' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'smartchecks' && '#333C3F'}
          />
          <Item
            label="Doctores"
            to="/app/doctores"
            icon={
              <DoctorsIcon
                color={
                  props.name === 'doctores'
                    ? '#FFFFFF'
                    : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'doctores' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'doctores' && '#333C3F'}
          />
          <Item
            label="Equipo"
            to="/app/equipo"
            icon={
              <TeamIcon
                color={
                  props.name === 'equipo' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'equipo' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'equipo' && '#333C3F'}
          />
          <Item
            label="Insights"
            to="/app/insights"
            icon={
              <InsightsIcon
                color={
                  props.name === 'insights'
                    ? '#FFFFFF'
                    : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'insights' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'insights' && '#333C3F'}
          />
          <Item
            label="Soporte"
            to="/app/soporte"
            icon={
              <HelpIcon
                color={
                  props.name === 'soporte' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'soporte' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'soporte' && '#333C3F'}
          />
          <Item
            label="Universidades"
            to="/app/universidades"
            icon={
              <UilUniversity
                color={
                  props.name === 'universidades'
                    ? '#FFFFFF'
                    : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'universidades' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'universidades' && '#333C3F'}
          />
          <Item
            label="Pagos"
            to="/app/pagos"
            icon={
              <UilMoneyWithdrawal
                color={
                  props.name === 'pagos' ? '#FFFFFF' : 'rgba(78, 90, 95, 0.6)'
                }
              />
            }
            color={props.name === 'pagos' ? '#FFFFFF' : '#4E5A5F'}
            $background={props.name === 'pagos' && '#333C3F'}
          />
        </>
      )}
    </>
  );
};

export default SectionsAdmin;
