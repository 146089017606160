import { EventEmitter } from 'events';

export default class ModalManagement extends EventEmitter {
  private dom: any;
  constructor() {
    super();
    this.dom = null;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(dom: any, callback?: any | undefined) {
    if (callback !== undefined && typeof callback === 'function') callback();
    this.dom = dom;
    this.emitChange();
  }

  close(callback: any) {
    if (typeof callback === 'function') callback();
    this.dom = null;
    this.emitChange();
  }

  emitChange() {
    this.emit('modal', this.dom);
  }
}
