import React from 'react';
import styled from 'styled-components';
import ButtonClose from '../shared/ButtonClose';

const Image = styled.img`
  height: 60px;
  width: 60px;
`;

const Inline = styled.div`
  display: flex;
  align-item: center;
  flex-direction: row;
  justify-content: center;
`;

const Warning = (props) => {
  return (
    <div className="modal">
      <Inline>
        <div className="text-center">
          <Image src={require('@images/icons/warning.png')} />
        </div>
        {!props.withoutIcon && <ButtonClose onClick={props.onClick} />}
      </Inline>
      <div>{props.children}</div>
    </div>
  );
};

export default Warning;
