import React from 'react';
import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: #3c4549;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    color: #eb9542;
  }
  &:active {
    color: #ab5d12;
  }
`;

export const ImgStyle = styled.img`
  filter: invert(23%) sepia(6%) saturate(908%) hue-rotate(153deg)
    brightness(101%) contrast(89%);
  transition: all 0.2s ease-out;
  &:hover {
    filter: invert(60%) sepia(70%) saturate(481%) hue-rotate(344deg)
      brightness(98%) contrast(88%);
  }
  &:active {
    filter: invert(45%) sepia(92%) saturate(0%) hue-rotate(248deg)
      brightness(95%) contrast(88%);
  }
`;

const EditProfile = (props) => (
  <Container onClick={() => props.onClick(true)}>
    <FeatherIcon
      color={props.color}
      icon="edit"
      style={{
        height: props.width ? props.width : 19,
        width: props.width ? props.width : 18.5,
        marginLeft: 20,
      }}
    />
    {/* <ImgStyle src={require('@images/icons/pen_edit.png')} style={{height: 19, width: 18.5, marginLeft: 20}} /> */}
  </Container>
);
export default EditProfile;
