import React from 'react';
import { Link } from 'react-router-dom';

const CasesTable = ({ treatments }) => {
  return (
    <div className="w-full rounded-3xl overflow-hidden shadow-md">
      <table className="text-base w-full border-collapse table-fixed">
        <thead className="bg-prominence-weak ">
          <tr className="">
            <th className="pl-6 pr-2 py-3 text-left w-5/12">Paciente</th>
            <th className="px-2 py-3 text-left w-1/12">ID</th>
            <th className="pr-6 pl-2 py-3 text-left w-1/2">Status</th>
          </tr>
        </thead>
      </table>
      <div className="w-full h-[200px] overflow-y-scroll">
        <table className="text-base w-full border-collapse table-fixed">
          <tbody className="bg-white w-full">
            {treatments.length > 0 ? (
              <>
                {treatments.map((item) => (
                  <tr className="w-full">
                    <td className="pl-6 pr-2 py-3 text-left w-5/12 border-b border-table whitespace-nowrap text-ellipsis overflow-hidden">
                      <Link
                        to={`/profesor/caso/${item.patient.id}`}
                        className="underline cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden"
                      >{`${item.patient.first_name} ${item.patient.last_name}`}</Link>
                    </td>
                    <td className="px-2 py-3 text-left w-1/12 border-b border-table">
                      {item.id}
                    </td>
                    <td className="pr-6 pl-2 py-3 text-left w-1/2 border-b border-table whitespace-nowrap text-ellipsis overflow-hidden">
                      <div className="w-full flex flex-row gap-1 items-center overflow-hidden">
                        <div
                          className={`!w-[12px] !h-[12px] grow-0 shrink-0 rounded-full mb-0.5 ${
                            item.treatmentStatus.color_type === 'success'
                              ? 'bg-success'
                              : item.treatmentStatus.color_type === 'error'
                              ? 'bg-error'
                              : 'bg-default'
                          }`}
                        ></div>
                        <p
                          className={`whitespace-nowrap text-ellipsis overflow-hidden ${
                            item.treatmentStatus.color_type === 'success'
                              ? 'text-success'
                              : item.treatmentStatus.color_type === 'error'
                              ? 'text-error'
                              : 'text-default'
                          }`}
                        >
                          {item.treatmentStatus.name}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={3}>Sin casos registrados</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CasesTable;
