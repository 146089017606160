type TreatmentStatus = {
  id: number;
  name: string;
  type:
    | 'new_case'
    | 'planning'
    | 'printing'
    | 'thermoforming'
    | 'warehouse'
    | 'packing'
    | 'shipping'
    | 'finished';
  index: number;
  admin_name?: string | null | undefined;
  color_type: 'normal' | 'error' | 'success' | null | undefined;
};

export enum TreatmentStatusEnum {
  IncompleteRegistry = 1,
  PendingPayment = 2,
  ReviewingFiles = 3,
  WaitingModel = 4,
  ReportedErrors = 5,
  SolvedError = 6,
  PausedCase = 7,
  ReadyToPlanning = 8,
  ReadyVisor = 9,
  WaitingPlanning = 10,
  ReadyPlanning = 11,
  ReadyToApprove = 12,
  ScheduledSmartcheck = 13,
  RescheduledSmartcheck = 14,
  DaySmartcheck = 15,
  MissedSmartcheck = 16,
  EndedSmartcheck = 17,
  ScheduledSecondSmartcheck = 18,
  EndedSecondSmartcheck = 19,
  ApprovedManufacture = 20,
  ReadyToPrinting = 21,
  Printing = 22,
  Thermoforming = 23,
  Warehouse = 24,
  Packing = 25,
  ReadyToShipping = 26,
  Shipping = 27,
  Delivered = 28,
  WaitingEvidence = 29,
  EndedCase = 30,
  InactiveCase = 31,
  WaitingApprove = 33,
  ApprovedByProfesor = 34,
  DoctorMessage = 35,
  DoubtsResolved = 36,
}

export default TreatmentStatus;
