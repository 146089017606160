import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Chip from '@components/shared/Chip';
import Select from '@components/shared/Select';
import SelectCheckbox from '@components/shared/SelectCheckbox';
import Input from '@components/shared/Input';
import ToggleSwitch from '@components/shared/ToggleSwitch';
import Checkbox from 'react-custom-checkbox';
import EditProfile from '../../../components/shared/EditProfle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import ButtonReactive from '../../../components/shared/ButtonReactive';
import {
  MUTATION_DELETED_MEMBER,
  MUTATION_EDIT_MEMBER,
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_REACTIVE_MEMBER,
} from '@graphql/mutation';
import {
  QUERY_GET_COUNTRIES,
  QUERY_GET_ROLES,
  QUERY_GET_DEPARTAMENT,
  QUERY_GET_ADMIN,
} from '@graphql/query';
import { modal } from '@components/ModalManagement';
import { useParams, useHistory } from 'react-router-dom';
import ModalWarning from '@components/Modal/ModalWarning';
import {
  loadPhotoProfileGender,
  validateEmail,
  validatePhone,
} from '../../../utils';
import axios from 'axios';
import UserIcon from '@images/icons/user.svg';
import PhoneIcon from '@images/icons/phone.svg';
import MailIcon from '@images/icons/mail.svg';
import LocationIcon from '@images/icons/location.svg';

const ContainHome = styled.div`
  width: 100%;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 30px;
  margin-right: 79px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily === 'medium' ? 'neue-montreal-medium' : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const InlineContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  min-width: 198px;
  padding: 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
`;

const ChipCircle = styled.div`
  width: 34px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5690ff;
  margin-left: 11px;
  cursor: pointer;
`;

const ProfileMember = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [editDoc, setEditDoc] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [admin, setAdmin] = useState(false);
  const [departament, setDepartament] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [role, setRole] = useState([]);
  const [Id, setId] = useState('');
  const [details, setDetails] = useState({});
  const [filename, setFilename] = useState('');
  const [file, setFile] = useState({});
  const [status, setStatus] = useState(null);
  const [enabledPermissions, setEnabledPermissions] = useState(false);

  const [countries, setCountries] = useState([]);
  const [departaments, setDepartaments] = useState([]);
  const [roles, setRoles] = useState([]);

  const [permissions, setPermissions] = useState([]);

  const [loader, setLoader] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [downloadError, setDownloadError] = useState(false);

  const [getDetails] = useLazyQuery(QUERY_GET_ADMIN, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.admin) {
        const admin = data.admin;
        setStatus(admin.status);
        setId(admin.user.id);
        setFirstName(admin.user.first_name);
        setLastName(admin.user.last_name);
        setGender(admin.user.gender);
        setEmail(admin.user.email);
        setPhone(admin.user.phone);
        setCountry(admin.user.country.id);
        setDepartament(admin.department.id);
        setAdmin(admin.super_admin);
        setFilename(admin.user.photo_filename);
        if (admin.administratorRoles) {
          setRole(admin.administratorRoles.map((rol) => rol.role.id));
          setSelectedOption([
            ...new Set(admin.administratorRoles.map((rol) => rol.country.id)),
          ]);
        }
        if (admin.super_admin) {
          setRole(roles.map((rol) => rol.id));
          setSelectedOption(countries.map((co) => co.id));
          setPermissions(roles);
          setEditDoc(true);
        } else {
          setPermissions(
            Array.from(new Set(admin.administratorRoles.map((rol) => rol.role)))
          );
        }
        setDetails({
          departament: admin.department.name,
          roles: admin.administratorRoles,
          country: admin.user.country.name,
        });
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  const [deletedMember] = useMutation(MUTATION_DELETED_MEMBER, {
    onCompleted: (data) => {
      if (data.deleteMember) {
        modal.close();
        getDetails({ variables: { id } });
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  const [reactiveMember] = useMutation(MUTATION_REACTIVE_MEMBER, {
    onCompleted: (data) => {
      if (data.reactiveMember) {
        getDetails({ variables: { id } });
      }
    },
  });

  const [getCountries] = useLazyQuery(QUERY_GET_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.countries) {
        if (admin) {
          setSelectedOption(data.countries.data.map((co) => co.id));
        }
        setCountries(data.countries.data);
        console.log(data.countries.data);
      }
    },
  });

  const [getDepartament] = useLazyQuery(QUERY_GET_DEPARTAMENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setDepartaments(data.departments.data);
    },
  });

  const [getRoles] = useLazyQuery(QUERY_GET_ROLES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.roles) {
        if (admin) {
          setRole(data.roles.data.map((rol) => rol.id));
          setPermissions(data.roles.data);
        }
        setRoles(data.roles.data);
      }
    },
  });

  const [updateMember] = useMutation(MUTATION_EDIT_MEMBER, {
    onCompleted: (data) => {
      if (data.updateMember) {
        if (file.name)
          addProfilePhoto({ variables: { file: file, user_id: +id } });
        else setEdit(false);
        getDetails({ variables: { id } });
      }
    },
    onError: (err) => console.log(err),
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        setEdit(false);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getRoles({});
    getCountries({});
    getDepartament({});
    getDetails({ variables: { id } });
  }, []);

  useEffect(() => {
    validationForms();
  }, [
    role,
    firstName,
    lastName,
    gender,
    country,
    phone,
    email,
    departament,
    selectedOption,
  ]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (gender === '') {
      setDisabled(true);
    } else if (country === '') {
      setDisabled(true);
    } else if (phone === '' || !validatePhone(phone)) {
      setDisabled(true);
    } else if (email === '' || !validateEmail(email)) {
      setDisabled(true);
    } else if (selectedOption.length === 0) {
      setDisabled(true);
    } else if (role.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleOpenModal = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 20 }}>
          El usuario perderá el acceso a su cuenta
        </LabelNormal>
        <LabelNormal fontSize="17" color="#4F4F4F">
          Podrás encontrarlo al activar el filtro de Desactivados o reactivarlo
          desde su perfil.
        </LabelNormal>
        <ButtonModal
          style={{ marginTop: 20 }}
          onClick={() => deletedMember({ variables: { id } })}
        >
          <LabelNormal fontSize="18" color="#3C4549">
            Dar de baja a administrador
          </LabelNormal>
        </ButtonModal>
      </ModalWarning>
    );
  };

  const handlerSubmit = () => {
    setDownloadError(false);
    updateMember({
      variables: {
        id: Id,
        first_name: firstName,
        last_name: lastName,
        gender,
        email,
        roles: [...new Set(role.map((x) => parseInt(x)))],
        super_admin: admin,
        department_id: +departament,
        country_id: +country,
        countries: selectedOption.map((x) => parseInt(x)),
        phone,
      },
    });
  };

  const [urlPrev, setUrlPrev] = useState('');

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setLoader(true);
      onUploadPhoto(prefile);
      setDownloadError(false);
    }
    evt.target.value = '';
  };

  const onUploadPhoto = async (e) => {
    await uploadPhotoAxios(e);
  };

  const uploadPhotoAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query:
          'mutation addProfilePhoto($file: Upload!, $user_id: Int!) {addProfilePhoto(input: { user_id: $user_id, file: $file }) {id}}',
        variables: {
          file: 'file',
          user_id: +Id,
        },
      })
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addProfilePhoto) {
          setUrlPrev(URL.createObjectURL(file));
          setProgressUpload(0);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        setDownloadError(true);
      });
  };

  const navigateBack = () => {
    setDownloadError(false);
    setEdit(false);
  };

  useEffect(() => {
    if (role.length && selectedOption.length) {
      const rls = JSON.stringify(roles.map((r) => r.id));
      const ctr = JSON.stringify(countries.map((c) => c.id));
      if (
        JSON.stringify(role) !== rls ||
        JSON.stringify(selectedOption) !== ctr
      ) {
        setAdmin(false);
      }
    }
  }, [role, selectedOption]);

  useEffect(() => {
    if (admin) {
      setSelectedOption(countries.map((c) => c.id));
      setRole(roles.map((r) => r.id));
      setEditDoc(true);
    } else {
      setSelectedOption([]);
      setRole([]);
      setEditDoc(false);
    }
  }, [admin]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="equipo" />
      <ContainHome>
        {status === 'disabled' && (
          <div
            style={{
              background: 'rgba(229, 229, 229, 0.4)',
              top: 0,
              bottom: 0,
              position: 'fixed',
              zIndex: 9,
              width: '100%',
            }}
          />
        )}
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => (edit ? navigateBack() : history.goBack())}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {edit && (
                <div className="col-md-12">
                  <Title>Editar miembro</Title>
                </div>
              )}
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <InlineContainer>
                      <Avatar
                        src={
                          (filename === '' || filename === null) &&
                          urlPrev === ''
                            ? loadPhotoProfileGender(gender)
                            : urlPrev
                            ? urlPrev
                            : `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${filename}`
                        }
                        floatButton={edit}
                        onChange={onChange}
                        height="161"
                        width="161"
                        borderRadius="30"
                        fadeOut={progressUpload === 100 ? true : false}
                        loader={progressUpload}
                        error={downloadError}
                        state={loader}
                        background={loader || downloadError}
                      />

                      <div style={{ marginLeft: 35 }}>
                        {!edit && (
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 10 }}
                          >
                            <LabelNormal
                              fontSize="22"
                              color="#3C4549"
                              fontFamily="medium"
                            >{`${firstName} ${lastName}`}</LabelNormal>
                            <EditProfile
                              color="#3C4549"
                              width="24"
                              onClick={setEdit}
                            />
                          </InlineContainer>
                        )}
                        {!edit && (
                          <InlineContainer>
                            <div className="row">
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={UserIcon}
                                    alt="user"
                                    style={{ marginRight: 5 }}
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${gender}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={PhoneIcon}
                                    style={{ marginRight: 5 }}
                                    alt="phone"
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${phone}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-7">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={MailIcon}
                                    style={{ marginRight: 5 }}
                                    alt="email"
                                  />
                                  <LabelNormal
                                    fontSize="17"
                                    color="#3C4549"
                                  >{`${email}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <img
                                    src={LocationIcon}
                                    style={{ marginRight: 5 }}
                                    alt="location"
                                  />
                                  <LabelNormal fontSize="17" color="#3C4549">
                                    {details.country ? details.country : ''}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                            </div>
                          </InlineContainer>
                        )}
                      </div>
                    </InlineContainer>
                  </div>
                  <div className="col-md-12">
                    {edit && (
                      <div className="bootstrap-wrapper">
                        <div className="grid-layout" style={{ marginTop: 38 }}>
                          <InlineContainer>
                            <div>
                              <Input
                                placeholder="Nombre"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                label="Nombres y apellidos"
                                required
                              />
                            </div>
                            <div>
                              <span
                                style={{ fontSize: 18, visibility: 'hidden' }}
                              >
                                none
                              </span>
                              <Input
                                placeholder="Apellidos"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                              />
                            </div>
                          </InlineContainer>
                        </div>
                        <div>
                          <LabelInput>Género</LabelInput>
                          <InlineContainer>
                            <div className="radio" style={{ marginRight: 20 }}>
                              <input
                                id="radio-1"
                                onClick={(e) => setGender('Masculino')}
                                checked={
                                  gender === 'Masculino' ? 'checked' : false
                                }
                                name="gender"
                                type="radio"
                              />
                              <label htmlFor="radio-1" className="radio-label">
                                Masculino
                              </label>
                            </div>
                            <div className="radio">
                              <input
                                id="radio-2"
                                onClick={(e) => setGender('Femenino')}
                                checked={
                                  gender === 'Femenino' ? 'checked' : false
                                }
                                name="gender"
                                type="radio"
                              />
                              <label htmlFor="radio-2" className="radio-label">
                                Femenino
                              </label>
                            </div>
                          </InlineContainer>
                        </div>
                        <div style={{ marginTop: 30 }} className="row">
                          <div className="col-md-4">
                            <LabelInput>País de residencia</LabelInput>
                            <Select
                              options={countries}
                              onSelect={setCountry}
                              value={country}
                              placeholder="Selecciona un pais"
                            />
                          </div>
                        </div>
                        <div
                          style={{ marginTop: 38, marginLeft: 0 }}
                          className="row"
                        >
                          <div className="col-md-6" style={{ padding: 0 }}>
                            <LabelInput>Teléfono</LabelInput>
                            <InlineContainer style={{ padding: 0 }}>
                              <Input
                                readOnly
                                disabled
                                value={
                                  country
                                    ? countries.filter(
                                        (c) => c.id === country
                                      )[0].lada_code
                                    : ''
                                }
                                backgroundColor="#F6F6F8"
                                maxWidth="70px"
                              />
                              <Input
                                placeholder="Teléfono"
                                defaultValue={phone}
                                onChange={setPhone}
                                type="phone"
                                value={phone}
                                mask="phone"
                                validation="phone"
                              />
                            </InlineContainer>
                          </div>
                          <div className="col-md-5" style={{ padding: 0 }}>
                            <Input
                              placeholder="Tu correo"
                              label="Correo"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: 38 }} className="row">
                          <div className="col-md-4">
                            <LabelInput>Departamento</LabelInput>
                            <Select
                              options={departaments}
                              onSelect={setDepartament}
                              value={departament}
                              placeholder="Selecciona un dpto."
                            />
                          </div>
                        </div>
                        <div style={{ width: '40%' }}>
                          <div
                            style={{
                              marginTop: 30,
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            <ToggleSwitch
                              small
                              id="toggle"
                              checked={admin ? 'checked' : false}
                              onChange={() => setAdmin(!admin)}
                            />
                            <label
                              className="radio-label"
                              style={{ color: '#3C4549' }}
                            >
                              Es superadministrador
                            </label>
                          </div>
                          <LabelMute>
                            *Seleccionar esta casilla le permite asignar/quitar
                            permisos a otros usuarios
                          </LabelMute>
                        </div>
                        <div style={{ marginTop: 38, width: '40%' }}>
                          <LabelInput>Permisos por departamento</LabelInput>
                          <SelectCheckbox
                            data={countries}
                            placeholder="Selecciona uno o varios países"
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                          />
                          <LabelMute>
                            *Los permisos estarán habilitados para el/los países
                            que selecciones
                          </LabelMute>
                        </div>
                        <div style={{ marginTop: 18, width: '70%' }}>
                          {roles.length > 0 && (
                            <div className="row">
                              {roles.map((row) => (
                                <div
                                  className="col-md-6"
                                  style={{ marginTop: 20 }}
                                  key={Math.random()}
                                >
                                  <Checkbox
                                    checked={role.indexOf(row.id) !== -1}
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        color="#EB9542"
                                        size="sm"
                                      />
                                    }
                                    borderColor="#C8C8C8"
                                    style={{ overflow: 'hidden' }}
                                    size={20}
                                    onChange={(value) =>
                                      value
                                        ? setRole([...role, row.id])
                                        : setRole(
                                            role.filter((r) => r !== row.id)
                                          )
                                    }
                                    labelStyle={{
                                      marginLeft: 5,
                                      userSelect: 'none',
                                      fontFamily: 'neue-montreal-regular',
                                      color: '#3C4549',
                                    }}
                                    label={row.name}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: 10, width: '40%' }}>
                          <InlineContainer>
                            <div>
                              <div style={{ marginTop: 15 }}>
                                <Checkbox
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      color="#EB9542"
                                      size="sm"
                                    />
                                  }
                                  checked={editDoc}
                                  onChange={(value) => setEditDoc(value)}
                                  borderColor="#C8C8C8"
                                  style={{ cursor: 'pointer' }}
                                  size={20}
                                  labelStyle={{
                                    marginLeft: 5,
                                    userSelect: 'none',
                                    fontFamily: 'neue-montreal-regular',
                                    color: '#3C4549',
                                  }}
                                  label="Puede editar datos de doctores"
                                />
                              </div>
                            </div>
                          </InlineContainer>
                        </div>
                        <InlineContainer
                          style={{
                            marginTop: 40,
                            marginBottom: 40,
                            alignItems: 'center',
                          }}
                        >
                          <ButtonPrime
                            label="Guardar cambios"
                            disabled={disabled}
                            onClick={() => handlerSubmit()}
                          />
                          <ButtonPrime
                            label="Cancelar"
                            background="transparent"
                            color="#3C4549"
                            border="1px solid #3C4549"
                            minWidth="127"
                            style={{ marginLeft: 20 }}
                            onClick={() => navigateBack()}
                          />
                        </InlineContainer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!edit && (
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <CardContainer style={{ minHeight: 212 }}>
                        <LabelNormal fontSize="17" color="#828282">
                          Departamento
                        </LabelNormal>
                        <InlineContainerWrap>
                          <Chip background="#757575">
                            <LabelNormal
                              fontSize="17"
                              style={{ textAlign: 'center' }}
                              color="#FFF"
                            >
                              {details.departament ? details.departament : ''}
                            </LabelNormal>
                          </Chip>
                        </InlineContainerWrap>
                        <div style={{ marginTop: 10 }}>
                          <LabelNormal fontSize="17" color="#828282">
                            Permisos
                          </LabelNormal>
                          <InlineContainerWrap>
                            {permissions.map((perm, index) => {
                              if (!enabledPermissions) {
                                if (index < 3) {
                                  return (
                                    <Chip
                                      background="#F6F6F8"
                                      key={Math.random()}
                                      style={{ textAlign: 'center' }}
                                    >
                                      <LabelNormal
                                        fontSize="17"
                                        color="#828282"
                                      >
                                        {perm.name}
                                      </LabelNormal>
                                    </Chip>
                                  );
                                }
                              } else {
                                return (
                                  <Chip
                                    background="#F6F6F8"
                                    key={Math.random()}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <LabelNormal fontSize="17" color="#828282">
                                      {perm.name}
                                    </LabelNormal>
                                  </Chip>
                                );
                              }
                            })}
                            {permissions.length > 3 && !enabledPermissions && (
                              <ChipCircle
                                onClick={() => setEnabledPermissions(true)}
                              >
                                <LabelNormal fontSize="17" color="#FFF">
                                  +{permissions.length - 3}
                                </LabelNormal>
                              </ChipCircle>
                            )}
                            {permissions.length > 3 && enabledPermissions && (
                              <ChipCircle
                                onClick={() => setEnabledPermissions(false)}
                              >
                                <LabelNormal fontSize="17" color="#FFF">
                                  -
                                </LabelNormal>
                              </ChipCircle>
                            )}
                          </InlineContainerWrap>
                          {status === 'disabled' && (
                            <ButtonReactive
                              label={'Reactivar perfil'}
                              onClick={() =>
                                reactiveMember({ variables: { id } })
                              }
                              marginTop={'20'}
                            />
                          )}
                        </div>
                      </CardContainer>
                    </div>
                    {status !== 'disabled' && (
                      <div className="col-md-12" style={{ marginTop: 38 }}>
                        <CardContainer
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleOpenModal()}
                        >
                          <InlineContainer style={{ alignItems: 'center' }}>
                            <img
                              src={require('@images/icons/user-minus.png')}
                              style={{
                                height: 19,
                                width: 18.5,
                                marginRight: 10,
                                marginLeft: 20,
                              }}
                            />
                            <LabelNormal fontSize="17" color="#3C4549">
                              Dar de baja
                            </LabelNormal>
                          </InlineContainer>
                        </CardContainer>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default ProfileMember;
