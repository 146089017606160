import React from 'react';
import FeatherIcons from 'feather-icons-react';
import { UilFileBlank } from '@iconscout/react-unicons';

import styled from 'styled-components';
import ButtonPrime from '@components/shared/ButtonPrime';
import Input from '@components/shared/Input';

const AlertVisor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 443px;
  height: 31px;
  background-color: #f9eeca;
  border: 1px solid #6b4d00;
  border-radius: 7px;
  margin-top: 30px;
`;

const AlertCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #f0bb00;
  border-radius: 9px;
  margin-right: 5px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17rem;
  height: 2.8rem;
  background-color: #f6f6f8;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
  border-radius: 5px;
  margin: 0.6rem 0 1.9rem 0.3rem;
`;

export const PlanningReviewSmileSystem = ({
  treatment,
  downloadFile,
  edit,
  fileMp4,
  handleOnChange,
  idCase,
  mp4FileName,
  mp4LinkVisor,
  onUploadMP4ForOtherInputRef,
  planningSendReady,
  progressUpload,
  refInputSmartCheck,
  setEdit,
  setFileMp4,
  setIdCase,
}) => {
  return (
    <div
      className="col-md-12 border-b border-gray-300 pb-8"
      style={{ maxWidth: 1145 }}
    >
      {edit ? (
        <div className="row" style={{ paddingBottom: 40 }}>
          <div className="col-md-4">
            <Input
              label="ID de caso"
              placeholder="ID"
              onChange={(e) => {
                setIdCase(e.target.value);
                handleOnChange(e.target.value, 'idCase');
              }}
              style={{ marginTop: 10, height: 47 }}
              value={idCase}
            />
          </div>
          <div style={{ marginLeft: 30 }}>
            <LabelNormal
              color="#3C4549"
              fontSize="18"
              style={{
                fontWeight: '600',
                marginRight: 10,
                marginBottom: '0.8rem',
              }}
            >
              Tipo de bracket
            </LabelNormal>
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ marginRight: 10 }}
            >
              {treatment?.bracket_ss.includes('Brackets')
                ? treatment?.bracket_ss.replace(' Brackets', '')
                : treatment?.bracket_ss}
            </LabelNormal>
          </div>
        </div>
      ) : (
        <>
          <ColumnContainer style={{ paddingBottom: '2.5rem' }}>
            {/* <ButtonPrime icon="eye" label="Ver video de visor" onClick={() => window.open(linkvisor, '_blank')} style={{marginRight:40}}/> */}
            <div style={{ display: 'flex', marginBottom: '0.6rem' }}>
              <div style={{ marginRight: '11.7rem' }}>
                <LabelNormal
                  color="#3C4549"
                  fontSize="18"
                  style={{
                    // textDecorationLine: 'bold',
                    fontWeight: '600',
                    marginRight: 10,
                    marginBottom: '0.8rem',
                  }}
                >
                  ID de caso
                </LabelNormal>
                <LabelNormal
                  color="#3C4549"
                  fontSize="17"
                  style={{ marginRight: 10 }}
                >
                  {idCase}
                </LabelNormal>
              </div>
              <div>
                <LabelNormal
                  color="#3C4549"
                  fontSize="18"
                  style={{
                    // textDecorationLine: 'bold',
                    fontWeight: '600',
                    marginRight: 10,
                    marginBottom: '0.8rem',
                  }}
                >
                  Tipo de bracket
                </LabelNormal>
                <LabelNormal
                  color="#3C4549"
                  fontSize="17"
                  style={{ marginRight: 10 }}
                >
                  {treatment?.bracket_ss.includes('Brackets')
                    ? treatment?.bracket_ss.replace(' Brackets', '')
                    : treatment?.bracket_ss}
                </LabelNormal>
              </div>
            </div>

            {treatment?.treatmentStatus.type === 'planning' && mp4FileName && (
              <div>
                <LabelNormal
                  fontSize="17"
                  style={{
                    marginBottom: '0.6rem',
                    marginTop: 30,
                  }}
                >
                  Para que el doctor comparta con el paciente
                </LabelNormal>
                <LabelNormal
                  fontSize="18"
                  color="#3C4549"
                  style={{ fontWeight: '600' }}
                >
                  Link de visor para compartir con paciente
                </LabelNormal>
                <div
                  className="col-12"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <VideoWrapper
                    style={{
                      marginLeft: -10,
                      marginRight: 30,
                      marginBottom: 0,
                    }}
                  >
                    <UilFileBlank size="24" color="#757575" />
                    <LabelNormal
                      color="#828282"
                      fontSize="17"
                      style={{
                        fontSize: 17,
                        fontFamily: 'neue-montreal-regular',
                        cursor: 'pointer',
                        color: '#828282',
                        margin: 5,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        direction: 'rtl',
                      }}
                      onClick={() => downloadFile(mp4FileName, mp4LinkVisor)}
                    >
                      {progressUpload > 0 ? `${progressUpload}%` : mp4FileName}
                    </LabelNormal>
                  </VideoWrapper>
                </div>
              </div>
            )}
            {(treatment?.treatmentStatus.type === 'planning' &&
              (treatment?.treatmentStatus.index === 13 ||
                treatment?.treatmentStatus.id === '12')) ||
              (treatment?.treatmentStatus.id >= 20 &&
                treatment?.treatmentStatus.id < 29 && (
                  <div style={{ marginTop: '1.3rem' }}>
                    <LabelNormal
                      fontSize="17"
                      style={{ marginBottom: '0.6rem' }}
                    >
                      Esta información es la que se le muestra al doctor en el
                      visor del caso.
                    </LabelNormal>
                    <LabelNormal
                      fontSize="18"
                      color="#3C4549"
                      style={{ fontWeight: '600' }}
                    >
                      Link de visor para compartir con paciente
                    </LabelNormal>
                    <VideoWrapper>
                      <UilFileBlank size="24" color="#757575" />
                      <LabelNormal
                        color="#828282"
                        fontSize="17"
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          direction: 'rtl',
                        }}
                      >
                        <a
                          href={mp4LinkVisor}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontSize: 17,
                            fontFamily: 'neue-montreal-regular',
                            color: '#828282',
                            margin: 5,
                            width: 250,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {mp4FileName}
                        </a>
                      </LabelNormal>
                    </VideoWrapper>
                    <ButtonPrime
                      label="Editar planificación"
                      icon="edit"
                      size="24"
                      background="transparent"
                      color="#3C4549"
                      border="2px solid #3C4549"
                      minWidth="227"
                      onClick={() => setEdit(true)}
                      style={{
                        marginLeft: 5,
                        fontWeight: '500',
                        width: '15.4rem',
                      }}
                    />
                  </div>
                ))}
          </ColumnContainer>
          {((treatment?.treatmentStatus.type === 'planning' &&
            treatment?.treatmentStatus.index === 9) ||
            (treatment?.treatmentStatus.id >= 20 &&
              treatment?.treatmentStatus.id < 29)) && (
            <div
              className="col-md-6"
              style={{
                borderTop: '1px solid #BDBDBD',
                paddingTop: 50,
              }}
            >
              <LabelNormal color="#757575" fontSize="22">
                Aprobación de caso
              </LabelNormal>
              <InlineContainer style={{ alignItems: 'center' }}>
                <FeatherIcons icon="check" color="#EB9542" />
                <LabelNormal
                  color="#EB9542"
                  fontSize="17"
                  style={{ marginLeft: 14 }}
                >
                  Caso aprobado
                </LabelNormal>
              </InlineContainer>
            </div>
          )}
        </>
      )}
      {edit &&
        treatment?.treatmentStatus.type === 'planning' &&
        treatment?.treatmentStatus.index === 9 && (
          <div>
            <AlertVisor style={{ marginTop: 0 }}>
              <AlertCircle>
                <FeatherIcons icon="alert-triangle" color="#FFFFFF" size="9" />
              </AlertCircle>
              <LabelNormal color="#6B4D00" fontSize="14">
                Sube el video del visor para concluir el proceso de SmartCheck
              </LabelNormal>
            </AlertVisor>

            <LabelNormal
              fontSize="17"
              style={{
                marginBottom: '0.6rem',
                marginTop: '0.6rem',
              }}
            >
              Para que el doctor comparta con el paciente
            </LabelNormal>
            <LabelNormal
              fontSize="18"
              color="#3C4549"
              style={{ fontWeight: '600' }}
            >
              Link de visor para compartir con paciente
            </LabelNormal>
            <input
              type="file"
              accept=".mp4"
              ref={refInputSmartCheck}
              style={{ display: 'none' }}
              onChange={(e) => {
                onUploadMP4ForOtherInputRef(e);
                setFileMp4(true);
                handleOnChange('fileMp4', 'fileMp4');
              }}
            ></input>
            {!fileMp4 && !mp4LinkVisor && (
              <>
                <ButtonPrime
                  label="Subir video de visor"
                  icon="arrow-up"
                  background="transparent"
                  color="#3C4549"
                  border="2px solid #3C4549"
                  minWidth="227"
                  onClick={() => refInputSmartCheck.current.click()}
                  style={{
                    marginLeft: 5,
                    fontWeight: '500',
                    width: 251,
                    height: 47,
                    marginTop: 10,
                  }}
                />
              </>
            )}
            {(fileMp4 || mp4LinkVisor) && (
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <VideoWrapper
                  style={{
                    marginLeft: -10,
                    marginRight: 30,
                    marginBottom: 0,
                  }}
                >
                  <UilFileBlank size="35" color="#757575" />
                  <LabelNormal
                    color="#828282"
                    fontSize="17"
                    style={{
                      fontSize: 17,
                      fontFamily: 'neue-montreal-regular',
                      cursor: 'pointer',
                      color: '#828282',
                      margin: 5,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      direction: 'rtl',
                    }}
                    onClick={() => downloadFile(mp4FileName, mp4LinkVisor)}
                  >
                    {progressUpload > 0 ? `${progressUpload}%` : mp4FileName}
                  </LabelNormal>
                </VideoWrapper>
                <ButtonPrime
                  label="Cambiar archivos"
                  fontSize="17"
                  background="transparent"
                  colorEnabled="#436FF1"
                  color="#757575"
                  border="none"
                  backgroundEnabled="transparent"
                  onClick={() => refInputSmartCheck.current.click()}
                  style={{ textDecoration: 'underline' }}
                />
                <input
                  type="file"
                  accept=".mp4"
                  ref={refInputSmartCheck}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    onUploadMP4ForOtherInputRef(e);
                    setFileMp4(true);
                    handleOnChange('fileMp4', 'fileMp4');
                  }}
                />
              </div>
            )}
            <div className="flex w-full pt-12">
              <ButtonPrime
                onClick={() =>
                  planningSendReady({
                    variables: { patient_id: treatment?.patient.id },
                  })
                }
                disabled={!mp4LinkVisor}
                label="Enviar caso como listo para aprobarse"
                style={{ width: 377 }}
              />
            </div>
          </div>
        )}
    </div>
  );
};
