import React from 'react';

import { RegisterNewCase } from './NewCase';
import { RegisterExtension } from './Extension';

/**
 * @typedef {'Initial' | 'Default' | 'Success' | 'Error' | 'Loading'} AsyncState
 * @typedef {'NewCase' | 'Extension'} RegisterType
 * @typedef {'Cryst Aligner' | 'Smile System'} TreatmentType
 * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
 * @typedef {{
 *  countryName,
 *  day,
 *  email,
 *  firstName,
 *  gender,
 *  lastName,
 *  month,
 *  phone,
 *  onClickNewCaseNextStep: () => void,
 *  setDay,
 *  setEmail,
 *  setFirstName,
 *  setGender,
 *  setLastName,
 *  setMonth,
 *  setPhone,
 *  setYear,
 *  validDate,
 *  validGender,
 *  validLastName,
 *  validName,
 *  year
 * }} RegisterNewCase
 * @typedef {{
 *  context: {
 *    bundleType: string;
 *    expirationDate: Date;
 *    expired: boolean;
 *    purchaseOptions: Array<{
 *      id: number;
 *      title: string;
 *      description: string;
 *      descriptionMedium: string;
 *      priceLabel: string;
 *      type: string;
 *      bundleType: string;
 *    }>;
 *    freePlanningsAvailable: number | null;
 *    freePlanningsTotal: number | null;
 *    remainingAligners: number | null;
 *  };
 *  doctorId: number;
 *  firstName: string;
 *  gender: string;
 *  lastName: string;
 *  patientId: number;
 *  photoUrl: string;
 *  parentTreatmentId: number;
 * }} ExtendiblePatient
 * @typedef {{
 *  extendiblePatientSelected: ExtendiblePatient | null;
 *  extendiblePatients: Array<ExtendiblePatient> | null;
 *  extendiblePatientsState: AsyncState;
 *  isErrorValidation?: boolean;
 *  onClickExtensionNextStep: () => void;
 *  selectExtendiblePatient: (attachPatientItem: AttachPatientItem) => void;
 *  setIsErrorValidation: (isErrorValidation: boolean) => void;
 * }} RegisterExtension
 * @typedef {{
 *  isTreatmentRegistered: boolean;
 *  registerExtension: RegisterExtension;
 *  registerNewCase: RegisterNewCase;
 *  registerType: RegisterType;
 *  selectRegisterType: (type: RegisterType) => void;
 *  selectTreatmentType: (type: TreatmentType) => void;
 *  treatmentType: TreatmentType;
 * }} NewCasePatientInfoStepProps
 * @param {NewCasePatientInfoStepProps} props
 * @returns
 */
export const NewCasePatientInfoStep = ({
  isTreatmentRegistered,
  registerExtension,
  registerNewCase,
  registerType,
  selectRegisterType,
  selectTreatmentType,
  treatmentType,
}) => {
  const {
    extendiblePatientSelected,
    extendiblePatients,
    extendiblePatientsState,
    onClickExtensionNextStep,
    selectExtendiblePatient,
  } = registerExtension;

  /**
   *  * @typedef {{
   *  firstName: string;
   *  gender: string;
   *  lastName: string;
   *  patientId: number;
   *  photoUrl: string;
   * }} ExtendiblePatient
   * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
   * @param {ExtendiblePatient} extendiblePatient
   * @returns {AttachPatientItem | null}
   */
  const parseToAttachPatientItem = (extendiblePatient) => {
    if (extendiblePatient == null) return null;

    return {
      avatar: extendiblePatient.photoUrl,
      fullname: `${extendiblePatient.firstName} ${extendiblePatient.lastName}`,
      id: extendiblePatient.patientId,
    };
  };

  /**
   * @typedef {{
   * aligners?: number;
   *  bundleType: BundleType;
   *  expirationDate: Date;
   *  refinements?: {
   *    available: number;
   *    total: number;
   *  };
   * }} InformationContextAlert
   * @type {InformationContextAlert}
   */
  const informativeContextAlert = extendiblePatientSelected
    ? {
        bundleType: extendiblePatientSelected.context.bundleType,
        expirationDate: new Date(
          Number(extendiblePatientSelected.context.expirationDate)
        ),
        aligners: extendiblePatientSelected.context.remainingAligners,
        freePlannings: {
          available: extendiblePatientSelected.context.freePlanningsAvailable,
          total: extendiblePatientSelected.context.freePlanningsTotal,
        },
      }
    : null;

  /**
   * @typedef {{ avatar: string; fullname: string; id: number; }} AttachPatientItem
   * @typedef {{
   *  isErrorValidation?: boolean;
   *  isRemovable: boolean;
   *  onChangeSelectedPatient: (patient: AttachPatientItem | null) => void;
   *  options: Array<AttachPatientItem>;
   *  selected: AttachPatientItem | null;
   *  setIsErrorValidation: (isErrorValidation: boolean) => void;
   *  state: "Error" | "Initial" | "Loading" | "Success";
   * }} PatientSelector
   * @type {PatientSelector}
   */
  const attachPatient = {
    isErrorValidation: registerExtension.isErrorValidation,
    isRemovable: !isTreatmentRegistered,
    onChangeSelectedPatient: selectExtendiblePatient,
    options: extendiblePatients.map((extendiblePatient) =>
      parseToAttachPatientItem(extendiblePatient)
    ),
    selected: parseToAttachPatientItem(extendiblePatientSelected),
    state: extendiblePatientsState,
    setIsErrorValidation: registerExtension.setIsErrorValidation,
  };

  const purchaseOptions = extendiblePatientSelected
    ? extendiblePatientSelected.context.purchaseOptions
    : [];

  return (
    <div className="flex flex-col gap-y-8 font-neue-montreal text-lg font-normal tracking-[.02em] text-prominence-weak mb-24">
      <div className="flex w-6-column flex-col gap-4">
        <h1 className="m-0 p-0 text-3xl font-medium text-prominence-default">
          Datos del paciente
        </h1>
        <p>Ingresa la información para guardar el perfil de tu paciente.</p>
      </div>
      <div className="flex w-6-column flex-col">
        <h2 className="text-lg font-medium text-prominence-default">
          Tratamiento
        </h2>
        <div className="flex flex-row items-start gap-6 text-prominence-default">
          <div className="radio">
            <input
              id="treatment-ca"
              name="treatment-ca"
              onClick={() => selectTreatmentType('Cryst Aligner')}
              checked={treatmentType === 'Cryst Aligner' ? true : false}
              type="radio"
            />
            <label htmlFor="treatment-ca" className="radio-label">
              Cryst Aligner
            </label>
          </div>
          <div className="radio">
            <input
              id="treatment-ss"
              name="treatment-ss"
              onClick={() => selectTreatmentType('Smile System')}
              checked={treatmentType === 'Smile System' ? true : false}
              type="radio"
              disabled={treatmentType === 'Cryst Aligner'}
            />
            <label htmlFor="treatment-ss" className="radio-label">
              Smile System
            </label>
          </div>
        </div>
      </div>
      <div className="flex w-6-column flex-col">
        <h2 className="text-lg font-medium text-prominence-default">
          Tipo de Registro
        </h2>
        <div className="flex flex-row items-start gap-6 text-prominence-default">
          <div className="radio">
            <input
              id="new-case"
              name="new-case"
              onClick={() => selectRegisterType('NewCase')}
              checked={registerType === 'NewCase' ? true : false}
              type="radio"
            />
            <label htmlFor="new-case" className="radio-label">
              Nuevo Caso
            </label>
          </div>
          <div className="radio">
            <input
              id="ref-ret"
              name="ref-ret"
              onClick={() => selectRegisterType('Extension')}
              checked={registerType === 'Extension' ? true : false}
              type="radio"
            />
            <label htmlFor="ref-ret" className="radio-label">
              Refinamiento/Retratamiento
            </label>
          </div>
        </div>
      </div>
      {registerType === 'NewCase' && <RegisterNewCase {...registerNewCase} />}
      {registerType === 'Extension' && (
        <RegisterExtension
          informativeContextAlert={informativeContextAlert}
          onClickExtensionNextStep={onClickExtensionNextStep}
          attachPatient={attachPatient}
          purchaseOptions={purchaseOptions}
        />
      )}
    </div>
  );
};
