/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_DATA_POSTAL_CODE } from '@graphql/query';
import {
  Column,
  InlineContainer,
  LabelInput,
  LabelMute,
  Row,
} from '../shared/Styled';
import TextInput from '../shared/inputs/TextInput';
import ZipCodeInput from '../shared/inputs/ZipCodeInput';
import Input from '../shared/Input';
import SelectCustomAddress from '@components/shared/SelectCustomAddress';
import { validateZipCode, getPlaceholdersByCountry } from '../../utils';

const AddressForm = ({
  country,
  setValid,
  setValues,
  defaultValues = null,
  doctor = false,
  profileFiscal = false,
  mode = 'onBlur',
  submit = 0,
  clear = 0,
}) => {
  const [placeholders, setPlaceholders] = useState({});
  const [placeholderNumber, setPlaceholderNumber] = useState('');
  const [placeholderZipCode, setPlaceholderZipCode] = useState('');
  const [placeholderLocality, setPlaceholderLocality] = useState('');
  const [placeholderState, setPlaceholderState] = useState('');
  const [placeholderCity, setPlaceholderCity] = useState('');
  const [dataPostalCode, setDataPostalCode] = useState({});
  const [canResetSuburb, setCanResetSuburb] = useState(false);
  const [cities, setCities] = useState([]);
  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    setValue,
    watch,
    setError,
    getValues,
    trigger,
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          street: '',
          number: '',
          internal_number: '',
          postal_code: '',
          city: '',
          locality: '',
          state: '',
          timezone: '',
          timezone_name: '',
          references: '',
        },
    mode: mode,
  });

  const watchAllFields = useWatch({ control });
  const codePostal = watch('postal_code');
  const city = watch('city');

  const [getDataPostalCode] = useLazyQuery(QUERY_GET_DATA_POSTAL_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDomDataByCode) {
        if (
          data.getDomDataByCode.suburbs.length > 0 &&
          data.getDomDataByCode.state.name.length > 0
        ) {
          setDataPostalCode(data.getDomDataByCode);
          setPlaceholderLocality(placeholders?.locality);
          setPlaceholderState(placeholders?.state);
          setPlaceholderCity(placeholders?.city);
          setCities(
            data.getDomDataByCode.suburbs.map((s) => {
              return { label: s.name, value: s.name };
            }),
          );
          setValue('timezone', data.getDomDataByCode.timezone);
          setValue('timezone_name', data.getDomDataByCode.timezone_name);
          setValue('state', data.getDomDataByCode.state?.name ?? '');
          setValue('locality', data.getDomDataByCode.locality);
        } else {
          setPlaceholderLocality(placeholders?.locality);
          setPlaceholderState(placeholders?.state);
          setPlaceholderCity(placeholders?.city);
          setCities([]);
          setError('postalCode', {
            type: 'custom',
            message: 'Código inválido',
          });
          setValue('timezone', '');
          setValue('timezone_name', '');
          setValue('state', '');
          setValue('locality', '');
        }
      }
    },
    onError: (err) => {
      setPlaceholderLocality(placeholders?.locality);
      setPlaceholderState(placeholders?.state);
      setPlaceholderCity(placeholders?.city);
      setCities([]);
      setError('postalCode', { type: 'custom', message: 'Código inválido' });
      setValue('timezone', '');
      setValue('timezone_name', '');
      setValue('state', '');
      setValue('locality', '');
    },
  });

  useEffect(() => {
    const ph = getPlaceholdersByCountry(country);
    setPlaceholderNumber(ph?.external);
    setPlaceholderZipCode(ph?.zipCode);
    setPlaceholderLocality(ph?.locality);
    setPlaceholderState(ph?.state);
    setPlaceholderCity(ph?.city);
    setPlaceholders(ph);
  }, []);

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        if (key === 'city') {
          setValue(key, {
            label: defaultValues[key],
            value: defaultValues[key],
          });
        } else setValue(key, defaultValues[key]);
      });
      trigger();
    }
  }, [defaultValues]);

  useEffect(() => {
    setValid(isValid);
    if (isValid) {
      const values = getValues();
      setValues({
        ...values,
        suburb: values.city?.value,
      });
    } else {
      setValues({});
    }
  }, [watchAllFields, isValid]);

  useEffect(() => {
    const ph = getPlaceholdersByCountry(country);
    setPlaceholderNumber(ph?.external);
    setPlaceholderZipCode(ph?.zipCode);
    setPlaceholderLocality(ph?.locality);
    setPlaceholderState(ph?.state);
    setPlaceholderCity(ph?.city);
    setPlaceholders(ph);
  }, [country]);

  useEffect(() => {
    if (validateZipCode(codePostal, country) && country) {
      setPlaceholderLocality('Cargando...');
      setPlaceholderState('Cargando...');
      setPlaceholderCity('Cargando...');
      getDataPostalCode({
        variables: { country_id: parseInt(country), postal_code: codePostal },
      });
    }
  }, [codePostal, country]);

  useEffect(() => {
    if (codePostal) {
      if (canResetSuburb) setValue('city', null);
      else setCanResetSuburb(true);
    }
  }, [codePostal]);

  useEffect(() => {
    if (clear > 0) clearErrors();
  }, [clear]);

  useEffect(() => {
    if (submit > 0) {
      trigger();
    }
  }, [submit]);

  return (
    <Column style={{ width: '100%' }}>
      <Row alignItems="flex-end" gap="6px">
        <TextInput
          name="street"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: '*Introduce tu calle' }}
          placeholder="Calle"
          label="Dirección"
          parentProps={{
            style: {
              flex: '0 0 33%',
              maxWidth: '33%',
            },
          }}
          marginRight="0px"
        />
        {(!country || parseInt(country) === 1 || parseInt(country) === 3) && (
          <TextInput
            name="number"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{
              required: {
                value: parseInt(country) === 1 || parseInt(country) === 3,
                message: '*Introduce tu número',
              },
            }}
            placeholder={placeholderNumber}
            // anotation={parseInt(country) === 2 ? "*Opcional" : null}
            parentProps={{
              style: {
                flex: '0 0 21%',
                maxWidth: '21%',
              },
            }}
            marginRight="0px"
          />
        )}
        {(!country || parseInt(country) === 1) && (
          <TextInput
            name="internal_number"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            placeholder="Nº interior"
            anotationPosition="top"
            anotation="*Opcional"
            parentProps={{
              style: {
                flex: '0 0 21%',
                maxWidth: '21%',
              },
            }}
            marginRight="0px"
          />
        )}
        <ZipCodeInput
          name="postal_code"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: '*Introduce un código',
            minLength: { value: 5, message: 'Código postal inválido' },
          }}
          setValue={setValue}
          placeholder={placeholderZipCode}
          parentProps={{
            style: {
              flex: parseInt(country) === 3 ? '0 0 25.2%' : '0 0 20%',
              maxWidth: parseInt(country) === 3 ? '25.2%' : '20%',
            },
          }}
          marginRight="0px"
          disabled={country === null}
        />
        {country && parseInt(country) === 2 && (
          <SelectCustomAddress
            name="city"
            control={control}
            errors={errors}
            value={city}
            clearErrors={clearErrors}
            rules={{ required: '*Selecciona una localidad' }}
            placeholder={placeholderCity}
            options={cities}
            parentProps={{
              style: {
                flex: parseInt(country) === 3 ? '0 0 40%' : '0 0 30%',
                maxWidth: parseInt(country) === 3 ? '40%' : '30%',
              },
            }}
          />
        )}
      </Row>
      <Row alignItems="flex-end" gap="6px">
        {(!country || parseInt(country) === 1 || parseInt(country) === 3) && (
          <SelectCustomAddress
            name="city"
            control={control}
            errors={errors}
            value={city}
            clearErrors={clearErrors}
            rules={{ required: '*Selecciona una localidad' }}
            placeholder={placeholderCity}
            options={cities}
            parentProps={{
              style: {
                flex: parseInt(country) === 3 ? '0 0 40%' : '0 0 30%',
                maxWidth: parseInt(country) === 3 ? '40%' : '30%',
              },
            }}
          />
        )}
        {(!country || parseInt(country) === 1) && (
          <Input
            placeholder={placeholderLocality}
            value={dataPostalCode.locality ? dataPostalCode.locality : ''}
            readOnly
            marginRight="0px"
            marginBottom="27px"
          />
        )}
        <Input
          placeholder={placeholderState}
          value={dataPostalCode.state ? dataPostalCode.state.name : ''}
          readOnly
          marginRight="0px"
          marginBottom="27px"
          parentProps={{
            style: {
              flex: parseInt(country) === 3 ? '0 0 40%' : '',
              maxWidth: parseInt(country) === 3 ? '40%' : '',
            },
          }}
        />
        {country && parseInt(country) === 2 && (
          <TextInput
            name="number"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{}}
            placeholder={placeholderNumber}
            anotation="*Opcional"
            anotationPosition="bottom"
            optional={true}
            parentProps={{
              style: {
                flex: '0 0 21%',
                maxWidth: '21%',
              },
            }}
            marginRight="0px"
          />
        )}
      </Row>
      {!doctor && !profileFiscal && (
        <div style={{ marginTop: 38 }}>
          <LabelInput>Zona horaria (Calculada desde código postal)</LabelInput>
          <InlineContainer style={{ alignItems: 'center' }}>
            <LabelMute>Zona horaria</LabelMute>
            <LabelMute style={{ marginLeft: 20 }}>
              {dataPostalCode.timezone_name
                ? dataPostalCode.timezone_name.charAt(0).toUpperCase() +
                  dataPostalCode.timezone_name.slice(1)
                : ''}
            </LabelMute>
          </InlineContainer>
        </div>
      )}
      {!profileFiscal && (
        <Row marginTop="38">
          <TextInput
            name="references"
            control={control}
            label="Referencias (Opcional)"
            placeholder="Notas adicionales: (piso, casa,oficina)"
            maxWidth="50%"
            parentProps={{
              style: {
                width: '100%',
              },
            }}
          />
        </Row>
      )}
    </Column>
  );
};

export default AddressForm;
