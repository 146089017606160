import React, { useState } from 'react';
import styled from 'styled-components';
import NoteIcon from '@images/icons/notes.svg';
import moment from 'moment';
import LabelUnderline from './LabelUnderline';
import Card from './Card';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CardHeader = styled.div`
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 12px 24px;
`;

const CardNotes = styled.div`
  min-height: 190px;
  border-radius: 30px;
  background: #fff;
`;

const DatesContainer = styled.div`
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: ${({ fontFamily }) =>
    fontFamily === 'medium' ? 'neue-montreal-medium' : 'neue-montreal-regular'};
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const NoteInput = styled.textarea`
  font-size: 17px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 20px;
  width: 100%;
  height: 47px;
  margin-top: 10px;
  padding: 12px 17px 5px 17px;
  box-shadow: 0px 1px 2px rgba(69, 72, 74, 0.06);
  border: 1px solid rgba(189, 189, 189, 0.7);
  border-radius: 16px;
  resize: none;
  text-align: justify;
  &:focus {
    outline: none;
    border: 1px solid #d97617;
  }
  &:hover {
    outline: none;
    border: 1px solid #d97617;
  }
`;

const InternalNotesCard = (props) => {
  const [commentSelected, setCommentSelected] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [readMore, setReadMore] = useState(false);

  return (
    <Card minWidth="340px" padding="0px" border="none">
      <CardNotes {...props}>
        <CardHeader>
          <InlineContainer style={{ alignItems: 'center' }}>
            <img src={NoteIcon} alt="user" style={{ marginRight: 5.5 }} />
            <LabelNormal color="#3C4549" fontSize="20" fontFamily="medium">
              Notas internas
            </LabelNormal>
          </InlineContainer>
        </CardHeader>
        <div style={{ padding: 20 }}>
          {props.comments.map((comment) => {
            return (
              <React.Fragment key={`comment-${comment.created_at}`}>
                <LabelNormal
                  color="#4F4F4F"
                  fontSize="17"
                  style={{ marginBottom: 0, marginTop: 0 }}
                >
                  {`${comment.administrator.user.first_name} ${comment.administrator.user.last_name}`}
                </LabelNormal>
                <DatesContainer>
                  <LabelNormal
                    color="#4F4F4F"
                    fontSize="14"
                    style={{
                      margin: '0 0 0 5px',
                      paddingRight: 6,
                      borderRight: '1px solid #C8C8C8',
                    }}
                  >
                    {`${moment(comment.created_at).format('HH:mm:ss')}`}
                  </LabelNormal>
                  <LabelNormal
                    color="#4F4F4F"
                    fontSize="14"
                    style={{ margin: 0 }}
                  >
                    {`${moment(comment.created_at).format('DD/MM/YYYY')}`}
                  </LabelNormal>
                </DatesContainer>
                <LabelNormal
                  color="#757575"
                  fontSize="14"
                  className={
                    commentSelected === comment.id && readMore
                      ? 'showText'
                      : 'hideText'
                  }
                  style={{ marginBottom: 10, marginTop: 5 }}
                >
                  {comment.text}
                </LabelNormal>
                {comment.text.length > 90 && (
                  <LabelUnderline
                    color="#757575"
                    fontSize="14"
                    label={
                      commentSelected === comment.id && readMore
                        ? 'Ver menos'
                        : 'Leer más'
                    }
                    onClick={() => {
                      setCommentSelected(comment.id);
                      setReadMore(!readMore);
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
          <>
            <NoteInput
              placeholder="Escribe tu comentario..."
              value={newComment}
              className="noteInput"
              onChange={(e) => setNewComment(e.target.value)}
            />
            <InlineContainer style={{ justifyContent: 'flex-end' }}>
              <LabelUnderline
                color="#757575"
                fontSize="14"
                label={'Añadir comentario'}
                onClick={() =>
                  newComment !== ''
                    ? (props.treatmentId
                        ? props.addComment({
                            variables: {
                              treatment_id: props.treatmentId,
                              comment: newComment,
                            },
                          })
                        : props.addComment({
                            variables: {
                              doctor_id: props.doctor_id,
                              comment: newComment,
                            },
                          }),
                      setNewComment(''))
                    : {}
                }
              />
            </InlineContainer>
          </>
        </div>
      </CardNotes>
    </Card>
  );
};

export default InternalNotesCard;
