import React from 'react';

const Point = () => {
  return (
    <svg
      width="111"
      height="111"
      viewBox="0 0 111 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="55.5" cy="55.5" r="55" fill="#EB9542" />
      <path
        d="M110.5 55.5C110.5 85.8757 85.8757 110.5 55.5 110.5C25.1243 110.5 0.5 85.8757 0.5 55.5C0.5 25.1243 25.1243 0.5 55.5 0.5C85.8757 0.5 110.5 25.1243 110.5 55.5ZM6.34256 55.5C6.34256 82.6489 28.3511 104.657 55.5 104.657C82.6489 104.657 104.657 82.6489 104.657 55.5C104.657 28.3511 82.6489 6.34256 55.5 6.34256C28.3511 6.34256 6.34256 28.3511 6.34256 55.5Z"
        fill="#E88322"
      />
      <g filter="url(#filter0_i_1663_24)">
        <path
          d="M66.4778 53.1045C66.4165 53.1045 66.4778 58.8097 66.4778 58.8097C71.1628 59.4453 74.7301 62.1739 74.7301 65.7086V68.4372C74.7301 72.7781 70.7647 75.026 66.8758 75.026C63.0329 75.026 59.19 72.4835 58.8225 67.1814L52.5147 66.3597C52.5147 66.3597 52.3003 72.5145 55.393 76.0803C58.103 79.739 62.3899 81.6149 66.7993 81.6149C73.8574 81.6149 81.0686 77.274 81.0686 68.4527V65.7241C81.0686 62.3599 78.328 57.6159 73.7043 56.0036C73.689 55.9881 67.9629 53.7401 66.4778 53.1045Z"
          fill="#D97617"
        />
        <path
          d="M30.299 43.4615V46.1901C30.299 49.5698 33.0395 54.2983 37.6786 55.9106C37.6786 55.9106 43.4047 58.1586 44.8898 58.8097C44.951 58.8407 44.8898 53.1045 44.8898 53.1045C40.1895 52.4844 36.6375 49.7403 36.6375 46.2056V43.4615C36.6375 39.1206 40.6029 36.8882 44.4917 36.8882C48.3346 36.8882 52.1929 39.4307 52.545 44.7328L58.8529 45.5545C58.8529 45.5545 59.0672 39.3997 55.9745 35.834C53.2646 32.1752 48.993 30.2993 44.5683 30.2993C37.5102 30.2993 30.299 34.6402 30.299 43.4615Z"
          fill="#D97617"
        />
        <path
          d="M55.1333 35.7156C52.6071 38.6302 52.2856 43.2811 52.255 44.878C52.255 45.2345 52.255 45.4361 52.255 45.4361V66.7995V67.0941C51.8875 72.3962 48.0752 74.9077 44.2323 74.9077C40.3435 74.9077 36.3781 72.6597 36.3781 68.3188V65.5902C36.3781 62.0555 39.9301 59.3114 44.6304 58.6913C44.6304 58.6913 44.6916 52.9706 44.6304 52.9861C43.1453 53.6218 37.4192 55.8697 37.4192 55.8697C32.7801 57.4821 30.0396 62.226 30.0396 65.5902V68.3188C30.0396 77.1556 37.2508 81.481 44.3089 81.481C48.7183 81.481 53.0052 79.6052 55.7151 75.9464C58.8078 72.3807 58.5935 66.2259 58.5935 66.2259V44.878V44.6144C58.9609 39.3123 62.7732 36.7698 66.6162 36.7698C70.505 36.7698 74.4704 39.0177 74.4704 43.3431V46.0717C74.4704 49.6064 70.9184 52.3505 66.2181 52.9706C66.2181 52.9706 66.1568 58.7068 66.2181 58.6758C67.7032 58.0402 73.4293 55.7767 73.4293 55.7767C78.0684 54.1644 80.7936 49.4359 80.7936 46.0562V43.3431C80.8089 34.5218 73.5977 30.1809 66.5396 30.1809C62.1149 30.1809 57.8433 32.0568 55.1333 35.7156Z"
          fill="#D97617"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1663_24"
          x="30.0396"
          y="30.1809"
          width="51.0291"
          height="56.6648"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.23077" />
          <feGaussianBlur stdDeviation="2.61539" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1663_24"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Point;
