import classNames from 'classnames';

import { LabelNormal, LabelTitle } from '@components/shared/Styled';
import Input from '@components/shared/Input';
import TrashIcon from '@images/icons/trash.svg';
import { defaultPartiality } from './consts';

/** Planning UI Component to control aligners planification.
 *
 * @param {{
 * alignerSendType: "Partial" | "Total",
 * canAddPartiality: (index: number) => boolean,
 * canRemovePartiality: (index: number) => boolean,
 * errorMessages: Object<number, string>,
 * isAddPartialityEnabled: (index: any) => boolean,
 * isEditMode: boolean,
 * partialities: Array<{ upper: number, bottom: number }>,
 * setAlignerSendType: React.Dispatch<"Partial" | "Total">,
 * setPartialities: React.Dispatch<Array<{ upper: number, bottom: number }>>,
 * validateErrors: (list: Array<{ upper: number, bottom: number }>, index: number, value: string) => boolean
 * }} props
 * @returns
 */
export const PlanningAlignerPartialities = ({
  alignerSendType,
  canAddPartiality,
  canRemovePartiality,
  isEditMode,
  errorMessages,
  isAddPartialityEnabled,
  partialities,
  setAlignerSendType,
  setPartialities,
  validateErrors,
}) => {
  /**
   * @param {"Partial" | "Total"} type
   */
  const onChangeAlignerType = (type) => {
    setAlignerSendType(type);
  };

  /**
   * @param {number} index
   * @param {string} value
   * @param {"bottom" | "upper"} type
   */
  const onChangeAligner = (index, value, type) => {
    // shallow copies.
    let list = [...partialities];
    let partiality = { ...list[index] };
    if (type === 'bottom') partiality.bottom = value;
    else partiality.upper = value;
    list[index] = partiality;

    setPartialities(list);
    validateErrors(list, index, value);
  };

  const onClickAddPartiality = () => {
    setPartialities((list) => [...list, defaultPartiality]);
  };

  const onClickRemovePartiality = () => {
    // Only remove last item.
    setPartialities((list) => [
      ...list.filter((_, i) => i !== list.length - 1),
    ]);
  };

  return (
    <>
      <LabelTitle color="#3C4549" fontSize="22">
        Envío de alineadores
      </LabelTitle>
      <AlignerSendType
        alignerSendType={alignerSendType}
        isEditMode={isEditMode}
        onChangeAlignerType={onChangeAlignerType}
      />
      {alignerSendType === 'Partial' && (
        <div className="grid grid-cols-2 gap-x-8 gap-y-4 mt-2 w-full">
          {partialities &&
            partialities.map((partiality, index) => (
              <AlignerPartiality
                canAddPartiality={canAddPartiality}
                canRemovePartiality={canRemovePartiality}
                errorMessages={errorMessages}
                index={index}
                isAddPartialityEnabled={isAddPartialityEnabled}
                isEditMode={isEditMode}
                key={`partiality-${index}`}
                onChangeAligner={onChangeAligner}
                onClickAddPartiality={onClickAddPartiality}
                onClickRemovePartiality={onClickRemovePartiality}
                partiality={partiality}
              />
            ))}
        </div>
      )}
      {alignerSendType === 'Total' && (
        <p className="text-[#9E9E9E]">
          Se enviarán todos los alineadores indicados
        </p>
      )}
    </>
  );
};

/** Aligner UI Element to control partiality aligners.
 *
 * @param {{
 * canAddPartiality: (index: number) => boolean,
 * canRemovePartiality: (index: number) => boolean,
 * errorMessages: Object<number, string>
 * index: number,
 * isAddPartialityEnabled: (index: number) => boolean,
 * isEditMode: boolean,
 * onChangeAligner: (index: number, value: string, aligner: "upper" | "bottom") => void,
 * onClickAddPartiality: () => void,
 * onClickRemovePartiality: () => void
 * partiality: {bottom: string, upper: string},
 * }} props
 * @returns
 */
const AlignerPartiality = ({
  canAddPartiality,
  canRemovePartiality,
  errorMessages,
  index,
  isAddPartialityEnabled,
  isEditMode,
  onChangeAligner,
  onClickAddPartiality,
  onClickRemovePartiality,
  partiality,
}) => {
  const partialityLabel = (index) => {
    return ['Primera', 'Segunda', 'Tercera', 'Cuarta'][index];
  };

  return (
    <div className="flex flex-col gap-4">
      <LabelNormal fontSize="17" color="#3C4549">
        {partialityLabel(index)}
      </LabelNormal>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex flex-col mr-2">
              {isEditMode ? (
                <Input
                  className={errorMessages[index] && 'etftxW'}
                  label="Superiores"
                  mask="onlyNumber"
                  onChange={(value) => onChangeAligner(index, value, 'upper')}
                  placeholder="Cantidad"
                  style={{ marginTop: 10 }}
                  value={partiality.upper}
                />
              ) : (
                <div className="flex flex-col gap-4 pr-16">
                  <p className="text-base text-[#757575]">Superiores</p>
                  <p className="text-base text-[#3C4549]">{partiality.upper}</p>
                </div>
              )}
            </div>
            <div className="flex flex-col mr-2">
              {isEditMode ? (
                <Input
                  className={errorMessages[index] && 'etftxW'}
                  label="Inferiores"
                  mask="onlyNumber"
                  onChange={(value) => onChangeAligner(index, value, 'bottom')}
                  placeholder="Cantidad"
                  style={{ marginTop: 10 }}
                  value={partiality.bottom}
                />
              ) : (
                <div className="flex flex-col gap-4 pr-16">
                  <p className="text-base text-[#757575]">Inferiores</p>
                  <p className="text-base text-[#3C4549]">
                    {partiality.bottom}
                  </p>
                </div>
              )}
            </div>
            {isEditMode && (
              <div className="flex flex-row items-end pb-4 justify-start gap-2 w-[72px] h-full">
                {canAddPartiality(index) && (
                  <AddButton
                    enabled={isAddPartialityEnabled(index)}
                    onClickAddPartiality={onClickAddPartiality}
                  />
                )}
                {canRemovePartiality(index) && (
                  <RemoveButton
                    onClickRemovePartiality={onClickRemovePartiality}
                  />
                )}
              </div>
            )}
          </div>
          {errorMessages[index] && (
            <div style={{ marginBottom: 15 }}>
              <LabelNormal fontSize="17" color="#C60E02">
                {errorMessages[index]}
              </LabelNormal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/** Aligner UI element to select which type of aligner sending method is desired.
 *
 * @param {{
 * alignerSendType: "Partial" | "Total",
 * isEditMode: boolean,
 * onChangeAlignerType: () => void
 * }} props
 * @returns {JSX.Element}
 */
const AlignerSendType = ({
  alignerSendType,
  isEditMode,
  onChangeAlignerType,
}) => {
  /**
   *
   * @param {"Partial" | "Total"} type
   */
  const getLabel = (type) => {
    switch (type) {
      case 'Partial':
        return 'Parcialidades';
      case 'Total':
        return 'Total';
      default:
        return '';
    }
  };

  const onChange = (type) => {
    onChangeAlignerType(type);
  };

  return (
    <div className="flex flex-row gap-8">
      {isEditMode === true &&
        ['Partial', 'Total'].map((type, index) => (
          <div className="radio" key={`aligner-type-${index}`}>
            <input
              checked={type === alignerSendType}
              id={`aligner-type-${index}`}
              onChange={() => onChange(type)}
              type="radio"
              value={type}
            />
            <label htmlFor={`aligner-type-${index}`} className="radio-label">
              {getLabel(type)}
            </label>
          </div>
        ))}
      {isEditMode === false && (
        <p className="py-1 px-2 bg-white text-xs rounded">
          {alignerSendType === 'Partial'
            ? 'Se enviará por parcialidades'
            : 'Se enviará el total de alineadores'}
        </p>
      )}
    </div>
  );
};

/** Aligner UI element to add partiality.
 *
 * @param {{enabled: boolean, onClickAddPartiality: () => void}} param0
 * @returns {JSX.Element}
 */
const AddButton = ({ enabled, onClickAddPartiality }) => {
  const onClick = () => {
    enabled && onClickAddPartiality();
  };

  return (
    <div
      className={classNames(
        'items-center flex h-8 w-8 justify-center rounded-full',
        enabled
          ? 'bg-[#eb9542] cursor-pointer'
          : 'bg-[#C8C8C8] cursor-not-allowed',
      )}
      onClick={onClick}
    >
      <img
        alt="add partiality"
        className="h-3 w-3"
        src={require('@images/icons/plus.png')}
      />
    </div>
  );
};

/** Aligner UI element to remove partiality.
 *
 * @param {{onClickAddPartiality: () => void}} param0
 * @returns {JSX.Element}
 */
const RemoveButton = ({ onClickRemovePartiality }) => {
  const onClick = () => {
    onClickRemovePartiality();
  };

  return (
    <div
      className="bg-[#D7D7D7] rounded-full w-8 h-8 flex items-center justify-center"
      onClick={onClick}
    >
      <img className="h-4 w-4" src={TrashIcon} alt="remove partiality" />
    </div>
  );
};
