import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import RealoadIcon from '@images/reaload-icon.svg';
import Arrow from '@images/arrow.svg';
import { modal } from '@components/ModalManagement';
import ModalPositionBrackets from '../Modal/ModalPositionBrackets';

const Map = (props) => {
  const [positionFocus, setPositionFocus] = useState([13, 1, null, null]);
  const [erros, setErrors] = useState([
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
    '0,0',
  ]);

  const isInteger = (value) => {
    return value.match(/^[0-9]+$/);
  };

  const refTopInput = useRef(null);
  const refBottomInput = useRef(null);

  const topNumeration = (num, orientation) => {
    return Array.from(Array(8).keys()).map((_, index) => (
      <div style={{ width: props.width ? props.width : 50, height: 134 }}>
        {((props.topData[index + num].split(',')[0] > 0 &&
          props.topData[index + num].split(',')[1] > 0) ||
          (positionFocus[2] === 0 && positionFocus[3] === index + num)) && (
          <>
            <div style={{ marginTop: -65 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 7,
                }}
              >
                <img src={Arrow} style={{ marginRight: 6 }} />
                <input
                  type="text"
                  ref={refTopInput}
                  onBlur={() => onBlurValidation(0, 'top', num)}
                  value={
                    props.topData
                      .find((v, i) => i === index + num)
                      .split(',')[0] || ''
                  }
                  onChange={(e) =>
                    isInteger(e.target.value) || e.target.value === ''
                      ? props.setTopData(
                          props.topData.map((v, i) =>
                            i === index + num
                              ? `${e.target.value ? e.target.value : '0'},${
                                  v.split(',')[1]
                                }`
                              : v,
                          ),
                        )
                      : {}
                  }
                  style={{
                    maxWidth: 28,
                    height: 30,
                    width: 28,
                    border: `1px solid ${
                      erros.find((v, k) => k === index + num && v === `error,0`)
                        ? '#C60E02'
                        : positionFocus[2] === 0 &&
                            positionFocus[3] === index + num
                          ? '#BDBDBDB2'
                          : 'transparent'
                    }`,
                    background: 'transparent',
                    textAlign: 'center',
                    borderRadius: 7,
                    padding: '4px, 8px, 6px, 9px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={Arrow}
                  style={{ marginRight: 6, transform: 'rotate(90deg)' }}
                />
                <input
                  type="text"
                  onBlur={() => onBlurValidation(1, 'top', num)}
                  value={
                    props.topData
                      .find((v, i) => i === index + num)
                      .split(',')[1] || ''
                  }
                  onChange={(e) =>
                    isInteger(e.target.value) || e.target.value === ''
                      ? props.setTopData(
                          props.topData.map((v, i) =>
                            i === index + num
                              ? `${v.split(',')[0]},${
                                  e.target.value ? e.target.value : '0'
                                }`
                              : v,
                          ),
                        )
                      : {}
                  }
                  style={{
                    maxWidth: 28,
                    height: 30,
                    width: 28,
                    border: `1px solid ${
                      erros.find((v, k) => k === index + num && v === `0,error`)
                        ? '#C60E02'
                        : positionFocus[2] === 0 &&
                            positionFocus[3] === index + num
                          ? '#BDBDBDB2'
                          : 'transparent'
                    }`,
                    background: 'transparent',
                    textAlign: 'center',
                    borderRadius: 7,
                    padding: '4px, 8px, 6px, 9px',
                  }}
                />
              </div>
            </div>
            {!props.disabled && (
              <div
                onClick={() => {
                  removeData(0, index + num);
                  refTopInput.current.focus();
                }}
                style={{
                  marginLeft: 17,
                  marginTop: -97,
                  marginBottom: 74,
                  cursor: 'pointer',
                  background: '#F6F6F8',
                  textAlign: 'center',
                  width: 24,
                  height: 24,
                  borderRadius: 23,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={RealoadIcon} />
              </div>
            )}
          </>
        )}
        {orientation === 'left' ? (
          <LabelNormal
            style={{ textAlign: 'center' }}
            color={
              props.disabledPointTop.find(
                (v, k) => k === index + num && v[0] !== '',
              )
                ? '#DBDBDB'
                : '#C2C2C2'
            }
            fontSize="17"
          >
            {Math.abs(props.initialNumerationTopLeft - (index + 1))}
          </LabelNormal>
        ) : (
          <LabelNormal
            style={{ textAlign: 'center' }}
            color={
              props.disabledPointTop.find(
                (v, k) => k === index + num && v[0] !== '',
              )
                ? '#DBDBDB'
                : '#C2C2C2'
            }
            fontSize="17"
          >
            {Math.abs(props.initialNumerationTopRight + (index + 1))}
          </LabelNormal>
        )}
      </div>
    ));
  };

  const bottomNumeration = (num, orientation) => {
    return Array.from(Array(8).keys()).map((_, index) => (
      <div style={{ width: props.width ? props.width : 50 }}>
        {orientation === 'left' ? (
          <LabelNormal
            style={{ textAlign: 'center' }}
            color={
              props.disabledPointBottom.find(
                (v, k) => k === index + num && v[0] !== '',
              )
                ? '#DBDBDB'
                : '#C2C2C2'
            }
            fontSize="17"
          >
            {Math.abs(props.initialNumerationBottomLeft - (index + 1))}
          </LabelNormal>
        ) : (
          <LabelNormal
            style={{ textAlign: 'center' }}
            color={
              props.disabledPointBottom.find(
                (v, k) => k === index + num && v[0] !== '',
              )
                ? '#DBDBDB'
                : '#C2C2C2'
            }
            fontSize="17"
          >
            {Math.abs(props.initialNumerationBottomRight + (index + 1))}
          </LabelNormal>
        )}

        {((props.bottomData[index + num].split(',')[0] > 0 &&
          props.bottomData[index + num].split(',')[1] > 0) ||
          (positionFocus[2] === 1 && positionFocus[3] === index + num)) && (
          <>
            {!props.disabled && (
              <div
                onClick={() => {
                  removeData(1, index + num);
                  refBottomInput.current.focus();
                }}
                style={{
                  marginLeft: 17,
                  marginBottom: -97,
                  marginTop: 74,
                  cursor: 'pointer',
                  background: '#F6F6F8',
                  textAlign: 'center',
                  width: 24,
                  height: 24,
                  borderRadius: 23,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={RealoadIcon} />
              </div>
            )}
            <div style={{ marginBottom: -65 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 7,
                }}
              >
                <img src={Arrow} style={{ marginRight: 6 }} />
                <input
                  type="text"
                  ref={refBottomInput}
                  onBlur={() => onBlurValidation(0, 'bottom', 0)}
                  value={
                    props.bottomData
                      .find((v, i) => i === index + num)
                      .split(',')[0] || ''
                  }
                  onChange={(e) =>
                    isInteger(e.target.value) || e.target.value === ''
                      ? props.setBottomData(
                          props.bottomData.map((v, i) =>
                            i === index + num
                              ? `${e.target.value ? e.target.value : '0'},${
                                  v.split(',')[1]
                                }`
                              : v,
                          ),
                        )
                      : {}
                  }
                  style={{
                    maxWidth: 28,
                    height: 30,
                    width: 28,
                    border: `1px solid ${
                      erros.find(
                        (v, k) => k === index + num + 16 && v === `error,0`,
                      )
                        ? '#C60E02'
                        : positionFocus[2] === 1 &&
                            positionFocus[3] === index + num
                          ? '#BDBDBDB2'
                          : 'transparent'
                    }`,
                    background: 'transparent',
                    textAlign: 'center',
                    borderRadius: 7,
                    padding: '4px, 8px, 6px, 9px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={Arrow}
                  style={{ marginRight: 6, transform: 'rotate(90deg)' }}
                />
                <input
                  type="text"
                  onBlur={() => onBlurValidation(1, 'bottom', 0)}
                  value={
                    props.bottomData
                      .find((v, i) => i === index + num)
                      .split(',')[1] || ''
                  }
                  onChange={(e) =>
                    isInteger(e.target.value) || e.target.value === ''
                      ? props.setBottomData(
                          props.bottomData.map((v, i) =>
                            i === index + num
                              ? `${v.split(',')[0]},${
                                  e.target.value ? e.target.value : '0'
                                }`
                              : v,
                          ),
                        )
                      : {}
                  }
                  style={{
                    maxWidth: 28,
                    height: 30,
                    width: 28,
                    border: `1px solid ${
                      erros.find(
                        (v, k) => k === index + num + 16 && v === `0,error`,
                      )
                        ? '#C60E02'
                        : positionFocus[2] === 1 &&
                            positionFocus[3] === index + num
                          ? '#BDBDBDB2'
                          : 'transparent'
                    }`,
                    background: 'transparent',
                    textAlign: 'center',
                    borderRadius: 7,
                    padding: '4px, 8px, 6px, 9px',
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    ));
  };

  const removeData = (i, index) => {
    if (i === 0) {
      const map = props.topData.map((v, key) => (key === index ? '0,0' : v));
      props.setTopData(map);
    } else {
      const map = props.bottomData.map((v, key) => (key === index ? '0,0' : v));
      props.setBottomData(map);
    }
  };

  const onBlurValidation = (input, position, num) => {
    const i = positionFocus[2];
    const index = positionFocus[3];
    let value = '';
    let valueAux = '';

    if (
      input === 0 &&
      position === 'top' &&
      props.topData.find((v, k) => k === index + num)
    ) {
      value = props.topData.find((v, k) => k === index + num).split(',')[0];
      valueAux = props.topData.find((v, k) => k === index + num).split(',')[1];
    }
    if (
      input === 1 &&
      position === 'top' &&
      props.topData.find((v, k) => k === index + num)
    ) {
      valueAux = props.topData.find((v, k) => k === index + num).split(',')[0];
      value = props.topData.find((v, k) => k === index + num).split(',')[1];
    }
    if (
      input === 0 &&
      position === 'bottom' &&
      props.bottomData.find((v, k) => k === index)
    ) {
      value = props.bottomData.find((v, k) => k === index).split(',')[0];
      valueAux = props.bottomData.find((v, k) => k === index).split(',')[1];
    }
    if (
      input === 1 &&
      position === 'bottom' &&
      props.bottomData.find((v, k) => k === index)
    ) {
      valueAux = props.bottomData.find((v, k) => k === index).split(',')[0];
      value = props.bottomData.find((v, k) => k === index).split(',')[1];
    }

    const adit = position === 'bottom' ? 16 : 0;

    if (
      (value === '' || value === 0 || value === '0') &&
      (valueAux === '' || valueAux === 0 || valueAux === '0')
    ) {
      setPositionFocus([null, null, null, null]);
    } else {
      if (value === '' || value === 0 || value === '0') {
        setErrors(
          erros.map((v, k) =>
            k === (i === 0 ? index : index + adit + num)
              ? `${input === 0 ? `error,0` : `0,error`}`
              : v,
          ),
        );
      } else {
        setErrors(
          erros.map((v, k) =>
            k === (i === 0 ? index : index + adit + num)
              ? `${
                  input === 0 ? `0,${v.split(',')[1]}` : `${v.split(',')[0]},0`
                }`
              : v,
          ),
        );
      }
      console.log(erros);
    }

    //setPositionFocus([null,null,null,null])
  };

  // const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  //     if (relatedTarget === null) return false;

  //     var node = relatedTarget.parentNode;

  //     while (node !== null) {
  //         if (node === currentTarget) return true;
  //         node = node.parentNode;
  //     }

  //     return false;
  // }

  // const onBlur = (e) => {
  //     if (!focusInCurrentTarget(e)) {
  //         console.log('table blurred');
  //     }
  // }

  return (
    <div
      style={{
        marginTop: 100,
        marginBottom: 100,
        marginLeft: 17,
        marginRight: 36,
        position: 'relative',
        width: props.width * 16,
      }}
    >
      {props.topData !== null && props.bottomData !== null && (
        <>
          <table style={{ borderSpacing: 0 }}>
            <tbody style={{ position: 'relative' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LabelNormal
                  color="#3C4549"
                  fontSize="17"
                  style={{
                    position: 'absolute',
                    top: props.height - 10,
                    left: -17,
                  }}
                >
                  R
                </LabelNormal>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: 2,
                    background: '#000',
                    top: props.height ? props.height : 101,
                    zIndex: 1,
                  }}
                />
                <LabelNormal
                  color="#3C4549"
                  fontSize="17"
                  style={{
                    position: 'absolute',
                    top: props.height - 10,
                    right: -17,
                  }}
                >
                  L
                </LabelNormal>
              </div>
              <div
                style={{
                  position: 'absolute',
                  width: 2,
                  height: props.height ? props.height * 2 + 50 : 202,
                  top: -25,
                  background: '#000',
                  left: '49.9%',
                  zIndex: 1,
                }}
              />
              <div style={{ position: 'absolute', top: -25, display: 'flex' }}>
                {topNumeration(0, 'left')}
                {topNumeration(8, 'right')}
              </div>
              {Array.from(Array(2).keys()).map((_, i) => (
                <tr>
                  {Array.from(Array(16).keys()).map((_, index) => (
                    <Contains
                      width={props.width}
                      height={props.height}
                      borderStyle={
                        positionFocus[3] === index && positionFocus[2] === i
                          ? 'none'
                          : 'solid'
                      }
                      backgroundColor={
                        (i === 0 &&
                          props.disabledPointTop.find(
                            (v, k) => k === index && v[0] !== '',
                          )) ||
                        (i === 1 &&
                          props.disabledPointBottom.find(
                            (v, k) => k === index && v[0] !== '',
                          ))
                          ? '#EDEDED'
                          : 'transparent'
                      }
                      cursor={
                        (i === 0 &&
                          props.disabledPointTop.find(
                            (v, k) => k === index && v[0] !== '',
                          )) ||
                        (i === 1 &&
                          props.disabledPointBottom.find(
                            (v, k) => k === index && v[0] !== '',
                          ))
                          ? 'not-allowed'
                          : 'default'
                      }
                    >
                      {(i === 0 &&
                        props.disabledPointTop.find(
                          (v, k) => k === index && v[0] !== '',
                        )) ||
                      (i === 1 &&
                        props.disabledPointBottom.find(
                          (v, k) => k === index && v[0] !== '',
                        )) ||
                      (positionFocus[3] === index && positionFocus[2] === i) ||
                      (i === 0 &&
                        +props.topData
                          .find((v, k) => k === index)
                          .split(',')[0] > 0 &&
                        +props.topData
                          .find((v, k) => k === index)
                          .split(',')[1] > 0) ||
                      (i === 1 &&
                        +props.bottomData
                          .find((v, k) => k === index)
                          .split(',')[0] > 0 &&
                        +props.bottomData
                          .find((v, k) => k === index)
                          .split(',')[1] > 0) ? null : props.disabled ? (
                        <LabelNormal
                          onClick={() => setPositionFocus([0, 0, i, index])}
                          style={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                          fontSize="12"
                          color="#C2C2C2"
                        >
                          FA
                        </LabelNormal>
                      ) : null}

                      <table
                        style={{
                          borderCollapse: 'collapse',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      >
                        {i === 0 ? (
                          <tbody>
                            {Array.from(Array(13).keys()).map((_, y) => (
                              <tr>
                                {Array.from(Array(10).keys()).map((_, x) => (
                                  <td
                                    onClick={() => {
                                      if (
                                        i === 0 &&
                                        props.disabledPointTop.find(
                                          (v, k) => k === index && v[0] === '',
                                        ) &&
                                        !props.disabled
                                      ) {
                                        //setPositionFocus([y, x, i, index]);
                                        modal.open(
                                          <ModalPositionBrackets
                                            number={Math.abs(
                                              i === 0 && index < 8
                                                ? 19 - (index + 1)
                                                : 21 + (index - 8),
                                            )}
                                            i={i}
                                            index={index}
                                            setData={props.setTopData}
                                            data={props.topData}
                                            positionFocus={positionFocus}
                                            setPositionFocus={setPositionFocus}
                                          />,
                                        );
                                        // props.setTopData(props.topData.map((v, k) => k === index ? `${y+1},${x+1}` : v))
                                      }
                                    }}
                                    style={{
                                      borderColor:
                                        positionFocus[2] === i &&
                                        positionFocus[3] === index
                                          ? '#E41D11'
                                          : 'transparent',
                                      borderWidth: 0.5,
                                      borderStyle: 'solid',
                                      height: 6,
                                      width: 6,
                                      background:
                                        (i === 0 &&
                                          +props.topData
                                            .find((v, k) => k === index)
                                            .split(',')[0] ===
                                            y + 1 &&
                                          +props.topData
                                            .find((v, k) => k === index)
                                            .split(',')[1] ===
                                            x + 1) ||
                                        (i === 1 &&
                                          +props.bottomData
                                            .find((v, k) => k === index)
                                            .split(',')[0] ===
                                            y + 1 &&
                                          +props.bottomData
                                            .find((v, k) => k === index)
                                            .split(',')[1] ===
                                            x + 1)
                                          ? '#EB9542'
                                          : 'transparent',
                                    }}
                                  >
                                    {/* {((i === 0 && (+props.topData.find((v,k) => k === (index)).split(',')[0]) === (y +1) && (+props.topData.find((v,k) => k === (index)).split(',')[1]) === (x+1)) || (i === 1 && (+props.bottomData.find((v,k) => k === (index)).split(',')[0]) === (y +1) && (+props.bottomData.find((v,k) => k === (index)).split(',')[1]) === (x+1))) ? <div style={{width: 3, height: 3, background: '#EB9542', borderRadius: 5}} /> : null} */}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            {Array.from(Array(13).keys())
                              .map((_, y) => (
                                <tr>
                                  {Array.from(Array(10).keys()).map((_, x) => (
                                    <td
                                      onClick={() => {
                                        if (
                                          i === 1 &&
                                          props.disabledPointBottom.find(
                                            (v, k) =>
                                              k === index && v[0] === '',
                                          ) &&
                                          !props.disabled
                                        ) {
                                          //setPositionFocus([y, x, i, index]);
                                          modal.open(
                                            <ModalPositionBrackets
                                              number={Math.abs(
                                                i === 1 && index < 8
                                                  ? 49 - (index + 1)
                                                  : 31 + (index - 8),
                                              )}
                                              i={i}
                                              index={index}
                                              setData={props.setBottomData}
                                              data={props.bottomData}
                                              positionFocus={positionFocus}
                                              setPositionFocus={
                                                setPositionFocus
                                              }
                                            />,
                                          );
                                          //props.setBottomData(props.bottomData.map((v, k) => k === index ? `${y+1},${x+1}` : v))
                                        }
                                      }}
                                      style={{
                                        borderColor:
                                          positionFocus[2] === i &&
                                          positionFocus[3] === index
                                            ? '#E41D11'
                                            : 'transparent',
                                        borderWidth: 0.5,
                                        borderStyle: 'solid',
                                        height: 6,
                                        width: 6,
                                        background:
                                          (i === 0 &&
                                            +props.bottomData
                                              .find((v, k) => k === index)
                                              .split(',')[0] ===
                                              y + 1 &&
                                            +props.bottomData
                                              .find((v, k) => k === index)
                                              .split(',')[1] ===
                                              x + 1) ||
                                          (i === 1 &&
                                            +props.bottomData
                                              .find((v, k) => k === index)
                                              .split(',')[0] ===
                                              y + 1 &&
                                            +props.bottomData
                                              .find((v, k) => k === index)
                                              .split(',')[1] ===
                                              x + 1)
                                            ? '#EB9542'
                                            : 'transparent',
                                      }}
                                    >
                                      {/* {((i === 0 && (+props.bottomData.find((v,k) => k === (index)).split(',')[0]) === (y +1) && (+props.bottomData.find((v,k) => k === (index)).split(',')[1]) === (x+1)) || (i === 1 && (+props.bottomData.find((v,k) => k === (index)).split(',')[0]) === (y +1) && (+props.bottomData.find((v,k) => k === (index)).split(',')[1]) === (x+1))) ? <div style={{width: 3, height: 3, background: '#EB9542', borderRadius: 5}} /> : null} */}
                                    </td>
                                  ))}
                                </tr>
                              ))
                              .reverse()}
                          </tbody>
                        )}
                      </table>
                    </Contains>
                  ))}
                </tr>
              ))}
              <div
                style={{ position: 'absolute', bottom: -25, display: 'flex' }}
              >
                {bottomNumeration(0, 'left')}
                {bottomNumeration(8, 'right')}
              </div>
            </tbody>
          </table>
          {erros.filter((v) => v === 'error,0' || v === '0,error').length >
            0 && (
            <LabelNormal
              style={{ color: '#C60E02', fontSize: 17, marginTop: 100 }}
            >
              *Modifica el IPR de al menos un diente o seleccina Posicionar
              todos los brackets en punto FA
            </LabelNormal>
          )}
        </>
      )}
    </div>
  );
};

const Contains = styled.td`
  position: relative;
  height: ${({ height }) => (height ? height : '101.75')}px;
  width: ${({ width }) => (width ? width : '50px')};
  border-style: ${({ borderStyle }) => (borderStyle ? borderStyle : 'dashed')};
  border-color: ${({ borderColor }) => (borderColor ? borderColor : '#C8C8C8')};
  border-width: 1px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};
  cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 0px;
`;

export default Map;
