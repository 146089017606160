import React, { Fragment } from 'react';
import { UilFileBlank } from '@iconscout/react-unicons';
import FeatherIcons from 'feather-icons-react';

import styled from 'styled-components';
import Input from '@components/shared/Input';
import ButtonPrime from '@components/shared/ButtonPrime';
import { PlanningTotalAligners } from '../Planning/TotalAligners';
import { PlanningAlignerPartialities } from '../Planning/AlignerPartialities';

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17rem;
  height: 2.8rem;
  background-color: #f6f6f8;
  box-shadow: 0px 0px 8px rgba(162, 162, 162, 0.1);
  border-radius: 5px;
  margin: 0.6rem 0 1.9rem 0.3rem;
`;

export const PlanningReviewCrystAligner = ({
  alignerSendType,
  alineadoresInferiores,
  alineadoresSuperiores,
  calculateAlineadores,
  errorMessages,
  handleOnChange,
  idCase,
  isEditMode,
  linkvisor,
  linkvisorPatient,
  linkvisordesk,
  mp4FileName,
  mp4LinkVisor,
  onUploadMP4ForOtherInputRef,
  openVisorDesck,
  partialAligner,
  partialities,
  partialitiesErrorMessages,
  progressUpload,
  refInput,
  setAlignerSendType,
  setAlineadoresInferiores,
  setAlineadoresSuperiores,
  setFileMp4,
  setIdCase,
  setLinkVisor,
  setLinkVisorDesk,
  setLinkVisorPatient,
  setPartialities,
  setPartialitiesErrorMessages,
  treatment,
}) => {
  const enablePartialities =
    process.env.REACT_APP_ENABLE_PARTIALITIES === 'true';
  return (
    <div className="col-md-12" style={{ maxWidth: 1145, marginTop: 10 }}>
      <LabelNormal color="#757575" fontSize="22">
        Datos de planificación
      </LabelNormal>

      {treatment?.planning_software === 'Nemocast' &&
        treatment?.treatmentStatus.type === 'planning' &&
        (treatment?.treatmentStatus.index !== 11 ||
          treatment?.treatmentStatus.index !== 4) && (
          <LabelNormal color="#757575" fontSize="17">
            {isEditMode
              ? 'Edita los datos de la planificación.'
              : 'Esta información es la que se le muestra al doctor en el visor del caso.'}
          </LabelNormal>
        )}

      <div className="row" style={{ marginTop: 26 }}>
        <div className="col-md-4">
          <LabelNormal
            color="#3C4549"
            fontSize="18"
            style={{ fontWeight: 'bolder' }}
          >
            Software
          </LabelNormal>
          <LabelNormal color="#3C4549" fontSize="17">
            {treatment?.planning_software}
          </LabelNormal>
        </div>
        <div className="col-md-4">
          {!isEditMode ? (
            <>
              <LabelNormal
                color="#3C4549"
                fontSize="18"
                style={{ fontWeight: 'bolder' }}
              >
                {treatment?.planning_software === 'ARCAD'
                  ? 'ID de caso de ARCAD'
                  : 'ID de caso'}
              </LabelNormal>
              <LabelNormal color="#3C4549" fontSize="17">
                {idCase}
              </LabelNormal>
            </>
          ) : (
            <Fragment>
              <Input
                label={
                  treatment?.planning_software === 'ARCAD'
                    ? 'ID de caso de ARCAD'
                    : 'ID de caso'
                }
                placeholder="ID"
                value={idCase}
                className={errorMessages.idCase && 'etftxW'}
                onChange={(e) => {
                  setIdCase(e.target.value);
                  handleOnChange(e.target.value, 'idCase');
                }}
                style={{ marginTop: 10 }}
              />
              {errorMessages.idCase && (
                <div style={{ marginBottom: 15 }}>
                  <LabelNormal fontSize="17" color="#C60E02">
                    {errorMessages.idCase}
                  </LabelNormal>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {treatment?.planning_software === 'Nemocast' &&
        treatment?.treatmentStatus.type === 'planning' && (
          <div className="row">
            <div className="col-md-4">
              <LabelNormal
                fontSize="17"
                style={{
                  marginBottom: '0.6rem',
                  marginTop: '0.6rem',
                }}
              >
                Para la revisión del doctor
              </LabelNormal>

              {!isEditMode ? (
                <>
                  <LabelNormal
                    fontSize="18"
                    color="#3C4549"
                    style={{ fontWeight: '600' }}
                  >
                    Link de video para ver visor
                  </LabelNormal>
                  <InlineContainer
                    style={{
                      alignItems: 'center',
                      marginTop: 10,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      linkvisor.includes('http')
                        ? window.open(linkvisor, '_blank')
                        : window.open(`http://${linkvisor}`, '_blank')
                    }
                  >
                    <LabelNormal fontSize="17" color="#757575">
                      {linkvisor.includes('http')
                        ? linkvisor
                        : `http://${linkvisor}`}
                    </LabelNormal>
                  </InlineContainer>
                </>
              ) : (
                <Fragment>
                  <Input
                    label={'Link de video para ver visor'}
                    placeholder="Link"
                    style={{
                      marginTop: 10,
                    }}
                    onChange={(e) => {
                      setLinkVisor(e.target.value);
                      handleOnChange(e.target.value, 'linkvisor');
                    }}
                    className={errorMessages.linkvisor && 'etftxW'}
                    value={linkvisor}
                    validation="url"
                    type="url"
                  />
                  {errorMessages.linkvisor && (
                    <div style={{ marginBottom: 15 }}>
                      <LabelNormal fontSize="17" color="#C60E02">
                        {errorMessages.linkvisor}
                      </LabelNormal>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            <div className="col-md-4">
              <LabelNormal
                fontSize="17"
                style={{
                  marginBottom: '0.6rem',
                  marginTop: '0.6rem',
                }}
              >
                Para que el doctor comparta con el paciente
              </LabelNormal>
              <LabelNormal
                fontSize="18"
                color="#3C4549"
                style={{ fontWeight: '600' }}
              >
                Link de visor para compartir con paciente
              </LabelNormal>

              {!isEditMode && (
                <VideoWrapper
                  style={{
                    marginBottom: 0,
                    padding: 11,
                  }}
                >
                  <UilFileBlank size="35" color="#757575" />
                  <LabelNormal
                    color="#828282"
                    fontSize="17"
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      direction: 'rtl',
                    }}
                    onClick={() => window.open(mp4LinkVisor, '_blank')}
                  >
                    {mp4FileName}
                  </LabelNormal>
                </VideoWrapper>
              )}

              <div
                className="col-12"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {isEditMode && (
                  <>
                    <VideoWrapper
                      style={{
                        marginLeft: -10,
                        marginRight: 30,
                        marginBottom: 0,
                      }}
                    >
                      <UilFileBlank size="24" color="#757575" />
                      <LabelNormal
                        color="#828282"
                        fontSize="17"
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          direction: 'rtl',
                        }}
                      >
                        <a
                          href={mp4LinkVisor}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontSize: 17,
                            fontFamily: 'neue-montreal-regular',
                            color: '#828282',
                            margin: 5,
                          }}
                        >
                          {progressUpload > 0
                            ? `${progressUpload}%`
                            : mp4FileName}
                        </a>
                      </LabelNormal>
                    </VideoWrapper>
                    <ButtonPrime
                      label="Cambiar archivo"
                      fontSize="17"
                      background="transparent"
                      colorEnabled="#436FF1"
                      color="#757575"
                      border="none"
                      backgroundEnabled="transparent"
                      onClick={() => refInput.current.click()}
                      style={{ textDecoration: 'underline' }}
                    />
                    <input
                      type="file"
                      accept=".mp4"
                      ref={refInput}
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        onUploadMP4ForOtherInputRef(e);
                        setFileMp4(true);
                        handleOnChange('fileMp4', 'fileMp4');
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      {treatment?.planning_software === 'ARCAD' && (
        <>
          <h2 className="text-2xl font-medium text-[#3C4549] border-t border-[#9E9E9E] pt-8 mt-8">
            Visor de planificación
          </h2>
          <LabelNormal color="#3C4549" fontSize="17" style={{ marginTop: 20 }}>
            Para la revisión del doctor
          </LabelNormal>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-md-4">
              {!isEditMode ? (
                <>
                  <LabelNormal color="#757575" fontSize="18">
                    {'Link del visor para abrir en software de escritorio'}
                  </LabelNormal>
                  <LabelNormal color="#757575" fontSize="17">
                    {linkvisordesk}
                  </LabelNormal>
                </>
              ) : (
                <>
                  <Input
                    label={
                      'Link del visor para abrir en software de escritorio'
                    }
                    placeholder="Link"
                    style={{ marginTop: 10, textColor: '#757575' }}
                    onChange={(e) => {
                      setLinkVisorDesk(e.target.value);
                      handleOnChange(e.target.value, 'linkvisordesk');
                    }}
                    value={linkvisordesk}
                  />
                  {treatment?.planning_software === 'ARCAD' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 3,
                        left: 340,
                        zIndex: 99,
                      }}
                      className="tooltip"
                    >
                      <FeatherIcons
                        icon="help-circle"
                        size="15"
                        color="#BDBDBD"
                      />
                      <span className="tooltiptext">
                        <LabelNormal
                          color="#757575"
                          fontSize="14"
                          style={{ textAlign: 'left' }}
                        >
                          Copia y pega directo el link desde Smile Design Viewer{' '}
                        </LabelNormal>
                        <img
                          alt="instrucciónARCAD"
                          src={require('@images/tooltipARCAD.png')}
                          style={{ height: 219, width: 247 }}
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-md-4">
              {!isEditMode ? (
                <>
                  <LabelNormal color="#3C4549" fontSize="18">
                    {'Link de visor para abrir en web'}
                  </LabelNormal>
                  <LabelNormal
                    color="#3C4549"
                    fontSize="17"
                    style={{ marginTop: 30 }}
                  >
                    {linkvisor}
                  </LabelNormal>
                </>
              ) : (
                <Input
                  label={'Link de visor para abrir en web'}
                  placeholder="Link"
                  style={{ marginTop: 30 }}
                  onChange={(e) => {
                    setLinkVisor(e.target.value);
                    handleOnChange(e.target.value, 'linkvisor');
                  }}
                  value={linkvisor}
                />
              )}
            </div>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-md-4">
              <LabelNormal
                color="#3C4549"
                fontSize="17"
                style={{ marginTop: 20 }}
              >
                Para que el doctor lo comparta con el paciente
              </LabelNormal>
              {!isEditMode ? (
                <LabelNormal
                  color="#3C4549"
                  fontSize="17"
                  style={{ marginTop: 30 }}
                >
                  {linkvisorPatient}
                </LabelNormal>
              ) : (
                <Input
                  label="Link de visor para compartir con paciente"
                  placeholder="Link"
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    setLinkVisorPatient(e.target.value);
                    handleOnChange(e.target.value, 'linkvisorPatient');
                  }}
                  value={linkvisorPatient}
                  validation="url"
                  type="url"
                />
              )}
            </div>
          </div>
          {treatment?.planning_software === 'ARCAD' && (
            <div className="mt-8">
              <ButtonPrime
                background="transparent"
                border="1px solid #3C4549"
                color="#3C4549"
                icon={'eye'}
                label={
                  'Abrir visor en escritorio con ' +
                  treatment?.planning_software
                }
                onClick={() => openVisorDesck()}
              />
              <InlineContainer
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LabelNormal color="#757575" fontSize="17">
                  ¿No lo tienes descargado? Descárgalo
                </LabelNormal>
                <LabelNormal
                  color="#757575"
                  fontSize="17"
                  style={{
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_URL}/files/smilestudioinstaller.exe`,
                      '_blank'
                    )
                  }
                >
                  aquí.
                </LabelNormal>
              </InlineContainer>
              <InlineContainer
                style={{
                  alignItems: 'center',
                  marginTop: 20,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  linkvisor.includes('http')
                    ? window.open(linkvisor, '_blank')
                    : window.open(`http://${linkvisor}`, '_blank')
                }
              >
                <FeatherIcons icon="external-link" color="#757575" />
                <LabelNormal fontSize="17" color="#757575">
                  Abrir visor en web
                </LabelNormal>
              </InlineContainer>
            </div>
          )}
          {treatment?.treatmentType.id === '1' && (
            <>
              <PlanningTotalAligners
                alineadoresInferiores={alineadoresInferiores}
                alineadoresSuperiores={alineadoresSuperiores}
                calculateAlineadores={calculateAlineadores}
                errorMessages={errorMessages}
                handleOnChange={handleOnChange}
                isEditMode={isEditMode}
                setAlineadoresInferiores={setAlineadoresInferiores}
                setAlineadoresSuperiores={setAlineadoresSuperiores}
              />
              {enablePartialities && (
                <PlanningAlignerPartialities
                  alignerSendType={alignerSendType}
                  bottomAligners={alineadoresInferiores}
                  canAddPartiality={partialAligner.canAddPartiality}
                  canRemovePartiality={partialAligner.canRemovePartiality}
                  errorMessages={partialitiesErrorMessages}
                  isAddPartialityEnabled={partialAligner.isAddPartialityEnabled}
                  isEditMode={isEditMode}
                  partialities={partialities}
                  setAlignerSendType={setAlignerSendType}
                  setErrorMessages={setPartialitiesErrorMessages}
                  setPartialities={setPartialities}
                  upperAligners={alineadoresSuperiores}
                  validateErrors={partialAligner.validateErrors}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
