import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputSearch from '@components/shared/InputSearch';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import DropdownCustom from '@components/shared/DropdownCustom';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Card from '@components/shared/Card';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_DOCTORS, QUERY_GET_MY_COUNTRIES } from '@graphql/query';
import { useQuery } from '@apollo/client';
import { loadPhotoProfileGender } from '../../utils';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelNormal = styled.p`
  font-size: 9px;
  font-family: neue-montreal-regular;
  color: #757575;
  margin: 0px;
`;

const TitleWithoutContent = styled.h1`
  font-family: neue-montreal-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #bdbdbd;
`;

const options = [
  { id: 'Por aprobar', name: 'Por aprobar' },
  { id: 'Aprobados', name: 'Aprobados' },
  { id: 'Con casos activos', name: 'Con casos activos' },
  { id: 'Sin casos', name: 'Sin casos' },
  { id: 'Declinados', name: 'Declinados' },
  { id: '', name: 'Universidades' },
];

const Doctors = (props) => {
  const history = useHistory();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [doctors, setDoctors] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState([
    'Por aprobar',
    'Aprobados',
    'Con casos activos',
    'Sin casos',
    'Declinados',
    '',
  ]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountryLabel, setSelectedCountryLabel] = useState('');

  const formatter = new Intl.ListFormat('es', {
    style: 'long',
    type: 'conjunction',
  });

  const [getDoctors] = useLazyQuery(QUERY_GET_DOCTORS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getDoctorsByFilters) {
        setDoctors(data.getDoctorsByFilters);
      }
      setLoadingSkeleton(false);
    },
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    if (search !== '') {
      getDoctors({
        variables: {
          filters: selectedOption,
          search,
          countries: selectedCountries.map((o) => +o),
        },
      });
    } else {
      getDoctors({
        variables: {
          filters: selectedOption,
          countries: selectedCountries.map((o) => +o),
        },
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    setLoadingSkeleton(true);
    if (search !== '') {
      getDoctors({
        variables: {
          filters: selectedOption,
          search,
          countries: selectedCountries.map((o) => +o),
        },
      });
    } else {
      getDoctors({
        variables: {
          filters: selectedOption,
          countries: selectedCountries.map((o) => +o),
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (search !== '') {
      getDoctors({
        variables: {
          filters: selectedOption,
          countries: selectedCountries.map((o) => +o),
          search,
        },
      });
    } else {
      getDoctors({
        variables: {
          filters: selectedOption,
          countries: selectedCountries.map((o) => +o),
          search,
        },
      });
    }
  }, [selectedCountries]);

  const { loading, data } = useQuery(QUERY_GET_MY_COUNTRIES, {
    onCompleted: (data) => {
      setCountries(data.getCountries);

      setSelectedCountries(data.getCountries.map((country) => country.id));
      setSelectedCountryLabel(
        formatter.format(data.getCountries.map((country) => country.name)),
      );
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="doctores" />
      <ContainHome>
        <Header style={{ marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <InlineContainer style={{ justifyContent: 'space-between' }}>
            <InlineContainer>
              <InputSearch
                placeholder="Busca por nombre o ID"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <DropdownCustom
                label="Filtros"
                data={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              {!loading && countries.length > 0 && (
                <DropdownCustom
                  label="País"
                  data={countries}
                  selectedOption={selectedCountries}
                  setSelectedOption={(options) => {
                    const countriesSelected = countries.filter((country) =>
                      options.includes(country.id),
                    );
                    setSelectedCountryLabel(
                      formatter.format(
                        countriesSelected.map((country) => country.name),
                      ),
                    );
                    setSelectedCountries(options);
                  }}
                />
              )}
              <LabelNormal
                style={{
                  fontSize: 15,
                  marginRight: 3,
                  marginTop: 3,
                }}
              >
                {!selectedCountries.length
                  ? 'No se ha seleccionado ningun país de la lista'
                  : `Se muestran doctores de ${selectedCountryLabel}`}
              </LabelNormal>
            </InlineContainer>
          </InlineContainer>
          <div className="bootstrap-wrapper" style={{ marginTop: 43 }}>
            {!loadingSkeleton ? (
              <div
                className="row"
                style={
                  doctors.length <= 0
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                      }
                    : {}
                }
              >
                {doctors.length > 0 ? (
                  <>
                    {doctors.map((item) => (
                      <div
                        style={{ marginTop: 15, padding: 10 }}
                        key={Math.random()}
                      >
                        <Card
                          style={{
                            borderColor:
                              item.status === 'pending'
                                ? '#F3A518'
                                : 'transparent',
                            borderWidth: 1,
                            borderStyle: 'solid',
                          }}
                          to={
                            item.status === 'pending' ||
                            item.status === 'decline'
                              ? `/app/doctores/formulario-solicitud-doctor/${item.id}`
                              : `/app/doctores/perfil-doctor/${item.id}`
                          }
                        >
                          <InlineContainer
                            style={{
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                            }}
                          >
                            <img
                              src={
                                item.user.photo_filename
                                  ? `${process.env.REACT_APP_API_URL}/users/${item.user.id}/profile/${item.user.photo_filename}`
                                  : loadPhotoProfileGender(
                                      item.user.gender,
                                      'doctor',
                                    )
                              }
                              style={{
                                height: 36,
                                width: 36,
                                borderRadius: 50,
                              }}
                            />
                            {item.status === 'pending' && (
                              <div
                                style={{
                                  width: 12.13,
                                  height: 12.13,
                                  borderRadius: 38.43,
                                  background: '#EB9542',
                                }}
                              />
                            )}
                          </InlineContainer>
                          <InlineContainer style={{ marginTop: 14 }}>
                            <LabelNormal
                              style={{
                                fontSize: 12,
                                color: '#436FF1',
                                marginRight: 3,
                                marginTop: 3,
                              }}
                            >
                              Dr.
                            </LabelNormal>
                            <LabelNormal
                              style={{
                                color: '#000000',
                                fontSize: 15,
                                marginTop: 3,
                              }}
                            >{`${item.user.first_name} ${item.user.last_name}`}</LabelNormal>
                          </InlineContainer>
                          <div style={{ marginTop: 8 }}>
                            {item.status !== 'approved' && (
                              <LabelNormal
                                style={{ color: '#828282', fontSize: 15.5 }}
                              >
                                {item.status === 'decline'
                                  ? 'Registro declinado'
                                  : item.status === 'disabled'
                                    ? 'Desactivado'
                                    : item.status === 'pending'
                                      ? 'Esperando aprobación'
                                      : ''}
                              </LabelNormal>
                            )}
                            {item.status === 'approved' && (
                              <InlineContainer>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="8"
                                  style={{ marginRight: 5 }}
                                >
                                  CASOS ACTIVOS
                                </LabelNormal>
                                <LabelNormal color="#BDBDBD" fontSize="10">
                                  {item.count_treatments}
                                </LabelNormal>
                                <LabelNormal
                                  color="#757575"
                                  fontSize="8"
                                  style={{ marginRight: 5, marginLeft: 7 }}
                                >
                                  CASOS TOTALES
                                </LabelNormal>
                                <LabelNormal color="#BDBDBD" fontSize="10">
                                  {item.count_total_treatments}
                                </LabelNormal>
                              </InlineContainer>
                            )}
                          </div>
                        </Card>
                      </div>
                    ))}
                  </>
                ) : (
                  <div style={{}}>
                    <TitleWithoutContent>
                      Aún no hay <br /> doctores registrados
                    </TitleWithoutContent>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div style={{ display: 'flex' }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  {Array.from(Array(5).keys()).map(() => (
                    <div style={{ padding: '0px 17px 0px 0px' }}>
                      <Skeleton
                        height={128}
                        borderRadius={27}
                        width={262}
                        baseColor={'#F6F6F8'}
                        highlightColor={'#FFF'}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Doctors;
