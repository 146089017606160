import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import {
  QUERY_GET_PATIENT,
  QUERY_GET_PATIENT_FILES,
  QUERY_GET_UNIVERSITY_ID,
} from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Input from '@components/shared/Input';
import History from '@components/shared/History';
import ButtonPrime from '@components/shared/ButtonPrime';
import { University } from '@components/Icons/University';
import {
  MUTATION_UPDATE_PATIENT,
  MUTATION_ADD_PHOTO_PATIENT,
} from '@graphql/mutation';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { modal } from '@components/ModalManagement';
import { loadPhotoProfileGender } from '../../utils';
import Skeleton from 'react-loading-skeleton';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const HeaderCard = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  min-width: 198px;
  padding: 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const CategoryCard = styled.div`
  background: ${({ background }) => (background ? background : '#828282')};
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ButtonReactive = styled(Link)`
  border-radius: 69px;
  min-width: 180px;
  min-height: 46px;
  text-decoration: none;
  background: ${({ background }) => (background ? background : '#C8C8C8')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`;
const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;
const CardNote = styled.div`
  max-height: 199px;
  border-radius: 30px;
  background: #fff;
`;

const Case = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const [edit, setEdit] = useState(false);
  const [historyClinic, setHistoryClinic] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [treatment, setTreatment] = useState('');
  const [gender, setGender] = useState('');
  const [day, setDay] = useState('');
  const [country, setCountry] = useState('');
  const [category, setCategory] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState({});

  const [visorUrl, setVisorUrl] = useState('');
  const [doctorId, setDoctorId] = useState('');

  const [firstNameDT, setFirstNameDT] = useState('');
  const [lastNameDT, setLastNameDT] = useState('');
  const [photoDT, setPhotoDT] = useState('');
  const [statusTreatment, setStatusTreatment] = useState({});
  const [treatmentId, setTreatmentId] = useState('');

  const [firstNameTA, setFirstNameTA] = useState('');
  const [lastNameTA, setLastNameTA] = useState('');
  const [photoTA, setPhotoTA] = useState('');

  const [selectItem, setSelectItem] = useState('Formulario');
  const [filesTreatment, setFilesTreatment] = useState({});
  const [filesTreatmentStl, setFilesTreatmentSlt] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});
  const [sendModelStl, setSendModelStl] = useState(false);
  const [sendLinkStl, setSendLinkStl] = useState(false);
  const [moreSmartchecks, setMoreSmartchecks] = useState(false);
  const [urlSTL, setUrlSTL] = useState('');
  const [histories, setHistories] = useState([]);
  const [archived, setArchived] = useState(false);

  const [universityName, setUniversityName] = useState('');
  const [universityId, setUniversityId] = useState('');

  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setDoctorId(patient.doctor.id);

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setPhone(patient.phone);
        setGender(patient.gender);
        setPhoto(
          patient.photo_filename
            ? `${process.env.REACT_APP_API_URL}/patients/${id}/profile/${patient.photo_filename}`
            : ''
        );
        setEmail(patient.email);
        setTreatment(patient.treatment);
        setVisorUrl(patient.treatment_related.visor_url);
        setHistories(patient.treatment_related.histories);
        setArchived(patient.treatment_related.archived);
        setMoreSmartchecks(patient.treatment_related.more_smartchecks);
        if (patient.birthdate) {
          setDay(patient.birthdate.split('-')[2]);
          setMonth(patient.birthdate.split('-')[1]);
          setYear(patient.birthdate.split('-')[0]);
        }
        if (patient.treatment_related.doctor) {
          setCategory(patient.treatment_related.doctor.category);
          setFirstNameDT(patient.treatment_related.doctor.user.first_name);
          setLastNameDT(patient.treatment_related.doctor.user.last_name);
          setPhotoDT(
            patient.treatment_related.doctor.user.photo_filename
              ? `${process.env.REACT_APP_API_URL}/users/${patient.treatment_related.doctor.user.id}/profile/${patient.treatment_related.doctor.user.photo_filename}`
              : ''
          );
          setCountry(patient.treatment_related.doctor.user.country.name);
        }

        if (patient.treatment_related.teacher) {
          setFirstNameTA(patient.treatment_related.teacher.user.first_name);
          setLastNameTA(patient.treatment_related.teacher.user.last_name);
          setPhotoTA(
            patient.treatment_related.teacher.user.photo_filename
              ? `${process.env.REACT_APP_API_URL}/users/${patient.treatment_related.teacher.user.id}/profile/${patient.treatment_related.teacher.user.photo_filename}`
              : ''
          );
        }
        setStatusTreatment(patient.treatment_related.treatmentStatus);
        setTreatmentId(patient.treatment_related.id);

        setUniversityName(patient.doctor.university.name);
        setUniversityId(patient.doctor.university.id);
      }
      setLoadingSkeleton(false);
    },
    onError: (err) => console.log(err),
  });

  const [getUniversity] = useLazyQuery(QUERY_GET_UNIVERSITY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.university) {
        const university = data.university;

        setStudents(university.students);
        setTeachers(university.teachers);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getUniversity({ variables: { id: universityId } });
  }, [universityId]);

  const [getPatientFiles] = useLazyQuery(QUERY_GET_PATIENT_FILES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getPatientFiles) {
        const patientfiles = data.getPatientFiles;

        let decode = {};
        patientfiles.map((item) => {
          decode = {
            ...decode,
            [item.name]: {
              type: item.file_type,
              name: item.file_name,
              url: item.fileUrl,
            },
          };
        });
        setFilesTreatment(decode);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    setLoadingSkeleton(true);
    getPatient({ variables: { id } });
    getPatientFiles({ variables: { patient_id: id, onlyErrors: false } });
  }, []);

  const [updatePatient] = useMutation(MUTATION_UPDATE_PATIENT, {
    onCompleted: (data) => {
      if (file.name) addProfilePhoto({ variables: file, patient_id: +id });
      else {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => {
                setEdit(false);
                getPatient({ variables: { id } });
              })
            }
          >
            <LabelNormal
              color="#060809"
              fontSize="22"
              style={{ marginTop: 30 }}
            >
              Cambios a perfil guardados
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_PHOTO_PATIENT, {
    onCompleted: (data) => {
      modal.open(
        <ModalSuccess
          onClick={() =>
            modal.close(() => {
              setEdit(false);
              getPatient({ variables: { id } });
            })
          }
        >
          <LabelNormal color="#060809" fontSize="22" style={{ marginTop: 30 }}>
            Cambios a perfil guardados
          </LabelNormal>
        </ModalSuccess>
      );
    },
  });

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  const maskPhone = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 2)} ${val.substring(2, 6)} ${val.substring(
      6,
      val.length
    )}`;
    num = num.trim();
    setPhone(num);
  };

  const backHistoryClinic = () => {
    setHistoryClinic(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => (historyClinic ? backHistoryClinic() : {})}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              {!edit && !historyClinic && (
                <div className="col-md-12">
                  <Title>Caso</Title>
                  <div style={{ marginTop: 10, marginBottom: 39 }}>
                    <Breadcrumb
                      elements={1}
                      onClick={() => history.goBack()}
                      mainLabel={'Casos'}
                      endLabel={`${firstName} ${lastName}`}
                    />
                  </div>
                </div>
              )}
              {edit && (
                <div className="col-md-12" style={{ marginBottom: 20 }}>
                  <Title>Editar perfil</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Edita los datos y guarda los cambios.
                  </LabelNormal>
                </div>
              )}
              {historyClinic && (
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                  <Title>Historial clínico</Title>
                  <LabelNormal color="#757575" fontSize="17">
                    Consulta los archivos de tu paciente.
                  </LabelNormal>
                </div>
              )}
              <div className={historyClinic ? 'col-md-9' : 'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    <InlineContainer>
                      {!loadingSkeleton ? (
                        <div>
                          <Avatar
                            src={photo ? photo : loadPhotoProfileGender(gender)}
                            floatButton={edit}
                            edit={!!photo}
                            onChange={onChange}
                            height="161"
                            width="161"
                            borderRadius="30"
                          />
                        </div>
                      ) : (
                        <div>
                          <Skeleton
                            height={161}
                            borderRadius={27}
                            width={161}
                            baseColor={'#EEEEF2'}
                            highlightColor={'#FFF'}
                          />
                        </div>
                      )}
                      {!loadingSkeleton ? (
                        <>
                          {!edit && !historyClinic && (
                            <div style={{ marginLeft: 35 }}>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <LabelNormal
                                  color="#436FF1"
                                  fontSize="21"
                                  style={{ marginRight: 7 }}
                                >
                                  PX.
                                </LabelNormal>
                                <LabelNormal
                                  fontSize="30"
                                  color="#3C4549"
                                >{`${firstName} ${lastName}`}</LabelNormal>
                              </InlineContainer>
                              <InlineContainer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <InlineContainer>
                                      <LabelNormal
                                        color="#4F4F4F"
                                        fontSize="20"
                                      >
                                        ID
                                      </LabelNormal>
                                      <LabelNormal
                                        color="#BDBDBD"
                                        fontSize="20"
                                      >
                                        {id}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  <div className="col-md-8">
                                    <InlineContainer
                                      style={{ alignItems: 'center' }}
                                    >
                                      <FeatherIcon icon="phone" size={19} />
                                      <LabelNormal
                                        fontSize="14"
                                        color="#3C4549"
                                      >
                                        {phone}
                                      </LabelNormal>
                                    </InlineContainer>
                                  </div>
                                  {email && (
                                    <div className="col-md-7">
                                      <InlineContainer
                                        style={{ alignItems: 'center' }}
                                      >
                                        <FeatherIcon icon="mail" size={19} />
                                        <LabelNormal
                                          fontSize="14"
                                          color="#3C4549"
                                        >
                                          {email}
                                        </LabelNormal>
                                      </InlineContainer>
                                    </div>
                                  )}
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            marginLeft: 35,
                            marginTop: 10,
                            width: '100%',
                          }}
                        >
                          <div>
                            <Skeleton
                              baseColor={'#F6F6F8'}
                              width={'70%'}
                              height={27}
                              borderRadius={5}
                              highlightColor={'#FFF'}
                            />
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <div className="row">
                              <div className="col-md-5">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-7">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 10 }}>
                              <div className="col-md-5">
                                <Skeleton
                                  count={1}
                                  baseColor={'#F6F6F8'}
                                  height={27}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {historyClinic && (
                        <div style={{ marginLeft: 35 }}>
                          <InlineContainer style={{ alignItems: 'center' }}>
                            <LabelNormal
                              fontSize="30"
                              color="#3C4549"
                            >{`${firstName} ${lastName}`}</LabelNormal>
                          </InlineContainer>
                          <InlineContainer>
                            <div className="row">
                              <div className="col-md-12">
                                <InlineContainer
                                  style={{
                                    alignItems: 'center',
                                    marginBottom: 10,
                                  }}
                                >
                                  <LabelNormal color="#828282" fontSize="13">
                                    Tratamiento
                                  </LabelNormal>
                                  <LabelNormal
                                    color={
                                      treatment === 'cryst_aligner'
                                        ? '#18A1DC'
                                        : '#F0BB00'
                                    }
                                    fontSize="20.45"
                                  >
                                    {treatment === 'cryst_aligner'
                                      ? 'Cryst Aligner'
                                      : 'Smile System'}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="user" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {gender}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div className="col-md-4">
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="phone" />
                                  <LabelNormal fontSize="14" color="#3C4549">
                                    {phone}
                                  </LabelNormal>
                                </InlineContainer>
                              </div>
                              <div
                                className="col-md-5"
                                style={{ marginTop: 8 }}
                              >
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon size="19" icon="gift" />
                                  <LabelNormal
                                    fontSize="14"
                                    color="#3C4549"
                                  >{`${year}-${month}-${day}`}</LabelNormal>
                                </InlineContainer>
                              </div>
                              {email && (
                                <div
                                  className="col-md-7"
                                  style={{ marginTop: 8 }}
                                >
                                  <InlineContainer
                                    style={{ alignItems: 'center' }}
                                  >
                                    <img
                                      src={require('@images/icons/email.png')}
                                      style={{ height: 16, width: 15.5 }}
                                    />
                                    <LabelNormal fontSize="14" color="#3C4549">
                                      {email}
                                    </LabelNormal>
                                  </InlineContainer>
                                </div>
                              )}
                            </div>
                          </InlineContainer>
                        </div>
                      )}
                    </InlineContainer>
                  </div>
                  {!edit && !historyClinic && (
                    <div className="col-md-12" style={{ marginTop: 47 }}>
                      <HeaderCard>
                        <LabelNormal color="#3C4549" fontSize="17">
                          Historial
                        </LabelNormal>
                      </HeaderCard>
                      {!loadingSkeleton ? (
                        <CardContainer>
                          <div style={{ textAlign: 'center' }}>
                            <LabelNormal
                              color="#3C4549"
                              fontSize="12"
                              style={{ textTransform: 'uppercase' }}
                            >
                              Paso actual
                            </LabelNormal>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <History
                                histories={histories}
                                type="admin"
                              ></History>
                            </div>
                          </div>
                        </CardContainer>
                      ) : (
                        <>
                          <CardContainer style={{ minHeight: 500 }}>
                            <center>
                              <Skeleton
                                baseColor={'#EEEEF2'}
                                height={16}
                                width={'20%'}
                                highlightColor={'#FFF'}
                              />
                            </center>
                            <div
                              className="row"
                              style={{
                                justifyContent: 'space-between',
                                marginTop: 33,
                                border: '3px solid #EEEEF2',
                                borderRadius: 10,
                                padding: 10,
                              }}
                            >
                              <div className="col-md-6">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div className="col-md-3">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div
                                className="col-md-12"
                                style={{ marginTop: 10 }}
                              >
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={69}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                justifyContent: 'space-between',
                                marginTop: 23,
                              }}
                            >
                              <div className="col-md-6">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div className="col-md-3">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div
                                className="col-md-12"
                                style={{ marginTop: 10 }}
                              >
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={69}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                justifyContent: 'space-between',
                                marginTop: 23,
                              }}
                            >
                              <div className="col-md-6">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div className="col-md-3">
                                <Skeleton
                                  borderRadius={5}
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                              <div
                                className="col-md-12"
                                style={{ marginTop: 10 }}
                              >
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={69}
                                  width={'100%'}
                                  highlightColor={'#FFF'}
                                />
                              </div>
                            </div>
                          </CardContainer>
                        </>
                      )}
                    </div>
                  )}
                  {edit && (
                    <div className="col-md-12" style={{ marginTop: 17 }}>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            style={{ marginTop: 38 }}
                            className="grid-layout"
                          >
                            <InlineContainer>
                              <div>
                                <Input
                                  placeholder="Nombre"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  label="Nombre del paciente"
                                />
                              </div>
                              <div>
                                <span
                                  style={{ fontSize: 18, visibility: 'hidden' }}
                                >
                                  none
                                </span>
                                <Input
                                  placeholder="Apellidos"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <InlineContainer
                            style={{ alignItems: 'center', marginBottom: 20 }}
                          >
                            <LabelNormal color="#828282" fontSize="13">
                              Tratamiento
                            </LabelNormal>
                            <LabelNormal
                              color={
                                treatment === 'cryst_aligner'
                                  ? '#18A1DC'
                                  : '#F0BB00'
                              }
                              fontSize="20.45"
                            >
                              {treatment === 'cryst_aligner'
                                ? 'Cryst Aligner'
                                : 'Smile System'}
                            </LabelNormal>
                          </InlineContainer>
                        </div>
                        <div className="col-md-7">
                          <div>
                            <LabelInput>Sexo</LabelInput>
                            <InlineContainer>
                              <div
                                className="radio"
                                style={{ marginRight: 20 }}
                              >
                                <input
                                  id="gender-1"
                                  onClick={(e) => setGender('Masculino')}
                                  checked={
                                    gender === 'Masculino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-1"
                                  className="radio-label"
                                >
                                  Hombre
                                </label>
                              </div>
                              <div className="radio">
                                <input
                                  id="gender-2"
                                  onClick={(e) => setGender('Femenino')}
                                  checked={
                                    gender === 'Femenino' ? 'checked' : false
                                  }
                                  name="gender"
                                  type="radio"
                                />
                                <label
                                  htmlFor="gender-2"
                                  className="radio-label"
                                >
                                  Mujer
                                </label>
                              </div>
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 28 }}>
                          <LabelInput>Fecha de nacimiento</LabelInput>
                          <InlineContainer>
                            <Input
                              placeholder="DD"
                              maxWidth="60px"
                              value={day}
                              onChange={(e) => setDay(e.target.value)}
                            />
                            <Input
                              placeholder="MM"
                              maxWidth="60px"
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                            />
                            <Input
                              placeholder="AAAA"
                              maxWidth="90px"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </InlineContainer>
                        </div>
                        <div className="col-md-7" style={{ marginTop: 28 }}>
                          <div>
                            <LabelInput>Teléfono</LabelInput>
                            <InlineContainer>
                              <Input
                                readOnly
                                disabled
                                value={'+52'}
                                backgroundColor="#F6F6F8"
                                maxWidth="70px"
                              />
                              <Input
                                placeholder="Teléfono"
                                value={phone}
                                type="int"
                                oonChange={maskPhone}
                              />
                            </InlineContainer>
                          </div>
                        </div>
                        <div className="col-md-5" style={{ marginTop: 28 }}>
                          <div className="grid-layout">
                            <Input
                              placeholder="Tu correo"
                              label="Email (Opcional)"
                              value={email}
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              maxWidth="300px"
                            />
                          </div>
                        </div>
                      </div>
                      <ButtonPrime
                        label="Guardar cambios"
                        disabled={false}
                        onClick={() =>
                          updatePatient({
                            variables: {
                              patient_id: id,
                              first_name: firstName,
                              last_name: lastName,
                              email: email === null ? '' : email,
                              phone,
                              gender,
                              birthdate: `${year}-${month}-${day}`,
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {historyClinic && (
                    <div
                      className="col-md-12"
                      style={{ marginTop: historyClinic ? 60 : 0 }}
                    >
                      <InlineContainer
                        style={{
                          background: '#F6F6F8',
                          borderRadius: 40,
                          paddingTop: 30,
                          paddingLeft: 20,
                          paddingBottom: 30,
                          alignItems: 'self-start',
                        }}
                      >
                        <div style={{ marginRight: 40 }}>
                          {[
                            'Formulario',
                            'Intraorales',
                            'Extraorales',
                            "STL's",
                            'Radiografias',
                          ].map((row, index) => (
                            <div
                              style={{
                                maxWidth: 165,
                                maxHeight: 55,
                                background:
                                  selectItem === row ? '#FFF' : 'transparent',
                                borderBottomColor:
                                  selectItem === row
                                    ? 'transparent'
                                    : '#D4D4D4',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                padding: 15,
                                borderRadius: selectItem !== row ? 0 : 10,
                              }}
                              key={Math.random()}
                            >
                              <LabelNormal
                                color="#000000"
                                fontSize="18.5"
                                onClick={() => setSelectItem(row)}
                                style={{ cursor: 'pointer' }}
                              >
                                {row}
                              </LabelNormal>
                            </div>
                          ))}
                        </div>
                        <div>
                          {selectItem === 'Formulario' && (
                            <div className="row">
                              <div className="col-md-12">
                                <InlineContainer
                                  style={{
                                    marginBottom: 30,
                                    alignSelf: 'flex-start',
                                  }}
                                >
                                  <InlineContainer
                                    style={{
                                      padding: 10,
                                      background: '#F6F6F8',
                                      marginTop: 10,
                                      borderRadius: 5,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <FeatherIcon icon="file" size={20} />
                                    <LabelNormal fontSize="17" color="#757575">
                                      {filesTreatment['patient_form']
                                        ? filesTreatment['patient_form'].name
                                        : ''}
                                    </LabelNormal>
                                  </InlineContainer>
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === 'Radiografias' && (
                            <div className="row">
                              <div className="col-md-7">
                                <InlineContainer>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral'] &&
                                          filesTreatment['lateral'].url
                                            ? filesTreatment['lateral'].url
                                            : require('@images/patient/lateral.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral'] &&
                                      filesTreatment['lateral'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['lateral'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Lateral
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['panoramica'] &&
                                          filesTreatment['panoramica'].url
                                            ? filesTreatment['panoramica'].url
                                            : require('@images/patient/panoramica.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['panoramica'] &&
                                      filesTreatment['panoramica'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['panoramica'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Panorámica
                                    </LabelNormal>
                                  </div>
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ] &&
                                          filesTreatment[
                                            'trazado_cefalometrico'
                                          ].url
                                            ? filesTreatment[
                                                'trazado_cefalometrico'
                                              ].url
                                            : require('@images/patient/trazado_cefalometrico.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['trazado_cefalometrico'] &&
                                      filesTreatment['trazado_cefalometrico']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {
                                            filesTreatment[
                                              'trazado_cefalometrico'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Trazado cefalométrico
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                                <InlineContainer
                                  style={{ alignItems: 'center' }}
                                >
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['tomografia'] &&
                                          filesTreatment['tomografia'].url
                                            ? require('@images/patient/zip-upload.png')
                                            : require('@images/patient/tomografia.png')
                                        }
                                        style={{
                                          height: 104,
                                          width: 162,
                                          borderRadius: 3,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['tomografia'] &&
                                      filesTreatment['tomografia'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 162,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="15"
                                        >
                                          {filesTreatment['tomografia'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="15">
                                      Tomografía
                                    </LabelNormal>
                                  </div>
                                </InlineContainer>
                              </div>
                            </div>
                          )}
                          {selectItem === "STL's" && (
                            <div className="row">
                              <div className="col-md-7">
                                {sendModelStl && (
                                  <div>
                                    <LabelNormal color="#3C4549" fontSize="17">
                                      Se seleccionó envío de modelos por
                                      paquetería a la siguiente dirección:
                                    </LabelNormal>
                                    <InlineContainer>
                                      <CardModel style={{ maxWidth: 356 }}>
                                        <LabelNormal
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            marginRight: 7,
                                            marginBottom: 10,
                                          }}
                                          color="#757575"
                                          fontSize="18"
                                        >
                                          Dirección de envío | Borgatta
                                        </LabelNormal>
                                        <LabelNormal
                                          color="#828282"
                                          style={{ margin: 0, padding: 0 }}
                                          fontSize="17"
                                        >
                                          {defaultAddress.id
                                            ? `${defaultAddress.street} ${defaultAddress.number}, ${defaultAddress.suburb}, ${defaultAddress.postal_code} ${defaultAddress.state}`
                                            : ''}
                                        </LabelNormal>
                                      </CardModel>
                                    </InlineContainer>
                                  </div>
                                )}
                                {sendLinkStl && (
                                  <InlineContainer>
                                    <LabelNormal color="#3C4549" fontSize="20">
                                      {urlSTL}
                                    </LabelNormal>
                                  </InlineContainer>
                                )}
                                {!sendLinkStl && !sendModelStl && (
                                  <InlineContainer>
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.arcada_superior
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/arcada_superior.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentStl.arcada_superior &&
                                        filesTreatmentStl.arcada_superior
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.arcada_superior
                                                .file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Arcada superior
                                      </LabelNormal>
                                    </div>
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.oclusion
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/oclusion.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentStl.oclusion &&
                                        filesTreatmentStl.oclusion.file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.oclusion.file
                                                .name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Oclusión
                                      </LabelNormal>
                                    </div>
                                    <div
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <img
                                          src={
                                            filesTreatmentStl.arcada_inferior
                                              ? require('@images/patient/stl-upload.png')
                                              : require('@images/patient/arcada_inferior.png')
                                          }
                                          style={{
                                            height: 104,
                                            width: 162,
                                            borderRadius: 3,
                                          }}
                                        />
                                      </div>
                                      {filesTreatmentStl.arcada_inferior &&
                                        filesTreatmentStl.arcada_inferior
                                          .file && (
                                          <LabelNormal
                                            style={{
                                              width: 162,
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                            }}
                                            color="#828282"
                                            fontSize="14"
                                          >
                                            {
                                              filesTreatmentStl.arcada_inferior
                                                .file.name
                                            }
                                          </LabelNormal>
                                        )}
                                      <LabelNormal
                                        color="#3C4549"
                                        fontSize="14"
                                      >
                                        Arcada inferior
                                      </LabelNormal>
                                    </div>
                                  </InlineContainer>
                                )}
                              </div>
                            </div>
                          )}
                          {selectItem === 'Extraorales' && (
                            <div>
                              <div
                                className="row"
                                style={{
                                  alignContent: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_serio'] &&
                                          filesTreatment['frontal_serio'].url
                                            ? filesTreatment['frontal_serio']
                                                .url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_serio'] &&
                                      filesTreatment['frontal_serio'].name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {filesTreatment['frontal_serio'].name}
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['frontal_sonriendo'] &&
                                          filesTreatment['frontal_sonriendo']
                                            .url
                                            ? filesTreatment[
                                                'frontal_sonriendo'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_sonriendo'] &&
                                      filesTreatment['frontal_sonriendo']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['frontal_sonriendo']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal sonriendo
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment[
                                            'frontal_retractores'
                                          ] &&
                                          filesTreatment['frontal_retractores']
                                            .url
                                            ? filesTreatment[
                                                'frontal_retractores'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['frontal_retractores'] &&
                                      filesTreatment['frontal_retractores']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment[
                                              'frontal_retractores'
                                            ].name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Frontal con retractores
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_izq_serio'] &&
                                          filesTreatment['lateral_izq_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_izq_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_izq_serio'] &&
                                      filesTreatment['lateral_izq_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_izq_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral izquierda serio
                                    </LabelNormal>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div
                                    style={{
                                      marginRight: 20,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <img
                                        src={
                                          filesTreatment['lateral_der_serio'] &&
                                          filesTreatment['lateral_der_serio']
                                            .url
                                            ? filesTreatment[
                                                'lateral_der_serio'
                                              ].url
                                            : require('@images/patient/empty-patient.png')
                                        }
                                        style={{
                                          height: 105,
                                          width: 105,
                                          borderRadius: 4,
                                        }}
                                      />
                                    </div>
                                    {filesTreatment['lateral_der_serio'] &&
                                      filesTreatment['lateral_der_serio']
                                        .name && (
                                        <LabelNormal
                                          style={{
                                            width: 105,
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}
                                          color="#828282"
                                          fontSize="14"
                                        >
                                          {
                                            filesTreatment['lateral_der_serio']
                                              .name
                                          }
                                        </LabelNormal>
                                      )}
                                    <LabelNormal color="#3C4549" fontSize="14">
                                      Lateral derecha serio
                                    </LabelNormal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectItem === 'Intraorales' && (
                            <div>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_superior'] &&
                                        filesTreatment['oclusal_superior'].url
                                          ? filesTreatment['oclusal_superior']
                                              .url
                                          : require('@images/patient/superior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_superior'] &&
                                    filesTreatment['oclusal_superior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_superior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal superior
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{
                                    width: 162,
                                    marginRight: 20,
                                    marginBottom: 20,
                                  }}
                                ></div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['oclusal_inferior'] &&
                                        filesTreatment['oclusal_inferior'].url
                                          ? filesTreatment['oclusal_inferior']
                                              .url
                                          : require('@images/patient/inferior.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['oclusal_inferior'] &&
                                    filesTreatment['oclusal_inferior'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['oclusal_inferior']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Oclusal inferior
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                              <InlineContainer>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['lat_izq_oclusion'] &&
                                        filesTreatment['lat_izq_oclusion'].url
                                          ? filesTreatment['lat_izq_oclusion']
                                              .url
                                          : require('@images/patient/lateral-izquierda.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_izq_oclusion'] &&
                                    filesTreatment['lat_izq_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_izq_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Lateral izquierda en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['frontal_oclusion'] &&
                                        filesTreatment['frontal_oclusion'].url
                                          ? filesTreatment['frontal_oclusion']
                                              .url
                                          : require('@images/patient/frontal.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['frontal_oclusion'] &&
                                    filesTreatment['frontal_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['frontal_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Frontal en oclusión
                                  </LabelNormal>
                                </div>
                                <div
                                  style={{ marginRight: 20, marginBottom: 20 }}
                                >
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={
                                        filesTreatment['lat_der_oclusion'] &&
                                        filesTreatment['lat_der_oclusion'].url
                                          ? filesTreatment['lat_der_oclusion']
                                              .url
                                          : require('@images/patient/lateral-derecha.png')
                                      }
                                      style={{ height: 105, width: 162 }}
                                    />
                                  </div>
                                  {filesTreatment['lat_der_oclusion'] &&
                                    filesTreatment['lat_der_oclusion'].name && (
                                      <LabelNormal
                                        style={{
                                          width: 162,
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                        color="#828282"
                                        fontSize="15"
                                      >
                                        {
                                          filesTreatment['lat_der_oclusion']
                                            .name
                                        }
                                      </LabelNormal>
                                    )}
                                  <LabelNormal color="#3C4549" fontSize="15">
                                    Lateral derecha en oclusión
                                  </LabelNormal>
                                </div>
                              </InlineContainer>
                            </div>
                          )}
                        </div>
                      </InlineContainer>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {!historyClinic && (
                  <div className="row">
                    <div className="col-md-12">
                      <HeaderCard>
                        <LabelNormal color="#3C4549" fontSize="17">
                          Detalles de caso
                        </LabelNormal>
                      </HeaderCard>
                      <CardContainer>
                        {!loadingSkeleton ? (
                          <div className="row">
                            <div className="col-md-12">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{ marginLeft: 0, marginBottom: 10 }}
                              >
                                Categorias
                              </LabelNormal>
                              <InlineContainer>
                                {treatment === 'cryst_aligner' ? (
                                  <DecoratorBlue style={{ marginTop: 10 }}>
                                    <LabelNormal color="#FFF" fontSize="17">
                                      Cryst Aligner
                                    </LabelNormal>
                                  </DecoratorBlue>
                                ) : (
                                  <DecoratorYellow>
                                    <LabelNormal color="#FFF" fontSize="17">
                                      Smile System
                                    </LabelNormal>
                                  </DecoratorYellow>
                                )}
                                <CategoryCard
                                  style={{ marginTop: 10, marginLeft: 10 }}
                                >
                                  <LabelNormal color="#FFF" fontSize="17">
                                    {country}
                                  </LabelNormal>
                                </CategoryCard>
                              </InlineContainer>
                              <InlineContainer>
                                <CategoryCard
                                  style={{ marginTop: 10, marginRight: 10 }}
                                >
                                  <LabelNormal color="#FFF" fontSize="17">
                                    Estudiante
                                  </LabelNormal>
                                </CategoryCard>
                              </InlineContainer>
                            </div>
                            <div className="col-md-12">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{
                                  marginLeft: 0,
                                  marginBottom: 10,
                                  marginTop: 30,
                                }}
                              >
                                Universidad
                              </LabelNormal>
                              <InlineContainer
                                style={{
                                  alignItems: 'center',
                                  marginBottom: 30,
                                }}
                              >
                                <University color="#BDBDBD" />
                                <LabelNormal
                                  color="#BDBDBD"
                                  style={{ marginLeft: 10 }}
                                  fontSize="17"
                                >
                                  {universityName}
                                </LabelNormal>
                              </InlineContainer>

                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{
                                  marginLeft: 0,
                                  marginBottom: 10,
                                  marginTop: 30,
                                }}
                              >
                                Profesor asignado
                              </LabelNormal>
                              <InlineContainer
                                style={{
                                  alignItems: 'center',
                                  marginBottom: 30,
                                }}
                              >
                                <img
                                  src={
                                    photoTA
                                      ? photoTA
                                      : require('@images/empty-profile.png')
                                  }
                                  style={{
                                    height: 46,
                                    width: 46,
                                    borderRadius: 50,
                                  }}
                                />
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="17"
                                >{`${firstNameTA} ${lastNameTA}`}</LabelNormal>
                              </InlineContainer>

                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{
                                  marginLeft: 0,
                                  marginBottom: 10,
                                  marginTop: 30,
                                }}
                              >
                                Estudiante asignado
                              </LabelNormal>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <img
                                  src={
                                    photoDT
                                      ? photoDT
                                      : require('@images/empty-profile.png')
                                  }
                                  style={{
                                    height: 46,
                                    width: 46,
                                    borderRadius: 50,
                                  }}
                                />
                                <LabelNormal
                                  color="#3C4549"
                                  fontSize="17"
                                >{`${firstNameDT} ${lastNameDT}`}</LabelNormal>
                              </InlineContainer>
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{
                                  marginBottom: 10,
                                  marginTop: 30,
                                  marginLeft: 0,
                                }}
                              >
                                Status del caso
                              </LabelNormal>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                {statusTreatment && statusTreatment.id && (
                                  <>
                                    {statusTreatment &&
                                    statusTreatment.index === 1 &&
                                    statusTreatment.type === 'planning' ? (
                                      <div></div>
                                    ) : (
                                      <>
                                        <div
                                          style={{
                                            background:
                                              statusTreatment.color_type ===
                                              'success'
                                                ? '#169D00'
                                                : statusTreatment.color_type ===
                                                  'error'
                                                ? 'red'
                                                : '#BDBDBD',
                                            height: 10,
                                            width: 10,
                                            borderRadius: 5,
                                          }}
                                        />
                                        <LabelNormal
                                          style={{
                                            color:
                                              statusTreatment.color_type ===
                                              'success'
                                                ? '#169D00'
                                                : statusTreatment.color_type ===
                                                  'error'
                                                ? 'red'
                                                : '#BDBDBD',
                                            fontSize: 15.5,
                                          }}
                                        >
                                          {statusTreatment.admin_name}
                                        </LabelNormal>
                                      </>
                                    )}
                                  </>
                                )}
                              </InlineContainer>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-9">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{ marginBottom: 10 }}
                              >
                                Tratamiento
                              </LabelNormal>
                              <Skeleton
                                baseColor={'#EEEEF2'}
                                height={37}
                                width={'70%'}
                                borderRadius={5}
                                highlightColor={'#FFF'}
                              />
                            </div>
                            <div className="col-md-12">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{ marginBottom: 10, marginTop: 30 }}
                              >
                                Profesor asignado
                              </LabelNormal>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={46}
                                  circle
                                  width={46}
                                  highlightColor={'#FFF'}
                                  style={{ marginRight: 15 }}
                                />
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={150}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </InlineContainer>
                            </div>
                            <div className="col-md-12">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{ marginBottom: 10, marginTop: 30 }}
                              >
                                Estudiante asignado
                              </LabelNormal>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={46}
                                  circle
                                  width={46}
                                  highlightColor={'#FFF'}
                                  style={{ marginRight: 15 }}
                                />
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={150}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </InlineContainer>
                            </div>
                            <div className="col-md-12">
                              <LabelNormal
                                color="#828282"
                                fontSize="16"
                                style={{ marginBottom: 10, marginTop: 30 }}
                              >
                                Status del caso
                              </LabelNormal>
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={14}
                                  circle
                                  width={14}
                                  highlightColor={'#FFF'}
                                  style={{ marginRight: 15 }}
                                />
                                <Skeleton
                                  baseColor={'#EEEEF2'}
                                  height={23}
                                  width={150}
                                  borderRadius={5}
                                  highlightColor={'#FFF'}
                                />
                              </InlineContainer>
                            </div>
                          </div>
                        )}
                      </CardContainer>
                    </div>
                    <div className="col-md-12" style={{ marginTop: 43 }}>
                      {!loadingSkeleton ? (
                        <CardContainer style={{ borderRadius: 25 }}>
                          <Link
                            to={`/app/doctores/perfil-doctor/${doctorId}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <InlineContainer style={{ marginTop: 30 }}>
                              <FeatherIcon
                                icon="user"
                                color="#3C4549"
                                size={19}
                                style={{ marginTop: 8 }}
                              />
                              <div>
                                <LabelNormal
                                  style={{ marginLeft: 10 }}
                                  color="#3C4549"
                                  fontSize="20"
                                >
                                  Datos del estudiante
                                </LabelNormal>
                                <LabelNormal fontSize="15" color="#BDBDBD">
                                  Contacto, Facturación, Envío
                                </LabelNormal>
                              </div>
                            </InlineContainer>
                          </Link>

                          <InlineContainer
                            style={{ marginTop: 20, cursor: 'pointer' }}
                            onClick={() => setHistoryClinic(!historyClinic)}
                          >
                            <FeatherIcon
                              icon="folder"
                              color="#3C4549"
                              size={19}
                              style={{ marginTop: 8 }}
                            />
                            <div>
                              <LabelNormal
                                style={{ marginLeft: 10 }}
                                color="#3C4549"
                                fontSize="20"
                              >
                                Archivos del paciente
                              </LabelNormal>
                              <LabelNormal fontSize="15" color="#BDBDBD">
                                Fotografías, STLs, Radiografías
                              </LabelNormal>
                            </div>
                          </InlineContainer>

                          <InlineContainer style={{ marginTop: 30 }}>
                            <FeatherIcon
                              icon="file"
                              color="#3C4549"
                              size={19}
                              style={{ marginTop: 8 }}
                            />
                            <LabelNormal
                              style={{ marginLeft: 10 }}
                              color="#3C4549"
                              fontSize="20"
                            >
                              Ver historial clínico
                            </LabelNormal>
                          </InlineContainer>

                          <InlineContainer
                            style={{ marginTop: 30, cursor: 'pointer' }}
                            onClick={() => {}}
                          >
                            <FeatherIcon
                              icon="eye"
                              color="#3C4549"
                              size={19}
                              style={{ marginTop: 8 }}
                            />
                            <LabelNormal
                              style={{ marginLeft: 10 }}
                              color="#3C4549"
                              fontSize="20"
                            >
                              Visor de tratamiento
                            </LabelNormal>
                          </InlineContainer>
                        </CardContainer>
                      ) : (
                        <CardContainer style={{ borderRadius: 25 }}>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="row"
                                style={{ alignItems: 'flex-start' }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                    style={{ marginTop: 4 }}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  alignItems: 'flex-start',
                                  marginTop: 30,
                                }}
                              >
                                <div className="col-md-1">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={24}
                                    circle
                                    width={24}
                                    highlightColor={'#FFF'}
                                    style={{ marginRight: 15 }}
                                  />
                                </div>
                                <div className="col-md-10">
                                  <Skeleton
                                    baseColor={'#EEEEF2'}
                                    height={23}
                                    width={'80%'}
                                    borderRadius={5}
                                    highlightColor={'#FFF'}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardContainer>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Case;
