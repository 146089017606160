import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_TEACHER,
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_LOGIN,
} from '@graphql/mutation';
import { QUERY_GET_TEACHER } from '@graphql/query';
import ButtonPrime from '@components/shared/ButtonPrime';
import { useHistory, useParams } from 'react-router-dom';
import Avatar from '@components/shared/Avatar';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const VerifyTeacher = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState({});
  const [photo, setPhoto] = useState('');
  const [idUser, setIdUser] = useState('');

  const [getTeacher] = useLazyQuery(QUERY_GET_TEACHER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.teacher) {
        const teacher = data.teacher;

        setIdUser(teacher.user.id);
        setFirstName(teacher.user.first_name);
        setLastName(teacher.user.last_name);
        setEmail(teacher.user.email);
        setPhoto(teacher.user.photo_filename);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getTeacher({ variables: { id } });
  }, []);

  const [verifyTeacher] = useMutation(MUTATION_UPDATE_TEACHER, {
    onCompleted: async (data) => {
      if (data.updateTeacher) {
        if (file.name)
          addProfilePhoto({ variables: { file, user_id: +idUser } });
        else
          login({
            variables: { password: history.location.state.password, email },
          });
      }
    },
    onError: (err) => console.log(err),
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        login({
          variables: { password: history.location.state.password, email },
        });
      }
    },
    onError: (err) => console.log(err),
  });

  const [login] = useMutation(MUTATION_LOGIN, {
    onCompleted: (data) => {
      if (data.login) {
        localStorage.setItem('onboarding', true);
        props.login(data.login.token, data.login.type, data.login.user_id, id);
      }
    },
  });

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, email]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    }
    if (email === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    verifyTeacher({
      variables: { id, first_name: firstName, last_name: lastName, email },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name={null} />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Profesor</Title>
          <LabelNormal color="#757575" fontSize="17">
            Completa tus datos para completar tu registro y asignarte
            estudiantes.
          </LabelNormal>

          <div style={{ marginTop: 39 }}>
            <Avatar
              src={
                photo && !file.name
                  ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                  : file.name
                    ? photo
                    : require('@images/people/usuario_masculino.jpg')
              }
              floatButton={true}
              edit={!!photo}
              onChange={onChangeFile}
              height="161"
              width="161"
              borderRadius="30"
            />
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre(s)"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                label="Nombre y apellidos"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          </div>

          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Verificar datos"
              disabled={disabled}
              onClick={() => handlerSubmit()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (token, type, id, role_id) =>
    dispatch(action.loginSuccess(token, type, id, role_id)),
});

export default connect(null, mapDispatchToProps)(VerifyTeacher);
