import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import { QUERY_GET_PATIENT } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import ButtonPrime from '@components/shared/ButtonPrime';
import FeatherIcon from 'feather-icons-react';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const HeaderCard = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const ButtonModal = styled.button`
  border-radius: 40px;
  min-width: 198px;
  padding: 12px;
  border: 1px solid #3c4549;
  background: transparent;
  cursor: pointer;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const CategoryCard = styled.div`
  background: ${({ background }) => (background ? background : '#828282')};
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ButtonReactive = styled.button`
  border-radius: 69px;
  padding: 12px 25px 11px 25px;
  background: ${({ background }) => (background ? background : '#C8C8C8')};
  display: flex;
  border: 2px solid #828282;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CardModel = styled.div`
  border: 2px solid #757575;
  border-radius: 10.1164px;
  padding: 21px;
  max-width: 338px;
  min-height: 143px;
  margin-right: 50px;
`;

const PlanningVisor = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatment, setTreatment] = useState('');

  const [visorUrl, setVisorUrl] = useState('');
  const [moreSmartchecks, setMoreSmartchecks] = useState(false);
  const [treatmentStatus, setTreatmentStatus] = useState({});

  const [getPatient] = useLazyQuery(QUERY_GET_PATIENT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.pacient) {
        const patient = data.pacient;

        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setTreatment(patient.treatment);
        setVisorUrl(patient.treatment_related.visor_url);

        setTreatmentStatus(patient.treatment_related.treatmentStatus);
        setMoreSmartchecks(patient.treatment_related.more_smartchecks);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getPatient({ variables: { id } });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="tus-casos" />
      <ContainHome>
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Planificación</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={2}
                    onClick={() => history.goBack()}
                    mainLabel={'Tus casos'}
                    midLabel={`${firstName} ${lastName}`}
                    endLabel={'Planificación'}
                  />
                  <LabelNormal
                    color="#757575"
                    fontSize="17"
                    style={{ maxWidth: 607 }}
                  >
                    {treatmentStatus.name === 'En manufactura'
                      ? 'Revisa el visor del tratamiento y ve al pago para aprobar el caso. Si lo necesitas puedes hacer comentarios y te contestaremos lo más pronto posible. '
                      : 'Revisa el visor del tratamiento y agenda tu Smartch check para aprobar el caso.'}
                  </LabelNormal>
                </div>
              </div>
              <div className="col-md-7">
                <ButtonPrime
                  icon="eye"
                  label="Abrir visor"
                  onClick={() => window.open(visorUrl, '_blank')}
                />
              </div>
              <div className="col-md-6" style={{ marginTop: 37 }}>
                {((treatment === 'smile_system' &&
                  treatmentStatus.index === 3 &&
                  treatmentStatus.type === 'planning') ||
                  moreSmartchecks === true) && (
                  <>
                    <LabelNormal color="#757575" fontSize="22">
                      Aprobación de caso
                    </LabelNormal>
                    <InlineContainer style={{ alignItems: 'center' }}>
                      <FeatherIcon icon="calendar" color="#EB9542" />
                      <LabelNormal
                        color="#EB9542"
                        fontSize="17"
                        style={{ marginLeft: 10 }}
                      >
                        Agenda tu Smart check
                      </LabelNormal>
                    </InlineContainer>
                  </>
                )}
                {treatmentStatus.name === 'En manufactura' && (
                  <>
                    <LabelNormal color="#757575" fontSize="22">
                      Aprobación de caso
                    </LabelNormal>
                    <InlineContainer style={{ alignItems: 'center' }}>
                      <FeatherIcon icon="check" color="#EB9542" />
                      <LabelNormal
                        color="#EB9542"
                        fontSize="17"
                        style={{ marginLeft: 10 }}
                      >
                        Caso aprobado
                      </LabelNormal>
                    </InlineContainer>
                  </>
                )}
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default PlanningVisor;
