import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENT = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      patient {
        id
        first_name
        last_name
        email
        photo_filename
        treatment
      }
      doctor {
        id
        user {
          id
          first_name
          last_name
        }
      }
      treatmentStatus {
        id
        name
        index
        type
        admin_name
        color_type
      }
      treatmentType {
        id
        name
      }
      stl_type
      form_correction
      form_status
      contact_question
    }
  }
`;

export const QUERY_GET_TREATMENT_FILES = gql`
  query getTreatmentFiles($treatment_id: ID!, $onlyErrors: Boolean!) {
    getTreatmentFiles(
      input: { treatment_id: $treatment_id, onlyErrors: $onlyErrors }
    ) {
      id
      fileUrl
      file_type
      file_name
      name
      section
      FileStatus {
        id
        name
      }
    }
  }
`;
