import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import { QUERY_GET_DETAILS_DOCTOR } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import {
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_DELETED_DOCTOR,
  MUTATION_APPROVED_DOCTOR,
  MUTATION_STUDENT_CHANGE_DOCTOR,
} from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import ModalSuccess from '@components/Modal/ModalSuccess';
import ModalWarning from '@components/Modal/ModalWarning';
import { modal } from '@components/ModalManagement';
import { loadPhotoProfileGender } from '../../utils';
import moment from 'moment';
import CasesTable from '../../components/student-details/cases-table';
import StudentDetailsCard from '../../components/student-details/details-card';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;
const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
  max-width: 1072px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const CardContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
`;

const HeaderCard = styled.div`
  background: #f6f6f8;
  min-height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  margin: 0;
  padding: 0;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const DecoratorBlue = styled.div`
  background: linear-gradient(#1aa5e0, #088ec7);
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const DecoratorYellow = styled.div`
  background: #f0bb00;
  width: 128px;
  height: 37px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const ScrollHorizontal = styled.div`
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
`;

const CardInlineContainer = styled.div`
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 22px;
  background: #fff;
  display: inline-block;
  margin: 0px 5px;
`;

const Student = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [student, setStudent] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [crystAligner, setCrystAligner] = useState('');
  const [smileSystem, setSmileSystem] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [photo, setPhoto] = useState('');
  const [file, setFile] = useState({});
  const [idUser, setIdUser] = useState('');
  const [createdAt, setCreatedAt] = useState('');

  const [nameUniversity, setNameUniversity] = useState('');
  const [idUniversity, setIdUniversity] = useState('');
  const [period, setPeriod] = useState('');
  const [type, setType] = useState('');

  const [taxDatas, setTaxDatas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [creditCards, setCreditCards] = useState([]);

  const [treatments, setTreatments] = useState([]);
  const [statusDoctor, setStatusDoctor] = useState(false);

  const [getStudent] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;
        setStudent(doctor);

        setTreatments(doctor.treatments);
        setStatusDoctor(doctor.status);

        setTaxDatas(doctor.taxdatas);
        setAddresses(doctor.addresses);
        setCreditCards(doctor.cards);

        setNameUniversity(doctor.university.name);
        setType(doctor.university.type);
        setPeriod(doctor.university.graduate_periods);

        setIdUser(doctor.user.id);
        setFirstName(doctor.user.first_name);
        setLastName(doctor.user.last_name);
        setGender(doctor.user.gender);
        setCountry(doctor.user.country.name);
        setEmail(doctor.user.email);
        setCreatedAt(doctor.user.created_at);
        setPhoto(doctor.user.photo_filename);

        setCrystAligner(doctor.cryst_aligner);
        setSmileSystem(doctor.smile_system);

        setIdUniversity(doctor.university.id);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getStudent({ variables: { id } });
  }, []);

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: (data) => {
      if (data.addProfilePhoto) {
        setFile({});
        getStudent({ variables: { id } });
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitFile = (file) => {
    addProfilePhoto({ variables: { file: file, user_id: +idUser } });
  };

  const onChange = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile.name) {
      setPhoto(URL.createObjectURL(prefile));
    }
    onSubmitFile(prefile);
  };

  const [unsubscribeDoctor] = useMutation(MUTATION_DELETED_DOCTOR, {
    onCompleted: (data) => {
      if (data.deleteDoctor) {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => getStudent({ variables: { id } }))}
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Estudiante desactivado
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14, marginBottom: 15 }}
            >
              El estudiante ya no aparecerá en el perfil de la universidad.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const [reactiveDoctor] = useMutation(MUTATION_APPROVED_DOCTOR, {
    onCompleted: (data) => {
      if (data.approveDoctor) {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => getStudent({ variables: { id } }))}
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Estudiante reactivado
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14, marginBottom: 15 }}
            >
              El estudiante aparecerá en el perfil de la universidad.
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
  });

  const [convertB360] = useMutation(MUTATION_STUDENT_CHANGE_DOCTOR, {
    onCompleted: (data) => {
      if (data.changeToDoctor) {
        modal.close();
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() =>
                history.push(`/app/doctores/perfil-doctor/${id}`)
              )
            }
          >
            <LabelNormal color="#060809" fontSize="22">
              Usuario actualizado a Dr. B360
            </LabelNormal>
          </ModalSuccess>
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const confirmConvertB360 = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        {treatments.length === 0 ? (
          <>
            <LabelNormal color="#060809" fontSize="22">
              Esta acción no se puede deshacer
            </LabelNormal>
            <LabelNormal
              style={{ marginTop: 14 }}
              color="#4F4F4F"
              fontSize="17"
            >
              Este usuario ahora podrá aprobar planificaciones y mandar
              tratamientos a manufactura.
            </LabelNormal>
            <ButtonPrime
              onClick={() => convertB360({ variables: { doctor_id: id } })}
              label="Convertir a Dr.B360"
            />
          </>
        ) : (
          <>
            <LabelNormal color="#060809" fontSize="22">
              Reasigna los casos activos para convertirlo a Dr. B360
            </LabelNormal>
            <LabelNormal
              style={{ marginTop: 14 }}
              color="#4F4F4F"
              fontSize="17"
            >
              Ingresa a cada caso y asignálo a un nuevo estudiante.
            </LabelNormal>
            <ButtonPrime onClick={() => modal.close()} label="Volver" />
          </>
        )}
      </ModalWarning>
    );
  };

  const modalUnsubscribeDoctor = () => {
    modal.open(
      <ModalWarning onClick={() => modal.close()}>
        <LabelNormal fontSize="22" color="#060809">
          El perfil se moverá a Estudiantes desactivados
        </LabelNormal>
        <LabelNormal color="#4F4F4F" fontSize="17">
          Puedes consultar a los Estudiantes desactivados al filtrar por
          Usuarios en el perfil de la Universidad.
        </LabelNormal>
        <ButtonPrime
          label="Desactivar estudiante"
          onClick={() => unsubscribeDoctor({ variables: { doctor_id: id } })}
        />
      </ModalWarning>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="estudiantes" />
      <ContainHome>
        {statusDoctor === 'disabled' && (
          <div
            style={{
              background: 'rgba(229, 229, 229, 0.4)',
              top: 0,
              bottom: 0,
              position: 'fixed',
              zIndex: 9,
              width: '100%',
            }}
          />
        )}
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => {}}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
              onClick={() => history.goForward()}
            />
          </div>
        </Header>
        <ContainContent>
          <div className="bootstrap-wrapper">
            <div className="row">
              <div className="col-md-12">
                <Title>Estudiante</Title>
                <div style={{ marginTop: 10, marginBottom: 39 }}>
                  <Breadcrumb
                    elements={1}
                    onClick={() => {}}
                    mainLabel={'Estudiantes'}
                    endLabel={`${firstName} ${lastName}`}
                  />
                </div>
              </div>
              <div className={'col-md-7'}>
                <div className="row">
                  <div className="col-md-12">
                    <InlineContainer>
                      <div>
                        <Avatar
                          src={
                            photo && !file.name
                              ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                              : file.name
                              ? photo
                              : loadPhotoProfileGender(gender)
                          }
                          floatButton={true}
                          edit={!!photo}
                          onChange={onChange}
                          height="161"
                          width="161"
                          borderRadius="30"
                        />
                        <LabelNormal
                          color="#828282"
                          fontSize="14"
                          style={{ textAlign: 'center' }}
                        >
                          Miembro desde
                        </LabelNormal>
                        <LabelNormal
                          color="#828282"
                          fontSize="14"
                          style={{ textAlign: 'center' }}
                        >
                          {moment(createdAt).format('DD/MM/YYYY')}
                        </LabelNormal>
                      </div>
                      <div style={{ marginLeft: 35 }}>
                        <InlineContainer style={{ alignItems: 'center' }}>
                          <LabelNormal
                            fontSize="30"
                            color="#3C4549"
                            style={{ marginRight: 20 }}
                          >{`${firstName} ${lastName}`}</LabelNormal>
                        </InlineContainer>
                        <InlineContainer>
                          <div className="row">
                            <div className="col-md-12">
                              <InlineContainer>
                                <FeatherIcon icon="user" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {gender}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div className="col-md-7">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <FeatherIcon icon="mail" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {email}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                            <div className="col-md-4">
                              <InlineContainer style={{ alignItems: 'center' }}>
                                <FeatherIcon icon="map-pin" size={19} />
                                <LabelNormal fontSize="14" color="#3C4549">
                                  {country}
                                </LabelNormal>
                              </InlineContainer>
                            </div>
                          </div>
                        </InlineContainer>
                      </div>
                    </InlineContainer>
                  </div>
                  <div className="col-md-12" style={{ marginTop: 47 }}>
                    <LabelNormal fontSize="22" color="#454D58">
                      Casos asignados
                    </LabelNormal>
                    <CasesTable treatments={treatments} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    {student && <StudentDetailsCard student={student} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainContent>
      </ContainHome>
    </div>
  );
};

export default Student;
