import { gql } from '@apollo/client';

export const MUTATION_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
      type
      user_id
      role_id
    }
  }
`;

export const MUTATION_LINK_LOGIN = gql`
  mutation generatePassword($uuid: String!, $password: String!) {
    generatePassword(input: { uuid: $uuid, password: $password }) {
      user_id
      email
      status
    }
  }
`;

export const MUTATION_FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
    }
  }
`;

export const MUTATION_RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(input: { token: $token, password: $password }) {
      status
      id
    }
  }
`;

export const MUTATION_UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $password_confirmation: String!) {
    updatePassword(
      input: {
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_REGISTER_MEMBER = gql`
  mutation registerMember(
    $country_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $gender: String!
    $phone: String!
    $password: String
    $department_id: Int!
    $super_admin: Boolean!
    $countries: [Int!]
    $roles: [Int!]
  ) {
    registerMember(
      input: {
        country_id: $country_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        gender: $gender
        phone: $phone
        password: $password
        department_id: $department_id
        super_admin: $super_admin
        countries: $countries
        roles: $roles
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_EDIT_MEMBER = gql`
  mutation updateMember(
    $id: ID!
    $country_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $gender: String!
    $phone: String!
    $department_id: Int!
    $super_admin: Boolean!
    $countries: [Int!]
    $roles: [Int!]
  ) {
    updateMember(
      input: {
        id: $id
        country_id: $country_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        gender: $gender
        phone: $phone
        department_id: $department_id
        super_admin: $super_admin
        countries: $countries
        roles: $roles
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_DELETED_MEMBER = gql`
  mutation deleteMember($id: ID!) {
    deleteMember(input: { id: $id }) {
      id
      status
    }
  }
`;

export const MUTATION_REACTIVE_MEMBER = gql`
  mutation reactiveMember($id: ID!) {
    reactiveMember(input: { id: $id }) {
      status
      id
    }
  }
`;

export const MUTATION_REGISTER_DOCTOR = gql`
  mutation registerDoctor(
    $country_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $gender: String!
    $phone: String!
    $university_id: Int
    $teacher_id: Int
    $id_doctor: String
    $id_adviser: String
    $category: String
    $has_adviser: Boolean
    $professional_license: String!
    $is_doctor: Boolean
    $cryst_aligner: Boolean!
    $smile_system: Boolean!
    $timezone: String
    $timezone_name: String
    $clinic_name: String
    $website: String
    $clinic_phone: String
    $alternative_phone: String
    $street: String
    $number: String
    $internal_number: String
    $postal_code: String
    $suburb: String
    $state: String
    $locality: String
    $references: String
    $birthdate: Date!
  ) {
    registerDoctor(
      input: {
        country_id: $country_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        gender: $gender
        phone: $phone
        university_id: $university_id
        teacher_id: $teacher_id
        id_doctor: $id_doctor
        id_adviser: $id_adviser
        category: $category
        has_adviser: $has_adviser
        professional_license: $professional_license
        is_doctor: $is_doctor
        cryst_aligner: $cryst_aligner
        smile_system: $smile_system
        timezone: $timezone
        timezone_name: $timezone_name
        clinic_name: $clinic_name
        website: $website
        clinic_phone: $clinic_phone
        alternative_phone: $alternative_phone
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        locality: $locality
        references: $references
        birthdate: $birthdate
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_EDIT_DOCTOR = gql`
  mutation updateDoctor(
    $user_id: Int
    $country_id: Int!
    $email: String
    $first_name: String!
    $last_name: String!
    $gender: String!
    $birthdate: String
    $phone: String!
    $university_id: Int
    $teacher_id: Int
    $id_doctor: String
    $id_adviser: String
    $category: String
    $has_adviser: Boolean
    $professional_license: String!
    $is_doctor: Boolean
    $cryst_aligner: Boolean!
    $smile_system: Boolean!
    $timezone: String
    $timezone_name: String
    $clinic_name: String
    $website: String
    $clinic_phone: String
    $alternative_phone: String
    $street: String
    $number: String
    $internal_number: String
    $postal_code: String
    $suburb: String
    $state: String
    $locality: String
    $references: String
    $status: String!
  ) {
    updateDoctor(
      input: {
        user_id: $user_id
        country_id: $country_id
        email: $email
        first_name: $first_name
        last_name: $last_name
        gender: $gender
        birthdate: $birthdate
        phone: $phone
        university_id: $university_id
        teacher_id: $teacher_id
        id_doctor: $id_doctor
        id_adviser: $id_adviser
        category: $category
        has_adviser: $has_adviser
        professional_license: $professional_license
        is_doctor: $is_doctor
        cryst_aligner: $cryst_aligner
        smile_system: $smile_system
        timezone: $timezone
        timezone_name: $timezone_name
        clinic_name: $clinic_name
        website: $website
        clinic_phone: $clinic_phone
        alternative_phone: $alternative_phone
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        locality: $locality
        references: $references
        status: $status
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_DECLINE_DOCTOR = gql`
  mutation declineDoctor($doctor_id: Int!) {
    declineDoctor(input: { doctor_id: $doctor_id }) {
      id
      status
    }
  }
`;

export const MUTATION_REACTIVE_DOCTOR = gql`
  mutation reactiveDoctor($doctor_id: Int!) {
    reactiveDoctor(input: { doctor_id: $doctor_id }) {
      id
      status
    }
  }
`;

export const MUTATION_DELETED_DOCTOR = gql`
  mutation deleteDoctor($doctor_id: ID!) {
    deleteDoctor(input: { doctor_id: $doctor_id }) {
      id
      status
    }
  }
`;

export const MUTATION_APPROVED_DOCTOR = gql`
  mutation approveDoctor($doctor_id: Int!) {
    approveDoctor(input: { doctor_id: $doctor_id }) {
      id
      status
    }
  }
`;

export const MUTATION_ADD_MEMBER_PHOTO = gql`
  mutation addProfilePhoto($file: Upload!, $user_id: Int!) {
    addProfilePhoto(input: { user_id: $user_id, file: $file }) {
      id
    }
  }
`;

export const MUTATION_ADD_TAX_DATA = gql`
  mutation registerTaxData(
    $business_name: String!
    $type: String!
    $rfc: String!
    $cfdi_usage_id: Int!
    $tax_system_id: Int!
    $street: String!
    $number: String!
    $internal_number: String
    $postal_code: String!
    $suburb: String!
    $state: String!
    $default: Boolean
    $locality: String
  ) {
    registerTaxData(
      input: {
        business_name: $business_name
        type: $type
        rfc: $rfc
        cfdi_usage_id: $cfdi_usage_id
        tax_system_id: $tax_system_id
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        default: $default
        locality: $locality
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_UPDATE_TAX_DATA = gql`
  mutation updateTaxData(
    $id: ID!
    $business_name: String!
    $type: String!
    $rfc: String!
    $cfdi_usage_id: Int!
    $tax_system_id: Int!
    $street: String!
    $number: String!
    $internal_number: String
    $postal_code: String!
    $suburb: String!
    $state: String!
    $default: Boolean!
    $locality: String
  ) {
    updateTaxData(
      input: {
        id: $id
        business_name: $business_name
        type: $type
        rfc: $rfc
        cfdi_usage_id: $cfdi_usage_id
        tax_system_id: $tax_system_id
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        default: $default
        locality: $locality
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_DELETED_TAX_DATA = gql`
  mutation deleteTaxData($id: ID!) {
    deleteTaxData(input: { id: $id }) {
      id
      status
    }
  }
`;

export const MUTATION_INSERT_ADDRESS = gql`
  mutation registerAddress(
    $name: String!
    $street: String!
    $number: String!
    $internal_number: String
    $postal_code: String!
    $suburb: String!
    $state: String!
    $default: Boolean
    $references: String
    $locality: String
  ) {
    registerAddress(
      input: {
        name: $name
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        default: $default
        references: $references
        locality: $locality
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $id: ID!
    $name: String!
    $street: String!
    $number: String!
    $internal_number: String
    $postal_code: String!
    $suburb: String!
    $state: String!
    $default: Boolean!
    $references: String
    $locality: String
  ) {
    updateAddress(
      input: {
        id: $id
        name: $name
        street: $street
        number: $number
        internal_number: $internal_number
        postal_code: $postal_code
        suburb: $suburb
        state: $state
        default: $default
        references: $references
        locality: $locality
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_DELETED_ADDRESS = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(input: { id: $id }) {
      id
      status
    }
  }
`;

export const MUTATION_REGISTER_PATIENT = gql`
  mutation registerPatient(
    $doctor_id: ID!
    $first_name: String!
    $last_name: String!
    $treatment: String!
    $gender: String!
    $birthdate: Date!
    $phone: String
    $email: String
    $treatment_id: ID
  ) {
    registerPatient(
      input: {
        doctor_id: $doctor_id
        first_name: $first_name
        last_name: $last_name
        treatment: $treatment
        gender: $gender
        birthdate: $birthdate
        phone: $phone
        email: $email
        treatment_id: $treatment_id
      }
    ) {
      patient_id
      treatment_id
      status
    }
  }
`;

export const MUTATION_REGISTER_TREATMENT_EXTENSION = gql`
  mutation registerTreatmentExtension($parent_treatment_id: Int!) {
    registerTreatmentExtension(
      input: { parent_treatment_id: $parent_treatment_id }
    ) {
      patient_id
      status
      treatment_id
    }
  }
`;

export const MUTATION_UPLOAD_PATIENT_PHOTO = gql`
  mutation addPatientProfilePhoto($patient_id: Int!, $file: Upload!) {
    addPatientProfilePhoto(input: { patient_id: $patient_id, file: $file }) {
      id
    }
  }
`;

export const MUTATION_CREATE_CREDIT_CARD = gql`
  mutation addDoctorCard(
    $user_id: ID!
    $payment_method: String!
    $default: Boolean!
  ) {
    addDoctorCard(
      input: {
        user_id: $user_id
        payment_method: $payment_method
        default: $default
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_CREATE_CREDIT_CARD_V2 = gql`
  mutation addDoctorCardV2(
    $user_id: ID!
    $card_name: String!
    $card_number: String!
    $exp_month: Int!
    $exp_year: Int!
    $cvv: String!
    $default: Boolean!
    $treatment_type: Int
  ) {
    addDoctorCardV2(
      input: {
        user_id: $user_id
        card_name: $card_name
        card_number: $card_number
        exp_month: $exp_month
        exp_year: $exp_year
        cvv: $cvv
        default: $default
        treatment_type: $treatment_type
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_DELETE_CREDIT_CARD = gql`
  mutation deleteDoctorCard($user_id: ID!, $card_id: ID!) {
    deleteDoctorCard(input: { user_id: $user_id, card_id: $card_id }) {
      id
    }
  }
`;

export const MUTATION_ADD_FORM_PATIENT_FILE = gql`
  mutation addPatientForm($patient_id: Int!, $file: Upload!) {
    addPatientForm(input: { patient_id: $patient_id, file: $file }) {
      id
    }
  }
`;

export const MUTATION_ADD_PATIENT_FILE_TREATMENT_PHOTO = gql`
  mutation addPatientTreatmentPhoto(
    $treatment_id: ID!
    $file: Upload!
    $fileType: FileType!
    $fileSection: FileSection!
  ) {
    addPatientTreatmentPhoto(
      input: {
        treatment_id: $treatment_id
        file: $file
        fileType: $fileType
        fileSection: $fileSection
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_ADD_STL_SHIPPMENT_PHOTO = gql`
  mutation addStlShippmentPhoto(
    $patient_id: ID!
    $file: Upload!
    $fileType: FileType!
    $fileSection: FileSection!
  ) {
    addStlShippmentPhoto(
      input: {
        patient_id: $patient_id
        file: $file
        fileType: $fileType
        fileSection: $fileSection
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_CHANGE_STL_ON_REVIEW = gql`
  mutation changeStlOnReview($treatment_id: ID!) {
    changeStlOnReview(input: { treatment_id: $treatment_id }) {
      id
    }
  }
`;

export const MUTATION_DELETE_EXTRA_STL = gql`
  mutation deleteExtraStlFile($patient_id: ID!) {
    deleteExtraStlFile(input: { patient_id: $patient_id }) {
      status
    }
  }
`;

export const MUTATION_CHANGE_DEFAULT_TAX_DATA = gql`
  mutation updateTaxDataDefault($tax_data_id: ID!) {
    updateTaxDataDefault(input: { tax_data_id: $tax_data_id }) {
      status
    }
  }
`;

export const MUTATION_CHANGE_DEFAULT_ADDRESS = gql`
  mutation updateAddressDefault($address_id: ID!) {
    updateAddressDefault(input: { address_id: $address_id }) {
      status
    }
  }
`;

export const MUTATION_CHANGE_DEFAULT_CARD = gql`
  mutation updatePaymentDefault(
    $user_id: ID!
    $payment_method_ca: String
    $payment_method_ss: String
  ) {
    updatePaymentDefault(
      input: {
        user_id: $user_id
        payment_method_ca: $payment_method_ca
        payment_method_ss: $payment_method_ss
      }
    ) {
      id
      service_id
      service_id_ca
      service_id_ss
      last_digits
      brand
      default
    }
  }
`;

export const MUTATION_PAY_PLANNING_TREATMENT = gql`
  mutation payPlanningTreatment(
    $user_id: ID!
    $invoice: Boolean!
    $treatment_id: ID!
    $taxdata_id: ID
    $intent_id: String
    $amount: Float
    $currency: String
    $count: Int
    $interval: String
    $type: String
    $method: String!
    $coupon_id: ID
    $pack_id: ID
  ) {
    payPlanningTreatment(
      input: {
        user_id: $user_id
        invoice: $invoice
        treatment_id: $treatment_id
        taxdata_id: $taxdata_id
        intent_id: $intent_id
        amount: $amount
        currency: $currency
        count: $count
        interval: $interval
        type: $type
        method: $method
        coupon_id: $coupon_id
        pack_id: $pack_id
      }
    ) {
      status
      bank_code
      bank_name
      clabe
      points
      referenceCode
    }
  }
`;

export const MUTATION_PAY_MANUFACTURE_TREATMENT = gql`
  mutation payManufactureTreatment(
    $user_id: ID!
    $invoice: Boolean!
    $treatment_id: ID!
    $taxdata_id: ID
    $address_id: ID!
    $intent_id: String
    $amount: Float
    $currency: String
    $count: Int
    $interval: String
    $type: String
    $currency_before: String
    $amount_before: String
    $installment_price: String
    $method: String!
    $coupon_id: ID
    $pack_id: ID
  ) {
    payManufactureTreatment(
      input: {
        user_id: $user_id
        invoice: $invoice
        treatment_id: $treatment_id
        taxdata_id: $taxdata_id
        address_id: $address_id
        intent_id: $intent_id
        amount: $amount
        currency: $currency
        count: $count
        interval: $interval
        type: $type
        currency_before: $currency_before
        amount_before: $amount_before
        installment_price: $installment_price
        method: $method
        coupon_id: $coupon_id
        pack_id: $pack_id
      }
    ) {
      status
      bank_code
      bank_name
      clabe
      points
      referenceCode
    }
  }
`;

export const MUTATION_CANCEL_INTENT = gql`
  mutation cancelTransferIntent($user_id: ID!, $treatment_id: ID!) {
    cancelTransferIntent(
      input: { user_id: $user_id, treatment_id: $treatment_id }
    ) {
      status
    }
  }
`;

export const MUTATION_UPDATE_ME_PROFILE_MEMBER = gql`
  mutation updateMemberProfile(
    $first_name: String!
    $last_name: String!
    $phone: String!
    $email: String!
    $gender: String!
  ) {
    updateMemberProfile(
      input: {
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
        gender: $gender
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_UPDATE_PATIENT = gql`
  mutation updatePatient(
    $patient_id: ID!
    $first_name: String!
    $last_name: String!
    $phone: String
    $email: String
    $gender: String!
    $birthdate: Date!
  ) {
    updatePatient(
      input: {
        patient_id: $patient_id
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
        gender: $gender
        birthdate: $birthdate
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_ADD_PHOTO_PATIENT = gql`
  mutation addPatientProfilePhoto($patient_id: Int!, $file: Upload!) {
    addPatientProfilePhoto(input: { patient_id: $patient_id, file: $file }) {
      id
    }
  }
`;

export const MUTATION_APPROVED_FILE_TREATMENT = gql`
  mutation aproveFile($file_id: ID!) {
    aproveFile(input: { file_id: $file_id }) {
      id
      status
    }
  }
`;

export const MUTATION_REJECT_FILE_TREATMENT = gql`
  mutation rejectFile($file_id: ID!, $comments: String!) {
    rejectFile(input: { file_id: $file_id, comments: $comments }) {
      id
      status
    }
  }
`;

export const MUTATION_ACCEPT_DECLINE_FORM = gql`
  mutation acceptDeclineForm(
    $treatment_id: ID!
    $status: String!
    $correction: String
  ) {
    acceptDeclineForm(
      input: {
        treatment_id: $treatment_id
        status: $status
        correction: $correction
      }
    ) {
      id
      form_status
      form_correction
    }
  }
`;

export const MUTATION_UNDO_CORRECTION_TREATMENT = gql`
  mutation undoCorrectionsFile($file_id: ID!) {
    undoCorrectionsFile(input: { file_id: $file_id }) {
      id
      status
    }
  }
`;

export const MUTATION_SUBMIT_QUESTION_DOCTOR = gql`
  mutation contactDoctor(
    $doctor_id: ID!
    $treatment_id: ID!
    $question: String!
  ) {
    contactDoctor(
      input: {
        doctor_id: $doctor_id
        treatment_id: $treatment_id
        question: $question
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_CHAT_CORRECTION_DOCTOR = gql`
  mutation chatCorrectionForm(
    $doctor_id: ID!
    $treatment_id: ID!
    $question: String!
  ) {
    chatCorrectionForm(
      input: {
        doctor_id: $doctor_id
        treatment_id: $treatment_id
        question: $question
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_EDIT_CHAT_CORRECTION_FORM = gql`
  mutation editChatCorrectionForm(
    $treatment_id: ID!
    $question: String!
    $form: Boolean!
  ) {
    editChatCorrectionForm(
      input: { treatment_id: $treatment_id, question: $question, form: $form }
    ) {
      status
    }
  }
`;

export const MUTATION_DELETE_CHAT_CORRECTION_FORM = gql`
  mutation deleteChatCorrectionForm($treatment_id: ID!, $form: Boolean!) {
    deleteChatCorrectionForm(
      input: { treatment_id: $treatment_id, form: $form }
    ) {
      status
    }
  }
`;

export const MUTATION_REVIEW_TREATMENT = gql`
  mutation reviewFilesByTreatment($treatment_id: ID!) {
    reviewFilesByTreatment(input: { treatment_id: $treatment_id }) {
      id
      status
    }
  }
`;

export const MUTATION_ADD_URL_STL = gql`
  mutation addStlUrl($patient_id: ID!, $url_data: [String!]!) {
    addStlUrl(input: { patient_id: $patient_id, url_data: $url_data }) {
      id
    }
  }
`;

export const MUTATION_ARCHIVIED_TREATMENT = gql`
  mutation changeArchivedTreatment($patient_id: ID!, $archived: Boolean!) {
    changeArchivedTreatment(
      input: { patient_id: $patient_id, archived: $archived }
    ) {
      id
      archived
    }
  }
`;

export const MUTATION_SELECT_TREATMENT_BRACKETS = gql`
  mutation updateBracketSS($patient_id: ID!, $brackets: String!) {
    updateBracketSS(input: { patient_id: $patient_id, bracket_ss: $brackets }) {
      status
    }
  }
`;

export const MUTATION_SET_URL_TREATMENT = gql`
  mutation addStlUrl($patient_id: ID!, $url: String!) {
    addStlUrl(input: { patient_id: $patient_id, url: $url }) {
      id
    }
  }
`;

export const MUTATION_SET_SHIPPING_TREATMENT = gql`
  mutation changeToShippingStl($patient_id: ID!) {
    changeToShippingStl(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_SEND_PLANNING_DATA = gql`
  mutation savePlanningData(
    $aligners_number: Int
    $aligner_send_type: String
    $bottom_aligners_number: Int
    $partialities: [CreatePartialityInput!]!
    $planning_case_id: String
    $planning_software: String
    $treatment_id: ID!
    $visor_desk_link: String
    $visor_patient_link: String
    $visor_url: String
  ) {
    savePlanningData(
      input: {
        aligners_number: $aligners_number
        aligner_send_type: $aligner_send_type
        bottom_aligners_number: $bottom_aligners_number
        partialities: $partialities
        planning_case_id: $planning_case_id
        planning_software: $planning_software
        treatment_id: $treatment_id
        visor_desk_link: $visor_desk_link
        visor_patient_link: $visor_patient_link
        visor_url: $visor_url
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_SEND_CORRECTIONS = gql`
  mutation sendCorrections($treatment_id: ID!) {
    sendCorrections(input: { treatment_id: $treatment_id }) {
      treatmentStatus {
        admin_name
        name
      }
    }
  }
`;

export const MUTATION_APPROVED_VISOR = gql`
  mutation ($treatment_id: ID!) {
    approveVisor(input: { treatment_id: $treatment_id }) {
      id
    }
  }
`;
export const MUTATION_PAUSE_VISOR = gql`
  mutation pauseVisor($patient_id: ID!) {
    pauseVisor(input: { patient_id: $patient_id }) {
      id
    }
  }
`;
export const MUTATION_UNDO_PAUSE_VISOR = gql`
  mutation undoPauseVisor($patient_id: ID!) {
    undoPauseVisor(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_CHANGE_MESSAGE_DOCTOR = gql`
  mutation changeMessageDoctor($patient_id: ID!) {
    changeMessageDoctor(input: { patient_id: $patient_id }) {
      id
      message_doctor
    }
  }
`;

export const MUTATION_CHANGE_MORE_SMARTCHECKS = gql`
  mutation changeMoreSmartchecks($treatment_id: ID!) {
    changeMoreSmartchecks(input: { treatment_id: $treatment_id }) {
      id
      message_doctor
    }
  }
`;

export const MUTATION_CHANGE_OPENED_SMARTCHECK = gql`
  mutation changeOpenedSmartcheck($smartcheck_id: ID!) {
    changeOpenedSmartcheck(input: { smartcheck_id: $smartcheck_id }) {
      id
      start_date
      finish_date
      zoom_link
    }
  }
`;

export const MUTATION_DAY_SMARTCHECK = gql`
  mutation daySmartcheck($patient_id: ID!) {
    daySmartcheck(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_APPROVE_CASE_SS = gql`
  mutation approveCaseSS($patient_id: ID!) {
    approveCaseSS(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_GENERATE_SMARTCHECK = gql`
  mutation generateSmartCheck(
    $patient_id: ID!
    $start_date: DateTime!
    $finish_date: DateTime!
  ) {
    generateSmartCheck(
      input: {
        patient_id: $patient_id
        start_date: $start_date
        finish_date: $finish_date
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_CHANGE_STATUS_SMARTCHECK = gql`
  mutation updateStatusSmartCheck($smart_check_id: ID!, $status: String!) {
    updateStatusSmartCheck(
      input: { smart_check_id: $smart_check_id, status: $status }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_CHANGE_NUMBER_ALIGNER = gql`
  mutation confirmUpdateAlignersNumber(
    $smart_check_id: ID!
    $aligners_number: Int!
  ) {
    confirmUpdateAlignersNumber(
      input: {
        smart_check_id: $smart_check_id
        aligners_number: $aligners_number
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_SMARTCHECK_DOCTOR = gql`
  mutation changeMakeSmartcheck($patient_id: ID!) {
    changeMakeSmartcheck(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_CASE_READY = gql`
  mutation changeToPlanificationReady($patient_id: ID!) {
    changeToPlanificationReady(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_PRINTING_READY = gql`
  mutation printingReady($patient_id: ID!) {
    printingReady(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_THERMOFORMING_READY = gql`
  mutation thermoformingReady($patient_id: ID!) {
    thermoformingReady(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_REFINED_READY = gql`
  mutation refinedReady($patient_id: ID!) {
    refinedReady(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_BRACES_PLACED = gql`
  mutation bracesPlaced($patient_id: ID!) {
    bracesPlaced(input: { patient_id: $patient_id }) {
      id
      treatmentStatus {
        name
      }
    }
  }
`;

export const MUTATION_UPDATE_ALIGNER_NUMBER_PATIENT = gql`
  mutation confirmUpdateAlignersNumberByPatient(
    $patient_id: ID!
    $aligners_number: Int!
  ) {
    confirmUpdateAlignersNumberByPatient(
      input: { patient_id: $patient_id, aligners_number: $aligners_number }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_UPDATE_CARD_DOCTOR = gql`
  mutation updatePaymentDefault(
    $user_id: ID!
    $payment_method_ca: String
    $payment_method_ss: String
  ) {
    updatePaymentDefault(
      input: {
        user_id: $user_id
        payment_method_ca: $payment_method_ca
        payment_method_ss: $payment_method_ss
      }
    ) {
      id
      service_id
      service_id_ca
      service_id_ss
      last_digits
      brand
    }
  }
`;

export const MUTATION_UPLOAD_STL_ZIP = gql`
  mutation addTreatmentStl($treatment_id: ID!, $file: Upload!) {
    addTreatmentStl(input: { treatment_id: $treatment_id, file: $file }) {
      id
    }
  }
`;

export const MUTATION_UPLOAD_EVIDENCE = gql`
  mutation addPatientEvidencePhoto($patient_id: ID!, $file: Upload!) {
    addPatientEvidencePhoto(input: { patient_id: $patient_id, file: $file }) {
      fileName
      fileUrl
    }
  }
`;

export const MUTATION_CHANGE_STATUS_TREATMENT = gql`
  mutation changeStatusTreatment(
    $patient_id: ID!
    $treatment_status_index: Int!
    $treatment_status_type: String!
  ) {
    changeStatusTreatment(
      input: {
        patient_id: $patient_id
        treatment_status_index: $treatment_status_index
        treatment_status_type: $treatment_status_type
      }
    ) {
      id
    }
  }
`;

export const MUTATION_ON_NEW_MESSAGE_CHAT = gql`
  mutation onNewMessageChat($channel_url: String!, $patient_id: ID!) {
    onNewMessageChat(
      input: { channel_url: $channel_url, patient_id: $patient_id }
    ) {
      id
    }
  }
`;

export const MUTATION_RESTORE_CHANNEL_NOTIFICATION = gql`
  mutation restoreChannelNotification($patient_id: ID!) {
    restoreChannelNotification(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_SEND_TREATMENT_DHL = gql`
  mutation saveWayBillData(
    $treatment_id: ID!
    $parcel: String!
    $waybill_number: String!
  ) {
    saveWayBillData(
      input: {
        treatment_id: $treatment_id
        parcel: $parcel
        waybill_number: $waybill_number
      }
    ) {
      id
      status
    }
  }
`;

export const MUTATION_END_CONVERSATION = gql`
  mutation endConversationBorgatta($patient_id: ID!) {
    endConversationBorgatta(input: { patient_id: $patient_id }) {
      treatment {
        id
      }
      status
      areaStatus {
        id
        area
        status
        priority
      }
    }
  }
`;

export const MUTATION_UNDO_END_CONVERSATION = gql`
  mutation undoEndChat($patient_id: ID!) {
    undoEndChat(input: { patient_id: $patient_id }) {
      treatment {
        id
      }
      status
      areaStatus {
        id
        area
        status
        priority
      }
    }
  }
`;

export const MUTATION_ASK_CHANGES_SS = gql`
  mutation askChangesSS($patient_id: ID!) {
    askChangesSS(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_ASK_CHANGES_CA = gql`
  mutation askChangesCA($patient_id: ID!) {
    askChangesCA(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_ADD_COMMENT = gql`
  mutation addComment($treatment_id: ID, $comment: String!, $doctor_id: ID) {
    addComment(
      input: {
        treatment_id: $treatment_id
        comment: $comment
        doctor_id: $doctor_id
      }
    ) {
      status
    }
  }
`;

export const MUTATION_REACTIVE_CONVERSATION = gql`
  mutation reactiveConversationBorgatta($patient_id: ID!) {
    reactiveConversationBorgatta(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_REGISTER_UNIVERSITY = gql`
  mutation registerUniversity(
    $name: String!
    $country_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $type: String!
    $graduate_periods: Int!
  ) {
    registerUniversity(
      input: {
        country_id: $country_id
        name: $name
        last_name: $last_name
        first_name: $first_name
        email: $email
        phone: $phone
        type: $type
        graduate_periods: $graduate_periods
      }
    ) {
      id
      name
    }
  }
`;

export const MUTATION_REGISTER_STUDENT = gql`
  mutation registerStudent(
    $university_id: Int!
    $first_name: String!
    $last_name: String!
    $gender: String!
    $cryst_aligner: Boolean!
    $semester: Int!
    $smile_system: Boolean!
    $email: String!
  ) {
    registerStudent(
      input: {
        university_id: $university_id
        first_name: $first_name
        last_name: $last_name
        gender: $gender
        cryst_aligner: $cryst_aligner
        semester: $semester
        smile_system: $smile_system
        email: $email
      }
    ) {
      id
      is_doctor
    }
  }
`;

export const MUTATION_UPDATE_STUDENT = gql`
  mutation updateStudent(
    $id: ID!
    $first_name: String!
    $last_name: String!
    $gender: String!
    $cryst_aligner: Boolean
    $semester: Int
    $smile_system: Boolean
    $email: String
    $phone: String
  ) {
    updateStudent(
      input: {
        id: $id
        first_name: $first_name
        last_name: $last_name
        gender: $gender
        cryst_aligner: $cryst_aligner
        semester: $semester
        smile_system: $smile_system
        email: $email
        phone: $phone
      }
    ) {
      id
      is_doctor
    }
  }
`;

export const MUTATION_REGISTER_TEACHER = gql`
  mutation registerTeacher(
    $first_name: String!
    $last_name: String!
    $university_id: Int!
    $email: String!
    $treatment: String!
  ) {
    registerTeacher(
      input: {
        first_name: $first_name
        last_name: $last_name
        university_id: $university_id
        email: $email
        treatment: $treatment
      }
    ) {
      id
      university {
        id
        name
      }
    }
  }
`;

export const MUTATION_DISABLED_UNIVERSITY = gql`
  mutation changeDisabledUniversity($university_id: Int!, $disabled: Boolean!) {
    changeDisabledUniversity(
      input: { university_id: $university_id, disabled: $disabled }
    ) {
      id
      graduate_periods
      disabled
    }
  }
`;

export const MUTATION_UPDATE_UNIVERSITY = gql`
  mutation updateUniversity(
    $university_id: ID!
    $country_id: Int
    $name: String!
    $last_name: String!
    $first_name: String!
    $email: String!
    $phone: String!
  ) {
    updateUniversity(
      input: {
        university_id: $university_id
        country_id: $country_id
        name: $name
        last_name: $last_name
        first_name: $first_name
        email: $email
        phone: $phone
      }
    ) {
      id
      name
      user {
        id
      }
    }
  }
`;

export const MUTATION_UPDATE_TEACHER = gql`
  mutation updateTeacher(
    $id: ID!
    $first_name: String!
    $last_name: String!
    $gender: String
    $email: String!
    $treatment: String
  ) {
    updateTeacher(
      input: {
        id: $id
        first_name: $first_name
        last_name: $last_name
        gender: $gender
        email: $email
        treatment: $treatment
      }
    ) {
      id
      university {
        id
        name
      }
    }
  }
`;

export const MUTATION_DISABLED_TEACHER = gql`
  mutation deleteActiveTeacher($teacher_id: ID!, $status: String!) {
    deleteActiveTeacher(input: { teacher_id: $teacher_id, status: $status }) {
      id
      status
    }
  }
`;

export const MUTATION_STUDENT_CHANGE_DOCTOR = gql`
  mutation changeToDoctor($doctor_id: ID!) {
    changeToDoctor(input: { doctor_id: $doctor_id }) {
      id
      is_doctor
      user {
        type
      }
    }
  }
`;

export const MUTATION_UPDATE_PLANNING_DATA = gql`
  mutation updatePlanningData(
    $treatment_id: ID!
    $planning_software: String
    $planning_case_id: String
    $aligners_number: Int
    $aligner_send_type: String
    $bottom_aligners_number: Int
    $partialities: [UpdatePartialityInput!]!
    $visor_url: String
    $visor_desk_link: String
    $visor_patient_link: String
  ) {
    updatePlanningData(
      input: {
        treatment_id: $treatment_id
        planning_software: $planning_software
        planning_case_id: $planning_case_id
        aligners_number: $aligners_number
        aligner_send_type: $aligner_send_type
        bottom_aligners_number: $bottom_aligners_number
        partialities: $partialities
        visor_url: $visor_url
        visor_desk_link: $visor_desk_link
        visor_patient_link: $visor_patient_link
      }
    ) {
      status
      id
    }
  }
`;

export const MUTATION_ASSIGN_CASE_TEACHER = gql`
  mutation assignTeacherCase($teacher_id: ID!, $treatment_id: ID!) {
    assignTeacherCase(
      input: { teacher_id: $teacher_id, treatment_id: $treatment_id }
    ) {
      id
    }
  }
`;

export const MUTATION_REASIGNE_CASE = gql`
  mutation reasingToTreatment($id: Int!, $treatment_id: Int!, $type: String!) {
    reasingToTreatment(
      input: { id: $id, type: $type, treatment_id: $treatment_id }
    ) {
      id
    }
  }
`;

export const MUTATION_APPROVED_BY_TEACHER = gql`
  mutation approveCaseByTeacher($patient_id: ID!) {
    approveCaseByTeacher(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_END_CHAT_TREATMENT = gql`
  mutation endPlanningChat($patient_id: ID!) {
    endPlanningChat(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_REQUEST_APPROVAL_STUDENT = gql`
  mutation requestApproval($patient_id: ID!) {
    requestApproval(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_FORM_ARCADA = gql`
  mutation saveFormArcade(
    $treatment_id: ID!
    $type: String!
    $middle_line: String!
    $has_middle_line_left: Boolean!
    $middle_line_left: Float
    $has_middle_line_right: Boolean!
    $middle_line_right: Float
    $overbite: String!
    $overbite_data: Float
    $overjet: String!
    $overjet_data: Float
    $spaces: String!
    $up_spaces_data: [String!]
    $lo_spaces_data: [String!]
    $arch_width: String!
    $arch_exp: Boolean!
    $arch_exp_data: Float
    $arch_shr: Boolean!
    $arch_shr_data: Float
    $arch_teeth_data: [String!]
    $cross_bite: String!
    $crossbite_exp: Boolean!
    $crossbite_exp_data: Float
    $crossbite_shr: Boolean!
    $crossbite_shr_data: Float
    $crossbite_teeth_data: [String!]
    $procline_adjust_maxilla: Boolean!
    $procline_adjust_maxilla_data: Float
    $procline_adjust_jaw: Boolean!
    $procline_adjust_jaw_data: Float
    $expand_adjust_maxilla: Boolean!
    $expand_adjust_maxilla_data: Float
    $expand_adjust_jaw: Boolean!
    $expand_adjust_jaw_data: Float
    $treatment_type: String!
    $aligner_pack: String!
    $spee: String!
    $cross_bite_subselect: String!
  ) {
    saveFormArcade(
      input: {
        treatment_id: $treatment_id
        type: $type
        middle_line: $middle_line
        has_middle_line_left: $has_middle_line_left
        middle_line_left: $middle_line_left
        has_middle_line_right: $has_middle_line_right
        middle_line_right: $middle_line_right
        overbite: $overbite
        overbite_data: $overbite_data
        overjet: $overjet
        overjet_data: $overjet_data
        spaces: $spaces
        up_spaces_data: $up_spaces_data
        lo_spaces_data: $lo_spaces_data
        arch_width: $arch_width
        arch_exp: $arch_exp
        arch_exp_data: $arch_exp_data
        arch_shr: $arch_shr
        arch_shr_data: $arch_shr_data
        arch_teeth_data: $arch_teeth_data
        cross_bite: $cross_bite
        cross_bite_subselect: $cross_bite_subselect
        crossbite_exp: $crossbite_exp
        crossbite_exp_data: $crossbite_exp_data
        crossbite_shr: $crossbite_shr
        crossbite_shr_data: $crossbite_shr_data
        crossbite_teeth_data: $crossbite_teeth_data
        procline_adjust_maxilla: $procline_adjust_maxilla
        procline_adjust_maxilla_data: $procline_adjust_maxilla_data
        procline_adjust_jaw: $procline_adjust_jaw
        procline_adjust_jaw_data: $procline_adjust_jaw_data
        expand_adjust_maxilla: $expand_adjust_maxilla
        expand_adjust_maxilla_data: $expand_adjust_maxilla_data
        expand_adjust_jaw: $expand_adjust_jaw
        expand_adjust_jaw_data: $expand_adjust_jaw_data
        treatment_type: $treatment_type
        aligner_pack: $aligner_pack
        spee: $spee
      }
    ) {
      id
    }
  }
`;

export const MUTATION_FORM_SHAPE = gql`
  mutation saveFormShape(
    $treatment_id: ID!
    $arc_shape: String!
    $desired_class: String!
    $molar_class: Boolean!
    $molar_left_type: String
    $molar_right_type: String
    $canine_relationship: Boolean!
    $canine_left: String
    $canine_right: String
    $anterior_area: String!
  ) {
    saveFormShape(
      input: {
        treatment_id: $treatment_id
        arc_shape: $arc_shape
        desired_class: $desired_class
        molar_class: $molar_class
        molar_left_type: $molar_left_type
        molar_right_type: $molar_right_type
        canine_relationship: $canine_relationship
        canine_left: $canine_left
        canine_right: $canine_right
        anterior_area: $anterior_area
      }
    ) {
      id
    }
  }
`;

export const MUTATION_FORM_IPR = gql`
  mutation saveFormIpr(
    $treatment_id: ID!
    $additional_indications: String
    $instructions: String!
    $up_ipr_data: [String!]
    $lo_ipr_data: [String!]
    $torque: String!
    $back_teeth: Boolean
    $back_teeth_to: Int
    $front_teeth: Boolean
    $front_teeth_to: Int
    $teeth_torque_data: [String!]
    $kind: String
  ) {
    saveFormIpr(
      input: {
        treatment_id: $treatment_id
        instructions: $instructions
        up_ipr_data: $up_ipr_data
        lo_ipr_data: $lo_ipr_data
        torque: $torque
        back_teeth: $back_teeth
        back_teeth_to: $back_teeth_to
        front_teeth: $front_teeth
        front_teeth_to: $front_teeth_to
        teeth_torque_data: $teeth_torque_data
        additional_indications: $additional_indications
        kind: $kind
      }
    ) {
      id
    }
  }
`;

export const MUTATION_FORM_ODONTOGRAMA = gql`
  mutation saveFormOdontogram(
    $treatment_id: ID!
    $type: String!
    $up_left_third_molar_data: [String!]
    $up_left_second_molar_data: [String!]
    $up_left_first_molar_data: [String!]
    $up_left_second_premolar_data: [String!]
    $up_left_first_premolar_data: [String!]
    $up_left_canine_data: [String!]
    $up_left_lateral_incisor_data: [String!]
    $up_left_central_incisor_data: [String!]
    $up_right_third_molar_data: [String!]
    $up_right_second_molar_data: [String!]
    $up_right_first_molar_data: [String!]
    $up_right_second_premolar_data: [String!]
    $up_right_first_premolar_data: [String!]
    $up_right_canine_data: [String!]
    $up_right_lateral_incisor_data: [String!]
    $up_right_central_incisor_data: [String!]
    $lo_left_third_molar_data: [String!]
    $lo_left_second_molar_data: [String!]
    $lo_left_first_molar_data: [String!]
    $lo_left_second_premolar_data: [String!]
    $lo_left_first_premolar_data: [String!]
    $lo_left_canine_data: [String!]
    $lo_left_lateral_incisor_data: [String!]
    $lo_left_central_incisor_data: [String!]
    $lo_right_third_molar_data: [String!]
    $lo_right_second_molar_data: [String!]
    $lo_right_first_molar_data: [String!]
    $lo_right_second_premolar_data: [String!]
    $lo_right_first_premolar_data: [String!]
    $lo_right_canine_data: [String!]
    $lo_right_lateral_incisor_data: [String!]
    $lo_right_central_incisor_data: [String!]
    $comments: String
  ) {
    saveFormOdontogram(
      input: {
        treatment_id: $treatment_id
        type: $type
        up_left_third_molar_data: $up_left_third_molar_data
        up_left_second_molar_data: $up_left_second_molar_data
        up_left_first_molar_data: $up_left_first_molar_data
        up_left_second_premolar_data: $up_left_second_premolar_data
        up_left_first_premolar_data: $up_left_first_premolar_data
        up_left_canine_data: $up_left_canine_data
        up_left_lateral_incisor_data: $up_left_lateral_incisor_data
        up_left_central_incisor_data: $up_left_central_incisor_data
        up_right_third_molar_data: $up_right_third_molar_data
        up_right_second_molar_data: $up_right_second_molar_data
        up_right_first_molar_data: $up_right_first_molar_data
        up_right_second_premolar_data: $up_right_second_premolar_data
        up_right_first_premolar_data: $up_right_first_premolar_data
        up_right_canine_data: $up_right_canine_data
        up_right_lateral_incisor_data: $up_right_lateral_incisor_data
        up_right_central_incisor_data: $up_right_central_incisor_data
        lo_left_third_molar_data: $lo_left_third_molar_data
        lo_left_second_molar_data: $lo_left_second_molar_data
        lo_left_first_molar_data: $lo_left_first_molar_data
        lo_left_second_premolar_data: $lo_left_second_premolar_data
        lo_left_first_premolar_data: $lo_left_first_premolar_data
        lo_left_canine_data: $lo_left_canine_data
        lo_left_lateral_incisor_data: $lo_left_lateral_incisor_data
        lo_left_central_incisor_data: $lo_left_central_incisor_data
        lo_right_third_molar_data: $lo_right_third_molar_data
        lo_right_second_molar_data: $lo_right_second_molar_data
        lo_right_first_molar_data: $lo_right_first_molar_data
        lo_right_second_premolar_data: $lo_right_second_premolar_data
        lo_right_first_premolar_data: $lo_right_first_premolar_data
        lo_right_canine_data: $lo_right_canine_data
        lo_right_lateral_incisor_data: $lo_right_lateral_incisor_data
        lo_right_central_incisor_data: $lo_right_central_incisor_data
        comments: $comments
      }
    ) {
      id
    }
  }
`;

export const MUTATION_FORM_ADDITIONAL = gql`
  mutation updateAdditionalInstructions(
    $patient_id: ID!
    $additional_instructions: String
  ) {
    updateAdditionalInstructions(
      input: {
        patient_id: $patient_id
        additional_instructions: $additional_instructions
      }
    ) {
      id
    }
  }
`;

export const MUTATION_FORM_BRACKETS = gql`
  mutation saveFormBracket(
    $treatment_id: ID!
    $type: String!
    $up_positions_data: [String!]
    $lo_positions_data: [String!]
  ) {
    saveFormBracket(
      input: {
        treatment_id: $treatment_id
        type: $type
        up_positions_data: $up_positions_data
        lo_positions_data: $lo_positions_data
      }
    ) {
      id
    }
  }
`;

export const MUTATION_RESTART_CHAT = gql`
  mutation restartPlanningChat($patient_id: ID!) {
    restartPlanningChat(input: { patient_id: $patient_id }) {
      id
    }
  }
`;

export const MUTATION_ADD_VISOR_VIDEO = gql`
  mutation addVisorVideo($treatment_id: Int!, $file: Upload!) {
    addVisorVideo(input: { treatment_id: $treatment_id, file: $file }) {
      file_url
      file_name
    }
  }
`;
