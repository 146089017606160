import React from 'react';
import Item from '../../Item/Item';
import { Cap, Libraries, ClipBoard } from '@components/shared/ChipIcons';

const SectionsUniversity = (props) => {
  return (
    <>
      <Item
        label="Casos"
        to="/universidad/casos"
        icon={
          <ClipBoard color={props.name === 'casos' ? '#FFFFFF' : '#959C9F'} />
        }
        color={props.name === 'casos' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'casos' && '#333C3F'}
        height={'18'}
        width={'20'}
      />
      <Item
        label="Profesores"
        to="/universidad/profesores"
        icon={
          <Libraries
            color={props.name === 'profesores' ? '#FFFFFF' : '#959C9F'}
          />
        }
        color={props.name === 'profesores' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'profesores' && '#333C3F'}
        height={'19'}
        width={'20'}
      />
      <Item
        label="Estudiantes"
        to="/universidad/estudiantes"
        icon={
          <Cap color={props.name === 'estudiantes' ? '#FFFFFF' : '#959C9F'} />
        }
        color={props.name === 'estudiantes' ? '#FFFFFF' : '#4E5A5F'}
        $background={props.name === 'estudiantes' && '#333C3F'}
        height={'19'}
        width={'20'}
      />
    </>
  );
};

export default SectionsUniversity;
