import React, { Component } from 'react';
import styled from 'styled-components';
import { EventEmitter } from 'events';
import { CSSTransition } from 'react-transition-group';
import ModalManagement from '@classes/modal-management.class';

const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) =>
    background ? background : 'rgba(226, 226, 226, 0.3);'}
  ${({ backdrop }) => (backdrop ? backdrop : 'backdrop-filter: blur(16px);')}
  margin-left: 210px;
  box-sizing: border-box;
`;

const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
`;

export const modal = new ModalManagement();

export default class ModalComponent extends Component {
  state = {
    dom: null,
  };

  componentDidMount() {
    modal.addListener('modal', this.handleModal);
    window.addEventListener('keydown', this.hendleKeyClose);
  }

  componentWillUnmount() {
    modal.removeListener('modal', this.handleModal);
    window.removeEventListener('keydown', this.hendleKeyClose);
  }

  handleModal = (dom) => {
    this.setState({ dom });
  };

  hendleClickClose = (e) => {
    if (e.target !== e.currentTarget) return;
    modal.close();
  };

  render() {
    const { dom } = this.state;
    return (
      <CSSTransition in={dom} timeout={250} classNames="me-modal" unmountOnExit>
        <BackdropContainer>
          <ScrollContainer>{dom}</ScrollContainer>
        </BackdropContainer>
      </CSSTransition>
    );
  }
}
