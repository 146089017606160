import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Select from '@components/shared/Select';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_UNIVERSITY,
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_LOGIN,
} from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import { QUERY_GET_COUNTRIES, QUERY_GET_UNIVERSITY_ID } from '@graphql/query';
import { useHistory, useParams } from 'react-router-dom';
import Avatar from '@components/shared/Avatar';
import * as action from '@redux/actions/auth-action';
import { connect } from 'react-redux';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f8;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const VerifyUniversity = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lada_code, setLadaCode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [file, setFile] = useState({});
  const [photo, setPhoto] = useState('');
  const [idUser, setIdUser] = useState('');

  const [getUniversity] = useLazyQuery(QUERY_GET_UNIVERSITY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.university) {
        const university = data.university;

        setIdUser(university.user.id);

        setName(university.name);
        setLadaCode(university.user.country.lada_code);
        setFirstName(university.user.first_name);
        setLastName(university.user.last_name);
        setPhone(university.user.phone);
        setEmail(university.user.email);
        setPhoto(university.user.photo_filename);
      }
    },
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getUniversity({ variables: { id } });
  }, []);

  const [verifyUniversity] = useMutation(MUTATION_UPDATE_UNIVERSITY, {
    onCompleted: async (data) => {
      if (data.updateUniversity) {
        if (file.name)
          addProfilePhoto({ variables: { file, user_id: +idUser } });
        else
          login({
            variables: { password: history.location.state.password, email },
          });
      }
    },
    onError: (err) => console.log(err),
  });

  const [login] = useMutation(MUTATION_LOGIN, {
    onCompleted: (data) => {
      if (data.login) {
        localStorage.setItem('onboarding', true);
        props.login(data.login.token, data.login.type, data.login.user_id, id);
      }
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        login({
          variables: { password: history.location.state.password, email },
        });
      }
    },
    onError: (err) => console.log(err),
  });

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setPhoto(URL.createObjectURL(prefile));
    }
  };

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, phone, email]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (phone === '') {
      setDisabled(true);
    } else if (email === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    verifyUniversity({
      variables: {
        university_id: id,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        name,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name={null} />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Universidad</Title>
          <LabelNormal color="#757575" fontSize="17">
            Verifica los datos de la universidad y del dato de contacto para que
            te
            <br /> registremos estudiantes y profesores.
          </LabelNormal>

          <div style={{ marginTop: 39 }}>
            <Avatar
              src={
                photo && !file.name
                  ? `${process.env.REACT_APP_API_URL}/users/${idUser}/profile/${photo}`
                  : file.name
                    ? photo
                    : require('@images/university.png')
              }
              floatButton={true}
              edit={!!photo}
              onChange={onChangeFile}
              height="161"
              width="161"
              borderRadius="30"
            />
          </div>
          <div style={{ marginTop: 38 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Nombre de la universidad"
                onChange={(e) => setName(e.target.value)}
                value={name}
                label="Nombre de la institución"
              />
            </div>
          </div>

          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                label="Datos de contacto"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <LabelInput>Teléfono</LabelInput>
              <InlineContainer>
                <Input
                  readOnly
                  disabled
                  value={lada_code !== '' ? lada_code : '+52'}
                  backgroundColor="#F6F6F8"
                  maxWidth="70px"
                />
                <Input
                  placeholder="Teléfono"
                  onChange={setPhone}
                  value={phone}
                  style={{ width: 256 }}
                  mask="phone"
                  validation="phone"
                />
              </InlineContainer>
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Verificar datos"
              disabled={disabled}
              onClick={() => handlerSubmit()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (token, type, id, role_id) =>
    dispatch(action.loginSuccess(token, type, id, role_id)),
});

export default connect(null, mapDispatchToProps)(VerifyUniversity);
