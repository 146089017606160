import Skeleton from 'react-loading-skeleton';
import { CardContainer } from '../../shared/Styled';

const SkeletonActions = () => (
  <CardContainer style={{ borderRadius: 25 }}>
    <div className="row">
      <div className="col-md-12">
        <div
          className="row"
          style={{
            alignItems: 'flex-start',
            marginTop: 30,
          }}
        >
          <div className="col-md-1">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={24}
              circle
              width={24}
              highlightColor={'#FFF'}
              style={{ marginRight: 15 }}
            />
          </div>
          <div className="col-md-10">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={23}
              width={'80%'}
              borderRadius={5}
              highlightColor={'#FFF'}
            />
          </div>
        </div>
        <div
          className="row"
          style={{
            alignItems: 'flex-start',
            marginTop: 30,
          }}
        >
          <div className="col-md-1">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={24}
              circle
              width={24}
              highlightColor={'#FFF'}
              style={{ marginRight: 15 }}
            />
          </div>
          <div className="col-md-10">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={23}
              width={'80%'}
              borderRadius={5}
              highlightColor={'#FFF'}
            />
          </div>
        </div>
        <div
          className="row"
          style={{
            alignItems: 'flex-start',
            marginTop: 30,
          }}
        >
          <div className="col-md-1">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={24}
              circle
              width={24}
              highlightColor={'#FFF'}
              style={{ marginRight: 15 }}
            />
          </div>
          <div className="col-md-10">
            <Skeleton
              baseColor={'#EEEEF2'}
              height={23}
              width={'80%'}
              borderRadius={5}
              highlightColor={'#FFF'}
            />
          </div>
        </div>
      </div>
    </div>
  </CardContainer>
);

export default SkeletonActions;
