import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Avatar from '@components/shared/Avatar';
import Checkbox from 'react-custom-checkbox';
import Select from '@components/shared/Select';
import SelectCustomAddress from '@components/shared/SelectCustomAddress';
import Input from '@components/shared/Input';
import ButtonAction from '@components/shared/ButtonAction';
import ButtonPrime from '@components/shared/ButtonPrime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  MUTATION_EDIT_DOCTOR,
  MUTATION_DECLINE_DOCTOR,
  MUTATION_ADD_MEMBER_PHOTO,
  MUTATION_REACTIVE_DOCTOR,
} from '@graphql/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  QUERY_GET_COUNTRIES,
  QUERY_GET_DATA_POSTAL_CODE,
  QUERY_GET_DETAILS_DOCTOR,
} from '@graphql/query';
import { useParams } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalUnSubscribe from '@components/Modal/ModalUnSubscribe';
import ModalSuccess from '@components/Modal/ModalSuccess';
import { useHistory } from 'react-router-dom';
import {
  loadPhotoProfileGender,
  validateEmail,
  validatePhone,
  validateURL,
} from '../../../utils';
import axios from 'axios';
import B360NetworkDoctorOfficeForm from '../../../components/Doctor/B360NetworkDoctorOfficeForm';
import RegisterForm from '../../../components/Doctor/RegisterForm';
import { useForm } from 'react-hook-form';
import { Column, ErrorMessage, Row } from '../../../components/shared/Styled';
import TextInput from '../../../components/shared/inputs/TextInput';

const ContainHome = styled.div`
  width: 100%;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 8px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 15px;
  font-family: neue-montreal-regular;
  color: #757575;
`;

const FormRequestDoctor = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [genderLocal, setGender] = useState('');
  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    register,
    getValues,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      id_doctor: '',
      id_adviser: '',
      has_adviser: false,
      is_doctor: false,
      category: 'Cadenas',
    },
    mode: 'onBlur',
  });
  const [photo, setPhoto] = useState('');
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [file, setFile] = useState({});
  const [Id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [validFormData, setValidFormData] = useState(true);
  const [formData, setFormData] = useState({});
  const [registerDefaultValues, setRegisterDefaultValues] = useState(null);
  const [officeDefaultValues, setOfficeDefaultValues] = useState(null);
  const [addressDefaultValues, setAddressDefaultValues] = useState(null);
  const [submit, setSubmit] = useState(0);
  const [loader, setLoader] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);
  const [downloadError, setDownloadError] = useState(false);
  const [parentErrors, setParentErrors] = useState([]);

  const watchAdvisor = watch('has_adviser');
  const watchIsPremium = watch('is_doctor');

  const [getDoctorDetail] = useLazyQuery(QUERY_GET_DETAILS_DOCTOR, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.doctor) {
        const doctor = data.doctor;
        const { first_name, last_name, phone, email, gender } =
          data.doctor.user;
        const {
          birthdate,
          cryst_aligner,
          smile_system,
          professional_license,
          timezone,
          timezone_name,
        } = data.doctor;
        setStatus(doctor.status);
        setId(doctor.user.id);
        setPhoto(doctor.user.photo_filename);
        setGender(gender);
        setRegisterDefaultValues({
          first_name,
          last_name,
          phone,
          email,
          birthdate,
          gender,
          country_id: parseInt(data.doctor?.user?.country?.id),
          cryst_aligner,
          smile_system,
          professional_license,
        });
        if (doctor.clinic) {
          const {
            name: clinic_name,
            website,
            phone: clinic_phone,
            alternative_phone,
            street,
            number,
            internal_number,
            postal_code,
            locality,
            state,
            references,
            suburb,
          } = doctor.clinic;
          setOfficeDefaultValues({
            clinic_name,
            website,
            clinic_phone,
            alternative_phone,
          });
          setAddressDefaultValues({
            street,
            number,
            internal_number,
            postal_code,
            locality,
            state,
            timezone,
            timezone_name,
            references,
            city: suburb,
          });
        } else {
          setAddressDefaultValues({
            street: '',
            number: '',
            internal_number: '',
            postal_code: '',
            city: '',
            locality: '',
            state: '',
            timezone,
            timezone_name,
            references: '',
          });
        }
      }
    },
    onError: (err) => console.log(err),
  });

  const [updateDoctor] = useMutation(MUTATION_EDIT_DOCTOR, {
    onCompleted: (data) => {
      if (data.updateDoctor) {
        if (file.name) onSubmitFile(data.updateDoctor.id);
        else {
          modal.open(
            <ModalSuccess
              onClick={() => modal.close(() => history.push('/app/doctores'))}
            >
              <LabelNormal
                fontSize="22"
                color="#060809"
                style={{ paddingTop: 30 }}
              >
                Solicitud aprobada con éxito
              </LabelNormal>
              <LabelNormal
                fontSize="17"
                color="#4F4F4F"
                style={{ paddingTop: 14 }}
              >
                El doctor podrá ingresar al portal y registrar casos
              </LabelNormal>
            </ModalSuccess>,
          );
        }
      }
    },
    onError: (err) => {
      setParentErrors(err.graphQLErrors.map((error) => error.message));
      console.log(err);
    },
  });

  const [addProfilePhoto] = useMutation(MUTATION_ADD_MEMBER_PHOTO, {
    onCompleted: async (data) => {
      if (data.addProfilePhoto) {
        modal.open(
          <ModalSuccess
            onClick={() => modal.close(() => history.push('/app/doctores'))}
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 30 }}
            >
              Solicitud aprobada con éxito
            </LabelNormal>
            <LabelNormal
              fontSize="17"
              color="#4F4F4F"
              style={{ paddingTop: 14 }}
            >
              El doctor podrá ingresar al portal y registrar casos
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const onSubmitFile = async (id) => {
    addProfilePhoto({ variables: { file: file, user_id: +id } });
  };

  useEffect(() => {
    getDoctorDetail({ variables: { id } });
  }, []);

  const [declineDoctor] = useMutation(MUTATION_DECLINE_DOCTOR, {
    onCompleted: (data) => {
      if (data.declineDoctor) {
        modal.close();
        history.push('/app/doctores');
      }
      console.log(data);
    },
    onError: (err) => console.log(err),
  });

  const onSubmitDecline = () => {
    modal.open(
      <ModalUnSubscribe>
        <LabelNormal fontSize="22" color="#060809" style={{ paddingTop: 30 }}>
          La solicitud se moverá a solicitudes declinadas
        </LabelNormal>
        <LabelNormal
          fontSize="17"
          color="#4F4F4F"
          style={{ paddingTop: 14, marginBottom: 15 }}
        >
          Puedes consultar tus solicitud declinadas en el apartado de doctores
          al filtrar por Etiquetas
        </LabelNormal>
        <ButtonPrime
          label="Mover a solicitudes declinadas"
          onClick={() => declineDoctor({ variables: { doctor_id: +id } })}
        />
      </ModalUnSubscribe>,
    );
  };

  const onSubmitUpdateDoctor = () => {
    setSubmit(submit + 1);
    const values = getValues();
    setDownloadError(false);
    updateDoctor({
      variables: {
        user_id: parseInt(Id),
        ...values,
        ...formData,
        status: 'approved',
      },
    });
  };

  const onChangeFile = (evt) => {
    const prefile = evt.target.files[0];
    setFile(prefile);
    if (prefile) {
      setLoader(true);
      onUploadPhoto(prefile);
      setDownloadError(false);
    }
    evt.target.value = '';
  };

  const onUploadPhoto = async (e) => {
    await uploadPhotoAxios(e);
  };

  const uploadPhotoAxios = async (file) => {
    const token = localStorage.getItem('token-b360');
    const instance = axios.create({
      baseURL: process.env.REACT_APP_URL_GRAPHQL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query:
          'mutation addProfilePhoto($file: Upload!, $user_id: Int!) {addProfilePhoto(input: { user_id: $user_id, file: $file }) {id}}',
        variables: {
          file: 'file',
          user_id: +Id,
        },
      }),
    );
    formData.append('0', file);
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    instance
      .post('', formData, {
        onUploadProgress: (progressEvent) => {
          let progress = Math.round(
            (100 * progressEvent.loaded) / progressEvent.total,
          );
          setProgressUpload(progress);
        },
      })
      .then((response) => {
        if (response.data.data.addProfilePhoto) {
          setPreviewPhoto(URL.createObjectURL(file));
          setProgressUpload(0);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        setDownloadError(true);
      });
  };

  const [reactiveDoctorRequest] = useMutation(MUTATION_REACTIVE_DOCTOR, {
    onCompleted: (data) => {
      if (data.reactiveDoctor) {
        getDoctorDetail({ variables: { id } });
      }
    },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="doctores" />
      <ContainHome>
        {status === 'decline' && (
          <div
            style={{
              background: 'rgba(229, 229, 229, 0.4)',
              top: 0,
              bottom: 0,
              position: 'fixed',
              zIndex: 9,
              width: '100%',
            }}
          />
        )}
        <ContainerRegister className="bootstrap-wrapper">
          <div
            style={
              status === 'decline' ? { position: 'relative', zIndex: 99 } : {}
            }
          >
            <Title>Solicitud de Registro</Title>
            <Subtitle>
              Registra a doctores para que puedan utilizar la plataforma
            </Subtitle>
          </div>
          <Column>
            <LabelInput style={{ fontSize: 22 }}>Registrar doctor</LabelInput>
            <Row marginTop="24">
              <TextInput
                name="id_doctor"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                label="ID de doctor"
                placeholder="ID"
                rules={{ required: '*Ingresa el ID del doctor' }}
              />
            </Row>
            <Row>
              <Checkbox
                icon={
                  <FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />
                }
                borderColor="#C8C8C8"
                style={{ overflow: 'hidden' }}
                size={20}
                labelStyle={{
                  marginLeft: 5,
                  userSelect: 'none',
                  fontFamily: 'neue-montreal-regular',
                  color: '#3C4549',
                }}
                label="Tiene asesor"
                checked={watchAdvisor}
                onChange={(e) => setValue('has_adviser', e)}
              />
            </Row>
            {watchAdvisor && (
              <Row marginTop="24">
                <TextInput
                  name="id_adviser"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  label="ID de asesor"
                  placeholder="ID"
                />
              </Row>
            )}
            <Row marginTop="24">
              <Column gap="5px">
                <LabelInput>Categoría</LabelInput>
                <InlineContainer>
                  <div className="radio" style={{ marginRight: 20 }}>
                    <input
                      id="radio-1"
                      {...register('category', {
                        required: '*Selecciona la categoría',
                      })}
                      type="radio"
                      value="Cadenas"
                    />
                    <label htmlFor="radio-1" className="radio-label">
                      Cadenas
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      id="radio-2"
                      {...register('category', {
                        required: '*Selecciona la categoría',
                      })}
                      type="radio"
                      value="Menudeo"
                    />
                    <label htmlFor="radio-2" className="radio-label">
                      Menudeo
                    </label>
                  </div>
                </InlineContainer>
              </Column>
            </Row>
            <Row marginTop="24">
              <Column>
                <Checkbox
                  icon={
                    <FontAwesomeIcon icon={faCheck} color="#EB9542" size="sm" />
                  }
                  borderColor="#C8C8C8"
                  style={{ overflow: 'hidden' }}
                  size={20}
                  labelStyle={{
                    marginLeft: 5,
                    userSelect: 'none',
                    fontFamily: 'neue-montreal-regular',
                    color: '#3C4549',
                  }}
                  label="Es doctor premium"
                  checked={watchIsPremium}
                  onChange={(e) => setValue('is_doctor', e)}
                />
                <LabelMute>
                  *Seleccionar esta casilla le permite al doctor no pagar la
                  planificación en sus tratamientos de CA.
                </LabelMute>
              </Column>
            </Row>
          </Column>
          <ContainForm>
            <Avatar
              src={
                previewPhoto
                  ? previewPhoto
                  : photo
                    ? `${process.env.REACT_APP_API_URL}/users/${Id}/profile/${photo}`
                    : loadPhotoProfileGender(genderLocal, 'doctor')
              }
              edit={photo ? true : false}
              floatButton
              onChange={onChangeFile}
              fadeOut={progressUpload === 100 ? true : false}
              loader={progressUpload}
              error={downloadError}
              state={loader}
            />
          </ContainForm>
          <RegisterForm
            parentErrors={parentErrors}
            setValid={setValidFormData}
            setValues={setFormData}
            submit={submit}
            afterValidate={() => {}}
            defaultValues={registerDefaultValues}
            officeDefaultValues={officeDefaultValues}
            addressDefaultValues={addressDefaultValues}
            edit={true}
          />
          {status === 'decline' ? (
            <div style={{ marginTop: 31, position: 'relative', zIndex: 99 }}>
              <ButtonPrime
                label="Reactivar registro"
                onClick={() =>
                  reactiveDoctorRequest({ variables: { doctor_id: +id } })
                }
              />
            </div>
          ) : (
            <InlineContainer style={{ marginTop: 40 }}>
              <ButtonPrime
                label="Aprobar solicitud"
                disabled={!validFormData || !isValid}
                onClick={() => onSubmitUpdateDoctor()}
              />
              <ButtonPrime
                label="Declinar solicitiud"
                background="transparent"
                color="#3C4549"
                border="1px solid #3C4549"
                minWidth="227"
                style={{ marginLeft: 20 }}
                onClick={() => onSubmitDecline()}
              />
            </InlineContainer>
          )}
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default FormRequestDoctor;
