const initState = {
  isLoggedIn: localStorage.getItem('b360-role')
    ? localStorage.getItem('b360-role')
    : 'unauth',
  isLoading: false,
  token: localStorage.getItem('token-b360')
    ? localStorage.getItem('token-b360')
    : null,
  id: localStorage.getItem('b360-id') ? localStorage.getItem('b360-id') : '',
  role_id: localStorage.getItem('b360-role_id')
    ? localStorage.getItem('b360-role_id')
    : '',
  error: {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token-b360', action.token);
      localStorage.setItem('b360-role', action.role);
      localStorage.setItem('b360-id', action.id);
      localStorage.setItem('b360-role_id', action.role_id);
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.role,
        token: action.token,
        id: action.id,
        role_id: action.role_id,
        error: undefined,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
      };
    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        isLoading: false,
        isLoggedIn: 'unauth',
        id: '',
        role_id: null,
        token: null,
      };
    default:
      return state;
  }
};

export default auth;
