import React, { FC } from 'react';
import CaseDetailsProps from '@interfaces/case-details-props.interface';
import { UserRoleEnum } from '@models/user-role';
import AdminFunctions from './admin-functions';
import DoctorFunctions from './doctor-functions';
import TeacherFunctions from './teacher-functions';

const CaseDetailsContainer: FC<CaseDetailsProps> = ({
  treatment,
  viewer,
  refresh,
}) => {
  const pendingMessages =
    treatment.messages?.filter(
      (message) =>
        ((viewer === UserRoleEnum.DOCTOR || viewer === UserRoleEnum.STUDENT) &&
          (message.from === null ||
            message.from?.id !== treatment.doctor.user?.id)) ||
        (viewer === UserRoleEnum.ADMIN &&
          message.from?.id === treatment.doctor.user?.id)
    ).length ?? 0;

  switch (viewer) {
    case UserRoleEnum.ADMIN:
      return (
        <AdminFunctions
          pendingMessages={pendingMessages}
          treatment={treatment}
          refresh={refresh}
        />
      );
    case UserRoleEnum.DOCTOR:
    case UserRoleEnum.STUDENT:
      return (
        <DoctorFunctions
          pendingMessages={pendingMessages}
          treatment={treatment}
          viewer={viewer}
          refresh={refresh}
        />
      );
    case UserRoleEnum.TEACHER:
      return (
        <TeacherFunctions
          treatment={treatment}
          viewer={viewer}
          refresh={refresh}
        />
      );
    default:
      return null;
  }
};

export default CaseDetailsContainer;
