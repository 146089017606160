import styled from 'styled-components';
import DienteAusente from '@images/odontograma/diente_ausente.svg';
import DienteExtraer from '@images/odontograma/diente_a_extraer.svg';
import PiesaExcluir from '@images/odontograma/pieza_a_excluir.svg';
import Implante from '@images/odontograma/implante.svg';
import PiezaErupcion from '@images/odontograma/pieza_en_erupcion.svg';
import Inscrustacion from '@images/odontograma/inscrustacion.svg';
import ProtesisFija from '@images/odontograma/protesis_fija.svg';
import DienteIncluidoRetenido from '@images/odontograma/diente_incluido_retenido.svg';
import DienteConEndodoncia from '@images/odontograma/diente_con_endodoncia.svg';
import ErosionDental from '@images/odontograma/erosion_dental.svg';
import FracturaCorona from '@images/odontograma/fractura_en_corona.svg';
import Restauracion from '@images/odontograma/restauracion.svg';

const Glossary = () => {
  return (
    <div>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={DienteAusente}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Diente ausente
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={Implante}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Implante
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={ProtesisFija}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Prótesis fija
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={ErosionDental}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Erosión dental
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={DienteExtraer}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Diente a extraer
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={PiezaErupcion}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Pieza en erupción
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={DienteIncluidoRetenido}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Diente incluido retenido
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={FracturaCorona}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Fractura en corona
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={PiesaExcluir}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Pieza a excluir
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={Inscrustacion}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Incrustación
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={DienteConEndodoncia}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Diente con endodoncia
            </LabelNormal>
          </InlineContainer>
        </div>
        <div className="col-md-4">
          <InlineContainer>
            <img
              src={Restauracion}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '48.6765px',
                background: '#FFFFFF',
              }}
            />
            <LabelNormal
              color="#3C4549"
              fontSize="17"
              style={{ fontWeight: 400 }}
            >
              Restauración
            </LabelNormal>
          </InlineContainer>
        </div>
      </div>
    </div>
  );
};

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

export default Glossary;
