import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '@components/Navigation/Sidebar/Sidebar';
import Select from '@components/shared/Select';
import Input from '@components/shared/Input';
import ButtonNavigation from '@components/Navigation/ButtonNavigation/ButtonNavigation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MUTATION_REGISTER_UNIVERSITY } from '@graphql/mutation';
import ButtonPrime from '@components/shared/ButtonPrime';
import { QUERY_GET_COUNTRIES } from '@graphql/query';
import { useHistory } from 'react-router-dom';
import { modal } from '@components/ModalManagement';
import ModalSuccess from '@components/Modal/ModalSuccess';

const ContainHome = styled.div`
  width: 100%;
  background-color: #fbfbfc;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f6f6f833;
`;

const ContainContent = styled.div`
  margin-left: 51px;
  margin-top: 28px;
  margin-right: 79px;
  margin-bottom: 100px;
`;

const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerRegister = styled.div`
  margin-top: 29px;
  margin-left: 51px;
  max-width: 875px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 30px;
  font-family: neue-montreal-regular;
  color: #3c4549;
`;

const Subtitle = styled.h2`
  font-size: 17px;
  color: #757575;
  font-family: neue-montreal-regular;
`;

const ContainForm = styled.div`
  margin-top: 39px;
`;

const LabelInput = styled.div`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #3c4549;
  line-height: 21.6px;
`;

const LabelMute = styled.p`
  font-size: 18px;
  font-family: neue-montreal-regular;
  color: #757575;
`;
const LabelNormal = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '9')}px;
  font-family: neue-montreal-regular;
  color: ${({ color }) => (color ? color : '#757575')};
  margin: 5px;
`;

const RegisterUniversity = (props) => {
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [type, setType] = useState('');
  const [periodGraduate, setPeriodGraduate] = useState('');

  const [countries, setCountries] = useState([]);

  const [registerUniversity] = useMutation(MUTATION_REGISTER_UNIVERSITY, {
    onCompleted: async (data) => {
      if (data.registerUniversity) {
        modal.open(
          <ModalSuccess
            onClick={() =>
              modal.close(() => history.push('/app/universidades'))
            }
          >
            <LabelNormal
              fontSize="22"
              color="#060809"
              style={{ paddingTop: 20 }}
            >
              Universidad registrada
            </LabelNormal>
            <LabelNormal fontSize="17" color="#4F4F4F">
              Ahora puedes registrar a sus estudiantes, profesores y asignar
              casos.
            </LabelNormal>
          </ModalSuccess>,
        );
      }
    },
    onError: (err) => console.log(err),
  });

  const [getCountries] = useLazyQuery(QUERY_GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCountries(data.countries.data);
    },
  });

  useEffect(() => {
    getCountries({});
  }, []);

  useEffect(() => {
    validationForms();
  }, [firstName, lastName, country, phone, email, periodGraduate]);

  const validationForms = () => {
    if (firstName === '') {
      setDisabled(true);
    } else if (lastName === '') {
      setDisabled(true);
    } else if (country === '') {
      setDisabled(true);
    } else if (phone === '') {
      setDisabled(true);
    } else if (email === '') {
      setDisabled(true);
    } else if (periodGraduate === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handlerSubmit = () => {
    registerUniversity({
      variables: {
        first_name: firstName,
        last_name: lastName,
        email,
        country_id: +country,
        phone,
        name,
        graduate_periods: +periodGraduate,
        type,
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar name="universidades" />
      <ContainHome className="bootstrap-wrapper">
        <Header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 17,
              marginLeft: 51,
            }}
          >
            <ButtonNavigation
              icon={require('@images/icons/chevron-left.png')}
              onClick={() => history.goBack()}
            />
            <ButtonNavigation
              icon={require('@images/icons/chevron-right.png')}
            />
          </div>
        </Header>
        <ContainerRegister>
          <Title>Registro de universidad</Title>
          <div style={{ marginTop: 38 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Nombre de la universidad"
                onChange={(e) => setName(e.target.value)}
                value={name}
                label="Nombre de la institución"
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: 28 }}>
            <div className="col-md-5">
              <LabelInput>País</LabelInput>
              <Select
                options={countries}
                onSelect={setCountry}
                value={country}
                placeholder="Selecciona un país"
              />
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <Input
                placeholder="Nombre"
                onChange={(e) => setFirstName(e.target.value)}
                label="Datos de contacto"
              />
            </div>
            <div>
              <span style={{ fontSize: 18, visibility: 'hidden' }}>none</span>
              <Input
                placeholder="Apellidos"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="grid-layout">
            <div>
              <LabelInput>Teléfono</LabelInput>
              <InlineContainer>
                <Input
                  readOnly
                  disabled
                  value={
                    country !== ''
                      ? countries.find((c) => c.id === country).lada_code
                      : '+52'
                  }
                  backgroundColor="#F6F6F8"
                  maxWidth="70px"
                />
                <Input
                  placeholder="Teléfono"
                  onChange={setPhone}
                  value={phone}
                  style={{ width: 256 }}
                  mask="phone"
                  validation="phone"
                />
              </InlineContainer>
            </div>
          </div>
          <div style={{ marginTop: 28 }} className="row">
            <div className="col-md-5">
              <Input
                placeholder="Tu correo"
                label="Correo"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <InlineContainer style={{ marginTop: 18 }}>
            <LabelNormal color="#3C4549" fontSize="18">
              Período escolar{' '}
            </LabelNormal>
            <LabelMute>
              (Esta opción no se puede modificar más adelante)
            </LabelMute>
          </InlineContainer>
          <div className="row">
            <div className="col-md-5">
              <LabelInput>Tipo</LabelInput>
              <Select
                options={[
                  { name: 'Semestre', id: 'Semestre' },
                  { name: 'Trimestre', id: 'Trimestre' },
                  { name: 'Cuatrimestre', id: 'Cuatrimestre' },
                  { name: 'Anual', id: 'Anual' },
                ]}
                onSelect={setType}
                value={type}
                placeholder="Selecciona un período"
              />
            </div>
            <div className="col-md-5">
              <Input
                placeholder="Cantidad"
                label="Períodos para graduarse"
                value={periodGraduate}
                onChange={(e) =>
                  setPeriodGraduate(e.target.value).replace(/[^0-9]/g, '')
                }
              />
            </div>
          </div>
          <InlineContainer style={{ marginTop: 40 }}>
            <ButtonPrime
              label="Registrar"
              disabled={disabled}
              onClick={() => handlerSubmit()}
            />
          </InlineContainer>
        </ContainerRegister>
      </ContainHome>
    </div>
  );
};

export default RegisterUniversity;
