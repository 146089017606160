import { gql } from '@apollo/client';

export const QUERY_GET_TREATMENT = gql`
  query treatment($id: ID) {
    treatment(id: $id) {
      id
      patient {
        id
        first_name
        last_name
        treatment
      }
      treatmentStatus {
        id
        name
        index
        type
        admin_name
        color_type
      }
      treatmentType {
        id
        name
      }
      doctor {
        id
        category
        university {
          id
          name
        }
        user {
          id
          first_name
          last_name
          photo_filename
          type
          country {
            id
            name
          }
        }
      }
      bracket_ss
      has_changes_ca
      has_changes_ss
      aligner_send_type
      visor_url
      visor_desk_link
      visor_patient_link
      planning_software
      planning_case_id
      message_doctor
      make_smartcheck
      aligners_number
      bottom_aligners_number
      last_smart_check {
        id
        zoom_link
        status
      }
      files {
        id
        name
        file_name
      }
      partialities {
        id
        bottom
        upper
      }
      smartsChecks {
        id
        start_date
        finish_date
        zoom_link
        status
      }
      payments {
        id
        amount
        history_amount
        history_currency
        amount_usd
        currency
        invoiced
        status
        type
        coupon {
          id
          coupon_code
        }
        card {
          id
        }
        invoiced
        invoices {
          id
        }
        type
        status
        bank_code
        bank_name
        clabe
        reference
        taxdata {
          id
          business_name
          type
          rfc
          street
          number
          suburb
          postal_code
          state
          cfdiUsage {
            id
            name
          }
          taxSystem {
            id
            name
          }
        }
        manufact {
          id
          pack {
            id
            name
          }
        }
      }
    }
  }
`;
