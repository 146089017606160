import { CardInlineContainer, LabelNormal } from '../shared/Styled';

const AddressCard = ({ address }) => (
  <CardInlineContainer
    style={{
      borderRadius: 10,
      minHeight: 105,
      maxWidth: 334,
      marginTop: 10,
      marginBottom: 10,
      padding: 16,
      boxShadow: '0px 0px 8px rgba(162, 162, 162, 0.1)',
    }}
  >
    <LabelNormal color="#3C4549" fontSize="18" style={{ marginBottom: 10 }}>
      {address.name ? address.name : ''}
    </LabelNormal>
    <LabelNormal
      color="#757575"
      fontSize="16"
      style={{ marginBottom: 10, width: 302 }}
    >
      {address.street
        ? `${address.street} ${address.number}, ${address.suburb}, ${address.postal_code} ${address.state}`
        : ''}
    </LabelNormal>
  </CardInlineContainer>
);

export default AddressCard;
